import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  getDay,
  getHours,
  getMinutes,
  isDate,
  isWithinInterval,
} from 'date-fns';
import { SignupComponent } from 'src/app/profile/office/signup/signup.component';
import { HelpersService } from '../../services/helpers.service';
import { SignupFormService } from '../../services/signup-form.service';
import { SmallDataService } from '../../services/small-data.service';
import { WorkersAvatarsService } from '../../services/workers-avatars.service';
import { DatetimePickerDialogComponent } from '../../compnents/datetime-picker-dialog/datetime-picker-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-plan-temp-group',
  templateUrl: './plan-temp-group.component.html',
  styleUrls: ['./plan-temp-group.component.css'],
})
export class PlanTempGroupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helpers: HelpersService,
    private workersService: WorkersAvatarsService,
    private smallDataService: SmallDataService,
    private ref: ChangeDetectorRef,
    private signupService: SignupFormService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }
  @Input() groupToEdit;
  teachers;
  studentsForGroup = [];
  times = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  splitAvalibility = [];
  selectedDay;
  enteredStartTime;
  enteredEndTime;
  selectedTeacher;
  groupTitle = '';
  tempGroup = {
    id: null,
    title: '',
    start: null,
    end: null,
    color: {
      primary: null,
      secondary: null,
    },
    draggable: true,
    resizable: {
      beforeStart: true,
      afterEnd: true,
    },

    meta: {
      teacher: null,
      location: null,
      students: [],
      studentsIds: [],
    },
  };
  ngOnInit(): void {
    this.initAvalibility();
  }
  initAvalibility() {
    if (this.groupToEdit) {
      this.studentsForGroup = [];
      this.tempGroup = this.groupToEdit;
      this.groupTitle = this.tempGroup.title;
      this.selectedDay = this.selectedDay =
        this.tempGroup.start.toISOString().split('T')[0] + 'T';
      //if time is in format 8:50 i want to convert it to 08:30 so it will be displayed correctly in the input
      let hours = getHours(this.tempGroup.start);
      let minutes = this.tempGroup.start
        .toISOString()
        .split('T')[1]
        .split(':')[1];
      this.enteredStartTime = hours.toString().padStart(2, '0') + ':' + minutes;
      let hours2 = getHours(this.tempGroup.end);
      let minutes2 = this.tempGroup.end
        .toISOString()
        .split('T')[1]
        .split(':')[1];
      this.enteredEndTime = hours2.toString().padStart(2, '0') + ':' + minutes2;
      this.selectedTeacher = this.tempGroup.meta.teacher;
      this.studentsForGroup = this.tempGroup.meta.students;
      console.log(
        '🚀 ~ file: plan-temp-group.component.ts ~ line 67 ~ PlanTempGroupComponent ~ ngOnInit ~ this.selectedTeacher',
        this.selectedTeacher,
      );
    }
    this.teachers = this.workersService.workersWithAvatars.filter(
      (worker) => worker.teacherId,
    );

    if (!this.groupToEdit) {
      this.studentsForGroup = this.data.selectedStudents;
    }
    this.studentsForGroup?.forEach((student) => {
      student?.avalibility.forEach((avalibility) => {
        const day = isDate(avalibility.start)
          ? avalibility.start
          : this.helpers.convertFromStringToDate(avalibility?.start);
        avalibility.day = getDay(day);
      });

      student.splitAvalibility = this.helpers.splitArrayByProperty(
        student.avalibility,
        'day',
      );
      if (
        !student.splitAvalibility.some((avalibility) =>
          avalibility.some((avalibility2) => avalibility2.day == 1),
        )
      ) {
        student.splitAvalibility.push([{ day: 1, value: 'empty' }]);
      }
      if (
        !student.splitAvalibility.some((avalibility) =>
          avalibility.some((avalibility2) => avalibility2.day == 5),
        )
      ) {
        student.splitAvalibility.push([{ day: 5, value: 'empty' }]);
      }
      if (
        !student.splitAvalibility.some((avalibility) =>
          avalibility.some((avalibility2) => avalibility2.day == 2),
        )
      ) {
        student.splitAvalibility.push([{ day: 2, value: 'empty' }]);
      }
      if (
        !student.splitAvalibility.some((avalibility) =>
          avalibility.some((avalibility2) => avalibility2.day == 3),
        )
      ) {
        student.splitAvalibility.push([{ day: 3, value: 'empty' }]);
      }
      if (
        !student.splitAvalibility.some((avalibility) =>
          avalibility.some((avalibility2) => avalibility2.day == 4),
        )
      ) {
        student.splitAvalibility.push([{ day: 4, value: 'empty' }]);
      }
    });
  }
  checkAvalibility(): boolean {
    const enteredStartData = this.selectedDay + this.enteredStartTime;
    const enteredEndData = this.selectedDay + this.enteredEndTime;
    if (!this.selectedDay) {
      alert('Wybierz dzień!');
      return;
    }
    if (this.groupToEdit?.meta?.studentsIds?.length > 0) {
      return this.smallDataService.checkAvalibility(
        this.studentsForGroup,
        enteredStartData,
        enteredEndData,
      );
    }
  }
  addStudentToGroup(): void {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: '80vw',
      height: '80vh',
      minWidth: '80vw',
      minHeight: '80vh',
      data: { windowMode: true },
    });
    dialogRef.afterClosed().subscribe((studentFromList) => {
      if (!this.groupToEdit.meta.students) {
        this.groupToEdit.meta.students = [];
      }
      if (!this.groupToEdit.meta.studentsIds) {
        this.groupToEdit.meta.studentIds = [];
      }
      this.groupToEdit.meta.students.push(studentFromList);
      this.groupToEdit.meta.studentIds.push(studentFromList.studentId);
      this.initAvalibility();
    });
  }
  removeStudentFromGroup(student) {
    this.groupToEdit.meta.students = this.helpers.removeElementFromArray(
      this.groupToEdit.meta.students,
      student,
    );
    this.groupToEdit.meta.studentIds = this.helpers.removeElementFromArray(
      this.groupToEdit.meta.studentsIds,
      student.studentId,
    );
  }
  timeEntered() {
    this._snackBar.open(
      'Wpisywanie czasu niedostępne. Kliknij zegarek i wybierz czas',
      null,
      {
        duration: 3000,
      },
    );
  }
  setTimes(outside?) {
    let students = [];
    if (this.studentsForGroup?.length > 0) {
      students = this.studentsForGroup.map((student) => {
        return student.studentId;
      });
    }
    const enteredStartData = this.selectedDay + this.enteredStartTime;
    console.log(
      '🚀 ~ file: plan-temp-group.component.ts:199 ~ PlanTempGroupComponent ~ setTimes ~ enteredStartData:',
      enteredStartData,
    );
    const enteredEndData = this.selectedDay + this.enteredEndTime;
    this.tempGroup.id = this.helpers.makeid();
    this.tempGroup.start =
      this.helpers.convertFromStringToDate(enteredStartData);
    //if time is in format 8:50 i want to convert it to 08:30

    this.tempGroup.end = this.helpers.convertFromStringToDate(enteredEndData);
    this.tempGroup.meta.teacher = this.selectedTeacher;
    this.tempGroup.meta.studentsIds = students;
    this.tempGroup.color.primary = this.selectedTeacher.colorDark;
    this.tempGroup.color.secondary = this.selectedTeacher.colorLight;
    this.tempGroup.title = this.groupTitle;
    if (this.studentsForGroup?.length > 0) {
      this.tempGroup.meta.location = this.studentsForGroup[0].location;
    }
    const checkPromise = new Promise<any>((resolve, reject) => {
      this.checkAvalibility();
      resolve(null);
    });
    checkPromise
      .then((result) => {
        // this.smallDataService.tempGroups.push(this.tempGroup);
        if (!outside) {
          this.smallDataService.saveNewTempGroup(this.tempGroup);
          alert('Grupa zapisana!');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  //todo move this to a service
  //todo change students to ids and search for them in db
}
