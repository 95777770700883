<h2 mat-dialog-title>{{ data.resource ? "Edytuj" : "Dodaj" }} zasoby kursu</h2>

<div mat-dialog-content>
  <div class="container-fluid">
    <!-- Programy nauczania (przeniesione na górę) -->
    <div class="row mb-4">
      <div class="col-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Programy nauczania</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="curriculum-actions">
              <button
                mat-raised-button
                color="primary"
                (click)="openAddCurriculumDialog()"
              >
                <mat-icon>add</mat-icon> Dodaj program nauczania
              </button>
            </div>

            <div
              *ngIf="
                !resource.curriculumRefs || resource.curriculumRefs.length === 0
              "
              class="empty-list-message"
            >
              Brak przypisanych programów nauczania
            </div>

            <div
              *ngFor="let curriculumRef of resource.curriculumRefs"
              class="curriculum-item"
            >
              <mat-card>
                <mat-card-header>
                  <mat-card-title>{{ curriculumRef.name }}</mat-card-title>
                  <div class="card-actions">
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="viewReferencedCurriculum(curriculumRef)"
                      matTooltip="Wyświetl program"
                    >
                      <mat-icon>visibility</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="removeCurriculumRef(curriculumRef)"
                      matTooltip="Usuń program"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </mat-card-header>
              </mat-card>
            </div>

            <!-- Stare programy nauczania (tylko widok, będą usunięte przy zapisie) -->
            <div
              *ngIf="resource.curriculum && resource.curriculum.length > 0"
              class="mt-4"
            >
              <mat-card
                class="warning-card"
                style="background-color: #fff3cd; margin-bottom: 16px"
              >
                <mat-card-content>
                  <div style="display: flex; align-items: center">
                    <mat-icon style="color: #856404; margin-right: 8px"
                      >warning</mat-icon
                    >
                    <span style="color: #856404">
                      Uwaga: Poniższe programy nauczania są w starym formacie i
                      zostaną usunięte przy zapisie. Aby zachować te programy,
                      zapisz je w panelu programów nauczania i dodaj je
                      ponownie.
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>

              <div
                *ngFor="let curriculum of resource.curriculum; let i = index"
                class="curriculum-item"
              >
                <mat-card>
                  <mat-card-header>
                    <mat-card-title
                      >{{ curriculum.name }} (stary format)</mat-card-title
                    >
                    <div class="card-actions">
                      <button
                        mat-icon-button
                        color="primary"
                        (click)="viewCurriculum(i)"
                        matTooltip="Wyświetl program"
                      >
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </div>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="curriculum-summary">
                      <p>
                        <strong>Liczba jednostek:</strong>
                        {{ curriculum.units?.length || 0 }}
                      </p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nazwa zasobów</mat-label>
          <input matInput [(ngModel)]="resource.name" required />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Wiek od</mat-label>
              <mat-select [(ngModel)]="resource.ageFrom">
                <mat-option [value]="0">Nie określono</mat-option>
                <mat-option *ngFor="let age of ageOptions" [value]="age">
                  {{ age }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Wiek do</mat-label>
              <mat-select [(ngModel)]="resource.ageTo">
                <mat-option [value]="0">Nie określono</mat-option>
                <mat-option *ngFor="let age of ageOptions" [value]="age">
                  {{ age }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Poziom trudności</mat-label>
          <mat-select [(ngModel)]="resource.difficultyLevel">
            <mat-option [value]="">Nie określono</mat-option>
            <mat-option
              *ngFor="let level of difficultyLevelOptions"
              [value]="level"
            >
              {{ level }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Poziom CEFR</mat-label>
          <mat-select [(ngModel)]="resource.difficultyLevelCEFR">
            <mat-option [value]="">Nie określono</mat-option>
            <mat-option
              *ngFor="let level of difficultyLevelCEFROptions"
              [value]="level"
            >
              {{ level }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Kompetencja</mat-label>
          <mat-select [(ngModel)]="resource.competence">
            <mat-option [value]="">Nie określono</mat-option>
            <mat-option *ngFor="let comp of competenceOptions" [value]="comp">
              {{ comp }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Opis</mat-label>
          <textarea
            matInput
            [(ngModel)]="resource.description"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-checkbox [(ngModel)]="resource.isSchoolCurriculum">
          Zgodne z programem szkolnym
        </mat-checkbox>
      </div>
    </div>

    <div class="row" *ngIf="resource.isSchoolCurriculum">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nazwa podręcznika</mat-label>
          <input
            matInput
            [(ngModel)]="resource.bookName"
            placeholder="Wprowadź nazwę podręcznika"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4">
        <div class="resource-section">
          <div class="resource-header">
            <h3>Zestawy</h3>
            <div class="resource-header-actions">
              <button
                mat-icon-button
                matTooltip="Wklej zestawy"
                (click)="pasteItems('sets')"
              >
                <mat-icon>content_paste</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Kopiuj wszystkie zestawy"
                (click)="copyItems('sets')"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Usuń wszystkie zestawy"
                color="warn"
                (click)="removeAllSets()"
                *ngIf="resource.sets.length > 0"
              >
                <mat-icon>delete_sweep</mat-icon>
              </button>
              <button mat-mini-fab color="primary" (click)="openAddSetDialog()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="resource-list">
            <mat-card
              *ngFor="let set of resource.sets"
              class="resource-item"
              style="
                border-radius: 8px;
                border-left: 4px solid #3f51b5;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
              "
            >
              <div class="resource-item-content">
                <span class="resource-item-name">{{ set.name }}</span>
                <div class="resource-item-actions">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="previewSet(set)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="removeSet(set)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
            <div *ngIf="resource.sets.length === 0" class="empty-list">
              Brak zestawów
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="resource-section">
          <div class="resource-header">
            <h3>Ćwiczenia</h3>
            <div class="resource-header-actions">
              <button
                mat-icon-button
                matTooltip="Wklej ćwiczenia"
                (click)="pasteItems('activities')"
              >
                <mat-icon>content_paste</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Kopiuj wszystkie ćwiczenia"
                (click)="copyItems('activities')"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Usuń wszystkie ćwiczenia"
                color="warn"
                (click)="removeAllActivities()"
                *ngIf="resource.activities.length > 0"
              >
                <mat-icon>delete_sweep</mat-icon>
              </button>
              <button
                mat-mini-fab
                color="accent"
                (click)="openAddActivityDialog()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="resource-list">
            <mat-card
              *ngFor="let activity of resource.activities"
              class="resource-item"
              style="
                border-radius: 8px;
                border-left: 4px solid #ffc107;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
              "
            >
              <div class="resource-item-content">
                <span class="resource-item-name">{{ activity.name }}</span>
                <div class="resource-item-actions">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="openAddActivityPanel(activity)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeActivity(activity)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
            <div *ngIf="resource.activities.length === 0" class="empty-list">
              Brak ćwiczeń
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="resource-section">
          <div class="resource-header">
            <h3>Zadania domowe</h3>
            <div class="resource-header-actions">
              <button
                mat-icon-button
                matTooltip="Wklej zadania"
                (click)="pasteItems('homework')"
              >
                <mat-icon>content_paste</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Kopiuj wszystkie zadania"
                (click)="copyItems('homework')"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Usuń wszystkie zadania domowe"
                color="warn"
                (click)="removeAllHomework()"
                *ngIf="resource.homework.length > 0"
              >
                <mat-icon>delete_sweep</mat-icon>
              </button>
              <button
                mat-mini-fab
                color="warn"
                (click)="openAddHomeworkDialog()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="resource-list">
            <mat-card
              *ngFor="let hw of resource.homework"
              class="resource-item"
              style="
                border-radius: 8px;
                border-left: 4px solid #f44336;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
              "
            >
              <div class="resource-item-content">
                <span class="resource-item-name">{{
                  hw.displayName || hw.name
                }}</span>
                <div class="resource-item-actions">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="openShowHomeworkPanel(hw)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeHomework(hw)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
            <div *ngIf="resource.homework.length === 0" class="empty-list">
              Brak zadań domowych
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div class="resource-section">
          <div class="resource-header">
            <h3>Pliki</h3>
            <button mat-mini-fab color="primary" (click)="addFileGroup()">
              <mat-icon>create_new_folder</mat-icon>
            </button>
          </div>
          <div class="resource-list files-list">
            <div
              *ngIf="resource.fileGroups && resource.fileGroups.length === 0"
              class="empty-list"
            >
              Brak grup plików. Kliknij przycisk powyżej, aby dodać grupę.
            </div>

            <mat-card
              *ngFor="let group of resource.fileGroups; let groupIndex = index"
              class="file-group-card mb-3"
              style="
                border-radius: 8px;
                border-left: 4px solid #3f51b5;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
              "
            >
              <!-- Nagłówek grupy -->
              <mat-card-header
                style="
                  background-color: #f5f5f5;
                  border-bottom: 1px solid #e0e0e0;
                  margin-bottom: 12px;
                  padding: 12px;
                "
              >
                <div
                  *ngIf="!group.editMode"
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <mat-card-title>{{
                    group.name || "Nowa grupa"
                  }}</mat-card-title>
                  <div>
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="toggleGroupEditMode(group)"
                      matTooltip="Edytuj nazwę grupy"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="addFileToGroup(groupIndex)"
                      matTooltip="Dodaj plik"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="warn"
                      (click)="removeFileGroup(groupIndex)"
                      matTooltip="Usuń grupę"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="group.editMode"
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <mat-form-field appearance="outline" class="w-100 m-0">
                    <mat-label>Nazwa grupy</mat-label>
                    <input
                      matInput
                      [(ngModel)]="group.name"
                      placeholder="Wprowadź nazwę grupy"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="toggleGroupEditMode(group)"
                    matTooltip="Zapisz nazwę grupy"
                  >
                    <mat-icon>check</mat-icon>
                  </button>
                </div>
              </mat-card-header>

              <!-- Lista plików -->
              <mat-card-content>
                <div
                  *ngIf="group.files && group.files.length === 0"
                  class="empty-list"
                >
                  Brak plików w tej grupie. Kliknij przycisk "+" aby dodać plik.
                </div>

                <div
                  *ngFor="let file of group.files; let fileIndex = index"
                  class="file-item mb-2"
                >
                  <!-- Widok pliku (nie edycja) -->
                  <div
                    *ngIf="!file.editMode"
                    class="d-flex justify-content-between align-items-center p-2"
                    style="
                      border-bottom: 1px solid #f0f0f0;
                      background-color: white;
                    "
                  >
                    <div
                      class="d-flex flex-row flex-wrap justify-content-start align-items-start"
                    >
                      <a
                        class="mr-2 mb-1"
                        style="
                          color: #3f51b5;
                          text-decoration: none;
                          cursor: pointer;
                        "
                        (click)="openFileWithFreshUrl(file.url)"
                      >
                        {{ file.name }}
                      </a>
                      <span class="mat-small ml-3">{{ file.description }}</span>
                    </div>
                    <div>
                      <button
                        mat-icon-button
                        color="primary"
                        (click)="toggleFileEditMode(file)"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        color="warn"
                        (click)="removeFile(file, groupIndex)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>

                  <!-- Tryb edycji pliku -->
                  <div
                    *ngIf="file.editMode"
                    class="file-edit-form p-3"
                    style="
                      border-bottom: 1px solid #f0f0f0;
                      background-color: #fafafa;
                    "
                  >
                    <mat-form-field appearance="outline" class="w-100 mb-2">
                      <mat-label>Nazwa pliku</mat-label>
                      <input
                        matInput
                        [(ngModel)]="file.name"
                        placeholder="Wprowadź nazwę pliku"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 mb-2">
                      <mat-label>Opis</mat-label>
                      <input
                        matInput
                        [(ngModel)]="file.description"
                        placeholder="Wprowadź opis pliku"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 mb-2">
                      <mat-label>URL</mat-label>
                      <input
                        matInput
                        [(ngModel)]="file.url"
                        placeholder="Wprowadź URL pliku"
                      />
                    </mat-form-field>

                    <div class="d-flex justify-content-end">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="toggleFileEditMode(file)"
                      >
                        Zapisz
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Anuluj</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="resource"
    [disabled]="!resource.name"
  >
    {{ data.resource ? "Zapisz zmiany" : "Dodaj zasoby" }}
  </button>
</div>
