import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ReadTextService } from '../../services/read-text.service';
import { SpeechRecognitionService } from '../../services/speech-recognition.service';

@Component({
  selector: 'app-speech-recognition',
  templateUrl: './speech-recognition.component.html',
  styleUrls: ['./speech-recognition.component.css'],
})
export class SpeechRecognitionComponent implements OnInit, OnDestroy {
  sub;
  listening = false;
  understood = true;
  data;
  result;
  @ViewChild('area') area: ElementRef;
  @Input() expectedInput = null;
  @Input() difficulty;
  @Input() _startRecognition = null;
  @Input() lang = null;
  @Input() big = null;
  @Input() possibilities = null;
  @Output() answer = new EventEmitter();
  answerAlreadyEmitted: boolean;
  constructor(
    public speechRecognitionService: SpeechRecognitionService,
    private ref: ChangeDetectorRef,
    private readTextService: ReadTextService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes._startRecognition?.currentValue == true) {
      this.startRecognition(this.difficulty);
      console.log(
        '🚀 ~ file: speech-recognition.component.ts:47 ~ SpeechRecognitionComponent ~ ngOnChanges ~ this.difficulty:',
        this.difficulty,
      );
    }
  }
  ngOnInit(): void {
    this.sub = this.speechRecognitionService
      .passRecognitionData()
      .subscribe((data) => {

        this.data = data;
        this.valuesSetter();
        this.ref.detectChanges();
      });
  }
  valuesSetter() {
    switch (this.data.listening) {
      case true:
        this.listening = true;
        break;
      case false:
        this.listening = false;
        break;
    }

    switch (this.data.understood) {
      case true:
        this.understood = true;
        break;
      case false:
        this.understood = false;
        break;
    }
    if (this.data.result) {
      this.result = null;
      this.result = this.data.result;
      setTimeout(() => {
        this.emit();
      }, 500);
    }
  }
  emit() {

    if (
      this.result.recognitionType === 'expectedInput' ||
      'matchResultToPossibilities'
    ) {
      if (
        this.result.recognizedText
          .toLowerCase()
          .trim()
          .replace('.', '')
          .replace('?', '') ===
        this.expectedInput
          .toLowerCase()
          .trim()
          .replace('.', '')
          .replace('?', '')
      ) {
        //correctAnswer
        if (!this.answerAlreadyEmitted) {
          this.answer.emit({
            source: 'speech-recognition',
            answer: 'correct',
            correctAnswer: this.expectedInput,
            text: this.result.recognizedText.toLowerCase().trim(),
          });
        }
      } else {
        //wrongAnswer
        if (!this.answerAlreadyEmitted) {
          this.answerAlreadyEmitted = true;
          this.answer.emit({
            source: 'speech-recognition',
            answer: 'wrong',
            correctAnswer: this.expectedInput,
            text: this.result.recognizedText.toLowerCase().trim(),
          });
        }
      }
    }
  }
  startRecognition(difficulty) {
    console.log(
      '🚀 ~ file: speech-recognition.component.ts:127 ~ SpeechRecognitionComponent ~ startRecognition ~ difficulty:',
      this.difficulty,
    );
    this.speechRecognitionService.startRecognition(
      this.expectedInput,
      this.possibilities,
      this.lang,
      this.difficulty,
    );
    this.readTextService.stopPlaying();
  }
  stopRecording() {
    // this.speechRecognitionService.stopRecording()
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
