import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { isThisMinute } from 'date-fns/esm';
import { th } from 'date-fns/locale';
import { SetMergePreviewComponent } from 'src/app/database/sets/set-merge-preview/set-merge-preview.component';
import { LessonActivitiesAddEditComponent } from 'src/app/profile/teacher/teacher-activities/lesson-activities-add-edit/lesson-activities-add-edit.component';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { DigitalOceanService } from 'src/app/shared/services/digitalocean.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CoursesPlanComponent } from '../../courses-plan/courses-plan.component';
import { CoursePlanService } from './course-plan.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { reverse } from 'dns';

@Component({
  selector: 'app-course-plan',
  templateUrl: './course-plan.component.html',
  styleUrls: ['./course-plan.component.css'],
})
export class CoursePlanComponent implements OnInit {
  @Input() plan = [];
  itemToDisplay = null;
  @Input() editable = false;
  @Input() draggable = false;
  @Input() finishedItems = [];
  @Input() lessonView = false;
  @Input() clickFinished = false;
  @Input() courseResources = [];
  @Output() buttonClicked = new EventEmitter<any>();

  sub: any;

  constructor(
    private dialog: MatDialog,
    private coursePlanService: CoursePlanService,
    private coursesService: CoursesService,
    private helpers: HelpersService,
    private bottomSheet: MatBottomSheet,
    public userDetailsService: UserDetailsService,
    private digitalOceanService: DigitalOceanService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.clickFinished?.currentValue == true && changes?.clickFinished?.previousValue == false) {
      this.clickFinishButton();
    }
    if (changes?.finishedItems?.currentValue?.length > 0) {
      changes.finishedItems.currentValue.forEach((item: any) => {
        let nextItems = this.plan.filter(
          (planItem) => planItem?.item?.id !== item,
        );
        this.plan.forEach((planItem: any, index) => {
          if (
            !nextItems.some((nextItem) => nextItem.item.id !== planItem.item.id)
          ) {
            planItem.finished = true;
            nextItems = nextItems.filter(
              (nextItem) => nextItem.item.id !== planItem.item.id,
            );
          }
          // if (nextItems[index]) {
          //   if (planItem.item.id === nextItems[index].item.id && !this.itemToDisplay) {
          //     this.itemToDisplay = index
          //   }
          // }
        });
      });
      this.itemToDisplay =
        this.plan.findIndex(
          (planItem: any) =>
            planItem.item.id ===
            changes.finishedItems.currentValue[
            changes.finishedItems.currentValue.length - 1
            ],
        ) + 1;
    } else {
      this.itemToDisplay = 0;
    }
  }
  ngOnInit(): void {
    this.sub = this.coursesService
      .foundPlanItemsUpdatedListener()
      .subscribe((planItem: any) => {
        if (!planItem) return;

        // Find all items that match this ID
        let foundItems = this.plan.filter(
          (_planItem) => {
            const itemId = _planItem.item?.id || (_planItem.item?.item?.id);
            return itemId == planItem.id;
          }
        );

        // Update all matched items with the full data
        foundItems.forEach((item: any) => {
          const index = this.plan.findIndex((__planItem) => {
            const existingId = __planItem.item?.id || (__planItem.item?.item?.id);
            const itemId = item.item?.id || (item.item?.item?.id);
            return existingId == itemId;
          });

          if (index !== -1) {
            // Ensure we preserve the structure while updating with new data
            this.plan[index].item = {
              item: planItem
            };
          }
        });
      });

    // In edit mode, load all plan items
    if (this.editable && this.plan.length > 0) {
      // Load all plan items when in edit mode
      this.plan.forEach((planItem) => {
        if (planItem && planItem.item) {
          const itemId = planItem.item?.item?.id ? planItem.item.item.id : planItem.item.id;
          if (itemId) {
            this.coursesService.findPlanItemsbyId(itemId);
          } else {
            console.warn('Plan item has no ID to load:', planItem);
          }
        }
      });
    } else if (this.itemToDisplay !== null && this.plan.length > 0) {
      // Load the current item to display
      const currentItem = this.plan[this.itemToDisplay];
      if (currentItem && currentItem.item) {
        const itemId = currentItem.item?.item?.id ? currentItem.item.item.id : currentItem.item.id;
        if (itemId) {
          this.coursesService.findPlanItemsbyId(itemId);
        }
      }

      // Load the next item if it exists
      if (this.itemToDisplay + 1 < this.plan.length) {
        const nextItem = this.plan[this.itemToDisplay + 1];
        if (nextItem && nextItem.item) {
          const itemId = nextItem.item?.item?.id ? nextItem.item.item.id : nextItem.item.id;
          if (itemId) {
            this.coursesService.findPlanItemsbyId(itemId);
          }
        }
      }
    } else if (this.plan.length > 0) {
      // If no specific item is displayed, load the first item
      const firstItem = this.plan[0];
      if (firstItem && firstItem.item) {
        const itemId = firstItem.item?.item?.id ? firstItem.item.item.id : firstItem.item.id;
        if (itemId) {
          this.coursesService.findPlanItemsbyId(itemId);
        }
      }
    }
  }
  clickFinishButton() {
    this.markAsFinished(this.plan[this.itemToDisplay]);

  }
  rearrangeItem(currentIndex: number, newIndex: number): void {
    // Guard against out-of-bounds
    if (newIndex < 0 || newIndex >= this.plan.length) {
      console.warn('Invalid position specified');
      return;
    }

    // Move the item
    const [itemToMove] = this.plan.splice(currentIndex, 1);
    this.plan.splice(newIndex, 0, itemToMove);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.plan, event.previousIndex, event.currentIndex);
  }
  previewSet(set) {
    // Check if set exists
    if (!set) {
      console.error('Cannot preview undefined set');
      return;
    }

    // Clone to prevent unintended reference issues
    const safeSet = {
      id: set.id || '',
      name: set.name || '',
      // Only add other essential properties needed for preview
      type: set.type || '',
      items: set.items || 0
    };

    // Emit a clean event with only necessary data
    this.buttonClicked.emit({
      type: 'set',
      action: 'visibility',
      ...safeSet
    });
  }

  previewActivity(activity) {
    // Check if activity exists
    if (!activity) {
      console.error('Cannot preview undefined activity');
      return;
    }

    // Clone to prevent unintended reference issues
    const safeActivity = {
      id: activity.id || '',
      name: activity.name || '',
      description: activity.description || '',
      // Only add other essential properties needed for preview
      time: activity.time || '',
      tag: activity.tag || ''
    };

    // Emit a clean event with only necessary data
    this.buttonClicked.emit({
      type: 'activity',
      action: 'visibility',
      ...safeActivity
    });
  }

  previewHomework(homework) {
    // Check if homework exists
    if (!homework) {
      console.error('Cannot preview undefined homework');
      return;
    }

    // Clone to prevent unintended reference issues
    const safeHomework = {
      id: homework.id || '',
      name: homework.displayName || homework.name || '',
      // Only add other essential properties needed for preview
      type: homework.type || ''
    };

    // Emit a clean event with only necessary data
    this.buttonClicked.emit({
      type: 'homework',
      action: 'visibility',
      ...safeHomework
    });
  }

  openActivityDialog() {
    // Implement activity dialog logic

    // Implement dialog opening for adding new activities
  }

  openHomeworkDialog() {
    // Implement homework dialog logic

    // Implement dialog opening for adding new homework
  }
  openAddActivityPanel(activity) {
    let _class = 'empty-wide';
    if (window.innerWidth < 800) {
      _class = 'empty';
    }
    const ref = this.bottomSheet.open(LessonActivitiesAddEditComponent, {
      data: {
        id: activity.id,
      },
      panelClass: _class,
    });
  }
  openShowHomeworkPanel(homework) {
    let _class = 'empty-wide';
    if (window.innerWidth < 800) {
      _class = 'empty';
    }
    const ref = this.bottomSheet.open(LessonActivitiesAddEditComponent, {
      data: {
        tag: homework.displayName,
      },
      panelClass: _class,
    });
  }
  openAddCoursePanel() {
    // Save current overflow state
    const originalOverflow = document.body.style.overflow;

    const dialogRef = this.dialog.open(CoursesPlanComponent, {
      data: {
        addMode: true,
        courseResources: this.courseResources
      },
      width: '100vw',
      height: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      panelClass: 'full-screen-dialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Restore original overflow state
      document.body.style.overflow = originalOverflow;

      if (result && Array.isArray(result)) {
        result.forEach((item) => {
          if (!this.plan) {
            this.plan = [];
          }
          this.plan.push({
            item: { item: item.item, optional: false, done: false },
          });
        });
        this.coursePlanService.passUpdatedPlan(this.plan);
      }
    });
  }
  showNextItem() {
    const nextItem = this.plan[this.itemToDisplay + 1];

    if (nextItem && nextItem.item) {
      this.itemToDisplay++;

      // Load the next item's details
      this.coursesService.findPlanItemsbyId(
        nextItem.item?.item?.id ? nextItem.item.item.id : nextItem.item.id,
      );

      // Preload the item after the next one if it exists
      if (this.itemToDisplay + 1 < this.plan.length) {
        const itemAfterNext = this.plan[this.itemToDisplay + 1];
        if (itemAfterNext && itemAfterNext.item) {
          this.coursesService.findPlanItemsbyId(
            itemAfterNext.item?.item?.id ? itemAfterNext.item.item.id : itemAfterNext.item.id,
          );
        }
      }
    }
  }
  showPreviousItem(): void {
    if (this.itemToDisplay > 0) {
      this.itemToDisplay--;

      // Load the previous item's details
      const prevItem = this.plan[this.itemToDisplay];
      if (prevItem && prevItem.item) {
        this.coursesService.findPlanItemsbyId(
          prevItem.item?.item?.id ? prevItem.item.item.id : prevItem.item.id,
        );
      }
    }
  }
  markAsFinished(item) {
    this.buttonClicked.emit({ type: 'itemFinished', value: item.item.item.id });
    this.showNextItem();
  }
  addSetClicked(set) {
    this.buttonClicked.emit({ type: 'addSet', value: set, fromPlanItem: this.plan[this.itemToDisplay] });
  }
  addActivityClicked(activity) {
    this.buttonClicked.emit({ type: 'addActivity', value: activity });
  }
  addHomeworkClicked(homework) {
    this.buttonClicked.emit({ type: 'addHomework', value: homework });
  }
  removeItem(item) {
    const itemName = item?.item?.item?.name || 'wybrany element';
    if (confirm('Czy na pewno usunąć element ' + itemName)) {
      this.plan = this.helpers.removeElementFromArray(this.plan, item);
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
  /**
   * Opens a file URL in a new tab, ensuring a fresh pre-signed URL is used if it's from Digital Ocean
   * @param fileUrl The file URL to open
   */
  openFileWithFreshUrl(fileUrl: string) {
    // Validate URL format first
    if (!fileUrl) {
      console.error('Invalid URL provided');
      return;
    }

    try {
      // Test if it's a valid URL by creating a URL object
      new URL(fileUrl);

      // Check if this is a DigitalOcean Spaces URL
      if (fileUrl.includes('digitaloceanspaces.com')) {
        // Get a fresh download link for Digital Ocean URLs
        this.digitalOceanService.getFreshDownloadLinkFromUrl(fileUrl)
          .subscribe(
            response => {
              if (response.success) {
                // Open the fresh URL in a new tab
                window.open(response.downloadLink, '_blank');
              } else {
                console.error('Error getting fresh download link:', response);
                // Fallback to the original URL if we can't get a fresh one
                window.open(fileUrl, '_blank');
              }
            },
            error => {
              console.error('Error getting fresh download link:', error);
              // Fallback to the original URL if there's an error
              window.open(fileUrl, '_blank');
            }
          );
      } else {
        // If it's not a DigitalOcean URL, just open it directly
        window.open(fileUrl, '_blank');
      }
    } catch (e) {
      // If the URL is invalid, show an error
      console.error('Invalid URL format:', fileUrl);
      alert('The file URL is invalid or malformed.');
    }
  }
  private logItemStructureOnce(item: any, type: string) {
    // Create a unique key for this item
    const itemId = item?.item?.item?.id || item?.item?.id;
    const logKey = `logged_${type}_${itemId}`;

    // Only log if we haven't logged this item before
    if (!this[logKey]) {
      console.log(`No ${type} found in item. Full item structure:`, {
        originalItem: item,
        itemDotItem: item.item,
        itemDotItemDotItem: item.item?.item,
        itemContent: item.item?.item || item.item
      });
      // Mark this item as logged
      this[logKey] = true;
    }
  }

  private getItemContent(item: any): any {
    if (!item) return null;

    // Try to get the deepest item content first
    if (item?.item?.item?.item) {
      return item.item.item.item;
    }

    // If not found, try each level up
    if (item?.item?.item) {
      return item.item.item;
    }

    if (item?.item) {
      return item.item;
    }

    return item;
  }

  getItemName(item: any): string {
    const content = this.getItemContent(item);
    return content?.name || '';
  }

  getItemDescription(item: any): string {
    const content = this.getItemContent(item);
    return content?.description || '';
  }

  getItemSets(item: any): any[] {
    const content = this.getItemContent(item);
    return content?.sets || [];
  }

  getItemActivities(item: any): any[] {
    const content = this.getItemContent(item);
    return content?.activities || [];
  }

  getItemHomework(item: any): any[] {
    const content = this.getItemContent(item);
    return content?.homework || [];
  }

  getItemSkills(item: any): string[] {
    const content = this.getItemContent(item);
    if (!content?.skills?.length) {
      console.log('Skills not found in item:', {
        content,
        originalItem: item
      });
    }
    return content?.skills || [];
  }

  getItemAges(item: any): string[] {
    const content = this.getItemContent(item);
    if (!content?.ages?.length) {
      console.log('Ages not found in item:', {
        content,
        originalItem: item
      });
    }
    return content?.ages || [];
  }

  /**
   * Safely retrieves the files array from a potentially nested plan item.
   * @param item The plan item.
   * @returns The files array or an empty array if not found.
   */
  getPlanItemFiles(item: any): any[] {
    // Access the nested structure safely
    const content = this.getItemContent(item);
    return content?.files || [];
  }
}
