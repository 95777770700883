import { HelpersService } from 'src/app/shared/services/helpers.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';

@Injectable({
  providedIn: 'root',
})
export class SetsService {
  textChanged = new Subject();
  buttonClicked = new Subject();
  menuMode = new Subject();
  badgesData = new Subject();
  searchResults = new Subject();
  imageSelected = new Subject();
  setName = new Subject();
  setsUpdated = new Subject();
  openSetId = new Subject();
  currentSetName = new Subject();
  foundSetUpdated = new Subject();
  foundWordByIdSub = new Subject();
  foundSetByPath = new Subject();
  sliderMode = new Subject();
  currentSet = new Subject();
  setsForLessonUpdated = new Subject();
  userDetails;
  _currentSet;
  numberOfPages = 1;
  exerciseSliderChecked = false;
  foundWordById;
  _foundWordByIdSub;
  url;
  recentTags = [];
  editFirstSetAfterCreation = false;
  foundWords;
  allSets = [];
  setCreated = new Subject();
  allTags: any;
  allTagsSub = new Subject();
  numberOfSets: any;
  multipleSetsFound = new Subject();

  constructor(
    private http: HttpClient,
    private userDetailsService: UserDetailsService,
    private authService: AuthenticationService,
    private helpers: HelpersService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;

    this.userDetails = this.userDetailsService.getUserDetails();

  }

  findWords(wordsToFind, searchParameters) {
    if (wordsToFind.length === 0) {
      return;
    }
    this.http
      .post<{ words: any }>(this.url + '/api/dictionary/findsetwords', {
        wordstofind: wordsToFind,
        searchParameters: searchParameters,
      })
      .pipe(
        map((words) => {
          return words.words.map((word) => {
            return {
              id: word._id,
              word: word.data.word,
              added: word.data.added,
              meanings: word.data.meanings,
            };
          });
        }),
      )
      .subscribe((words) => {
        this.foundWords = words;
        this.searchResults.next(this.foundWords);
      });
  }

  addPath(path, setId) {
    this.http
      .post(this.url + '/api/sets/addpath', {
        setId: setId,
        path: path,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  setmissingWords(missingWords, setId) {
    missingWords.words.forEach((word) => {
      word.fromSet = setId;
    });
    this.http
      .post(this.url + '/api/sets/setmissingWords', {
        setId: setId,
        missingWords: missingWords,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  removePath(path, setId) {
    this.http
      .post(this.url + '/api/sets/removepath', {
        setId: setId,
        path: path,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  updateStoriesProperty(setId, id, property, text) {
    this.http
      .put(this.url + '/api/sets/update-stories-property', {
        setId: setId,
        id: id,
        property: property,
        text: text
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  updateDialoguesProperty(setId, id, property, text) {
    this.http
      .put(this.url + '/api/sets/update-dialogues-property', {
        setId: setId,
        id: id,
        property: property,
        text: text
      })
      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  removePathFromSetsByPath(folderPath) {
    this.http
      .post(this.url + '/api/sets/removepathfromsetsbypath', {
        path: folderPath,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }
  setRecentTags(tags) {
    this.recentTags = this.helpers.clone(tags);
    console.log(
      '🚀 ~ file: sets.service.ts:161 ~ SetsService ~ setRecentTags ~  this.recentTags:',
      this.recentTags,
    );
  }
  addTag(tag, setId) {
    this.http
      .post(this.url + '/api/sets/addtag', {
        setId: setId,
        tag: tag,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
        if (!this.allTags.includes(tag)) {
          this.allTags.push(tag);
          this.allTagsSub.next(this.allTags);
        }
      });
  }
  removeTag(tag, setId) {
    this.http
      .post(this.url + '/api/sets/removetag', {
        setId: setId,
        tag: tag,
      })

      .subscribe((result) => {
        console.log(
          '🚀 ~ file: sets.service.ts ~ line 95 ~ SetsService ~ .subscribe ~ result',
          result,
        );
      });
  }

  setCurrentSetName(name) {
    this.currentSetName.next(name);
  }

  setExerciseSlider(type) {

    switch (type) {
      case 'exercise':
        this.exerciseSliderChecked = true;
        this.updateSlider();
        break;
      case 'main':
        this.exerciseSliderChecked = false;
        this.updateSlider();
        break;
    }
    this.sliderMode.next(this.exerciseSliderChecked);
  }

  updateSlider(): Observable<any> {
    return this.sliderMode.asObservable();
  }

  removeSetFromAllStudents(setToRemoveId) {
    this.http
      .put(
        this.url + '/api/customers/removesetfromallstudents/' + setToRemoveId,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  removeSetFromAllGroups(setToRemoveId) {
    this.http
      .put(this.url + '/api/sets/removesetfromallgroups/' + setToRemoveId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => { });
  }

  setCurrentSet(set) {

    this.currentSet.next(set);
    this._currentSet = set;
  }

  getCurrentSet(): Observable<any> {
    return this.currentSet.asObservable();
  }

  getPlainCurrentSet() {
    return this._currentSet;
  }

  searchForSets(setName) {
    this.allSets = [];

    if (setName === '') {
      this.getSetsList();
      return;
    }
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetbytag', {
        setToFind: setName,
      })
      .pipe(
        map((sets) => {
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              parentSet: set.data?.parentSet,
              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((sets) => {
        console.log(
          '🚀 ~ file: sets.service.ts:287 ~ SetsService ~ .subscribe ~ sets:',
          sets,
        );
        this.allSets = sets;
        this.setsUpdated.next(this.allSets);
      });
  }

  searchForSetsbyId(setId) {
    this.allSets = [];

    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetbyid', {
        setToFind: setId,
      })
      .pipe(
        map((sets) => {
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((set) => {
        if (set[0] === 'reload') {
          alert('Błąd! Aplikacja zostanie przeładowana');
          window.location.reload();
        } else {
          this.foundSetUpdated.next(set[0]);
        }
      });
  }
  findSetsByPath(path) {
    this.allSets = [];

    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetbypath', {
        path: path,
      })
      .pipe(
        map((sets) => {
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((set) => {
        if (set[0] === 'reload') {
          alert('Błąd! Aplikacja zostanie przeładowana');
          window.location.reload();
        } else {
          this.foundSetByPath.next(set);
        }
      });
  }

  findsetsbulk(setsIds, merge?, mode?: 'exercise' | 'homework') {
    this.allSets = [];
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetsbulk', {
        setsToFind: setsIds,
      })
      .pipe(
        map((sets) => {
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((set) => {
        const allsets2 = this.allSets.concat(set);
        this.allSets = Array.from(new Set([...allsets2]));
        if (mode == 'homework') {
          let finalSet = this.helpers.clone(this.allSets[0]);
          finalSet.items = [];
          this.allSets.forEach((set) => {
            set.items.forEach((item: any) => {
              item.fromSet = { name: set.name, id: set.id };
            });
            finalSet.items = [...finalSet.items, ...set.items];
          });
          finalSet.items = this.helpers.removeDuplicatesObj(finalSet.items);
          finalSet.id = '1234';
          finalSet.name = 'Exercise';
          this.setsForLessonUpdated.next({ sets: [finalSet], mode: mode });
          console.log(
            '🚀 ~ file: sets.service.ts ~ line 397 ~ SetsService ~ .subscribe ~ finalSet',
            finalSet,
          );
        }
        if (mode == 'exercise') {
          let finalSet = this.helpers.clone(this.allSets[0]);
          let allTags = [];
          finalSet.items = [];
          this.allSets.forEach((set) => {
            set.items.forEach((item: any) => {
              item.fromSet = { name: set.name, id: set.id };
            });
            if (set?.tags?.length > 0) {
              allTags = [...allTags, ...set.tags];
            }
            finalSet.items = [...finalSet.items, ...set.items];
          });
          finalSet.items = this.helpers.removeDuplicatesObj(finalSet.items);
          finalSet.tags = this.helpers.removeDuplicatesObj(allTags);
          finalSet.id = '1234';
          finalSet.name = 'Exercise';
          if (
            !this.allSets.some(
              (set) => set.tags?.some((tag) => tag.toLowerCase() == 'text'),
            )
          ) {
            finalSet.items = this.helpers.shuffle(finalSet.items);
          }
          this.setsForLessonUpdated.next({ sets: [finalSet], mode: mode });
          console.log(
            '🚀 ~ file: sets.service.ts ~ line 397 ~ SetsService ~ .subscribe ~ finalSet',
            finalSet,
          );
        }

        this.setsUpdated.next(this.allSets);


      });
  }

  sendSetToDatabase(set, duplicate?, merge?, name?, missingWords?) {
    set = this.helpers.clone(set);

    // set.items.forEach(element => {
    //   element.item = ''
    //   })
    set.items.forEach((element) => {
      element.item = '';
    });
    if (set.type !== 'free') {
      set.items = this.helpers.shuffle(set.items);
    }
    set.lastModified = Date.now();
    const dataToSend = set;
    set.owner = {
      name: this.userDetailsService.getSimpleUserDetails().name,
      id: this.userDetailsService.getSimpleUserDetails().id,
      role: this.userDetailsService.getSimpleUserDetails().role,
    };
    this.http
      .post<{ message: string; createdSetId }>(
        this.url + '/api/sets/addset',
        dataToSend,
      )
      .subscribe((response) => {
        // if(duplicate === true || merge === true){
        // }
        if (response.createdSetId) {
          if (missingWords) {
            missingWords.words.forEach((word) => {
              word.fromSet = response.createdSetId._id;
              delete word.meanings;
              word.missingWordsMode = missingWords.mode;
              word.translation = word.wordToFind.translation;
              word.changedWord = word.wordToFind.word;
              delete word.wordToFind;
            });

            this.setmissingWords(missingWords, response.createdSetId._id);
          }
          console.log(
            '🚀 ~ file: sets.service.ts:516 ~ SetsService ~ .subscribe ~ this.editFirstSetAfterCreation:',
            this.editFirstSetAfterCreation,
          );

          this.setCreated.next({
            setCreated: true,
            createdSetId: response.createdSetId,
          });
          setTimeout(() => {
            set.id = response.createdSetId;

            if (this.allSets) {
              this.allSets.unshift(set);
            }

            this.setCurrentSet(set);
            this.setsUpdated.next(this.allSets);
            this.openSet(response.createdSetId);
          }, 1000);
        }
      });
    // this.getSetsList()
    if (name) {
      this.setCurrentSetName(name);
    } else {
      this.setCurrentSetName('');
    }
  }


  getAllTabs() {
    return this.http.get(this.url + '/api/sets/get-all-tabs').pipe(
      map((tabs: any) => {
        console.log(
          '🚀 ~ file: sets.service.ts:661 ~ SetsService ~ this.http.get ~ tabs:',
          tabs,
        );
        this.allTags = tabs;
        this.allTagsSub.next(this.allTags);
        return tabs;
      })
    );
  }
  updateSet(set) {
    set.lastModified = Date.now();
    set.selected = false;
    set.owner = {
      name: this.userDetailsService.getSimpleUserDetails().name,
      id: this.userDetailsService.getSimpleUserDetails().id,
      role: this.userDetailsService.getSimpleUserDetails().role,
    };
    let setToSend = this.helpers.clone(set);
    setToSend.items.forEach((element) => {
      element.item = '';
    });
    this.http
      .put<any>(this.url + '/api/sets/updateset/', setToSend)
      .subscribe((response) => {
        this.openSet(set.id);
      });
  }

  duplicateSet(set, duplicate?) {


    const newSet = {
      added: Date.now(),
      children: set.children,
      items: set.items,
      owner: {
        name: this.userDetailsService.getSimpleUserDetails().name,
        id: this.userDetailsService.getSimpleUserDetails().id,
        role: this.userDetailsService.getSimpleUserDetails().role,
      },
      textChunks: set.textChunks,
      dialogue: set.dialogue,
      lastModified: Date.now(),
      name: set.name + ' - copy',
      type: set.type,
      questionLang: set?.questionLang,
      answersLang: set?.answersLang,
    };
    this.sendSetToDatabase(newSet, true, null, newSet.name);
  }

  removeSet(id) {
    this.http.delete(this.url + '/api/sets/removeset/' + id).subscribe(() => {
      const updatedSets = this.allSets.filter((set) => set.id !== id);
      this.allSets = updatedSets;
      this.setsUpdated.next([...this.allSets]);
    });
    this.removeSetFromAllStudents(id);
    this.removeSetFromAllGroups(id);
  }

  openSet(id) {
    this.openSetId.next(id);
  }

  getSetsList() {
    this.http
      .get<{ sets: any }>(this.url + '/api/sets/getsetslist/')
      .pipe(
        map((sets) => {
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              parentSet: set.data?.parentSet,

              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((sets) => {
        this.allSets = sets;
        this.setsUpdated.next(this.allSets);
      });
  }
  findMultipleSets(ids: string[]) {
    const idsArray = ids;

    this.http
      .post(this.url + '/api/sets/find-multiple-sets/', { ids: idsArray })
      .subscribe((sets: any) => {
        this.allSets = sets;

        this.multipleSetsFound.next(this.allSets);
      });
  }
  selectTitleImage(image) {
    this.imageSelected.next(image);
  }
  getAllTags() {
    this.http.get(this.url + '/api/sets/get-all-tags').subscribe((tags) => {
      console.log(
        '🚀 ~ file: sets.service.ts:661 ~ SetsService ~ this.http.get ~ tags:',
        tags,
      );
      this.allTags = tags;
      this.allTagsSub.next(this.allTags);
    });
  }
  getAllSetst(forCategorisation?) {
    this.http
      .get(this.url + '/api/sets/getallsets/')
      .pipe(
        map((sets: any) => {
          this.allTags = sets.allTags;
          console.log(
            '🚀 ~ file: sets.service.ts ~ line 532 ~ SetsService ~ map ~ this.allTags',
            this.allTags,
          );
          return sets.sets.map((set) => {
            return {
              id: set?._id,
              name: set.data?.name,
              added: set.data?.added,
              items: set.data?.items,
              owner: set.data?.owner,
              lastModified: set.data?.lastModified,
              simpleWords: set.data?.simpleWords,
              textChunks: set.data?.textChunks,
              dialogue: set.data?.dialogue,

              children: set.data?.children,
              type: set.data?.type,
              questionLang: set.data?.questionLang,
              answersLang: set.data?.answersLang,
              turboMain: set.data?.turboMain,
              allGoodAnswers: set.data?.allGoodAnswers,
              grammarTextSetType: set.data?.grammarTextSetType,
              frontImage: set.data?.frontImage,
              illustrationImage: set.data?.illustrationImage,
              paths: set.data?.paths,
              tags: set.data?.tags,
              parentSet: set.data?.parentSet,

              missingWords: set.data?.missingWords,
            };
          });
        }),
      )
      .subscribe((sets) => {
        if (forCategorisation) {
          this.allSets = sets;
          this.allSets = this.allSets.filter((set) => set?.paths == undefined);
        } else {
          this.allSets = sets;
        }
        this.setsUpdated.next(this.allSets);
      });
  }
  getAllSetsWithPagination(
    page: number,
    pageSize: number,
    forCategorisation?: boolean,
    tab?: string,
  ) {
    const params = new HttpParams()
      .set('page', String(page))
      .set('pageSize', String(pageSize))
      .set('tab', tab);

    this.http
      .get(this.url + '/api/sets/getallsetswithpagination/', { params })
      .pipe(
        map((response: any) => {
          this.numberOfSets = response.totalCount;
          console.log(
            '🚀 ~ file: sets.service.ts:707 ~ SetsService ~ map ~ this.numberOfSets:',
            this.numberOfSets,
          );
          const sets = response.sets;
          this.allTags = response.allTags;


          return sets.map((set) => ({
            id: set?._id,
            name: set.data?.name,
            added: set.data?.added,
            items: set.data?.items,
            owner: set.data?.owner,
            lastModified: set.data?.lastModified,
            simpleWords: set.data?.simpleWords,
            textChunks: set.data?.textChunks,
            dialogue: set.data?.dialogue,
            children: set.data?.children,
            type: set.data?.type,
            questionLang: set.data?.questionLang,
            answersLang: set.data?.answersLang,
            turboMain: set.data?.turboMain,
            allGoodAnswers: set.data?.allGoodAnswers,
            grammarTextSetType: set.data?.grammarTextSetType,
            frontImage: set.data?.frontImage,
            illustrationImage: set.data?.illustrationImage,
            paths: set.data?.paths,
            tags: set.data?.tags,
            parentSet: set.data?.parentSet,
            missingWords: set.data?.missingWords,
          }));
        }),
      )
      .subscribe((sets) => {
        if (forCategorisation) {
          this.allSets = sets.filter((set) => set?.paths == undefined);
        } else {
          this.allSets = sets;
        }

        this.setsUpdated.next(this.allSets);
      });
  }

  passChangedText(text) {
    this.textChanged.next(text);
  }

  updateSetsList() {
    this.setsUpdated.next(this.allSets);
  }

  updateSetsListsGroup(group) {
    this.setsUpdated.next({ group: group });
  }

  passSetName(name) {
    this.setName.next(name);
  }

  passButtonClicked(buttonText) {
    this.buttonClicked.next(buttonText);
  }

  upadateBadgesData(badgesData) {
    this.badgesData.next(badgesData);
  }

  setMenuMode(mode) {
    this.menuMode.next(mode);
  }
  foundSetsByPathListener() {
    return this.foundSetByPath.asObservable();
  }
  multipleSetsFoundListener() {
    return this.multipleSetsFound.asObservable();
  }
  textChangedListener() {
    return this.textChanged.asObservable();
  }

  buttonClickedListener() {
    return this.buttonClicked.asObservable();
  }

  foundSetListener() {
    return this.foundSetUpdated.asObservable();
  }

  menuModeListener() {
    return this.menuMode.asObservable();
  }

  searchResultsListener() {
    return this.searchResults.asObservable();
  }

  badgesDataListener() {
    return this.badgesData.asObservable();
  }

  setNameListener() {
    return this.setName.asObservable();
  }
  imageSelectedListener() {
    return this.imageSelected.asObservable();
  }
  setsUpdatedListener() {
    return this.setsUpdated.asObservable();
  }

  openSetListener() {
    return this.openSetId.asObservable();
  }

  foundWordByIdListener() {
    return this.foundWordByIdSub.asObservable();
  }

  currentSetNameListener() {
    return this.currentSetName.asObservable();
  }
  allTagsSubListener() {
    return this.allTagsSub.asObservable();
  }
  setsForLessonUpdatedListener() {
    return this.setsForLessonUpdated.asObservable();
  }

  setCreatedListener() {
    return this.setCreated.asObservable();
  }
}
