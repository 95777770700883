import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-video-intro-pictures-background',
  templateUrl: './video-intro-pictures-background.component.html',
  styleUrls: ['./video-intro-pictures-background.component.scss']
})
export class VideoIntroPicturesBackgroundComponent implements OnInit {
  audio = new Audio();
  @Input() allImages = [

  ];
  images = [];
  selectionInterval
  constructor(
    private helpers: HelpersService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (changes.allImages && changes.allImages.currentValue && changes.allImages.currentValue?.length > 0) {
      let imgs = this.helpers.clone(changes.allImages.currentValue);
      imgs = this.helpers.removeDuplicatesSingle(imgs);
      const shuffledImages = this.helpers.shuffle(imgs);
      let selectedImages = shuffledImages.slice(0, 20);

      selectedImages.forEach((img, index) => {
        // Convert blob to URL
        const imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(img));
        this.images.push({ image: imageUrl, selected: false });
      });

    }
  }

  ngOnInit() {
    // for (let i = 0; i < 20; i++) {
    //   this.images.push({ image: `https://linget-pictures.fra1.digitaloceanspaces.com/1597176017649icon-1597175998138.jpg`, selected: false });
    // }
    this.audio.loop = false;
    // set time of the audio to 5s
    this.audio.currentTime = 0;
    this.audio.src = '/assets/sounds/positive_fuse.mp3';
    this.audio.load();
    this.audio.play();
    this.audio.volume = 0.1;
    // add fading out from 24th second
    this.audio.addEventListener('timeupdate', () => {
      if (this.audio.currentTime > 24) {
        const fadeDuration = 20; // Adjust the fade duration as needed
        const maxVolume = 0.1; // Adjust the maximum volume as needed
        const fadeOutVolume = maxVolume - ((this.audio.currentTime - 24) / fadeDuration);
        this.audio.volume = Math.max(fadeOutVolume, 0);
      }
    });
    setTimeout(() => {
      this.startRandomSelection();
    }, 1000);
  }

  startRandomSelection() {
    this.selectionInterval = setInterval(() => {
      this.selectRandomImage();
    }, 1300); // Change image every 1 second (adjust as needed)
  }
  selectRandomImage() {
    // Filter uncolored images
    const uncoloredImages = this.images.filter(img => !img.colored);

    // If all images are colored, stop the interval
    if (uncoloredImages.length === 0) {
      clearInterval(this.selectionInterval);

      return;
    }

    // Select a random uncolored image
    const randomIndex = Math.floor(Math.random() * uncoloredImages.length);
    const selectedImage = uncoloredImages[randomIndex];

    // Mark the image as selected, colored, and apply the colorTransition class
    this.images.forEach(img => img.selected = false); // Deselect all images
    selectedImage.selected = true;
    selectedImage.colored = true;
    selectedImage.colorTransition = true;

    // Remove the colorTransition class after the animation completes
    setTimeout(() => {
      selectedImage.colorTransition = false;
    }, 1000); // Adjust this timeout to match your CSS animation duration
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.selectionInterval);
    this.audio.pause();
  }
}
