import { Component, NgZone, OnInit } from '@angular/core';
import { UserDetailsService } from '../../user-details.service';
import { DatePipe } from '@angular/common';
import { UsersServiceService } from '../../../shared/services/users-service.service';
import { WorkersAvatarsService } from 'src/app/shared/services/workers-avatars.service';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FinancesStatsService } from '../../owner/finances-stats/finances-stats.service';

@Component({
  selector: 'app-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.css']
})
export class WorkHoursComponent implements OnInit {
  selectedMonth: number = new Date().getMonth() + 1;
  selectedYear: number = new Date().getFullYear();
  daysArray: number[];
  cleaningMode = false;
  simpleuserDetails: any;
  workerHours: { [key: number]: { fullHours: number; lessonHours: number } } = {};
  dayInputEnabled: { [key: number]: boolean } = {};
  monthNames: string[] = [null, 'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
  titleText = 'Godziny realizacji umowy cywilnoprawnej';
  allWorkingHours = []
  workers = []
  selectedWorker
  avalibility = ''
  editAvalibility = false
  customName = ''
  sub: any;
  sub2: any;
  constructor(
    public userDetailsService: UserDetailsService, private datePipe: DatePipe,
    private usersService: UsersServiceService, private workersAvatarsService: WorkersAvatarsService,
    private financesStatsService: FinancesStatsService,
    private ngZone: NgZone) {
    this.updateDaysArray();
  }

  ngOnInit(): void {
    this.simpleuserDetails = this.userDetailsService.getSimpleUserDetails();

    this.selectedWorker = this.simpleuserDetails



    this.usersService.getWorkingHours(this.selectedWorker.id)
    this.usersService.getAvailability(this.selectedWorker.id)
    this.sub = this.usersService.workersHoursUpdatedListener().subscribe((data) => {
      if (data.data) {
        this.allWorkingHours = data.data
        this.usersService.getAvailability(this.selectedWorker.id)

        const workerHours2 = this.allWorkingHours.find(x => x.month == `${this.selectedMonth % 12}/${this.selectedYear}`)?.hours
        if (workerHours2) {
          this.workerHours = workerHours2
        }
        else {
          this.workerHours = {}
        }
      }
      else {
        this.workerHours = {}
      }
    })
    this.sub2 = this.usersService.avalibilityUpdatedListener().subscribe((data) => {


      this.avalibility = data || ''

    })

    this.workers = this.workersAvatarsService.workersWithAvatars.filter(
      (worker) => worker.priority != 0
    );

    this.allWorkingHours = this.userDetailsService.getUserDetails().userData.workingHours
    const workerHours = this.allWorkingHours.find(x => x.month == `${this.selectedMonth % 12}/${this.selectedYear}`)?.hours
    if (workerHours) {
      this.workerHours = workerHours
    }
    else {
      this.workerHours = {}
    }    // this.simpleuserDetails = { name: 'Jan Kowalski' }

  }

  saveAvalibility() {
    if (!this.editAvalibility) {

      this.usersService.updateAvailability(this.selectedWorker.id, this.avalibility)
    }
  }
  onWorkerChange() {
    this.customName = this.selectedWorker.name
    this.usersService.getWorkingHours(this.selectedWorker.id)
    this.avalibility = ''
    this.usersService.getAvailability(this.selectedWorker.id)

  }
  checkSalaries() {
    // 
    // let salaryPerHour = this.financesStatsService.teachersCosts.find(x => x.name == this.selectedWorker.name)?.value
    // 
    // const fullHoursNumber = Object.values(this.workerHours).reduce((acc, curr) => acc + curr.fullHours, 0)
    // 
    // const lessonHoursNumber = Object.values(this.workerHours).reduce((acc, curr) => acc + curr.lessonHours, 0)
    // 
    // const salaryPerLessonHour = salaryPerHour * 0.75
    // console.log("🚀 ~ file: work-hours.component.ts:145 ~ WorkHoursComponent ~ checkSalaries ~ salaryPerLessonHour", salaryPerLessonHour
    // )
    // const salaryPerLessonHours = lessonHoursNumber * salaryPerLessonHour
    // console.log("🚀 ~ file: work-hours.component.ts:147 ~ WorkHoursComponent ~ checkSalaries ~ salaryPerLessonHours", salaryPerLessonHours
    // )
    // const salaryPerFullHours = fullHoursNumber * salaryPerHour
    // console.log("🚀 ~ file: work-hours.component.ts:149 ~ WorkHoursComponent ~ checkSalaries ~ salaryPerFullHours", salaryPerFullHours
    // )
    // const total = salaryPerFullHours + salaryPerLessonHours
    // 
    // alert(`Wynagrodzenie za godziny pełne: ${salaryPerFullHours} zł, wynagrodzenie za godziny lekcyjne: ${salaryPerLessonHours} zł, łącznie: ${total} zł`)
  }
  getDayOfWeek2(day: number): string {
    const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
    return this.datePipe.transform(date, 'EEEE');
  }
  getDayOfWeek(day: number): string {
    const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
    const dayOfWeek = date.getDay();
    const polishDaysOfWeek = ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'];
    return polishDaysOfWeek[dayOfWeek];
  }

  onYearMonthChange(): void {
    this.updateDaysArray();
  }
  print() {
    window.print();
  }
  padWithZero(number: number): string {
    return number.toString().padStart(2, '0');
  }
  isWeekend(day: number): boolean {
    const date = new Date(this.selectedYear, this.selectedMonth - 1, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 for Sunday and 6 for Saturday
  }
  removeHours() {
    if (confirm('Czy na pewno chcesz usunąć godziny?')) {

      this.usersService.removeWorkingHours(this.selectedWorker.id)
    }
  }
  updateDaysArray(): void {
    const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    this.daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const workerHours = this.allWorkingHours.find(x => x.month == `${this.selectedMonth % 12}/${this.selectedYear}`)?.hours
    if (workerHours) {
      this.workerHours = workerHours
    }
    else {
      this.workerHours = {}
    }

  }
  changeMode() {
    this.cleaningMode = !this.cleaningMode;
    this.titleText = this.cleaningMode ? 'Ilość realizacji umowy cywilnoprawnej (sprzątanie lokalu)' : 'Godziny realizacji umowy cywilnoprawnej';
  }
  saveHours() {
    const month = `${this.selectedMonth % 12}/${this.selectedYear}`;
    this.usersService.updateWorkingHours(this.selectedWorker.id, month, this.workerHours)
  }
  toggleInput(day: number): void {
    this.dayInputEnabled[day] = !this.dayInputEnabled[day];

    if (!this.workerHours[day]) {
      this.workerHours[day] = {
        fullHours: 0,
        lessonHours: 0
      };
    }
    if (this.dayInputEnabled[day] == false) {
      this.saveHours()
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe()
    this.sub2.unsubscribe()
  }
}
