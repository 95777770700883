<h2>{{ data?.name }}</h2>
<button
  (click)="openCategorizeSetDialog()"
  *ngIf="userDetailsService.owner"
  class="ml-3"
  color="primary"
  mat-stroked-button
  matTooltip="Categorize set & edit tags"
>
  <mat-icon aria-label="label">category</mat-icon>Categorize & tags
</button>
<button
  (click)="showSetContent = !showSetContent"
  class="ml-3"
  color="primary"
  mat-stroked-button
  matTooltip="load set content"
>
  <mat-icon aria-label="label">category</mat-icon>Load set content
</button>
<button mat-raised-button (click)="dialogRef.close()" color="primary">
  Zamknij
</button>
<app-set-content
  *ngIf="
    !data.set?.parentSet &&
    data.type !== 'free' &&
    data?.set?.type !== 'free' &&
    showSetContent
  "
  [_setId]="data.id"
></app-set-content>
<app-set-content
  *ngIf="
    data.set?.parentSet &&
    data.type !== 'free' &&
    data?.set?.type !== 'free' &&
    showSetContent
  "
  [user]="user"
  [_setId]="data.set.parentSet"
></app-set-content>
<app-set-content
  *ngIf="showSetContent && (data.set?.type === 'free' || data.type == 'free')"
  [hideWrongAnswers]="true"
  [user]="user"
  [freeSet]="data.set ? data.set : data"
></app-set-content>
