import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GenerateAudioService } from 'src/app/shared/services/database/generate-audio.service';
import { ReadTextService } from 'src/app/shared/services/read-text.service';

@Component({
  selector: 'app-audio-repo',
  templateUrl: './audio-repo.component.html',
  styleUrls: ['./audio-repo.component.scss']
})
export class AudioRepoComponent implements OnInit {
  textToSearch = ''

  genders = ['male', 'female']
  selectedGender = ''
  textToRecord = ''
  selectedLanguage = ''
  selectedVoice = ''
  selectedEmotion = 'Neutral'
  voicesToChoose = []
  audiosList = []
  voices = {
    english: {
      male: ["en-US-BrandonNeural", 'en-US-RogerNeural', "en-US-ChristopherNeural", "en-US-EricNeural", "en-US-GuyNeural", "en-US-JacobNeural"],
      // male: [
      //   "en-US-GuyNeural",
      //   "en-US-DavisNeural",
      //   "en-US-TonyNeural",
      //   "en-US-JasonNeural",
      //   "en-US-RogerNeural"
      // ],
      female: ["en-US-AmberNeural", "en-US-AshleyNeural", "en-US-CoraNeural", "en-US-ElizabethNeural", "en-US-JennyNeural", "en-US-MichelleNeural", "en-US-MonicaNeural", "en-US-SaraNeural"]
      // female: [
      //   "en-US-JennyMultilingualNeural",
      //   "en-US-JennyNeural",
      //   "en-US-AriaNeural",
      //   "en-US-SaraNeural",
      //   "en-US-JaneNeural",
      //   "en-US-NancyNeural",
      //   "en-US-MichelleNeural",
      // ]
    },

    german: {
      male: ["de-DE-ConradNeural", "de-DE-KillianNeural"],
      female: ["de-DE-KatjaNeural", "de-DE-ElkeNeural", "de-DE-GiselaNeural"]
    },
    spanish: {
      male: ["es-MX-JorgeNeural"],
      female: ["es-MX-DaliaNeural"]
    },
    norwegian: {
      male: ["nb-NO-FinnNeural"],
      female: ["nb-NO-PernilleNeural", "nb-NO-IselinNeural"]
    },
    french: {
      male: ["fr-FR-PaulNeural"],
      female: ["fr-FR-JulieNeural"]
    },
    polish: {
      male: ["pl-PL-MarekNeural"],
      female: ["pl-PL-AgnieszkaNeural", "pl-PL-ZofiaNeural"]
    }
  };
  emotions = [
    "cheerful",
    "sad",
    "angry",
    "empathetic",
    "excited",
    "friendly",
    "terrified",
    "shouting",
    "unfriendly",
    "whispering"
  ];

  languages = []
  speeds = ['-20%', '-10%', '0%', '+10%', '+20%']
  selectedSpeed = '0%'
  sub: any;
  sub2: any;

  constructor(
    private generateAudioService: GenerateAudioService,
    private readTextService: ReadTextService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }



  ngOnInit() {
    this.extractLanguagesFromVoices()
    this.sub = this.generateAudioService.getAudiosRepoListListener().subscribe((audiosList: any) => {


      this.audiosList = audiosList.audios.filter(audio =>
        audio?.data?.id > 0

      )
      this.audiosList = this.audiosList.map(audio => {

        return audio.data
      }
      )
      this.audiosList.forEach(audio => {
        audio.fileUrl = this.getAudioUrl(audio)
      }
      )

    })
    this.sub2 = this.generateAudioService.savedFreeAudioItemListener().subscribe((audio) => {

      this.getLatestAudios()
    }
    )
    this.getLatestAudios()
  }
  copyAudioUrl(audio) {
    navigator.clipboard.writeText('https://backend-int.linget.it/api/read/getaudiodata?url=' + audio.url)

  }
  recordAudio() {

    this.generateAudioService.saveNewAudioToRepo(null, this.textToRecord, this.selectedLanguage, this.selectedGender, this.selectedVoice, this.selectedEmotion, null, this.selectedSpeed)

  }
  getAudioUrl(audio) {
    let url = 'https://backend-int.linget.it/api/read/getaudiodata?url=' + audio.url


    this.http.get(url, { responseType: 'blob' }).pipe(
      catchError((error) => {
        console.error('Error occurred while downloading:', error);
        return throwError(error);
      })
    ).subscribe(
      (res) => {

        const blob = new Blob([res], { type: 'audio/mp3' });
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
        audio.fileUrl = url

      },
      (error) => {
        console.error('Error occurred while downloading:', error);
      }
    );
  }

  fillInVoices() {
    if (this.selectedGender === '' || this.selectedLanguage === '') {
      return
    }
    this.voicesToChoose = this.voices[this.selectedLanguage][this.selectedGender]
  }
  extractLanguagesFromVoices() {
    for (const language in this.voices) {
      this.languages.push(language)
    }

  }
  searchAudio() {
    this.generateAudioService.findAudioByText(this.textToSearch)
  }
  getLatestAudios() {
    this.generateAudioService.getLatestAudiosFromRepo()
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe()
    this.sub2.unsubscribe()
  }
}
