import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VersionService } from 'src/app/shared/services/version.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface TeacherCost {
  name: string;
  value: number;
}

export interface FinanceItem {
  name: string;
  value: number;
  type: string;
  company: boolean;
  teacher?: boolean;
}

export interface StateItem {
  name: string;
  value: number;
}

export interface FinanceData {
  teachersCosts: TeacherCost[];
  incomes: FinanceItem[];
  expenses: FinanceItem[];
  states: StateItem[];
}

@Injectable({
  providedIn: 'root'
})
export class FinancesStatsService {
  private financeDataSubject = new BehaviorSubject<FinanceData>(null);
  private url: string;

  // Default data as fallback
  private defaultFinanceData: FinanceData = {
    teachersCosts: [
      { name: 'Adrianna Butka', value: 45 },
      { name: 'Wiktoria Zakrzewska', value: 55 },
      { name: 'Weronika Oczki', value: 49 },
      { name: 'Joanna Staniszewska', value: 49 },
      { name: 'Agnieszka Jacek', value: 60 },
      { name: 'Krzysztof Jacek', value: 0 },
      { name: 'Karolina Frasz', value: 61.5 },
      { name: 'Nicole Hojnowska', value: 45 },
      { name: 'Paulina Galant', value: 40 },
      { name: 'Anna Pokorska', value: 40 },
    ],
    incomes: [
      { name: 'Cykliczne', value: 47500, type: 'monthly', company: true },
      { name: 'zasiłki', value: 3200, type: 'monthly', company: false },
      { name: 'ubezpieczenie', value: 5000, type: 'fixed', company: false },
      { name: 'Karolina (Zastępstwo)', value: 5800, type: 'fixed', company: false },
      { name: 'Wiktoria (Zastępstwo)', value: 3300, type: 'fixed', company: false },
    ],
    expenses: [
      { name: 'Sekretarka', value: 4500, type: 'monthly', company: true },
      { name: 'Lokal Szwederowo', value: 2600, type: 'monthly', company: true },
      { name: 'Lokal', value: 2150, type: 'monthly', company: true },
      { name: 'ZUS', value: 1400, type: 'monthly', company: true },
      { name: 'Podatek', value: 3500, type: 'monthly', company: true },
      { name: 'Rachunki', value: 500, type: 'monthly', company: true },
      { name: 'Leasing', value: 2300, type: 'monthly', company: false },
      { name: 'Paliwo', value: 350, type: 'monthly', company: false },
      { name: 'Telefony', value: 250, type: 'monthly', company: true },
      { name: 'Sprzątanie', value: 300, type: 'monthly', company: true },
      { name: 'Śmieci', value: 200, type: 'monthly', company: true },
      { name: 'Szkolenie lektorek', value: 350, type: 'monthly', company: true },
      { name: 'Księgowość', value: 500, type: 'monthly', company: true },
      { name: 'Aplikacja', value: 1500, type: 'monthly', company: true },
      { name: 'Inwestycje/Naprawy', value: 600, type: 'monthly', company: true },
      { name: 'Zdrowie', value: 600, type: 'monthly', company: true },
      { name: 'Koszty domowe', value: 5000, type: 'monthly', company: false },
      { name: 'Inne wydatki', value: 3000, type: 'monthly', company: false },
    ],
    states: [
      { name: 'Konto', value: 15500 },
      { name: 'Gotówka', value: 0 },
      { name: 'Niezapłacone', value: 15000 },
      { name: 'Strata', value: 0 },
    ],
  };

  constructor(
    private http: HttpClient,
    private versionService: VersionService,
    private authService: AuthenticationService,
    private snackbarService: SnackbarService
  ) {
    this.url = this.versionService.url;
  }

  // Get finance data from the backend
  getFinanceData() {
    this.http
      .get(this.url + '/api/finances/getfinancedata', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => response?.financeData || null),
        catchError(error => {
          console.error('Error fetching finance data:', error);
          this.snackbarService.showSnackBar('Could not connect to the server. Using local data.', 'error');
          return of(null);
        })
      )
      .subscribe(financeData => {
        if (financeData) {
          this.financeDataSubject.next({
            teachersCosts: financeData.teachersCosts || [],
            incomes: financeData.incomes || [],
            expenses: financeData.expenses || [],
            states: financeData.states || []
          });
        } else {
          // Use default data if backend request fails
          this.financeDataSubject.next(this.defaultFinanceData);
        }
      });
  }

  // Update finance data in the backend
  updateFinanceData(financeData: Partial<FinanceData>): Observable<any> {
    return this.http
      .put(this.url + '/api/finances/updatefinancedata', financeData, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => {
          if (response?.financeData) {
            this.financeDataSubject.next({
              teachersCosts: response.financeData.teachersCosts || [],
              incomes: response.financeData.incomes || [],
              expenses: response.financeData.expenses || [],
              states: response.financeData.states || []
            });
          }
          return response;
        }),
        catchError(error => {
          console.error('Error updating finance data:', error);
          this.snackbarService.showSnackBar('Could not update finance data on the server.', 'error');
          return of({ error: 'Failed to update finance data' });
        })
      );
  }

  // Get the finance data as an observable
  getFinanceDataListener(): Observable<FinanceData> {
    return this.financeDataSubject.asObservable();
  }

  // Get the current finance data value
  getCurrentFinanceData(): FinanceData {
    return this.financeDataSubject.getValue() || this.defaultFinanceData;
  }

  // Get teacher costs
  getTeacherCosts(): Observable<TeacherCost[]> {
    return this.http
      .get(this.url + '/api/finances/getteachercosts', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => {

          const teacherCosts = response?.teachersCosts || [];

          return teacherCosts;
        }),
        catchError(error => {
          console.error('Error fetching teacher costs:', error);
          this.snackbarService.showSnackBar('Could not fetch teacher costs. Using default data.', 'error');
          return of(this.defaultFinanceData.teachersCosts);
        })
      );
  }

  // Add a teacher cost
  addTeacherCost(teacherCost: TeacherCost): Observable<any> {
    return this.http
      .post(this.url + '/api/finances/addteachercost', teacherCost, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => {
          if (response?.financeData) {
            this.financeDataSubject.next({
              teachersCosts: response.financeData.teachersCosts || [],
              incomes: response.financeData.incomes || [],
              expenses: response.financeData.expenses || [],
              states: response.financeData.states || []
            });
            // After adding, refresh the teacher costs
            this.getTeacherCosts().subscribe();
          }
          return response;
        }),
        catchError(error => {
          console.error('Error adding teacher cost:', error);
          this.snackbarService.showSnackBar('Could not add teacher cost.', 'error');
          return of({ error: 'Failed to add teacher cost' });
        })
      );
  }

  // Update a teacher cost
  updateTeacherCost(index: number, teacherCost: TeacherCost): Observable<any> {
    return this.http
      .put(this.url + `/api/finances/updateteachercost/${index}`, teacherCost, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => {
          if (response?.financeData) {
            this.financeDataSubject.next({
              teachersCosts: response.financeData.teachersCosts || [],
              incomes: response.financeData.incomes || [],
              expenses: response.financeData.expenses || [],
              states: response.financeData.states || []
            });
            // After updating, refresh the teacher costs
            this.getTeacherCosts().subscribe();
          }
          return response;
        }),
        catchError(error => {
          console.error('Error updating teacher cost:', error);
          this.snackbarService.showSnackBar('Could not update teacher cost.', 'error');
          return of({ error: 'Failed to update teacher cost' });
        })
      );
  }

  // Delete a teacher cost
  deleteTeacherCost(index: number): Observable<any> {
    return this.http
      .delete(this.url + `/api/finances/deleteteachercost/${index}`, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` }
      })
      .pipe(
        map((response: any) => {
          if (response?.financeData) {
            this.financeDataSubject.next({
              teachersCosts: response.financeData.teachersCosts || [],
              incomes: response.financeData.incomes || [],
              expenses: response.financeData.expenses || [],
              states: response.financeData.states || []
            });
            // After deleting, refresh the teacher costs
            this.getTeacherCosts().subscribe();
          }
          return response;
        }),
        catchError(error => {
          console.error('Error deleting teacher cost:', error);
          this.snackbarService.showSnackBar('Could not delete teacher cost.', 'error');
          return of({ error: 'Failed to delete teacher cost' });
        })
      );
  }

  // Add an income
  addIncome(income: FinanceItem): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedIncomes = [...currentData.incomes, income];

    return this.updateFinanceData({ incomes: updatedIncomes });
  }

  // Update an income
  updateIncome(index: number, income: FinanceItem): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedIncomes = [...currentData.incomes];
    updatedIncomes[index] = income;

    return this.updateFinanceData({ incomes: updatedIncomes });
  }

  // Delete an income
  deleteIncome(index: number): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedIncomes = currentData.incomes.filter((_, i) => i !== index);

    return this.updateFinanceData({ incomes: updatedIncomes });
  }

  // Add an expense
  addExpense(expense: FinanceItem): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedExpenses = [...currentData.expenses, expense];

    return this.updateFinanceData({ expenses: updatedExpenses });
  }

  // Update an expense
  updateExpense(index: number, expense: FinanceItem): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedExpenses = [...currentData.expenses];
    updatedExpenses[index] = expense;

    return this.updateFinanceData({ expenses: updatedExpenses });
  }

  // Delete an expense
  deleteExpense(index: number): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedExpenses = currentData.expenses.filter((_, i) => i !== index);

    return this.updateFinanceData({ expenses: updatedExpenses });
  }

  // Update a state
  updateState(index: number, state: StateItem): Observable<any> {
    const currentData = this.getCurrentFinanceData();
    const updatedStates = [...currentData.states];
    updatedStates[index] = state;

    return this.updateFinanceData({ states: updatedStates });
  }
}
