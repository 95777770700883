<h2 mat-dialog-title>Importuj program nauczania z JSON</h2>

<mat-dialog-content class="mat-typography">
  <div class="import-container">
    <div class="row">
      <div class="col-12">
        <p class="format-explanation">
          Wprowadź program nauczania w formacie JSON. Program powinien zawierać
          następujące pola:
        </p>
        <ul class="format-list">
          <li><strong>name</strong> - nazwa programu nauczania</li>
          <li>
            <strong>units</strong> - tablica jednostek programu, każda z polami:
            <ul>
              <li><strong>topic</strong> - temat rozdziału</li>
              <li><strong>subjectArea</strong> - obszar tematyczny</li>
              <li><strong>subjectSubArea</strong> - podrozdział obszaru</li>
              <li><strong>functions</strong> - funkcje językowe</li>
              <li><strong>vocabularyTopic</strong> - temat słownictwa</li>
              <li><strong>vocabularyList</strong> - lista słownictwa</li>
              <li>
                <strong>grammar</strong> - tablica elementów gramatycznych
              </li>
              <li>
                <strong>communicativeSkills</strong> - tablica umiejętności
                komunikacyjnych
              </li>
              <li>
                <strong>receptiveSkills</strong> - tablica umiejętności
                receptywnych
              </li>
              <li>
                <strong>productiveSkills</strong> - tablica umiejętności
                produktywnych
              </li>
              <li>
                <strong>interactiveSkills</strong> - tablica umiejętności
                interaktywnych
              </li>
              <li>
                <strong>processingSkills</strong> - tablica umiejętności
                przetwarzania
              </li>
              <li>
                <strong>otherSkills</strong> - tablica innych umiejętności
              </li>
              <li>
                <strong>coreStandards</strong> - tablica odniesień do podstawy
                programowej
              </li>
              <li><strong>listening</strong> - ćwiczenia ze słuchania</li>
              <li><strong>reading</strong> - ćwiczenia z czytania</li>
              <li><strong>speaking</strong> - ćwiczenia z mówienia</li>
              <li><strong>writing</strong> - ćwiczenia z pisania</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="full-width">
          <mat-label>Zawartość JSON</mat-label>
          <textarea
            matInput
            [(ngModel)]="jsonContent"
            rows="12"
            placeholder="Wprowadź dane w formacie JSON"
            class="json-textarea"
          ></textarea>
        </mat-form-field>

        <!-- Show message when repair has been attempted -->
        <div *ngIf="repairAttempted" class="repair-message">
          <mat-icon color="accent">build</mat-icon>
          <span>Podjęto próbę naprawy błędów składniowych w formacie JSON</span>
          <button mat-button color="warn" (click)="revertChanges()">
            <mat-icon>restore</mat-icon> Przywróć oryginalny tekst
          </button>
        </div>
      </div>
    </div>

    <div class="example-section">
      <div class="example-header">
        <h3>Przykładowy format</h3>
        <button mat-stroked-button color="primary" (click)="useExample()">
          Użyj przykładu
        </button>
      </div>
      <pre class="example-json">{{ exampleJson }}</pre>
    </div>

    <div class="repair-info">
      <h3>Automatyczna naprawa błędów</h3>
      <p>
        System automatycznie próbuje naprawić typowe błędy składniowe JSON,
        takie jak:
      </p>
      <ul>
        <li>Brakujące cudzysłowy wokół nazw właściwości</li>
        <li>Nieprawidłowe użycie apostrofów zamiast cudzysłowów</li>
        <li>Zbędne przecinki na końcu list i obiektów</li>
        <li>Brakujące nawiasy (zarówno klamrowe jak i kwadratowe)</li>
        <li>Brakujące cudzysłowy wokół wartości tekstowych</li>
        <li>Niedomknięte tablice i obiekty</li>
        <li>Brakujące przecinki między elementami</li>
        <li>Niezbilansowane nawiasy (nadmiarowe nawiasy zamykające)</li>
        <li>Brakujące dwukropki między kluczami i wartościami</li>
      </ul>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Anuluj</button>
  <button
    mat-raised-button
    color="primary"
    (click)="onImport()"
    [disabled]="!jsonContent"
  >
    Importuj
  </button>
</mat-dialog-actions>
