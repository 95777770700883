import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { LessonActivitiesService } from '../../../../shared/services/lesson-activities.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  animateChild,
} from '@angular/animations';

import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { FormBuilder } from '@angular/forms';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPictureComponent } from 'src/app/database/pictures/add-picture/add-picture.component';
import { PictureFinderComponent } from 'src/app/shared/components/picture-finder/picture-finder.component';
import { BigPictureComponent } from '../../../../database/pictures/big-picture/big-picture.component';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GoogleService } from 'src/app/shared/services/database/google.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { GenerateAudioService } from 'src/app/shared/services/database/generate-audio.service';
import { finished } from 'stream';
import { threadId } from 'worker_threads';
import { DigitalOceanService } from 'src/app/shared/services/digitalocean.service';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UsersServiceService } from 'src/app/shared/services/users-service.service';

@Component({
  selector: 'app-lesson-activities-add-edit',
  templateUrl: './lesson-activities-add-edit.component.html',
  styleUrls: ['./lesson-activities-add-edit.component.css'],
  animations: [
    trigger('rotateAnimation', [
      state(
        'normal',
        style({
          transform: 'rotate(0deg)',
          width: '100%',
          height: '100%',
        })
      ),
      state(
        'rotated',
        style({
          transform: 'rotate(90deg) translateY(-100%)',
          transformOrigin: 'top left',
          width: '100vh',
          height: '100vw',
          position: 'absolute',
          top: '0',
          left: '0',
        })
      ),
      transition('normal => rotated', animate('500ms ease')),
      transition('rotated => normal', animate('500ms ease')),
    ]),
  ],
})
export class LessonActivitiesAddEditComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('angularEditor') angularEditor: AngularEditorComponent;

  activityPictures = [];
  activityPdfs = [];
  addOnBlur = true;
  activityData;
  showAudio = true
  isRotated = false;
  pdfZoom = 1;
  mode = '';

  hasForTrainingTag(): boolean {
    return this.activityTags?.some(tag => tag.tag === 'forTraining');
  }
  descriptionAudio
  activitiesPicturesIds = [];
  audioDurtion = 0
  audioProgressPercentage = 0
  activitiesVideosIds = [];
  activitiesPdfsIds = [];
  activitiesAudios;
  activitiesFrequency = 0
  parseddActivityPdfs = null;
  zoomScale: 'page-width' | 'page-height' | 'page-fit' = 'page-fit';
  type: 'activities' | 'training' | 'app' | 'theory' | 'office' | 'recordedLesson';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    uploadWithCredentials: false,
    defaultFontName: 'Arial',
    defaultFontSize: '3',
    upload: (file) => this.uploadImage(file),
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
      ],
    ]
  };
  editMode = false;
  isRecording = false;
  usersTrackingData = [];
  trackingData
  addMode = false;
  activityId = null;
  activityName: string = '';
  private _activityDescription: string = '';

  private addPageBreakClassToHrTags(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Add class to all hr tags
    const hrElements = doc.querySelectorAll('hr');
    hrElements.forEach(hr => {
      hr.classList.add('page-break');
    });

    // Return modified HTML
    return doc.body.innerHTML;
  }

  get activityDescription(): string {
    return this._activityDescription;
  }

  set activityDescription(value: string) {
    this._activityDescription = this.addPageBreakClassToHrTags(value);
    this.ref.detectChanges();
  }
  activityAuthor = { name: '', id: '', role: '' };
  activityUnfinished = true;
  steps = [];
  ageGroups = [
    {
      value: '4 lata',
      selected: false,
    },
    {
      value: 'przedszkole',
      selected: false,
    },
    {
      value: '1 klasa',
      selected: false,
    },
    {
      value: '2 klasa',
      selected: false,
    },
    {
      value: '3 klasa',
      selected: false,
    },
    {
      value: '4 klasa',
      selected: false,
    },
    {
      value: '5 klasa',
      selected: false,
    },
    {
      value: '6 klasa',
      selected: false,
    },
    {
      value: '7 klasa',
      selected: false,
    },
    {
      value: '8 klasa',
      selected: false,
    },
    {
      value: '1 klasa LT',
      selected: false,
    },
    {
      value: '2 klasa LT',
      selected: false,
    },
    {
      value: '3 klasa LT',
      selected: false,
    },
    {
      value: '4 klasa LT',
      selected: false,
    },
    {
      value: 'Dorośli',
      selected: false,
    },
  ];
  numbersOfStudents = [
    {
      value: '1 osoba',
      selected: false,
    },
    {
      value: '2 osoby',
      selected: false,
    },
    {
      value: '3 osoby',
      selected: false,
    },
    {
      value: '4 osoby',
      selected: false,
    },
    {
      value: '5 osób',
      selected: false,
    },
    {
      value: '6 osób',
      selected: false,
    },
    {
      value: '7 osób',
      selected: false,
    },
    {
      value: '8 osób',
      selected: false,
    },
    {
      value: 'więcej',
      selected: false,
    },
    {
      value: 'parzyście',
      selected: false,
    },
    {
      value: 'nieparzyście',
      selected: false,
    },
  ];
  timesOfActivity = [
    {
      value: '5 minut',
      selected: false,
    },
    {
      value: '10 minut',
      selected: false,
    },
    {
      value: '15 minut',
      selected: false,
    },
    {
      value: '20 minut',
      selected: false,
    },
    {
      value: '25 minut',
      selected: false,
    },
    {
      value: '30 minut',
      selected: false,
    },
  ];
  skills = [
    {
      value: 'Czytanie',
      selected: false,
    },
    {
      value: 'Pisanie',
      selected: false,
    },
    {
      value: 'Komunikacja',
      selected: false,
    },
    {
      value: 'Gramatyka',
      selected: false,
    },
    {
      value: 'Dowolnie',
      selected: false,
    },
  ];
  variants = [];
  hints = [];
  comments = [];
  activityVideos = [];
  activityTags = [];
  sub: any;
  showPdf: boolean = true;
  descriptionReadingTimeNeeded = 0;
  readingFinished = false;
  fileToDisplay: string;
  sub2: any;
  userDetails

  constructor(
    private lessonActivitiesService: LessonActivitiesService,
    private _bottomSheetRef: MatBottomSheetRef<LessonActivitiesAddEditComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private helpers: HelpersService,
    public userDetailsService: UserDetailsService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private chatbotsService: ChatbotsService,
    private chatService: ChatService,
    private generateAudioService: GenerateAudioService,
    private ref: ChangeDetectorRef,
    private digitaloceanService: DigitalOceanService,
    private usersService: UsersServiceService
  ) {
    // this.activityForm = this.fb.group({
    //   name: ['', Validators.required],
    //   ages: this.fb.array([], Validators.required),
    //   numberOfStudents: this.fb.array([], Validators.required),
    //   timeOfActivity: this.fb.array([], Validators.required),
    //   classrooms: this.fb.array([]),
    //   description: ['', Validators.required],
    //   skills: this.fb.array([], Validators.required),
    //   variants: this.fb.array([]),
    //   pictures: this.fb.array([]),
    //   videos: this.fb.array([]),
    //   comments: this.fb.array([]),
    // });
  }

  ngOnInit(): void {
    this.userDetails = this.userDetailsService.getUserDetails()
    this.sub2 = this.generateAudioService.savedFreeAudioItemListener().subscribe((data: any) => {
      const audioUrl = this.helpers.clone(data.url);

      // Reset current audio player and initialize with new URL
      this.resetAudioPlayer();

      // Update audio URLs
      this.data.activityData.audio = audioUrl;
      this.activitiesAudios = audioUrl;

      // Initialize new audio player
      this.initializeAudioPlayer(audioUrl);

      this.showAudio = true;
      this.ref.detectChanges();
      this.ref.detectChanges()
    }
    )

    this.sub = this.lessonActivitiesService
      .foundActivitiesUpdatedListener()
      .subscribe((data) => {
        console.log(
          '🚀 ~ file: lesson-activities-add-edit.component.ts:280 ~ LessonActivitiesAddEditComponent ~ this.sub=this.lessonActivitiesService.foundActivitiesUpdatedListener ~ data:',
          data
        );
        this.data.activityData = data;
        this.setValues();

      });
    if (!this.data.activityData && this.data.id) {
      this.lessonActivitiesService.findActivityById(this.data.id);
    }
    if (!this.data.activityData && this.data.tag) {
      this.lessonActivitiesService.findActivityByTag(this.data.tag);
    }
    this.editMode = this.data.editMode;
    this.addMode = this.data.addMode;
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 263 ~ LessonActivitiesAddEditComponent ~ ngOnInit ~ activityData',
      this.data.activityData
    );
    if (this.data.activityData) {
      this.setValues();

      if (this.activityTags.some((tag) => tag.tag == 'office')) {
        this.mode == 'office';
      }
      const textToRead = this.helpers.extractTextFromHTML(
        this.activityDescription
      );

      this.descriptionReadingTimeNeeded = textToRead.split(' ').length / 350;
      const timeForReading = this.descriptionReadingTimeNeeded * 60000;
      console.log(
        '🚀 ~ file: lesson-activities-add-edit.component.ts:339 ~ LessonActivitiesAddEditComponent ~ ngOnInit ~ timeForReading:',
        timeForReading / 60000 + 'min'
      );

      setTimeout(() => {
        this.readingFinished = true;
      }, timeForReading);
    }

    this.parseddActivityPdfs = this.activityPdfs.map((pdf) => {
      let dataToReturn = null;
      let fileId = this.extractFileIdFromLink(pdf.link);

      if (fileId && fileId !== null) {
        dataToReturn = { link: 'https://backend-dev.linget.it/api/google/get-file-by-id/' + fileId, page: 1 };
      }
      else {
        dataToReturn = { link: pdf, page: 1 };
      }


      return dataToReturn;
    });
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 299 ~ LessonActivitiesAddEditComponent ~ ngOnInit ~ this.activityPdfs',
      this.activityPdfs
    );
    this.checkType();

    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 297 ~ LessonActivitiesAddEditComponent ~ ngOnInit ~ this.type',
      this.type
    );
    const userDetails: any = this.userDetailsService.getUserDetails();

    this.activityAuthor = {
      name: userDetails.name,
      id: userDetails.id ? userDetails?.id : userDetails?._id,
      role: userDetails.role,
    };
    this.generateGoogleDriveLinks();
  }
  uploadImage(file): Observable<any> {

    return this.digitaloceanService.uploadFileToFolder(file, 'activities/', true, 'englishexpanse').pipe(
      tap(response => {





        const imageHtml = this.createImageHtml(response.downloadLink);
        this.insertHtmlAtCursor(imageHtml);
      }




      ),



      map(response => response.downloadLink)
    );
  }
  createImageHtml(imageUrl: string): string {
    return `<img src="${imageUrl}" alt="Uploaded image" style="max-height: 70vh;  height: auto;">`;
  }
  insertHtmlAtCursor(html: string): void {
    if (this.angularEditor && this.angularEditor.textArea) {
      const editorElement = this.angularEditor.textArea.nativeElement;

      // Focus the editor
      editorElement.focus();

      // Insert the HTML at the cursor position
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;

        range.deleteContents();
        range.insertNode(tempElement.firstChild);
        range.collapse(false);

        selection.removeAllRanges();
        selection.addRange(range);

        // Update the editor's content
        this.updateEditorContent(editorElement.innerHTML);
      } else {
        // If no selection, append to the end
        const currentContent = editorElement.innerHTML;
        const newContent = currentContent + html;
        this.updateEditorContent(newContent);
      }
    }
  }

  private updateEditorContent(content: string): void {
    this.angularEditor.writeValue(content);

    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    this.angularEditor.textArea.nativeElement.dispatchEvent(event);
  }
  rotateElement() {
    this.showPdf = false;
    this.isRotated = !this.isRotated;
    setTimeout(() => {
      this.showPdf = true;
    }, 100);
  }

  setTrackingData(time) {
    let oldTime: Number = Number(this.userDetails.userData.timeInTrainingThisMonth);

    let newTime: Number = oldTime + time


    this.userDetails.userData.timeInTrainingThisMonth = newTime

    this.usersService.setUsersProp(this.userDetailsService.getSimpleUserDetails().id, 'timeInTrainingThisMonth', newTime)
    this.data.activityData.finished = true;
    if (!this.trackingData) {
      this.trackingData = [
        {
          userId: this.userDetailsService.getSimpleUserDetails().id,
          data: {
            finishedTimes: [],
          },
        },

      ]
    }
    else if (!this.trackingData.find(trackingData => trackingData.userId == this.userDetailsService.getSimpleUserDetails().id)) {

      this.trackingData.push({
        userId: this.userDetailsService.getSimpleUserDetails().id,
        data: {
          finishedTimes: [],
        },
      })

    }
    let userInTrackingData = this.trackingData.find(trackingData => trackingData.userId == this.userDetailsService.getSimpleUserDetails().id)

    if (!userInTrackingData.data.finishedTimes) {
      userInTrackingData.data.finishedTimes = []
    }
    userInTrackingData.data.finishedTimes.push(Date.now());
    this.lessonActivitiesService.setTrackingDataForActivity(this.activityId, this.userDetailsService.getSimpleUserDetails().id, userInTrackingData.data.finishedTimes);
    this.data.activityData.finished = true;


  }
  setValues() {
    this.activitiesAudios = this.data.activityData.audio;
    if (this.activitiesAudios) {
      const audioUrl = this.getFullAudioUrl(this.activitiesAudios);
      this.initializeAudioPlayer(audioUrl);
    }


    this.trackingData = this.data.activityData.trackingData;
    this.activityId = this.data.activityData.id;
    this.activitiesFrequency = this.data.activityData.frequency
    this.activityName = this.data.activityData.name;
    this.activityDescription = this.data.activityData.description;
    this.variants = this.data.activityData.variants;
    this.activityAuthor = this.data.activityData.author;
    this.activityUnfinished = this.data.activityData.unfinished;
    this.ageGroups = this.data.activityData.ageGroups;
    this.numbersOfStudents = this.data.activityData.numbersOfStudents;
    this.timesOfActivity = this.data.activityData.timesOfActivity;
    this.skills = this.data.activityData.skills;
    this.hints = this.data.activityData.hints;
    this.comments = this.data.activityData.comments;
    this.activityPictures = this.data.activityData.pictures
      ? this.data.activityData.pictures
      : [];
    this.activityVideos = this.data.activityData.videos
      ? this.data.activityData.videos
      : [];
    this.activityPdfs = this.data.activityData.pdfs
      ? this.data.activityData.pdfs
      : [];
    this.activityTags = this.data.activityData.tags
      ? this.data.activityData.tags
      : [];
    this.steps = this.data.activityData.steps
      ? this.data.activityData.steps
      : [];
    if (this.activityPictures.length > 0) {
      this.activityPictures.forEach(picture => {
        const fileId = this.extractFileIdFromLink(picture)
        this.activitiesPicturesIds.push(fileId)


      })
    }
    if (this.activityVideos.length > 0) {
      this.activityVideos.forEach(video => {
        const fileId = this.extractFileIdFromLink(video)

        this.activitiesVideosIds.push(fileId)
      }
      )

    }

  }

  playAudio() {

    this.descriptionAudio.play().then(() => {
      this.descriptionAudio.playing = true;
    }).catch(error => {
      console.error('Error playing audio:', error);
    });
  }

  pauseAudio() {
    this.descriptionAudio.pause();
    this.descriptionAudio.playing = false;
  }
  checkType() {
    if (this.activityTags.some((_tag) => _tag.tag == 'activities')) {
      this.type = 'activities';
    }
    if (this.activityTags.some((_tag) => _tag.tag == 'training')) {
      this.type = 'training';
    }
    if (this.activityTags.some((_tag) => _tag.tag == 'app')) {
      this.type = 'app';
    }
    if (this.activityTags.some((_tag) => _tag.tag == 'theory')) {
      this.type = 'theory';
    }
    if (this.activityTags.some((_tag) => _tag.tag == 'office')) {
      this.type = 'office';
    }
    if (this.activityTags.some((_tag) => _tag.tag == 'recordedLesson')) {
      this.type = 'recordedLesson';
    }
  }
  resetAllTrackingData() {
    this.lessonActivitiesService.resetAllTrackingDataForActivity(this.activityId).subscribe((response) => {
      console.log("🚀 ~ LessonActivitiesAddEditComponent ~ this.lessonActivitiesService.resetAllTrackingDataForActivity ~ response", response


      )
      this.data.activityData.finished = false
    }
    )
  }
  getSafeActivityDescription(): SafeHtml {
    if (!this.activityDescription) return '';

    // First sanitize the content while preserving styles
    const sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.activityDescription);

    // Add a wrapper with ::ng-deep to ensure styles are applied
    const contentWithStyles = `
      <div class="activity-content">
        ${this.activityDescription}
      </div>
    `;

    return this.sanitizer.bypassSecurityTrustHtml(contentWithStyles);
  }
  generateGoogleDriveLinks() { }
  prepareDescriptionToRecord() {
    let html = this.helpers.clone(this.activityDescription);
    const STANDARD_PAUSE = '!!!';
    const LONG_PAUSE = '!!!!!!';

    // Remove style tags and their contents
    html = html.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');

    // Add long pauses before and after headings
    html = html.replace(/<h[1-6][^>]*>(.*?)<\/h[1-6]>/gi, `${LONG_PAUSE}$1${LONG_PAUSE}`);

    // Add long pauses before unordered lists
    html = html.replace(/<ul[^>]*>/gi, LONG_PAUSE);

    // Add pauses before list items and ensure proper spacing with increased pause after
    html = html.replace(/<li[^>]*>(.*?)<\/li>/gi, `• $1${LONG_PAUSE}`);

    // Remove remaining HTML tags, but keep content
    html = html.replace(/<(?:.|\n)*?>/gm, '');

    // Remove emojis (extended range)
    html = html.replace(/[\u{1F000}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F191}-\u{1F251}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}]/gu, '');

    // Replace multiple spaces or newlines with a single space or newline
    html = html.replace(/\s+/g, ' ');
    html = html.replace(/\n+/g, '\n');

    // Ensure there's a standard pause before each new line
    html = html.replace(/\n/g, `${STANDARD_PAUSE}\n`);

    // Add long pauses between sections (assuming sections are separated by two or more newlines)
    html = html.replace(/\n\n+/g, `\n${LONG_PAUSE}\n\n`);
    html = html.replace(/(\.|&nbsp;)/g, `${LONG_PAUSE}`);

    // Trim leading and trailing whitespace
    html = html.trim();

    return html;
  }
  private resetAudioPlayer() {
    if (this.descriptionAudio) {
      this.descriptionAudio.pause();
      this.descriptionAudio.currentTime = 0;
      this.descriptionAudio.playing = false;
      this.audioProgressPercentage = 0;
    }
  }

  private getFullAudioUrl(url: string): string {
    // If URL is already absolute, return as-is
    if (url.startsWith('http')) {
      return url;
    }

    // If URL starts with /, use localhost base URL
    if (url.startsWith('/')) {
      return `http://localhost:4200${url}`;
    }

    // Otherwise, use the backend API URL
    return `https://backend-int.linget.it/api/read/getaudiodata?url=${url}`;
  }

  private initializeAudioPlayer(url: string) {
    this.resetAudioPlayer();
    this.descriptionAudio = new Audio();

    if (this.descriptionAudio.canPlayType('audio/mpeg')) {
      const fullUrl = this.getFullAudioUrl(url);
      this.descriptionAudio.src = fullUrl;
      this.descriptionAudio.load();

      // Set up event listeners
      this.descriptionAudio.addEventListener('loadedmetadata', () => {
        this.audioDurtion = this.descriptionAudio.duration;
      });

      this.descriptionAudio.addEventListener('ended', () => {
        this.descriptionAudio.playing = false;
        const durationInMinutes = Math.round(this.descriptionAudio.duration / 60);
        this.setTrackingData(durationInMinutes);
      });

      this.descriptionAudio.addEventListener('timeupdate', () => {
        if (this.descriptionAudio.duration > 0) {
          this.audioProgressPercentage = (this.descriptionAudio.currentTime / this.descriptionAudio.duration) * 100;
          this.ref.detectChanges();
        }
      });
    }
  }

  generateAudio() {
    this.isRecording = true;
    this.showAudio = false;

    // Stop any current playback
    this.resetAudioPlayer();

    let textToRecord = this.prepareDescriptionToRecord();
    this.generateAudioService.saveNewAudioToRepoWithResponse(this.activityId, textToRecord, 'polish', 'male')
      .subscribe((response: any) => {
        // Update the activitiesAudios with the new audio URL
        const audioUrl = response.url;
        this.activitiesAudios = audioUrl;
        this.data.activityData.audio = audioUrl;

        // Initialize the audio player with the new URL
        this.initializeAudioPlayer(audioUrl);

        // Update UI state
        this.showAudio = true;
        this.isRecording = false;

        // Update the activity in the database
        const updatedActivity = {
          ...this.getActivityData(),
          audio: audioUrl
        };
        this.lessonActivitiesService.updateActivity(this.activityId, updatedActivity);

        this.ref.detectChanges();
      }, (error) => {
        console.error('Error recording audio:', error);
        this.isRecording = false;
        this.ref.detectChanges();
      });
  }
  generteDescription() {
    let selectedChat = this.chatbotsService.chatbots.find(chat => chat.name == 'Saul')
    const data = {
      messages: [{ role: 'user', content: this.prepareDescriptionToRecord() }],
      aiProvider: selectedChat.aiProvider,
      model: selectedChat.model,
      maxTokens: selectedChat.maxTokens,
      maxMessages: selectedChat.maxMessages,
      systemMsg: selectedChat.systemMsg,
      files: null
    };
    this.chatService.sendPromptToChosenAi(data).subscribe((response) => {


      this.activityDescription = response.res.content;
      this.ref.detectChanges()
    }
    );
  }
  generteGrammar() {
    let selectedChat = this.chatbotsService.chatbots.find(chat => chat.name == 'Tedd')
    const data = {
      messages: [{ role: 'user', content: this.prepareDescriptionToRecord() }],
      aiProvider: selectedChat.aiProvider,
      model: selectedChat.model,
      maxTokens: selectedChat.maxTokens,
      maxMessages: selectedChat.maxMessages,
      systemMsg: selectedChat.systemMsg,
      files: null
    };
    this.chatService.sendPromptToChosenAi(data).subscribe((response) => {


      this.activityDescription = response.res.content;
      this.ref.detectChanges()
    }
    );
  }
  formatDescription() {
    let selectedChat = this.chatbotsService.chatbots.find(chat => chat.name == 'Salvador')
    const data = {
      messages: [{ role: 'user', content: this.prepareDescriptionToRecord() }],
      aiProvider: selectedChat.aiProvider,
      model: selectedChat.model,
      maxTokens: selectedChat.maxTokens,
      maxMessages: selectedChat.maxMessages,
      systemMsg: selectedChat.systemMsg,
      files: null
    };
    this.chatService.sendPromptToChosenAi(data).subscribe((response) => {


      this.activityDescription = response.res.content;
      this.ref.detectChanges()
    }
    );
  }
  getEmptyComment() {
    const comment = {
      id: this.activityId,
      author: this.activityAuthor,
      value: '',
      added: Date.now(),
      finished: false,
      text: '',
    };

    return comment;
  }
  addPicture() {
    this.dialog.open(AddPictureComponent, {
      data: { mode: 'fromLessonActivities' },
    });
  }
  addNewActivity() {
    //send to database
    this.lessonActivitiesService.saveNewActivity(this.getActivityData());
  }


  removeTag(tag) {
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 328 ~ LessonActivitiesAddEditComponent ~ removeTag ~ tag',
      tag
    );
    this.activityTags = this.activityTags.filter(
      (_tag) => _tag.tag !== tag.tag
    );
    this.checkType();
    if (this.activityTags.some((tag) => tag.tag == 'office')) {
      this.mode == 'office';
    } else {
      this.mode == '';
    }
  }
  addTag(tag) {
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 337 ~ LessonActivitiesAddEditComponent ~ addTag ~ tag',
      tag
    );
    if (tag.value.length > 0) {
      this.activityTags.push({ tag: tag.value });
      setTimeout(() => {
        if (this.activityTags.some((tag) => tag.tag == 'office')) {
          this.mode == 'office';
        }
        this.checkType();
      }, 1000);
    }
  }
  getActivityData() {
    const activityData = {
      added: Date.now(),
      id: this.activityId,
      name: this.activityName,
      description: this.activityDescription,
      variants: this.variants,
      author: this.activityAuthor,
      unfinished: this.activityUnfinished,
      ageGroups: this.ageGroups,
      numbersOfStudents: this.numbersOfStudents,
      timesOfActivity: this.timesOfActivity,
      skills: this.skills,
      hints: this.hints,
      comments: this.comments,
      pictures: this.activityPictures,
      videos: this.activityVideos,
      pdfs: this.activityPdfs,
      tags: this.activityTags,
      steps: this.steps,
      audio: this.activitiesAudios,
      trackingData: this.trackingData,
      finished: this.data?.activityData?.finished,
      frequency: this.activitiesFrequency
    };
    return activityData;
  }
  getEmptyVariant() {
    const variant = {
      value: '',
      name: '',
      added: Date.now(),
      author: this.activityAuthor,
    };
    return variant;
  }
  editStepsValue(stepToEdit) {
    let currentSteps = this.getSteps(this.activityId)?.steps;
    if (currentSteps) {
      currentSteps.forEach((step) => {
        step.edit = false;
      });
      let stepToEditInSteps = currentSteps.find(
        (step) => step?.added == stepToEdit?.added
      );
      if (stepToEditInSteps) {
        stepToEditInSteps.edit = true;
      }
    }
  }
  // openActivity(step) {
  //   let _class = "empty-wide";
  //   if (window.innerWidth < 800) {
  //     _class = "empty";
  //   }
  //   const activityToOpen = this.data.currentActivities.find(activity => activity.id == step.source)
  //   this.bottomSheet.open(LessonActivitiesAddEditComponent, { data: { activityData: activityToOpen, currentActivities: this.data.currentActivities }, panelClass: _class })

  // }
  getSteps(step) {
    let result = null;
    result = this.data?.currentActivities?.find(
      (activity) => activity?.id == step
    );
    if (result) {
      return result;
    } else {
      return;
    }
  }
  addPictureLink(link) {
    const directLink = this.helpers.convertGoogleDriveLink(link);
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 358 ~ LessonActivitiesAddEditComponent ~ addPictureLink ~ directLink',
      directLink
    );

    this.activityPictures.push(directLink);
    this.generateGoogleDriveLinks();
  }
  addVideoLink(link) {
    const directLink = this.helpers.convertGoogleDriveLink(link);
    this.activityVideos.push(directLink);
    this.generateGoogleDriveLinks();
    this.activitiesVideosIds.push(this.extractFileIdFromLink(link))
  }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  addPdfLink(link: string) {
    // Extract the file ID from the Google Drive link
    const fileId = this.extractFileIdFromLink(link);

    if (fileId) {
      // Create the preview URL
      const previewUrl = `https://drive.google.com/file/d/${fileId}/preview`;

      // Sanitize the URL and add it to the activityPdfs array

      this.activityPdfs.push({
        link: previewUrl,
        page: 1  // If you have specific page numbers, you can modify this accordingly
      });

      console.log(
        '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 416 ~ LessonActivitiesAddEditComponent ~ addPdfLink ~ this.activityPdfs',
        this.activityPdfs
      );

      // If there's a method to update the view or process the links, call it here
      // this.generateGoogleDriveLinks();  // This line may not be needed anymore, unless it does something else in your code
    } else {
      console.error('Invalid Google Drive link');
    }
  }

  private extractFileIdFromLink(input: string | null): string | null {
    if (typeof input === 'string') {
      const idRegex = /^[a-zA-Z0-9_-]+$/;
      if (idRegex.test(input)) {
        return input;
      }

      const urlRegex = /drive\.google\.com\/(?:file\/d\/|uc\?id=)([a-zA-Z0-9_-]+)(\/view|&export=download)?/;
      const match = input.match(urlRegex);
      return match ? match[1] : null;
    }

    return null;
  }
  openAddPictureDialog(source) {
    const dialogRef = this.dialog.open(PictureFinderComponent, {
      data: { latestPictures: true },
    });
    dialogRef.afterClosed().subscribe((chosenPicture) => {
      if (source == 'main') {
        this.activityPictures.push(chosenPicture.smallUrl);
      }
    });
  }
  pageRendered(event, pdf) {
    console.log(
      '🚀 ~ file: lesson-activities-add-edit.component.ts ~ line 422 ~ LessonActivitiesAddEditComponent ~ pageRendered ~ event',
      event
    );
    pdf.totalPages = event._pdfInfo.numPages;
  }
  increasePdfPage(pdf) {
    if (pdf.page + 1 <= pdf.totalPages) {
      pdf.page = pdf.page + 1;
    }
  }
  decreasePdfPage(pdf) {
    if (pdf.page > 1) {
      pdf.page = pdf.page - 1;
    }
  }
  openBigPictureDialog(picture) {
    const dialogRef = this.dialog.open(BigPictureComponent, { data: 'https://backend-dev.linget.it/api/google/get-file-by-id/' + picture });

  }
  removePicture(picToRemove) {

    this.activityPictures = this.activityPictures.filter(
      (picture) => picture !== picToRemove
    );
  }
  removeVideo(vidToRemove) {
    this.activityVideos = this.activityVideos.filter(
      (video) => video !== vidToRemove
    );
  }
  removePdf(pdfToRemove) {
    this.activityPdfs = this.activityPdfs.filter((pdf) => pdf !== pdfToRemove.link);
  }
  getEmptyHint() {
    const hintType: 'Polecam' | 'Nie polecam' | 'Neutralna' = 'Neutralna';
    const hint = {
      value: '',
      name: '',
      type: hintType,
      added: Date.now(),
      author: this.activityAuthor,
    };
    return hint;
  }
  getEmptyStep() {
    const step = {
      value: '',
      name: '',
      type: null,
      source: null,
      added: Date.now(),
      author: this.activityAuthor,
    };
    return step;
  }
  setEditMode() {
    this.editMode = !this.editMode;
  }
  editFinished() {
    this.editMode = !this.editMode;
    this.editStepsValue(null);
    this.lessonActivitiesService.updateActivity(
      this.activityId,
      this.getActivityData()
    );
  }
  removeComment(element) {
    this.comments = this.helpers.removeElementFromArray(this.comments, element);
  }
  removeVariant(element) {
    this.variants = this.helpers.removeElementFromArray(this.variants, element);
  }
  removeHint(element) {
    this.hints = this.helpers.removeElementFromArray(this.hints, element);
  }
  removeStep(element) {
    this.steps = this.helpers.removeElementFromArray(this.steps, element);
  }
  closeSheet() {
    // Stop any active audio playback
    if (this.descriptionAudio) {
      this.descriptionAudio.pause();
      this.descriptionAudio.currentTime = 0;
      this.descriptionAudio.src = '';
      this.descriptionAudio.load();
    }

    // Close the bottom sheet
    this._bottomSheetRef.dismiss(this.getActivityData());
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }

}
