import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  Inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignupFormService } from '../../shared/services/signup-form.service';
import { AdultsTestComponent } from './adults-test/adults-test.component';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SignupAddLeadComponent } from '../../profile/office/signup/signup-add-lead/signup-add-lead.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CustomersComponent } from '../../customers/customers.component';
import { EmailService } from 'src/app/shared/services/email.service';
@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
  internal;
  childrenMode = true;
  formSubmitted = false;
  fromStart = false;
  answers;
  shortVersion = false;
  shortestVersion = false;
  confirmation = false;
  childrenList = [];
  bgColor = '#fff';
  signUpData = new FormGroup({
    customerFirstName: new FormControl(null, [Validators.required]),
    customerLastName: new FormControl(null),
    customerPhone: new FormControl(null, [Validators.required]),
    internal: new FormControl(false),
    location: new FormControl(null),
    customerEmail: new FormControl(null),

    students: new FormArray([
      new FormGroup({
        id: new FormControl(Date.now()),
        continuing: new FormControl(false),
        child: new FormControl(this.childrenMode),
        childFirstName: new FormControl(null),
        childLastName: new FormControl(null),
        childGrade: new FormControl(null),
        childLevel: new FormControl(null),
        childPreviousClasses: new FormControl(null),
        additionalData: new FormControl(null),
        adultFirstName: new FormControl(null),
        adultLastName: new FormControl(null),
        adultPhone: new FormControl(null),
        adultEmail: new FormControl(null),
        adultAdditionalData: new FormControl(null),
        adultTestData: new FormControl(null),
      }),
    ]),
    termsAccepted: new FormControl(false, [Validators.requiredTrue]),
  });
  students = this.signUpData.get('students') as FormArray;
  showLocationSelector: boolean;
  sub: any;

  constructor(
    private signupService: SignupFormService,
    private authentication: AuthenticationService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<SignupFormComponent>,
    private dialog: MatDialog,
    private emailService: EmailService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    window.addEventListener('load', () => {
      let message = {
        mode:
          !this.shortVersion && !this.shortestVersion
            ? 'full'
            : this.shortVersion && !this.shortestVersion
              ? 'short'
              : !this.shortVersion && this.shortestVersion
                ? 'shortest'
                : null,
        height: document.body.scrollHeight,
        width: document.body.scrollWidth,
      };

      // window.top refers to parent window
      window.top.postMessage(message, '*');

    });
    this.shortVersion = JSON.parse(
      this.route.snapshot.queryParamMap.get('short'),
    );
    this.bgColor = this.route.snapshot.queryParamMap.get('color');
    if (/\d/.test(this.bgColor)) {
      this.bgColor = '#' + this.bgColor;
    }
    console.log(
      '🚀 ~ file: signup-form.component.ts:107 ~ SigndffpFormComponent ~ ngOnInit ~ this.route.snapshot.queryParamMap:',
      this.route.snapshot.queryParamMap,
    );
    this.shortestVersion = JSON.parse(
      this.route.snapshot.queryParamMap.get('shortest'),
    );

    if (this.data.internal) {
      this.internal = true;
    }
    this.sub = this.signupService
      .studentsUpdatedListener()
      .subscribe((newStudents) => {
        this.dialogRef.close();
      });
    this.setChild(0);
    this.spinner.hide();
    const url = this.route.snapshot['_routerState'].url;
    if (url.includes('bydgoszcz')) {
      // this.signUpData.get('location').setValue('Bydgoszcz')
    }
    if (url.includes('bialeblota')) {
      // this.signUpData.get('location').setValue('Biale Blota')
    }
    if (url.includes('online')) {
      // this.signUpData.get('location').setValue('Online')
    }
    if (this.authentication.isLoggedIn() || this.internal) {
      this.signUpData.get('internal').setValue(true);
      this.signUpData.get('termsAccepted').setValidators([]);
      this.signUpData.get('customerEmail').setValidators([]);
    }
    if (this.signUpData.get('location').value == null) {
      this.showLocationSelector = true;
    }
  }
  changeStudent(value, i) {

    if (value.checked === true) {
      this.setAdult(i);
    } else {
      this.setChild(i);
    }
  }
  addStudent(i) {
    const student = new FormGroup({
      id: new FormControl(Date.now(), [Validators.required]),
      continuing: new FormControl(false, [Validators.required]),
      child: new FormControl(this.childrenMode, [Validators.required]),
      childFirstName: new FormControl(null, [Validators.required]),
      childLastName: new FormControl(null, [Validators.required]),
      childGrade: new FormControl(null, [Validators.required]),
      childLevel: new FormControl(null, [Validators.required]),
      childPreviousClasses: new FormControl(null, [Validators.required]),
      additionalData: new FormControl(null),
      adultFirstName: new FormControl(null, [Validators.required]),
      adultLastName: new FormControl(null, [Validators.required]),
      adultPhone: new FormControl(null, [Validators.required]),
      adultEmail: new FormControl(null),
      adultAdditionalData: new FormControl(null),
      adultTestData: new FormControl(null),
    });

    (<FormArray>this.signUpData.get('students')).push(student);
    this.setChild(i);
    this.ref.detectChanges();
  }
  removeStudent(index: number) {
    this.students.removeAt(index);
  }

  setAdult(i) {

    const students = (<FormGroup>this.signUpData.get('students')).controls[i];


    students.get('child').setValue(false);
    students.get('childFirstName').disable();
    students.get('childLastName').disable();
    students.get('childGrade').disable();
    students.get('childLevel').disable();
    students.get('childPreviousClasses').disable();
    students.get('adultFirstName').enable();
    students.get('adultLastName').enable();
    students.get('adultPhone').enable();
    students.get('adultEmail').enable();
    students.get('adultAdditionalData').enable();
    students.get('adultTestData').enable();
  }
  setChild(i) {
    const students = (<FormGroup>this.signUpData.get('students')).controls[i];

    students.get('child').setValue(true);
    students.get('childFirstName').enable();
    students.get('childLastName').enable();
    students.get('childGrade').enable();
    students.get('childLevel').enable();
    students.get('childPreviousClasses').enable();
    students.get('additionalData').enable();
    students.get('adultFirstName').disable();
    students.get('adultLastName').disable();
    students.get('adultPhone').disable();
    students.get('adultEmail').disable();
    students.get('adultAdditionalData').disable();
    students.get('adultTestData').disable();
  }

  copyData(checked, i) {

    if (checked.checked === true) {
      const students = (<FormGroup>this.signUpData.get('students')).controls[i];
      students
        .get('adultFirstName')
        .setValue(this.signUpData.get('customerFirstName').value);
      students
        .get('adultLastName')
        .setValue(this.signUpData.get('customerLastName').value);
      students
        .get('adultPhone')
        .setValue(this.signUpData.get('customerPhone').value);
      students
        .get('adultEmail')
        .setValue(this.signUpData.get('customerEmail').value);
    } else {
      const students = (<FormGroup>this.signUpData.get('students')).controls[i];
      students.get('adultFirstName').setValue(null);
      students.get('adultLastName').setValue(null);
      students.get('adultPhone').setValue(null);
      students.get('adultEmail').setValue(null);
    }
  }
  updateAnswwers(answers) {
    this.answers = answers;

    const students = (<FormArray>this.signUpData.get('students')).controls[
      answers.i
    ];
    // const testData = {adultTestData: new FormControl(null)},
    // students.push(testData)
    students.get('adultTestData').setValue(answers.answers);

  }
  onSubmit() {
    if (
      !this.signUpData.value.termsAccepted &&
      !this.authentication.isLoggedIn()
    ) {
      alert('Zaznacz, że zapoznałeś się z regulaminem');
      return;
    }
    console.warn(this.signUpData);
    if (this.signUpData.valid) {
      this.formSubmitted = true;
      const dataToSubmit = {
        child: this.childrenMode,
        internal: this.signUpData.value.internal,
        location: this.signUpData.value.location,
        customerFirstName: this.signUpData.value.customerFirstName,
        customerLastName: this.signUpData.value.customerLastName,
        customerPhone: this.signUpData.value.customerPhone,
        customerEmail: this.signUpData.value.customerEmail,
        students: this.signUpData.value.students,
        termsAccepted: this.signUpData.value.termsAccepted,
      };

      this.signupService.saveNewStudent(dataToSubmit, this.internal);
    }
  }

  getCustomerFromExisting() {
    const dialogRef = this.dialog.open(CustomersComponent, {
      width: '80vw',
      height: '90vh',
      data: { windowMode: true },
    });
    dialogRef.afterClosed().subscribe((result) => {

      this.signUpData
        .get('customerFirstName')
        .setValue(result.customerFirstName);
      this.signUpData.get('customerLastName').setValue(result.customerLastName);
      this.signUpData.get('customerPhone').setValue(result.customerPhone);
      this.signUpData.get('customerEmail').setValue(result.customerEmail);
      this.signUpData.get('internal').setValue(true);
      result.students.forEach((student, index) => {
        let studentInForm = (<FormArray>this.signUpData.get('students'))
          .controls[index];
        if (!studentInForm) {
          this.addStudent(index);
          studentInForm = (<FormArray>this.signUpData.get('students')).controls[
            index
          ];
        }

        if (student.child) {
          this.setChild(index);
          studentInForm.get('childFirstName').setValue(student.childFirstName);
          studentInForm.get('childLastName').setValue(student.childLastName);
          studentInForm.get('childGrade').setValue(student.childGrade);
          studentInForm.get('childLevel').setValue(student.childLevel);
          studentInForm.get('childPreviousClasses').setValue(true);
          studentInForm.get('continuing').setValue(true);
        } else {
          this.setAdult(index);
          studentInForm.get('adultFirstName').setValue(student.adultFirstName);
          studentInForm.get('adultLastName').setValue(student.adultLastName);
          studentInForm.get('adultPhone').setValue(student.adultPhone);
          studentInForm.get('adultEmail').setValue(student.adultEmail);
          studentInForm.get('continuing').setValue(true);
        }
      });
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
