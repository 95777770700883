<div
  fxLayout="column"
  style="width: 75vw; height: 75vh"
  fxLayoutAlign="start start"
>
  <h2>Tagi i foldery dla {{ data.set.name }}</h2>
  <mat-chip-list>
    <mat-chip (click)="add({ value: tag })" *ngFor="let tag of allTags"
      >{{ tag }}
    </mat-chip>
  </mat-chip-list>
  <hr />
  <h2>Kategorie zdefiniowane</h2>
  <mat-chip-list>
    <mat-chip
      selected
      color="accent"
      (click)="addTag(tag)"
      *ngFor="let tag of fixedTags"
      >{{ tag.replace("type:", "") }}
    </mat-chip>
  </mat-chip-list>
  <div class="d-flex flex-row align-items-center">
    <mat-form-field class="mt-2" style="width: 70vw" appearance="outline">
      <mat-label>Tagi </mat-label>
      <mat-chip-list #chipList>
        <mat-chip
          selected
          removable
          *ngFor="let tag of data.set.tags"
          (removed)="remove(tag)"
          >{{ tag }}<mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Dodaj tag..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="false"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-icon
        color="primary"
        class="pointer"
        (click)="add({ value: 'archived' })"
        matTooltip="Archiwizuj"
        matSuffix
        >inventory_2
      </mat-icon>
      <mat-icon
        color="primary"
        class="pointer"
        (click)="copyRecentTags()"
        matTooltip="Kopiuj tagi "
        matPrefix
        >content_copy
      </mat-icon>
    </mat-form-field>
    <button
      class="ml-2 mr-2 mb-2"
      matTooltip="Wklej tagi "
      (click)="pasteRecentTags()"
      color="primary"
      mat-icon-button
    >
      <mat-icon class="pointer">content_paste </mat-icon>
    </button>
  </div>
  <app-sets-tree
    [currentSet]="data.set"
    [nested]="true"
    style="height: 50vh; width: 75vw"
  ></app-sets-tree>
</div>
