import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CoursesResourcesService } from 'src/app/shared/services/courses-resources.service';
// Remove the import that's causing routing conflicts
// import { CurriculumViewComponent } from '../../courses-resources/curriculum-add-edit/curriculum-view/curriculum-view.component';

@Component({
    selector: 'app-resource-preview-dialog',
    templateUrl: './resource-preview-dialog.component.html',
    styleUrls: ['./resource-preview-dialog.component.css']
})
export class ResourcePreviewDialogComponent implements OnInit {
    resource: any = null;
    isLoading = true;

    constructor(
        private coursesResourcesService: CoursesResourcesService,
        public dialogRef: MatDialogRef<ResourcePreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { resourceId: string, resourceName: string },
        private dialog: MatDialog
    ) {
        // No need to check CurriculumViewComponent anymore
    }

    ngOnInit(): void {
        this.loadResourceDetails();
    }

    // Helper method to get type safely for template
    getTypeOf(value: any): string {
        return typeof value;
    }

    // Helper method to check if value is array safely for template
    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    loadResourceDetails() {
        this.isLoading = true;
        // Jeśli mamy tylko ID i nazwę zasobu, pobieramy pełne szczegóły
        this.coursesResourcesService.getResourceById(this.data.resourceId)
            .then((resource) => {
                this.resource = resource;


                // Sprawdź szczegółowo dane curriculum
                if (this.resource && this.resource.curriculum) {



                    // Jeśli curriculum jest stringiem, spróbuj sparsować jako JSON
                    if (typeof this.resource.curriculum === 'string') {
                        try {

                            this.resource.curriculum = JSON.parse(this.resource.curriculum);

                        } catch (e) {
                            console.error('Failed to parse curriculum string:', e);
                            // Inicjalizuj jako pustą tablicę jeśli parsowanie się nie powiodło
                            this.resource.curriculum = [];
                        }
                    }

                    // Jeśli curriculum nie jest tablicą, przekształć w tablicę
                    if (!Array.isArray(this.resource.curriculum)) {

                        // Jeśli jest obiektem, zawrzyj go w tablicy
                        if (typeof this.resource.curriculum === 'object' && this.resource.curriculum !== null) {
                            this.resource.curriculum = [this.resource.curriculum];
                        } else {
                            // W innym przypadku, inicjalizuj pustą tablicę
                            this.resource.curriculum = [];
                        }
                    }



                } else {

                    this.resource.curriculum = [];
                }

                this.isLoading = false;
            })
            .catch((error) => {
                console.error('Błąd podczas pobierania szczegółów zasobu:', error);
                // Jeśli wystąpił błąd, pokazujemy uproszczony widok z dostępnymi danymi
                this.resource = {
                    id: this.data.resourceId,
                    name: this.data.resourceName,
                    curriculum: [],
                    error: true
                };
                this.isLoading = false;
            });
    }

    /**
     * Oblicza całkowitą ilość plików we wszystkich grupach plików zasobu
     */
    getFilesCount(resource): number {
        if (!resource.fileGroups || !Array.isArray(resource.fileGroups)) {
            return 0;
        }

        return resource.fileGroups.reduce((total, group) => {
            if (group.files && Array.isArray(group.files)) {
                return total + group.files.length;
            }
            return total;
        }, 0);
    }

    /**
     * Sprawdza czy zasób zawiera jakiekolwiek elementy, które można pokazać w podglądzie
     */
    hasResourceItems(resource): boolean {
        if (!resource) return false;

        const hasSets = resource.sets && Array.isArray(resource.sets) && resource.sets.length > 0;
        const hasActivities = resource.activities && Array.isArray(resource.activities) && resource.activities.length > 0;
        const hasHomework = resource.homework && Array.isArray(resource.homework) && resource.homework.length > 0;
        const hasFiles = this.getFilesCount(resource) > 0;

        return hasSets || hasActivities || hasHomework || hasFiles;
    }

    /**
     * Sprawdza czy zasób zawiera jakiekolwiek programy nauczania lub inne elementy, które można pokazać w podglądzie
     */
    hasCurriculumOrResourceItems(resource): boolean {
        if (!resource) return false;

        const hasCurriculum = resource.curriculum && Array.isArray(resource.curriculum) && resource.curriculum.length > 0;
        const hasResourceItems = this.hasResourceItems(resource);


        return hasCurriculum || hasResourceItems;
    }

    /**
     * Otwiera dialog z podglądem programu nauczania
     */
    viewCurriculum(curriculum: any): void {


        // Zamiast używać zewnętrznego komponentu, używamy własnego dialogu
        this.dialog.open(CurriculumSimpleViewComponent, {
            width: '800px',
            height: '80vh',
            data: { curriculum: curriculum },
            panelClass: 'curriculum-view-dialog'
        });
    }

    close() {
        this.dialogRef.close();
    }
}

/**
 * Prosty komponent dialogowy do wyświetlania komunikatów
 */
@Component({
    selector: 'app-simple-dialog',
    template: `
        <h2 mat-dialog-title>{{data.title}}</h2>
        <div mat-dialog-content>{{data.message}}</div>
        <div mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Zamknij</button>
        </div>
    `
})
export class SimpleDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<SimpleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
}

/**
 * Prosty komponent do wyświetlania programu nauczania
 */
@Component({
    selector: 'app-curriculum-simple-view',
    template: `
        <h2 mat-dialog-title>{{ data.curriculum.name || 'Program nauczania' }}</h2>
        <div mat-dialog-content style="height: calc(100% - 100px); overflow-y: auto;">
            <!-- Debug info -->
          

            <div class="curriculum-overview">
                <div class="overview-card">
                    <div class="overview-stat">
                        <div class="stat-number">{{ data.curriculum.units?.length || 0 }}</div>
                        <div class="stat-label">Jednostek</div>
                    </div>
                    <div class="overview-info">
                        <div class="overview-title">Program nauczania</div>
                        <div class="overview-description">
                            Przegląd struktury programu nauczania z rozbiciem na jednostki,
                            słownictwo i gramatykę.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pokaż komunikat, jeśli brak jednostek -->
            <div *ngIf="!data.curriculum.units || data.curriculum.units.length === 0" class="empty-message">
                Brak jednostek w programie nauczania.
            </div>

            <div class="curriculum-chapters" *ngIf="data.curriculum.units && data.curriculum.units.length > 0">
                <div *ngFor="let unit of data.curriculum.units; let i = index" class="unit-container">
                    <div class="unit-header" (click)="toggleUnit(i)">
                        <div class="unit-title">
                            <div class="unit-number">{{ i + 1 }}</div>
                            <div class="unit-name">{{ unit.topic || "Bez tematu" }}</div>
                        </div>
                        <mat-icon class="expand-icon" [class.expanded]="expandedUnits[i]">
                            {{ expandedUnits[i] ? "expand_less" : "expand_more" }}
                        </mat-icon>
                    </div>

                    <div class="unit-content" *ngIf="expandedUnits[i]">
                        <div class="unit-grid">
                            <div class="content-section vocabulary-section">
                                <div class="section-title">Słownictwo</div>
                                <div class="section-subtitle">{{ unit.vocabularyTopic }}</div>
                                <div class="vocabulary-list">
                                    <div class="vocabulary-item" *ngFor="let vocab of formatVocabularyList(unit.vocabularyList)">
                                        {{ vocab }}
                                    </div>
                                    <div *ngIf="formatVocabularyList(unit.vocabularyList).length === 0" class="empty-list">
                                        Brak słownictwa
                                    </div>
                                </div>
                            </div>

                            <div class="content-section grammar-section">
                                <div class="section-title">Gramatyka</div>
                                <div class="grammar-list">
                                    <div class="grammar-item" *ngFor="let grammarItem of unit.grammar">
                                        {{ grammarItem }}
                                    </div>
                                    <div *ngIf="!unit.grammar || unit.grammar.length === 0" class="empty-list">
                                        Brak punktów gramatycznych
                                    </div>
                                </div>
                            </div>

                            <div class="content-section skills-section">
                                <div class="skills-container">
                                    <div class="skill-item">
                                        <div class="skill-icon listening-icon">
                                            <mat-icon>headset</mat-icon>
                                        </div>
                                        <div class="skill-content">
                                            <div class="skill-title">Słuchanie</div>
                                            <div class="skill-description">
                                                {{ unit.listening || "Brak ćwiczeń słuchania" }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="skill-item">
                                        <div class="skill-icon reading-icon">
                                            <mat-icon>menu_book</mat-icon>
                                        </div>
                                        <div class="skill-content">
                                            <div class="skill-title">Czytanie</div>
                                            <div class="skill-description">
                                                {{ unit.reading || "Brak ćwiczeń czytania" }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="skill-item">
                                        <div class="skill-icon speaking-icon">
                                            <mat-icon>record_voice_over</mat-icon>
                                        </div>
                                        <div class="skill-content">
                                            <div class="skill-title">Mówienie</div>
                                            <div class="skill-description">
                                                {{ unit.speaking || "Brak ćwiczeń mówienia" }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="skill-item">
                                        <div class="skill-icon writing-icon">
                                            <mat-icon>edit</mat-icon>
                                        </div>
                                        <div class="skill-content">
                                            <div class="skill-title">Pisanie</div>
                                            <div class="skill-description">
                                                {{ unit.writing || "Brak ćwiczeń pisania" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Zamknij</button>
        </div>
    `,
    styles: [`
        .empty-message {
            padding: 20px;
            text-align: center;
            color: #666;
            font-style: italic;
            background-color: #f9f9f9;
            border-radius: 8px;
            margin: 20px 0;
        }
        .curriculum-overview {
            margin-bottom: 24px;
        }
        .overview-card {
            display: flex;
            background-color: #f5f5f5;
            border-radius: 8px;
            padding: 16px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .overview-stat {
            margin-right: 20px;
            text-align: center;
            min-width: 80px;
        }
        .stat-number {
            font-size: 28px;
            font-weight: 500;
            color: #3f51b5;
        }
        .stat-label {
            font-size: 14px;
            color: #666;
        }
        .overview-info {
            flex: 1;
        }
        .overview-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 8px;
            color: #333;
        }
        .overview-description {
            font-size: 14px;
            color: #666;
        }
        .curriculum-chapters {
            margin-top: 20px;
        }
        .unit-container {
            margin-bottom: 12px;
            border: 1px solid #eee;
            border-radius: 8px;
            overflow: hidden;
        }
        .unit-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            background-color: #f9f9f9;
            cursor: pointer;
            transition: background-color 0.2s;
        }
        .unit-header:hover {
            background-color: #f0f0f0;
        }
        .unit-title {
            display: flex;
            align-items: center;
        }
        .unit-number {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3f51b5;
            color: white;
            border-radius: 50%;
            margin-right: 12px;
            font-weight: 500;
        }
        .unit-name {
            font-weight: 500;
            color: #333;
        }
        .expand-icon {
            transition: transform 0.3s;
        }
        .expand-icon.expanded {
            transform: rotate(180deg);
        }
        .unit-content {
            padding: 16px;
            background-color: white;
            border-top: 1px solid #eee;
        }
        .unit-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }
        .content-section {
            padding: 12px;
            background-color: #f9f9f9;
            border-radius: 6px;
        }
        .section-title {
            font-weight: 500;
            color: #3f51b5;
            margin-bottom: 8px;
            font-size: 16px;
        }
        .section-subtitle {
            font-style: italic;
            color: #666;
            margin-bottom: 8px;
        }
        .vocabulary-list, .grammar-list {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        .vocabulary-item, .grammar-item {
            padding: 4px 8px;
            background-color: white;
            border-radius: 4px;
            font-size: 14px;
        }
        .skills-section {
            grid-column: 1 / -1;
        }
        .skills-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
        }
        .skill-item {
            display: flex;
            align-items: flex-start;
            background-color: white;
            padding: 10px;
            border-radius: 6px;
        }
        .skill-icon {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
        .listening-icon {
            background-color: #e3f2fd;
            color: #1976d2;
        }
        .reading-icon {
            background-color: #e8f5e9;
            color: #388e3c;
        }
        .speaking-icon {
            background-color: #fff9c4;
            color: #f57f17;
        }
        .writing-icon {
            background-color: #fff3e0;
            color: #d84315;
        }
        .skill-title {
            font-weight: 500;
            margin-bottom: 4px;
        }
        .skill-description {
            font-size: 14px;
            color: #666;
        }
        .empty-list {
            font-style: italic;
            color: #999;
            font-size: 13px;
            padding: 4px;
        }
        @media (max-width: 768px) {
            .unit-grid {
                grid-template-columns: 1fr;
            }
            .skills-container {
                grid-template-columns: 1fr;
            }
        }
    `]
})
export class CurriculumSimpleViewComponent implements OnInit {
    // Track expanded state for each unit
    expandedUnits: boolean[] = [];

    constructor(
        public dialogRef: MatDialogRef<CurriculumSimpleViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // Ensure data structure is correct
        if (!this.data.curriculum) {
            console.error('No curriculum data provided');
            this.data.curriculum = { name: 'Brak danych', units: [] };
        }


    }

    ngOnInit(): void {
        // Initialize all units as expanded
        if (this.data?.curriculum?.units) {
            this.expandedUnits = this.data.curriculum.units.map(() => true);
        } else {
            console.warn('No units found in curriculum data');
        }
    }

    toggleUnit(index: number): void {
        this.expandedUnits[index] = !this.expandedUnits[index];
    }

    // Format vocabulary list for better display
    formatVocabularyList(list: string): string[] {
        if (!list) return [];

        // Split by comma or newline and trim whitespace
        return list.split(/,|\n/)
            .map(item => item.trim())
            .filter(item => item.length > 0);
    }
} 