<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h2>Programy nauczania</h2>
        <div class="d-flex">
          <button
            mat-raised-button
            color="accent"
            class="me-3"
            [disabled]="isMigrating"
            (click)="migrateCurriculums()"
            matTooltip="Migruj programy nauczania z zasobów kursów"
          >
            <mat-icon>sync</mat-icon>
            <span *ngIf="!isMigrating">Migruj z zasobów</span>
            <span *ngIf="isMigrating">Migracja w toku...</span>
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="openAddCurriculumDialog()"
          >
            <mat-icon>add</mat-icon> Dodaj program nauczania
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Szukaj programu nauczania</mat-label>
        <input
          matInput
          [(ngModel)]="searchTerm"
          (keyup)="applyFilter()"
          placeholder="Wpisz nazwę programu..."
        />
        <button
          *ngIf="searchTerm"
          matSuffix
          mat-icon-button
          aria-label="Wyczyść"
          (click)="searchTerm = ''; applyFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="isLoading" class="row">
    <div class="col-12 text-center py-5">
      <mat-spinner style="margin: 0 auto"></mat-spinner>
      <p class="mt-3">Ładowanie programów nauczania...</p>
    </div>
  </div>

  <div *ngIf="!isLoading && filteredCurriculumList.length === 0" class="row">
    <div class="col-12 text-center py-5">
      <mat-icon style="font-size: 48px; width: 48px; height: 48px; color: #ccc"
        >school</mat-icon
      >
      <p class="mt-3">Brak programów nauczania</p>
      <button
        mat-stroked-button
        color="primary"
        (click)="openAddCurriculumDialog()"
      >
        Dodaj pierwszy program nauczania
      </button>
    </div>
  </div>

  <div *ngIf="!isLoading && filteredCurriculumList.length > 0" class="row">
    <div
      class="col-md-4 col-sm-6 col-12 mb-4"
      *ngFor="let curriculum of filteredCurriculumList"
    >
      <mat-card class="h-100">
        <mat-card-header>
          <mat-card-title>{{ curriculum.data.name }}</mat-card-title>
          <mat-card-subtitle *ngIf="curriculum.data.units">
            {{ curriculum.data.units.length }} jednostek
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="mt-2">
          <div class="mb-2 curriculum-details">
            <div
              *ngIf="curriculum.data.units && curriculum.data.units.length > 0"
              class="topics-list mb-3"
            >
              <strong>Tematy:</strong>
              <ul class="mt-1">
                <li *ngFor="let unit of curriculum.data.units.slice(0, 3)">
                  {{ unit.topic || "Bez tematu" }}
                </li>
                <li *ngIf="curriculum.data.units.length > 3" class="text-muted">
                  ...i {{ curriculum.data.units.length - 3 }} więcej
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions align="end">
          <button
            mat-button
            color="primary"
            (click)="viewCurriculum(curriculum)"
            matTooltip="Podgląd programu"
          >
            <mat-icon>visibility</mat-icon> Podgląd
          </button>
          <button
            mat-button
            color="accent"
            (click)="editCurriculum(curriculum)"
            matTooltip="Edytuj program"
          >
            <mat-icon>edit</mat-icon> Edytuj
          </button>
          <button
            mat-button
            color="warn"
            (click)="deleteCurriculum(curriculum._id)"
            matTooltip="Usuń program"
          >
            <mat-icon>delete</mat-icon> Usuń
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
