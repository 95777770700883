import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-section-fullscreen-dialog',
  templateUrl: './section-fullscreen-dialog.component.html',
  styleUrls: ['./section-fullscreen-dialog.component.css']
})
export class SectionFullscreenDialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<SectionFullscreenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // Method to handle adding an item to the lesson plan
  addToLessonPlan(item: any): void {
    this.dialogRef.close({ action: 'add', item: item });
  }
}
