<mat-card
  class="mb-2"
  fxLayout="column"
  [style.background-color]="activity?.finished ? '#c8e6c9' : '#ffebee'"
>
  <span *ngIf="!trainingMode" style="font-weight: bolder" class="mat-h5"
    >{{ activity.author.name }}
  </span>
  <div
    style="width: 100%; color: #7e57c2"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <span
      *ngIf="checkTags(activity) == 'fullProcess'"
      class="material-symbols-outlined mr-2"
      >hourglass_full
    </span>
    <span
      *ngIf="checkTags(activity) == 'processPiece'"
      class="material-symbols-outlined mr-2"
      >hourglass_empty
    </span>
    <span
      *ngIf="checkTags(activity) == 'processPiece'"
      class="material-symbols-outlined mr-2"
      >hourglass_empty
    </span>
    <span
      *ngIf="checkTags(activity) == 'instruction'"
      class="material-symbols-outlined mr-2"
      >info
    </span>
    <span
      [matTooltip]="
        'Długość opisu ' + activity?.description?.length + ' znaków'
      "
      style="font-weight: bolder"
      [class]="trainingMode ? 'mat-small' : ''"
      class="mat-h4 mb-0"
      [style.color]="activity.unfinished ? 'black' : '#673ab7'"
      >{{ activity.name }}
    </span>
    <span style="flex: 1" *ngIf="!activity.finished"></span>
    <span fxFlex></span>
    <button
      *ngIf="userDetailsService.owner && !trainingMode"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        addForTrainingToActivity(activity)
      "
      mat-icon-button
      matTooltip="Dodaj do szkoleń"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
    <span
      *ngIf="!trainingMode"
      matTooltip="Ilość filmików"
      [matBadge]="activity?.videos?.length"
      class="material-symbols-outlined mr-3"
    >
      videocam
    </span>

    <span
      *ngIf="activity?.audio && !trainingMode"
      matTooltip="Ilość audio"
      class="material-symbols-outlined mr-3"
    >
      headphones
    </span>
    <span
      *ngIf="!trainingMode"
      matTooltip="Ilość obrazków"
      [matBadge]="activity?.pictures?.length"
      class="material-symbols-outlined mr-3"
    >
      imagesmode
    </span>

    <button
      *ngIf="addToLessonMode && !activitiesToAdd?.includes(activity)"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        addActivityToLesson(activity)
      "
      mat-icon-button
      color="primary"
      matTooltip="Dodaj aktywność do lekcji"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>

    <button
      *ngIf="addToLessonMode && activitiesToAdd?.includes(activity)"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        removeActivityFromLesson(activity)
      "
      mat-icon-button
      color="warn"
      matTooltip="Usuń aktywność z lekcji"
    >
      <mat-icon>remove_circle_outline</mat-icon>
    </button>

    <button
      *ngIf="!trainingMode"
      class="mb-1"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      matTooltip="Więcej opcji"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <ng-container *ngIf="!addToLessonMode">
        <button
          mat-menu-item
          (click)="
            removeActivity(activity.id);
            $event.preventDefault();
            $event.stopPropagation()
          "
        >
          <mat-icon>delete</mat-icon>
          <span>Usuń aktywność</span>
        </button>
        <button
          mat-menu-item
          (click)="
            duplicateActivity(activity);
            $event.preventDefault();
            $event.stopPropagation()
          "
        >
          <mat-icon>refresh</mat-icon>
          <span>Zduplikuj aktywność</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</mat-card>
