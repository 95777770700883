<h2 mat-dialog-title>
  {{
    data?.curriculum ? "Edytuj program nauczania" : "Dodaj program nauczania"
  }}
</h2>

<mat-dialog-content class="mat-typography">
  <div class="split-view-container" [class.edit-mode]="isEditPanelVisible">
    <!-- Edit Panel -->
    <div
      class="edit-panel"
      *ngIf="isEditPanelVisible"
      style="
        min-height: 500px !important;
        height: auto !important;
        overflow: visible !important;
      "
    >
      <div class="edit-panel-header">
        <h3>Edycja</h3>
        <button
          mat-icon-button
          color="primary"
          (click)="toggleEditPanel()"
          matTooltip="Ukryj panel edycji"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width">
            <mat-label>Nazwa programu nauczania</mat-label>
            <input
              matInput
              [(ngModel)]="curriculum.name"
              placeholder="Wprowadź nazwę programu"
              required
            />
          </mat-form-field>
        </div>
      </div>

      <div class="units-container">
        <h3>Jednostki programu</h3>

        <div class="import-export-buttons">
          <button
            mat-stroked-button
            color="primary"
            (click)="openImportJsonDialog()"
          >
            <mat-icon>file_upload</mat-icon> Importuj z JSON
          </button>

          <button
            mat-stroked-button
            color="accent"
            [disabled]="!curriculum.name || curriculum.units.length === 0"
            (click)="exportToJson()"
          >
            <mat-icon>file_download</mat-icon> Eksportuj do JSON
          </button>

          <button
            mat-stroked-button
            color="primary"
            (click)="createFullExampleCurriculum()"
          >
            <mat-icon>auto_awesome</mat-icon> Utwórz pełny przykład
          </button>
        </div>

        <!-- Units -->
        <mat-card
          *ngFor="let unit of curriculum.units; let unitIndex = index"
          class="unit-card"
        >
          <mat-card-header>
            <mat-card-title>Jednostka {{ unitIndex + 1 }}</mat-card-title>
            <div class="unit-actions">
              <button
                mat-icon-button
                color="primary"
                matTooltip="Duplikuj jednostkę"
                (click)="duplicateUnit(unitIndex)"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                matTooltip="Usuń jednostkę"
                (click)="removeUnit(unitIndex)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Temat rozdziału</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.topic"
                    placeholder="Wprowadź temat rozdziału"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Obszar tematyczny</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.subjectArea"
                    placeholder="Np. Człowiek, Edukacja"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Podrozdział obszaru</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.subjectSubArea"
                    placeholder="Np. dane personalne, cechy charakteru"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Funkcje językowe</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.functions"
                    placeholder="Np. Wyrażanie opinii o cechach charakteru ludzi"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Temat słownictwa</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.vocabularyTopic"
                    placeholder="Wprowadź temat słownictwa"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Lista słownictwa</mat-label>
                  <textarea
                    matInput
                    [(ngModel)]="unit.vocabularyList"
                    placeholder="Wprowadź listę słownictwa"
                    rows="3"
                  ></textarea>
                  <mat-hint
                    >Wprowadź słowa oddzielone przecinkami lub w nowych
                    liniach</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h4>Gramatyka</h4>
                <div
                  *ngFor="let grammar of unit.grammar; let grammarIndex = index"
                  class="grammar-item"
                >
                  <mat-form-field class="grammar-input">
                    <mat-label
                      >Pozycja gramatyczna {{ grammarIndex + 1 }}</mat-label
                    >
                    <input
                      matInput
                      [(ngModel)]="unit.grammar[grammarIndex]"
                      placeholder="Wprowadź pozycję gramatyczną"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeGrammarItem(unitIndex, grammarIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addGrammarItem(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj pozycję gramatyczną
                </button>
              </div>
            </div>

            <!-- Umiejętności komunikacyjne -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Umiejętności komunikacyjne</h4>
                <div
                  *ngFor="
                    let skill of unit.communicativeSkills;
                    let skillIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.communicativeSkills[skillIndex]"
                      placeholder="Np. Opisywanie ludzi, Przedstawianie siebie"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeCommunicativeSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addCommunicativeSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj umiejętność komunikacyjną
                </button>
              </div>
            </div>

            <!-- Umiejętności receptywne -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Umiejętności receptywne</h4>
                <div
                  *ngFor="
                    let skill of unit.receptiveSkills;
                    let skillIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.receptiveSkills[skillIndex]"
                      placeholder="Np. Znajdowanie w tekście określonych informacji"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeReceptiveSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addReceptiveSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj umiejętność receptywną
                </button>
              </div>
            </div>

            <!-- Umiejętności produktywne -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Umiejętności produktywne</h4>
                <div
                  *ngFor="
                    let skill of unit.productiveSkills;
                    let skillIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.productiveSkills[skillIndex]"
                      placeholder="Np. Opisywanie ludzi, Przedstawianie faktów"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeProductiveSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addProductiveSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj umiejętność produktywną
                </button>
              </div>
            </div>

            <!-- Umiejętności interaktywne -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Umiejętności interaktywne</h4>
                <div
                  *ngFor="
                    let skill of unit.interactiveSkills;
                    let skillIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.interactiveSkills[skillIndex]"
                      placeholder="Np. Wyrażanie swoich opinii, pytanie o opinię"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeInteractiveSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addInteractiveSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj umiejętność interaktywną
                </button>
              </div>
            </div>

            <!-- Umiejętności przetwarzania -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Umiejętności przetwarzania</h4>
                <div
                  *ngFor="
                    let skill of unit.processingSkills;
                    let skillIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.processingSkills[skillIndex]"
                      placeholder="Np. Przekazywanie informacji z materiałów wizualnych"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeProcessingSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addProcessingSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj umiejętność przetwarzania
                </button>
              </div>
            </div>

            <!-- Inne umiejętności -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Inne umiejętności</h4>
                <div
                  *ngFor="let skill of unit.otherSkills; let skillIndex = index"
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Umiejętność {{ skillIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.otherSkills[skillIndex]"
                      placeholder="Np. Współdziałanie w grupie"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeOtherSkill(unitIndex, skillIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addOtherSkill(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj inną umiejętność
                </button>
              </div>
            </div>

            <!-- Odniesienia do podstawy programowej -->
            <div class="row skills-section">
              <div class="col-12">
                <h4>Odniesienia do podstawy programowej</h4>
                <div
                  *ngFor="
                    let standard of unit.coreStandards;
                    let standardIndex = index
                  "
                  class="skill-item"
                >
                  <mat-form-field class="skill-input">
                    <mat-label>Odniesienie {{ standardIndex + 1 }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="unit.coreStandards[standardIndex]"
                      placeholder="Np. II 5, III 4"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeCoreStandard(unitIndex, standardIndex)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addCoreStandard(unitIndex)"
                >
                  <mat-icon>add</mat-icon> Dodaj odniesienie do podstawy
                  programowej
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Słuchanie</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.listening"
                    placeholder="Ćwiczenia ze słuchania"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Czytanie</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.reading"
                    placeholder="Ćwiczenia z czytania"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Mówienie</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.speaking"
                    placeholder="Ćwiczenia z mówienia"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="full-width">
                  <mat-label>Pisanie</mat-label>
                  <input
                    matInput
                    [(ngModel)]="unit.writing"
                    placeholder="Ćwiczenia z pisania"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="add-unit-container">
          <button mat-raised-button color="primary" (click)="addUnit()">
            <mat-icon>add</mat-icon> Dodaj jednostkę
          </button>

          <div class="examples-buttons">
            <button
              mat-stroked-button
              color="primary"
              (click)="addExampleUnit()"
            >
              <mat-icon>school</mat-icon> Przykład 1 - What are you like?
            </button>

            <button
              mat-stroked-button
              color="primary"
              (click)="addUnitFromTemplate()"
            >
              <mat-icon>library_books</mat-icon> Przykład 2 - Student's blog
            </button>
          </div>

          <div class="examples-row">
            <button
              mat-stroked-button
              color="primary"
              (click)="addMoreExamples()"
            >
              <mat-icon>library_add</mat-icon> Dodaj 3 przykłady
            </button>

            <button
              mat-stroked-button
              color="primary"
              (click)="addFinalExamples()"
            >
              <mat-icon>add_circle</mat-icon> Dodaj 3 końcowe przykłady
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Preview Panel -->
    <div
      class="preview-panel"
      [class.full-width-preview]="!isEditPanelVisible"
      *ngIf="curriculum.name && curriculum.units.length > 0"
    >
      <div class="preview-header">
        <div class="preview-title">
          <h3>Podgląd</h3>
        </div>
        <div class="preview-actions" *ngIf="!isEditPanelVisible">
          <button
            mat-raised-button
            color="accent"
            (click)="printCurriculum()"
            matTooltip="Drukuj program nauczania"
            class="mr-2"
          >
            <mat-icon>print</mat-icon> Drukuj
          </button>
          <button mat-raised-button color="primary" (click)="toggleEditPanel()">
            <mat-icon>edit</mat-icon> Edytuj
          </button>
        </div>
      </div>

      <div class="preview-content">
        <!-- Curriculum Overview -->
        <div class="curriculum-overview">
          <div class="overview-card">
            <div class="overview-stat">
              <div class="stat-number">{{ curriculum.units.length }}</div>
              <div class="stat-label">Jednostek</div>
            </div>
            <div class="overview-info">
              <div class="overview-title">{{ curriculum.name }}</div>
              <div class="overview-description">
                Program nauczania z rozbiciem na jednostki, słownictwo i
                gramatykę.
              </div>
            </div>
          </div>
        </div>

        <!-- Curriculum Units -->
        <div class="curriculum-chapters">
          <div
            *ngFor="let unit of curriculum.units; let i = index"
            class="unit-container"
          >
            <div class="unit-header" (click)="toggleUnitInPreview(i)">
              <div class="unit-title">
                <div class="unit-number">{{ i + 1 }}</div>
                <div class="unit-name">{{ unit.topic || "Bez tematu" }}</div>
              </div>
              <mat-icon
                class="expand-icon"
                [class.expanded]="expandedUnitsInPreview[i]"
              >
                {{ expandedUnitsInPreview[i] ? "expand_less" : "expand_more" }}
              </mat-icon>
            </div>

            <div class="unit-content" *ngIf="expandedUnitsInPreview[i]">
              <div class="unit-grid">
                <div class="content-section metadata-section">
                  <div class="section-title">Informacje ogólne</div>
                  <div class="metadata-list">
                    <div class="metadata-item" *ngIf="unit.subjectArea">
                      <div class="metadata-label">Obszar tematyczny:</div>
                      <div class="metadata-value">{{ unit.subjectArea }}</div>
                    </div>
                    <div class="metadata-item" *ngIf="unit.subjectSubArea">
                      <div class="metadata-label">Podrozdział obszaru:</div>
                      <div class="metadata-value">
                        {{ unit.subjectSubArea }}
                      </div>
                    </div>
                    <div class="metadata-item" *ngIf="unit.functions">
                      <div class="metadata-label">Funkcje językowe:</div>
                      <div class="metadata-value">{{ unit.functions }}</div>
                    </div>
                  </div>
                </div>

                <div class="content-section vocabulary-section">
                  <div class="section-title">Słownictwo</div>
                  <div class="section-subtitle">{{ unit.vocabularyTopic }}</div>
                  <div class="vocabulary-list">
                    <div
                      class="vocabulary-item"
                      *ngFor="
                        let vocab of formatVocabularyList(unit.vocabularyList)
                      "
                    >
                      {{ vocab }}
                    </div>
                    <div
                      *ngIf="
                        formatVocabularyList(unit.vocabularyList).length === 0
                      "
                      class="empty-list"
                    >
                      Brak słownictwa
                    </div>
                  </div>
                </div>

                <div class="content-section grammar-section">
                  <div class="section-title">Gramatyka</div>
                  <div class="grammar-list">
                    <div
                      class="grammar-item"
                      *ngFor="let grammarItem of unit.grammar"
                    >
                      {{ grammarItem }}
                    </div>
                    <div
                      *ngIf="!unit.grammar || unit.grammar.length === 0"
                      class="empty-list"
                    >
                      Brak punktów gramatycznych
                    </div>
                  </div>
                </div>

                <div class="content-section skills-expanded-section">
                  <div class="section-title">Umiejętności</div>
                  <div class="skills-expanded">
                    <!-- Komunikacyjne -->
                    <div
                      class="skill-category"
                      *ngIf="
                        unit.communicativeSkills &&
                        unit.communicativeSkills.length > 0
                      "
                    >
                      <div class="skill-category-title">Komunikacyjne</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.communicativeSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>

                    <!-- Receptywne -->
                    <div
                      class="skill-category"
                      *ngIf="
                        unit.receptiveSkills && unit.receptiveSkills.length > 0
                      "
                    >
                      <div class="skill-category-title">Receptywne</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.receptiveSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>

                    <!-- Produktywne -->
                    <div
                      class="skill-category"
                      *ngIf="
                        unit.productiveSkills &&
                        unit.productiveSkills.length > 0
                      "
                    >
                      <div class="skill-category-title">Produktywne</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.productiveSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>

                    <!-- Interaktywne -->
                    <div
                      class="skill-category"
                      *ngIf="
                        unit.interactiveSkills &&
                        unit.interactiveSkills.length > 0
                      "
                    >
                      <div class="skill-category-title">Interaktywne</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.interactiveSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>

                    <!-- Przetwarzania -->
                    <div
                      class="skill-category"
                      *ngIf="
                        unit.processingSkills &&
                        unit.processingSkills.length > 0
                      "
                    >
                      <div class="skill-category-title">Przetwarzania</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.processingSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>

                    <!-- Inne -->
                    <div
                      class="skill-category"
                      *ngIf="unit.otherSkills && unit.otherSkills.length > 0"
                    >
                      <div class="skill-category-title">Inne</div>
                      <ul class="skill-category-list">
                        <li *ngFor="let skill of unit.otherSkills">
                          {{ skill }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="content-section standards-section"
                  *ngIf="unit.coreStandards && unit.coreStandards.length > 0"
                >
                  <div class="section-title">
                    Odniesienia do podstawy programowej
                  </div>
                  <div class="standards-list">
                    <div
                      class="standard-tag"
                      *ngFor="let standard of unit.coreStandards"
                    >
                      {{ standard }}
                    </div>
                  </div>
                </div>

                <div class="content-section skills-section">
                  <div class="skills-container">
                    <div class="skill-item">
                      <div class="skill-icon listening-icon">
                        <mat-icon>headset</mat-icon>
                      </div>
                      <div class="skill-content">
                        <div class="skill-title">Słuchanie</div>
                        <div class="skill-description">
                          {{ unit.listening || "Brak ćwiczeń słuchania" }}
                        </div>
                      </div>
                    </div>

                    <div class="skill-item">
                      <div class="skill-icon reading-icon">
                        <mat-icon>menu_book</mat-icon>
                      </div>
                      <div class="skill-content">
                        <div class="skill-title">Czytanie</div>
                        <div class="skill-description">
                          {{ unit.reading || "Brak ćwiczeń czytania" }}
                        </div>
                      </div>
                    </div>

                    <div class="skill-item">
                      <div class="skill-icon speaking-icon">
                        <mat-icon>record_voice_over</mat-icon>
                      </div>
                      <div class="skill-content">
                        <div class="skill-title">Mówienie</div>
                        <div class="skill-description">
                          {{ unit.speaking || "Brak ćwiczeń mówienia" }}
                        </div>
                      </div>
                    </div>

                    <div class="skill-item">
                      <div class="skill-icon writing-icon">
                        <mat-icon>edit</mat-icon>
                      </div>
                      <div class="skill-content">
                        <div class="skill-title">Pisanie</div>
                        <div class="skill-description">
                          {{ unit.writing || "Brak ćwiczeń pisania" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty Preview Message -->
    <div
      class="empty-preview"
      [class.full-width-preview]="!isEditPanelVisible"
      *ngIf="!curriculum.name || curriculum.units.length === 0"
    >
      <div class="empty-preview-message">
        <mat-icon>remove_red_eye</mat-icon>
        <h3>Podgląd</h3>
        <p>
          Dodaj nazwę programu i przynajmniej jedną jednostkę, aby zobaczyć
          podgląd.
        </p>
        <div class="empty-preview-buttons">
          <button
            mat-raised-button
            color="primary"
            (click)="toggleEditPanel()"
            *ngIf="!isEditPanelVisible"
          >
            <mat-icon>edit</mat-icon> Edytuj
          </button>

          <button
            mat-raised-button
            color="accent"
            (click)="createFullExampleCurriculum()"
            matTooltip="Utwórz pełny przykładowy program nauczania z wszystkimi jednostkami"
          >
            <mat-icon>school</mat-icon> Utwórz pełny przykład
          </button>
        </div>

        <div class="examples-buttons" *ngIf="isEditPanelVisible">
          <p>Lub użyj przykładowych jednostek:</p>
          <div class="examples-row">
            <button
              mat-stroked-button
              color="accent"
              (click)="addExampleUnit()"
            >
              <mat-icon>lightbulb</mat-icon> Przykładowa jednostka
            </button>
            <button
              mat-stroked-button
              color="accent"
              (click)="addUnitFromTemplate()"
            >
              <mat-icon>library_books</mat-icon> Przykład 2 - Student's blog
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Anuluj</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="curriculum"
    [disabled]="!curriculum.name || curriculum.units.length === 0"
  >
    Zapisz
  </button>
</mat-dialog-actions>
