import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentNameService {
nameToPass = new Subject()
  constructor() {
    this.nameToPass.next('test')
   }

 changeName(name){
  this.nameToPass.next(name)
 }

  nameChanged(){
  return this.nameToPass.asObservable()
  }
}

