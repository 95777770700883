import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { SetsService } from '../../../shared/services/sets.service';

@Component({
  selector: 'app-sets-categorize',
  templateUrl: './sets-categorize.component.html',
  styleUrls: ['./sets-categorize.component.css'],
})
export class SetsCategorizeComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  allTags = [];
  sub: any;
  fixedTags = [
    'type: Gramatyka',
    'type: Tłumaczenie zdania',
    'type: Tłumaczenie słowa / wyrażenia',
    'type: Uzupełnij lukę',
    'type: Uzupełnij tekst',
    'type: Popraw błędy',
    'type: Słowotwórstwo',
    'type: Transformacje',
    'type: Zgadnij lukę',
    'type: Porównanie czasów',
    'type: Porównanie struktur',
    'type: Pisanie',
    'type: Słownictwo',
    'type: Słuchanie',
    'type: Czytanie',
    'type: Dialogi',
    'type: Zwroty i wyrażenia',
    'type: Łatwy',
    'type: Średni',
    'type: Trudny',
    'type: Ekspert',
    'type: Do lekcji',
    'type: Maluchy',
    'type: Dziecięce ',
    'type: Książka',
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public setsService: SetsService,
    private helpers: HelpersService,
  ) {}

  ngOnInit(): void {
    this.sub = this.setsService.allTagsSubListener().subscribe((tags: any) => {
      this.allTags = tags;
      console.log(
        '🚀 ~ file: sets-categorize.component.ts ~ line 26 ~ SetsCategorizeComponent ~ this.sub=this.setsService.allTagsSubListener ~ this.allTags',
        this.allTags,
      );
    });
    // this.setsService.getAllTags();
    this.data;
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (!this.data.set.tags) {
        this.data.set.tags = [];
      }
      this.data.set.tags.push(value);
      this.setsService.addTag(event.value.toUpperCase(), this.data.set.id);
    }

    event.input.value = '';
  }
  pasteRecentTags() {
    console.log(
      '🚀 ~ file: sets-categorize.component.ts:78 ~ SetsCategorizeComponent ~ this.setsService.recentTags.forEach ~ this.setsService.recentTags:',
      this.setsService.recentTags,
    );
    this.setsService.recentTags.forEach((tag) => {
      this.addTag(tag);
    });
  }
  copyRecentTags() {
    this.setsService.setRecentTags(this.data.set.tags);
  }
  addTag(tag) {
    if (!this.data.set.tags) {
      this.data.set.tags = [];
    }
    if (!this.data.set.tags.includes(tag)) {
      this.data.set.tags.push(tag);
      this.setsService.addTag(tag.toUpperCase(), this.data.set.id);
    }
  }
  remove(tag): void {
    this.data.set.tags = this.helpers.removeElementFromArray(
      this.data.set.tags,
      tag,
    );
    this.setsService.removeTag(tag, this.data.set.id);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
