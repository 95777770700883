import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { StudentDictionaryDialogComponent } from '../components/student-dictionary-dialog/student-dictionary-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VersionService } from './version.service';
import { FoundMeaningsDialogComponent } from 'src/app/database/sets/found-meanings-dialog/found-meanings-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  url;
  allWords = [];
  duplicatePictures = [];
  wordsToSent = new Subject();
  translationsFound = new Subject();
  _duplicatePictures = new Subject();
  foundMeanings = new Subject();
  propertyChangedUpdated = new Subject();
  tagsSet = new Subject();
  wordsToSendForTranslation = new Subject();

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  addWord(newWord) {
    const dataToSend = newWord;
    this.http
      .post<{ message: string; createdPicture }>(
        this.url + '/api/dictionary/addword/',
        dataToSend,
      )
      .subscribe((response) => { });
    // this.getWordsList()
  }

  checkSentenceForTranslations(
    disassembledSentence: any,
    meaningId: string,
  ): void {
    const words = disassembledSentence.map((word: any) => {
      const result = {
        word: word.word.replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
        id: word.id,
      };
      return result;
    });
    this.http
      .post<{ message: string }>(
        this.url + '/api/dictionary/checksentencefortranslations/',
        { words: words },
      )
      .subscribe((response: any) => {
        response.meaningId = meaningId;

        this.translationsFound.next(response);
      });
    // this.getWordsList()
  }
  redoDescription(meaningId) {
    this.http
      .post(this.url + '/api/chat/redo-description', {
        meaningId: meaningId,
      })
      .subscribe((response: any) => {
        this.propertyChangedUpdated.next(response);
      });
  }
  setPolishTags(wordId, meaningId, polishTags): void {
    this.http
      .post<{ message: string }>(this.url + '/api/dictionary/setpolishtags/', {
        wordId: wordId,
        meaningId: meaningId,
        polishTags: polishTags,
      })
      .subscribe((response: any) => {
        if (response.res == 'ok') {
          this.tagsSet.next('tagsSetOk');
        }
      });
    // this.getWordsList()
  }
  setMeaningsProperty(meaningId, property, value): void {
    this.http
      .post<{ message: string }>(
        this.url + '/api/dictionary/set-meanings-property/',
        {
          meaningId: meaningId,
          property: property,
          value: value,
        },
      )
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: dictionary.service.ts:104 ~ DictionaryService ~ .subscribe ~ response:',
          response,
        );
      });

    // this.getWordsList()
  }
  setQa(wordId, meaningId, qa): void {
    this.http
      .post<{ message: string }>(this.url + '/api/dictionary/setqa/', {
        wordId: wordId,
        meaningId: meaningId,
        qa: qa,
      })
      .subscribe((response: any) => {
        if (response.res == 'ok') {
          this.tagsSet.next('qa');
        }
      });
    // this.getWordsList()
  }

  getWordsList(limit?) {
    this.http
      .get<{ words: any }>(
        this.url + '/api/dictionary/getWordsList?limit=' + limit,
      )
      .pipe(
        map((words) => {
          return words.words.map((word) => {
            return {
              id: word._id,
              word: word.data.word,
              added: word.data.added,
              meanings: word.data.meanings,
            };
          });
        }),
      )
      .subscribe((words) => {
        this.allWords = words;
        this.wordsToSent.next(this.allWords);
      });
  }
  setAutomaticPolishTags() {
    this.http
      .get<{ words: any }>(this.url + '/api/dictionary/setautomaticpolishtags')

      .subscribe((words) => {
        console.log(
          '🚀 ~ file: dictionary.service.ts ~ line 116 ~ DictionaryService ~ .subscribe ~ words',
          words,
        );
      });
  }
  findWordsByPart(part, lang?) {
    let langToSend = '';
    if (lang) {
      langToSend = '&lang=lang:' + lang;
    }
    this.http
      .get<{ words: any }>(
        this.url + '/api/dictionary/findwordsbypart?part=' + part + langToSend,
      )

      .subscribe((meanings: any) => {
        this.wordsToSent.next(meanings);
      });
  }
  findWordsById(id) {
    this.http
      .get<{ words: any }>(this.url + '/api/dictionary/findwordsbyid?id=' + id)

      .subscribe((meanings: any) => {
        this.wordsToSent.next(meanings);
      });
  }
  findWordsByMeaning(meaning) {
    this.http
      .get<{ words: any }>(
        this.url + '/api/dictionary/findwordsbymeaning?meaning=' + meaning,
      )

      .subscribe((meanings: any) => {
        // this.foundMeanings.next(meanings);
        const dialogRef = this.dialog.open(FoundMeaningsDialogComponent, {
          data: meanings,
          width: '100vw',
          minWidth: '100vw',
          height: '100vh',
          minHeight: '100vh',
        });
        dialogRef.afterClosed().subscribe((foundMeanings) => {
          console.log(
            '🚀 ~ file: dictionary.service.ts:210 ~ DictionaryService ~ dialogRef.afterClosed ~ foundMeanings:',
            foundMeanings,
          );
          this.foundMeanings.next(foundMeanings); // to update in finder
        });
      });
  }
  findWordsBySetsTag(tag) {
    this.http
      .get<{ words: any }>(
        this.url + '/api/dictionary/findwordsbysetsname?tag=' + tag,
      )

      .subscribe((meanings: any) => {
        this.wordsToSent.next(meanings);
      });
  }
  getWordsListWithPagination(pageNumber, itemsPerPage) {
    this.http
      .get<{ words: any; count: number }>(
        this.url +
        '/api/dictionary/getWordsListWithPagination?pageNumber=' +
        pageNumber +
        '&itemsPerPage=' +
        itemsPerPage,
      )
      .pipe(
        map((words) => {
          return {
            count: words.count,
            words: words.words.map((word) => {
              return {
                id: word._id,
                word: word.data.word,
                added: word.data.added,
                meanings: word.data.meanings,
              };
            }),
          };
        }),
      )
      .subscribe((words: any) => {
        this.allWords = words;
        this.wordsToSent.next(words);
      });
  }

  findDuplicatePictures() {
    this.http
      .get<{ words }>(this.url + '/api/dictionary/find-duplicate-pictures/')
      .pipe(
        map((words) => {
          return words.words.map((word) => {
            return {
              meaning: word,
            };
          });
        }),
      )
      .subscribe((words) => {
        this.duplicatePictures = words;
        this.duplicatePictures = Object.keys(this.duplicatePictures).map(
          (i) => this.duplicatePictures[i],
        );

        this._duplicatePictures.next(this.duplicatePictures);
      });
  }

  findWords(wordToFind, searchThruSentence, searchMode, spanish?) {
    if (wordToFind === '') {
      // this.getWordsList()
      return;
    }
    if (wordToFind) {
      wordToFind = wordToFind.replace('’', "'");
    }

    this.http
      .get<{ words: any }>(
        this.url +
        '/api/dictionary/findWords?wordtofind=' +
        wordToFind +
        '&searchthrusentence=' +
        searchThruSentence +
        '&searchmode=' +
        searchMode +
        '&spanish=' +
        spanish,
      )
      .pipe(
        map((words) => {
          return words.words.map((word) => {
            return {
              id: word._id,
              word: word.data.word,
              added: word.data.added,
              meanings: word.data.meanings,
            };
          });
        }),
      )
      .subscribe((words) => {
        console.log(
          '🚀 ~ file: dictionary.service.ts:259 ~ DictionaryService ~ .subscribe ~ words:',
          words,
        );
        this.allWords = words;

        this.wordsToSent.next(this.allWords);
      });
  }

  findWordsForStudents(
    wordToFind,
    searchThruSentence,
    searchMode,
    object?,
    fromButton?,
    connected?,
    optionalWordForConnected?,
    connectedNotFound?,
    spanish?,
  ) {
    if (wordToFind === '') {
      // this.getWordsList()
      return;
    }
    let wordToFindToSend;
    if (object) {
      wordToFindToSend = wordToFind.word
        .toLowerCase()
        .replace('.', '')
        .replace('"', '')
        .replace('"', '')
        // .replace("'", '')
        // .replace("'", '')
        .replace(',', '')
        .replace('?', '')
        .replace('!', '')
        .replace(':', '')
        .replace('’', "'")
        .trim();
    } else {
      wordToFindToSend = wordToFind
        .toLowerCase()
        .replace('.', '')
        .replace(',', '')
        .replace('?', '')
        .replace('!', '')
        .replace(':', '')
        .replace('"', '')
        .replace('"', '')
        // .replace("'", '')
        // .replace("'", '')
        .replace('’', "'")
        .trim();
    }
    this.http
      .get<{ words: any }>(
        this.url +
        '/api/dictionary/findWords?wordtofind=' +
        wordToFindToSend +
        '&searchthrusentence=' +
        searchThruSentence +
        '&searchmode=' +
        searchMode +
        '&spanish=' +
        spanish,
      )
      .pipe(
        map((words) => {
          return words.words.map((word) => {
            return {
              id: word._id,
              word: word.data.word,
              added: word.data.added,
              meanings: word.data.meanings,
            };
          });
        }),
      )
      .subscribe((words) => {
        if (words.length === 0 && connected) {
          this.findWordsForStudents(
            optionalWordForConnected,
            false,
            'exact',
            false,
            false,
            false,
            null,
            true,
          );
          return;
        }
        this.allWords = words;
        // let singleWord =  this.allWords.filter(word=> word.meanings?.some(meaning=>meaning.tags.some(
        //   tag=>
        //     tag.tag.trim() === wordToFindToSend

        //   )))[0]
        // if(singleWord){

        let finalMeanings = [];
        this.allWords.forEach((singleWord) => {
          singleWord.meanings?.forEach((meaning) => {
            finalMeanings.push(meaning);
          });
        });

        const dialogRef = this.dialog.open(StudentDictionaryDialogComponent, {
          width: '90vw',
          maxHeight: '90vh',
          data: {
            meanings: finalMeanings,
            fromButton: fromButton,
            connectedNotFound: connectedNotFound,
          },
        });
        // }
        // else{
        //   let singleWord =  this.allWords.filter(word=> word.meanings?.some(meaning=>meaning.tags.some(
        //     tag=>
        //       tag.tag.trim() === wordToFindToSend.replace(' ', '  ')

        //     )))[0]
        //     if(singleWord){

        //       let finalMeanings = []
        //      singleWord.meanings?.forEach(meaning=>{
        //        let simpleTags = meaning.tags.map(tag=>{

        //          return tag.tag.trim()
        //        })
        //       if(simpleTags.some(tag=>tag.replace('  ', ' ') == wordToFindToSend)){
        //         finalMeanings.push(meaning)
        //       }
        //      })

        //         const dialogRef = this.dialog.open(StudentDictionaryDialogComponent, {
        //         width:'90vw',
        //         maxHeight:'90vh',
        //           data: {
        //             meanings:finalMeanings,
        //             fromButton:fromButton
        //           }
        //         });
        //       }
        // }
      });
  }

  updateWordsList() {
    return this.wordsToSent.asObservable();
  }
  propertyChangedListener() {
    return this.propertyChangedUpdated.asObservable();
  }

  translationsFoundListener() {
    return this.translationsFound.asObservable();
  }

  foundWordsForTranslationsListener() {
    return this.wordsToSendForTranslation.asObservable();
  }

  foundDuplicatePictures() {
    return this._duplicatePictures.asObservable();
  }
  foundMeaningsListener() {
    return this.foundMeanings.asObservable();
  }
  tagsSetListener() {
    return this.tagsSet.asObservable();
  }

  removeWord(id) {
    this.http
      .delete(this.url + '/api/dictionary/removeWord/' + id)
      .subscribe(() => {
        const updatedWords = this.allWords.filter((word) => word.id !== id);
        this.allWords = updatedWords;
        this.wordsToSent.next([...this.allWords]);
      });
  }

  updateWord(id: string, word) {
    let dataToSend;

    dataToSend = word;

    this.http
      .put(this.url + '/api/dictionary/updateWord/' + id, dataToSend, {
        reportProgress: true,
        observe: 'response',
      })
      .subscribe((response) => {
        // let wordToUpdate = this.allWords.findIndex(
        //   (_word) => _word.id === word.id
        // );

        // this.allWords[wordToUpdate] = word;

        // this.wordsToSent.next(this.allWords);

        if (response.type === HttpEventType.Response) {
          // const updatedPicture = picture
          // const oldPictureIndex = updatedPicture.findIndex(s=>s.id === id)
          // //
          // updatedPicture[oldPictureIndex] = picture
          // // this.Sentences = updatedSentences2
          //   this.picturesUpdated.next(updatedPicture)
          //   //
        }
      });
  }

  addMeaning(wordId: string, meaning: any): Observable<any> {
    return this.http.post<any>(
      this.url + '/api/dictionary/add-meaning',
      {
        wordId: wordId,
        meaning: meaning,
      }
    ).pipe(
      tap((response) => {
        // Refresh words list after adding a meaning
        this.updateWordsList().subscribe();
      })
    );
  }
}
