import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';

import {
  Course
} from '../../shared/models/course.model';
import {
  CoursesService
} from '../../shared/services/courses.service';
import {
  FormBuilder, Validators
} from '@angular/forms';
import { Control } from 'src/app/shared/models/controls.model';
import { MatDialog } from '@angular/material/dialog';
import { CourseAddEditComponent } from '../course-add-edit/course-add-edit.component';
import { CoursesPlanComponent } from '../courses-plan/courses-plan.component';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.css']
})
export class CoursesListComponent implements OnInit, OnDestroy {
  coursesSub
  courses: Course[] = []
  controls: Control[] = [
    { text: 'Dodaj kurs', color: 'primary', type: 'button', mode: 'flat', icon: 'post_add' },
    { text: 'Elementy kursów', color: 'primary', type: 'button', mode: 'flat', icon: 'extension' },

  ];
  incomingTextFromMenu: any;

  constructor(private coursesService: CoursesService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.coursesSub = this.coursesService.coursesUpdatedListener().subscribe(courses => {
      this.courses = courses

    })
    this.coursesService.getCoursesList()
  }
  incomingText(text) {
    this.incomingTextFromMenu = text
    this.coursesService.findCourseByName(this.incomingTextFromMenu)
  }
  buttonClicked(event) {
    switch (event) {

      case 'Dodaj kurs':
        this.openAddCoursePanel()
        break;
      case 'Elementy kursów':
        this.openCoursesPiecesPanel()
        break;

      default:
        break;
    }

  }
  openAddCoursePanel() {
    const dialogRef = this.dialog.open(CourseAddEditComponent, {
      width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh',
      disableClose: true
    });

  }
  openCoursesPiecesPanel() {
    const dialogRef = this.dialog.open(CoursesPlanComponent, {
      width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh',
      disableClose: true
    });
  }
  openEditCoursePanel(course) {
    const dialogRef = this.dialog.open(CourseAddEditComponent, {
      width: '100vw', height: '100vh', minWidth: '100vw', minHeight: '100vh',
      data: { course: course },
      disableClose: true
    });
  }
  ngOnDestroy(): void {
    this.coursesSub.unsubscribe()
  }
}
