import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { WorkersAvatarsService } from './workers-avatars.service';
import { UsersServiceService } from './users-service.service';

@Injectable({
    providedIn: 'root'
})
export class TeachersEvaluationService {
    teachers = [];
    private workersSub: Subscription;
    private teachersSubject = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private usersService: UsersServiceService,
        private workersAvatarsService: WorkersAvatarsService // Injecting WorkersAvatarsService
    ) {
        this.workersSub = this.usersService
            .workersUpdatedListener()
            .subscribe((workers) => {

                this.teachers = this.getTeachersList(workers);

                this.teachersSubject.next(this.teachers); // Emit the updated teachers data
            });
        this.usersService.getWorkersList();
    }

    getTeachersList(workers: any[]): any[] {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        return workers.filter(worker =>
            worker.role === 'teacher' &&
            (new Date(worker.userData.lastLogin) >= oneMonthAgo)
        ).map(worker => ({
            ...worker,
            avatar: this.workersAvatarsService.getAvatarURL(worker.name) // Assuming getAvatar method exists
        }));
    }

    getTeacherEvaluations(): Observable<any> {
        return this.http.get('/api/teachers-evaluations');
    }

    getTeachersUpdates(): Observable<any[]> {
        return this.teachersSubject.asObservable();
    }

    ngOnDestroy() {
        this.workersSub.unsubscribe();
    }
}