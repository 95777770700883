import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalOceanService {
  private url;

  constructor(private http: HttpClient, private versionService: VersionService) {
    this.url = this.versionService.url + '/api/do'
  }

  uploadFileToFolder(file: File, folderName: string, share: boolean, bucketName: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderName', folderName);
    formData.append('share', share.toString());
    formData.append('bucketName', bucketName);
    let res = this.http.post(`${this.url}/upload`, formData);

    return res
  }
  createFolder(folderName: string, bucketName: string): Observable<any> {
    const body = {
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/create-folder`, body);
  }
  listFilesInFolder(folderName: string, bucketName: string, share: boolean): Observable<any> {
    const body = {
      folderName: folderName,
      bucketName: bucketName,
      share: share
    };

    return this.http.post(`${this.url}/list-files`, body);
  }

  downloadFile(fileName: string, folderName: string, bucketName: string): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/download`, body, { responseType: 'blob' });
  }

  deleteFile(fileName: string, folderName: string, bucketName: string): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/delete`, body);
  }

  getDownloadLink(fileName: string, folderName: string, bucketName: string, expiresIn: number): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName,
      expiresIn: expiresIn
    };

    return this.http.post(`${this.url}/get-download-link`, body);
  }

  /**
   * Extracts file information from a Digital Ocean Spaces URL and generates a fresh download link
   * @param url The Digital Ocean Spaces URL to extract information from
   * @param expiresIn Optional. The expiration time in seconds for the URL (default: 3600 = 1 hour)
   * @returns An Observable with the download link response
   */
  getFreshDownloadLinkFromUrl(url: string, expiresIn: number = 3600): Observable<any> {
    // Parse URL to extract bucket name and file path
    let bucketName = '';
    let fileName = '';

    try {
      // Extract bucket name
      const hostnameMatch = url.match(/https?:\/\/([^.]+)\.fra1\.digitaloceanspaces\.com/);
      if (hostnameMatch && hostnameMatch[1]) {
        bucketName = hostnameMatch[1];
      }

      // Extract file path - get everything after the domain and before any query params
      const pathMatch = url.match(/digitaloceanspaces\.com\/([^?]+)/);
      if (pathMatch && pathMatch[1]) {
        fileName = decodeURIComponent(pathMatch[1]);
      }

      // If we couldn't extract both, throw an error
      if (!bucketName || !fileName) {
        throw new Error('Could not extract file information from URL');
      }

      // Generate a fresh download link
      return this.getDownloadLink(fileName, '', bucketName, expiresIn);
    } catch (error) {
      console.error('Error parsing Digital Ocean URL:', error);
      throw error;
    }
  }

  listBuckets(): Observable<any> {
    return this.http.get(`${this.url}/list-buckets`);
  }
}