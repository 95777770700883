import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-event-time-dialog',
  templateUrl: './add-event-time-dialog.component.html',
  styleUrls: ['./add-event-time-dialog.component.css']
})
export class AddEventTimeDialogComponent implements OnInit {
  selectedTime;
  duration = 35;
  name = 'Lekcja Próbna';
  type = 'testLesson';
  description = '';
  constructor() { }

  ngOnInit(): void {
  }
  calculateLessonTimes() {
    const chosenTimeArr = this.selectedTime.split(':');
    chosenTimeArr.forEach(e => {
      e = parseInt(e);
    });

    const date = new Date();
    date.setHours(chosenTimeArr[0]);
    date.setMinutes(chosenTimeArr[1]);
    const startMinutes = date.getMinutes().toString().padStart(2, '0');
    const lessonStartTime = (date.getHours() + ':' + startMinutes);


    const lessonEndTimeDate = new Date(date.getTime() + this.duration * 60000);

    const endMinutes = lessonEndTimeDate.getMinutes().toString().padStart(2, '0');
    const lessonEndTime = (lessonEndTimeDate.getHours() + ':' + endMinutes);


    return {
      startDateObj: date, endDateObj: lessonEndTimeDate,
      startTime: this.selectedTime, endTime: lessonEndTime,
      duration: this.duration, name: this.name, description: this.description, type: this.type
    };
  }
}
