<div fxLayout="column" style="width: 100%">
  <h2 class="p-2" *ngIf="trainingMode">Szkolenia</h2>
  <mat-card *ngIf="!trainingMode" class="activity-filter-card">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxFlex>
        <mat-form-field appearance="outline" ngClass="mr-2">
          <mat-label>Szukaj...</mat-label>
          <input
            [(ngModel)]="activityToFind"
            matInput
            #input
            (keyup.enter)="findActivityByName()"
          />
        </mat-form-field>
        <div style="margin-bottom: 1.5em" fxLayoutAlign="start center">
          <button
            (click)="openAddActivityPanel(true, true)"
            color="primary"
            fxHide
            fxShow.gt-sm
            mat-flat-button
          >
            Dodaj Ćwiczenie
          </button>
          <button
            *ngIf="!isEditMode"
            (click)="recordDescription()"
            color="primary"
            mat-flat-button
            class="ml-2"
          >
            Nagraj opis
          </button>
          <button
            (click)="openAddActivityPanel(true, true)"
            color="primary"
            mat-icon-button
            fxHide
            fxShow.lt-md
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
      <button
        *ngIf="data.addToLessonMode && activitiesToAdd.length > 0"
        color="primary"
        mat-flat-button
        [mat-dialog-close]="activitiesToAdd"
        class="ml-2"
      >
        Dodaj wybrane ({{ activitiesToAdd.length }})
      </button>
    </div>
    <mat-button-toggle-group
      class="button-group"
      (change)="filterActivities()"
      [(ngModel)]="filter"
    >
      <mat-button-toggle
        *ngIf="userDetailsService.owner || userDetailsService.office"
        value="office"
        >Sekretariat
      </mat-button-toggle>
      <mat-button-toggle value="activities">Aktywności</mat-button-toggle>
      <mat-button-toggle value="activities-single"
        >Aktywności - indywidualni</mat-button-toggle
      >
      <mat-button-toggle value="theory">Teoria</mat-button-toggle>
      <mat-button-toggle value="training">Metodyka</mat-button-toggle>
      <mat-button-toggle value="app">Platforma</mat-button-toggle>
      <mat-button-toggle value="recordedLesson">Lekcje</mat-button-toggle>
      <mat-button-toggle value="forTraining">Szkolenia</mat-button-toggle>
      <mat-button-toggle *ngIf="userDetailsService.owner" value="null"
        >Wszystko
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card>
  <div class="p-3" style="height: 75%; overflow-y: auto">
    <mat-accordion *ngIf="activityToFind.length == 0">
      <div *ngFor="let course of courses">
        <mat-expansion-panel
          *ngIf="checkIfDisplayCourse(course)"
          [style.background-color]="
            course?.finishedActivtiesNumber == course.activities.length
              ? '#d1c4e9'
              : '#f3e5f5'
          "
          class="mt-2 mb-2"
        >
          <mat-expansion-panel-header>
            <div class="d-flex flex-column w-100">
              <div
                style="gap: 10px; line-height: 12px; font-weight: bold"
                [class.mat-small]="trainingMode"
                class="d-flex flex-row w-100 align-items-center"
              >
                <span style="max-width: 85%">
                  {{ course.name }}
                </span>
                <span style="flex: 1"></span>
                <div style="width: 50px" *ngIf="trainingMode">
                  {{ course?.finishedActivtiesNumber }} /
                  {{ course.activities.length }}
                </div>
              </div>
              <div class="mt-2 mr-3">
                <mat-progress-bar
                  *ngIf="trainingMode"
                  style="flex: 1"
                  mode="determinate"
                  [value]="
                    (course?.finishedActivtiesNumber /
                      course.activities.length) *
                    100
                  "
                >
                </mat-progress-bar>
              </div>
            </div>
          </mat-expansion-panel-header>

          <app-activity-card
            [trainingMode]="trainingMode"
            *ngFor="let activity of course.activities"
            (click)="openAddActivityPanel(false, false, activity)"
            [activity]="activity"
            [activitiesToAdd]="activitiesToAdd"
            (addActivity)="addActivityToLesson($event)"
            (removeActivity)="removeActivityFromLesson($event)"
            [addToLessonMode]="data.addToLessonMode"
          ></app-activity-card>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <div *ngFor="let activity of lessonActivities">
      <app-activity-card
        *ngIf="checkIfDisplayActivity(activity)"
        (click)="openAddActivityPanel(false, false, activity)"
        [activity]="activity"
        [activitiesToAdd]="activitiesToAdd"
        (addActivity)="addActivityToLesson($event)"
        (removeActivity)="removeActivityFromLesson($event)"
        [addToLessonMode]="data.addToLessonMode"
      ></app-activity-card>
    </div>
  </div>
</div>
