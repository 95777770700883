<div class="fullscreen-dialog-container">
  <div class="dialog-header">
    <h2>{{ data.title }}</h2>
    <button mat-icon-button (click)="closeDialog()" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="dialog-content">
    <div *ngFor="let item of data.items" class="item">
      <div class="item-content">
        <span class="item-name">{{ item.name }}</span>
        
        <div class="item-usage" *ngIf="item.usedIn && item.usedIn.length > 0">
          <div class="lesson-badges">
            <span class="lesson-badge" *ngFor="let lesson of item.usedIn.slice(0, 5)">{{ lesson }}</span>
            <span class="more-badge" *ngIf="item.usedIn.length > 5">+{{ item.usedIn.length - 5 }}</span>
          </div>
        </div>
        
        <span class="source-indicator" [ngClass]="item.source">
          {{ item.source === 'resource' ? data.getResourceName(item.resourceId) : 
             item.source === 'merged' ? 'Połączony' : 'Lekcja' }}
        </span>
      </div>
      
      <div class="item-actions">
        <button mat-icon-button color="accent" *ngIf="item.source === 'resource' || item.source === 'merged'"
                (click)="data.previewResource(item.resourceId)" matTooltip="Podgląd zasobu">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="addToLessonPlan(item)" matTooltip="Dodaj do bieżącej lekcji">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    
    <div *ngIf="data.items.length === 0" class="no-items">
      Brak elementów
    </div>
  </div>
</div>
