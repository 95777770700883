import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';

import { CoursesResourcesComponent } from './courses-resources.component';
import { CoursesResourcesAddEditComponent } from './courses-resources-add-edit/courses-resources-add-edit.component';
import { CoursesResourcesRoutingModule } from './courses-resources-routing.module';
import { PdfSelectionDialogModule } from './pdf-selection-dialog/pdf-selection-dialog.module';
import { PdfSelectionDialogComponent } from './pdf-selection-dialog/pdf-selection-dialog.component';
import { CurriculumAddEditComponent } from './curriculum-add-edit/curriculum-add-edit.component';
import { CurriculumJsonImportDialogComponent } from './curriculum-add-edit/curriculum-json-import-dialog/curriculum-json-import-dialog.component';
import { CurriculumViewComponent } from './curriculum-add-edit/curriculum-view/curriculum-view.component';
import { CurriculumManagementComponent } from './curriculum-management/curriculum-management.component';
import { CurriculumSelectionDialogComponent } from './curriculum-selection-dialog/curriculum-selection-dialog.component';

@NgModule({
    declarations: [
        CoursesResourcesComponent,
        CoursesResourcesAddEditComponent,
        CurriculumAddEditComponent,
        CurriculumJsonImportDialogComponent,
        CurriculumViewComponent,
        CurriculumManagementComponent,
        CurriculumSelectionDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatListModule,
        CoursesResourcesRoutingModule,
        PdfSelectionDialogModule
    ],
    exports: [
        CoursesResourcesComponent,
        CoursesResourcesAddEditComponent,
        CurriculumAddEditComponent,
        CurriculumManagementComponent,
        CommonModule,
        FormsModule
    ],
    entryComponents: [
        CoursesResourcesAddEditComponent,
        PdfSelectionDialogComponent,
        CurriculumAddEditComponent,
        CurriculumJsonImportDialogComponent,
        CurriculumViewComponent,
        CurriculumSelectionDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CoursesResourcesModule { } 