import { O } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { GenerateAudioService } from 'src/app/shared/services/database/generate-audio.service';
import { FactsService } from '../facts.service';
import { VideoMakerService } from 'src/app/video-maker/video-maker.service';
import { VideosRepoComponent } from '../../videos-repo/videos-repo.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FactCreatePostComponent } from '../fact-create-post/fact-create-post.component';
import { UserDetailsService } from 'src/app/profile/user-details.service';

@Component({
  selector: 'app-fact-full',
  templateUrl: './fact-full.component.html',
  styleUrls: ['./fact-full.component.scss']
})
export class FactFullComponent implements OnInit {
  @Input() currentCategory: any;
  @Input() fact: any;
  @Input() select = false;
  @Output() selectEvent = new EventEmitter();
  @Output() removeFactEvent = new EventEmitter();
  @Output() updateFactEvent = new EventEmitter();
  selectedChat = null;
  creatingVideo = false;
  sub: any;
  editMode: any;
  constructor(
    private generateAudioService: GenerateAudioService,
    private chatBotsService: ChatbotsService,
    private chatService: ChatService,
    private factsService: FactsService,
    private videoMakerService: VideoMakerService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private userDetailsService: UserDetailsService
  ) { }

  ngOnInit() {
    this.sub = this.generateAudioService.savedFreeAudioItemListener().subscribe((data: any) => {


      if (this.fact._id === data.itemId) {
        this.fact[data.prop] = 'https://backend-int.linget.it/api/read/getaudiodata?url=' + data.url;
        this.updateFact();
      }
    })
  }
  encodeTextToSendViaHttp(text: string) {
    return encodeURIComponent(text);
  }

  selectFact(event: MatCheckboxChange) {

    this.fact.selected = event.checked;
    this.selectEvent.emit(this.fact);
  }

  markFactAsDone(fact) {
    if (!fact.markedFinishedByUsers) {
      fact.markedFinishedByUsers = []
    }
    if (!fact.markedFinishedByUsers.find(user => user.user.id === this.userDetailsService.getSimpleUserDetails().id)) {
      fact.markedFinishedByUsers.push({ user: this.userDetailsService.getSimpleUserDetails(), date: Date.now() })
    }

    this.updateFact();
  }
  markFactAsUndone(fact) {
    fact.markedFinishedByUsers = fact.markedFinishedByUsers.filter(user => user.user.id !== this.userDetailsService.getSimpleUserDetails().id)

    this.updateFact();
  }
  checkIfMarkedByUserAsDone(fact) {
    return fact?.markedFinishedByUsers?.some(user => user.user.id === this.userDetailsService.getSimpleUserDetails().id)
  }


  createVideo(reel?) {
    let title = "";
    let videoUrl = '';
    // if (confirm('Custom video url?')) {
    //   videoUrl = prompt('Enter the video url');
    // }
    title = prompt('Enter the title of the video');
    this.creatingVideo = true;
    let text = reel ? this.fact.texts.English.replaceAll(':', '').replaceAll('+', 'plus') + ' ' + this.fact.explanationOfTheAnswer.English.replaceAll(':', '').replaceAll('+', 'plus') : this.fact.texts.English.replaceAll(':', '').replaceAll('+', 'plus')
    let explanation = reel ? this.fact.texts.Polish.replaceAll(':', '').replaceAll('+', 'plus') + ' ' + this.fact.explanationOfTheAnswer.Polish.replaceAll(':', '').replaceAll('+', 'plus') : this.fact.explanationOfTheAnswer.English.replaceAll(':', '').replaceAll('+', 'plus')



    this.videoMakerService.createYoutubeShort(this.fact.video,
      text,
      explanation, this.fact.audio, this.fact.explanationAudio, this.fact.texts.English + '.mp4', this.fact.image, title, reel).subscribe(
        res => {

          this.fact.shortVideo = res.shortUrl;
          this.fact.shortCreated = Date.now();
          this.updateFact();
          setTimeout(() => {
            this.snackbarService.showSnackBar('Video created successfully', 'success');
          }, 2000);
          this.creatingVideo = false;
          // this.factsService.updateFact(this.fact._id, this.fact).subscribe(
          //   res => {
          //      // Log the response
          //   },
          //   err => {
          //     console.error('Error:', err); // Log any error that occurs
          //   }
          // )
          //update the fact
        },
        err => {
          this.snackbarService.showSnackBar('Error creating video', 'error');
          console.error('Error:', err); // Log any error that occurs
        }
      )
  }
  createImage() {
    let imagesCreator = this.chatBotsService.chatbots.find((chatbot: any) => chatbot.name === 'Tom')

    const data = {
      messages: [{ role: 'user', content: this.fact.imageTag }],
      aiProvider: imagesCreator.aiProvider,
      model: imagesCreator.model,
      maxTokens: imagesCreator.maxTokens,
      maxMessages: imagesCreator.maxMessages,
      systemMsg: imagesCreator.systemMsg,
      files: null
    };

    this.chatService.sendPromptToChosenAi(data).subscribe(
      res => {

        this.fact.image = res.res.downloadLink;
        // Check if fact id is defined
        this.factsService.updateFact(this.fact._id, this.fact).subscribe(
          res => {
            // Log the response
            this.snackbarService.showSnackBar('Image created successfully', 'success');
          },
          err => {
            console.error('Error:', err); // Log any error that occurs
            this.snackbarService.showSnackBar('Error creating image', 'error');
          }
        )
        //update the fact
      },
      err => {
        console.error('Error:', err); // Log any error that occurs
        this.snackbarService.showSnackBar('Error updating the fact', 'error');
      }
    )
  }
  createAbcQuestion() {
    this.selectedChat = this.chatBotsService.chatbots.find(chatbot => chatbot.name === 'Hank');
    this.chatService.sendPromptToChosenAi({
      messages: [{ role: 'user', content: 'fact:' + this.fact.texts.English + 'explanation:' + this.fact.explanationOfTheAnswer.English }],
      aiProvider: this.selectedChat.aiProvider,
      model: this.selectedChat.model,
      maxTokens: this.selectedChat.maxTokens,
      maxMessages: this.selectedChat.maxMessages,
      systemMsg: this.selectedChat.systemMsg,
      files: null
    }).subscribe(
      (res) => {

        const transformedFact = this.extractJsonFromText(res.res.content);

        this.fact.ABCQuestion = transformedFact.question;
        this.fact.ABCAnswers = transformedFact.answers;
        this.fact.ABCcorrectAnswer = transformedFact.correctAnswer;
        this.fact.ABCQuestionWithAnswers = transformedFact.questionWithAnswers;
        this.fact.ABCExplanation = transformedFact.ABCExplanation;

        this.updateFact();
      }
    );
  }
  extractJsonFromText(text: string) {
    const jsonStart = text.indexOf('{');
    const jsonEnd = text.lastIndexOf('}');
    if (jsonStart === -1 || jsonEnd === -1) {
      return null;
    }
    return JSON.parse(text.substring(jsonStart, jsonEnd + 1));
  }
  createPost() {
    this.dialog.open(FactCreatePostComponent, {
      width: '100vw',
      height: '100vh',
      minHeight: '100vh',
      minWidth: '100vw',
      data: { fact: this.fact }
    })
  }

  editFinished() {
    this.editMode = false;
    this.updateFact();
  }
  createTfQuestion() {
    const answers = {
      English: ['true', 'false'],
      French: ['vrai', 'faux'],
      German: ['wahr', 'falsch'],
      Hindi: ['सही', 'गलत'],
      Indonesian: ['benar', 'salah'],
      Polish: ['prawda', 'fałsz'],
      PortugueseBrazil: ['verdadeiro', 'falso'],
      Spanish: ['verdadero', 'falso'],
      Thai: ['จริง', 'เท็จ'],
      Turkish: ['doğru', 'yanlış'],
      Vietnamese: ['đúng', 'sai']
    };
    this.selectedChat = this.chatBotsService.chatbots.find(chatbot => chatbot.name === 'Gus');
    this.chatService.sendPromptToChosenAi({
      messages: [{ role: 'user', content: 'fact:' + this.fact.texts.English + 'explanation:' + this.fact.explanationOfTheAnswer.English }],
      aiProvider: this.selectedChat.aiProvider,
      model: this.selectedChat.model,
      maxTokens: this.selectedChat.maxTokens,
      maxMessages: this.selectedChat.maxMessages,
      systemMsg: this.selectedChat.systemMsg,
      files: null
    }).subscribe(
      (res) => {

        const transformedFact = this.extractJsonFromText(res.res.content);

        this.fact.TFQuestion = transformedFact.question;
        this.fact.TFcorrectAnswer = transformedFact.correctAnswer;
        this.fact.TFAnswers = answers
        this.fact.TFExplanation = transformedFact.TFExplanation;
        this.updateFact();



        // this.updateFactEvent.emit(transformedFact);
      }
    );
  }
  removeFact() {
    this.removeFactEvent.emit(this.fact._id);
  }
  generateQuestionsAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.texts.English, 'English', 'male', 'en-US-RogerNeural', null, 'audio', '0%');
  }
  generateDescriptionsAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.explanationOfTheAnswer.English, 'English', 'male', 'en-US-RogerNeural', null, 'explanationAudio', '0%');

  }
  generateABCQuestionAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.ABCQuestionWithAnswers.English.replace('B)', ',, B) , ').replace('C)', ',, C), ').concat('?'), 'English', 'male', 'en-US-RogerNeural', null, 'ABCQuestionWithAnswersAudio', '0%');

  }
  generateABCExplanationAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.ABCExplanation.English, 'English', 'male', 'en-US-RogerNeural', null, 'ABCExplanationAudio', '0%');

  }
  generateTFQuestionAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.TFQuestion.English, 'English', 'male', 'en-US-RogerNeural', null, 'TFQuestionAudio', '0%');

  }
  generateTFExplanationAudio() {
    this.generateAudioService.saveNewAudioToRepo(this.fact._id, this.fact.TFExplanation.English, 'English', 'male', 'en-US-RogerNeural', null, 'TFExplanationAudio', '0%');

  }

  translateProperty(property: string,) {
    this.selectedChat = this.chatBotsService.chatbots.find(chatbot => chatbot.name === 'Jessie');
    this.chatService.sendPromptToChosenAi({
      messages: [{ role: 'user', content: JSON.stringify(this.fact[property]) }],
      aiProvider: this.selectedChat.aiProvider,
      model: this.selectedChat.model,
      maxTokens: this.selectedChat.maxTokens,
      maxMessages: this.selectedChat.maxMessages,
      systemMsg: this.selectedChat.systemMsg,
      files: null
    }).subscribe(
      (res) => {

        this.fact[property] = this.extractJsonFromText(res.res.content);
        this.updateFact();
      }
    );
  }


  updateFact() {
    this.fact.updated = Date.now();
    this.updateFactEvent.emit(this.fact);
  }
  addVideoToFact() {
    const dialogRef = this.dialog.open(VideosRepoComponent, {
      width: '80%',
      data: { fact: this.fact }
    })
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.fact.video = result;
        this.updateFact();
      }
    });

  }
  downloadFactsShort() {
    let shortUrl = this.fact.shortVideo
    if (!shortUrl) {
      return
    }
    window.open(shortUrl, '_blank');

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
