import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pdf2ExercisesService } from 'src/app/pdf2/pdf2-exercises.service';

@Component({
    selector: 'app-pdf-selection-dialog',
    templateUrl: './pdf-selection-dialog.component.html',
    styleUrls: ['./pdf-selection-dialog.component.css']
})
export class PdfSelectionDialogComponent implements OnInit {
    selectedPdfs: any[] = [];
    childrenPdfTypes: any[] = [];
    sentencesPdfTypes: any[] = []; // For vocab sets
    freeSetPdfTypes: any[] = [];
    selectedTab = 0;

    constructor(
        public dialogRef: MatDialogRef<PdfSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private pdf2ExercisesService: Pdf2ExercisesService
    ) { }

    ngOnInit(): void {
        this.childrenPdfTypes = this.pdf2ExercisesService.childrenPdfTypes;
        this.sentencesPdfTypes = this.pdf2ExercisesService.sentencesPdfTypes;
        this.freeSetPdfTypes = this.pdf2ExercisesService.freeSetPdfTypes;
    }

    togglePdfSelection(pdf: any): void {
        const index = this.selectedPdfs.findIndex(p => p.name === pdf.name);
        if (index === -1) {
            // Generate a unique ID for this homework item
            const id = 'pdf_' + new Date().getTime() + '_' + Math.floor(Math.random() * 1000);
            this.selectedPdfs.push({
                id: id,
                name: pdf.name,
                displayName: pdf.displayName,
                type: pdf.type,
                descriptionPl: pdf.descriptionPl,
                descriptionEn: pdf.descriptionEn
            });
        } else {
            this.selectedPdfs.splice(index, 1);
        }
    }

    isPdfSelected(pdf: any): boolean {
        return this.selectedPdfs.some(p => p.name === pdf.name);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.selectedPdfs);
    }
} 