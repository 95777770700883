<div fxLayout="column" style="height: 90%; width: 100%">
  <div
    fxLayoutGap="10px"
    fxLayout="row"
    fxFlex
    style="height: 100%; width: 100%"
  >
    <div fxFlex="50" fxLayout="column" style="border: 2px solid #673ab7">
      <h3>Przychody</h3>
      <div class="ml-2">
        <h3>Ilość miesięcy: {{ numberOfMonthsIncomes }}</h3>
        <div style="overflow-y: auto" fxLayout="column">
          <div
            (click)="item.selected = !item.selected"
            [style.background-color]="
              helpers.isEven(i) && !item.selected
                ? '#ffe0b2'
                : !helpers.isEven(i) && !item.selected
                ? '#ffcc80'
                : '#ef9a9a'
            "
            *ngFor="let item of summarizedIncomes; let i = index"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="pt-1 pb-1"
          >
            <span class="ml-3">{{ item.name }}:</span>
            <span style="margin-right: 10vw">{{ item.value }}</span>
          </div>
        </div>
        <hr />
        <h3>Razem Przychody: {{ allIncomes }}</h3>
        <h2>Stany:</h2>
        <div style="width: 100%" fxLayout="column">
          <span
            style="width: 100%"
            class="pt-1 pb-1"
            [style.background-color]="
              helpers.isEven(i) && !item.selected
                ? '#bbdefb'
                : !helpers.isEven(i) && !item.selected
                ? '#90caf9'
                : '#ef9a9a'
            "
            (click)="item.selected = !item.selected"
            *ngFor="let item of finances?.states; let i = index"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <span>{{ item.name + ": " }}</span>
            <span style="margin-right: 10vw">{{ item.value }}</span>
          </span>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxLayout="column" style="border: 2px solid #673ab7">
      <h3>Wydatki</h3>
      <h3>Ilość miesięcy: {{ numberOfMonthsExpenses }}</h3>
      <div style="overflow-y: auto" fxLayout="column">
        <div
          (click)="item.selected = !item.selected"
          [style.background-color]="
            helpers.isEven(i) && !item.selected
              ? '#b39ddb'
              : !helpers.isEven(i) && !item.selected
              ? '#ede7f6'
              : '#ef9a9a'
          "
          *ngFor="let item of summarizedExpenses; let i = index"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="pt-1 pb-1"
        >
          <span class="ml-3">{{ item.name }}:</span>
          <span *ngIf="!item.teacher"
            >{{ numberOfMonthsExpenses + " * " }}
            {{ item.value / numberOfMonthsExpenses | number: "1.0-0" }}
          </span>
          <span *ngIf="item.teacher"
            >{{ numberOfMonthsExpensesTeacher + " * " }}
            {{ item.value / numberOfMonthsExpensesTeacher | number: "1.0-0" }}
          </span>
          <span style="margin-right: 10vw">{{ item.value }}</span>
        </div>
      </div>
      <hr />
      <h3>Razem Wydatki: {{ allExpenses }}</h3>
    </div>
  </div>
  <div
    class="mt-3"
    style="width: 100%"
    fxLayout="row"
    fxLayoutGap="20px"
    fxLayoutAlign="center center"
  >
    <h1>Wynik:{{ result }}</h1>
    <h1>Wynik Firma:{{ companyResult }}</h1>
  </div>

  <!-- Finance Stats Section -->
  <div
    *ngIf="!statsLoading && financeStats"
    class="mt-4"
    style="width: 100%"
    fxLayout="column"
  >
    <h2>Finance Statistics</h2>
    <div fxLayout="row wrap" fxLayoutGap="20px" class="mt-2">
      <mat-card fxFlex="30">
        <mat-card-header>
          <mat-card-title>Total Income</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h2>{{ financeStats.totalIncome || 0 }}</h2>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="30">
        <mat-card-header>
          <mat-card-title>Total Expenses</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h2>{{ financeStats.totalExpenses || 0 }}</h2>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="30">
        <mat-card-header>
          <mat-card-title>Balance</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <h2>{{ financeStats.balance || 0 }}</h2>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Teacher Costs Section -->
  <div class="mt-4" style="width: 100%" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2>Teacher Costs</h2>
      <button mat-raised-button color="primary" (click)="addTeacherCost()">
        Add Teacher Cost
      </button>
    </div>

    <div *ngIf="isLoading" fxLayoutAlign="center center" class="mt-2">
      <mat-spinner diameter="40"></mat-spinner>
    </div>

    <div
      *ngIf="!isLoading && teachersCosts.length === 0"
      class="mt-2"
      fxLayoutAlign="center center"
    >
      <p>No teacher costs found. Add some using the button above.</p>
    </div>

    <mat-table
      *ngIf="!isLoading && teachersCosts.length > 0"
      [dataSource]="teachersCosts"
      class="mat-elevation-z4 mt-2"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Teacher Name</mat-header-cell>
        <mat-cell *matCellDef="let cost">{{ cost.name }}</mat-cell>
      </ng-container>

      <!-- Value Column -->
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef>Hourly Rate (PLN)</mat-header-cell>
        <mat-cell *matCellDef="let cost">{{ cost.value }}</mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let cost; let i = index">
          <button
            mat-icon-button
            color="primary"
            (click)="updateTeacherCost(i, cost)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteTeacherCost(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['name', 'value', 'actions']"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'value', 'actions']"
      ></mat-row>
    </mat-table>
  </div>

  <!-- Database Finance Entries Section -->
  <div class="mt-4" style="width: 100%" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2>Finance Entries</h2>
      <div fxLayout="row" fxLayoutGap="10px">
        <button mat-raised-button color="accent" (click)="createTransaction()">
          Create Transaction
        </button>
        <button mat-raised-button color="primary" (click)="openFinanceDialog()">
          Add New Entry
        </button>
      </div>
    </div>

    <div *ngIf="isLoading" fxLayoutAlign="center center" class="mt-2">
      <mat-spinner diameter="40"></mat-spinner>
    </div>

    <div
      *ngIf="!isLoading && financeEntries.length === 0"
      class="mt-2"
      fxLayoutAlign="center center"
    >
      <p>No finance entries found. Add some using the button above.</p>
    </div>

    <mat-table
      *ngIf="!isLoading && financeEntries.length > 0"
      [dataSource]="financeEntries"
      class="mat-elevation-z4 mt-2"
    >
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <span
            [ngClass]="{
              'text-success': entry.type === 'income',
              'text-danger': entry.type === 'expense'
            }"
          >
            {{ entry.type === "income" ? "Income" : "Expense" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let entry">{{ entry.name }}</mat-cell>
      </ng-container>

      <!-- Value Column -->
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell *matCellDef="let entry">{{ entry.value }}</mat-cell>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
        <mat-cell *matCellDef="let entry">{{ entry.category }}</mat-cell>
      </ng-container>

      <!-- Company Column -->
      <ng-container matColumnDef="isCompany">
        <mat-header-cell *matHeaderCellDef>Company</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <mat-icon *ngIf="entry.isCompany">check</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Teacher Column -->
      <ng-container matColumnDef="isTeacher">
        <mat-header-cell *matHeaderCellDef>Teacher</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <mat-icon *ngIf="entry.isTeacher">check</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let entry">
          <button
            mat-icon-button
            color="primary"
            (click)="openFinanceDialog(entry)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteFinance(entry.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="[
          'type',
          'name',
          'value',
          'category',
          'isCompany',
          'isTeacher',
          'actions'
        ]"
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let row;
          columns: [
            'type',
            'name',
            'value',
            'category',
            'isCompany',
            'isTeacher',
            'actions'
          ]
        "
      ></mat-row>
    </mat-table>
  </div>

  <!-- Add this section at the appropriate place in your template -->
  <div class="finance-summary" *ngIf="!statsLoading && financeStats">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Financial Summary</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="summary-section">
          <h3>Income</h3>
          <div class="summary-item">
            <span>Monthly Income:</span>
            <span>{{ financeStats.income.monthly | currency: "PLN" }}</span>
          </div>
          <div class="summary-item">
            <span>Fixed Income:</span>
            <span>{{ financeStats.income.fixed | currency: "PLN" }}</span>
          </div>
          <div class="summary-item">
            <span>Transaction Income:</span>
            <span>{{
              financeStats.income.transactions | currency: "PLN"
            }}</span>
          </div>
          <div class="summary-item total">
            <span>Total Income:</span>
            <span>{{ financeStats.income.total | currency: "PLN" }}</span>
          </div>
        </div>

        <div class="summary-section">
          <h3>Expenses</h3>
          <div class="summary-item">
            <span>Monthly Expenses:</span>
            <span>{{ financeStats.expenses.monthly | currency: "PLN" }}</span>
          </div>
          <div class="summary-item">
            <span>Fixed Expenses:</span>
            <span>{{ financeStats.expenses.fixed | currency: "PLN" }}</span>
          </div>
          <div class="summary-item">
            <span>Teacher Costs:</span>
            <span>{{
              financeStats.expenses.teacherCosts | currency: "PLN"
            }}</span>
          </div>
          <div class="summary-item">
            <span>Transaction Expenses:</span>
            <span>{{
              financeStats.expenses.transactions | currency: "PLN"
            }}</span>
          </div>
          <div class="summary-item total">
            <span>Total Expenses:</span>
            <span>{{ financeStats.expenses.total | currency: "PLN" }}</span>
          </div>
        </div>

        <div class="summary-section">
          <h3>Balance</h3>
          <div
            class="summary-item"
            [ngClass]="{
              positive: financeStats.balance > 0,
              negative: financeStats.balance < 0
            }"
          >
            <span>Net Balance:</span>
            <span>{{ financeStats.balance | currency: "PLN" }}</span>
          </div>
        </div>

        <div class="summary-section">
          <h3>Current State</h3>
          <div class="summary-item">
            <span>Account Balance:</span>
            <span>{{
              financeStats.currentState.accountBalance | currency: "PLN"
            }}</span>
          </div>
          <div class="summary-item">
            <span>Cash:</span>
            <span>{{ financeStats.currentState.cash | currency: "PLN" }}</span>
          </div>
          <div class="summary-item">
            <span>Unpaid:</span>
            <span>{{
              financeStats.currentState.unpaid | currency: "PLN"
            }}</span>
          </div>
          <div class="summary-item">
            <span>Loss:</span>
            <span>{{ financeStats.currentState.loss | currency: "PLN" }}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="loading-spinner" *ngIf="statsLoading">
    <mat-spinner></mat-spinner>
  </div>

  <style>
    .finance-summary {
      margin: 20px;
    }

    .summary-section {
      margin-bottom: 24px;
    }

    .summary-section h3 {
      color: #333;
      margin-bottom: 12px;
      border-bottom: 1px solid #eee;
      padding-bottom: 8px;
    }

    .summary-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 4px 0;
    }

    .summary-item.total {
      font-weight: bold;
      border-top: 1px solid #eee;
      padding-top: 8px;
      margin-top: 8px;
    }

    .positive {
      color: #4caf50;
    }

    .negative {
      color: #f44336;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
    }
  </style>

  <button
    class="mt-4"
    style="width: 100%"
    mat-raised-button
    color="primary"
    mat-dialog-close
  >
    Zamknij
  </button>
</div>
