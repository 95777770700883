import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { fromEvent } from "rxjs";
import { switchMap, takeUntil, pairwise } from "rxjs/operators";
import { CanvasService } from "./canvas.service";
@Component({
  selector: "app-canvas",
  templateUrl: "./canvas.component.html",
  styleUrls: ["./canvas.component.css"],
})
export class CanvasComponent implements OnInit, AfterViewInit {
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("imgCanvas") imgCanvas: ElementRef;
  ctx;
  @Input() displayMode;
  @Input() public width = 300;
  @Input() public height = 300;
  // @Input() public pointsInput = [];
  @Input() public lineThickness = 3;
  @Input() public mode: "sender" | "receiver";
  @Input() public drawingEnabled;
  @Input() public showControls = true;
  @Input() public color;
  @Input() listenForPathChanged = false;
  @Output() pathChanged = new EventEmitter();
  @Input() id;
  @Input() public imageSrc = null;
  @Output() image = new EventEmitter();
  canvasImg;
  intervalPaused = true;
  message;
  startingPoint = null;
  pointsToEmit = [];
  points = [];
  private cx: CanvasRenderingContext2D;
  imgCx: CanvasRenderingContext2D;
  sub: any;
  emitterInterval;
  drawing: boolean = false;
  refreshInterval: NodeJS.Timeout;

  constructor(private canvasService: CanvasService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(
      "🚀 ~ file: canvas.component.ts ~ line 52 ~ CanvasComponent ~ ngOnChanges ~ changes",
      changes
    );
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }
  changeDrawing() {
    this.drawingEnabled = true;
  }
  ngAfterViewInit(): void {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    const imgCanvasEl: HTMLCanvasElement = this.imgCanvas.nativeElement;
    this.cx = canvasEl.getContext("2d");
    this.imgCx = imgCanvasEl.getContext("2d");
    var img = new Image();
    img.src = this.imageSrc;
    img.onload = () => {
      this.imgCx.drawImage(img, 0, 0, this.width, this.height);
    };
    imgCanvasEl.width = this.width;
    imgCanvasEl.height = this.height;
    canvasEl.width = this.width;
    canvasEl.height = this.height;

    this.captureEvents(canvasEl);
  }

  ngOnInit(): void {
    setInterval(() => {
      if (!this.drawing) {
        // this.redraw(false);
      }
    }, 1000);
    this.emitterInterval = setInterval(() => {
      if (!this.intervalPaused) {
        this.pathChanged.emit({
          points: this.pointsToEmit[this.pointsToEmit.length - 1],
          undo: false,
        });
      }
    }, 20);
    this.sub = this.canvasService.messagesListener().subscribe((message) => {
      this.message = message;
      switch (this.message.type) {
        case "changeColor":
          this.color = this.message.color;
          break;
        case "changeLineThickness":
          this.lineThickness = this.message.lineThickness;
          break;
        case "undoClicked":
          this.canvasUndo();
          break;
        case "captureImage":
          if (this.id === this.message.id) {
            this.captureImage();
          }
          break;
        case "redraw":
          if (this.id === this.message.playerId) {
            if (this.message.points) {
              if (this.message.undo) {
                this.points = this.message.points;
                this.redraw(true);
              }
              // if(!this.message.undo){
              //   this.points = this.message.points;
              //   this.redraw();
              // }
              this.remoteDrawOnCanvas(this.message.points);
            }
          }

          break;

        default:
          break;
      }
    });
  }
  colorChanged(event) {
    this.color = event.value;
  }
  lineChanged(event) {
    this.lineThickness = event;
  }
  undoClicked() {
    this.canvasUndo();
  }
  captureImage() {
    this.cx.clearRect(0, 0, this.width, this.height);
    var img = new Image();
    img.src = this.imageSrc;
    img.onload = () => {
      this.cx.lineJoin = "round";
      this.cx.lineCap = "round";
      this.cx.drawImage(img, 0, 0, this.width, this.height);
      this.points.forEach((point, index) => {
        if (point?.mode === "start") {
          this.cx.moveTo(point.point.x, point.point.y); // from
          this.cx.strokeStyle = point.color;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
          this.cx.lineWidth = point.lineThickness;
          this.cx.beginPath();
        }
        if (point?.mode === "draw") {
          this.cx.lineTo(point.point.x, point.point.y);
          this.cx.strokeStyle = point.color;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
          this.cx.lineWidth = point.lineThickness;
          this.cx.stroke();
        }
        if (point?.mode === "end") {
          this.cx.strokeStyle = point.color;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
          this.cx.lineWidth = point.lineThickness;
        }
      });
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      this.cx.shadowBlur = 2;
      this.cx.lineCap = "round";
      this.cx.stroke();
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      this.canvasImg = canvasEl.toDataURL();
      this.image.emit({ image: this.canvasImg, id: this.id });
      this.redraw(true);
    };
  }
  private captureEvents(canvasEl: HTMLCanvasElement) {
    const mouseDowns = fromEvent(canvasEl, "mousedown");
    const mouseUps = fromEvent(canvasEl, "mouseup");
    const mousemove = fromEvent(canvasEl, "mousemove");
    const mouseenter = fromEvent(canvasEl, "mouseenter");
    const touchstarts = fromEvent(canvasEl, "touchstart");
    const touchmove = fromEvent(canvasEl, "touchmove");
    const touchends = fromEvent(canvasEl, "touchend");
    let lastPoint;
    mouseDowns.forEach((event: MouseEvent) => {
      this.drawing = true;

      const currentPos = {
        x: event.offsetX,
        y: event.offsetY,
      };
      this.startingPoint = currentPos;
      this.pointsToEmit = [];
      this.pointsToEmit.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "start",
      });
      this.points.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "start",
      });
      this.pathChanged.emit({
        points: this.pointsToEmit[this.pointsToEmit.length - 1],
        undo: false,
      });
      this.pointsToEmit.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "draw",
      });
      this.points.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "draw",
      });
      this.pathChanged.emit({
        points: this.pointsToEmit[this.pointsToEmit.length - 1],
        undo: false,
      });

      this.intervalPaused = false;
    });
    mouseenter.forEach((event: MouseEvent) => {
      console.log(
        "🚀 ~ file: canvas.component.ts ~ line 192 ~ CanvasComponent ~ mouseenter.forEach ~ event",
        event
      );
    });
    mouseUps.forEach((event: MouseEvent) => {

      const currentPos = {
        x: event.clientX,
        y: event.clientY,
      };
      this.pointsToEmit = [];
      this.points.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "end",
      });
      this.pointsToEmit.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "end",
      });
      this.pathChanged.emit({
        points: this.pointsToEmit[this.pointsToEmit.length - 1],
        undo: false,
      });

      this.drawing = false;
      this.intervalPaused = true;
    });
    // mousemove.forEach((event: MouseEvent) => {

    // if(this.pointsToEmit.length >100){
    // this.pointsToEmit = [];
    // }
    //         this.drawing = false;
    //         this.intervalPaused = true;
    // });

    touchstarts.forEach((event: TouchEvent) => {

      this.drawing = true;
      const currentPos = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
      this.startingPoint = currentPos;

      this.pointsToEmit = [];
      this.pointsToEmit.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "start",
      });
      this.points.push({
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "start",
      });
      this.intervalPaused = false;
    });
    touchmove.forEach((event: TouchEvent) => {
      const currentPos = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
      lastPoint = {
        point: currentPos,
        lineThickness: this.lineThickness,
        color: this.color,
        mode: "end",
      };
      event.preventDefault();
    });
    touchends.forEach((event: TouchEvent) => {
      this.points.push(lastPoint);
      this.pointsToEmit = [];
      this.pointsToEmit.push(lastPoint);
      this.intervalPaused = true;
      this.drawing = false;
    });

    fromEvent(canvasEl, "mousedown")
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, "mousemove").pipe(
            takeUntil(fromEvent(canvasEl, "mouseup")),
            takeUntil(fromEvent(canvasEl, "mouseleave")),

            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };
        if (this.startingPoint) {
          this.drawOnCanvas(this.startingPoint, currentPos);

          this.startingPoint = null;
        } else {
          this.drawOnCanvas(prevPos, currentPos);
        }
        res[0].stopPropagation();
        res[1].stopPropagation();
      });
    fromEvent(canvasEl, "touchstart")
      .pipe(
        switchMap((e) => {
          return fromEvent(canvasEl, "touchmove").pipe(
            takeUntil(fromEvent(canvasEl, "touchend")),
            takeUntil(fromEvent(canvasEl, "mouseleave")),

            pairwise()
          );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top,
        };

        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top,
        };

        if (this.startingPoint) {
          this.drawOnCanvas(prevPos, currentPos);
          this.startingPoint = null;
        } else {
          this.drawOnCanvas(prevPos, currentPos);
        }
      });
  }

  redraw(clearCanvas?) {
    if (this.points.length === 0) {
      this.cx.clearRect(0, 0, this.width, this.height);
    } else {
      if (this.cx && clearCanvas) {
        this.cx.clearRect(0, 0, this.width, this.height);
      }
      // else{
      //   return;
      // }
      this.points.forEach((point, index) => {
        if (point?.mode === "start") {
          this.cx.moveTo(point.point.x, point.point.y); // from
          this.cx.strokeStyle = point.color;
          this.cx.lineWidth = point.lineThickness;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
          this.cx.beginPath();
        }
        if (point?.mode === "draw") {
          this.cx.lineTo(point.point.x, point.point.y);
          this.cx.strokeStyle = point.color;
          this.cx.lineWidth = point.lineThickness;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
        }
        if (point?.mode === "end") {
          this.cx.strokeStyle = point.color;
          this.cx.lineWidth = point.lineThickness;
          this.cx.shadowOffsetX = 0;
          this.cx.shadowOffsetY = 0;
          this.cx.shadowBlur = 1;
          this.cx.shadowColor = point.color;
          this.cx.stroke();
        }
      });
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      this.cx.shadowBlur = 1;
      this.cx.lineCap = "round";
      this.cx.setLineDash([4, 2]);
      this.cx.stroke();
    }
  }

  canvasUndo() {
    this.cx.clearRect(0, 0, this.width, this.height);
    const endPoints = this.points.filter((point) => point?.mode === "end");
    const lastEndPoint = this.points.findIndex(
      (point) => point == endPoints[endPoints.length - 1]
    );
    if (lastEndPoint !== -1) {
      if (lastEndPoint === this.points.length - 1) {
        this.points = this.points.filter(
          (point, index) => index < lastEndPoint
        );
        const endPoints2 = this.points.filter((point) => point?.mode === "end");
        const lastEndPoint2 = this.points.findIndex(
          (point) => point == endPoints2[endPoints2.length - 1]
        );
        this.points = this.points.filter(
          (point, index) => index < lastEndPoint2
        );
      } else {
        this.points = this.points.filter(
          (point, index) => index < lastEndPoint
        );
      }
      this.cx.clearRect(0, 0, this.width, this.height);
      this.redraw(true);
    } else {
      this.points = [];
      this.cx.clearRect(0, 0, this.width, this.height);
    }
    if (this.listenForPathChanged) {
      setTimeout(() => {
        this.pathChanged.emit({ points: this.points, undo: true });
      }, 100);
    }
  }
  lastIndexOf = (array, key) => {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].key === key) return i;
    }
    return -1;
  };
  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (this.drawingEnabled) {
      if (!this.cx) {
        return;
      }

      this.cx.beginPath();
      this.cx.lineJoin = "round";
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      // this.cx.shadowBlur = 1;
      this.cx.lineWidth = this.lineThickness;
      this.cx.strokeStyle = this.color;
      this.cx.lineCap = "round";
      if (prevPos) {
        const currentPoint = { x: currentPos.x, y: currentPos.y };
        const point = { x: prevPos.x, y: prevPos.y };
        this.points.push({
          point: currentPoint,
          lineThickness: this.lineThickness,
          color: this.color,
          mode: "draw",
        });
        this.pointsToEmit.push({
          point: currentPoint,
          lineThickness: this.lineThickness,
          color: this.color,
          mode: "draw",
        });
        this.intervalPaused = false;
        this.cx.moveTo(prevPos.x, prevPos.y); // from
        this.cx.lineTo(currentPos.x, currentPos.y);
        this.cx.stroke();
      }
    }
  }

  private remoteDrawOnCanvas(point) {
    if (!this.cx) {
      return;
    }
    this.cx.setLineDash([4, 2]);
    this.cx.lineJoin = "round";
    this.cx.lineCap = "round";

    if (point?.mode === "start") {
      this.points.push(point);
      this.cx.moveTo(point.point.x, point.point.y); // from
      this.cx.strokeStyle = point.color;
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      this.cx.shadowBlur = 1;
      this.cx.shadowColor = point.color;
      this.cx.lineWidth = point.lineThickness;
      this.cx.beginPath();
    }
    if (point?.mode === "draw") {
      this.cx.lineTo(point.point.x, point.point.y); // from
      this.cx.strokeStyle = point.color;
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      this.cx.shadowBlur = 1;
      this.cx.shadowColor = point.color;
      this.cx.lineWidth = point.lineThickness;
      this.points.push(point);

      this.cx.stroke();
    }
    if (point?.mode === "end") {
      this.points.push(point);
      this.cx.shadowOffsetX = 0;
      this.cx.shadowOffsetY = 0;
      this.cx.shadowBlur = 1;
      this.cx.shadowColor = point.color;
      this.cx.strokeStyle = point.color;
      this.cx.lineWidth = point.lineThickness;
    }
    // this.cx.lineCap = 'round';
    // this.cx.stroke();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
