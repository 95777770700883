<div fxLayout="column" style="height: 100%; overflow-y: auto">
  <mat-card fxLayout="row" fxLayoutAlign="start center">
    <!-- <mat-form-field
            appearance="outline"
            style="margin-bottom: -1.25em;"
        >
            <input
                matInput
                placeholder="Szukaj..."
             />
        </mat-form-field> -->
    <button
      [disabled]="addingElement"
      (click)="addItem()"
      class="ml-2"
      color="primary"
      mat-raised-button
    >
      Dodaj element
    </button>
    <button
      (click)="this.coursesService.getPlanItemsList()"
      class="ml-2"
      color="primary"
      mat-raised-button
    >
      Odświez foldery
    </button>
    <!-- Removed text field from action bar -->
    <button (click)="copyItem()" class="ml-2" color="primary" mat-raised-button>
      kopiuj moduł
    </button>
    <button
      [disabled]="selectedItems.length === 0"
      (click)="duplicateSelectedItems()"
      class="ml-2"
      color="accent"
      mat-raised-button
    >
      Duplikuj wybrane
    </button>
    <button
      [disabled]="selectedItems.length === 0"
      (click)="archiveAllSelected()"
      class="ml-2"
      color="warn"
      mat-raised-button
    >
      Archiwizuj wybrane
    </button>

    <button
      class="ml-2"
      mat-flat-button
      (click)="toggleArchived()"
      [color]="showArchived ? 'warn' : 'primary'"
      matTooltip="{{
        showArchived ? 'Ukryj zarchiwizowane' : 'Pokaż zarchiwizowane'
      }}"
    >
      {{ showArchived ? "Ukryj zarchiwizowane" : "Pokaż zarchiwizowane" }}
    </button>
    <span style="flex: 1"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon>arrow_downward</mat-icon>
    </button>
  </mat-card>

  <!-- Section for displaying selected items as chips -->
  <mat-card *ngIf="selectedItems.length > 0" class="mt-2">
    <mat-card-content>
      <div fxLayout="column">
        <p class="mb-1">Wybrane elementy:</p>
        <mat-chip-list>
          <mat-chip
            *ngFor="let item of selectedItems"
            [removable]="true"
            (removed)="addRemoveItems({ selected: false, item: item.item })"
            color="primary"
            selected
          >
            {{ item.item.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mt-2" fxFlex style="overflow-y: auto">
    <div *ngFor="let item of planItems; let i = index" #panel>
      <app-courses-plan-item
        *ngIf="item.new && item.id !== 'temp'"
        [item]="item"
      ></app-courses-plan-item>
    </div>
    <hr />

    <!-- Grid layout for folders -->
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div
        *ngFor="let folder of folders; let j = index"
        fxFlex="33.33%"
        fxFlex.lt-md="50%"
        fxFlex.lt-sm="100%"
        class="folder-card-container"
      >
        <mat-card class="folder-card" (click)="openFolderModal(folder)">
          <mat-card-header>
            <mat-card-title>{{ folder.name | uppercase }}</mat-card-title>
            <div class="folder-actions">
              <button
                mat-icon-button
                color="primary"
                class="folder-expand-btn"
                (click)="openFolderModal(folder); $event.stopPropagation()"
                matTooltip="Otwórz folder"
              >
                <mat-icon>open_in_new</mat-icon>
              </button>
              <!-- <button
                mat-icon-button
                color="warn"
                class="folder-delete-btn"
                (click)="removeFolder(folder, $event)"
                matTooltip="Usuń folder"
              >
                <mat-icon>delete</mat-icon>
              </button> -->
            </div>
          </mat-card-header>

          <mat-card-content>
            <div fxLayout="column" fxLayoutGap="8px">
              <p class="item-count">Elementy: {{ folder.items.length }}</p>
              <p *ngIf="areItemsSelected(folder)" class="selected-items">
                Wybrane: {{ countSelectedItems(folder) }}
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<!-- Folder Content Modal Template -->
<ng-template #folderContentModal let-data>
  <div class="folder-modal-container">
    <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ data.folder.name | uppercase }}</span>
      <button
        mat-icon-button
        (click)="closeFolderModal()"
        color="primary"
        matTooltip="Zamknij"
      >
        <mat-icon>close</mat-icon>
      </button>
    </h2>

    <mat-dialog-content>
      <div fxLayout="column" class="mobile-friendly-container">
        <!-- Search bar -->
        <div fxLayout="row" class="search-container">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Szukaj w folderze</mat-label>
            <input
              matInput
              #input
              (keyup)="findInFolder(data.folder, input.value)"
              type="text"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <!-- Selected items counter -->
        <div
          class="folder-info-bar"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="start start"
          fxLayoutGap.lt-sm="8px"
        >
          <p class="item-count">Elementy: {{ data.folder.items.length }}</p>
          <p *ngIf="selectedItems.length > 0" class="selected-items">
            Wybrane: {{ selectedItems.length }}
          </p>
        </div>

        <!-- Folder items -->
        <div class="folder-items-container">
          <app-courses-plan-item
            [folderOpened]="true"
            [addMode]="data?.addMode"
            [editMode]="editMode && i == index"
            [item]="item"
            [folder]="data.folder"
            [courseResources]="courseResources"
            *ngFor="let item of data.folder.items; let i = index"
            (selectionChange)="addRemoveItems($event)"
            (createFolders)="refreshFolders()"
          ></app-courses-plan-item>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions
      align="end"
      fxLayout="row wrap"
      fxLayoutGap="8px"
      fxLayoutAlign="end center"
    >
      <button
        *ngIf="selectedItems.length > 0"
        mat-raised-button
        color="accent"
        (click)="duplicateSelectedItems()"
        fxFlex.lt-sm="100"
      >
        <mat-icon>content_copy</mat-icon>
        <span class="button-text"
          >Duplikuj wybrane ({{ selectedItems.length }})</span
        >
      </button>
      <!-- <button
        mat-raised-button
        color="warn"
        (click)="removeFolder(data.folder); closeFolderModal()"
        matTooltip="Usuń folder"
        fxFlex.lt-sm="100"
      >
        <mat-icon>delete</mat-icon>
        <span class="button-text">Usuń folder</span>
      </button> -->
      <button
        mat-raised-button
        (click)="closeFolderModal()"
        color="primary"
        fxFlex.lt-sm="100"
      >
        <mat-icon>check</mat-icon>
        <span class="button-text">Zamknij</span>
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
