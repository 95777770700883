import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SignupFormService } from 'src/app/shared/services/signup-form.service';
import { DatetimePickerDialogComponent } from '../../../../shared/compnents/datetime-picker-dialog/datetime-picker-dialog.component';
import getDay from 'date-fns/getDay';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import isWithinInterval from 'date-fns/isWithinInterval';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditComponent implements OnInit {
  studentsAvalibility: Array<{
    id: string;
    start: Date;
    end: Date;
    day?: number;
  }> = [];
  selectedDay;
  enteredStartTime;
  enteredEndTime;
  dataToEdit = new FormGroup({
    customerFirstName: new FormControl(null, [Validators.required]),
    customerLastName: new FormControl(null, [Validators.required]),
    customerPhone: new FormControl(null, [Validators.required]),
    customerEmail: new FormControl(null),
    location: new FormControl(null),
    status: new FormControl(null),
    weeklyLessons: new FormControl(null),
    lastYearsPrice: new FormControl(null),
    thisYearsPrice: new FormControl(189),
    contractPrice: new FormControl(0),
    priceAddition: new FormControl(20),
    childFirstName: new FormControl(null, [Validators.required]),
    childLastName: new FormControl(null, [Validators.required]),
    childGrade: new FormControl(null, [Validators.required]),
    childLevel: new FormControl(null, [Validators.required]),
    childPreviousClasses: new FormControl(null, [Validators.required]),
    additionalData: new FormControl(null),
    adultFirstName: new FormControl(null, [Validators.required]),
    adultLastName: new FormControl(null, [Validators.required]),
    adultPhone: new FormControl(null, [Validators.required]),
    adultEmail: new FormControl(null, [Validators.required]),
    adultAdditionalData: new FormControl(null),
  });
  priceDeduction: number;
  finalPrice: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private signUpFormService: SignupFormService,
    private dialog: MatDialog,
    private helpers: HelpersService,
    private dialogRef: MatDialogRef<EditComponent>,
  ) { }

  ngOnInit(): void {

    this.data.studentData.tempGroups = this.helpers.sortArrayByProperty(
      this.data.studentData.tempGroups,
      'start',
      'asc',
    );
    this.data.studentData.avalibility = this.helpers.sortArrayByProperty(
      this.data.studentData.avalibility,
      'start',
      'asc',
    );
    this.studentsAvalibility = this.data.studentData.avalibility
      ? this.data.studentData.avalibility
      : [];

    this.dataToEdit
      .get('customerFirstName')
      .setValue(this.data.studentData.customerFirstName);
    this.dataToEdit
      .get('customerLastName')
      .setValue(this.data.studentData.customerLastName);
    this.dataToEdit
      .get('customerPhone')
      .setValue(this.data.studentData.customerPhone);
    this.dataToEdit
      .get('customerEmail')
      .setValue(this.data.studentData.customerEmail);
    this.dataToEdit.get('location').setValue(this.data.studentData.location);
    if (this.data.studentData.child === true) {
      this.setChild();
    } else {
      this.setAdult();
    }
    this.countStudentsPrice();
  }

  setChild() {
    this.dataToEdit.get('childFirstName').enable();
    this.dataToEdit
      .get('childFirstName')
      .setValue(this.data.studentData.childFirstName);
    this.dataToEdit.get('childLastName').enable();
    this.dataToEdit
      .get('childLastName')
      .setValue(this.data.studentData.childLastName);
    this.dataToEdit.get('childGrade').enable();
    this.dataToEdit
      .get('childGrade')
      .setValue(this.data.studentData.childGrade);
    this.dataToEdit.get('childLevel').enable();
    this.dataToEdit
      .get('childLevel')
      .setValue(this.data.studentData.childLevel);
    this.dataToEdit.get('childPreviousClasses').enable();
    this.dataToEdit
      .get('childPreviousClasses')
      .setValue(this.data.studentData.childPreviousClasses);
    this.dataToEdit.get('additionalData').enable();
    this.dataToEdit
      .get('additionalData')
      .setValue(this.data.studentData.additionalData);
    this.dataToEdit.get('status').setValue(this.data.studentData.status);
    this.dataToEdit
      .get('weeklyLessons')
      .setValue(this.data.studentData.weeklyLessons);
    this.dataToEdit
      .get('lastYearsPrice')
      .setValue(this.data.studentData.lastYearsPrice);
    this.dataToEdit
      .get('thisYearsPrice')
      .setValue(
        this.data.studentData.thisYearsPrice
          ? this.data.studentData.thisYearsPrice
          : 189,
      );
    this.dataToEdit
      .get('contractPrice')
      .setValue(this.data.studentData.contractPrice);
    this.dataToEdit
      .get('priceAddition')
      .setValue(
        this.data.studentData.priceAddition
          ? this.data.studentData.priceAddition
          : 20,
      );
    this.dataToEdit.get('adultFirstName').disable();
    this.dataToEdit.get('adultLastName').disable();
    this.dataToEdit.get('adultPhone').disable();
    this.dataToEdit.get('adultEmail').disable();
    this.dataToEdit.get('adultAdditionalData').disable();
  }

  setAdult() {
    this.data.studentData.child = false;
    this.data.studentData.adult = true;
    this.dataToEdit.get('childFirstName').disable();
    this.dataToEdit.get('childLastName').disable();
    this.dataToEdit.get('childGrade').disable();
    this.dataToEdit.get('childLevel').disable();
    this.dataToEdit.get('childPreviousClasses').disable();
    this.dataToEdit.get('additionalData').disable();
    this.dataToEdit.get('adultFirstName').enable();
    this.dataToEdit
      .get('adultFirstName')
      .setValue(this.data.studentData.adultFirstName);
    this.dataToEdit.get('adultLastName').enable();
    this.dataToEdit
      .get('adultLastName')
      .setValue(this.data.studentData.adultLastName);
    this.dataToEdit.get('adultPhone').enable();
    this.dataToEdit
      .get('adultPhone')
      .setValue(this.data.studentData.adultPhone);
    this.dataToEdit.get('adultEmail').enable();
    this.dataToEdit
      .get('adultEmail')
      .setValue(this.data.studentData.adultEmail);
    this.dataToEdit.get('adultAdditionalData').enable();
    this.dataToEdit
      .get('adultAdditionalData')
      .setValue(this.data.studentData.adultAdditionalData);
    this.dataToEdit.get('status').setValue(this.data.studentData.status);
    this.dataToEdit
      .get('weeklyLessons')
      .setValue(this.data.studentData.weeklyLessons);
    this.dataToEdit
      .get('lastYearsPrice')
      .setValue(this.data.studentData.lastYearsPrice);
    this.dataToEdit
      .get('contractPrice')
      .setValue(this.data.studentData.contractPrice);
    this.dataToEdit
      .get('thisYearsPrice')
      .setValue(
        this.data.studentData.thisYearsPrice
          ? this.data.studentData.thisYearsPrice
          : 189,
      );
    this.dataToEdit
      .get('priceAddition')
      .setValue(
        this.data.studentData.priceAddition
          ? this.data.studentData.priceAddition
          : 20,
      );
  }

  constructMessage(): void {
    let messageToCopy: string = '';
    if (this.data?.studentData?.childFirstName) {
      const tempGroups = this.data.studentData.tempGroups || [];
      let days = tempGroups.map((group: any, index: number) => ({
        start: group?.start,
        isIndividual: group?.meta?.studentsIds?.length === 1,
        conjunction: index === 1 ? 'i' : index > 1 ? 'oraz' : '',
      }));

      messageToCopy = `Dzień dobry, informujemy, że zgodnie z terminami, które Państwo podali ${this.data.studentData.childFirstName} będzie mieć zajęcia w następujące dni:`;

      days.forEach((day, index) => {
        if (day.start) {
          messageToCopy += ` ${day.conjunction} ${new Date(
            day.start,
          ).toLocaleDateString('pl-PL', {
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })} ${day.isIndividual ? '(indywidualnie)' : ''}`;
        }
      });

      messageToCopy += `. Zajęcia rozpoczynają się od najbliższego tygodnia, wtedy prześlemy
      tez link do zawarcia umowy online. Prosimy o potwierdzenie odebrania
      wiadomości. Pozdrawiamy serdecznie, Ling King.`;
    }
    console.log(
      '🚀 ~ file: edit.component.ts:238 ~ EditComponent ~ constructMessage ~ messageToCopy:',
      messageToCopy,
    );
    this.dataToEdit.get('status').setValue('scheduleSent');
    navigator.clipboard.writeText(messageToCopy);
  }

  onSubmit() {

    if (this.dataToEdit.valid) {
      if (this.data.studentData.child) {
        const childToUpdate = {
          customerId: this.data.studentData.customerId,
          childId: this.data.studentData.studentId,
          childFirstName: this.dataToEdit.value.childFirstName,
          childLastName: this.dataToEdit.value.childLastName,
          childGrade: this.dataToEdit.value.childGrade,
          childLevel: this.dataToEdit.value.childLevel,
          childPreviousClasses: this.dataToEdit.value.childPreviousClasses,
          avalibility: this.studentsAvalibility,
          additionalData: this.dataToEdit.value.additionalData,
          status: this.dataToEdit.value.status,
          weeklyLessons: this.dataToEdit.value.weeklyLessons,
          lastYearsPrice: this.dataToEdit.value.lastYearsPrice,
          thisYearsPrice: this.dataToEdit.value.thisYearsPrice,
          contractPrice: this.dataToEdit.value.contractPrice,
          priceAddition: this.dataToEdit.value.priceAddition,
        };
        //service set child
        this.signUpFormService.updateChild(childToUpdate);
        this.dialogRef.close({ mode: 'child', value: childToUpdate });
      }
      if (!this.data.studentData.child) {
        const adultToUpdate = {
          customerId: this.data.studentData.customerId,
          adultId: this.data.studentData.studentId,
          adultFirstName: this.dataToEdit.value.adultFirstName,
          adultLastName: this.dataToEdit.value.adultLastName,
          adultPhone: this.dataToEdit.value.adultPhone,
          adultEmail: this.dataToEdit.value.adultEmail,
          avalibility: this.studentsAvalibility,
          status: this.dataToEdit.value.status,
          weeklyLessons: this.dataToEdit.value.weeklyLessons,
          lastYearsPrice: this.dataToEdit.value.lastYearsPrice,
          thisYearsPrice: this.dataToEdit.value.thisYearsPrice,
          contractPrice: this.dataToEdit.value.contractPrice,
          priceAddition: this.dataToEdit.value.priceAddition,
          adultAdditionalData: this.dataToEdit.value.adultAdditionalData,
        };
        //service set adult
        this.signUpFormService.updateAdult(adultToUpdate);
        this.dialogRef.close({ mode: 'adult', value: adultToUpdate });
      }
      if (
        this.dataToEdit.get('customerFirstName').dirty ||
        this.dataToEdit.get('customerLastName').dirty ||
        this.dataToEdit.get('customerPhone').dirty ||
        this.dataToEdit.get('location').dirty ||
        this.dataToEdit.get('customerEmail').dirty
      ) {
        const customerToUpdate = {
          customerId: this.data.studentData.customerId,
          customerFirstName: this.dataToEdit.value.customerFirstName,
          customerLastName: this.dataToEdit.value.customerLastName,
          customerPhone: this.dataToEdit.value.customerPhone,
          customerEmail: this.dataToEdit.value.customerEmail,
          location: this.dataToEdit.value.location,
        };
        //service set adult
        this.signUpFormService.updateCustomer(customerToUpdate);
        this.dialogRef.close({
          mode: 'customer',
          value: customerToUpdate,
        });
      }
    }
  }
  countStudentsPrice(deduct?) {
    if (deduct) {
      this.dataToEdit
        .get('priceAddition')
        .setValue(this.dataToEdit.get('priceAddition').value - 5);
    }
    if (this.dataToEdit.get('lastYearsPrice').value > 0) {
      this.priceDeduction = -(
        this.dataToEdit.get('thisYearsPrice').value -
        (this.dataToEdit.get('lastYearsPrice').value +
          this.dataToEdit.get('priceAddition').value)
      );
      if (this.priceDeduction > -5) {
        this.dataToEdit
          .get('priceAddition')
          .setValue(this.dataToEdit.get('priceAddition').value - 5);
      }
      this.priceDeduction = -(
        this.dataToEdit.get('thisYearsPrice').value -
        (this.dataToEdit.get('lastYearsPrice').value +
          this.dataToEdit.get('priceAddition').value)
      );
      this.finalPrice =
        this.dataToEdit.get('thisYearsPrice').value + this.priceDeduction;
    } else {
    }
  }
  removeStudentsAvalibility(avalibilityId) {
    this.studentsAvalibility = this.studentsAvalibility.filter(
      (avaibility) => avaibility.id !== avalibilityId,
    );
    this.data.studentData.avalibility = this.studentsAvalibility;
  }

  checkStudentAvailability() { }
  openDateTimePicker() {
    const dialogRef = this.dialog.open(DatetimePickerDialogComponent, {
      data: { title: 'Dodaj dostępność', mode: 'withEnd' },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.dateTime) {
        const day = getDay(result.dateTime);
        this.studentsAvalibility.push({
          id: this.helpers.makeid(),
          start: result.dateTime,
          end: result.dateTime2,
          day: day,
        });
        this.data.studentData.avalibility = this.studentsAvalibility;
      }
    });
  }
  removeGroupFromStudent(studentData, groupToRemove) {
    studentData.tempGroups = studentData.tempGroups.filter(
      (group) => group.id !== groupToRemove.id,
    );
  }
  addAvalibility() {
    if (!this.enteredStartTime) {
      alert('Wprowadź czas początku');
      return;
    }
    if (!this.enteredEndTime) {
      alert('Wprowadź czas końca');
      return;
    }
    if (!this.selectedDay) {
      alert('Wybierz dzień');
      return;
    }
    const avalibilityToAdd = {
      id: this.helpers.makeid(),
      start: this.selectedDay.concat(this.enteredStartTime),
      end: this.selectedDay.concat(this.enteredEndTime),
    };
    console.log(
      '🚀 ~ file: edit.component.ts ~ line 332 ~ EditComponent ~ addAvalibility ~ avalibilityToAdd',
      avalibilityToAdd,
    );
    this.studentsAvalibility.push(avalibilityToAdd);
    this.data.studentData.avalibility = this.studentsAvalibility;
  }
}
