import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ResourceSelectionDialogComponent } from './resource-selection-dialog/resource-selection-dialog.component';
import { ResourcePreviewDialogComponent } from './resource-preview-dialog/resource-preview-dialog.component';

@Component({
  selector: 'app-course-add-edit',
  templateUrl: './course-add-edit.component.html',
  styleUrls: ['./course-add-edit.component.css'],
})
export class CourseAddEditComponent implements OnInit, OnDestroy {
  editMode = false;
  selectedResources = [];
  coursesForm = this.fb.group({
    name: [null, Validators.required],
    weeklyFrequency: [null, Validators.required],
    duration: [],
    maxStudents: [],
    hoursType: [null],
    online: [null],
    price: [null],
    group: [null],
    forWho: [null],
    plan: [[]],
    description: [null],
    startDate: [null],
    endDate: [null],
    resources: [[]],
    additionalPayments: this.fb.group({
      price: [null],
      forWhat: [null],
    }),
  });
  sub: any;
  constructor(
    private coursesService: CoursesService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CourseAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private coursesPlanService: CoursesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Only subscribe to updates if we're not in edit mode
    if (!this.data) {
      this.sub = this.coursesPlanService
        .coursesUpdatedListener()
        .subscribe((data) => {
          if (data && data.length) {
            this.coursesForm.patchValue({ plan: data }, { emitEvent: false });
          }
        });
    }

    if (this.data) {
      this.editMode = true;
      // Use patchValue instead of setting individual controls
      this.coursesForm.patchValue({
        name: this.data.course.name,
        weeklyFrequency: this.data.course.weeklyFrequency,
        duration: this.data.course.duration,
        maxStudents: this.data.course.maxStudents,
        online: this.data.course.online,
        hoursType: this.data.course.hoursType,
        price: this.data.course.price,
        plan: this.data.course.plan,
        group: this.data.course.group,
        forWho: this.data.course.forWho,
        description: this.data.course.description,
        startDate: this.data.course.startDate,
        endDate: this.data.course.endDate,
        resources: this.data.course.resources || [],
        additionalPayments: {
          price: this.data.course.additionalPayments?.price,
          forWhat: this.data.course.additionalPayments?.forWhat
        }
      }, { emitEvent: false });

      // Aktualizuj listę wybranych zasobów
      if (this.data.course.resources && Array.isArray(this.data.course.resources)) {
        this.selectedResources = [...this.data.course.resources];
      }
    }
  }

  /**
   * Otwiera dialog wyboru zasobów
   */
  openResourceSelectionDialog() {
    // Save current overflow state
    const originalOverflow = document.body.style.overflow;

    const dialogRef = this.dialog.open(ResourceSelectionDialogComponent, {
      width: '80%',
      height: '80%',
      data: { selectedResources: this.selectedResources },
      panelClass: 'resource-selection-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      // Restore original overflow state
      document.body.style.overflow = originalOverflow;

      if (result) {
        this.selectedResources = result;
        this.coursesForm.patchValue({ resources: result });
      }
    });
  }

  /**
   * Usuwa zasób z listy wybranych
   */
  removeResource(resource) {
    const index = this.selectedResources.findIndex(r => r.id === resource.id);
    if (index !== -1) {
      this.selectedResources.splice(index, 1);
      // Aktualizuj również wartość w formularzu
      this.coursesForm.patchValue({ resources: [...this.selectedResources] });

    }
  }

  /**
   * Otwiera dialog podglądu zasobu
   */
  openResourcePreviewDialog(resource) {
    this.dialog.open(ResourcePreviewDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        resourceId: resource.id,
        resourceName: resource.name
      }
    });
  }

  addCourse() {

    if (this.coursesForm.valid) {
      // Upewnij się, że wybrane zasoby są dostępne w formularzu
      const courseData = {
        ...this.coursesForm.value,
        resources: this.selectedResources
      };
      this.coursesService.saveNewCourse(courseData);
      this.dialogRef.close();
    }
  }

  updateCourse() {

    if (this.coursesForm.valid) {
      // Upewnij się, że wybrane zasoby są dostępne w formularzu
      const courseData = {
        ...this.coursesForm.value,
        resources: this.selectedResources
      };
      this.coursesService.updateCourse(
        this.data.course.id,
        courseData,
      );
      this.dialogRef.close();
    }
  }
  removeCourse(course) {
    this.coursesService.removeCourse(course.id);
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
