<h2 mat-dialog-title>Wybierz historię detektywistyczną</h2>
<div mat-dialog-content>
  <div class="search-container">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Szukaj po tytule</mat-label>
      <input
        matInput
        type="text"
        (input)="searchStories($event)"
        placeholder="Wpisz tytuł historii"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="story-list">
    <div
      *ngFor="let story of filteredStories"
      class="story-item"
      [class.selected]="selectedStory?.id === story.id"
      (click)="selectStory(story)"
    >
      <div class="story-header">
        <h3>{{ story.title }}</h3>
        <div class="story-meta">
          <span class="story-level"
            >Poziom: {{ getPolishLevel(story.level) }}</span
          >
          <span class="story-age"
            >Wiek: {{ story.ageRange[0] }}-{{ story.ageRange[1] }} lat</span
          >
        </div>
      </div>
      <div class="story-content" *ngIf="story.content">
        <div
          *ngIf="selectedStory?.id === story.id || showFullStory"
          class="full-story"
        >
          {{ story.content }}
        </div>
        <div
          *ngIf="selectedStory?.id !== story.id && !showFullStory"
          class="story-preview"
        >
          {{ story.content.substring(0, 100) }}...
          <button
            mat-button
            color="primary"
            (click)="
              $event.stopPropagation();
              selectStory(story);
              toggleShowFullStory()
            "
          >
            Pokaż więcej
          </button>
        </div>
      </div>
      <div class="story-suspects">
        <span
          *ngFor="let suspect of story.suspects.slice(0, 3)"
          class="suspect-emoji"
        >
          {{ suspect.emoji }}
        </span>
      </div>
    </div>
    <div *ngIf="filteredStories.length === 0" class="no-results">
      <p>Nie znaleziono historii o podanym tytule.</p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Anuluj</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!selectedStory"
    (click)="confirm()"
  >
    Wybierz
  </button>
</div>
