import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { CoursesService } from 'src/app/shared/services/courses.service';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  private currentFolderSubject = new BehaviorSubject<any>(null);
  private foldersSubject = new BehaviorSubject<any[]>([]);
  private currentModalRef: MatDialogRef<any> = null;
  private modalData: any = null;

  constructor(private coursesService: CoursesService) {
    // Subscribe to the courses service to get updates
    this.coursesService.coursesPlanItemsUpdatedListener().subscribe((planItems: any) => {
      if (!planItems.newItemId) {
        // If we received the full list of plan items, update our folders
        this.updateFolders();
      }
    });
  }

  // Set the current folder
  setCurrentFolder(folder: any): void {
    this.currentFolderSubject.next(folder);
  }

  // Get the current folder as an observable
  getCurrentFolder(): Observable<any> {
    return this.currentFolderSubject.asObservable();
  }

  // Set the folders
  setFolders(folders: any[]): void {
    this.foldersSubject.next(folders);

    // If we have a current folder, update it with the new data
    const currentFolder = this.currentFolderSubject.value;
    if (currentFolder) {
      const updatedFolder = folders.find(f => f.name === currentFolder.name);
      if (updatedFolder) {
        this.currentFolderSubject.next(updatedFolder);

        // Also update the modal data if we have a modal open
        if (this.currentModalRef && this.modalData && this.modalData.folder &&
          this.modalData.folder.name === updatedFolder.name) {
          this.updateModalData(updatedFolder);
        }
      }
    }
  }

  // Get the folders as an observable
  getFolders(): Observable<any[]> {
    return this.foldersSubject.asObservable();
  }

  // Refresh the folders by triggering a new request
  refreshFolders(): void {
    this.coursesService.getPlanItemsList();
  }

  // Set the current modal reference
  setCurrentModal(modalRef: MatDialogRef<any>, data: any): void {
    this.currentModalRef = modalRef;
    this.modalData = data;
  }

  // Clear the current modal reference
  clearCurrentModal(): void {
    this.currentModalRef = null;
    this.modalData = null;
  }

  // Get the current modal reference
  getCurrentModal(): MatDialogRef<any> {
    return this.currentModalRef;
  }

  // Update the modal data
  updateModalData(folder: any): void {
    if (this.currentModalRef && this.modalData) {
      // Store the original courseResources before updating
      const originalCourseResources = this.modalData.courseResources || [];

      // Update the folder
      this.modalData.folder = folder;

      // Force Angular to detect changes by updating the componentInstance data
      if (this.currentModalRef.componentInstance) {
        // Check if componentInstance.data exists, if not initialize it
        if (!this.currentModalRef.componentInstance.data) {
          this.currentModalRef.componentInstance.data = {};
        }

        // Make sure we preserve the courseResources data
        this.currentModalRef.componentInstance.data = {
          ...this.modalData,
          courseResources: originalCourseResources
        };
      }
    }
  }

  // Update the folders based on the current plan items
  private updateFolders(): void {
    // This will be called when the plan items are updated
    // We'll rely on the component to create the folders and call setFolders
  }
}
