import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class LessonActivitiesService {
  lessonActivities = [];
  //  url = 'https://backend-int.linget.it'
  url;
  // url = 'https://test.lingking.com.pl:3001'
  private lessonActivitiesUpdated = new Subject<any[]>();
  foundActivitiesUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  saveNewActivity(activity) {
    this.http
      .post(this.url + '/api/lessonactivities/addlessonactivity', activity)
      .subscribe((response) => {
        this.getActivitiesList();
      });
  }

  getActivitiesList() {
    this.http
      .get(this.url + '/api/lessonactivities/getlessonactivityslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let lessonActivitysArray = Object.keys(response).map(
          (i) => response[i],
        );

        lessonActivitysArray = lessonActivitysArray[0].map(
          (incomingLessonActivity) => {
            return {
              id: incomingLessonActivity._id,
              name: incomingLessonActivity.data.name,
              ageGroups: incomingLessonActivity.data.ageGroups,
              author: incomingLessonActivity.data.author,
              comments: incomingLessonActivity.data.comments,
              description: incomingLessonActivity.data.description,
              unfinished: incomingLessonActivity.data.unfinished,
              hints: incomingLessonActivity.data.hints,
              numbersOfStudents: incomingLessonActivity.data.numbersOfStudents,
              skills: incomingLessonActivity.data.skills,
              timesOfActivity: incomingLessonActivity.data.timesOfActivity,
              variants: incomingLessonActivity.data.variants,
              pictures: incomingLessonActivity.data.pictures,
              videos: incomingLessonActivity.data.videos,
              pdfs: incomingLessonActivity.data.pdfs,
              audio: incomingLessonActivity.data.audio,
              tags: incomingLessonActivity.data.tags,
              steps: incomingLessonActivity.data.steps,
              trackingData: incomingLessonActivity.data.trackingData,
              frequency: incomingLessonActivity.data.frequency,


            };
          },
        );
        this.lessonActivities = lessonActivitysArray;


        this.lessonActivitiesUpdated.next(this.lessonActivities);
      });
  }

  findActivityByName(name) {
    this.http
      .post(
        this.url + '/api/lessonactivities/findlessonactivitybyname',
        { name: name },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let lessonActivitiesArray = Object.keys(response).map(
          (i) => response[i],
        );

        lessonActivitiesArray = lessonActivitiesArray[0].map(
          (incomingLessonActivity) => {
            return {
              id: incomingLessonActivity._id,
              name: incomingLessonActivity.data.name,
              ageGroups: incomingLessonActivity.data.ageGroups,
              author: incomingLessonActivity.data.author,
              comments: incomingLessonActivity.data.comments,
              description: incomingLessonActivity.data.description,
              unfinished: incomingLessonActivity.data.unfinished,
              hints: incomingLessonActivity.data.hints,
              numbersOfStudents: incomingLessonActivity.data.numbersOfStudents,
              skills: incomingLessonActivity.data.skills,
              timesOfActivity: incomingLessonActivity.data.timesOfActivity,
              variants: incomingLessonActivity.data.variants,
              pictures: incomingLessonActivity.data.pictures,
              videos: incomingLessonActivity.data.videos,
              pdfs: incomingLessonActivity.data.pdfs,
              tags: incomingLessonActivity.data.tags,
              steps: incomingLessonActivity.data.steps,
              audio: incomingLessonActivity.data.audio,
              trackingData: incomingLessonActivity.data.trackingData,
              frequency: incomingLessonActivity.data.frequency,

            };
          },
        );
        this.lessonActivities = lessonActivitiesArray;

        this.lessonActivitiesUpdated.next(this.lessonActivities);
      });
  }
  findActivityById(id) {
    this.http
      .post(
        this.url + '/api/lessonactivities/findlessonactivitybyid',
        { id: id },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let lessonActivitiesArray = Object.keys(response).map(
          (i) => response[i],
        );

        lessonActivitiesArray = lessonActivitiesArray[0].map(
          (incomingLessonActivity) => {
            return {
              id: incomingLessonActivity._id,
              name: incomingLessonActivity.data.name,
              ageGroups: incomingLessonActivity.data.ageGroups,
              author: incomingLessonActivity.data.author,
              comments: incomingLessonActivity.data.comments,
              description: incomingLessonActivity.data.description,
              unfinished: incomingLessonActivity.data.unfinished,
              hints: incomingLessonActivity.data.hints,
              numbersOfStudents: incomingLessonActivity.data.numbersOfStudents,
              skills: incomingLessonActivity.data.skills,
              timesOfActivity: incomingLessonActivity.data.timesOfActivity,
              variants: incomingLessonActivity.data.variants,
              pictures: incomingLessonActivity.data.pictures,
              videos: incomingLessonActivity.data.videos,
              pdfs: incomingLessonActivity.data.pdfs,
              tags: incomingLessonActivity.data.tags,
              steps: incomingLessonActivity.data.steps,
              audio: incomingLessonActivity.data.audio,
              trackingData: incomingLessonActivity.data.trackingData,
              frequency: incomingLessonActivity.data.frequency,

            };
          },
        );
        this.lessonActivities = lessonActivitiesArray;

        this.foundActivitiesUpdated.next(this.lessonActivities[0]);
      });
  }
  setTrackingDataForActivity(id: string, userId: string, trackingData: any) {

    const body = {
      userId: userId,
      trackingData: trackingData
    };
    this.http.put(this.url + '/api/lessonactivities/settrackingdataforactivity/' + id, body, {
      headers: { Authorization: `Bearer ${this.authService.getToken()}` },
    }).subscribe((response: any) => {



    }
    );
  }
  resetTrackingDataForActivity(id: string, userId: string): Observable<any> {

    const body = { userId: userId };
    return this.http.put(`${this.url}/api/lessonactivities/reset-tracking/${id}`, body, {
      headers: { Authorization: `Bearer ${this.authService.getToken()}` }
    });
  }

  resetAllTrackingDataForActivity(id: string): Observable<any> {

    return this.http.put(`${this.url}/api/lessonactivities/reset-all-tracking/${id}`, {}, {
      headers: { Authorization: `Bearer ${this.authService.getToken()}` }
    });
  }

  findActivityByTag(tag) {
    this.http
      .post(
        this.url + '/api/lessonactivities/findlessonactivitybytag',
        { tag: tag },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let lessonActivitiesArray = Object.keys(response).map(
          (i) => response[i],
        );

        lessonActivitiesArray = lessonActivitiesArray[0].map(
          (incomingLessonActivity) => {
            return {
              id: incomingLessonActivity._id,
              name: incomingLessonActivity.data.name,
              ageGroups: incomingLessonActivity.data.ageGroups,
              author: incomingLessonActivity.data.author,
              comments: incomingLessonActivity.data.comments,
              description: incomingLessonActivity.data.description,
              unfinished: incomingLessonActivity.data.unfinished,
              hints: incomingLessonActivity.data.hints,
              numbersOfStudents: incomingLessonActivity.data.numbersOfStudents,
              skills: incomingLessonActivity.data.skills,
              timesOfActivity: incomingLessonActivity.data.timesOfActivity,
              variants: incomingLessonActivity.data.variants,
              pictures: incomingLessonActivity.data.pictures,
              videos: incomingLessonActivity.data.videos,
              pdfs: incomingLessonActivity.data.pdfs,
              tags: incomingLessonActivity.data.tags,
              steps: incomingLessonActivity.data.steps,
              audio: incomingLessonActivity.data.audio,
              trackingData: incomingLessonActivity.data.trackingData,
              frequency: incomingLessonActivity.data.frequency,


            };
          },
        );
        this.lessonActivities = lessonActivitiesArray;

        this.foundActivitiesUpdated.next(this.lessonActivities[0]);
      });
  }

  // filterStudents(text) {
  //
  //   const lowerText = text.toLowerCase()
  //   const filteredStudents = this.lessonActivities.filter(e => e.childFirstName.toLowerCase().includes(lowerText))

  //   this.lessonActivitiesUpdated.next(filteredStudents)

  // }

  removeActivity(id) {
    console.log(
      '🚀 ~ file: lesson-activities.service.ts ~ line 129 ~ LessonActivitiesService ~ removeActivity ~ id',
      id,
    );
    this.http
      .delete(this.url + '/api/lessonactivities/removelessonactivity/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.lessonActivities = this.lessonActivities.filter(
          (e) => e.id !== id,
        );

        this.lessonActivitiesUpdated.next(this.lessonActivities);
      });
  }

  updateActivity(activityId, activity) {
    this.http
      .put(
        this.url + '/api/lessonactivities/updatelessonactivity/' + activityId,
        activity,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        const currentId = this.lessonActivities.findIndex(
          (e) => e.id === activityId,
        );
        const singleLessonActivity = this.lessonActivities[currentId];
        (singleLessonActivity.name = activity.name),
          this.lessonActivitiesUpdated.next(this.lessonActivities);
      });
  }

  lessonActivitiesUpdatedListener() {
    return this.lessonActivitiesUpdated.asObservable();
  }
  foundActivitiesUpdatedListener() {
    return this.foundActivitiesUpdated.asObservable();
  }
}
