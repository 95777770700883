<h2 mat-dialog-title>{{ data.curriculum.name }}</h2>

<mat-dialog-content class="mat-typography curriculum-view-container">
  <div class="curriculum-overview">
    <div class="overview-card">
      <div class="overview-stat">
        <div class="stat-number">{{ data.curriculum.units.length }}</div>
        <div class="stat-label">Jednostek</div>
      </div>
      <div class="overview-info">
        <div class="overview-title">Program nauczania</div>
        <div class="overview-description">
          Przegląd struktury programu nauczania z rozbiciem na jednostki,
          słownictwo i gramatykę.
        </div>
      </div>
    </div>
  </div>

  <div class="curriculum-chapters">
    <div
      *ngFor="let unit of data.curriculum.units; let i = index"
      class="unit-container"
    >
      <div class="unit-header" (click)="toggleUnit(i)">
        <div class="unit-title">
          <div class="unit-number">{{ i + 1 }}</div>
          <div class="unit-name">{{ unit.topic || "Bez tematu" }}</div>
        </div>
        <mat-icon class="expand-icon" [class.expanded]="expandedUnits[i]">
          {{ expandedUnits[i] ? "expand_less" : "expand_more" }}
        </mat-icon>
      </div>

      <div class="unit-content" *ngIf="expandedUnits[i]">
        <div class="unit-grid">
          <div class="content-section metadata-section">
            <div class="section-title">Informacje ogólne</div>
            <div class="metadata-list">
              <div class="metadata-item" *ngIf="unit.subjectArea">
                <div class="metadata-label">Obszar tematyczny:</div>
                <div class="metadata-value">{{ unit.subjectArea }}</div>
              </div>
              <div class="metadata-item" *ngIf="unit.subjectSubArea">
                <div class="metadata-label">Podrozdział obszaru:</div>
                <div class="metadata-value">{{ unit.subjectSubArea }}</div>
              </div>
              <div class="metadata-item" *ngIf="unit.functions">
                <div class="metadata-label">Funkcje językowe:</div>
                <div class="metadata-value">{{ unit.functions }}</div>
              </div>
            </div>
          </div>

          <div class="content-section vocabulary-section">
            <div class="section-title">Słownictwo</div>
            <div class="section-subtitle">{{ unit.vocabularyTopic }}</div>
            <div class="vocabulary-list">
              <div
                class="vocabulary-item"
                *ngFor="let vocab of formatVocabularyList(unit.vocabularyList)"
              >
                {{ vocab }}
              </div>
              <div
                *ngIf="formatVocabularyList(unit.vocabularyList).length === 0"
                class="empty-list"
              >
                Brak słownictwa
              </div>
            </div>
          </div>

          <div class="content-section grammar-section">
            <div class="section-title">Gramatyka</div>
            <div class="grammar-list">
              <div
                class="grammar-item"
                *ngFor="let grammarItem of unit.grammar"
              >
                {{ grammarItem }}
              </div>
              <div
                *ngIf="!unit.grammar || unit.grammar.length === 0"
                class="empty-list"
              >
                Brak punktów gramatycznych
              </div>
            </div>
          </div>

          <div class="content-section skills-expanded-section">
            <div class="section-title">Umiejętności</div>
            <div class="skills-expanded">
              <!-- Komunikacyjne -->
              <div
                class="skill-category"
                *ngIf="
                  unit.communicativeSkills &&
                  unit.communicativeSkills.length > 0
                "
              >
                <div class="skill-category-title">Komunikacyjne</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.communicativeSkills">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <!-- Receptywne -->
              <div
                class="skill-category"
                *ngIf="unit.receptiveSkills && unit.receptiveSkills.length > 0"
              >
                <div class="skill-category-title">Receptywne</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.receptiveSkills">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <!-- Produktywne -->
              <div
                class="skill-category"
                *ngIf="
                  unit.productiveSkills && unit.productiveSkills.length > 0
                "
              >
                <div class="skill-category-title">Produktywne</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.productiveSkills">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <!-- Interaktywne -->
              <div
                class="skill-category"
                *ngIf="
                  unit.interactiveSkills && unit.interactiveSkills.length > 0
                "
              >
                <div class="skill-category-title">Interaktywne</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.interactiveSkills">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <!-- Przetwarzania -->
              <div
                class="skill-category"
                *ngIf="
                  unit.processingSkills && unit.processingSkills.length > 0
                "
              >
                <div class="skill-category-title">Przetwarzania</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.processingSkills">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <!-- Inne -->
              <div
                class="skill-category"
                *ngIf="unit.otherSkills && unit.otherSkills.length > 0"
              >
                <div class="skill-category-title">Inne</div>
                <ul class="skill-category-list">
                  <li *ngFor="let skill of unit.otherSkills">{{ skill }}</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            class="content-section standards-section"
            *ngIf="unit.coreStandards && unit.coreStandards.length > 0"
          >
            <div class="section-title">Odniesienia do podstawy programowej</div>
            <div class="standards-list">
              <div
                class="standard-tag"
                *ngFor="let standard of unit.coreStandards"
              >
                {{ standard }}
              </div>
            </div>
          </div>

          <div class="content-section skills-section">
            <div class="skills-container">
              <div class="skill-item">
                <div class="skill-icon listening-icon">
                  <mat-icon>headset</mat-icon>
                </div>
                <div class="skill-content">
                  <div class="skill-title">Słuchanie</div>
                  <div class="skill-description">
                    {{ unit.listening || "Brak ćwiczeń słuchania" }}
                  </div>
                </div>
              </div>

              <div class="skill-item">
                <div class="skill-icon reading-icon">
                  <mat-icon>menu_book</mat-icon>
                </div>
                <div class="skill-content">
                  <div class="skill-title">Czytanie</div>
                  <div class="skill-description">
                    {{ unit.reading || "Brak ćwiczeń czytania" }}
                  </div>
                </div>
              </div>

              <div class="skill-item">
                <div class="skill-icon speaking-icon">
                  <mat-icon>record_voice_over</mat-icon>
                </div>
                <div class="skill-content">
                  <div class="skill-title">Mówienie</div>
                  <div class="skill-description">
                    {{ unit.speaking || "Brak ćwiczeń mówienia" }}
                  </div>
                </div>
              </div>

              <div class="skill-item">
                <div class="skill-icon writing-icon">
                  <mat-icon>edit</mat-icon>
                </div>
                <div class="skill-content">
                  <div class="skill-title">Pisanie</div>
                  <div class="skill-description">
                    {{ unit.writing || "Brak ćwiczeń pisania" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="accent"
    (click)="printCurriculum()"
    matTooltip="Drukuj program nauczania"
  >
    <mat-icon>print</mat-icon> Drukuj
  </button>
  <button mat-button (click)="onClose()">Zamknij</button>
</mat-dialog-actions>
