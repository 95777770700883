<div class="dialog-container">
  <div class="main-content" [class.panel-visible]="isRightPanelVisible">
    <div class="action-bar">
      <button
        mat-icon-button
        (click)="toggleRightPanel()"
        matTooltip="Pokaż/ukryj lekcje"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <span class="flex-spacer"></span>
      <button mat-icon-button (click)="closeDialog()" matTooltip="Zamknij">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="scrollable-content">
      <mat-accordion class="full-width">
        <!-- Resources Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>folder</mat-icon>
              <span class="panel-title">Zasoby</span>
            </mat-panel-title>
            <mat-panel-description *ngIf="getSortedResources().length > 0">
              {{ getSortedResources().length }} elementów
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="summary-card">
            <div class="summary-items">
              <div
                *ngFor="
                  let item of getSortedResources();
                  trackBy: trackByResourceId
                "
                class="summary-item"
              >
                <span class="item-name">{{ item.name }}</span>
                <span class="item-type" *ngIf="item.type">{{ item.type }}</span>
                <div class="item-usage">
                  <ng-container
                    *ngTemplateOutlet="
                      usageBadges;
                      context: { $implicit: getResourceUsage(item.id) }
                    "
                  ></ng-container>
                  <span
                    class="frequency-badge"
                    *ngIf="item.frequency > 0"
                    [ngClass]="{
                      low: item.frequency <= 1,
                      medium: item.frequency > 1 && item.frequency <= 3,
                      high: item.frequency > 3
                    }"
                    [matTooltip]="
                      'Użyto ' +
                      item.frequency +
                      ' ' +
                      getPolishCountSuffix(item.frequency)
                    "
                  >
                    {{ item.frequency }}
                  </span>
                </div>
                <div class="item-actions">
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="item.source === 'resource'"
                    (click)="openResourcePreview(item.resourceId)"
                    matTooltip="Podgląd zasobu"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="sendToLessonPlan(item, 'set')"
                    matTooltip="Dodaj do bieżącej lekcji"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Sets Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>library_books</mat-icon>
              <span class="panel-title">Zestawy</span>
            </mat-panel-title>
            <mat-panel-description *ngIf="getFrequentItems('set').length > 0">
              {{ getFrequentItems("set").length }} elementów
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="summary-card">
            <div class="summary-items">
              <div
                *ngFor="
                  let item of getFrequentItems('set');
                  trackBy: trackByItemId
                "
                class="summary-item"
              >
                <span class="item-name">{{ item.name }}</span>
                <span class="item-type" *ngIf="item.type">{{ item.type }}</span>
                <div class="item-usage">
                  <ng-container
                    *ngTemplateOutlet="
                      usageBadges;
                      context: { $implicit: item.usedIn }
                    "
                  ></ng-container>
                  <span
                    class="frequency-badge"
                    *ngIf="item.frequency > 0"
                    [ngClass]="{
                      low: item.frequency <= 1,
                      medium: item.frequency > 1 && item.frequency <= 3,
                      high: item.frequency > 3
                    }"
                    [matTooltip]="
                      'Użyto ' +
                      item.frequency +
                      ' ' +
                      getPolishCountSuffix(item.frequency)
                    "
                  >
                    {{ item.frequency }}
                  </span>
                </div>
                <div class="item-actions">
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="item.source === 'resource'"
                    (click)="openResourcePreview(item.resourceId)"
                    matTooltip="Podgląd zasobu"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="sendToLessonPlan(item, 'set')"
                    matTooltip="Dodaj do bieżącej lekcji"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Activities Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment</mat-icon>
              <span class="panel-title">Ćwiczenia</span>
            </mat-panel-title>
            <mat-panel-description
              *ngIf="getFrequentItems('activity').length > 0"
            >
              {{ getFrequentItems("activity").length }} elementów
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="summary-card">
            <div class="summary-items">
              <div
                *ngFor="
                  let item of getFrequentItems('activity');
                  trackBy: trackByItemId
                "
                class="summary-item"
              >
                <span class="item-name">{{ item.name }}</span>
                <span class="item-type" *ngIf="item.type">{{ item.type }}</span>
                <div class="item-usage">
                  <ng-container
                    *ngTemplateOutlet="
                      usageBadges;
                      context: { $implicit: item.usedIn }
                    "
                  ></ng-container>
                  <span
                    class="frequency-badge"
                    *ngIf="item.frequency > 0"
                    [ngClass]="{
                      low: item.frequency <= 1,
                      medium: item.frequency > 1 && item.frequency <= 3,
                      high: item.frequency > 3
                    }"
                    [matTooltip]="
                      'Użyto ' +
                      item.frequency +
                      ' ' +
                      getPolishCountSuffix(item.frequency)
                    "
                  >
                    {{ item.frequency }}
                  </span>
                </div>
                <div class="item-actions">
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="item.source === 'resource'"
                    (click)="openResourcePreview(item.resourceId)"
                    matTooltip="Podgląd zasobu"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="sendToLessonPlan(item, 'activity')"
                    matTooltip="Dodaj do bieżącej lekcji"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Homework Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>home_work</mat-icon>
              <span class="panel-title">Zadania domowe</span>
            </mat-panel-title>
            <mat-panel-description
              *ngIf="getFrequentItems('homework').length > 0"
            >
              {{ getFrequentItems("homework").length }} elementów
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="summary-card">
            <div class="summary-items">
              <div
                *ngFor="
                  let item of getFrequentItems('homework');
                  trackBy: trackByItemId
                "
                class="summary-item"
              >
                <span class="item-name">{{
                  item.displayName || item.name
                }}</span>
                <span class="item-type" *ngIf="item.type">{{ item.type }}</span>
                <div class="item-usage">
                  <ng-container
                    *ngTemplateOutlet="
                      usageBadges;
                      context: { $implicit: item.usedIn }
                    "
                  ></ng-container>
                  <span
                    class="frequency-badge"
                    *ngIf="item.frequency > 0"
                    [ngClass]="{
                      low: item.frequency <= 1,
                      medium: item.frequency > 1 && item.frequency <= 3,
                      high: item.frequency > 3
                    }"
                    [matTooltip]="
                      'Użyto ' +
                      item.frequency +
                      ' ' +
                      getPolishCountSuffix(item.frequency)
                    "
                  >
                    {{ item.frequency }}
                  </span>
                </div>
                <div class="item-actions">
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="item.source === 'resource'"
                    (click)="openResourcePreview(item.resourceId)"
                    matTooltip="Podgląd zasobu"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="sendToLessonPlan(item, 'homework')"
                    matTooltip="Dodaj do bieżącej lekcji"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Files Panel -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>insert_drive_file</mat-icon>
              <span class="panel-title">Pliki</span>
            </mat-panel-title>
            <mat-panel-description *ngIf="getFrequentItems('file').length > 0">
              {{ getFrequentItems("file").length }} elementów
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="summary-card">
            <div class="summary-items">
              <div
                *ngFor="
                  let item of getFrequentItems('file');
                  trackBy: trackByItemId
                "
                class="summary-item"
              >
                <span class="item-name">{{ item.name }}</span>
                <span class="item-type" *ngIf="item.type">{{ item.type }}</span>
                <div class="item-usage">
                  <ng-container
                    *ngTemplateOutlet="
                      usageBadges;
                      context: { $implicit: item.usedIn }
                    "
                  ></ng-container>
                  <span
                    class="frequency-badge"
                    *ngIf="item.frequency > 0"
                    [ngClass]="{
                      low: item.frequency <= 1,
                      medium: item.frequency > 1 && item.frequency <= 3,
                      high: item.frequency > 3
                    }"
                    [matTooltip]="
                      'Użyto ' +
                      item.frequency +
                      ' ' +
                      getPolishCountSuffix(item.frequency)
                    "
                  >
                    {{ item.frequency }}
                  </span>
                </div>
                <div class="item-actions">
                  <button
                    mat-icon-button
                    color="accent"
                    *ngIf="item.source === 'resource'"
                    (click)="openResourcePreview(item.resourceId)"
                    matTooltip="Podgląd zasobu"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="sendToLessonPlan(item, 'file')"
                    matTooltip="Dodaj do bieżącej lekcji"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="right-panel" [class.visible]="isRightPanelVisible">
    <div class="panel-header">
      <h2>Lista lekcji</h2>
      <div class="panel-actions">
        <button
          mat-icon-button
          color="primary"
          (click)="copyLessonsToClipboard()"
          matTooltip="Kopiuj wszystkie lekcje do schowka"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          (click)="copyLessonsTillSelectedToClipboard()"
          matTooltip="Kopiuj lekcje do bieżącej"
        >
          <mat-icon>copy_all</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="toggleRightPanel()"
          matTooltip="Ukryj panel"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="panel-content">
      <mat-button-toggle-group
        [value]="displayMode"
        (change)="changeDisplayMode($event)"
        class="display-mode-toggle"
      >
        <mat-button-toggle [value]="DisplayMode.PreviousUnarchived">
          Poprzednie
        </mat-button-toggle>
        <mat-button-toggle [value]="DisplayMode.AllUnarchived">
          Wszystkie
        </mat-button-toggle>
        <mat-button-toggle [value]="DisplayMode.All">
          Archiwalne
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-slide-toggle
        [checked]="includeFutureLessons"
        (change)="toggleIncludeFutureLessons()"
      >
        Uwzględnij przyszłe lekcje
      </mat-slide-toggle>

      <div
        class="lesson-container"
        *ngFor="
          let lesson of data.lessons;
          let i = index;
          trackBy: trackByLessonId
        "
      >
        <div
          class="card"
          [ngStyle]="{
            'background-color': getBackgroundColor(i),
            opacity: lesson.name.includes('archived') ? 0.5 : 1
          }"
        >
          <h2>{{ lesson.name }}</h2>

          <!-- Sets Section -->
          <div class="card">
            <h3>Zestawy</h3>
            <ul>
              <li
                *ngFor="let set of lesson.sets; trackBy: trackByItemId"
                class="item-row"
              >
                <div
                  class="item-content"
                  [matTooltip]="getUsageTooltip(set, 'set')"
                >
                  <span>{{ set.name }}</span>
                  <ng-container
                    *ngTemplateOutlet="
                      itemUsageInfo;
                      context: { $implicit: getItemUsage(set, 'set') }
                    "
                  ></ng-container>
                </div>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="sendToLessonPlan(set, 'set')"
                  class="add-button"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </li>
            </ul>
          </div>

          <!-- Activities Section -->
          <div class="card">
            <h3>Ćwiczenia</h3>
            <ul>
              <li
                *ngFor="
                  let activity of lesson.exercises;
                  trackBy: trackByItemId
                "
                class="item-row"
              >
                <div
                  class="item-content"
                  [matTooltip]="getUsageTooltip(activity, 'activity')"
                >
                  <span>{{ activity.name }}</span>
                  <ng-container
                    *ngTemplateOutlet="
                      itemUsageInfo;
                      context: { $implicit: getItemUsage(activity, 'activity') }
                    "
                  ></ng-container>
                </div>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="sendToLessonPlan(activity, 'activity')"
                  class="add-button"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </li>
            </ul>
          </div>

          <!-- Homework Section -->
          <div class="card">
            <h3>Zadania domowe</h3>
            <ul>
              <li
                *ngFor="let hw of lesson.homework; trackBy: trackByItemId"
                class="item-row"
              >
                <div
                  class="item-content"
                  [matTooltip]="getUsageTooltip(hw, 'homework')"
                >
                  <span>{{ hw.displayName || hw.name }}</span>
                  <ng-container
                    *ngTemplateOutlet="
                      itemUsageInfo;
                      context: { $implicit: getItemUsage(hw, 'homework') }
                    "
                  ></ng-container>
                </div>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="sendToLessonPlan(hw, 'homework')"
                  class="add-button"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </li>
            </ul>
          </div>

          <!-- Files Section -->
          <div class="card">
            <h3>Pliki</h3>
            <ul>
              <li
                *ngFor="let file of lesson.files; trackBy: trackByItemId"
                class="item-row"
              >
                <div
                  class="item-content"
                  [matTooltip]="getUsageTooltip(file, 'file')"
                >
                  <span>{{ file.name }}</span>
                  <ng-container
                    *ngTemplateOutlet="
                      itemUsageInfo;
                      context: { $implicit: getItemUsage(file, 'file') }
                    "
                  ></ng-container>
                </div>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="sendToLessonPlan(file, 'file')"
                  class="add-button"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #usageBadges let-usage>
  <div class="usage-badges">
    <span
      class="usage-badge"
      *ngFor="let type of usage | keyvalue"
      [ngClass]="type.key"
      [matTooltip]="'Użyto w ' + type.value + ' ' + type.key"
    >
      {{ type.value }}
    </span>
  </div>
</ng-template>

<ng-template #itemUsageInfo let-usage>
  <div class="usage-info">
    <span
      class="usage-badge"
      *ngFor="let type of usage | keyvalue"
      [ngClass]="type.key"
      [matTooltip]="'Użyto w ' + type.value + ' ' + type.key"
    >
      {{ type.value }}
    </span>
  </div>
</ng-template>
