import { DatePipe } from "@angular/common";
import { Component, OnInit, Input } from "@angular/core";
import { Teacher } from "src/app/shared/interfaces/teacher.interface";
import { GroupsService } from "src/app/shared/services/groups.service";

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent implements OnInit {
  @Input() teacher!: Teacher;
  teachersGroups: any;
  lessons: any[] = [];
  allLessons: any[] = [];
  originalLessons: any[] = [];
  groupedLessons: any[] = [];

  constructor(private groupsService: GroupsService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getTeachersGroups();
  }

  getTeachersGroups() {
    this.groupsService.groupsUpdatedListener().subscribe(groups => {

      let tempLessons: any[] = [];
      groups.forEach(group => {
        if (group.lessons && Array.isArray(group.lessons)) {
          tempLessons.push(...group.lessons);
        }
      });
      this.allLessons = tempLessons;
      this.originalLessons = [...tempLessons];

      this.teachersGroups = groups;
      this.filterByMonth();
      setTimeout(() => {
        this.groupLessonsByGroupName();
      }, 1000);

    });
    this.groupsService.findGroups(this.teacher.name);
  }

  filterByTwoWeeks() {
    const now = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(now.getDate() - 14);
    this.lessons = this.originalLessons
      .filter(lesson => {
        const lessonDate = new Date(lesson.date);
        return lessonDate >= twoWeeksAgo && lessonDate <= now && lesson.attributes.includes('avalible');
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  filterByMonth() {
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1);
    this.lessons = this.originalLessons
      .filter(lesson => {
        const lessonDate = new Date(lesson.date);
        return lessonDate >= oneMonthAgo && lessonDate <= now && lesson.attributes.includes('avalible');
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  groupLessonsByGroupName() {
    const grouped = this.lessons.reduce((acc, lesson) => {
      if (lesson.assignedTeacher.name !== this.teacher.name) {
        return acc; // Skip lessons where the assigned teacher does not equal the selected teacher
      }
      const groupName = lesson.group.name;
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      // Find the group from teachersGroups to get the students
      const group = this.teachersGroups.find(g => g.name === groupName);
      if (group) {
        lesson.students = group.students; // Add students to the lesson
      }
      acc[groupName].push(lesson);
      return acc;
    }, {});

    this.groupedLessons = Object.keys(grouped).map(groupName => {
      const group = this.teachersGroups.find(g => g.name === groupName);
      return {
        groupName,
        lessons: grouped[groupName],
        group: group // Add the original group object
      };
    });

    this.groupedLessons.forEach(group => {
      group.currentLessonIndex = 0;
      group.students = group.lessons[0].students;
      group.lessons.forEach((lesson: any) => {
        lesson.activeColor = 'black';
        lesson.inactiveColor = 'lightgray';
        // logic for changing colors
      });
    });

  }
}