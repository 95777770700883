<div
  class="mat-typography"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
>
  <button
    *ngIf="editable"
    mat-flat-button
    color="primary"
    (click)="openAddCoursePanel(); $event.preventDefault()"
  >
    Dodaj element kursu
  </button>
  <div
    cdkDropList
    style="width: 100%"
    (cdkDropListDropped)="editable ? drop($event) : null"
  >
    <div *ngFor="let item of plan; let i = index">
      <mat-card
        [ngClass]="{ 'p-0 mat-elevation-z0': lessonView }"
        *ngIf="lessonView ? itemToDisplay == i : true"
        cdkDrag
        [cdkDragDisabled]="!draggable"
        class="mt-2"
        style="width: 100%; max-height: 25vh; overflow: auto"
        fxLayout="column"
        fxLayoutAlign="start"
      >
        <span
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="center center"
          [ngClass]="{ disabled: item.finished }"
          fxFlex
          style="font-size: 1.2em"
          fxLayoutGap="15px"
          class="mat-body-strong"
        >
          <span>{{ i + 1 + "/" + plan.length }}</span>
          <span>{{ getItemName(item) }}</span>
        </span>
        <label *ngIf="userDetailsService.owner || userDetailsService.office"
          >Przenieś na pozycję<input
            style="
              width: 40px;
              margin-left: 10px;
              border: 2px solid #673ab7;
              border-radius: 5px;
              text-align: center;
            "
            type="number"
            [(ngModel)]="item.newPosition"
          />
          <button
            color="primary"
            [matTooltip]="
              item?.newPosition
                ? 'Przenieś na pozycję ' + item.newPosition
                : 'Wpisz numer pozycji aby przenieść'
            "
            (click)="
              $event.preventDefault();
              ($event.stopPropagation);
              rearrangeItem(i, item.newPosition - 1)
            "
            mat-icon-button
          >
            <span class="material-symbols-outlined">compress</span>
          </button>
        </label>
        <div fxLayoutAlign="sart center" style="width: 100%; overflow: auto">
          <span style="font-size: 1.1em; height: 75px">{{
            getItemDescription(item)
          }}</span>
        </div>
        <div
          class="mt-2"
          fxLayout="row"
          style="width: 100%; height: 150px; overflow: auto"
        >
          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="stretch stretch"
            fxFlex
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <div
              fxFlex
              style="
                border: 1px solid #673ab7;
                border-radius: 5px;
                height: 100%;
                background-color: #d1c4e9;
              "
              fxLayout="column"
              fxLayoutAlign="center center"
            >
              <span
                fxLayoutAlign="center center"
                style="
                  border-bottom: 1px solid #673ab7;
                  width: 100%;
                  text-align: center;
                "
              >
                <span class="m-0">Mozliwe zestawy</span>
                <button
                  *ngIf="editable"
                  mat-icon-button
                  color="primary"
                  (click)="
                    openAddSetsList();
                    $event.preventDefault();
                    $event.stopPropagation()
                  "
                  matTooltip="Dodaj nowe zestawy"
                >
                  <span class="material-symbols-outlined">add_circle</span>
                </button>
              </span>
              <div
                class="block"
                fxLayout="column"
                style="height: 100%; width: 100%; overflow-y: auto"
              >
                <mat-chip
                  [ngClass]="{ 'mt-2': i == 0 }"
                  [matBadgeColor]="
                    set.type == 'children'
                      ? 'accent'
                      : set.type == 'exercise' || set.type == 'main'
                      ? 'primary'
                      : 'warn'
                  "
                  [matBadge]="set.items"
                  style="width: 100%; transform: scale(0.85)"
                  selected
                  color="primary"
                  *ngFor="let set of getItemSets(item); let i = index"
                >
                  <span
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      previewSet(set)
                    "
                    class="material-symbols-outlined mr-1"
                    >visibility
                  </span>
                  <span fxFlex style="text-align: center">
                    {{ set.name }}
                  </span>
                  <span
                    (click)="addSetClicked(set)"
                    class="material-symbols-outlined ml-1"
                    >add_circle_outline
                  </span>
                </mat-chip>
                <div
                  *ngIf="getItemSets(item)?.length === 0"
                  class="text-center p-2"
                >
                  <span>Brak zestawów</span>
                </div>
              </div>
            </div>
            <div
              fxFlex
              style="
                border: 1px solid #ffc107;
                border-radius: 5px;
                height: 100%;
                background-color: #ffecb3;
              "
              fxLayout="column"
              fxLayoutAlign="center center"
            >
              <span
                fxLayoutAlign="center center"
                style="
                  border-bottom: 1px solid #ffc107;
                  width: 100%;
                  text-align: center;
                "
              >
                <span class="m-0">Dostępne ćwiczenia</span>
                <button
                  *ngIf="editable"
                  mat-icon-button
                  color="accent"
                  (click)="
                    openActivityDialog();
                    $event.preventDefault();
                    $event.stopPropagation()
                  "
                  matTooltip="Dodaj ćwiczenie"
                >
                  <span class="material-symbols-outlined">add_circle</span>
                </button>
              </span>
              <div
                class="block"
                fxLayout="column"
                style="height: 100%; width: 100%; overflow-y: auto"
              >
                <mat-chip
                  [ngClass]="{ 'mt-2': i == 0 }"
                  style="width: 100%; transform: scale(0.85)"
                  selected
                  color="accent"
                  *ngFor="
                    let activity of getItemActivities(item);
                    let i = index
                  "
                >
                  <span
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      previewActivity(activity)
                    "
                    class="material-symbols-outlined mr-1"
                    >visibility
                  </span>
                  <span fxFlex style="text-align: center">{{
                    activity.name
                  }}</span>
                  <span
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      addActivityClicked(activity)
                    "
                    class="material-symbols-outlined ml-1"
                    >add_circle_outline
                  </span>
                </mat-chip>
                <div
                  *ngIf="getItemActivities(item)?.length === 0"
                  class="text-center p-2"
                >
                  <span>Brak ćwiczeń</span>
                </div>
              </div>
            </div>
            <div
              fxFlex
              style="
                border: 1px solid #f44336;
                border-radius: 5px;
                height: 100%;
                background-color: #ffcdd2;
              "
              fxLayout="column"
              fxLayoutAlign="center center"
            >
              <span
                fxLayoutAlign="center center"
                style="
                  border-bottom: 1px solid #f44336;
                  width: 100%;
                  text-align: center;
                "
              >
                <span class="m-0">Zadania domowe</span>
                <button
                  *ngIf="editable"
                  mat-icon-button
                  color="warn"
                  (click)="
                    openHomeworkDialog();
                    $event.preventDefault();
                    $event.stopPropagation()
                  "
                  matTooltip="Dodaj zadanie domowe"
                >
                  <span class="material-symbols-outlined">add_circle</span>
                </button>
              </span>
              <div
                class="block"
                fxLayout="column"
                style="height: 100%; width: 100%; overflow-y: auto"
              >
                <mat-chip
                  [ngClass]="{ 'mt-2': i == 0 }"
                  style="width: 100%; transform: scale(0.85)"
                  selected
                  color="warn"
                  *ngFor="let homework of getItemHomework(item); let i = index"
                >
                  <span
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      previewHomework(homework)
                    "
                    class="material-symbols-outlined mr-1"
                    >visibility
                  </span>
                  <span fxFlex style="text-align: center">{{
                    homework.displayName
                  }}</span>
                  <span
                    *ngIf="lessonView"
                    (click)="
                      $event.stopPropagation();
                      $event.preventDefault();
                      addHomeworkClicked(homework)
                    "
                    class="material-symbols-outlined ml-1"
                    >add_circle_outline
                  </span>
                </mat-chip>
                <div
                  *ngIf="getItemHomework(item)?.length === 0"
                  class="text-center p-2"
                >
                  <span>Brak zadań domowych</span>
                </div>
              </div>
            </div>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <div
              *ngIf="!lessonView"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
            >
              <button
                matTooltip="Element opcjonalny"
                mat-icon-button
                [disabled]="!editable"
                [ngClass]="{ disabled: !item.optional }"
                [color]="item?.optional ? 'primary' : null"
                (click)="
                  $event.stopPropagation();
                  $event.preventDefault();
                  item.optional = !item.optional
                "
              >
                <mat-icon>
                  <span class="material-symbols-outlined">psychology_alt</span>
                </mat-icon>
              </button>
              <button
                *ngIf="editable"
                type="null"
                (click)="
                  $event.stopPropagation();
                  $event.preventDefault();
                  removeItem(item)
                "
                mat-icon-button
                color="warn"
              >
                <span class="material-symbols-outlined">delete_forever</span>
              </button>
              <span cdkDragHandle class="material-symbols-outlined"
                >drag_pan
              </span>
            </div>
          </div>
        </div>
        <!-- Check if item.item.item exists and assign to planItemDetail -->
        <div
          *ngIf="getPlanItemFiles(item)?.length > 0"
          class="w-100 mt-2 d-flex flex-column align-items-center"
          style="
            height: 10vh;
            border: 1px solid #d1c4e9;
            border-radius: 5px;
            overflow: auto;
          "
        >
          <div
            class="d-flex flex-row w-100 justify-content-center align-items-center"
          >
            Pliki
          </div>
          <div class="d-flex flex-column">
            <span
              class="d-flex flex-row flex-wrap justify-content-start align-items-start"
              *ngFor="let file of getPlanItemFiles(item)"
            >
              <a
                class="mr-2 mb-1"
                style="color: #3f51b5; text-decoration: none; cursor: pointer"
                (click)="openFileWithFreshUrl(file.url)"
              >
                {{ file.name }}
              </a>
              <span class="mat-small ml-3">{{ file.description }}</span>
            </span>
          </div>
        </div>
        <div
          fxLayout="row"
          class="mt-2"
          style="width: 100%"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <button
            (click)="
              showPreviousItem();
              $event.stopPropagation();
              $event.preventDefault()
            "
            *ngIf="lessonView"
            type="null"
            mat-stroked-button
            color="primary"
            style="width: 50%"
          >
            Poprzedni
          </button>
          <button
            *ngIf="lessonView"
            type="null"
            (click)="
              showNextItem(); $event.stopPropagation(); $event.preventDefault()
            "
            mat-stroked-button
            color="primary"
            style="width: 50%"
          >
            Następny
          </button>
          <button
            style="width: 0px"
            id="finishButton"
            [disabled]="!this.plan[this.itemToDisplay + 1]?.item"
            fxFlex
            *ngIf="
              lessonView &&
              (userDetailsService?.owner || userDetailsService?.office)
            "
            type="null"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              markAsFinished(item)
            "
            mat-raised-button
            color="primary"
          >
            Ukończono
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
