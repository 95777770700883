import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesResourcesComponent } from './courses-resources.component';
import { CurriculumManagementComponent } from './curriculum-management/curriculum-management.component';

const routes: Routes = [
    {
        path: '',
        component: CoursesResourcesComponent
    },
    {
        path: 'curriculum',
        component: CurriculumManagementComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoursesResourcesRoutingModule { } 