import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-curriculum-json-import-dialog',
    templateUrl: './curriculum-json-import-dialog.component.html',
    styleUrls: ['./curriculum-json-import-dialog.component.css']
})
export class CurriculumJsonImportDialogComponent implements OnInit {
    jsonContent: string = '';
    repairAttempted: boolean = false;
    originalContent: string = '';

    // Example data to show in the dialog
    exampleJson: string = JSON.stringify({
        name: 'Program nauczania języka angielskiego - poziom A1',
        units: [
            {
                topic: 'Ludzie i ich otoczenie',
                subjectArea: 'Człowiek',
                subjectSubArea: 'Dane personalne, cechy charakteru',
                functions: 'Przedstawianie siebie i innych, pytanie o dane osobowe',
                vocabularyTopic: 'Rodzina i przyjaciele',
                vocabularyList: 'Alfabet, Liczby, Kolory, Przedmioty w klasie, Członkowie rodziny',
                grammar: [
                    'a/an',
                    'Liczba mnoga rzeczowników',
                    'Zaimki dzierżawcze: my/your',
                    'Czasownik to be - forma twierdząca'
                ],
                communicativeSkills: [
                    'Przedstawianie siebie i innych',
                    'Pytanie o dane osobowe',
                    'Opisywanie wyglądu osób'
                ],
                receptiveSkills: [
                    'Rozumienie prostych opisów osób',
                    'Znajdowanie w tekście określonych informacji o ludziach'
                ],
                productiveSkills: [
                    'Opisywanie wyglądu i cech charakteru',
                    'Tworzenie krótkich notatek o sobie'
                ],
                interactiveSkills: [
                    'Prowadzenie prostych rozmów o sobie',
                    'Zadawanie prostych pytań o dane osobowe'
                ],
                processingSkills: [
                    'Przekazywanie informacji o innych osobach'
                ],
                otherSkills: [
                    'Współdziałanie w parach przy zadaniach'
                ],
                coreStandards: [
                    'I.1', 'II.2', 'III.4'
                ],
                listening: 'Słuchanie prostych dialogów przedstawiających się osób',
                reading: 'Czytanie profili osób, rozumienie podstawowych informacji',
                speaking: 'Przedstawianie siebie i zadawanie pytań o dane osobowe',
                writing: 'Pisanie krótkiego opisu o sobie i swojej rodzinie'
            },
            {
                topic: 'Moje rzeczy',
                subjectArea: 'Życie prywatne',
                subjectSubArea: 'Przedmioty codziennego użytku',
                functions: 'Opisywanie przedmiotów, wyrażanie posiadania',
                vocabularyTopic: 'Ubrania i przedmioty codziennego użytku',
                vocabularyList: 'Ubrania, Przymiotniki opisujące przedmioty, Kolory, Moje rzeczy',
                grammar: [
                    'Dopełniacz saksoński',
                    'Czasownik to be - forma przecząca',
                    'Zaimki wskazujące: this/that'
                ],
                communicativeSkills: [
                    'Opisywanie przedmiotów',
                    'Wyrażanie posiadania'
                ],
                receptiveSkills: [
                    'Rozumienie opisów przedmiotów',
                    'Wyszukiwanie informacji w tekście o przedmiotach'
                ],
                productiveSkills: [
                    'Opisywanie wyglądu przedmiotów',
                    'Pisanie o swoich rzeczach'
                ],
                interactiveSkills: [
                    'Pytanie o przedmioty innych osób',
                    'Udzielanie informacji o swoich rzeczach'
                ],
                processingSkills: [
                    'Przekazywanie informacji z materiałów wizualnych'
                ],
                otherSkills: [
                    'Porównywanie swoich rzeczy z rzeczami innych'
                ],
                coreStandards: [
                    'I.5', 'II.5', 'IV.1'
                ],
                listening: 'Słuchanie nagrań o przedmiotach codziennego użytku',
                reading: 'Czytanie tekstów o ulubionych rzeczach innych osób',
                speaking: 'Opowiadanie o swoich ulubionych przedmiotach',
                writing: 'Pisanie krótkiego tekstu o swoich rzeczach'
            }
        ]
    }, null, 2);

    constructor(
        public dialogRef: MatDialogRef<CurriculumJsonImportDialogComponent>,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onImport(): void {
        if (!this.jsonContent) {
            return;
        }

        try {
            // Attempt to parse the JSON directly first
            const data = JSON.parse(this.jsonContent);
            this.dialogRef.close(data);
        } catch (e) {
            console.error('Invalid JSON, attempting to repair:', e);

            if (!this.repairAttempted) {
                // Save original content before attempting repair
                this.originalContent = this.jsonContent;

                // Track what was fixed
                const originalContent = this.jsonContent;

                // Try to repair the JSON
                const repairedJson = this.repairJson(this.jsonContent);
                this.jsonContent = repairedJson;
                this.repairAttempted = true;

                // Determine what was likely fixed
                const fixedIssues: string[] = [];

                if (originalContent.match(/(\s*)(\w+)(\s*):/g)) {
                    fixedIssues.push('brakujące cudzysłowy');
                }

                if (originalContent.match(/'\w+'/g)) {
                    fixedIssues.push('apostrofy zamiast cudzysłowów');
                }

                if (originalContent.match(/,\s*([}\]])/g)) {
                    fixedIssues.push('zbędne przecinki');
                }

                // Check for unbalanced brackets
                const openBraces = (originalContent.match(/{/g) || []).length;
                const closeBraces = (originalContent.match(/}/g) || []).length;
                const openBrackets = (originalContent.match(/\[/g) || []).length;
                const closeBrackets = (originalContent.match(/\]/g) || []).length;

                if (openBraces !== closeBraces || openBrackets !== closeBrackets) {
                    fixedIssues.push('niedomknięte nawiasy');
                }

                // Check for missing colons
                if (originalContent.match(/"([^"]+)"\s+([{["'\w])/g)) {
                    fixedIssues.push('brakujące dwukropki');
                }

                try {
                    // Try parsing the repaired JSON
                    const data = JSON.parse(repairedJson);

                    // Show success message with what was fixed
                    let message = 'Naprawiono błędy w formacie JSON.';
                    if (fixedIssues.length > 0) {
                        message += ' Naprawiono: ' + fixedIssues.join(', ') + '.';
                    }
                    this.snackBar.open(message, 'OK', { duration: 5000 });
                } catch (repairError) {
                    // Still invalid after repair
                    this.snackBar.open('Nie udało się naprawić formatu JSON. Sprawdź składnię ręcznie.', 'OK', { duration: 3000 });
                    this.dialogRef.close({ error: 'invalid_json' });
                }
            } else {
                // Already attempted repair, still failing
                this.dialogRef.close({ error: 'invalid_json' });
            }
        }
    }

    // Function to repair common JSON syntax errors
    repairJson(jsonString: string): string {
        let result = jsonString;

        // Fix 1: Remove trailing commas in objects and arrays
        result = result.replace(/,\s*([}\]])/g, '$1');

        // Fix 2: Add missing quotes around property names
        result = result.replace(/(\s*)(\w+)(\s*):/g, '$1"$2"$3:');

        // Fix 3: Fix unquoted string values that are not true, false, null, or numbers
        result = result.replace(/:(\s*)([a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ][a-zA-Z0-9_\s\-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*?)([,}\]])/g, function (match, space, value, ending) {
            // Skip if the value is a boolean, null, or number
            if (['true', 'false', 'null'].includes(value.trim()) || !isNaN(Number(value.trim()))) {
                return match;
            }
            return ':' + space + '"' + value.trim() + '"' + ending;
        });

        // Fix 4: Fix single quotes used instead of double quotes
        result = result.replace(/'/g, '"');

        // Fix 5: Replace Unicode spaces with regular spaces
        result = result.replace(/\u00A0/g, ' ');

        // Fix 6: Fix missing colons in property assignments
        result = result.replace(/"([^"]+)"\s+([{["'\w])/g, '"$1": $2');

        // Fix 7: Handle bracket/brace balancing more intelligently
        let balancedResult = this.balanceBracketsAndBraces(result);

        return balancedResult;
    }

    // Helper method to balance brackets and braces in JSON
    balanceBracketsAndBraces(json: string): string {
        // Create stacks to track opening brackets/braces
        const stack: string[] = [];
        const charArray = json.split('');

        // First pass - check if there are more closing than opening brackets
        // If so, we need to remove the extra closing ones
        const bracketCounts = {
            '{': 0, '}': 0,
            '[': 0, ']': 0
        };

        // Count all brackets
        for (const char of charArray) {
            if (char in bracketCounts) {
                bracketCounts[char]++;
            }
        }

        // If there are more closing than opening, clean those up
        if (bracketCounts['}'] > bracketCounts['{'] ||
            bracketCounts[']'] > bracketCounts['[']) {

            // Create a new array to rebuild the string
            const cleanedArray: string[] = [];
            const tempStack: string[] = [];

            for (const char of charArray) {
                if (char === '{' || char === '[') {
                    tempStack.push(char);
                    cleanedArray.push(char);
                } else if (char === '}') {
                    if (tempStack.length > 0 && tempStack[tempStack.length - 1] === '{') {
                        tempStack.pop();
                        cleanedArray.push(char);
                    }
                    // Skip extra closing braces
                } else if (char === ']') {
                    if (tempStack.length > 0 && tempStack[tempStack.length - 1] === '[') {
                        tempStack.pop();
                        cleanedArray.push(char);
                    }
                    // Skip extra closing brackets
                } else {
                    cleanedArray.push(char);
                }
            }

            json = cleanedArray.join('');
        }

        // Second pass - traverse the JSON to find unbalanced brackets and fix them
        for (let i = 0; i < json.length; i++) {
            const char = json[i];

            if (char === '{' || char === '[') {
                stack.push(char);
            } else if (char === '}') {
                if (stack.length === 0 || stack[stack.length - 1] !== '{') {
                    // Mismatched closing brace - remove it
                    json = json.substring(0, i) + json.substring(i + 1);
                    i--;  // Adjust index after removal
                } else {
                    stack.pop();
                }
            } else if (char === ']') {
                if (stack.length === 0 || stack[stack.length - 1] !== '[') {
                    // Mismatched closing bracket - remove it
                    json = json.substring(0, i) + json.substring(i + 1);
                    i--;  // Adjust index after removal
                } else {
                    stack.pop();
                }
            }
        }

        // Now add any missing closing brackets/braces in the correct order
        while (stack.length > 0) {
            const openingChar = stack.pop();
            if (openingChar === '{') {
                json += '}';
            } else if (openingChar === '[') {
                json += ']';
            }
        }

        // Fix 8: Check for and fix incomplete arrays in property values
        // This regex looks for array properties that might be unterminated
        json = json.replace(/"([^"]+)":\s*\[(([^[\]{}]*?)(,\s*)?)*?(\s*}|$)/g, (match, propName, innerContent, lastItem, comma, end) => {
            // If the match ends with a closing brace without a closing bracket, add the missing bracket
            if (end.trim() === '}' || end.trim() === '') {
                return match.replace(end, ']' + end);
            }
            return match;
        });

        // Fix 9: Fix arrays immediately followed by properties without commas
        json = json.replace(/\](\s*)"/g, '],$1"');

        // Fix 10: Fix objects immediately followed by properties without commas
        json = json.replace(/}(\s*)"/g, '},$1"');

        return json;
    }

    // Method to revert to original content
    revertChanges(): void {
        if (this.originalContent && this.repairAttempted) {
            this.jsonContent = this.originalContent;
            this.repairAttempted = false;
            this.snackBar.open('Przywrócono oryginalną treść JSON.', 'OK', { duration: 2000 });
        }
    }

    useExample(): void {
        this.jsonContent = this.exampleJson;
        this.repairAttempted = false;
    }
} 