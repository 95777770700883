<h2 mat-dialog-title>
  <ng-container *ngIf="isFinanceDialog"
    >{{ data?.id ? "Edit" : "Add" }} Finance Entry</ng-container
  >
  <ng-container *ngIf="isTeacherDialog"
    >{{ data?.name ? "Edit" : "Add" }} Teacher Cost</ng-container
  >
  <ng-container *ngIf="isStateDialog">Edit State</ng-container>
</h2>

<form [formGroup]="financeForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <!-- Finance specific fields -->
      <ng-container *ngIf="isFinanceDialog">
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option value="expense">Expense</mat-option>
            <mat-option value="income">Income</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            <mat-option value="monthly">Monthly</mat-option>
            <mat-option value="fixed">Fixed</mat-option>
            <mat-option value="combined">Combined</mat-option>
          </mat-select>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox formControlName="isCompany">Company</mat-checkbox>
          <mat-checkbox formControlName="isTeacher">Teacher</mat-checkbox>
        </div>
      </ng-container>

      <!-- Common fields for all dialog types -->
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="Name" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          <ng-container *ngIf="isTeacherDialog">Hourly Rate</ng-container>
          <ng-container *ngIf="!isTeacherDialog">Value</ng-container>
        </mat-label>
        <input
          matInput
          type="number"
          formControlName="value"
          placeholder="Value"
        />
        <span matSuffix *ngIf="isTeacherDialog">PLN/hour</span>
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <button type="button" mat-button (click)="onCancel()">Cancel</button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="financeForm.invalid"
    >
      Save
    </button>
  </div>
</form>
