import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LessonsArchiveService {
    private lessonsUpdated = new Subject<any>();
    private url: string;

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private versionService: VersionService
    ) {
        this.url = this.versionService.url;
    }

    saveLessonToArchive(lessonData: any): Promise<any> {
        return this.http.post(
            `${this.url}/api/lessons-archive/savelesson`,
            lessonData,
            { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
        ).pipe(
            tap(response => {
                // Notify subscribers that lessons have been updated
                this.lessonsUpdated.next(response);
            }),
            catchError(error => {
                console.error('Error saving lesson to archive:', error);
                throw error;
            })
        ).toPromise();
    }

    searchLessons(params: {
        startDate?: string;
        endDate?: string;
        groupName?: string;
        teacherId?: string;
        studentId?: string;
        courseId?: string;
        courseName?: string;
        page?: number;
        limit?: number;
    }) {
        return this.http.get(
            `${this.url}/api/lessons-archive/search`,
            {
                params: params as any,
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            }
        ).toPromise();
    }

    getLessonById(id: string) {
        return this.http.get(
            `${this.url}/api/lessons-archive/lesson/${id}`,
            { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
        ).toPromise();
    }

    getLessonsByGroupId(groupId: string, page: number = 1, limit: number = 10) {
        return this.http.get(
            `${this.url}/api/lessons-archive/group`,
            {
                params: { groupId, page: page.toString(), limit: limit.toString() },
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            }
        ).toPromise();
    }

    getLessonsByTeacherId(teacherId: string, page: number = 1, limit: number = 10) {
        return this.http.get(
            `${this.url}/api/lessons-archive/teacher`,
            {
                params: { teacherId, page: page.toString(), limit: limit.toString() },
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            }
        ).toPromise();
    }

    getLessonsByStudentId(studentId: string, page: number = 1, limit: number = 10) {
        return this.http.get(
            `${this.url}/api/lessons-archive/student`,
            {
                params: { studentId, page: page.toString(), limit: limit.toString() },
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            }
        ).toPromise();
    }

    getLessonsByCourseId(courseId: string, page: number = 1, limit: number = 10) {
        return this.http.get(
            `${this.url}/api/lessons-archive/course`,
            {
                params: { courseId, page: page.toString(), limit: limit.toString() },
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            }
        ).toPromise();
    }

    deleteLessonById(lessonId: string): Promise<any> {
        return this.http.delete(
            `${this.url}/api/lessons-archive/lesson/${lessonId}`,
            { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
        ).pipe(
            tap(response => {
                // Notify subscribers that lessons have been updated
                this.lessonsUpdated.next(response);
            }),
            catchError(error => {
                console.error('Error deleting lesson from archive:', error);
                throw error;
            })
        ).toPromise();
    }

    deleteMultipleLessons(lessonIds: string[]): Promise<any> {
        const options = {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
            body: { lessonIds }
        };

        return this.http.request(
            'delete',
            `${this.url}/api/lessons-archive/lessons`,
            options
        ).pipe(
            tap(response => {
                // Notify subscribers that lessons have been updated
                this.lessonsUpdated.next(response);
            }),
            catchError(error => {
                console.error('Error deleting multiple lessons from archive:', error);
                throw error;
            })
        ).toPromise();
    }

    lessonsUpdatedListener() {
        return this.lessonsUpdated.asObservable();
    }
} 