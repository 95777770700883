import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurriculumService } from '../curriculum.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CurriculumAddEditComponent } from '../curriculum-add-edit/curriculum-add-edit.component';
import { CurriculumViewComponent } from '../curriculum-add-edit/curriculum-view/curriculum-view.component';

@Component({
    selector: 'app-curriculum-management',
    templateUrl: './curriculum-management.component.html',
    styleUrls: ['./curriculum-management.component.css']
})
export class CurriculumManagementComponent implements OnInit {
    curriculumList: any[] = [];
    filteredCurriculumList: any[] = [];
    isLoading: boolean = true;
    searchTerm: string = '';
    isMigrating: boolean = false;

    constructor(
        private curriculumService: CurriculumService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private helpers: HelpersService
    ) { }

    ngOnInit(): void {
        this.loadCurriculumList();
    }

    loadCurriculumList(): void {
        this.isLoading = true;
        this.curriculumService.getCurriculumList()
            .then(curriculums => {
                this.curriculumList = curriculums;
                this.applyFilter();
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Błąd podczas ładowania listy programów nauczania:', error);
                this.snackBar.open('Nie udało się załadować listy programów nauczania', 'OK', { duration: 3000 });
                this.isLoading = false;
            });
    }

    applyFilter(): void {
        if (!this.searchTerm) {
            this.filteredCurriculumList = [...this.curriculumList];
        } else {
            const searchTermLower = this.searchTerm.toLowerCase();
            this.filteredCurriculumList = this.curriculumList.filter(curriculum =>
                curriculum.data.name.toLowerCase().includes(searchTermLower)
            );
        }
    }

    openAddCurriculumDialog(): void {
        const dialogRef = this.dialog.open(CurriculumAddEditComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.name) {
                this.saveCurriculum(result);
            }
        });
    }

    editCurriculum(curriculum: any): void {
        const dialogRef = this.dialog.open(CurriculumAddEditComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {
                curriculum: this.helpers.clone(curriculum.data)
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.name) {
                this.updateCurriculum(curriculum._id, result);
            }
        });
    }

    viewCurriculum(curriculum: any): void {
        const dialogRef = this.dialog.open(CurriculumViewComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {
                curriculum: this.helpers.clone(curriculum.data)
            }
        });
    }

    deleteCurriculum(id: string): void {
        if (confirm('Czy na pewno chcesz usunąć ten program nauczania? Tej operacji nie można cofnąć.')) {
            this.curriculumService.removeCurriculum(id)
                .then(() => {
                    this.snackBar.open('Program nauczania został usunięty', 'OK', { duration: 3000 });
                    this.loadCurriculumList();
                })
                .catch(error => {
                    console.error('Błąd podczas usuwania programu nauczania:', error);
                    this.snackBar.open('Nie udało się usunąć programu nauczania', 'OK', { duration: 3000 });
                });
        }
    }

    saveCurriculum(curriculum: any): void {
        this.curriculumService.saveCurriculum(curriculum)
            .then(response => {
                this.snackBar.open('Program nauczania został zapisany', 'OK', { duration: 3000 });
                this.loadCurriculumList();
            })
            .catch(error => {
                console.error('Błąd podczas zapisywania programu nauczania:', error);
                this.snackBar.open('Nie udało się zapisać programu nauczania', 'OK', { duration: 3000 });
            });
    }

    updateCurriculum(id: string, curriculum: any): void {
        this.curriculumService.updateCurriculum(id, curriculum)
            .then(response => {
                this.snackBar.open('Program nauczania został zaktualizowany', 'OK', { duration: 3000 });
                this.loadCurriculumList();
            })
            .catch(error => {
                console.error('Błąd podczas aktualizacji programu nauczania:', error);
                this.snackBar.open('Nie udało się zaktualizować programu nauczania', 'OK', { duration: 3000 });
            });
    }

    /**
     * Triggers the migration of embedded curriculums from resources to standalone curriculum documents
     */
    migrateCurriculums(): void {
        if (confirm('Ta operacja zmigruje wszystkie programy nauczania z zasobów kursów do oddzielnej kolekcji programów nauczania. Czy chcesz kontynuować?')) {
            this.isMigrating = true;
            this.curriculumService.migrateCurriculums()
                .then((response: any) => {
                    this.snackBar.open(`Migracja zakończona. Przeniesiono ${response.migratedCount} programów nauczania.`, 'OK', { duration: 5000 });
                    this.loadCurriculumList(); // Refresh list to show new curriculums
                })
                .catch(error => {
                    console.error('Błąd podczas migracji:', error);
                    this.snackBar.open('Wystąpił błąd podczas migracji. Sprawdź logi serwera.', 'OK', { duration: 5000 });
                })
                .finally(() => {
                    this.isMigrating = false;
                });
        }
    }
} 