import { Injectable } from '@angular/core';
import { Pdf2ExercisesService } from 'src/app/pdf2/pdf2-exercises.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesPlanItemPropsService {
  homeworks = []
  ages = [
    'Przedszkole',
    '1 klasa', '2 klasa', '3 klasa', '4 klasa', '5 klasa', '6 klasa', '7 klasa', '8 klasa', '1 klasaLT', '2 klasaLT', '3 klasaLT', '4 klasaLT', 'Dorosły'
  ];
  skills = ['Czytanie', 'Pisanie', 'Komunikacja', 'Słuchanie', 'Wymowa', 'Gramatyka', 'Słownictwo']
  constructor(
    private pdf2ExercisesService: Pdf2ExercisesService
  ) {
    this.homeworks = this.pdf2ExercisesService.childrenPdfTypes.concat(this.pdf2ExercisesService.freeSetPdfTypes).concat(this.pdf2ExercisesService.sentencesPdfTypes)
  }
}
