import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import sortArray from 'sort-array';
import compare from 'compare-arrays';
import clone from 'clone-deep';
const findDuplicates = require('array-find-duplicates');
const uniqid = require('uniqid');
import randomItem from 'random-item';
import uniqueRandomArray from 'unique-random-array';
import { addHours, isDate } from 'date-fns';
import { AngularEditorConfig } from '@kolkov/angular-editor';
const arrayPower = require('array-power');
const replaceAll = require('just-replace-all');
var clg = require('crossword-layout-generator');
var scramble = require('scramble');

const arrayHelper = require('array-helper-functions');
const WordSearch = require('@blex41/word-search');

interface TrackItemUsageOptions {
  includeFuture?: boolean;
  currentLessonName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300',
    minHeight: '300',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    // upload: (file: File) => { ... }
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };
  itemsInTasks = []
  accents = [
    'á',
    'à',
    'â',
    'ä',
    'ã',
    'é',
    'è',
    'ê',
    'ë',
    'í',
    'ì',
    'î',
    'ï',
    'ó',
    'ò',
    'ô',
    'ö',
    'õ',
    'ú',
    'ù',
    'û',
    'ü',
    'ñ',
    'ç',
    'ß',
    'ă',
    'Ă',
    'ĕ',
    'Ĕ',
    'ğ',
    'Ğ',
    'ĭ',
    'Ĭ',
    'ŏ',
    'Ŏ',
    'œ',
    'Œ',
    'ř',
    'Ř',
    'ş',
    'Ş',
    'ț',
    'Ț',
    'ů',
    'Ů',
    'ű',
    'Ű',
    'ź',
    'Ź',
    'ż',
    'Ż',
  ];
  accentMap = {
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
  };
  colors100 = [
    '#ffcdd2',
    '#e1bee7',
    '#d1c4e9',
    '#c5cae9',
    '#b3e5fc',
    '#b2ebf2',
    '#b2dfdb',
    '#c8e6c9',
    '#dcedc8',
    '#f0f4c3',
    '#fff9c4',
    '#ffecb3',
    '#ffe0b2',
  ];
  colors400 = [
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#78909c',
  ];
  customPDFColor = null
  constructor() {
    this.shuffle(this.colors100);
    //chose random color from colors400
    this.customPDFColor = this.colors400[this.getRandomNumber(0, this.colors400.length - 1)];
  }
  makeid(length?) {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // const charactersLength = characters.length;
    // for (let i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    result = uniqid();
    return result;
  }
  createWordSearch(options) {
    let result;
    result = new WordSearch(options);

    return result;
  }
  flatenArray(array) {
    return arrayHelper.flatten(array);
  }
  enterFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  }
  replaceAllString(string, searchFor, replaceTo) {
    return replaceAll(string, searchFor, replaceTo);
  }

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  lightenColor(hex, amount) {
    // Convert the hex color code to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Increase the value of each RGB component
    r = Math.min(Math.floor(r + amount), 255);
    g = Math.min(Math.floor(g + amount), 255);
    b = Math.min(Math.floor(b + amount), 255);

    // Convert the updated RGB values back to a hex color code
    const newHex = ((r << 16) | (g << 8) | b).toString(16);
    return '#' + newHex.padStart(6, '0');
  }
  createCrossword(items) {
    return clg.generateLayout(items);
  }
  getRandomNumber(min, max) {
    let result;
    result = Math.random() * (max - min) + min;
    return Math.round(result);
  }
  replaceWordsWithPercentage(sentence, percentage) {
    // Split the sentence into an array of words
    let words = sentence.split(' ');

    // Calculate the number of words to replace
    let numWordsToReplace = Math.ceil((percentage / 100) * words.length);
    numWordsToReplace = 1;

    // Filter words that have at least four letters
    let filteredWords = words.filter((word) => word.length >= 4 && word !== '<br>');

    // Check if there are enough words with at least four letters for replacement
    const hasEnoughWords = filteredWords.length > 0;

    // Replace the selected word and other random words
    filteredWords = this.shuffle(filteredWords);
    const randomIndex = Math.floor(Math.random() * filteredWords.length);
    let mark = this.extractLastPunctuation(filteredWords[randomIndex]);
    if (!mark) {
      mark = '';
    }
    const replacedSentence = sentence.replace(
      filteredWords[randomIndex],
      '______________' + mark,
    );

    // Join the replaced words back into a sentence, preserving the punctuation marks

    return replacedSentence;
  }

  replaceRandomWords(input) {
    if (input.includes('<br>')) {
      input = this.clone(input)
      console.log("🚀 ~ HelpersService ~ replaceRandomWords ~ input:", input)
      return {
        modifiedText: input,
        replcedWords: null
      }


    }
    else {


      const words = input.split(/\s+/);
      const longWordsIndices = [];

      words.forEach((word, index) => {
        // Check if the word is capitalized
        const isCapitalized = word.charAt(0) === word.charAt(0).toUpperCase();

        // Only add the word to the list if it's not capitalized and its length is >= 5
        if (!isCapitalized && word.length >= 5) {
          longWordsIndices.push(index);
        }
      });

      if (longWordsIndices.length < 3) {
        return { modifiedText: input, replacedWords: '' };
      }

      const segmentLength = Math.floor(longWordsIndices.length / 3);
      const randomIndices = [
        longWordsIndices[Math.floor(Math.random() * segmentLength)],
        longWordsIndices[
        Math.floor(Math.random() * segmentLength) + segmentLength
        ],
        longWordsIndices[
        Math.floor(Math.random() * segmentLength) + 2 * segmentLength
        ],
      ];

      let replacedWords = [];
      randomIndices.forEach((index) => {
        let mark = this.extractLastPunctuation(words[index]);

        // Preserve accents and apostrophes in the word
        const word = words[index].replace(/[^a-zA-Z0-9áàâäãéèêëíìîïóòôöõúùûüñçßăĂĕĔğĞĭĬŏŎœŒřŘşŞțȚůŮűŰźŹżŻ']/g, '');
        replacedWords.push(word.toLowerCase());
        if (!mark) {
          mark = '';
        }
        words[index] = '_____________' + mark;
      });
      replacedWords = this.shuffle(replacedWords);
      return {
        modifiedText: words.join(' '),
        replacedWords: replacedWords.join(' ~ '),
      };
    }
  }
  extractLastPunctuation(str) {
    // Regular expression to match any punctuation at the end of a string
    const match = str.match(/[.,!?;:"'-]+$/);

    // Return the matched punctuation or null if none is found
    return match ? match[0] : null;
  }
  scramble(string) {
    return scramble(string).split(' ').join('');
  }
  removeTextBetweenParenths(string) {
    return string.replace(/ *\([^)]*\) */g, '');
  }
  checkIos() {
    const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
    return iOS;
  }
  getRandomItemFromArray(array, multiple?, numberOfItems?) {
    let result;
    if (multiple) {
      result = randomItem.multiple(array, numberOfItems);
    } else {
      result = randomItem(array);
    }

    return result;
  }
  removeAccentFromString(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  removeSpecialCharsAndSpaces(str) {
    return this.removeAccentFromString(str)
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  }
  replaceLettersWithUnderscore(str) {
    return str.replace(/[a-zA-Z]/g, '_');
  }
  replaceLettersWithDoubleUnderscore(str) {
    return str.replace(/[a-zA-Z]/g, '__');
  }
  convertGoogleDriveLink(link) {
    let id = link.replace('https://drive.google.com/file/d/', '');
    id = id
      .replace('/view?usp=share_link', '')
      .replace('/view?usp=sharing', '')
      .replace('/view?usp=drivesdk', '');

    const directLink =
      'https://drive.google.com/uc?id=' + id + '&export=download';
    console.log(
      '🚀 ~ file: helpers.service.ts ~ line 125 ~ HelpersService ~ convertGoogleDriveLink ~ directLink',
      directLink,
    );
    return directLink;
  }

  getPolishDayName() {
    const date = new Date();
    const dayIndex = date.getDay();
    const polishDays = [
      'Niedziela',
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
    ];

    return polishDays[dayIndex];
  }
  getUniqueRandomItemsFromArrayOfObjects(array, numberOfItems, skipAddingIds?) {
    // Log initial state
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ this.itemsInTasks:", this.itemsInTasks)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ array:", array)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ numberOfItems:", numberOfItems)

    // Check if we have enough items available
    const availableItems = array.filter(item => this.itemsInTasks.indexOf(item.id) === -1);

    // If we don't have enough unique items available, log warning
    if (availableItems.length < numberOfItems) {
      console.warn(`Not enough unique items available! Requested ${numberOfItems} but only ${availableItems.length} unique items remaining.`);
      // Either use all available unique items or return empty array based on requirements
      // For now, we'll continue with what we have
    }

    let result = [];
    let index = 0;
    const random = uniqueRandomArray(array);

    // Attempt to find unique items
    while (result.length < numberOfItems) {
      index++;

      const itemToPush: any = random();

      // Check if the item is unique (not in result) and not used before (not in itemsInTasks)
      if (result.findIndex(r => r.id === itemToPush.id) === -1 &&
        this.itemsInTasks.indexOf(itemToPush.id) === -1) {
        result.push(itemToPush);
      }

      // Safety exit to prevent infinite loops
      if (index > 500 || (availableItems.length < numberOfItems && result.length === availableItems.length)) {
        console.warn(`Breaking loop after ${index} iterations. Found ${result.length} unique items out of ${numberOfItems} requested.`);
        break;
      }
    }

    // Update tracking of used item IDs
    const addedIds = result.map((item) => item.id);
    const duplicates = result.filter((item) => this.itemsInTasks.indexOf(item.id) !== -1);

    if (!skipAddingIds && numberOfItems > 1) {
      this.itemsInTasks = this.itemsInTasks.concat(addedIds);
    }

    // Log final results
    console.log(`🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ RETURNED ${result.length} ITEMS`);
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ this.itemsInTasks:", this.itemsInTasks)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ result:", result)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ duplicates:", duplicates)

    return result;
  }
  getNumberedRandomItemsFromArrayOfObjects(array, numberOfItems, skipAddingIds?) {
    this.itemsInTasks
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ this.itemsInTasks:", this.itemsInTasks)

    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ array:", array)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ numberOfItems:", numberOfItems)
    let result = [];
    let temp = [];
    let index = 0;
    const random = uniqueRandomArray(array);
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ array:", array)
    while (result.length < numberOfItems) {
      index++

      const itemToPush: any = array[index - 1]; // Subtract 1 from index to get the correct item
      console.log("🚀 ~ HelpersService ~ getNumberedRandomItemsFromArrayOfObjects ~ itemToPush:", itemToPush)
      if (itemToPush) {
        if (result.indexOf(itemToPush) === -1 && this.itemsInTasks.indexOf(itemToPush?.id) == -1) {
          //if array is smaller than number of items we have a problem
          result.push(itemToPush);
        }
      }
      else {
        if (index > 500) {
          break;
        }
      }
    }
    const addedIds = result.map((item) => item.id);
    const duplicates = result.filter((item, index) => this.itemsInTasks.indexOf(item.id) !== -1);
    if (!skipAddingIds && numberOfItems > 1) {
      this.itemsInTasks = this.itemsInTasks.concat(addedIds);
    }
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ this.itemsInTasks:", this.itemsInTasks)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ result:", result)
    console.log("🚀 ~ HelpersService ~ getUniqueRandomItemsFromArrayOfObjects ~ duplicates:", duplicates)
    return result;
  }
  isEven(n) {
    return n % 2 == 0;
  }
  checkArrayDuplicates(array) {
    const duplicates = findDuplicates(array);
    if (duplicates.lenght > 0) {
      return true;
    } else {
      return false;
    }
  }
  chunk(arr, chunkSize) {
    if (chunkSize <= 0) {
      throw new Error('Invalid chunk size');
    }
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  disassembleObject(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object.split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }

  disassembleObjectForBook(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object.split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }

  pushToArray(array, element) {
    array.push(element);
    return array;
  }
  removeDuplicatesObj(arr) {
    return arr?.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i,
    );
  }
  arrayDifference(arrA, arrB) {
    return arrA
      .filter((x) => !arrB.includes(x))
      .concat(arrB.filter((x) => !arrA.includes(x)));
  }
  removeDuplicatesSingle(arr) {
    arr = arr.filter(
      (item, index) => !arr.slice(0, index).find((e) => e === item),
    );
    return arr;
  }
  convertFromStringToDate(responseDate) {
    let result;
    if (!isDate(responseDate)) {
      let iso = responseDate.includes('.000Z');
      responseDate = responseDate.replace('.000Z', '');
      let dateComponents = responseDate.split('T');
      let datePieces = dateComponents[0].split('-');
      let timePieces = dateComponents[1].split(':');
      // return { date: datePieces, time: timePieces };
      const dateToSend = new Date(
        datePieces[0],
        datePieces[1] - 1,
        datePieces[2],
        timePieces[0],
        timePieces[1],
        timePieces[2] ? timePieces[2] : null,
      );
      if (iso) {
        result = addHours(dateToSend, 2);
      }
      if (!iso) {
        result = dateToSend;
      }
    }
    if (isDate(responseDate)) {
      result = responseDate;
    }
    return result;
  }
  formatTime(timeString) {
    let [hour, minute] = timeString.split(':');
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    return `${hour}:${minute}`;
  }
  splitArrayByProperty(arr, key) {
    return arr
      .reduce((acc, cur) => {
        acc[cur[key]] = [...(acc[cur[key]] || []), cur];
        return acc;
      }, [])
      .filter(Boolean);
  }

  removeElementFromArray(array, element) {
    const numberToRemove = array.findIndex((e) => e == element);
    array.splice(numberToRemove, 1);
    return array;
  }
  replaceElementFromArrayObj(array, property, oldValue, newValue, equals) {
    if (equals) {
      const numberTReplace = array.findIndex((e) => e[property] == oldValue);
      array[numberTReplace] = newValue;
    } else {
      const numberTReplace = array.findIndex((e) => e[property] !== oldValue);
      array[numberTReplace] = newValue;
    }
    return array;
  }
  setColor100(colorNumber) {
    if (colorNumber < this.colors100.length) {
      return this.colors100[colorNumber];
    } else {
      colorNumber = 0;
      return this.colors100[colorNumber];
    }
  }
  getUniqueArrayOfObjectsById(array) {
    let result = [];
    result = array?.filter(
      (a, i) => array.findIndex((s) => a.id === s.id) === i,
    );
    // array.forEach((item) => {
    //   if (!result.some((_item) => item.id == item.id)) {
    //     result.push(item);
    //   }
    // });
    return result;
  }

  clone(item) {
    return clone(item);
  }

  getTextShadow(color) {
    let textShadow = '';
    for (let i = 10; i <= 25; i++) {
      textShadow += `0 0 ${i}px rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.7), `;
    }
    return textShadow.slice(0, -2); // remove the last comma and space
  }
  sortArrayByProperty(array, sortProperty, order) {
    sortProperty = sortProperty
    if (!Array.isArray(array) || array.length === 0) {
      return [];
    }

    // Creating a copy to avoid mutating the original array
    let result = [...array].sort((a, b) => {
      let valA = a[sortProperty]?.toString() || '';
      let valB = b[sortProperty]?.toString() || '';

      // Numeric comparison
      if (typeof valA === 'number' && typeof valB === 'number') {
        return order === 'asc' ? valA - valB : valB - valA;
      }

      // String comparison
      if (order === 'asc') {
        return valA.localeCompare(valB);
      } else {
        return valB.localeCompare(valA);
      }
    });

    return result;
  }


  sortArraySimple(array, order: 'asc' | 'desc') {
    let result = [];
    if (array?.length > 0) {
      result = sortArray(array, order);
    }

    return result;
  }

  compareArrays(array1, array2) {
    return compare(array1, array2);
  }
  pushPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property].push(value);
    return array;
  }
  unshiftPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property].unshift(value);
    return array;
  }
  setPropertyById(array, id, property: string, value) {
    const number = array.findIndex((e) => e.id === id);
    array[number][property] = value;
    return array;
  }
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  shuffleArrayOfObjects(array) {
    return arrayPower.shuffle(array);
  }
  getUniqueNumberedItemsFromArrayOfObjects(array, numberOfItems) {
    console.log("Starting getUniqueNumberedItemsFromArrayOfObjects");
    console.log("Array length:", array.length);
    console.log("numberOfItems requested:", numberOfItems);

    let result: any = [];

    // Handle case where array is smaller than numberOfItems
    if (array.length < numberOfItems) {
      console.warn(`Array has only ${array.length} items but ${numberOfItems} were requested. Will cycle through array.`);

      // Create a new array by repeating the original array until we have enough items
      let extendedArray = [];
      while (extendedArray.length < numberOfItems) {
        extendedArray = extendedArray.concat(array);
      }

      // Trim to exactly the number we need
      extendedArray = extendedArray.slice(0, numberOfItems);

      // Use the extended array for processing
      array = extendedArray;
    }

    // Now process the items (either original or extended array)
    for (let index = 0; index < numberOfItems; index++) {
      const itemToPush = array[index];
      console.log(`Processing item at index ${index}:`, itemToPush?.id || itemToPush);

      if (result.indexOf(itemToPush) === -1) {
        result.push(itemToPush);
      }
    }

    console.log("Final result length:", result.length);
    console.log("Final result items:", result.map(item => item?.id || item));
    return result;
  }
  removeDuplicatesObjbyProp(array, prop) {
    if (!array) return [];
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item[prop] == itemToCheck[prop])) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }

  getItemsForPack(itemsPacks, _allItems, numberOfItems, random?) {
    // implement random
    let allItems = this.removeDuplicatesObjbyId(_allItems);

    let usedWords = this.flatenArray(this.clone(itemsPacks));
    usedWords = this.removeDuplicatesObj(usedWords);
    let itemsToUse = allItems.filter(
      (item: any) => !usedWords.some((usedWord) => item?.id == usedWord?.id),
    );

    if (itemsToUse.length >= numberOfItems) {
      let result = this.getNumberedRandomItemsFromArrayOfObjects(
        itemsToUse,
        numberOfItems,
      );
      return result;
    } else {
      let result = this.getNumberedRandomItemsFromArrayOfObjects(
        itemsToUse,
        itemsToUse.length,
      );
      return result;
    }
  }

  removeDuplicatesObjbyId(array) {
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item.id == itemToCheck.id)) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }

  getUniqueRandomItemsFromArray(array, numberOfItems) {
    let result = [];
    let temp = [];
    const random = uniqueRandomArray(array);
    for (let index = 0; index < numberOfItems; index++) {
      result.push(random());
    }
    return result;
  }

  hexToRgba(hex, alpha) {
    // Remove the '#' character if present
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1);
    }

    // Check if the hex code is shorthand (e.g., #abc)
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    // Parse the hex code to RGB values
    var bigint = parseInt(hex, 16);
    var red = (bigint >> 16) & 255;
    var green = (bigint >> 8) & 255;
    var blue = bigint & 255;

    // Calculate the alpha value in the range of 0-1
    alpha = parseFloat(alpha);
    alpha = Math.min(Math.max(alpha, 0), 1);

    // Return the RGBA value
    return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')';
  }

  extractTextFromHTML(html) {
    // Remove style tags
    html = html.replace(/<style[^>]*>.*<\/style>/gm, '');

    // Remove inline styles
    html = html.replace(/ style="[^"]*"/gm, '');

    // Remove CSS styles like body{...}
    html = html.replace(/[^}{]+{[^}]*}/gm, '');

    let text = '';
    let tag = false;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<') {
        tag = true;
      } else if (html[i] === '>') {
        tag = false;
      } else if (!tag) {
        text += html[i];
      }
    }

    // Remove emojis
    text = text.replace(/[\u{1F600}-\u{1F64F}]/gu, ''); // Emoticons
    text = text.replace(/[\u{1F300}-\u{1F5FF}]/gu, ''); // Misc Symbols and Pictographs
    text = text.replace(/[\u{1F680}-\u{1F6FF}]/gu, ''); // Transport and Map
    text = text.replace(/[\u{1F1E0}-\u{1F1FF}]/gu, ''); // Flags (iOS)
    text = text.replace(/[\u{2600}-\u{26FF}]/gu, ''); // Misc symbols
    text = text.replace(/[\u{2700}-\u{27BF}]/gu, ''); // Dingbats
    text = text.replace(/[\u{1F900}-\u{1F9FF}]/gu, ''); // Supplemental Symbols and Pictographs
    text = text.replace(/[\u{1F600}-\u{1F64F}]/gu, ''); // Emoticons
    text = text.replace(/[\u{1F680}-\u{1F6FF}]/gu, ''); // Transport and Map Symbols
    text = text.replace(/[\u{1F1E0}-\u{1F1FF}]/gu, ''); // Country Flags
    text = text.replace(/[\u{1F300}-\u{1F5FF}]/gu, ''); // Miscellaneous Symbols and Pictographs
    text = text.replace(/[\u{1F900}-\u{1F9FF}]/gu, ''); // Supplemental Symbols and Pictographs

    console.log(
      '🚀 ~ file: helpers.service.ts:207 ~ HelpersService ~ extractTextFromHTML ~ text:',
      text,
    );
    return text;
  }

  createSentenceChunks(sentence, numberOfChunks) {
    const regex = /\b(?:A:|B:)?\w+['-]?\w*(?:[.,;!?])?/g;
    const words = sentence.match(regex); //qer

    // Calculate the size of each chunk
    const chunkSize = Math.ceil(words.length / numberOfChunks);
    let chunks = [];

    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, Math.min(i + chunkSize, words.length)).join(' '));
    }

    // Adjust the last chunk if it's too small
    if (chunks.length > numberOfChunks) {
      let lastChunk = chunks.pop().split(' ');
      while (chunks.length < numberOfChunks) {
        chunks[chunks.length - 1] += ' ' + lastChunk.shift();
      }
    }
    chunks = this.shuffle(chunks);
    return chunks;
  }

  /**
   * Sprawdza, czy lekcja jest widoczna (nie jest zarchiwizowana)
   * @param lesson Obiekt lekcji lub nazwa lekcji
   * @returns True jeśli lekcja jest widoczna, false jeśli zarchiwizowana
   */
  isLessonVisible(lesson) {
    const lessonName = typeof lesson === 'string' ? lesson : lesson.name;
    return !lessonName.includes('archived');
  }

  /**
   * Wyciąga nazwę modułu z nazwy lekcji
   * Moduły są identyfikowane jako część nazwy lekcji przed ostatnią literą
   * Np. "Explorers 7B" -> "Explorers 7"
   * @param lessonName Pełna nazwa lekcji
   * @returns Nazwa modułu
   */
  extractModuleNameFromLesson(lessonName) {
    if (!lessonName) return '';

    // Usuń białe znaki z początku i końca
    const trimmed = lessonName.trim();

    // Najprostszy przypadek: nazwa kończy się literą poprzedzoną cyfrą lub spacją
    const moduleRegex = /^(.*?)[A-Za-z]$/;
    const match = trimmed.match(moduleRegex);

    if (match && match[1]) {
      // Usuń ewentualne spacje na końcu nazwy modułu
      return match[1].trim();
    }

    // Jeśli nie znaleziono wzorca, zwróć oryginalną nazwę
    return trimmed;
  }

  /**
   * @param {Array} lessons - Tablica wszystkich lekcji do przeszukania
   * @param {string} itemId - ID elementu do śledzenia
   * @param {string} itemType - Typ elementu (set, activity, homework)
   * @param {Object} options - Opcjonalne parametry
   * @param {boolean} options.includeFuture - Czy uwzględniać bieżące i przyszłe lekcje (domyślnie: false)
   * @param {string} options.currentLessonName - The name of the current lesson (required if includeFuture is false)
   * @returns {Object} Usage data with counts and module information
   */
  trackItemUsage(lessons, itemId, itemType, options: TrackItemUsageOptions = {}) {
    if (!lessons || !itemId) {
      return {
        totalCount: 0,
        currentModuleCount: 0,
        moduleCount: 0,
        lastModule: null,
        moduleNames: [],
        currentModuleName: ''
      };
    }

    const includeFuture = options.includeFuture || false;
    const currentLessonName = options.currentLessonName;

    // Filtruj tylko widoczne lekcje
    const visibleLessons = lessons.filter(lesson => this.isLessonVisible(lesson));

    let totalCount = 0;
    let currentModuleCount = 0;
    let moduleCount = 0;
    let lastModule = null;
    const moduleNames = [];
    let currentModuleName = '';
    let pastLessons = [...visibleLessons];

    // Określenie nazwy bieżącego modułu
    if (currentLessonName) {
      currentModuleName = this.extractModuleNameFromLesson(currentLessonName);
    }

    // Jeśli uwzględniamy tylko poprzednie lekcje, znajdź bieżącą lekcję i przytnij tablicę
    if (!includeFuture && currentLessonName) {
      const currentIndex = visibleLessons.findIndex(lesson => lesson.name === currentLessonName);
      if (currentIndex !== -1) {
        // Pobierz tylko lekcje przed bieżącą (poprzednie lekcje)
        pastLessons = visibleLessons.slice(0, currentIndex);
      }
    }

    // Sprawdź czy element jest używany w bieżącym module
    if (currentLessonName && currentModuleName) {
      visibleLessons.forEach(lesson => {
        // Pomijaj lekcje z przyszłości, jeśli includeFuture jest false
        if (!includeFuture) {
          const lessonIndex = visibleLessons.findIndex(l => l.name === lesson.name);
          const currentLessonIndex = visibleLessons.findIndex(l => l.name === currentLessonName);
          if (lessonIndex >= currentLessonIndex) {
            return; // Pomijaj przyszłe lekcje
          }
        }

        // Sprawdź czy lekcja należy do tego samego modułu co bieżąca lekcja
        const lessonModuleName = this.extractModuleNameFromLesson(lesson.name);
        if (lessonModuleName === currentModuleName) {
          let found = 0;

          // Sprawdź element na podstawie jego typu
          if (itemType === 'set' && lesson.sets) {
            found = lesson.sets.filter(set => set.id === itemId).length;
          } else if (itemType === 'activity' && lesson.exercises) {
            found = lesson.exercises.filter(exercise => exercise.id === itemId).length;
          } else if (itemType === 'homework' && lesson.homework) {
            found = lesson.homework.filter(hw => hw.id === itemId).length;
          }

          moduleCount += found;
        }
      });
    }

    // Sprawdź czy element jest używany w bieżącej lekcji
    if (currentLessonName) {
      const currentLesson = visibleLessons.find(lesson => lesson.name === currentLessonName);
      if (currentLesson) {
        if (itemType === 'set' && currentLesson.sets) {
          currentModuleCount = currentLesson.sets.filter(set => set.id === itemId).length;
        } else if (itemType === 'activity' && currentLesson.exercises) {
          currentModuleCount = currentLesson.exercises.filter(exercise => exercise.id === itemId).length;
        } else if (itemType === 'homework' && currentLesson.homework) {
          currentModuleCount = currentLesson.homework.filter(hw => hw.id === itemId).length;
        }
      }
    }

    // Przejrzyj wszystkie istotne lekcje, aby znaleźć użycie
    pastLessons.forEach(lesson => {
      let found = false;

      // Sprawdź element na podstawie jego typu
      if (itemType === 'set' && lesson.sets) {
        const matches = lesson.sets.filter(set => set.id === itemId);
        found = matches.length > 0;
        totalCount += matches.length;
      } else if (itemType === 'activity' && lesson.exercises) {
        const matches = lesson.exercises.filter(exercise => exercise.id === itemId);
        found = matches.length > 0;
        totalCount += matches.length;
      } else if (itemType === 'homework' && lesson.homework) {
        const matches = lesson.homework.filter(hw => hw.id === itemId);
        found = matches.length > 0;
        totalCount += matches.length;
      }

      if (found) {
        lastModule = lesson.name;
        moduleNames.push(lesson.name);
      }
    });

    return {
      totalCount,
      currentModuleCount,
      moduleCount,
      lastModule,
      moduleNames,
      currentModuleName
    };
  }

  handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
