import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SetsComponent } from 'src/app/database/sets/sets.component';
import { TeacherActivitiesComponent } from 'src/app/profile/teacher/teacher-activities/teacher-activities.component';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { DigitalOceanService } from 'src/app/shared/services/digitalocean.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CoursePlanService } from '../../course-add-edit/course-plan/course-plan.service';
import { CoursePlanItem } from './course-plan-item';
import { CoursesPlanItemPropsService } from './courses-plan-item-props.service';
import { LessonDetailsDialogComponent } from '../lesson-details-dialog/lesson-details-dialog.component';
import { FolderService } from '../folder.service';

@Component({
  selector: 'app-courses-plan-item',
  templateUrl: './courses-plan-item.component.html',
  styleUrls: ['./courses-plan-item.component.scss']
})
export class CoursesPlanItemComponent implements OnInit {
  @Input() folder
  @Input() item
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @Output() createFolders = new EventEmitter()
  @Input() editMode = false
  @Input() addMode = false;
  @Input() folderOpened = false;
  @Input() courseResources = [];
  approved = false;
  homeworks = []
  ages = []
  skills = []
  folded = true
  itemSelected = false
  addingElement: boolean;
  allHomeworks: any;
  homeworksFrequency: { [key: string]: number } = {};
  setsFrequency: { [key: string]: number } = {};
  activitiesFrequency: { [key: string]: number } = {};
  recentHomeworks: Set<string> = new Set();
  recentSets: Set<string> = new Set();
  recentActivities: Set<string> = new Set();
  constructor(
    private bottomSheet: MatBottomSheet,
    private coursesService: CoursesService,
    private coursesPlanItemPropsService: CoursesPlanItemPropsService,
    private helpers: HelpersService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private coursePlanService: CoursePlanService,
    private digitalOceanService: DigitalOceanService,
    private folderService: FolderService
  ) { }

  ngOnInit(): void {

    this.ages = this.coursesPlanItemPropsService.ages
    this.skills = this.coursesPlanItemPropsService.skills
    this.homeworks = this.coursesPlanItemPropsService.homeworks
    this.allHomeworks = this.helpers.clone(this.homeworks)
    this.homeworks = []

    // Initialize approved status from item
    this.approved = this.item?.approved || false;

    this.trackFrequencies()
  }

  toggleApproved() {
    this.approved = !this.approved;
    if (this.item) {
      this.item.approved = this.approved;
      this.editFinished(this.item);
    }
  }

  trackFrequencies() {
    if (!this.folder?.items || !this.item?.name) {
      return;
    }

    const moduleNumber = this.extractModuleNumber(this.item.name);
    if (!moduleNumber) {
      return;
    }

    // Get all lessons from the same module
    const moduleLessons = this.folder.items.filter(lesson =>
      this.extractModuleNumber(lesson.name) === moduleNumber
    );

    // Reset frequencies and recent items
    this.homeworksFrequency = {};
    this.setsFrequency = {};
    this.activitiesFrequency = {};
    this.recentHomeworks.clear();
    this.recentSets.clear();
    this.recentActivities.clear();

    // Find current lesson index
    const currentLessonIndex = this.folder.items.findIndex(lesson => lesson.name === this.item.name);
    if (currentLessonIndex === -1) return;

    // Get previous 3 lessons
    const previousLessons = this.folder.items
      .slice(Math.max(0, currentLessonIndex - 3), currentLessonIndex)
      .reverse();

    // Track items in previous 3 lessons
    previousLessons.forEach(lesson => {
      lesson.homework?.forEach(homework => {
        this.recentHomeworks.add(homework.displayName);
      });
      lesson.sets?.forEach(set => {
        this.recentSets.add(set.name);
      });
      lesson.activities?.forEach(activity => {
        this.recentActivities.add(activity.name);
      });
    });

    // Track frequencies for items in the same module
    moduleLessons.forEach(lesson => {
      lesson.homework?.forEach(homework => {
        this.homeworksFrequency[homework.displayName] = (this.homeworksFrequency[homework.displayName] || 0) + 1;
      });
      lesson.sets?.forEach(set => {
        this.setsFrequency[set.name] = (this.setsFrequency[set.name] || 0) + 1;
      });
      lesson.activities?.forEach(activity => {
        this.activitiesFrequency[activity.name] = (this.activitiesFrequency[activity.name] || 0) + 1;
      });
    });
  }

  extractModuleNumber(lessonName: string): number {
    const match = lessonName?.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  getChipClass(type: 'homework' | 'set' | 'activity', name: string): string {
    if (name.includes('archived')) {
      return '';
    }

    // Check if the item was used in recent lessons
    // const isRecent = type === 'homework'
    //   ? this.recentHomeworks.has(name)
    //   : type === 'set'
    //     ? this.recentSets.has(name)
    //     : this.recentActivities.has(name);

    // if (isRecent) return 'border-recent';

    // Check frequency in the current module
    const frequency = type === 'homework'
      ? this.homeworksFrequency[name]
      : type === 'set'
        ? this.setsFrequency[name]
        : this.activitiesFrequency[name];

    if (frequency > 2) return 'border-more';
    if (frequency === 2) return 'border-twice';
    return 'border-once';
  }
  copyPlanItem(item) {
    localStorage.setItem('copiedPlanItem', JSON.stringify(item))
  }
  pastePlanItem() {
    const item = JSON.parse(localStorage.getItem('copiedPlanItem'))
    if (item) {
      if (confirm('Czy na pewno wkleić element? Dane zostaną zastąpione')) {


        this.item.homework = item.homework
        this.item.skills = item.skills
        this.item.description = item.description
        if (confirm('Czy zastąpić wszystkie aktywności?')) {
          this.item.activities = item.activities
          this.ref.detectChanges()

        }
        else {
          this.item.activities.push(item.activities[item.activities.length - 1])
          this.item.activities.push(item.activities[item.activities.length - 2])
        }
      }
      if (confirm('Czy zastąpić wszystkie zestawy?')) {
        this.item.sets = item.sets
      }
    }
    else {
      alert('Brak skopiowanego elementu')
      return
    }
    this.editFinished(this.item)
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes.item.currentValue.name) {
      setTimeout(() => {

        this.checkItemsHomework()
      }, 500)
    }

  }
  changeItemsSelection(item) {
    this.coursePlanService.passItemSelected({ item: item, selected: !this.itemSelected })
    this.itemSelected = !this.itemSelected

  }

  removeTag(item, tag) {
    item.tags = item.tags.filter(_tag => tag.tag !== _tag.tag)
  }
  addRemoveAge(item, age) {
    if (!item.ages.includes(age)) {
      item.ages.push(age)
    }
    else {
      item.ages = this.helpers.removeElementFromArray(item.ages, item)
    }

    this.ref.detectChanges()
  }
  addRemoveHomework(item, homework) {
    if (homework?.type == 'freeset') {
      homework.type = 'free'
    }
    if (homework?.type == 'sentences') {
      homework.type = 'exercise'
    }
    if (!item?.homework.some(_homework => _homework.displayName === homework.displayName)) {
      item.homework.push(homework)
      const selected = this.homeworks.findIndex(_homework => _homework.displayName === homework.displayName)
      this.homeworks[selected].selected = true
    }
    else {
      item.homework = this.helpers.removeElementFromArray(item.homework, item)
      const selected = this.homeworks.findIndex(_homework => _homework.displayName === homework.displayName)
      this.homeworks[selected].selected = false
    }



  }
  checkHomework() {
    this.item.homework.forEach(homework => {
      const selected = this.homeworks.findIndex(_homework => _homework.displayName === homework.displayName)
      if (selected !== -1) {
        this.homeworks[selected].selected = true;
      }
    })
  }
  addSkill(skill) {
    if (!this.item.skills.includes(skill)) {
      this.item.skills.push(skill)
    }
    else {
      this.item.skills = this.helpers.removeElementFromArray(this.item.skills, skill)
    }
  }

  edit() {
    this.editMode = true;
  }
  removeFile(item, file) {
    if (confirm('Czy na pewno usunąć plik? ' + file.name)) {
      item.files = item.files.filter(_file => _file !== file)
    }
  }
  addFile() {
    if (!this.item.files) {
      this.item.files = []
    }
    this.item.files.push({ name: '', description: '', url: '' })
  }
  openAddSetsList(item) {
    let _class = "end-wide";
    if (window.innerWidth < 800) {
      _class = "end";
    }
    const ref = this.bottomSheet.open(SetsComponent, {
      data: {
        group: { name: item.name },
        source: { type: "lessonPlanning", forStudents: false },
      },
      panelClass: _class,
    });
    ref.afterDismissed().subscribe((result) => {


      result.forEach((_item) => {
        if (this.item.sets.length == 0) {


          //   if (_item?.set.children || _item?.set.type == 'children') {
          //     this.homeworks = this.allHomeworks.filter(element => element.type == 'children')
          //   }
          //   if (_item?.set.type == 'main' || _item?.set.type == 'exercise') {
          //     this.homeworks = this.allHomeworks.filter(element => element.type == 'sentences')
          //   }
          //   if (_item?.set.type == 'free') {
          //     this.homeworks = this.allHomeworks.filter(element => element.type == 'freeset')
          //   }
        }
        this.homeworks = this.allHomeworks
        if (!item.sets.some(set => set.id === _item.set.id)) {
          item.sets.push({
            id: _item.set.id, name: _item.set.name, items: _item.set.items.length, type: _item.set.children ? 'children' : _item.set.type
          })
        }

      })
    })
  }
  checkItemsHomework() {
    // if (this.item.sets.length > 0) {


    //   if (this.item?.sets[0]?.children || this.item.sets[0]?.type == 'children') {
    //     this.homeworks = this.allHomeworks.filter(element => element.type == 'children')
    //   }
    //   if (this.item?.sets[0]?.type == 'main' || this.item.sets[0].type == 'exercise') {
    //     this.homeworks = this.allHomeworks.filter(element => element.type == 'sentences')
    //   }
    //   if (this.item?.sets[0]?.type == 'free') {
    //     this.homeworks = this.allHomeworks.filter(element => element.type == 'freeset')
    //   }
    // }
    this.homeworks = this.allHomeworks
    this.checkHomework()


  }
  openAddActivityPanel(item) {
    const dialogRef = this.dialog.open(TeacherActivitiesComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      panelClass: "full-screen-dialog",
      data: {
        addToLessonMode: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      result.forEach((activity) => {
        if (!item.activities.some(_activity => activity.id === _activity.id)) {
          item.activities.push({ name: activity.name, id: activity.id })
        }

      })
    })
  }
  removeSet(item, set) {
    item.sets = item.sets.filter(_set => _set !== set)
  }
  removeActivity(item, set) {

    item.activities = item.activities.filter(activity => activity !== set)

  }

  editFinished(item) {
    item.new = false;

    // Update the item without triggering unnecessary refreshes
    this.coursesService.updatePlanItem(item.id, item).subscribe({
      next: () => {
        this.editMode = false;
        // Only emit createFolders if this is a new item being saved for the first time
        if (item.id === 'temp') {
          this.createFolders.emit(null);
        }
      }
    });
  }
  delete(item) {
    if (confirm('Czy na pewno usunąć element ' + item.name + '?')) {
      this.editMode = false

      // Get the current folder name before deleting the item
      const folderName = this.folder ? this.folder.name : null;

      this.coursesService.removePlanItem(item.id)

      // Use the folder service to refresh the folders
      setTimeout(() => {
        // First refresh the folders
        this.folderService.refreshFolders();

        // Then force update the modal if it's open
        setTimeout(() => {
          // Get the current modal reference
          const modalRef = this.folderService.getCurrentModal();
          if (modalRef && folderName) {
            // Get the updated folders
            this.folderService.getFolders().subscribe(folders => {
              // Find the updated folder
              const updatedFolder = folders.find(f => f.name === folderName);
              if (updatedFolder) {
                // Update the modal data
                this.folderService.updateModalData(updatedFolder);
              }
            });
          }
        }, 500);
      }, 300);
    }
  }
  duplicateItem(item) {
    let itemToPush = this.helpers.clone(item)
    itemToPush.new = true
    itemToPush.name = item.name + ' - kopia'
    itemToPush.id = 'temp'

    // Get the current folder name before duplicating the item
    const folderName = this.folder ? this.folder.name : null;

    this.coursesService.saveNewCoursePlanItem(itemToPush, true)

    // Use the folder service to refresh the folders
    setTimeout(() => {
      // First refresh the folders
      this.folderService.refreshFolders();

      // Then force update the modal if it's open
      setTimeout(() => {
        // Get the current modal reference
        const modalRef = this.folderService.getCurrentModal();
        if (modalRef && folderName) {
          // Get the updated folders
          this.folderService.getFolders().subscribe(folders => {
            // Find the updated folder
            const updatedFolder = folders.find(f => f.name === folderName);
            if (updatedFolder) {
              // Update the modal data
              this.folderService.updateModalData(updatedFolder);
            }
          });
        }
      }, 500);
    }, 300);
  }

  archiveItem(item) {
    if (!item.name.endsWith('-archived')) {
      item.name += ' -archived';
    }
    if (!item.tags.some(tag => tag.tag === 'archived')) {
      this.addTag({ value: 'archived', input: null }, item);
    }

    // Get the current folder name before archiving the item
    const folderName = this.folder ? this.folder.name : null;

    this.editFinished(item);

    // Use the folder service to refresh the folders
    setTimeout(() => {
      // First refresh the folders
      this.folderService.refreshFolders();

      // Then force update the modal if it's open
      setTimeout(() => {
        // Get the current modal reference
        const modalRef = this.folderService.getCurrentModal();
        if (modalRef && folderName) {
          // Get the updated folders
          this.folderService.getFolders().subscribe(folders => {
            // Find the updated folder
            const updatedFolder = folders.find(f => f.name === folderName);
            if (updatedFolder) {
              // Update the modal data
              this.folderService.updateModalData(updatedFolder);
            }
          });
        }
      }, 500);
    }, 300);
  }
  addTag(event: MatChipInputEvent, item): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      item.tags.push({ tag: value });
    }

    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }
  }

  openLessonDetailsDialog() {
    // Extract the prefix and module number from the current lesson name
    const moduleMatch = this.item.name.match(/(.*?)(\d+)/);
    if (!moduleMatch) {
      console.error('Could not extract module information from lesson name');
      return;
    }

    const modulePrefix = moduleMatch[1].trim(); // Get the prefix before the number

    // Fetch all lessons by prefix
    const allLessons = this.getLessonsByPrefix(modulePrefix);

    console.log('Opening lesson details dialog with resources:', this.courseResources);

    // Open dialog with all lessons data and current lesson details
    this.dialog.open(LessonDetailsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog',
      data: {
        lessons: allLessons,
        currentLesson: this.item, // Pass the current lesson details
        courseResources: this.courseResources || [] // Ensure we always pass an array
      }
    });
  }
  getLessonsByPrefix(prefix: string): any[] {


    const matchingFolder = this.folder.items;
    if (matchingFolder) {
      const currentLessonIndex = matchingFolder.findIndex(lesson => lesson.name === this.item.name);
      return matchingFolder
        // turned off only older lessons for now
        // .filter((lesson, index) => index > currentLessonIndex) // Only include lessons older than the current lesson
        .map(lesson => ({
          name: lesson.name,
          sets: lesson.sets || [],
          homework: lesson.homework || [],
          exercises: lesson.activities || [],
          files: lesson.files || []
        }));
    }
    return [];
  }

  ngOnDestroy(): void {

  }

  /**
   * Opens a file URL in a new tab, ensuring a fresh pre-signed URL is used if it's from Digital Ocean
   * @param fileUrl The file URL to open
   */
  openFileWithFreshUrl(fileUrl: string) {
    // Validate URL format first
    if (!fileUrl) {
      console.error('Invalid URL provided');
      return;
    }

    try {
      // Test if it's a valid URL by creating a URL object
      new URL(fileUrl);

      // Check if this is a DigitalOcean Spaces URL
      if (fileUrl.includes('digitaloceanspaces.com')) {
        // Get a fresh download link for Digital Ocean URLs
        this.digitalOceanService.getFreshDownloadLinkFromUrl(fileUrl)
          .subscribe(
            response => {
              if (response.success) {
                // Open the fresh URL in a new tab
                window.open(response.downloadLink, '_blank');
              } else {
                console.error('Error getting fresh download link:', response);
                // Fallback to the original URL if we can't get a fresh one
                window.open(fileUrl, '_blank');
              }
            },
            error => {
              console.error('Error getting fresh download link:', error);
              // Fallback to the original URL if there's an error
              window.open(fileUrl, '_blank');
            }
          );
      } else {
        // If it's not a DigitalOcean URL, just open it directly
        window.open(fileUrl, '_blank');
      }
    } catch (e) {
      // If the URL is invalid, show an error
      console.error('Invalid URL format:', fileUrl);
      alert('The file URL is invalid or malformed.');
    }
  }
}