import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionService } from './version.service';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    url = ''
    taskPanelOpened = false;
    usersTasks = 0
    usersComments = 0
    tasksListsUpdated = new Subject<any>();
    foundTasksUpdated = new Subject<any>();

    constructor(
        private _snackBar: MatSnackBar,
        private http: HttpClient,
        private versionService: VersionService,
        private authService: AuthenticationService,
        private userDetailsService: UserDetailsService


    ) {
        this.url = this.versionService.url
        setInterval(() => {
            this.checkTasksForUser(this.userDetailsService.getSimpleUserDetails().id)
        }, 60000)
    }


    addTask(userId, taskToAdd) {
        delete taskToAdd?.status;
        delete taskToAdd?.dontDisplay;
        delete taskToAdd?.recipient;
        taskToAdd.recipients = [userId];
        taskToAdd.lastModified = Date.now();
        if (!taskToAdd?.recipients.some((recipient) => recipient === this.userDetailsService.getSimpleUserDetails().id)) {
            taskToAdd.recipients.push(this.userDetailsService.getSimpleUserDetails().id)
        }
        this.http
            .post(
                this.url + '/api/tasks/add-task/',
                { taskToAdd: taskToAdd },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );
            });
    }



    getTasksForUser(userId) {
        if (!this.taskPanelOpened) {



            this.http
                .get(this.url + '/api/tasks/get-tasks-for-user/' + userId, {
                    headers: { Authorization: `Bearer ${this.authService.getToken()}` },
                })
                .subscribe((response) => {
                    this.tasksListsUpdated.next(response);
                });
        }
    }
    setTasksCompletion(taskId, completion) {
        this.http.put(this.url + '/api/tasks/set-tasks-completion/' + taskId, { completion: completion }, {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }).subscribe((response) => {
            console.log(
                '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                response,
            );
            this.openSnackBarInfo('Zapisano zmiany')
        }
        )
    }
    setTasksSeenBy(taskId, seenBy) {
        this.http.put(this.url + '/api/tasks/set-tasks-seen-by/' + taskId, { seenBy: seenBy }, {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }).subscribe((response) => {
            console.log(
                '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                response,
            );
        }
        )
    }
    getSingleTask(taskId) {
        this.http.get(this.url + '/api/tasks/get-single-task/' + taskId, {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }).subscribe((response) => {
            console.log(
                '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                response,
            );
        }
        )
    }
    setTasksCommentsSeenBy(taskId, commentId, seenBy) {
        this.http.put(this.url + '/api/tasks/set-tasks-comments-seen-by/' + taskId, { commentId: commentId, seenBy: seenBy }, {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }).subscribe((response) => {
            console.log(
                '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                response,
            );
        }
        )
    }
    setTasksAllCommentsSeenBy(taskId, seenBy) {
        this.http.put(this.url + '/api/tasks/set-tasks-all-comments-seen-by/' + taskId, { seenBy: seenBy }, {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        }).subscribe((response) => {
            console.log(
                '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                response,
            );
        }
        )
    }
    deleteTask(taskId) {
        this.http
            .delete(this.url + '/api/tasks/delete-task/' + taskId, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` },
            })
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );
            });
    }
    checkTasksForUser(userId) {
        this.http
            .get(this.url + '/api/tasks/check-tasks-for-user/' + userId, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` },
            })
            .subscribe((response: any) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );
                if (response.newTasks > this.usersTasks || response.newComments > this.usersComments) {
                    this.usersTasks = response.newTasks
                    this.usersComments = response.newComments
                    this.openSnackBar(response.newTasks, response.newComments)
                }
            });
    }
    openSnackBar(
        newTasks, newComments
    ) {

        if (newTasks !== 0 && newComments !== 0) {
            this._snackBar.open('Hej, masz coś w zadaniach!', null, { duration: 5000, panelClass: 'tasks-indication-snackbar', verticalPosition: 'top', horizontalPosition: 'right' });

        }
    }
    openSnackBarInfo(
        message
    ) {
        this._snackBar.open(message, null, { duration: 3000, panelClass: 'info-snackbar', verticalPosition: 'top', horizontalPosition: 'right' });
    }


    searchForTasks(text: string, startDate: number, endDate: number, userId: string) {

        if (text !== '') {

            this.http.post(this.url + '/api/tasks/search-for-tasks/', { text: text, startDate: startDate, endDate: endDate, userId: userId }, {})
                .subscribe((response: any) => {
                    console.log(
                        '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                        response,
                    );
                    if (response.tasks.length == 0) {
                        this.openSnackBarInfo('Nie znaleziono zadań')
                    }
                    this.foundTasksUpdated.next(response.tasks);
                });
        }
    }

    addComment(taskId, comment) {

        this.http
            .post(
                this.url + '/api/tasks/add-comment/' + taskId,
                { comment: comment },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );

            });
    }
    updateCommentsCompletion(taskId, commentId, completion) {

        this.http
            .put(
                this.url + '/api/tasks/update-comments-completion/' + taskId,
                { commentsDate: commentId, completion: completion },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );

            });
    }
    updateCommentsDescription(taskId, commentId, description) {

        this.http
            .put(
                this.url + '/api/tasks/update-comments-description/' + taskId,
                { commentsDate: commentId, description: description },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );

            });
    }


    rescheduleTask(taskId, dateScheduled) {
        this.http
            .put(
                this.url + '/api/tasks/reschedule-task/' + taskId,
                { dateScheduled: dateScheduled },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                console.log(
                    '🚀 ~ file: users-service.service.ts ~ line 59 ~ UsersServiceService ~ .subscribe ~ response',
                    response,
                );
                this.openSnackBarInfo('Zadanie zostało przeniesione na inny dzień')
            });
    }

    tasksListsListener() {
        return this.tasksListsUpdated.asObservable();
    }
    foundTasksListener() {
        return this.foundTasksUpdated.asObservable();
    }
}
