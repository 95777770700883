import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HelpersService } from './helpers.service';
var stringSimilarity = require('string-similarity');
import io from 'socket.io-client';
import * as homophones from '../../../assets/const/homophones.json';

import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { ErrorLoggingService } from './error-logging.service';
import { VersionService } from './version.service';
// var recognizeMic = require('watson-speech/speech-to-text/recognize-microphone');
// import * as recognizeMic from 'watson-speech/speech-to-text/recognize-microphone'
const DOWNSAMPLING_WORKER = '/assets/downsampling_worker.js';
interface IWindow extends Window {
  webkitSpeechRecognition: any;
}
@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  url;
  rec;
  onaudioprocess: any;
  browserRecognitionSupported = false;
  listening = false;
  understood = false;
  recognitionData = new Subject();
  recognitionPending = false;
  token = { accessToken: '', url: '' };
  expectedInput = null;
  possibilities = null;
  homophones = [];
  constructor(
    private helper: HelpersService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private logger: ErrorLoggingService,
    private versionService: VersionService,
  ) {
    const { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    if (webkitSpeechRecognition) {
      // speech recognition API supported
      this.browserRecognitionSupported = true;
      // this.browserRecognitionSupported = false
    } else {
      // speech recognition API not supported
      this.browserRecognitionSupported = false;
    }

    this.url = this.versionService.url;

    this.homophones = JSON.parse(JSON.stringify(homophones)).default;

  }

  startRecognition(expectedInput, possibilities, lang?, difficulty?) {
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:69 ~ SpeechRecognitionService ~ startRecognition ~ difficulty:',
      difficulty,
    );

    if (this.recognitionPending === false) {
      // if (!this.browserRecognitionSupported) {
      //   this.recognitionPending = true;
      //   this.expectedInput = expectedInput;
      //   this.possibilities = possibilities;
      //   this.getWatsonSSTToken();
      // } else {
      this.recognitionPending = true;
      this.startBrowserRecognition(
        expectedInput,
        possibilities,
        lang,
        difficulty,
      );
      // }
    } else {
      this.stopRecognition();
    }
  }
  getWatsonSSTToken() {
    this.http
      .get(this.url + '/api/read/getwatsonssttoken', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let res = Object.keys(response).map((i) => response[i]);

        let token = { accessToken: '', url: '' };
        token.accessToken = res[0];
        token.url = res[1];

        this.token = token;
        this.recognitionData.next({ listening: true });
        this.startRecording();
      });
  }

  startRecording = () => {
    const stream = null;
    // const stream = recognizeMic(
    //   Object.assign(this.token, {
    //     objectMode: true,
    //     maxAlternatives: 10,
    //   })
    // );
    stream.on('data', (message) => {

      stream.stop();

      const recognitionOutput = message;
      if (recognitionOutput.results[0].final) {
        if (!this.expectedInput) {
          this.outputResult({
            recognizedText: recognitionOutput,
            recognitionType: 'noExpectedInput',
            recognitionMode: 'browser',
          });
        }
        if (this.expectedInput) {
          if (!this.possibilities) {
            this.checkSimilarity(this.expectedInput, recognitionOutput);
          } else {
            const outputArray = recognitionOutput.results[0].alternatives;
            // this.matchResultToPossibilities(outputArray, this.possibilities)
            this.checkSimilarity(this.expectedInput, recognitionOutput);
          }
        }
        this.stopRecording();
        this.recognitionData.next({ listening: false });
        this.recognitionPending = false;
      }
    });

    stream.on('error', function (err) {

    });
  };

  stopRecording() {
    this.recognitionData.next({ listening: false });
    this.recognitionPending = false;
    this.rec.abort();
  }

  startBrowserRecognition(expectedInput, possibilities, lang?, difficulty?) {
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:149 ~ SpeechRecognitionService ~ startBrowserRecognition ~ difficulty:',
      difficulty,
    );
    let language = null;
    switch (lang) {
      case 'english':
        language = 'en-US';
        break;
      case 'german':
        language = 'de-DE';
        break;
      case 'french':
        language = 'fr-FR';
        break;
      case 'spanish':
        language = 'es-ES';
        break;

      default:
        break;
    }
    var { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    var recognition = new webkitSpeechRecognition();
    this.rec = recognition;
    if (!lang) {
      recognition.lang = 'en-US';
    } else {
      recognition.lang = language;
    }
    recognition.interimResults = false;
    recognition.maxAlternatives = 10;
    recognition.start();

    recognition.onstart = (e) => {
      this.recognitionData.next({ listening: true });
    };
    recognition.onaudiostart = (e) => {

    };
    recognition.onaudiostart = (e) => {

    };
    recognition.onaudioend = (e) => {

    };
    recognition.onend = (e) => {
      //   
      //   this.recognitionData.next({ listening: false });
      //   this.recognitionData.next({ understood: this.understood });
      //   this.recognitionPending = false;
    };
    recognition.onerror = (e) => {

      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });

      recognition = null;
    };
    recognition.onnomatch = (e) => {

      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });
      recognition = null;
    };
    recognition.onsoundstart = (e) => {

    };
    recognition.onspeechend = (e) => {

    };
    recognition.onspeechstart = (e) => {

    };

    recognition.onresult = (e) => {
      let result = e.results;

      // if (!expectedInput) {
      //   this.outputResult({
      //     recognizedText: result[0][0].transcript,
      //     recognitionType: "noExpectedInput",
      //     recognitionMode: "browser",
      //   });
      // }
      if (expectedInput) {
        this.checkSimilarity(expectedInput, result, difficulty);
      }
    };
    this.recognitionPending = false;
  }
  checkSimilarity(expectedInput, result, difficulty?) {
    let similaritiesArray = [];

    let resultsArray: any = [];
    if (this.browserRecognitionSupported) {
      resultsArray = Array.from(result[0]);
    } else {
      resultsArray = Array.from(result.results[0].alternatives);
    }

    resultsArray.forEach((element) => {
      const similarity = stringSimilarity.compareTwoStrings(
        this.helper
          .removeSpecialCharsAndSpaces(
            expectedInput.replace('A: ', '').replace('B: ', ''),
          )
          .trim()
          .toLowerCase(),
        this.helper
          .removeSpecialCharsAndSpaces(
            element.transcript.replace('A:', '').replace('B:', ''),
          )
          .trim()
          .toLowerCase(),
      );
      similaritiesArray.push({
        word: element.transcript.toLowerCase().trim().replace('.', ''),
        similarity: similarity,
      });
    });

    similaritiesArray = this.helper.sortArrayByProperty(
      similaritiesArray,
      'similarity',
      'desc',
    );

    let selectedOption;
    selectedOption = similaritiesArray.find(
      (element) => element.word == expectedInput,
    );
    if (!selectedOption) {
      selectedOption = similaritiesArray[0];
    }
    const similarityToCorrectAnswer = stringSimilarity.compareTwoStrings(
      this.helper
        .removeSpecialCharsAndSpaces(
          expectedInput.replace('A: ', '').replace('B: ', ''),
        )
        .trim()
        .toLowerCase(),
      this.helper
        .removeSpecialCharsAndSpaces(
          selectedOption?.word.replace('A:', '').replace('B:', ''),
        )
        .trim()
        .toLowerCase(),
    );
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:278 ~ SpeechRecognitionService ~ checkSimilarity ~ difficulty:',
      difficulty,
    );
    let difficultyRate = 0;
    if (difficulty == 'easy') {
      difficultyRate = 0.6;
    } else if (difficulty == 'medium') {
      difficultyRate = 0.75;
    } else if (difficulty == 'hard') {
      difficultyRate = 0.95;
    }
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:283 ~ SpeechRecognitionService ~ checkSimilarity ~ difficultyRate:',
      difficultyRate,
    );
    if (
      similarityToCorrectAnswer > difficultyRate ||
      this.homophones.includes(selectedOption?.word)
    ) {
      this.outputResult({
        recognizedText: expectedInput,
        recognitionType: 'expectedInput',
        recognitionMode: 'browser',
      });
    } else {
      this.outputResult({
        recognizedText: selectedOption?.word,
        recognitionType: 'expectedInput',
        recognitionMode: 'browser',
      });
    }
  }

  matchResultToPossibilities(result, possibilities) {

    let similaritiesArray = [];
    if (this.browserRecognitionSupported) {
      result = Array.from(result[0]);
    }

    result.forEach((element) => {
      const similarity = stringSimilarity.findBestMatch(
        element.transcript,
        possibilities,
      );
      similaritiesArray.push(similarity);
    });
    similaritiesArray = similaritiesArray.map((e) => {
      return { word: e.bestMatch, rating: e.rating };
    });
    similaritiesArray = this.helper.sortArrayByProperty(
      similaritiesArray,
      'rating',
      'desc',
    );

    const chosenWord = similaritiesArray[0].word;
    this.outputResult({
      recognizedText: chosenWord.target,
      recognitionType: 'matchResultToPossibilities',
      recognitionMode: 'browser',
    });
  }

  selectBestOption() { }

  outputResult(result: object) {
    this.understood = true;
    this.recognitionData.next({ listening: false });
    this.recognitionData.next({ understood: this.understood });
    this.recognitionData.next({ result: result });
    this.recognitionPending = false;
  }

  passRecognitionData() {
    return this.recognitionData.asObservable();
  }

  stopRecognition() {
    this.recognitionPending = false;
    this.recognitionData.next({ listening: false });
    this.recognitionData.next({ understood: false });
    if (this.rec) {
      this.rec.abort();
    }

  }
}
