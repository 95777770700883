import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { Control } from "../../../shared/models/controls.model";
import { UsersServiceService } from "../../../shared/services/users-service.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  incomingTextFromMenu = "";
  displayMode = "list";
  controls: Control[] = [
    {
      text: "Dodaj nowego użytkownika",
      color: "primary",
      type: "button",
      mode: "stroked",
      icon: "person_add",
    },

  ];

  constructor(
    private usersService: UsersServiceService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void { }
  // eslint-disable-next-line
  buttonClicked(event) {
    switch (event) {
      case "Dodaj nowego użytkownika":
        this.onRegisterClicked();
        break;
      case "reset progress":
        this.onResetProgressClicked();
        break;

      default:
        break;
    }
  }

  incomingText(text) {
    this.incomingTextFromMenu = text;

    this.usersService.filterUsers(text);
  }
  onResetProgressClicked() {
    // this.usersService.resetWeeklyProgress()
  }
  onListClicked() {
    this.displayMode = "list";
  }
  onRegisterClicked() {
    this.displayMode = "register";
  }
}
