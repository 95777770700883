<div class="container-fluid">
  <!-- Pasek akcji -->
  <div
    class="action-bar"
    style="
      background-color: white;
      border-radius: 12px;
      margin-bottom: 24px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      border: 1px solid #f0f0f0;
      overflow: hidden;
    "
  >
    <div class="row g-0 align-items-center" style="padding: 20px">
      <div class="col-12 col-lg-4 mb-3 mb-lg-0">
        <h2
          class="mb-0"
          style="font-size: 1.5rem; font-weight: 600; color: #333"
        >
          Zasoby kursów
        </h2>
      </div>
      <div class="col-12 col-lg-8">
        <div class="d-flex flex-column flex-md-row align-items-center gap-3">
          <mat-form-field
            appearance="outline"
            class="flex-grow-1 mb-0 search-field"
            style="margin-top: 0; margin-bottom: 0"
          >
            <mat-label>Wyszukaj zasoby</mat-label>
            <input
              matInput
              [(ngModel)]="searchText"
              (keyup)="filterResources()"
              placeholder="Wpisz nazwę, opis, temat lub kompetencję"
            />
            <button
              *ngIf="searchText"
              matSuffix
              mat-icon-button
              aria-label="Wyczyść"
              (click)="searchText = ''; filterResources()"
              matTooltip="Wyczyść wyszukiwanie"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-icon
              *ngIf="!searchText"
              matSuffix
              style="color: rgba(0, 0, 0, 0.6)"
              >search</mat-icon
            >
          </mat-form-field>
          <div class="d-flex gap-3 align-items-center action-buttons">
            <button
              mat-raised-button
              color="accent"
              class="py-2 px-3"
              routerLink="curriculum"
              style="min-height: 44px; font-weight: 500; border-radius: 8px"
            >
              <mat-icon>school</mat-icon>
              <span class="ms-2">Programy nauczania</span>
            </button>
            <button
              mat-raised-button
              color="primary"
              class="py-2 px-3"
              (click)="openAddEditDialog()"
              style="min-height: 44px; font-weight: 500; border-radius: 8px"
            >
              <mat-icon>add</mat-icon>
              <span class="ms-2">Dodaj</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div *ngIf="filteredResources.length === 0" class="text-center p-5">
        <p>Brak zasobów do wyświetlenia</p>
      </div>

      <mat-card
        *ngFor="let resource of filteredResources"
        class="mb-3 resource-card compact"
        style="
          border-radius: 8px;
          border-left: 4px solid #3f51b5;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
        "
      >
        <div class="card-content-wrapper">
          <!-- Główna zawartość karty -->
          <div class="card-main-content w-100">
            <mat-card-header>
              <mat-card-title>{{ resource.name }}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <!-- Górna część - tagi i info -->
              <div class="resource-info-grid">
                <div class="resource-tags-container">
                  <div class="d-flex flex-wrap align-items-center">
                    <span *ngIf="resource.theme" class="resource-tag theme">
                      <mat-icon class="tag-icon">local_offer</mat-icon>
                      {{ resource.theme }}
                    </span>
                    <span
                      *ngIf="resource.difficultyLevel"
                      class="resource-tag difficulty"
                    >
                      <mat-icon class="tag-icon">signal_cellular_alt</mat-icon>
                      {{ resource.difficultyLevel }}
                    </span>
                    <span
                      *ngIf="resource.difficultyLevelCEFR"
                      class="resource-tag cefr"
                    >
                      <mat-icon class="tag-icon">school</mat-icon>
                      CEFR: {{ resource.difficultyLevelCEFR }}
                    </span>
                    <span
                      *ngIf="resource.competence"
                      class="resource-tag competence"
                    >
                      <mat-icon class="tag-icon">psychology</mat-icon>
                      {{ resource.competence }}
                    </span>
                    <span *ngIf="resource.ages" class="resource-tag ages">
                      <mat-icon class="tag-icon">people</mat-icon>
                      {{ resource.ages }}
                    </span>
                    <span
                      *ngIf="resource.isSchoolCurriculum"
                      class="resource-tag curriculum"
                      matTooltip="Zgodne z programem szkolnym"
                    >
                      <mat-icon class="tag-icon">school</mat-icon>
                      Program szkolny
                    </span>
                  </div>
                </div>

                <div class="resource-metadata">
                  <div
                    class="metadata-item"
                    *ngIf="resource.isSchoolCurriculum && resource.bookName"
                  >
                    <mat-icon class="metadata-icon">menu_book</mat-icon>
                    <span>{{ resource.bookName }}</span>
                  </div>
                  <div class="metadata-item" *ngIf="resource.added">
                    <mat-icon class="metadata-icon">event</mat-icon>
                    <span>{{ resource.added | date: "dd.MM.yyyy" }}</span>
                  </div>
                </div>
              </div>

              <!-- Opis -->
              <div class="description-container" *ngIf="resource.description">
                <p class="description-text">
                  {{ resource.description | slice: 0 : 200
                  }}{{ resource.description.length > 200 ? "..." : "" }}
                </p>
              </div>

              <!-- Sekcja zawartości z ustaloną wysokością i scrollowaniem -->
              <div
                class="resource-content-section"
                *ngIf="hasResourceItems(resource)"
              >
                <h3 class="content-header">Zawartość:</h3>
                <div class="content-grid">
                  <!-- Zestawy -->
                  <div
                    class="content-item-box"
                    *ngIf="resource.sets && resource.sets.length > 0"
                  >
                    <div class="content-item-header">
                      <span class="content-item-title">Zestawy</span>
                      <span class="content-item-count">{{
                        resource.sets.length
                      }}</span>
                    </div>
                    <div
                      class="content-item-details-container"
                      style="max-height: 120px; overflow-y: auto"
                    >
                      <div
                        class="content-item-details"
                        *ngFor="let set of resource.sets"
                      >
                        <mat-icon class="content-item-icon sets-icon"
                          >category</mat-icon
                        >
                        <span class="content-item-name">{{ set.name }}</span>
                        <button
                          mat-icon-button
                          color="warn"
                          class="delete-item-btn"
                          (click)="deleteSet(resource, set, $event)"
                          matTooltip="Usuń zestaw"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Ćwiczenia -->
                  <div
                    class="content-item-box"
                    *ngIf="
                      resource.activities && resource.activities.length > 0
                    "
                  >
                    <div class="content-item-header">
                      <span class="content-item-title">Ćwiczenia</span>
                      <span class="content-item-count">{{
                        resource.activities.length
                      }}</span>
                    </div>
                    <div
                      class="content-item-details-container"
                      style="max-height: 120px; overflow-y: auto"
                    >
                      <div
                        class="content-item-details"
                        *ngFor="let activity of resource.activities"
                      >
                        <mat-icon class="content-item-icon activities-icon"
                          >extension</mat-icon
                        >
                        <span class="content-item-name">{{
                          activity.name
                        }}</span>
                        <button
                          mat-icon-button
                          color="warn"
                          class="delete-item-btn"
                          (click)="deleteActivity(resource, activity, $event)"
                          matTooltip="Usuń ćwiczenie"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Zadania domowe -->
                  <div
                    class="content-item-box"
                    *ngIf="resource.homework && resource.homework.length > 0"
                  >
                    <div class="content-item-header">
                      <span class="content-item-title">Zadania domowe</span>
                      <span class="content-item-count">{{
                        resource.homework.length
                      }}</span>
                    </div>
                    <div
                      class="content-item-details-container"
                      style="max-height: 120px; overflow-y: auto"
                    >
                      <div
                        class="content-item-details"
                        *ngFor="let hw of resource.homework"
                      >
                        <mat-icon class="content-item-icon homework-icon"
                          >assignment</mat-icon
                        >
                        <span class="content-item-name">{{
                          hw.displayName || hw.name
                        }}</span>
                        <button
                          mat-icon-button
                          color="warn"
                          class="delete-item-btn"
                          (click)="deleteHomework(resource, hw, $event)"
                          matTooltip="Usuń zadanie domowe"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Pliki -->
                  <div
                    class="content-item-box"
                    *ngIf="resource.fileGroups && getFilesCount(resource) > 0"
                  >
                    <div class="content-item-header">
                      <span class="content-item-title">Pliki</span>
                      <span class="content-item-count">{{
                        getFilesCount(resource)
                      }}</span>
                    </div>
                    <div
                      class="content-item-details-container"
                      style="max-height: 120px; overflow-y: auto"
                    >
                      <ng-container *ngFor="let group of resource.fileGroups">
                        <div
                          class="file-group-name"
                          *ngIf="group.name"
                          style="
                            font-weight: 500;
                            margin-top: 8px;
                            margin-bottom: 4px;
                            padding-left: 4px;
                            color: #5c6bc0;
                            font-size: 0.9rem;
                          "
                        >
                          {{ group.name }}
                        </div>
                        <div
                          class="content-item-details"
                          *ngFor="let file of group.files"
                        >
                          <mat-icon class="content-item-icon files-icon"
                            >insert_drive_file</mat-icon
                          >
                          <span class="content-item-name">{{ file.name }}</span>
                          <button
                            mat-icon-button
                            color="warn"
                            class="delete-item-btn"
                            (click)="deleteFile(resource, group, file, $event)"
                            matTooltip="Usuń plik"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>

            <!-- Przyciski akcji - większe -->
            <mat-card-actions class="px-3 pb-3 d-flex justify-content-end">
              <button
                mat-raised-button
                color="primary"
                class="mx-2"
                (click)="openAddEditDialog(resource)"
                matTooltip="Edytuj zasób"
              >
                <mat-icon>edit</mat-icon> Edytuj
              </button>
              <button
                mat-raised-button
                color="accent"
                class="mx-2"
                (click)="duplicateResource(resource)"
                matTooltip="Duplikuj zasób"
              >
                <mat-icon>content_copy</mat-icon> Duplikuj
              </button>
              <button
                mat-raised-button
                color="warn"
                (click)="deleteResource(resource)"
                matTooltip="Usuń zasób"
              >
                <mat-icon>delete</mat-icon> Usuń
              </button>
            </mat-card-actions>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
