import { Component, OnInit, OnDestroy, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesResourcesService } from 'src/app/shared/services/courses-resources.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-resource-selection-dialog',
    templateUrl: './resource-selection-dialog.component.html',
    styleUrls: ['./resource-selection-dialog.component.css']
})
export class ResourceSelectionDialogComponent implements OnInit, OnDestroy {
    resources = [];
    filteredResources = [];
    searchText = '';
    selectedResources = [];
    private resourcesSub: Subscription;

    constructor(
        private coursesResourcesService: CoursesResourcesService,
        private helpers: HelpersService,
        public dialogRef: MatDialogRef<ResourceSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { selectedResources: any[] }
    ) { }

    ngOnInit(): void {
        // Inicjalizacja wybranych zasobów z przekazanych danych
        if (this.data && this.data.selectedResources) {
            this.selectedResources = [...this.data.selectedResources];
        }

        this.coursesResourcesService.getResourcesList();
        this.resourcesSub = this.coursesResourcesService
            .resourcesUpdatedListener()
            .subscribe((resources) => {
                this.resources = resources;
                this.filteredResources = resources;
                // Oznacz zasoby, które są już wybrane
                this.markSelectedResources();
            });
    }

    filterResources() {
        if (!this.searchText) {
            this.filteredResources = this.resources;
            this.markSelectedResources();
            return;
        }

        this.filteredResources = this.resources.filter(resource =>
            resource.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.description?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.theme?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.competence?.toLowerCase().includes(this.searchText.toLowerCase())
        );
        this.markSelectedResources();
    }

    /**
     * Oznacza zasoby, które są już wybrane
     */
    markSelectedResources() {
        const selectedIds = this.selectedResources.map(r => r.id);
        this.filteredResources.forEach(resource => {
            resource.selected = selectedIds.includes(resource.id);
        });
    }

    /**
     * Przełącza wybór zasobu
     */
    toggleResourceSelection(resource) {
        const index = this.selectedResources.findIndex(r => r.id === resource.id);

        if (index === -1) {
            // Dodaj do wybranych
            this.selectedResources.push({
                id: resource.id,
                name: resource.name
            });
            resource.selected = true;
        } else {
            // Usuń z wybranych
            this.selectedResources.splice(index, 1);
            resource.selected = false;
        }
    }

    /**
     * Oblicza całkowitą ilość plików we wszystkich grupach plików zasobu
     */
    getFilesCount(resource): number {
        if (!resource.fileGroups || !Array.isArray(resource.fileGroups)) {
            return 0;
        }

        return resource.fileGroups.reduce((total, group) => {
            if (group.files && Array.isArray(group.files)) {
                return total + group.files.length;
            }
            return total;
        }, 0);
    }

    /**
     * Sprawdza czy zasób zawiera jakiekolwiek elementy, które można pokazać w podglądzie
     */
    hasResourceItems(resource): boolean {
        if (!resource) return false;

        const hasSets = resource.sets && Array.isArray(resource.sets) && resource.sets.length > 0;
        const hasActivities = resource.activities && Array.isArray(resource.activities) && resource.activities.length > 0;
        const hasHomework = resource.homework && Array.isArray(resource.homework) && resource.homework.length > 0;
        const hasFiles = this.getFilesCount(resource) > 0;

        return hasSets || hasActivities || hasHomework || hasFiles;
    }

    /**
     * Zatwierdza wybrane zasoby i zamyka dialog
     */
    confirmSelection() {
        this.dialogRef.close(this.selectedResources);
    }

    /**
     * Anuluje wybór i zamyka dialog
     */
    cancelSelection() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.resourcesSub) {
            this.resourcesSub.unsubscribe();
        }
    }
} 