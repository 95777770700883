import { ChangeDetectorRef, Component, Inject, Input, OnInit } from "@angular/core";
import { TopBarVisibilityService } from "../../top-bar-visibility.service";
import { LessonActivitiesService } from "../../../shared/services/lesson-activities.service";
import { LessonActivitiesAddEditComponent } from "./lesson-activities-add-edit/lesson-activities-add-edit.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserDetailsService } from "../../user-details.service";
import { HelpersService } from "src/app/shared/services/helpers.service";

@Component({
  selector: "app-teacher-activities",
  templateUrl: "./teacher-activities.component.html",
  styleUrls: ["./teacher-activities.component.css"],
})
export class TeacherActivitiesComponent implements OnInit {
  isEditMode = false;
  @Input() trainingMode = false;
  sub: any;
  lessonActivities = [];
  courses = [];
  activitiesToAdd = [];
  interval;
  activityToFind = "";
  filter = 'activities';
  allActivities: any[];

  constructor(
    private topBarVisibilityService: TopBarVisibilityService,
    public userDetailsService: UserDetailsService,
    public dialogRef: MatDialogRef<TeacherActivitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private lessonActivitiesService: LessonActivitiesService,
    private bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService,
    private helpers: HelpersService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.courses.forEach(course => {
        course.finishedActivtiesNumber = course.activities.filter(activity => activity.finished).length;
      });
    }, 3000);

    if (this.trainingMode) {
      this.filter = 'forTraining';
    }

    this.spinner.show();
    this.sub = this.lessonActivitiesService
      .lessonActivitiesUpdatedListener()
      .subscribe((activities) => {
        this.lessonActivities = activities;
        this.lessonActivities.forEach(activity => {
          const userInTrackingData = activity?.trackingData?.find(trackingData =>
            trackingData?.userId == this.userDetailsService.getSimpleUserDetails().id
          );

          if (userInTrackingData) {
            if (!userInTrackingData.data.finishedTimes) {
              userInTrackingData.data.finishedTimes = [];
            }
            let lastFinished = userInTrackingData?.data?.finishedTimes[userInTrackingData?.data?.finishedTimes?.length - 1];
            let threeMonthsAgo = Date.now() - 1000 * 60 * 60 * 24 * (activity.frequency || 90);
            if (lastFinished > threeMonthsAgo) {
              activity.finished = true;
            }
          }
        });

        if (this.filter !== 'null') {
          this.lessonActivities = this.lessonActivities.filter(activity =>
            activity?.tags?.some(tag => tag.tag == this.filter)
          );
        }

        let activitiesInCourses = this.lessonActivities.filter(activity =>
          activity?.tags?.some(tag => tag.tag.includes('course:'))
        );

        activitiesInCourses.forEach(activity => {
          let courseName = activity.tags.find(tag => tag.tag.includes('course:')).tag.split(':')[1];
          let course = this.courses.find(course => course.name == courseName);
          if (course && !course.activities.some(act => act.id == activity.id)) {
            course.activities.push(activity);
          } else if (!course) {
            this.courses.push({ name: courseName, activities: [activity] });
          }
        });

        this.courses = this.courses.sort((a, b) => a.name.localeCompare(b.name));
        this.courses.forEach(course => {
          course.activities = course.activities.sort((a, b) => a.name.localeCompare(b.name));
          course.finishedActivtiesNumber = course.activities.filter(activity => activity.finished).length;
        });

        this.spinner.hide();
      });

    this.filterActivities();
    this.topBarVisibilityService.toggle(true);
  }

  addActivityToLesson(activity) {
    if (!this.activitiesToAdd.some(act => act.id === activity.id)) {
      this.activitiesToAdd.push(activity);
    }
  }

  removeActivityFromLesson(activity) {
    this.activitiesToAdd = this.activitiesToAdd.filter(act => act.id !== activity.id);
  }

  checkIfDisplayActivity(activity) {
    if (activity?.tags) {
      if (activity?.tags?.some(tag => tag.tag.includes('course:')) && this.activityToFind?.length == 0) {
        return false;
      }
      return true;
    }
    return true;
  }

  findActivityByName() {
    this.allActivities = this.lessonActivities;
    this.lessonActivitiesService.findActivityByName(this.activityToFind);
  }

  recordDescription() {

  }

  openAddActivityPanel(addMode, editMode, activityData?) {
    this.isEditMode = editMode;
    let _class = "empty-wide";
    if (window.innerWidth < 800) {
      _class = "empty";
    }
    const ref = this.bottomSheet.open(LessonActivitiesAddEditComponent, {
      data: {
        addMode: addMode,
        editMode: editMode,
        activityData: activityData,
        currentActivities: this.allActivities,
      },
      panelClass: _class,
    });
    ref.afterDismissed().subscribe((e) => {
      if (e) {
        let activityToChange = this.lessonActivities.findIndex(
          (activity) => activity.id === e?.id || activity.id == e?.source
        );
        if (activityToChange !== -1) {
          this.lessonActivities[activityToChange] = e;
        }
      }
    });
  }

  filterActivities() {
    this.lessonActivitiesService.getActivitiesList();
  }

  checkIfDisplayCourse(course) {
    return course?.activities.some(activity =>
      activity?.tags?.some(tag => tag.tag == this.filter)
    );
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
