<mat-card class="responsive-card">
  <div class="card-header">
    <h2 class="section-title">Courses</h2>
  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <app-courses-list></app-courses-list>
  </div>
</mat-card>
