<h2 mat-dialog-title>Podgląd zasobu</h2>
<mat-divider></mat-divider>

<div
  mat-dialog-content
  style="height: calc(100% - 100px); overflow-y: auto; padding: 24px"
>
  <div *ngIf="isLoading" class="text-center p-5">
    <mat-spinner diameter="40" class="mx-auto mb-3"></mat-spinner>
    <p>Ładowanie szczegółów zasobu...</p>
  </div>

  <div *ngIf="!isLoading && resource && !resource.error">
    <!-- Nagłówek -->
    <div class="resource-header mb-4">
      <h3 class="resource-title mb-2">{{ resource.name }}</h3>
    </div>

    <!-- Tagi i info -->
    <div class="resource-info-grid mb-4">
      <div class="resource-tags-container">
        <div class="d-flex flex-wrap align-items-center">
          <span *ngIf="resource.theme" class="resource-tag theme">
            <mat-icon class="tag-icon">local_offer</mat-icon>
            {{ resource.theme }}
          </span>
          <span
            *ngIf="resource.difficultyLevel"
            class="resource-tag difficulty"
          >
            <mat-icon class="tag-icon">signal_cellular_alt</mat-icon>
            {{ resource.difficultyLevel }}
          </span>
          <span *ngIf="resource.competence" class="resource-tag competence">
            <mat-icon class="tag-icon">psychology</mat-icon>
            {{ resource.competence }}
          </span>
          <span *ngIf="resource.ages" class="resource-tag ages">
            <mat-icon class="tag-icon">people</mat-icon>
            {{ resource.ages }}
          </span>
          <span
            *ngIf="resource.isSchoolCurriculum"
            class="resource-tag curriculum"
            matTooltip="Zgodne z programem szkolnym"
          >
            <mat-icon class="tag-icon">school</mat-icon>
            Program szkolny
          </span>
        </div>
      </div>

      <div class="resource-metadata">
        <div
          class="metadata-item"
          *ngIf="resource.isSchoolCurriculum && resource.bookName"
        >
          <mat-icon class="metadata-icon">menu_book</mat-icon>
          <span>{{ resource.bookName }}</span>
        </div>
        <div class="metadata-item" *ngIf="resource.added">
          <mat-icon class="metadata-icon">event</mat-icon>
          <span>{{ resource.added | date: "dd.MM.yyyy" }}</span>
        </div>
      </div>
    </div>

    <!-- Opis -->
    <div class="description-container mb-4" *ngIf="resource.description">
      <h4 class="section-title">Opis</h4>
      <p class="description-text">{{ resource.description }}</p>
    </div>

    <!-- Sekcja zawartości -->
    <div
      class="resource-content-section"
      *ngIf="hasCurriculumOrResourceItems(resource)"
    >
      <h4 class="section-title">Zawartość</h4>

      <!-- Programy nauczania -->
      <div
        class="curriculum-container mb-4"
        *ngIf="resource.curriculum && resource.curriculum.length > 0"
      >
        <h5 class="subsection-title">Programy nauczania</h5>
        <div class="curriculum-list">
          <div
            *ngFor="let curriculum of resource.curriculum; let i = index"
            class="curriculum-item mb-3"
          >
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{
                  curriculum.name || "Program bez nazwy"
                }}</mat-card-title>
                <div class="card-actions">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="viewCurriculum(curriculum)"
                    matTooltip="Wyświetl program"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </mat-card-header>
              <mat-card-content>
                <div class="curriculum-summary">
                  <p>
                    <strong>Liczba jednostek:</strong>
                    {{ curriculum.units?.length || 0 }}
                  </p>
                  <p *ngIf="curriculum.units && curriculum.units.length > 0">
                    <strong>Rozdziały:</strong>
                    <span
                      *ngFor="
                        let unit of curriculum.units.slice(0, 3);
                        let last = last
                      "
                    >
                      {{ unit.topic || "Bez tematu" }}{{ !last ? ", " : "" }}
                    </span>
                    <span *ngIf="curriculum.units.length > 3">...</span>
                  </p>
                  <p *ngIf="!curriculum.units || curriculum.units.length === 0">
                    <em>Brak zdefiniowanych jednostek</em>
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="content-grid">
        <!-- Zestawy -->
        <div
          class="content-item-box"
          *ngIf="resource.sets && resource.sets.length > 0"
        >
          <div class="content-item-header">
            <span class="content-item-title">Zestawy</span>
            <span class="content-item-count">{{ resource.sets.length }}</span>
          </div>
          <div
            class="content-item-details-container"
            style="max-height: 120px; overflow-y: auto"
          >
            <div class="content-item-details" *ngFor="let set of resource.sets">
              <mat-icon class="content-item-icon sets-icon">category</mat-icon>
              <span class="content-item-name">{{ set.name }}</span>
            </div>
          </div>
        </div>

        <!-- Ćwiczenia -->
        <div
          class="content-item-box"
          *ngIf="resource.activities && resource.activities.length > 0"
        >
          <div class="content-item-header">
            <span class="content-item-title">Ćwiczenia</span>
            <span class="content-item-count">{{
              resource.activities.length
            }}</span>
          </div>
          <div
            class="content-item-details-container"
            style="max-height: 120px; overflow-y: auto"
          >
            <div
              class="content-item-details"
              *ngFor="let activity of resource.activities"
            >
              <mat-icon class="content-item-icon activities-icon"
                >extension</mat-icon
              >
              <span class="content-item-name">{{ activity.name }}</span>
            </div>
          </div>
        </div>

        <!-- Zadania domowe -->
        <div
          class="content-item-box"
          *ngIf="resource.homework && resource.homework.length > 0"
        >
          <div class="content-item-header">
            <span class="content-item-title">Zadania domowe</span>
            <span class="content-item-count">{{
              resource.homework.length
            }}</span>
          </div>
          <div
            class="content-item-details-container"
            style="max-height: 120px; overflow-y: auto"
          >
            <div
              class="content-item-details"
              *ngFor="let hw of resource.homework"
            >
              <mat-icon class="content-item-icon homework-icon"
                >assignment</mat-icon
              >
              <span class="content-item-name">{{
                hw.displayName || hw.name
              }}</span>
            </div>
          </div>
        </div>

        <!-- Pliki -->
        <div
          class="content-item-box"
          *ngIf="resource.fileGroups && getFilesCount(resource) > 0"
        >
          <div class="content-item-header">
            <span class="content-item-title">Pliki</span>
            <span class="content-item-count">{{
              getFilesCount(resource)
            }}</span>
          </div>
          <div
            class="content-item-details-container"
            style="max-height: 120px; overflow-y: auto"
          >
            <ng-container *ngFor="let group of resource.fileGroups">
              <div
                class="file-group-name"
                *ngIf="group.name"
                style="
                  font-weight: 500;
                  margin-top: 8px;
                  margin-bottom: 4px;
                  padding-left: 4px;
                  color: #5c6bc0;
                  font-size: 0.9rem;
                "
              >
                {{ group.name }}
              </div>
              <div
                class="content-item-details"
                *ngFor="let file of group.files"
              >
                <mat-icon class="content-item-icon files-icon"
                  >insert_drive_file</mat-icon
                >
                <span class="content-item-name">{{ file.name }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Komunikat o błędzie -->
  <div *ngIf="!isLoading && resource && resource.error" class="text-center p-5">
    <mat-icon color="warn" style="font-size: 40px; height: 40px; width: 40px"
      >error_outline</mat-icon
    >
    <p class="mt-3">Nie udało się załadować szczegółów zasobu.</p>
    <p>
      Nazwa zasobu: <strong>{{ resource.name }}</strong>
    </p>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="close()">Zamknij</button>
</div>
