import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UsersServiceService } from '../../services/users-service.service';
import { AddTaskDialogComponent } from './add-task-dialog/add-task-dialog.component';
import { LOCALE_ID, Inject } from '@angular/core';
import { HelpersService } from '../../services/helpers.service';
import { DeviceSizeService } from '../../services/device-size.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RealTimeCommunicationService } from '../../services/real-time-communication.service';
import { TaskViewComponent } from './task-view/task-view.component';

import { WorkersAvatarsService } from '../../services/workers-avatars.service';
import { TasksService } from '../../services/tasks-service';
import { OverdueTasksDialogComponent } from './overdue-tasks-dialog/overdue-tasks-dialog.component';
import { Router } from '@angular/router';
import { TasksFinderComponent } from './tasks-finder/tasks-finder.component';
import { D } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css'],
})
export class AddTaskComponent implements OnInit {


  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  showScrollButtons = true;
  workers = [];
  conversaionTasks = [];
  selectedWorker: any = null;
  workerMode = false;
  anyPanelOpened = false;
  overDueTasksSnoozed = false;
  workersSub: any;
  refreshScheduled = false;
  handsetSub: any;
  startDate = 0
  endDate = 0
  searchText = ''
  authorMode = false;
  myTasks = [];
  pinnedTasks = [];
  commentToAdd: any;
  previouslySelectedWorker: any = null;
  tasksChangesSub: any;
  showAllTasks: any;
  messagesForWorkersSub: any;
  params;
  overdueTasks = [];
  forTodayTasks = [];
  forFutureTasks = [];
  finishedTasks = [];
  platformTasks = [];
  rescheduleLessonsTasks = [];
  number = 0;
  taskPanelOpened = false;
  searchDateFrom
  searchDateTo
  paymentsTasks: any;
  tasksListsSub: any;
  taskOpened: any;
  foundTasks = []
  customTitle = '';
  snoozeTimeout: NodeJS.Timeout;
  foudTasksSub: any;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public usersService: UsersServiceService,
    private dialog: MatDialog,
    private helpers: HelpersService,
    public userDetailsService: UserDetailsService,
    public deviceSizeService: DeviceSizeService,
    private spinner: NgxSpinnerService,
    private realTimeCommunicationService: RealTimeCommunicationService,
    private router: Router,
    public workersAvatarsService: WorkersAvatarsService,
    private tasksService: TasksService
  ) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.checkOverflow();

  }
  ngOnInit(): void {
    this.foudTasksSub = this.tasksService.foundTasksUpdated.subscribe((foundTasks) => {
      this.foundTasks = foundTasks;
    }
    );

    // when the tab goes back from hidden to visible, refresh the tasks
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
      }
    }
    );
    // let checkingTimeout = setInterval(() => {
    //   if (this.userDetailsService.getSimpleUserDetails()?.id) {
    //     this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
    //   }
    // }, 30000);
    this.messagesForWorkersSub = this.realTimeCommunicationService.messagesForWorkersListener().subscribe((message: any) => {

      if (message.updatedTask && !this.tasksService.taskPanelOpened) {
        this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
      }
    }
    );

    this.tasksListsSub = this.tasksService.tasksListsUpdated.subscribe(
      (tasksLists) => {
        this.overdueTasks = tasksLists.overdueTasks;
        this.forTodayTasks = tasksLists.todayTasks;
        this.forFutureTasks = tasksLists.futureTasks;
        this.finishedTasks = tasksLists.finishedTasks;
        this.pinnedTasks = tasksLists.pinnedTasks;
        let interval = setInterval(() => {
          if (this.workers.length > 0) {

            this.spinner.hide();
            if (this.overdueTasks.length > 0) {
              if (!this.overDueTasksSnoozed) {
                this.openOverdueTasksDialog();
              }
            }
            clearInterval(interval);
          }
        }, 500)
      }

    );
    let interval = setInterval(() => {
      if (this.userDetailsService.getSimpleUserDetails()?.id) {
        this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
        clearInterval(interval);
      }
    }

      , 500);



    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {

      } else {

      }

    });


    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      //
    }, 5000);

    this.workersSub = this.usersService
      .workersUpdatedListener()
      .subscribe((workers) => {
        if (this.userDetailsService.getSimpleUserDetails()?.role === 'owner') {
          this.workerMode = false;
        }

        this.workers = workers;
        this.workers = this.workers.filter(
          (worker) =>
            worker.id !== '60214180a3210d4932017771' &&
            worker.id !== '60ae8f6687f8d107ddb63340',
        );
        this.workers.forEach((worker) => {
          const worker2 = this.workersAvatarsService.workersWithAvatars.find(
            (worker3) => worker3.id === worker.id,
          );
          worker.priority = worker2?.priority;
        });
        this.workers = this.workers.filter((worker) => worker.priority > 0);
        this.workers = this.helpers.sortArrayByProperty(
          this.workers,
          'priority',
          'desc',
        );
        console.log(
          '🚀 ~ file: add-task.component.ts ~ line 19 ~ AddTaskComponent ~ .subscribe ~  this.workers',
          this.workers,
        );

        const selectedWorker = this.selectedWorker
          ? this.selectedWorker
          : this.userDetailsService.getUserDetails();
        this.workerSelected({
          value: selectedWorker,
        });


        this.refreshScheduled = false;

        this.spinner.hide();
        this.checkOverflow();

      });
    this.usersService.getWorkersList();
  }


  findTasks() {
    const threeDaaysAgo = Date.now() - 1000 * 60 * 60 * 24 * 3;
    const twoWeeksAgo = Date.now() - 1000 * 60 * 60 * 24 * 14;
    const twoMonthsAgo = Date.now() - 1000 * 60 * 60 * 24 * 60;
    const threeMonthsAgo = Date.now() - 1000 * 60 * 60 * 24 * 90;
    const today = Date.now();
    const convertedDateFrom = this.searchDateFrom ? new Date(this.searchDateFrom).getTime() - 1000 * 60 * 60 * 24 : threeMonthsAgo;
    const convertedDateTo = this.searchDateTo ? new Date(this.searchDateTo).getTime() + 1000 * 60 * 60 * 24 : today;
    this.tasksService.searchForTasks(this.searchText, convertedDateFrom, convertedDateTo, this.userDetailsService.getSimpleUserDetails().id);
  }

  openTaskViewPanel(task) {
    this.taskPanelOpened = true;
    const dialogRef = this.dialog.open(TaskViewComponent, {
      minWidth: '99vw',
      minHeight: '99vh',
      width: '99vw',
      height: '99vh',
      panelClass: 'hide-scrollbars',
      data: {
        task: task,
        workers: this.workers,
        selectedWorker: !task?.recipient
          ? this.selectedWorker
          : task?.recipient,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.taskPanelOpened = false;
      this.router.navigateByUrl('/profile/tasks');
      this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
    });
  }
  checkPinnedUsers(id) {
    return this.taskOpened.completion.includes(id);
  }

  checkOverflow() {
    const container = this.scrollContainer.nativeElement;
    this.showScrollButtons = container.scrollWidth > container.clientWidth - 50 && window.innerWidth > 600;
  }

  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: -200, behavior: 'smooth' });
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: 200, behavior: 'smooth' });
  }


  openTasksFinder() {
    this.dialog.open(TasksFinderComponent, {
      width: '100vw',
      height: '100vh',
      minHeight: '100vh',
      minWidth: '100vw',
      disableClose: true,
      panelClass: 'no-padding-dialog'
    })
  }
  addTask() {
    this.openAddTaskDialog();
  }
  panelClosed() {
    this.anyPanelOpened = false;

  }
  openAddTaskDialog() {
    this.taskOpened = true;
    const dialogRef = this.dialog.open(AddTaskDialogComponent, {
      width: '100vw',

      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.task) {
        this.taskOpened = false;

        this.tasksService.addTask(this.selectedWorker.id, result.task);
      }
    });
  }
  openOverdueTasksDialog() {
    if (this.overdueTasks.length > 0) {


      this.tasksService.taskPanelOpened = true;
      let dialog = this.dialog.open(OverdueTasksDialogComponent, {
        width: '100vw',
        height: '100vh',
        minHeight: '100vh',
        minWidth: '100vw',
        disableClose: true,
        data: {
          overdueTasks: this.overdueTasks
        }
      });
      dialog.afterClosed().subscribe((result) => {
        if (result == 'snooze') {
          this.overDueTasksSnoozed = true;
          this.snoozeTimeout = setTimeout(() => {
            this.overDueTasksSnoozed = false;
            clearTimeout(this.snoozeTimeout);
            this.openOverdueTasksDialog();
          }, 1000 * 60 * 10);

        }
        this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id);
        this.tasksService.taskPanelOpened = false;
      }
      );
    }
    else {
      alert('Nie masz nieskończonych zadań')
    }
  }

  workerSelected(event) {
    this.previouslySelectedWorker = this.selectedWorker;
    this.selectedWorker = this.workers.find(
      (worker) => worker.id === event.value.id || worker.id === event.value._id,
    );

  }
  ngOnDestroy(): void {
    this.workersSub.unsubscribe();
    this.messagesForWorkersSub.unsubscribe();
    this.tasksListsSub.unsubscribe();
    this.foudTasksSub.unsubscribe();
  }
}
