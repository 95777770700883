import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SetsService } from 'src/app/shared/services/sets.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-sets',
  templateUrl: './sets.component.html',
  styleUrls: ['./sets.component.css'],
})
export class SetsComponent implements OnInit, OnDestroy {
  showComponent = 'list';
  @Input() mode;
  @Input() windowMode;
  currentFreeSet = {};
  buttonClickedSub;
  buttonClicked;
  chosenSets = [];
  setToEdit;
  activeTabNumber = 0;
  dialogMode = false;
  setsType: 'words' | 'free' | 'grammar' = 'words';
  private setsListSub;

  constructor(
    private setsService: SetsService,
    public dialogRef: MatDialogRef<SetsComponent>,
    public bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id;

    if (id) {
      this.showComponent = 'content';
      this.setsService.passButtonClicked('showSetContent');
      this.setsType = 'words';
    }

    this.setsListSub = this.setsService
      .setsUpdatedListener()
      .subscribe((sets: any) => {
        if (sets?.group) {
          this.data.group = sets.group;

        }
      });

    if (
      this.data?.group?.teacherIds ||
      this.data?.group?.teachers?.length > 0
    ) {
      this.mode = 'mySets';

      this.dialogMode = true;
    }
    if (this.data.group) {
      this.dialogMode = true;
    }
    this.buttonClickedSub = this.setsService
      .buttonClickedListener()
      .subscribe((buttonClicked: any) => {
        this.buttonClicked = buttonClicked;

        if (this.buttonClicked.freeSetClicked) {
          this.currentFreeSet = this.buttonClicked.freeSetClicked;
        }
        if (buttonClicked.chosenSets) {
          this.chosenSets = buttonClicked.chosenSets;
        }
        if (buttonClicked.mode == 'addWordsFromList') {
          this.showComponent = 'create';
        }
        switch (this.buttonClicked) {
          case 'Create':
            this.showComponent = 'create';
            break;

          case 'closeBottomSheet':
            this.bottomSheet.dismiss(this.chosenSets);
            break;
          case 'resetFreeSet':
            this.currentFreeSet = null;
            break;
          case 'createFree':
            this.showComponent = 'create';
            this.setsType = 'free';
            break;
          case 'freeSetDone':
            this.showComponent = 'list';
            this.setsType = 'words';
            break;
          case 'Close':
            this.showComponent = 'list';
            break;
          case 'Done':
            this.showComponent = 'content';
            break;
          case 'showSetsList':
            this.showComponent = 'list';
            break;
          case 'showSetContent':
            this.showComponent = 'content';
            break;
          case 'removeSet':
            this.showComponent = 'list';
            break;

          default:
            break;
        }
      });
  }

  tabChanged(event) {
    console.log(
      '🚀 ~ file: sets.component.ts ~ line 76 ~ SetsComponent ~ tabChanged ~ event',
      event,
    );

    this.activeTabNumber = event.index;
  }

  updateGroupAfterSetRemoval(event) {

    this.data.group = event;
  }

  ngOnDestroy(): void {
    this.buttonClickedSub.unsubscribe();
  }
}
