import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { DomSanitizer } from '@angular/platform-browser';
import { CustomersService } from 'src/app/shared/services/customers.service';
import { EmailService } from 'src/app/shared/services/email.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { GroupsSetsComponent } from '../groups-sets/groups-sets.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SetsComponent } from 'src/app/database/sets/sets.component';
import { FilesToSendService } from '../../shared/services/files-to-send.service';
import { ArrayType } from '@angular/compiler';
import { UserDetailsService } from 'src/app/profile/user-details.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
})
export class SendEmailComponent implements OnInit {
  @ViewChild('file') fileInput;
  subject = '';
  pdf;
  fileToSend;
  sendPossible = true;
  attachmentsName;
  presets = [
    {
      name: 'Zadanie domowe po lekcji',
      subject: '',
      html: 'Dzień dobry,&nbsp;<div><br></div><div>W załączeniu przesyłam materiały z ostatnich zajęć.</div><div>Młodsze dzieci (klasy 0-3) dostają ćwiczenia w formie papierowej, i jest to ich zadanie domowe, a materiały wysyłane mailem są dodatkowe.</div><div>Starsze dzieci (4 klasa i starsze) powinny ćwiczyć materiały umieszczone przez nas w aplikacji na telefonie, a materiały, które wysyłamy mailem są dodatkowe.&nbsp;</div>',
      from: 'lingkingteachers@lingking.pl',
    },
    {
      name: 'Mail powitalny',
      subject: '',
      html: 'Dzień dobry,&nbsp;<div><br></div><div>W załączeniu przesyłam materiały z ostatnich zajęć.</div><div>Młodsze dzieci (klasy 0-3) dostają ćwiczenia w formie papierowej, i jest to ich zadanie domowe, a materiały wysyłane mailem są dodatkowe.</div><div>Starsze dzieci (4 klasa i starsze) powinny ćwiczyć materiały umieszczone przez nas w aplikacji na telefonie, a materiały, które wysyłamy mailem są dodatkowe.&nbsp;</div>',
      from: 'lingking@lingking.pl',
    },
  ];
  filesToSend = [];
  from = null;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    // upload: (file: File) => { ... }
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };
  sub: any;
  foundStudents;
  students;
  htmlContent = '';
  form;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public datepipe: DatePipe,
    private userDetailsService: UserDetailsService,
    private filesToSendService: FilesToSendService,
    private customersService: CustomersService,
    private sanitizer: DomSanitizer,
    private bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<SendEmailComponent>,
    private emailService: EmailService
  ) {
    this.form = this.fb.group({
      htmlContent: [''],
    });
  }

  ngOnInit(): void {
    this.data;
    this.sub = this.customersService
      .studentsForGroupUpdatedListener()
      .subscribe((data) => {
        this.foundStudents = data;
        console.log(
          '🚀 ~ file: send-email.component.ts ~ line 83 ~ SendEmailComponent ~ this.sub=this.customersService.studentsForGroupUpdatedListener ~ this.foundStudents',
          this.foundStudents
        );
        this.students = this.foundStudents.map((student) => {
          const newStudent = {
            name: !student.adult
              ? student.childFirstName + ' ' + student.childLastName
              : student.adultFirstName + ' ' + student.adultLastName,
            customerId: student.studentCustomerId,
            studentAppId: student.studentAppId,
            selected: true,
            addStudentLoginData: false,
            addCustomerLoginData: false,
          };
          return newStudent;
        });
        if (this.data.fromHomeworkPanel) {
          this.sendPossible = false;
          const preset = this.presets.find(
            (preset) => (preset.name = 'Zadanie domowe po lekcji')
          );
          this.presetChosen({ value: preset });
          this.getPreparedFiles();
        }
      });
    this.customersService.getStudentsForGroup(this.data.group.students);
  }
  fileSelected() {

  }
  presetChosen(event) {
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 126 ~ SendEmailComponent ~ presetChosen ~ event',
      event
    );
    this.subject = event.value.subject;
    if (event.value.name == 'Zadanie domowe po lekcji') {
      this.subject =
        'Ling King materiały z zajęć, grupa ' + this.data.group.name;
      this.from = 'lingking@lingking.pl';
    }
    this.htmlContent =
      event.value.html +
      '<hr id="null">Pozdrawiam, ' +
      this.userDetailsService.getSimpleUserDetails().name +
      ' <div>Centrum Języków Obcych<br><div>Ling King</div></div>';
  }
  fileChangeEvent(event) {
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 110 ~ SendEmailComponent ~ fileChangeEvent ~ event',
      event
    );

    this.attachmentsName = event.target.files[0].name;
    this.fileToSend = event.target.files[0];
    const filesArray = Array.from(event.target.files);
    filesArray.forEach((file: any) => {
      this.filesToSend.push({ filename: file.name, file: file });
    });
    this.prepareFileToSend();
  }

  prepareFileToSend() {
    this.filesToSend.forEach((file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = (evt) => {
        file.path = evt.target.result;
      };
    });
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 145 ~ SendEmailComponent ~ this.filesToSend.forEach ~ this.filesToSend',
      this.filesToSend
    );
  }

  sendEmail(forReal?) {
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 31 ~ SendEmailComponent ~ this.students=this.foundStudents.map ~ this.students',
      this.students
    );

    this.students = this.students.filter((student) => student.selected);
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 103 ~ SendEmailComponent ~ sendEmail ~ this.form',
      this.form
    );
    this.filesToSend.forEach((file) => {
      delete file.file;
    });
    let dataToSend = {
      students: this.students,
      html: this.htmlContent,
      subject: this.subject,
      filesToSend: this.filesToSend[0],
      from: null,
    };
    if (this.from) {
      dataToSend.from = this.from;
    }
    this.emailService.sendEmailWithHtml(dataToSend, forReal);
    this.dialog.close();
  }

  getPreparedFiles() {
    let date = new Date();
    let date2 = this.datepipe.transform(date, 'fullDate');
    console.log(
      '🚀 ~ file: send-email.component.ts ~ line 214 ~ SendEmailComponent ~ getPreparedFiles ~ let date2',
      date2
    );
    this.filesToSend.push({
      filename:
        'Materiały z zajęć' + ' ' + this.data.group.name + ' ' + date2 + '.pdf',
      file: this.filesToSendService.filesToSend[0],
    });

    this.prepareFileToSend();
    this.sendPossible = true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
