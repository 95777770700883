import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VersionService } from 'src/app/shared/services/version.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export interface FinanceEntry {
    id?: string;
    type: string; // 'expense' or 'income'
    name: string;
    value: number;
    category: string; // 'monthly', 'fixed', etc.
    isCompany: boolean;
    isTeacher: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FinancesService {
    private finances: FinanceEntry[] = [];
    private financesUpdated = new Subject<FinanceEntry[]>();
    private url: string;

    constructor(
        private http: HttpClient,
        private versionService: VersionService,
        private authService: AuthenticationService,
        private snackbarService: SnackbarService
    ) {
        this.url = this.versionService.url;
    }

    getFinances() {
        this.http
            .get(this.url + '/api/finances/getfinanceslist', {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                catchError(error => {
                    console.error('Error fetching finances:', error);
                    this.loadFallbackData();
                    this.snackbarService.showSnackBar('Could not connect to the server. Using local data.', 'error');
                    return of({ sets: [] });
                })
            )
            .subscribe((response: any) => {
                // Always initialize as empty array if response or sets is undefined
                const sets = response?.sets || [];
                this.finances = sets.map(finance => ({
                    id: finance._id || finance.id,
                    type: finance.type || 'expense',
                    name: finance.name || 'Unnamed',
                    value: finance.value || 0,
                    category: finance.category || 'uncategorized',
                    isCompany: finance.isCompany || false,
                    isTeacher: finance.isTeacher || false
                }));
                this.financesUpdated.next([...this.finances]);
            });
    }

    private loadFallbackData() {
        this.finances = [
            {
                id: '1',
                type: 'expense',
                name: 'Office Rent',
                value: 2000,
                category: 'monthly',
                isCompany: true,
                isTeacher: false
            },
            {
                id: '2',
                type: 'income',
                name: 'Course Fees',
                value: 5000,
                category: 'monthly',
                isCompany: true,
                isTeacher: false
            }
        ];
        this.financesUpdated.next([...this.finances]);
    }

    getFinancesUpdateListener() {
        return this.financesUpdated.asObservable();
    }

    addFinance(finance: FinanceEntry) {
        this.http
            .post(this.url + '/api/finances/addfinance', finance, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                catchError(error => {
                    console.error('Error adding finance entry:', error);
                    const newFinance = {
                        ...finance,
                        id: Date.now().toString()
                    };
                    this.finances.push(newFinance);
                    this.financesUpdated.next([...this.finances]);
                    this.snackbarService.showSnackBar('Added locally. Changes will not be saved to the server.', 'error');
                    return of({ createdFinanceId: newFinance.id });
                })
            )
            .subscribe((response: any) => {
                const newFinance = {
                    id: response?.createdFinanceId || Date.now().toString(),
                    ...finance
                };
                this.finances.push(newFinance);
                this.financesUpdated.next([...this.finances]);
                this.snackbarService.showSnackBar(response?.message || 'Finance entry added successfully', 'success');
            });
    }

    updateFinance(id: string, finance: FinanceEntry) {
        this.http
            .put(this.url + '/api/finances/updatefinance/' + id, finance, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                catchError(error => {
                    console.error('Error updating finance entry:', error);
                    const index = this.finances.findIndex(f => f.id === id);
                    if (index !== -1) {
                        this.finances[index] = { ...finance, id };
                        this.financesUpdated.next([...this.finances]);
                        this.snackbarService.showSnackBar('Updated locally. Changes will not be saved to the server.', 'error');
                    }
                    return of({ message: 'Updated locally' });
                })
            )
            .subscribe((response: any) => {
                const index = this.finances.findIndex(f => f.id === id);
                if (index !== -1) {
                    this.finances[index] = { ...finance, id };
                    this.financesUpdated.next([...this.finances]);
                    this.snackbarService.showSnackBar(response?.message || 'Finance updated successfully', 'success');
                }
            });
    }

    deleteFinance(id: string) {
        this.http
            .delete(this.url + '/api/finances/removefinance/' + id, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                catchError(error => {
                    console.error('Error deleting finance entry:', error);
                    this.finances = this.finances.filter(f => f.id !== id);
                    this.financesUpdated.next([...this.finances]);
                    this.snackbarService.showSnackBar('Deleted locally. Changes will not be saved to the server.', 'error');
                    return of({ message: 'Deleted locally' });
                })
            )
            .subscribe((response: any) => {
                this.finances = this.finances.filter(f => f.id !== id);
                this.financesUpdated.next([...this.finances]);
                this.snackbarService.showSnackBar(response?.message || 'Finance deleted successfully', 'success');
            });
    }

    getFinanceStats() {
        return this.http
            .get(this.url + '/api/finances/getstats', {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                map((response: any) => response?.stats || { totalIncome: 0, totalExpenses: 0 }),
                catchError(error => {
                    console.error('Error fetching finance stats:', error);
                    this.snackbarService.showSnackBar('Could not fetch finance statistics', 'error');
                    return of({ totalIncome: 0, totalExpenses: 0 });
                })
            );
    }

    createTransaction(transaction: { amount: number, type: string, description: string }) {
        return this.http
            .post(this.url + '/api/finances/addtransaction', transaction, {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` }
            })
            .pipe(
                map((response: any) => ({
                    id: response?.createdTransactionId || Date.now().toString(),
                    ...transaction
                })),
                catchError(error => {
                    console.error('Error creating transaction:', error);
                    this.snackbarService.showSnackBar('Could not create transaction', 'error');
                    return of({
                        id: Date.now().toString(),
                        ...transaction
                    });
                })
            );
    }
} 