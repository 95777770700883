import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { HelpersService } from './helpers.service';
import { VersionService } from './version.service';

const { dool } = require('dooljs');

@Injectable({
  providedIn: 'root',
})
export class YearlyPlanService {
  private url: string;
  private yearlyPlan: any;
  private yearlyPlanUpdated = new Subject();

  constructor(
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;

    this.getYearlyPlan();
  }

  getYearlyPlan() {
    this.http
      .post(this.url + '/api/events/findcurrentyearsplan/', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let eventsArray = Object.keys(response).map((i) => response[i]);

        eventsArray = eventsArray.map((incomingevent) => {
          return {
            days: incomingevent?.data?.avalibleDays,
          };
        });
        this.yearlyPlan = eventsArray[0].days;
        this.yearlyPlan.forEach((day) => {
          day.dateMs = Date.parse(day.date);
        });
        this.yearlyPlanUpdated.next(this.yearlyPlan);
      });
  }

  getDaysList(startDateObj, endDateObj, dayName?) {
    let days;
    if (!dayName) {
      days = dool(startDateObj, endDateObj);
    } else {
      days = dool(startDateObj, endDateObj, dayName);
    }

    return days;
  }

  getDateObject(date) {
    const dateObj = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    ).toString();
    return dateObj;
  }
  getDateWithTimeObject(date) {
    const dateObj = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    ).toString();
    return dateObj;
  }

  getPolishDaysToNumbers() {
    const daysArray = [
      { name: 'Poniedziałek', value: 1, eng: 'monday' },
      { name: 'Wtorek', value: 2, eng: 'tuesday' },
      { name: 'Środa', value: 3, eng: 'wednesday' },
      { name: 'Czwartek', value: 4, eng: 'thursday' },
      { name: 'Piątek', value: 5, eng: 'friday' },
      { name: 'Sobota', value: 6, eng: 'saturday' },
      { name: 'Niedziela', value: 0, eng: 'sunday' },
    ];
    return daysArray;
  }

  generateLessonsList(group) {
    console.log(
      '🚀 ~ file: yearly-plan.service.ts ~ line 99 ~ YearlyPlanService ~ generateLessonsList ~ group',
      group,
    );
    return new Promise((resolve, reject) => {
      let firstTime = true;
      if (group?.lessons?.length > 0) {
        firstTime = false;
        group.lessons = group.lessons.filter(
          (lesson) => new Date(lesson.date) < new Date(),
        );
      }
      let startDate = this.getDateObject(new Date(group.courseStartDate));
      let endDate = this.getDateObject(new Date(group.courseEndDate));
      let allGroupsDays = [];
      group.schedule.forEach((scheduleItem) => {
        const dayName = this.getPolishDaysToNumbers().find(
          (day) => day.name === scheduleItem.day,
        );
        //remember to change only next lessons

        //creating whole new list, change days etc
        let days;
        if (firstTime) {
          days = this.getDaysList(startDate, endDate, dayName.eng);
        }
        if (!firstTime) {
          days = this.getDaysList(new Date(), endDate, dayName.eng);
        }


        const startHour = scheduleItem.lessonStartTime.split(':')[0];
        const startMinutes = scheduleItem.lessonStartTime.split(':')[1];
        const endHour = scheduleItem.lessonEndTime.split(':')[0];
        const endMinutes = scheduleItem.lessonEndTime.split(':')[1];
        days.forEach((day) => {
          let end = this.helpers.clone(day);
          day.setHours(startHour);
          day.setMinutes(startMinutes);
          end.setHours(endHour);
          end.setMinutes(endMinutes);
          let lessonObj = {
            lessonDuration: scheduleItem.duration,
            dayName: dayName,
            untouched: true,
            group: { name: group.name, id: group.id },
            id: this.helpers.makeid(),
            assignedTeacher: scheduleItem.teacher,
            date: this.getDateWithTimeObject(new Date(day)),
            endDate: this.getDateWithTimeObject(new Date(end)),
            dateMs: Date.parse(this.getDateObject(new Date(day))),
            startDateMs: Date.parse(this.getDateWithTimeObject(new Date(day))),
            endDateMs: Date.parse(this.getDateWithTimeObject(new Date(end))),
            attributes: null,
            lessonData: {},
          };

          lessonObj.attributes = this.assignYearlyPlansAttribute(lessonObj);
          if (!allGroupsDays.some((day) => day.date == lessonObj.date)) {
            allGroupsDays.push(lessonObj);
          }
        });
        allGroupsDays = this.helpers.sortArrayByProperty(
          allGroupsDays,
          'dateMs',
          'asc',
        );
        if (group.lessons?.length > 0) {
          group.lessons = group.lessons.concat(
            allGroupsDays.filter(
              (day) => !group.lessons.some((day2) => day2.date == day.date),
            ),
          );
        } else {
          group.lessons = allGroupsDays;
        }
      });

      resolve(this.helpers.sortArrayByProperty(group.lessons, 'dateMs', 'asc'));
    });
  }

  generateEmptyLesson(dateTime, group, teacher, duration) {
    let end = ''; //dateTime + duration
    let dayName = 'dayName'; //get day name from list
    let lessonObj = {
      lessonDuration: duration,
      dayName: dayName,
      untouched: true,
      group: { name: group.name, id: group.id },
      id: this.helpers.makeid(),
      assignedTeacher: teacher,
      date: this.getDateWithTimeObject(new Date(dateTime)),
      endDate: this.getDateWithTimeObject(new Date(end)),
      dateMs: Date.parse(this.getDateObject(new Date(dateTime))),
      startDateMs: Date.parse(this.getDateWithTimeObject(new Date(dateTime))),
      endDateMs: Date.parse(this.getDateWithTimeObject(new Date(end))),
      attributes: ['avalible'],
      lessonData: {},
    };
    return lessonObj;
  }
  assignYearlyPlansAttribute(lessonObj) {
    let result;
    const matchingDate = this.yearlyPlan.find(
      (day) =>
        this.getDateObject(new Date(day.date)) ==
        this.getDateObject(new Date(lessonObj.date)),
    );
    if (matchingDate) {
      result = matchingDate.avalibleHours;
    } else {
      result = ['absentInCalendar'];
    }
    return result;
  }

  yearlyPlanUpdatedListener() {
    return this.yearlyPlanUpdated.asObservable();
  }
}
