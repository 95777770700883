import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { FactsService } from '../facts.service';

@Component({
  selector: 'app-fact-to-edit',
  templateUrl: './fact-to-edit.component.html',
  styleUrls: ['./fact-to-edit.component.scss']
})
export class FactToEditComponent implements OnInit {
  @Input() currentCategory: any;
  @Input() fact: any;
  @Output() removeFactEvent = new EventEmitter();
  @Output() updateFactEvent = new EventEmitter();
  transformingFact = false
  selectedChat = null;
  constructor(private chatService: ChatService, private chatbotsService: ChatbotsService, private factsService: FactsService) { }

  ngOnInit() {
    this.selectedChat = this.chatbotsService.chatbots.find(chatbot => chatbot.name === 'Jessie');
  }
  okFact() {
    const data = {
      messages: [{ role: 'user', content: JSON.stringify(this.fact) }],
      aiProvider: this.selectedChat.aiProvider,
      model: this.selectedChat.model,
      maxTokens: this.selectedChat.maxTokens,
      maxMessages: this.selectedChat.maxMessages,
      systemMsg: this.selectedChat.systemMsg,
      files: null
    };
    this.transformingFact = true
    this.chatService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        this.transformingFact = false

        const transformedFact = this.extractJsonFromText(res.res.content);

        transformedFact._id = this.fact._id;
        transformedFact.category = this.currentCategory?.length > 0 ? this.currentCategory : this.fact.category;
        this.updateFactEvent.emit(transformedFact);
      }
    );

  }

  extractJsonFromText(text: string) {
    const jsonStart = text.indexOf('{');
    const jsonEnd = text.lastIndexOf('}');
    if (jsonStart === -1 || jsonEnd === -1) {
      return null;
    }
    return JSON.parse(text.substring(jsonStart, jsonEnd + 1));
  }
  updateFact() {
    this.fact.updated = Date.now();
    this.updateFactEvent.emit(this.fact);
  }

  assignCategory() {
    this.fact.category = this.currentCategory
    this.updateFact();
  }
  removeFact() {
    this.removeFactEvent.emit(this.fact._id);
  }
}
