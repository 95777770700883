import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ChatService } from 'src/app/shared/services/chat.service';
import { SrtManipulatorService } from 'src/app/video-maker/srt-manipulator.service';
import { VideoMakerService } from 'src/app/video-maker/video-maker.service';
import { VideosTypesService } from '../videos-types.service';
import { set } from 'date-fns';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { FactVideoQuestionComponent } from 'src/app/database/facts/fact-video-question/fact-video-question.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-video-word-repeat',
  templateUrl: './video-word-repeat.component.html',
  styleUrls: ['./video-word-repeat.component.scss']
})
export class VideoWordRepeatComponent implements OnInit {
  @ViewChild('image') image: ElementRef;
  @ViewChild('textBox') textBox: ElementRef;
  @ViewChild('intro') intro: ElementRef;
  @ViewChild('introAvatar') introAvatar: ElementRef;
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('videoDivider') videoDivider: ElementRef;
  @ViewChild('text') text: ElementRef;
  playingForward = true;
  reverseInterval: any;
  componentName: string = 'VideoWordDefinitionPictureComponent'; // Assign the class name to a variable
  showIntro: boolean = true;

  showSentence = false;
  currentName = '';
  currentSet;
  countDown = 4;
  progress = 0;
  showYourTurn = false;
  showGetReady = false;
  showWord = false
  showImage = false;
  audio
  beep = false;
  setIntroTime = 10000
  currentItemNumber: number;
  currentItem: any;
  pdfsToCreate = [];
  showVideoIntro: any;
  backgroundColor = '#1a237e'
  intervalId: NodeJS.Timeout;
  showSpeak: boolean;
  showVideoDivider: any;
  textColor = '#cddc39'
  colorsToChoose = ['#cddc39', '#009688', '#03a9f4', '#e91e63', '#ff9800']
  introAudio: HTMLAudioElement;
  introTextToDisplay: any;
  introData: any;
  introSequenceNumber: any = 0;
  introTime2 = 20.3
  introTime = 100
  videoColor: any;
  hues = [
    231,  // base color: #0239f9 (blue)
    66,   // l green: #cddc39 (lime green)
    174,  // d green: #009688 (teal)
    199,  // blue: #03a9f4 (light blue)
    340,  // pink: #e91e63 (pink)
    36,   // orange: #ff9800 (orange)
    288,  // purple: #ab47bc (purple)
    231,  // d blue: #5c6bc0 (indigo)
    52    // yellow: #ffd429 (yellow)
  ];
  allImages: any;
  selectedFact: any;
  facts: any;
  afterPolishAudio = false;
  selectedFactIndex = 0
  currentHueFilter = 'hue-rotate(18deg)';
  selectedFactType = 'question'
  showPolishText: boolean;

  constructor(
    private videoMakerService: VideoMakerService,
    private sanitizer: DomSanitizer,
    private srtService: SrtManipulatorService,
    private videosTypesService: VideosTypesService,
    private indexedDbService: NgxIndexedDBService,
    private router: Router,
    private chatService: ChatService,
    public helpers: HelpersService,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) { }
  selectRandomHueFilter() {

    return 'hue-rotate(' + this.hues[Math.floor(Math.random() * this.hues.length)] + 'deg)';
  }
  selectRandomColor() {
    this.textColor = this.colorsToChoose[Math.floor(Math.random() * this.colorsToChoose.length)];
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.startVideoLoop();
  }

  ngOnInit() {
    this.audio = new Audio();


    // setTimeout(() => {
    //   if (!this.videoMakerService.titleDescriptionRecieved) {
    //     alert('No title and description recieved')
    //     return

    //   }
    // }
    //   , 60000)
    this.audio.onended = () => {
      this.showPolishText = false;
      this.showSpeak = false;
      this.audio.volume = 0.2;

      if (this.showWord && !this.showSentence) {
        //add polish audio
        if (!this.afterPolishAudio) {
          this.showPolishText = true;
          this.playAudio('wordPolishAudioDownloaded', 1, 1);
        }
        else {


          if (this.countDown === 0) {
            this.selectRandomColor();
            this.showYourTurn = true;
          }
          let interval = setInterval(() => {
            if (this.countDown > 1) {
              this.showYourTurn = false;
              this.selectRandomColor();
              this.showGetReady = true;
              this.countDown--
              this.beep = true
              setTimeout(() => {
                this.beep = false
              }, 500)
            }
            else {
              this.countDown = 4;
              clearInterval(interval)
              this.showGetReady = false;
              this.selectRandomColor();
              this.showSpeak = true
              this.renderer.addClass(this.text.nativeElement, 'pulse-infinite');
              this.renderer.setStyle(this.text.nativeElement, 'color', this.textColor);
              this.renderer.setStyle(this.text.nativeElement, 'font-size', '4em');

              setTimeout(() => {
                this.playAudio('wordAudioDownloaded', 0.8, 1);
                this.showSentence = true;
              }, 500);
            }
          }, 1000);
        }
      } else if (this.showWord && this.showSentence) {
        setTimeout(() => {
          this.srtService.endDisplayingText();
          if (this.selectedFact.indexOfQuestion == this.currentItemNumber || this.selectedFact.indexOfAnswer == this.currentItemNumber) {
            this.nextItem(true);
          }
          else {
            this.nextItem();
          }
        }, 1000);
      }
    };
    this.currentSet = this.videoMakerService.currentSet;


    // { name: 'id', keypath: 'id', options: { unique: false } },
    // { name: 'setContent', keypath: 'setContent', options: { unique: false } },
    //find the current set in the db and replace it
    this.indexedDbService.getByKey('downloadedSetsForVideos', this.currentSet.id).subscribe((set) => {

      if (set) {
        this.currentSet = set.setContent;
        this.videoMakerService.currentSet = set.setContent;
      }
      else {
        alert('No set found')
        return
      }
      this.currentItemNumber = this.videoMakerService.currentItemNumber;
      this.currentItem = this.currentSet.items[this.currentItemNumber];

      this.currentName = this.currentSet.name.replace('video:', '').replace('Video:', '').replace('VIDEO:', '').replace('video:', '').replace('Video :', '').replace('VIDEO :', '').replace('video :', '').replace('Video :', '').replace('VIDEO :', '').replace('video :', '').replace('Video :', '').replace('VIDEO :', '').replace('video :', '').replace('Video :', '').replace('VIDEO :', '').replace('video :', '').replace('Video :', '').replace('VIDEO :', '').replace('video :', '').replace('Video :', '').replace('VIDEO :', '');
      this.currentName = this.currentName + '<hr>' + 'part 1 - Echo Words'// replace any text in brackets with nothing and also the brackets
      this.currentName = this.currentName.replace(/\(.*?\)/g, '');
      this.allImages = this.currentSet.items.map((item) => {
        return item.smallPictureDownloaded;

      })
      this.startVideo();
    });

  }



  startVideoLoop() {

  }
  getCleanDescription() {
    return this.currentItem?.description.replace(/"/g, '');
  }
  getDownloadedPicture() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.currentItem?.smallPictureDownloaded));
  }
  updateProgress() {
    if (!this.progress || this.progress < 100) {

      this.progress = (this.audio.currentTime / this.audio.duration) * 200;
    }


  }
  reverseVideoPlayback(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.pause(); // Pause the video to start reverse playback simulation
    const fps = 30; // Frames per second for reverse playback
    const interval = 1000 / fps; // Interval in milliseconds

    this.reverseInterval = setInterval(() => {
      if (video.currentTime <= 0) {
        // If the video reaches the beginning, clear the interval and play forward again
        clearInterval(this.reverseInterval);
        this.playingForward = true;
        video.play();
      } else {
        // Decrement the currentTime to simulate reverse playback
        video.currentTime -= 1 / fps;
      }
    }, interval);

  }
  startProgressUpdate() {
    this.intervalId = setInterval(() => {
      this.updateProgress();
    }, 1000 / 60); // Update 60 times per second for smooth progress
  }
  reset() {
    this.progress = 0;
    clearInterval(this.intervalId);
  }
  startVideo() {

    const currentVideoType = this.videosTypesService.videoTypes.find((type) => type.type === 'word-repeat');
    const pdfsToCreate = currentVideoType.pdfs;
    this.facts = JSON.parse(localStorage.getItem('factsData'));
    this.facts.forEach((fact) => {
      this.videoMakerService.downloadFacsVideo(fact);
    }
    );
    let segments = this.videoMakerService.generateSegments(this.currentSet.items.length, this.currentSet.items.length / this.facts.length);

    this.facts.forEach((fact, index) => {
      fact.indexOfQuestion = segments[index].indexOfQuestion;
      fact.indexOfAnswer = segments[index].indexOfAnswer;
    }
    );


    this.selectedFact = this.facts[0];

    let additionalIntroData = JSON.parse(localStorage.getItem('additionalIntroData') || '[]');
    additionalIntroData.forEach((data, index) => {
      try {
        data.texts = JSON.parse(data.texts);
      } catch (error) {
        console.error(`Error parsing texts at index ${index}:`, error);
      }
    });
    this.videoColor = currentVideoType.videoColor;
    this.introData = currentVideoType.introData
    if (additionalIntroData) {
      additionalIntroData.forEach((data, index) => {
        this.introData.introTexts.splice(index, 0, data.texts);
        this.introData.introAudios.splice(index, 0, data.audio);
      });
    }

    this.startIntro()
    const langs = this.videoMakerService.translationsToDownload
    let objectToSend = {
      type: 'titleDescription',
      langs: []
    }
    let titleDescription = JSON.parse(localStorage.getItem('titleDescription'));
    objectToSend.langs.push({ lang: 'English', titleText: '', descriptionText: '' })
    // langs.forEach((lang) => {

    //   objectToSend.langs.push({ lang: lang, titleText: '', descriptionText: '' })

    // })
    let simpleWords = this.currentSet.items.map((item) => {
      return item.word1 + ' ' + item.word2;
    }
    );
    //   let chatMessage = 'Write a YouTube title and description for a video for learning English. In the video there are words, sentences and pictures with subtitles. Make it excellent for YouTube algorithm and YouTube SEO.Use many keywords and hashtags and emojis.';
    //   chatMessage += `The video is in the topic of ${this.currentName}. The video displays a sequence of word + sentence + picture.  Use Emojis.The title should have maximum of 90 characters. The description should have between 800 and 900 characters. At the end include the following sentence: Links to free worksheets:`;
    // chatMessage += 'Also ';
    //   // chatMessage += 'The titles and descriptions should be in target languages and written as a native speaker of the target language would say it. Fill in the object: ' + JSON.stringify(objectToSend);
    //   let missingTitleDescription
    //   if (titleDescription) {
    //   }
    //   if (!titleDescription || missingTitleDescription) {
    //     this.chatService.sendPrompt(chatMessage, null, true);
    //   }
    pdfsToCreate.forEach((pdf) => {

      pdf.pages.forEach((page) => {
        page.forEach((task) => {
          task.setId = this.currentSet.id;
        });
      }
      );
      if (pdf.allLangs) {
        langs.forEach((lang) => {
          const pdfCopy = JSON.parse(JSON.stringify(pdf));
          pdfCopy.customLang = lang;
          this.pdfsToCreate.push(pdfCopy);
        });
      }
      else {
        this.pdfsToCreate.push(pdf);
      }
    }
    );



    // this.showIntro = true;

  }
  startIntro() {
    setTimeout(() => {
      setTimeout(() => {

        this.renderer.addClass(this.introAvatar.nativeElement, 'pulse-infinite');
      }, 10);
    }, 600);
    let interval = setInterval(() => {
      this.introTime = this.introTime + 1
      this.introTime2 = this.introTime2 + 10
    }
      , 100);
    this.introAudio = new Audio();
    this.introTextToDisplay = this.introData.introTexts[0].English
    this.introAudio.src = this.introData.introAudios[this.introSequenceNumber]
    this.srtService.startDisplayingText(null, null, (this.introTime * 10), this.introData.introTexts[this.introSequenceNumber], true);
    this.introAudio.play().then(() => {

    }).catch(error => {
      console.error('Error playing audio:', error);
    });
    this.introAudio.onended = () => {
      this.srtService.endDisplayingText();
      setTimeout(() => {
        if (
          this.introData.introTexts[this.introSequenceNumber + 1]

        ) {

          this.introSequenceNumber++;
          this.introTextToDisplay = this.introData.introTexts[this.introSequenceNumber].English

          this.introAudio.src = this.introData.introAudios[this.introSequenceNumber]
          this.srtService.startDisplayingText(null, null, this.introTime * 10, this.introData.introTexts[this.introSequenceNumber], true);


          this.introAudio.play().then(() => {

          }).catch(error => {
            console.error('Error playing audio:', error);
          });
        }
        else {
          setTimeout(() => {

            this.setIntroTime = this.introTime * 10;
            clearInterval(interval);
            this.showIntro = false;
            localStorage.setItem('introTime', (this.introTime2 * 10).toString())

            localStorage.setItem('setName', this.currentName)

            localStorage.setItem('typeofVideo', 'StoryTime')
            localStorage.setItem('pdfsToCreate', JSON.stringify(this.pdfsToCreate))

            this.showItem();
          }, 2000);


        }
      }, 600);
    }
  }
  nextItem(fact?) {
    this.currentHueFilter = this.selectRandomHueFilter();
    if (!fact) {
      this.showVideoDivider = true;

    }
    // this.renderer.removeClass(this.image.nativeElement, 'blur-in');
    // this.renderer.removeClass(this.textBox.nativeElement, 'scale-in-ver-bottom');

    // this.renderer.addClass(this.image.nativeElement, 'blur-out');
    // this.renderer.addClass(this.textBox.nativeElement, 'scale-out-ver-top');

    setTimeout(() => {
      this.showImage = false;
      this.showSentence = false;
      this.showWord = false;
      if (
        this.currentSet.items[this.currentItemNumber + 1]
        // &&
        // this.currentItemNumber < 30

      ) {
        if (fact) {

          const dialogRef = this.dialog.open(FactVideoQuestionComponent, {

            panelClass: 'fact-modal',
            width: '1610px',
            height: '910px',
            minHeight: '920px',
            minWidth: '1610px',




            data: { fact: this.selectedFact, mode: this.selectedFactType, introTime: this.introTime, polishMode: true },

          });
          dialogRef.afterClosed().subscribe(result => {
            // this.showVideoDivider = true;




            if (this.selectedFactType == 'answer') {
              this.selectedFactIndex++;
              this.selectedFact = this.facts[this.selectedFactIndex];

            }



            this.selectedFactType = this.selectedFactType == 'question' ? 'answer' : 'question';



            this.loadNextItem();



          });



        }

        else {
          this.loadNextItem();
        }
        // this.nextSet();
      } else {
        this.finishVideo();
      }
    }, 1000);
  }
  //       setTimeout(() => {
  //         this.renderer.removeClass(this.videoDivider.nativeElement, 'fade-in');
  //         setTimeout(() => {
  //           this.renderer.addClass(this.videoDivider.nativeElement, 'fade-out');
  //         }, 10)

  //         setTimeout(() => {
  //           this.showVideoDivider = false;
  //         }, 300);
  //       }, 300)
  //       setTimeout(() => {

  //         this.currentItemNumber++;
  //         this.currentItem = this.currentSet.items[this.currentItemNumber];



  //         this.showItem();
  //         // this.renderer.removeClass(this.image.nativeElement, 'rotate-out-2-cw');

  //       }, 300);


  //       // this.nextSet();
  //     } else {
  //       this.finishVideo();
  //     }
  //   }, 800);
  // }
  loadNextItem() {
    setTimeout(() => {
      this.renderer.removeClass(this.videoDivider.nativeElement, 'fade-in');
      setTimeout(() => {
        this.renderer.addClass(this.videoDivider.nativeElement, 'fade-out');
      }, 10)

      setTimeout(() => {
        this.showVideoDivider = false;
      }, 300);
    }, 300)
    this.currentItemNumber++;
    this.currentItem = this.currentSet.items[this.currentItemNumber];


    this.getDownloadedPicture();
    this.showItem();
    // check if this.currentItemNumber is even or odd

  }
  createChunkedSentence() {
  }
  finishVideo() {
    this.showVideoDivider = false
    let subtitles = this.srtService.generateWebVTT();
    localStorage.setItem('subtitles', JSON.stringify(subtitles));
    localStorage.setItem('stopVideo', 'true');
    // this.videoMakerService.nextType();
    // localStorage.setItem('stopVideo', 'true');
    // setTimeout(() => {
    //   this.router.navigate(['/make-video/create']);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 4000)
    // }, 5000);
    // add a chunk
  }
  playAudio(prop: string, speed?: number, volume?: number) {
    this.audio.volume = volume || 1;



    try {
      if (!this.currentItem || !this.currentItem[prop]) {
        throw new Error(`Property ${prop} does not exist on currentItem`);
      }

      this.audio.src = URL.createObjectURL(this.currentItem[prop]);


      let text = '';
      switch (prop) {
        case 'wordAudioDownloaded':
          text = this.currentItem.word1 + ' ' + this.currentItem.word2;
          break;
        case 'wordPolishAudioDownloaded':
          text = this.currentItem.wordPolish;
          this.afterPolishAudio = true;
          break;
        default:
          throw new Error(`Unknown prop: ${prop}`);
      }

      setTimeout(() => {

      }, 500);

      this.audio.playbackRate = speed || 1;
      this.audio.play().then(() => {

      }).catch(error => {
        console.error('Error playing audio:', error);
      });

    } catch (error) {
      console.error('Error in playAudio:', error);
    }
  }
  showItem() {
    this.afterPolishAudio = false;
    let interval = setInterval(() => {


      if (this.image?.nativeElement) {

        this.renderer.removeClass(this.image.nativeElement, 'blur-in');
        this.renderer.addClass(this.image.nativeElement, 'kenburns-top');
      }
    }, 1000);

    setTimeout(() => {
      this.renderer.removeClass(this.image.nativeElement, 'scale-in-ver-top');

      this.renderer.addClass(this.image.nativeElement, 'kenburns-top');
      clearInterval(interval);
    }, 1000);
    this.showImage = true;
    setTimeout(() => {
      this.playAudio('wordAudioDownloaded', 0.8);
    }, 2000);
    setTimeout(() => {
      this.showWord = true;
      this.srtService.startDisplayingText('word', this.currentItem, this.setIntroTime);

    }, 1000);
  }
}
