<div
  *ngIf="!editMode && parseddActivityPdfs?.length > 0"
  style="width: 100vw; height: 100vh"
>
  <div fxLayout="column" style="height: 100%; width: 100%">
    <div fxFlex>
      <ng-container>
        <div
          [@rotateAnimation]="isRotated ? 'rotated' : 'normal'"
          *ngFor="let pdf of parseddActivityPdfs; let i = index"
        >
          <div style="height: 100%">
            <pdf-viewer
              *ngIf="showPdf"
              [src]="{ url: pdf?.link, withCredentials: false }"
              [zoom-scale]="zoomScale"
              [zoom]="pdfZoom"
              [render-text]="true"
              [zoom-scale]="'page-width'"
              [fit-to-page]="true"
              [original-size]="false"
              [page]="pdf?.page"
              style="width: 100%; height: 100%"
              [show-all]="false"
              (after-load-complete)="pageRendered($event, pdf)"
            ></pdf-viewer>
          </div>
          <div
            style="
              background-color: rgba(203, 203, 203, 0.271);
              border-radius: 15px;
              width: 90%;
              margin-left: 5%;
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            [style.position]="isRotated ? 'absolute' : 'sticky'"
            [style.bottom]="isRotated ? '5vw' : '5vh'"
          >
            <button mat-icon-button (click)="decreasePdfPage(pdf)">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <span class="ml-3 mr-3">{{ pdf.page }}/{{ pdf?.totalPages }}</span>
            <button mat-icon-button (click)="increasePdfPage(pdf)">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button mat-icon-button (click)="pdfZoom = pdfZoom - 0.1">
              <mat-icon>zoom_out</mat-icon>
            </button>
            <span class="ml-2 mr-2">{{ pdfZoom | number: "1.0-1" }}</span>
            <button mat-icon-button (click)="pdfZoom = pdfZoom + 0.1">
              <mat-icon>zoom_in</mat-icon>
            </button>
            <button *ngIf="isRotated" mat-icon-button (click)="rotateElement()">
              <mat-icon>close_fullscreen</mat-icon>
            </button>
            <button
              *ngIf="!isRotated"
              mat-icon-button
              (click)="rotateElement()"
            >
              <mat-icon>open_in_full</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="closeSheet()">
              <mat-icon>exit_to_app</mat-icon>
            </button>
            <button
              *ngIf="userDetailsService.owner"
              mat-icon-button
              color="primary"
              (click)="editMode = true"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  *ngIf="editMode || parseddActivityPdfs?.length == 0 || !parseddActivityPdfs"
  [ngClass.gt-sm]="{ 'pl-3 pr-3': true }"
  fxLayout="column"
  fxLayoutAlign="start start"
  style="width: 98%; height: 100%; overflow-y: hidden; overflow-x: hidden"
>
  <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span class="mat-h1 mb-0" *ngIf="!editMode && addMode"
        >Dodaj ćwiczenie
      </span>
    </div>
    <mat-slide-toggle
      *ngIf="editMode || addMode"
      [(ngModel)]="activityUnfinished"
      >Wersja robocza
    </mat-slide-toggle>
  </div>
  <div fxFlex fxLayout="column" style="overflow-y: auto; width: 100%">
    <div class="p-3" fxLayout="column" style="width: 100%; overflow-y: auto">
      <span *ngIf="editMode" class="mat-h1">Nazwa </span>
      <mat-form-field *ngIf="editMode" appearance="outline">
        <mat-label>Nazwa</mat-label>
        <input [(ngModel)]="activityName" matInput />
      </mat-form-field>
      <div
        class="activity-name-container"
        *ngIf="
          !editMode &&
          activitiesAudios?.length > 0 &&
          (parseddActivityPdfs?.length == 0 || !parseddActivityPdfs)
        "
      >
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-center"
        >
          <span
            *ngIf="
              descriptionAudio?.paused ||
              !descriptionAudio ||
              !descriptionAudio?.playing
            "
            (click)="playAudio()"
            style="color: #673ab7; cursor: pointer; transform: scale(1.3)"
            class="material-symbols-outlined mb-1"
            >play_circle
          </span>
          <span
            *ngIf="descriptionAudio?.playing"
            (click)="pauseAudio()"
            style="color: #673ab7; cursor: pointer; transform: scale(1.3)"
            class="material-symbols-outlined mb-1"
            >pause_circle
          </span>
          <progress
            style="flex: 1"
            class="ml-2"
            [value]="audioProgressPercentage"
            max="100"
          ></progress>
          <!-- <span
            (click)="setTrackingData(1)"
            class="material-symbols-outlined mb-1"
            >done
          </span> -->
        </div>
      </div>
      <div *ngIf="editMode" fxLayout="column">
        <div class="mt-2" fxLayout="row wrap">
          <button
            (click)="addTag({ value: 'activities' })"
            mat-stroked-button
            color="primary"
          >
            ćwiczenie
          </button>
          <button
            (click)="addTag({ value: 'activities-single' })"
            mat-stroked-button
            color="primary"
          >
            ćwiczenie indywidualni
          </button>
          <button
            (click)="addTag({ value: 'theory' })"
            mat-stroked-button
            color="primary"
          >
            Teoria
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'training' })"
            color="primary"
          >
            Metodyka
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'app' })"
            color="primary"
          >
            Platforma
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'recordedLesson' })"
            color="primary"
          >
            Nagrana lekcja
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'office' })"
            color="primary"
          >
            Sekretariat
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'fullProcess' })"
            color="accent"
          >
            Pełny proces
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'processPiece' })"
            color="accent"
          >
            Element procesu
          </button>
          <button
            mat-stroked-button
            (click)="addTag({ value: 'instruction' })"
            color="accent"
          >
            Instrukcja
          </button>
        </div>
        <mat-form-field fxFlex appearance="outline">
          <mat-label>tagi</mat-label>
          <mat-chip-list #chipList>
            <mat-chip
              selected
              removable
              *ngFor="let tag of activityTags"
              (removed)="removeTag(tag)"
              >{{ tag?.tag }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              #input
              placeholder="Dodaj tag..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addTag($event); input.value = null"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div *ngIf="type == 'activities' && editMode" fxLayout="column">
        <mat-divider></mat-divider>
        <span class="mat-h1">Grupy wiekowe</span>
        <mat-divider class="mb-3"></mat-divider>
        <div
          fxFlex
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
        >
          <div *ngFor="let ageGroup of ageGroups" fxFlex="31" fxFlex.lt-md="31">
            <button
              (click)="
                editMode ? (ageGroup.selected = !ageGroup.selected) : ageGroup
              "
              *ngIf="!ageGroup.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-stroked-button
            >
              {{ ageGroup.value }}
            </button>
            <button
              (click)="ageGroup.selected = !ageGroup.selected"
              *ngIf="ageGroup.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-flat-button
            >
              {{ ageGroup.value }}
            </button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-2"></mat-divider>
        <span class="mat-h1">Liczba uczniów</span>
        <mat-divider class="mb-3"></mat-divider>
        <div
          fxFlex
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
        >
          <div
            *ngFor="let numberOfStudents of numbersOfStudents"
            fxFlex="31"
            fxFlex.lt-md="31"
          >
            <button
              (click)="
                editMode
                  ? (numberOfStudents.selected = !numberOfStudents.selected)
                  : numberOfStudents
              "
              *ngIf="!numberOfStudents.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-stroked-button
            >
              {{ numberOfStudents.value }}
            </button>
            <button
              (click)="
                editMode
                  ? (numberOfStudents.selected = !numberOfStudents.selected)
                  : numberOfStudents
              "
              *ngIf="numberOfStudents.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-flat-button
            >
              {{ numberOfStudents.value }}
            </button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-2"></mat-divider>
        <span class="mat-h1">Czas Ćwiczenia</span>
        <mat-divider class="mb-3"></mat-divider>
        <div
          fxFlex
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
        >
          <div
            *ngFor="let timeOfActivity of timesOfActivity"
            fxFlex="31"
            fxFlex.lt-md="31"
          >
            <button
              (click)="
                editMode
                  ? (timeOfActivity.selected = !timeOfActivity.selected)
                  : timeOfActivity
              "
              *ngIf="!timeOfActivity.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-stroked-button
            >
              {{ timeOfActivity.value }}
            </button>
            <button
              (click)="
                editMode
                  ? (timeOfActivity.selected = !timeOfActivity.selected)
                  : timeOfActivity
              "
              *ngIf="timeOfActivity.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-flat-button
            >
              {{ timeOfActivity.value }}
            </button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-2"></mat-divider>
        <span class="mat-h1">Umiejętności</span>
        <mat-divider class="mb-3"></mat-divider>
        <div
          fxFlex
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="5px"
        >
          <div *ngFor="let skill of skills" fxFlex="31" fxFlex.lt-md="31">
            <button
              (click)="editMode ? (skill.selected = !skill.selected) : skill"
              *ngIf="!skill.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-stroked-button
            >
              {{ skill.value }}
            </button>
            <button
              (click)="editMode ? (skill.selected = !skill.selected) : skill"
              *ngIf="skill.selected"
              class="mb-1"
              color="primary"
              fxFlex
              mat-flat-button
            >
              {{ skill.value }}
            </button>
          </div>
        </div>

        <mat-divider class="mb-2 mt-2"></mat-divider>
      </div>
      <div *ngIf="editMode" fxLayout="row">
        <span class="mat-h1">Opis</span>
      </div>
      <mat-form-field appearance="outline" *ngIf="editMode">
        <mat-label>Dodaj obrazek (link z Google Drive)</mat-label>
        <input #picture type="text" matInput />
        <mat-icon
          style="cursor: pointer"
          (click)="addPictureLink(picture.value); picture.value = null"
          matSuffix
          >add
        </mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="editMode">
        <mat-label>Dodaj video (link z Google Drive)</mat-label>
        <input #video type="text" matInput />
        <mat-icon
          style="cursor: pointer"
          (click)="addVideoLink(video.value); video.value = null"
          matSuffix
          >add
        </mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="editMode">
        <mat-label>Dodaj pdf (link z DO)</mat-label>
        <input #pdf type="text" matInput />
        <mat-icon
          style="cursor: pointer"
          (click)="addPdfLink(pdf.value); pdf.value = null"
          matSuffix
          >add
        </mat-icon>
      </mat-form-field>
      <input
        *ngIf="editMode"
        type="number"
        placeholder="Częstotliwość przypominania (dni)"
        [(ngModel)]="activitiesFrequency"
        id=""
      />
      <div style="gap: 15px" *ngIf="editMode" class="d-flex flex-row w-100">
        <button mat-raised-button (click)="generteDescription()">
          Generuj opis
        </button>
        <button mat-raised-button (click)="generteGrammar()">
          Generuj gramatykę
        </button>
        <button mat-raised-button (click)="formatDescription()">
          Formatuj opis
        </button>
        <button mat-raised-button (click)="generateAudio()">Nagraj opis</button>
        <button
          *ngIf="userDetailsService.owner"
          mat-raised-button
          (click)="resetAllTrackingData()"
        >
          reset tracking data
        </button>
        <span style="width: 30vw"
          >Nagrywanie dostępne jest tylko w trybie edycji, nie przy dodawaniu
          nowego</span
        >
        <audio *ngIf="showAudio" [src]="activitiesAudios" controls></audio>
      </div>
      <angular-editor
        *ngIf="editMode"
        #angularEditor
        [config]="editorConfig"
        [placeholder]="'Wpisz treść wiadomości'"
        [(ngModel)]="activityDescription"
      ></angular-editor>
      <!-- <mat-form-field class="mt-3" *ngIf="editMode" appearance="outline">

        <textarea
          matTextareaAutosize
          [matAutosizeMinRows]="2"
          [(ngModel)]="activityDescription"
          matInput
        ></textarea>
      </mat-form-field> -->
      <span
        style="margin-top: 65px"
        [innerHTML]="getSafeActivityDescription()"
        class="mat-h3 activity-content-wrapper"
        *ngIf="!editMode"
      ></span>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div
          fxLayout="column"
          class="p-2 mt-1"
          style="border: 2px solid #673ab7; border-radius: 10px"
          *ngFor="let picture of activitiesPicturesIds"
        >
          <!-- <iframe
                        [src]="getSafeUrl(picture)"
                        width="130"
                        allow="autoplay"
                    ></iframe> -->
          <!-- <img
                        (click)="openBigPictureDialog(picture)"
                        width="130px"
                        [src]="getSafeUrl(picture)"
                        alt
                     /> -->
          <img
            *ngIf="picture?.length < 50"
            (click)="openBigPictureDialog(picture)"
            width="130px"
            [src]="
              picture !== null
                ? getSafeUrl(
                    '
                        https://backend-int.linget.it/api/google/get-file-by-id/' +
                      picture
                  )
                : null
            "
            alt
          />
          <button
            (click)="removePicture(picture)"
            *ngIf="editMode"
            mat-stroked-button
            color="warn"
            style="position: relative; right: 5px; top: 5px"
          >
            Usuń
          </button>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div
          fxLayout="column"
          class="p-2 mt-1"
          style="border: 2px solid #ffa000; border-radius: 10px"
          *ngFor="let video of activitiesVideosIds"
        >
          <video (ended)="setTrackingData()" width="320" height="240" controls>
            <source
              [src]="
                'https://backend-dev.linget.it/api/google/get-file-by-id/' +
                video
              "
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <button
            (click)="removeVideo(video)"
            *ngIf="editMode"
            mat-stroked-button
            color="warn"
            style="position: relative; right: 5px; top: 5px"
          >
            Usuń
          </button>
        </div>
        <div
          fxLayout="column"
          class="p-2 mt-1"
          style="border: 2px solid #26a69a; border-radius: 10px"
          *ngFor="let pdf of parseddActivityPdfs; let i = index"
        >
          <!-- <ngx-extended-pdf-viewer
                        [src]="pdf?.link"
                        useBrowserLocale="true"
                    ></ngx-extended-pdf-viewer> -->
          <pdf-viewer
            [src]="{
              url: pdf?.link,
              withCredentials: false
            }"
            [zoom-scale]="zoomScale"
            [zoom]="pdfZoom"
            [render-text]="true"
            [fit-to-page]="false"
            [original-size]="false"
            [page]="pdf?.page"
            style="width: 90vw; height: 70vh"
            [show-all]="false"
            (after-load-complete)="pageRendered($event, pdf)"
          ></pdf-viewer>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-stroked-button (click)="decreasePdfPage(pdf)">
              Poprzednia
            </button>
            <span class="ml-3 mr-3">{{ pdf.page }}/{{ pdf?.totalPages }}</span>
            <button mat-stroked-button (click)="increasePdfPage(pdf)">
              Następna
            </button>
            <button mat-icon-button (click)="pdfZoom = pdfZoom - 0.1">
              <mat-icon>remove</mat-icon>
            </button>
            <span class="ml-2 mr-2">Zoom:{{ pdfZoom | number: "1.0-1" }}</span>
            <button mat-icon-button (click)="pdfZoom = pdfZoom + 0.1">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <button
            (click)="removePdf(pdf)"
            *ngIf="editMode"
            mat-stroked-button
            color="warn"
            style="position: relative; right: 5px; top: 5px"
          >
            Usuń
          </button>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column" *ngIf="type !== 'office'">
          <div
            fxLayout="column"
            fxFlex
            fxLayoutAlign="start center"
            class="mb-2 mat-h3"
          >
            <span *ngIf="variants?.length > 0" class="mat-h1 mb-0"
              >Warianty
            </span>
            <!-- <div fxFlex></div> -->
            <!-- <button
                            *ngIf="editMode"
                            (click)="variants.push(getEmptyVariant())"
                            mat-icon-button
                            color="primary"
                        >
                            <mat-icon>add_circle_outline</mat-icon>
                        </button> -->
          </div>
          <div *ngIf="type == 'activities'">
            <div fxLayoutAlign="start start" fxFlex>
              <div *ngIf="variants?.length > 0" class="variants-container">
                <div
                  fxLayout="column"
                  *ngFor="let variant of variants"
                  class="variant-container"
                >
                  <button (click)="removeVariant(variant)">Usuń wariant</button>
                  <div
                    class="mt-3"
                    *ngIf="editMode"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                  >
                    <!-- edit mode content -->
                  </div>
                  <angular-editor
                    *ngIf="editMode"
                    [config]="editorConfig"
                    [placeholder]="'Wpisz treść wiadomości'"
                    [(ngModel)]="variant.value"
                  ></angular-editor>
                  <div
                    class="variant-content"
                    style="display: flex; flex-direction: column"
                  >
                    <mat-chip-list class="mt-2 mb-2">
                      <mat-chip color="primary" selected *ngIf="!editMode"
                        >{{ variant.name }}
                      </mat-chip>
                    </mat-chip-list>
                    <span class="mat-h4 ml-4" *ngIf="!editMode"
                      >{{ variant.value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" *ngIf="type !== 'office'">
            <div
              fxLayout="row"
              fxFlex
              fxLayoutAlign="start center"
              class="mb-2 mat-h3 mt-2"
            >
              <span *ngIf="hints?.length > 0" class="mat-h1 mb-0"
                >Wskazówki
              </span>
              <!-- <div fxFlex></div> -->
              <!-- <button
                                *ngIf="editMode"
                                (click)="hints.push(getEmptyHint())"
                                mat-icon-button
                                color="primary"
                            >
                                <mat-icon>add_circle_outline</mat-icon>
                            </button> -->
            </div>
          </div>
          <div fxLayout="column" *ngIf="type !== 'office'">
            <div
              fxLayoutAlign="start start"
              fxLayout="column"
              *ngFor="let hint of hints"
            >
              <button (click)="removeHint(hint)">Usuń wskazówkę</button>
              <div
                fxLayout="column"
                fxLayoutGap.gt-sm="10px"
                fxLayoutAlign="center start"
                fxFlex
                fxLayout.gt-sm="row"
              >
                <div
                  class="mt-3"
                  *ngIf="editMode"
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <mat-form-field
                    style="margin-bottom: -1.25em"
                    appearance="outline"
                  >
                    <mat-label>Nazwa</mat-label>
                    <input
                      fxFlex
                      [(ngModel)]="hint.name"
                      matInput
                      type="text"
                    />
                  </mat-form-field>
                  <button
                    *ngIf="editMode"
                    (click)="removeHint(hint)"
                    fxHide
                    fxShow.lt-md
                    color="warn"
                    mat-icon-button
                    class="ml-2"
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <mat-form-field
                    *ngIf="editMode"
                    fxFlex
                    appearance="outline"
                    class="mt-3"
                  >
                    <mat-select
                      placeholder="Rodzaj"
                      [(ngModel)]="hint.type"
                      appearance="outline"
                    >
                      <mat-option value="Polecam">Polecam</mat-option>
                      <mat-option value="Nie polecam">Nie polecam</mat-option>
                      <mat-option value="Neutralna">Neutralna</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    *ngIf="editMode"
                    (click)="removeHint(hint)"
                    fxHide
                    fxShow.gt-sm
                    color="warn"
                    mat-icon-button
                    class="ml-2 mb-4"
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
              </div>
              <mat-form-field
                style="width: 100%"
                fxFlex
                *ngIf="editMode"
                appearance="outline"
              >
                <mat-label>{{ hint?.name }}</mat-label>
                <textarea
                  matTextareaAutosize
                  [matAutosizeMinRows]="2"
                  fxFlex
                  [(ngModel)]="hint.value"
                  matInput
                ></textarea>
              </mat-form-field>
              <mat-chip-list class="mt-2 mb-2" *ngIf="!editMode">
                <mat-chip color="primary" selected>{{ hint.name }} </mat-chip>
                <mat-chip color="accent" selected>{{ hint.type }} </mat-chip>
              </mat-chip-list>
              <span class="mat-h4 ml-4" *ngIf="!editMode"
                >{{ hint.value }}
              </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
              <span *ngIf="comments?.length > 0" class="mat-h1 mb-0"
                >Komentarze
              </span>
              <!-- <button
                                *ngIf="editMode"
                                (click)="this.comments.unshift(getEmptyComment())"
                                mat-icon-button
                                color="primary"
                            >
                                <mat-icon>add_circle_outline</mat-icon>
                            </button> -->
            </div>
            <div
              fxLayoutAlign="start start"
              fxFlex
              fxLayout="column"
              *ngFor="let comment of comments; let i = index"
            >
              <div
                fxLayout="row"
                style="width: 100%"
                fxFlex
                fxLayoutAlign="start center"
              >
                <mat-form-field
                  class="mt-3"
                  style="width: 100%"
                  fxFlex
                  *ngIf="editMode"
                  appearance="outline"
                >
                  <textarea
                    matTextareaAutosize
                    [matAutosizeMinRows]="2"
                    fxFlex
                    [(ngModel)]="comment.value"
                    matInput
                  ></textarea>
                </mat-form-field>
                <div fxLayout="column" *ngIf="!editMode">
                  <mat-chip-list class="mt-2 mb-2" *ngIf="!editMode">
                    <mat-chip>{{ comment.author.name }}</mat-chip>
                  </mat-chip-list>
                  <span class="mat-h3 ml-4">{{ comment.value }}</span>
                </div>
                <button
                  (click)="removeComment(comment)"
                  class="mb-4"
                  *ngIf="comment.author.id == activityAuthor.id && editMode"
                  mat-icon-button
                  color="warn"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="type == 'office'" fxLayout="column">
          <div
            fxLayout="row"
            fxFlex
            fxLayoutAlign="start center"
            class="mb-2 mat-h3 mt-2"
          >
            <span *ngIf="steps?.length > 0" class="mat-h1 mb-0">Kroki </span>
            <!-- <div fxFlex></div> -->
            <button
              *ngIf="editMode"
              (click)="steps.push(getEmptyStep())"
              mat-icon-button
              color="primary"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
          <div
            fxLayoutAlign="start start"
            fxLayout="column"
            *ngFor="let step of steps"
          >
            <div
              fxLayout="column"
              fxLayoutGap.gt-sm="10px"
              fxLayoutAlign="center start"
              fxFlex
              fxLayout.gt-sm="row"
            >
              <div
                class="mt-3"
                *ngIf="editMode"
                fxFlex
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-form-field
                  style="margin-bottom: -1.25em"
                  appearance="outline"
                >
                  <mat-label>Nazwa</mat-label>
                  <input fxFlex [(ngModel)]="step.name" matInput type="text" />
                </mat-form-field>
                <button
                  *ngIf="editMode"
                  (click)="removeStep(step)"
                  fxHide
                  fxShow.lt-md
                  color="warn"
                  mat-icon-button
                  class="ml-2"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field
                  *ngIf="editMode"
                  fxFlex
                  appearance="outline"
                  class="mt-3"
                >
                  <mat-select
                    placeholder="Rodzaj"
                    [(ngModel)]="step.type"
                    appearance="outline"
                  >
                    <mat-option value="text">Tekst</mat-option>
                    <mat-option value="checkbox">checkbox</mat-option>
                    <mat-option value="activity">activity</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  *ngIf="editMode && step.type == 'activity'"
                  fxFlex
                  appearance="outline"
                  class="mt-3"
                >
                  <mat-select
                    placeholder="Źródło"
                    [(ngModel)]="step.source"
                    appearance="outline"
                  >
                    <mat-option
                      *ngFor="let activity of data?.currentActivities"
                      [value]="activity?.id"
                      >{{ activity?.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  *ngIf="editMode"
                  (click)="removeStep(step)"
                  fxHide
                  fxShow.gt-sm
                  color="warn"
                  mat-icon-button
                  class="ml-2 mb-2"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
                <button
                  *ngIf="editMode && step.type !== 'activity'"
                  (click)="editStepsValue(step)"
                  fxHide
                  fxShow.gt-sm
                  color="primary"
                  mat-icon-button
                  class="ml-2 mb-2"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
            <angular-editor
              *ngIf="editMode && step.edit"
              [config]="editorConfig"
              [placeholder]="step?.name"
              [(ngModel)]="step.value"
            ></angular-editor>
            <!-- <mat-form-field
                            *ngIf="step?.type !== 'activity' && editMode"
                            style="width: 100%;"
                            fxFlex
                            appearance="outline"
                        >
                            <mat-label>{{step?.name}}</mat-label>
                            <textarea
                                matTextareaAutosize
                                [matAutosizeMinRows]="2"
                                fxFlex
                                [(ngModel)]="step.value"
                                matInput
                            ></textarea>

                        </mat-form-field> -->
            <div
              fxLayout="row"
              style="width: 100%"
              class="mt-2 mb-2"
              *ngIf="!editMode"
            >
              <span
                style="
                  border-radius: 10px;
                  background-color: #673ab7;
                  color: white;
                "
                class="p-1 pl-2 pr-2"
              >
                <div fxLayoutAlign="center center" fxLayout="row">
                  <span class="material-symbols-outlined mr-2">label</span>
                  <span>{{ step.name }}</span>
                  <!-- <button
                                        (click)="openActivity(step)"
                                        *ngIf="step.type == 'activity'"
                                        mat-icon-button
                                    >
                                        <mat-icon>visibility</mat-icon>
                                    </button> -->
                </div>
              </span>
              <div fxFlex></div>
              <!-- <span
                                fxLayoutAlign="center center"
                                style="border-radius:10px; background-color:#ffc107; "
                                class="p-1 pl-2 pr-2"
                            >{{step.type}}
                            </span> -->
            </div>
            <span
              [innerHtml]="step.value"
              class="mat-h4 ml-4"
              *ngIf="(!editMode || !step.edit) && step?.type !== 'activity'"
            >
            </span>
            <span
              [innerHtml]="getSteps(step?.source)?.description"
              class="mat-h4 ml-4"
              *ngIf="step.type == 'activity'"
            >
            </span>
            <div
              class="mt-1"
              fxFlex
              *ngIf="step?.type == 'activity' && step?.source"
            >
              <span
                class="mb-2 ml-4"
                *ngFor="let step of getSteps(step?.source).steps; let i = index"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div class="mr-3" style="font-size: 1.3em">
                  {{ i + 1 + ". " }}
                </div>
                <div fxLayout="column">
                  <span style="font-weight: bold; color: #673ab7">{{
                    step.name
                  }}</span>
                  <span
                    class="mat-small"
                    [innerHTML]="
                      step.value
                        ? step.value
                        : getSteps(step?.source)?.description
                    "
                  ></span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 15vh; background-color: transparent"></div>
    <div class="activity-button-container">
      <button
        fxFlex
        *ngIf="addMode"
        (click)="closeSheet(); addNewActivity()"
        mat-flat-button
        color="primary"
      >
        Dodaj ćwiczenie
      </button>
      <button fxFlex (click)="closeSheet()" mat-flat-button color="accent">
        Zamknij
      </button>
      <button
        fxFlex
        mat-flat-button
        color="primary"
        (click)="generateAudio()"
        [disabled]="isRecording"
        *ngIf="hasForTrainingTag()"
      >
        {{ isRecording ? "Nagrywanie..." : "Nagraj opis" }}
      </button>

      <button
        fxFlex
        *ngIf="
          !editMode &&
          !addMode &&
          (userDetailsService.owner ||
            userDetailsService.office ||
            userDetailsService.getSimpleUserDetails()?.name == 'Karolina Frasz')
        "
        (click)="setEditMode()"
        mat-flat-button
        color="primary"
      >
        <mat-icon aria-label="label">edit</mat-icon>
        <span>Edytuj</span>
      </button>
      <button
        fxFlex
        *ngIf="editMode && !addMode"
        (click)="editFinished()"
        mat-flat-button
        color="primary"
      >
        <mat-icon aria-label="label">done</mat-icon>
        <span>Zapisz</span>
      </button>
    </div>

    <!-- </div> -->
  </div>
</div>
