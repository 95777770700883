import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadTextService } from 'src/app/shared/services/read-text.service';
import { ChatbotService } from './chatbot.service';
import { ChatbotSpeakComponent } from './chatbot-speak/chatbot-speak.component';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {
  @Input() chatItems
  conversation
  readingFinishedSub
  reading = false;
  botName: string = 'Tom'
  yourName: string = 'Agnieszka'
  yourAvatar: string = 'https://picsum.photos/200'
  botAvatar: string = 'https://picsum.photos/201'
  questionNumber = 0
  conversationNumber = 0
  welcomeQuestions = []
  chosenBg = 'https://drive.google.com/file/d/1xVRG7EVG_M4UuJfWK3jze1f2But3unFC/view?usp=sharing'
  @ViewChild('asd') asd: ElementRef
  constructor(
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private readTextService: ReadTextService,
    private helpers: HelpersService) { }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.readText(this.conversation[this.questionNumber].item.qa.question1.question)
  }
  ngOnInit(): void {
    this.yourAvatar = this.helpers.convertGoogleDriveLink(this.chatbotService.getRandomWomensAvatar())
    this.botAvatar = this.helpers.convertGoogleDriveLink(this.chatbotService.getRandomWomensAvatar())
    this.chosenBg = this.helpers.convertGoogleDriveLink(this.chosenBg)
    this.readingFinishedSub = this.readTextService.readingFinishedIndicator().subscribe(finished => {
      this.reading = false

    })
    this.conversation = []
    const botNameSentence = this.helpers.getRandomItemFromArray(this.chatbotService.women_names)
    this.botName = botNameSentence.replace("My name's ", "").replace("I'm ", "").replace(".", "")
    const firstQuestion = this.chatbotService.createQuestion(this.chatbotService.my_name, this.chatbotService.name_question, 2)
    this.welcomeQuestions.push(firstQuestion)
    const botGreetings = this.chatbotService.createQuestion([botNameSentence], this.chatbotService.nice_to_meet_too, 4, true)
    this.welcomeQuestions.push(botGreetings)
    const niceToMeetToo = this.chatbotService.createQuestion(this.chatbotService.us_places, this.chatbotService.english, 4, false, true)
    this.welcomeQuestions.push(niceToMeetToo)
    this.chatItems[0].item.qa.question1.question = this.chatbotService.addToFirstQuestionFromSet() + ' ' + this.chatItems[0].item.qa.question1.question
    this.chatItems = this.welcomeQuestions.concat(this.chatItems)
    this.chatItems.push(this.chatbotService.createLeavingAcceptingAndThankingQuestion())
    this.chatItems.push(this.chatbotService.createWelcomeAndAgainQuestion)
    this.conversation.push(this.chatItems[this.questionNumber])

    setTimeout(() => {


    }, 1000)

  }
  scrollToBottom(): void {
    try {
      this.asd.nativeElement.scrollTop = this.asd.nativeElement.scrollHeight;
    } catch (err) { }
  }
  openChatbotSpeakPanel(text) {
    const dialogRef = this.dialog.open(ChatbotSpeakComponent, {
      data: { text: text }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (this.conversationNumber < (this.chatItems.length * 2) - 1 && this.conversation.length > 7) {
        if (result == 'correctAnswer') {
          if (this.chatItems[this.questionNumber + 1]) {
            const answer = this.chatItems[this.questionNumber].item.qa.answers.find(answer =>
              answer.text = text
            )
            if (!answer?.positive && !answer?.negative) {
              this.chatItems[this.questionNumber + 1] = this.chatbotService.addReactionToQuestion(this.chatItems[this.questionNumber + 1], 'neutral')
            }
            if (answer?.positive) {
              this.chatItems[this.questionNumber + 1] = this.chatbotService.addReactionToQuestion(this.chatItems[this.questionNumber + 1], 'positive')
            }
            if (answer?.negative) {
              this.chatItems[this.questionNumber + 1] = this.chatbotService.addReactionToQuestion(this.chatItems[this.questionNumber + 1], 'negative')
            }

          }
        }
      }
      else {
      }

      if (this.chatItems[this.questionNumber + 1]) {
        this.questionNumber++
        this.conversationNumber++
        this.conversation.push(this.chatItems[this.questionNumber])
        this.readText(this.chatItems[this.questionNumber].item.qa.question1.question)

      }
      else {
        alert("koniec")
      }

    })

  }

  answerChosen(text) {
    if (this.chatItems[this.questionNumber + 1]) {

      this.conversation.push({ selectedAnswer: text.text })
      this.readText(text.text)
      this.conversationNumber++
    }

  }
  readText(text) {
    this.reading = true
    this.readTextService.readText(text, null, 1, null, false, null, false, false, false, false)
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.readingFinishedSub.unsubscribe();
  }
}
