import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoMakerVariablesService {
  introTime = 13500
  propertiesToDownload = ['wordAudio', 'sentenceAudio', 'descriptionAudio', 'blankAudio', 'smallPicture', 'wordPolishAudio', 'sentencePolishAudio']
  translationsToDownload = ['German', 'Spanish', 'PortugueseBrazil', 'Turkish', 'French', 'Polish', 'Vietnamese', 'Thai', 'Indonesian', 'Hindi']
  exercisesTexts = [
    {
      pdfName: 'sentences-text-make-sentences-with-translations',
      langs: [
        { lang: 'English', text: 'Order the chunk of text to create sentences' },
        { lang: 'German', text: 'Ordnen Sie die Textblöcke, um Sätze zu bilden' },
        { lang: 'Spanish', text: 'Ordena los fragmentos de texto para crear oraciones' },
        { lang: 'French', text: 'Organisez les morceaux de texte pour créer des phrases' },
        { lang: 'Polish', text: 'Uporządkuj fragmenty tekstu, aby utworzyć zdania' },
        { lang: 'PortugueseBrazil', text: 'Organize os pedaços de texto para criar frases' },
        { lang: 'Turkish', text: 'Metin parçalarını sıralayarak cümleler oluşturun' },
        { lang: 'Vietnamese', text: 'Sắp xếp các mảnh văn bản để tạo thành câu' },
        { lang: 'Thai', text: 'จัดเรียงข้อความเพื่อสร้างประโยค' },
        { lang: 'Indonesian', text: 'Susun potongan teks untuk membuat kalimat' },
        { lang: 'Hindi', text: 'वाक्य बनाने के लिए पाठ के टुकड़ों को व्यवस्थित करें' }
      ]
    },
    {
      pdfName: 'translations-from-text-pdf',
      langs: [
        { lang: 'English', text: 'Translate the underlined words from the text' },
        { lang: 'German', text: 'Übersetzen Sie die unterstrichenen Wörter aus dem Text' },
        { lang: 'Spanish', text: 'Traduce las palabras subrayadas del texto' },
        { lang: 'French', text: 'Traduisez les mots soulignés du texte' },
        { lang: 'Polish', text: 'Przetłumacz podkreślone słowa z tekstu' },
        { lang: 'PortugueseBrazil', text: 'Traduza as palavras sublinhadas do texto' },
        { lang: 'Turkish', text: 'Metindeki altı çizili kelimeleri çevirin' },
        { lang: 'Vietnamese', text: 'Dịch các từ được gạch chân trong văn bản' },
        { lang: 'Thai', text: 'แปลคำที่ขีดเส้นใต้จากข้อความ' },
        { lang: 'Indonesian', text: 'Terjemahkan kata-kata yang digarisbawahi dari teks' },
        { lang: 'Hindi', text: 'पाठ से रेखांकित शब्दों का अनुवाद करें' }
      ]
    },
    {
      pdfName: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
      langs: [
        { lang: "English", text: "Fill in the missing words" },
        { lang: "German", text: "Füllen Sie die fehlenden Wörter aus" },
        { lang: "Spanish", text: "Complete las palabras que faltan" },
        { lang: "French", text: "Complétez les mots manquants" },
        { lang: "Polish", text: "Uzupełnij brakujące słowa" },
        { lang: "PortugueseBrazil", text: "Preencha as palavras que faltam" },
        { lang: "Turkish", text: "Eksik kelimeleri doldurun" },
        { lang: "Vietnamese", text: "Điền vào các từ còn thiếu" },
        { lang: "Thai", text: "ใส่คำที่ขาดหายไป" },
        { lang: "Indonesian", text: "Isi kata-kata yang hilang" },
        { lang: "Hindi", text: "गायब शब्दों को भरें" }
      ]
    },
    {
      pdfName: "sentences-picture-dictionary-single-20",
      langs: [
        { lang: "English", text: "Match the words and the pictures. Write the right numbers next to English words." },
        { lang: "German", text: "Ordnen Sie die Wörter den Bildern zu. Schreiben Sie die richtigen Nummern neben die deutschen Wörter." },
        { lang: "Spanish", text: "Empareje las palabras con las imágenes. Escriba los números correctos al lado de las palabras en español." },
        { lang: "French", text: "Associez les mots aux images. Écrivez les bons numéros à côté des mots en français." },
        { lang: "Polish", text: "Dopasuj słowa do obrazków. Napisz odpowiednie numery obok polskich słów." },
        { lang: "PortugueseBrazil", text: "Associe as palavras às imagens. Escreva os números corretos ao lado das palavras em português." },
        { lang: "Turkish", text: "Kelimeleri resimlerle eşleştirin. Türkçe kelimelerin yanına doğru numaraları yazın." },
        { lang: "Vietnamese", text: "Ghép các từ với hình ảnh. Viết số đúng bên cạnh các từ tiếng Việt." },
        { lang: "Thai", text: "จับคู่คำกับรูปภาพ ใส่ตัวเลขที่ถูกต้องข้างคำภาษาไทย." },
        { lang: "Indonesian", text: "Cocokkan kata-kata dengan gambar. Tulis nomor yang benar di samping kata-kata dalam bahasa Indonesia." },
        { lang: "Hindi", text: "शब्दों को चित्रों के साथ मिलाएँ। हिंदी शब्दों के पास सही नंबर लिखें।" }
      ]
    },
    {
      pdfName: "sentences-fill-gaps-clues",
      langs: [
        {
          lang: "English",
          text: "Fill in the gaps with right words"
        },
        {
          lang: "German",
          text: "Füllen Sie die Lücken mit den richtigen Wörtern aus"
        },
        {
          lang: "Spanish",
          text: "Rellena los huecos con las palabras correctas"
        },
        {
          lang: "French",
          text: "Remplissez les lacunes avec les bons mots"
        },
        {
          lang: "Polish",
          text: "Wypełnij luki odpowiednimi słowami"
        },
        {
          lang: "PortugueseBrazil",
          text: "Preencha as lacunas com as palavras certas"
        },
        {
          lang: "Turkish",
          text: "Boşlukları doğru kelimelerle doldurun"
        },
        {
          lang: "Vietnamese",
          text: "Điền vào chỗ trống với các từ đúng"
        },
        {
          lang: "Thai",
          text: "เติมคำที่ถูกต้องลงในช่องว่าง"
        },
        {
          lang: "Indonesian",
          text: "Isi celah-celah dengan kata yang tepat"
        },
        {
          lang: "Hindi",
          text: "सही शब्दों से रिक्त स्थान भरें"
        }
      ]
    }
  ];


  constructor() { }

}
