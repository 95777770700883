import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as hljs from 'highlight.js';
import 'highlight.js/styles/dracula.css';
@Component({
  selector: 'app-code-block',
  templateUrl: './code-block.component.html',
  styleUrls: ['./code-block.component.scss']
})
export class CodeBlockComponent implements OnInit {
  @Input() content: string;
  @Input() language
  @ViewChild('codeElement', { static: true }) codeElement!: ElementRef;
  code: string = '';

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.extractCode();
  }

  ngAfterViewInit() {
    this.highlightCode();
  }

  extractCode() {
    const codeRegex = /```(\w+)?\s*([\s\S]*?)```/;
    const match = this.content.match(codeRegex);

    if (match) {
      this.code = match[2].trim();
      this.language = match[1] || 'plaintext';
    } else {
      this.code = this.content.trim();
      // Try to detect language from first line if it starts with ```
      const firstLine = this.content.split('\n')[0];
      if (firstLine.startsWith('```')) {
        this.language = firstLine.slice(3).trim();
        this.code = this.content.slice(firstLine.length).replace(/```$/, '').trim();
      } else {
        this.language = 'plaintext';
      }
    }
  }

  highlightCode() {
    if (this.codeElement) {
      this.codeElement.nativeElement.className = `language-${this.language}`;
      this.codeElement.nativeElement.textContent = this.code;
      hljs.highlightBlock(this.codeElement.nativeElement);
    }
  }

  copyCode() {
    navigator.clipboard.writeText(this.code)
      .then(() => {

        this.snackBar.open('Code copied to clipboard', 'Close', { duration: 2000 });
      })
      .catch(err => {

      });
  }
}
