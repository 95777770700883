<app-sub-menu
  [controls]="controls"
  (text)="incomingText($event)"
  (buttonClicked)="buttonClicked($event)"
></app-sub-menu>

<div class="scrollable-container">
  <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px">
    <mat-card
      fxFlex.sm="0 1 calc(45%-10px)"
      fxFlex.md="0 1 calc(30%-10px)"
      fxFlex.gt-md="0 1 calc(31%-10px)"
      *ngFor="let course of courses"
    >
      <span class="mb-3" fxLayoutAlign="center center">
        <span
          fxLayoutAlign="center center"
          style="
            text-transform: uppercase;
            font-weight: bolder;
            font-size: 1.3em;
          "
          [innerHTML]="course.name | filterPipe: incomingTextFromMenu"
        ></span>
      </span>

      <mat-card-subtitle class="ml-4" fxLayout="column">
        <span fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>description</mat-icon>
          <span
            [innerHTML]="course.description | filterPipe: incomingTextFromMenu"
          ></span>
        </span>
        <span fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-icon>supervisor_account</mat-icon>
          <span
            [innerHTML]="course.forWho | filterPipe: incomingTextFromMenu"
          ></span>
        </span>
      </mat-card-subtitle>

      <mat-card-content>
        <ul>
          <li
            mat-line
            [innerHTML]="course.weeklyFrequency + ' razy w tygodniu'"
          ></li>
          <li mat-line [innerHTML]="course.duration + ' godzin'"></li>
          <li
            mat-line
            [innerHTML]="course.maxStudents + ' uczniów w grupie'"
          ></li>
          <li mat-line [innerHTML]="'Online: ' + course.online"></li>
          <li mat-line [innerHTML]="'Zajęcia grupowe: ' + course.group"></li>
          <li mat-line [innerHTML]="'Cena: ' + course.price + ' zł'"></li>
          <li
            mat-line
            *ngIf="course.additionalPayments.price"
            [innerHTML]="
              'Opłata dodatkowa: ' +
              course.additionalPayments.price +
              ' zł za ' +
              course.additionalPayments.forWhat
            "
          ></li>
        </ul>
      </mat-card-content>

      <mat-card-actions>
        <button
          mat-flat-button
          (click)="openEditCoursePanel(course)"
          class="ml-2 mr-2"
          fxFlex
          color="primary"
        >
          Edytuj
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
