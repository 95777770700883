import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { VersionService } from 'src/app/shared/services/version.service';
import { catchError, map, retry, tap } from 'rxjs/operators';

interface VideoRepo {
  id: string;
  data: {
    fileName: string;
    folderName?: string;
    s3Key: string;
    downloadLink?: string;
    size: number;
    mimetype: string;
    uploadDate: Date;
  };
}

@Injectable({
  providedIn: 'root'
})
export class VideoRepoService {
  private apiUrl = 'https://backend-videos3.linget.it/api/videorepo'; // Adjust this URL to match your backend

  constructor(private http: HttpClient, private versionService: VersionService) {
    // this.apiUrl = this.versionService.url + '/api/videorepo';
  }

  getAllVideos(): Observable<{ success: boolean; videos: VideoRepo[] }> {
    return this.http.get<{ success: boolean; videos: VideoRepo[] }>(this.apiUrl);
  }

  getVideoById(id: string): Observable<{ success: boolean; video: VideoRepo }> {
    return this.http.get<{ success: boolean; video: VideoRepo }>(`${this.apiUrl}/${id}`);
  }

  uploadVideo(file: File, metadata: {
    folderName?: string,
    share?: boolean,
    tags?: string[],
    category?: string
  }): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    if (metadata.folderName) formData.append('folderName', metadata.folderName);
    if (metadata.share !== undefined) formData.append('share', metadata.share.toString());
    if (metadata.tags) formData.append('tags', JSON.stringify(metadata.tags));
    if (metadata.category) formData.append('category', metadata.category);

    return this.http.post(`${this.apiUrl}/upload`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap(event => {

      }),
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / (event.total || file.size));

            return { status: 'progress', message: `Uploaded ${progress}%` };
          case HttpEventType.Response:

            return event.body;
          default:

            return `Unhandled event: ${event.type}`;
        }
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }


  updateVideo(id: string, data: Partial<VideoRepo['data']>): Observable<any> {
    return this.http.put(`${this.apiUrl}/${id}`, { data });
  }

  deleteVideo(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${id}`);
  }
}