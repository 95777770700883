import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UsersServiceService } from '../../../shared/services/users-service.service';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SignupFormService } from 'src/app/shared/services/signup-form.service';
import { Control } from 'src/app/shared/models/controls.model';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { EditComponent } from './edit/edit.component';
import { SignupAddLeadComponent } from './signup-add-lead/signup-add-lead.component';
import { SignupFormComponent } from 'src/app/public-access/signup-form/signup-form.component';
import { UserDetailsService } from '../../user-details.service';
import { SignupStatsComponent } from './signup-stats/signup-stats.component';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PlanTempGroupComponent } from '../../../shared/components/plan-temp-group/plan-temp-group.component';
import { SmallDataService } from 'src/app/shared/services/small-data.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// export interface User {
//   id:string;
//   name: string;
//   email: string;
//   role: string;
//   status:string
// }

// const users  = [];

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class SignupComponent implements OnInit {
  adultsMode;
  allChildren = [];
  decidedMode = false;
  allAdults = [];
  showCustomersData = false;
  incomingTextFromMenu = '';
  newStudents = [];
  children = [];
  adults = [];
  controls: Control[] = [
    {
      text: 'Dorośli',
      color: 'primary',
      type: 'button',
      mode: 'stroked',
      icon: 'people_alt',
    },

    {
      text: 'Dodaj ucznia',
      color: 'primary',
      type: 'button',
      mode: 'stroked',
      icon: 'people_alt',
    },
    // {
    //   text: "Dane klientów",
    //   color: "primary",
    //   type: "button",
    //   mode: "stroked",
    //   icon: "expand",
    // },
    {
      text: 'Statystyki',
      color: 'primary',
      type: 'button',
      mode: 'stroked',
      icon: 'query_stats',
    },
    {
      text: 'Grupa',
      color: 'primary',
      type: 'button',
      mode: 'stroked',
      icon: 'groups',
    },
  ];
  editMode = false;
  index;
  @ViewChild(MatPaginator, {
    static: true,
  })
  paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: true,
  })
  sort: MatSort;
  selection = new SelectionModel(true, []);

  displayedColumns: string[] = [
    'select',
    'groups',
    'date',
    'location',
    'childFirstName',
    'childLastName',
    'childGrade',
    'childLevel',
    'childPreviousClasses',
    'contractPrice',
    'actions',
    'state',
  ];
  @Input() contactRequestsMode = false;
  dataSource = new MatTableDataSource<any>();
  usersSub: Subscription;
  isTableExpanded = false;
  hover = false;
  formControl: any;
  windowMode = false;
  studentUpdatedSub: Subscription;
  constructor(
    private signupService: SignupFormService,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    private smallDataService: SmallDataService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SignupComponent>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.data.windowMode) {
      this.windowMode = true;
    }
    this.studentUpdatedSub = this.signupService.studentUpdated
      .asObservable()
      .subscribe((updated: any) => {
        if (updated.type === 'adult') {
          let adultToUpdate = this.adults.find(
            (adult) => adult.studentId === updated.value.adultId,
          );
          (adultToUpdate.adultFirstName = updated.value.adultFirstName),
            (adultToUpdate.adultLastName = updated.value.adultLastName);
          adultToUpdate.phone = updated.value.phone;
          adultToUpdate.adultEmail = updated.value.adultEmail;
          adultToUpdate.avalibility = updated.value.avalibility;
          adultToUpdate.status = updated.value.status;
          adultToUpdate.weeklyLessons = updated.value.weeklyLessons;
          adultToUpdate.lastYearsPrice = updated.value.lastYearsPrice;
          adultToUpdate.thisYearsPrice = updated.value.thisYearsPrice;
          adultToUpdate.contractPrice = updated.value.contractPrice;
          adultToUpdate.priceAddition = updated.value.priceAddition;
          adultToUpdate.additionalData = updated.value.additionalData;

          let adultToUpdateInAllAdults = this.allAdults.findIndex(
            (adult) =>
              adult.studentId === updated.value.adultId ||
              adult.studentId === updated.value.studentId,
          );
          this.allAdults[adultToUpdateInAllAdults] = adultToUpdate;

          this.adults = this.adults.filter(
            (adult) => !adult.adultFirstName.toLowerCase().includes('removed'),
          );
          this.allAdults = this.adults.filter(
            (adult) => !adult.adultFirstName.toLowerCase().includes('removed'),
          );
          this.dataSource.data = this.adults;
        }
        if (updated.type === 'child') {
          let childToUpdate = this.children.find(
            (child) => child.studentId === updated.value.childId,
          );
          (childToUpdate.childFirstName = updated.value.childFirstName),
            (childToUpdate.childLastName = updated.value.childLastName),
            (childToUpdate.childGrade = updated.value.childGrade),
            (childToUpdate.childLevel = updated.value.childLevel),
            (childToUpdate.childPreviousClasses =
              updated.value.childPreviousClasses),
            (childToUpdate.avalibility = updated.value.avalibility),
            (childToUpdate.additionalData = updated.value.additionalData),
            (childToUpdate.status = updated.value.status),
            (childToUpdate.weeklyLessons = updated.value.weeklyLessons),
            (childToUpdate.lastYearsPrice = updated.value.lastYearsPrice),
            (childToUpdate.contractPrice = updated.value.contractPrice),
            (childToUpdate.priceAddition = updated.value.priceAddition);

          let childToUpdateinAllChildren = this.allChildren.findIndex(
            (child) =>
              child.studentId === updated.value.childId ||
              child.studentId === updated.value.studentId,
          );

          this.allChildren[childToUpdateinAllChildren] = childToUpdate;
          this.dataSource.data = this.children;
        }
        if (updated.type == 'customer') {
          let adultToUpdate = this.adults.find(
            (adult) => adult.customerId === updated.value.customerId,
          );
          if (adultToUpdate) {
            adultToUpdate.customerFirstName = updated.value.customerFirstName;
            adultToUpdate.customerLastName = updated.value.customerLast;
            adultToUpdate.customerEmail = updated.value.customerEmail;
            adultToUpdate.customerPhone = updated.value.customerPhone;
            adultToUpdate.location = updated.value.location;
            let adultToUpdateInAllAdults = this.allAdults.findIndex(
              (adult) => adult.customerId === updated.value.customerId,
            );
            this.allAdults[adultToUpdateInAllAdults] = adultToUpdate;
            this.dataSource.data = this.adults;
          }
          let childToUpdate = this.children.find(
            (child) => child.customerId === updated.value.customerId,
          );
          if (childToUpdate) {
            childToUpdate.customerFirstName = updated.value.customerFirstName;
            childToUpdate.customerLastName = updated.value.customerLast;
            childToUpdate.customerEmail = updated.value.customerEmail;
            childToUpdate.customerPhone = updated.value.customerPhone;
            childToUpdate.location = updated.value.location;

            let childToUpdateinAllChildren = this.allChildren.findIndex(
              (child) => child.customerId === updated.value.customerId,
            );

            this.allChildren[childToUpdateinAllChildren] = childToUpdate;
            this.dataSource.data = this.children;
          }
        }
      });
    this.smallDataService.tempGeoupsUpdatedListener().subscribe((groups) => {
      if (this.children.length > 0 || this.adults.length > 0) {
        this.checkStudentsGroups();
      }
    });
    this.smallDataService.getTempGroups();
    this.usersSub = this.signupService
      .studentsUpdatedListener()
      .subscribe((newStudents) => {
        this.newStudents = newStudents;


        this.incomingTextFromMenu = '';
        this.children = [];
        this.adults = [];

        this.newStudents.forEach((e) => {
          e.students?.forEach((s) => {
            if (s.child || s.childFirstName) {
              const child = {
                lastYearsPrice: s.lastYearsPrice,
                thisYearsPrice: s.thisYearsPrice,
                contractPrice: s.contractPrice,
                priceAddition: s.priceAddition,
                status: s.status,
                weeklyLessons: s.weeklyLessons,

                location: e.location,
                continuing: s.continuing,
                avalibility: s.avalibility,
                internal: e.internal,
                customerFirstName: e.customerFirstName,
                customerLastName: e.customerLastName,
                customerPhone: e.customerPhone,
                customerEmail: e.customerEmail,
                childFirstName: s.childFirstName,
                childLastName: s.childLastName,
                childGrade: s.childGrade,
                childLevel: s.childLevel,
                childPreviousClasses: s.childPreviousClasses,
                additionalData: s.additionalData,
                child: s.child,
                studentId: s.id,
                customerId: e.id,
                weeklyLessonsSpots: [],
              };
              this.children.unshift(child);
            } else {
              if (!s.child || s.adultFirstName) {
                const adult = {
                  lastYearsPrice: s.lastYearsPrice,
                  thisYearsPrice: s.thisYearsPrice,
                  contractPrice: s.contractPrice,
                  avalibility: s.avalibility,
                  priceAddition: s.priceAddition,
                  status: s.status,
                  weeklyLessons: s.weeklyLessons,
                  location: e.location,
                  internal: e.internal,
                  continuing: s.continuing,
                  customerFirstName: e.customerFirstName,
                  customerLastName: e.customerLastName,
                  customerPhone: e.customerPhone,
                  customerEmail: e.customerEmail,
                  adultFirstName: s.adultFirstName,
                  adultLastName: s.adultLastName,
                  adultPhone: s.adultPhone,
                  adultEmail: s.adultEmail,
                  adultAdditionalData: s.adultAdditionalData,
                  additionalData: s.adultAdditionalData,
                  testResults: s.adultTestData,
                  child: s.child,
                  studentId: s.id,
                  customerId: e.id,
                  weeklyLessonsSpots: [],
                };
                this.adults.unshift(adult);
              }
            }
          });
        });
        this.children = this.children.filter(
          (child) => !child?.childFirstName?.toLowerCase().includes('removed'),
        );
        this.adults = this.adults.filter(
          (adult) => !adult.adultFirstName.toLowerCase().includes('removed'),
        );
        if (this.contactRequestsMode) {
          this.children = this.children.filter(
            (child) => !child?.childFirstName && !child?.adultFirstName,
          );
          this.displayedColumns = [
            'customerFirstName',
            'customerPhone',
            'actions',
            'state',
          ];
        } else {
          this.children = this.children.filter(
            (child) => child?.childFirstName,
          );
        }
        this.checkStudentsGroups();



        this.allChildren = this.helpers.clone(this.children);
        this.allAdults = this.helpers.clone(this.adults);
        if (!this.adultsMode) {
          this.dataSource = new MatTableDataSource<any>(this.children);
        } else {
          this.dataSource = new MatTableDataSource<any>(this.adults);
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          // this.openAddTempGroupPanel();
        }, 500);
      });
    this.signupService.getStudentList();
  }
  checkStudentsGroups() {
    this.children.forEach((child) => {
      child.weeklyLessonsSpots = [];
      if (!child.weeklyLessonsSpots || child.weeklyLessonsSpots.length == 0) {
        const promise = new Promise<any>((resolve, reject) => {
          let numberOfGroupsWithChild =
            this.smallDataService.checkNumberOfGroupsForStudent(child.studentId)
              ?.length;
          resolve(
            this.smallDataService.checkNumberOfGroupsForStudent(
              child.studentId,
            ),
          );
        });
        promise.then((groupsWithChild) => {
          child.tempGroups = groupsWithChild;
          for (let index = 0; index < child.weeklyLessons; index++) {
            child.weeklyLessonsSpots.push('toFill');
          }
          for (let index = 0; index < groupsWithChild?.length; index++) {
            child.weeklyLessonsSpots[index] = 'filled';
          }
        });
      }
    });
    this.adults.forEach((adult) => {
      adult.weeklyLessonsSpots = [];

      if (!adult.weeklyLessonsSpots || adult.weeklyLessonsSpots.length == 0) {
        const promise = new Promise<any>((resolve, reject) => {
          let numberOfGroupsWithChild =
            this.smallDataService.checkNumberOfGroupsForStudent(
              adult.studentId,
            );
          resolve(
            this.smallDataService.checkNumberOfGroupsForStudent(
              adult.studentId,
            ),
          );
        });
        promise.then((groupsWithAdult) => {
          adult.tempGroups = groupsWithAdult;

          for (let index = 0; index < adult.weeklyLessons; index++) {
            adult.weeklyLessonsSpots.push('toFill');
          }
          for (let index = 0; index < groupsWithAdult?.length; index++) {
            adult.weeklyLessonsSpots[index] = 'filled';
          }
        });
      }
    });
    setTimeout(() => {
      this.allChildren = this.helpers.clone(this.children);

      this.allAdults = this.helpers.clone(this.adults);
    }, 1000);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  openDialog(studentData) {
    console.log(
      '🚀 ~ file: signup.component.ts:467 ~ SignupComponent ~ openDialog ~ studentData:',
      studentData,
    );
    const dialogRef = this.dialog.open(EditComponent, {
      disableClose: true,
      width: '100vw',
      height: '100vh',
      minHeight: '100vh',
      minWidth: '100vw',
      data: {
        studentData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  incomingText(text) {
    this.incomingTextFromMenu = text;
    console.log(
      '🚀 ~ file: signup.component.ts ~ line 407 ~ SignupComponent ~ incomingText ~  this.formControl',
      this.formControl,
    );
    this.doFilter(text);
  }
  addStudent(student) {
    this.dialogRef.close(student);
  }
  buttonClicked(event) {
    switch (event) {
      case 'Dzieci':
        this.changeAdultsChildrenButton();
        break;
      case 'Dane klientów':
        this.changeDisplayMode();
        break;
      case 'Dodaj ucznia':
        this.openAddLeadPanel();
        break;
      case 'Statystyki':
        this.openStatsPanel();
        break;
      case 'Grupa':
        this.openAddTempGroupPanel();
        break;
      case 'Dorośli':
        this.changeAdultsChildrenButton();
        break;
      case 'Zdecydowani':
        this.setDecidedStudents();
        break;

      default:
        break;
    }
    if (event) {
      console.log(
        '🚀 ~ file: signup.component.ts ~ line 296 ~ SignupComponent ~ buttonClicked ~ event',
        event,
      );
    }
    if (event.selectChanged) {
      this.selection.clear();

      console.log(
        '🚀 ~ file: signup.component.ts ~ line 373 ~ SignupComponent ~ buttonClicked ~ this.allChildren',
        this.allChildren,
      );
      if (this.decidedMode) {
        this.doFilter('decided');
      } else {
        if (event.selectChanged == 'Wszystko') {
          this.doFilter('');
        }
        if (event.selectChanged == 'Bydgoszcz') {
          this.doFilter('Bydgoszcz');
        }
        if (event.selectChanged == 'bialeblota') {
          this.doFilter('bialeblota');
        }
      }
      this.children = this.allChildren.filter(
        (child) => child.location == event.selectChanged,
      );

      this.adults = this.allAdults.filter(
        (adult) => adult.location == event.selectChanged,
      );

      if (!this.adultsMode) {
        this.dataSource = new MatTableDataSource<any>(this.children);
      } else {
        this.dataSource = new MatTableDataSource<any>(this.adults);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (event.selectChanged == 'Wszystko') {
      this.children = this.allChildren;
      this.adults = this.allAdults;
      if (!this.adultsMode) {
        this.dataSource = new MatTableDataSource<any>(this.children);
      } else {
        this.dataSource = new MatTableDataSource<any>(this.adults);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.ref.detectChanges();
  };
  onEditStudentClick(i) {
    this.editMode = true;
    this.index = i;
  }
  setDecidedStudents() {
    if (!this.decidedMode) {
      this.children = this.allChildren.filter(
        (child) => child.status == 'decided',
      );
      this.adults = this.allAdults.filter((adult) => adult.status == 'decided');
      if (!this.adultsMode) {
        this.dataSource = new MatTableDataSource<any>(this.children);
      } else {
        this.dataSource = new MatTableDataSource<any>(this.adults);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.decidedMode = true;
    } else {
      this.children = this.allChildren;

      this.adults = this.allAdults;
      if (!this.adultsMode) {
        this.dataSource = new MatTableDataSource<any>(this.children);
      } else {
        this.dataSource = new MatTableDataSource<any>(this.adults);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.decidedMode = false;
    }
  }
  changeAdultsChildrenButton() {
    this.selection.clear();

    this.adultsMode = !this.adultsMode;
    if (this.adultsMode) {
      this.controls[0].text = 'Dzieci';
      this.dataSource = new MatTableDataSource<any>(this.adults);
      if (this.showCustomersData) {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'customerFirstName',
          'customerLastName',
          'customerPhone',
          'customerEmail',
          'adultFirstName',
          'adultLastName',
          'adultPhone',
          'adultEmail',
          'contractPrice',
          'actions',
          'state',
        ];
      } else {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'adultFirstName',
          'adultLastName',
          'adultPhone',
          'adultEmail',
          'contractPrice',
          'actions',
          'state',
        ];
      }
    }
    if (!this.adultsMode) {
      this.dataSource = new MatTableDataSource<any>(this.children);
      this.controls[0].text = 'Dorośli';
      if (this.showCustomersData) {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'customerFirstName',
          'customerLastName',
          'customerPhone',
          'customerEmail',
          'childFirstName',
          'childLastName',
          'childGrade',
          'childLevel',
          'childPreviousClasses',
          'contractPrice',
          'actions',
          'state',
        ];
      } else {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'childFirstName',
          'childLastName',
          'childGrade',
          'childLevel',
          'childPreviousClasses',
          'contractPrice',
          'actions',
          'state',
        ];
      }
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onRemoveCustomerClick(student) {

    const confirmationText = 'Czy na pewno usunąć potencjalnego ucznia';
    const studentToRemoveName =
      student.adultFirstName?.length > 0
        ? student.adultFirstName
        : student.childFirstName;
    const studentToRemoveLastName =
      student.adultFirstName?.length > 0
        ? student.adultLastName
        : student.childLastName;

    if (
      confirm(
        confirmationText +
        ' ' +
        studentToRemoveName +
        ' ' +
        studentToRemoveLastName +
        '?',
      )
    ) {
      const customerWithStudentToRemove = this.newStudents.find(
        (customer) =>
          customer.students?.some(
            (_student) => _student.id === student.studentId,
          ),
      );
      const studentToRemove = customerWithStudentToRemove.students.find(
        (_student) => _student.id === student.studentId,
      );
      const studentIsAdult = student.adultFirstName?.length > 0;
      studentToRemove.customerId = customerWithStudentToRemove.id;
      if (studentIsAdult) {
        studentToRemove.adultId = studentToRemove.id;

        studentToRemove.adultFirstName =
          studentToRemove.adultFirstName + ' removed';
      } else {
        studentToRemove.childId = studentToRemove.id;

        studentToRemove.childFirstName =
          studentToRemove.childFirstName + ' removed';
      }

      if (studentIsAdult) {
        studentToRemove.adultFirstName =
          studentToRemove.adultFirstName + ' removed';
        studentToRemove.adultId = studentToRemove.id;
        this.signupService.updateAdult(studentToRemove);
      } else {
        studentToRemove.childFirstName =
          studentToRemove.childFirstName + ' removed';
        studentToRemove.childId = studentToRemove.id;
        this.signupService.updateChild(studentToRemove);
      }
      // this.signupService.removeCustomer(student.customerId);
    }
  }
  changeDeleteIcon(i) {
    this.hover = true;
    this.index = i;
  }
  //  edit(i){
  //    this.editMode = true
  //    this.index = i
  //  }
  //  onUserEditFinished(user){
  //    this.editMode = false
  //   this.usersService.updateUser(user)
  //  }
  ngOnDestroy(): void {
    this.usersSub.unsubscribe();
    this.studentUpdatedSub.unsubscribe();
  }

  private openAddLeadPanel() {
    this.dialog.open(SignupFormComponent, {
      data: { internal: true },
      disableClose: true,
    });
  }
  private openStatsPanel() {
    if (this.userDetailsService.getSimpleUserDetails().role === 'owner') {
      this.dialog.open(SignupStatsComponent, {
        data: { adults: this.adults, children: this.children },
        width: '80vw',
        height: '80vh',
      });
    }
  }
  private openAddTempGroupPanel() {
    if (
      this.userDetailsService.getSimpleUserDetails().role === 'owner' ||
      this.userDetailsService.getSimpleUserDetails().role === 'office'
    ) {
      const dialogRef = this.dialog.open(PlanTempGroupComponent, {
        data: { selectedStudents: this.selection.selected },
        width: '80vw',
        height: '80vh',
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.checkStudentsGroups();
      });
    }
  }

  private changeDisplayMode(fromButton?) {
    this.showCustomersData = !this.showCustomersData;
    if (!this.adultsMode) {
      if (this.showCustomersData) {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'customerFirstName',
          'customerLastName',
          'customerPhone',
          'customerEmail',
          'childFirstName',
          'childLastName',
          'childGrade',
          'childLevel',
          'childPreviousClasses',
          'contractPrice',
          'actions',
          'state',
        ];
      } else {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'childFirstName',
          'childLastName',
          'childGrade',
          'childLevel',
          'childPreviousClasses',
          'contractPrice',
          'actions',
          'state',
        ];
      }
    }
    if (this.adultsMode) {
      if (this.showCustomersData) {
        this.displayedColumns = [
          'select',

          'groups',
          'date',
          'location',
          'customerFirstName',
          'customerLastName',
          'customerPhone',
          'customerEmail',
          'adultFirstName',
          'adultLastName',
          'adultPhone',
          'adultEmail',
          'actions',
          'state',
        ];
      } else {
        this.displayedColumns = [
          'select',
          'groups',
          'date',
          'location',
          'adultFirstName',
          'adultLastName',
          'adultPhone',
          'adultEmail',
          'actions',
          'state',
        ];
      }
    }
  }
}
