import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HelpersService } from "src/app/shared/services/helpers.service";
import { BoardgameService } from "src/app/live/boardgame/boardgame.service";

@Component({
  selector: "app-horizontal-race",
  templateUrl: "./horizontal-race.component.html",
  styleUrls: ["./horizontal-race.component.css"],
  animations: [
    trigger("go", [
      // Custom state
      state(
        "normal",
        style({
          opacity: 1,
          transform: "translateX({{positionX}}px) translateY({{positionY}}px) ",
        }),
        { params: { positionX: 0, positionY: 0 } }
      ),
      state(
        "die",
        style({
          opacity: 0.5,
          transform:
            "translateX({{positionX}}px) translateY(1000px) rotate(-180deg)",
        }),
        { params: { positionX: 0, positionY: 0 } }
      ),
      state(
        "init",
        style({
          opacity: 1,
          transform: "translateX({{positionX}}px) translateY({{positionY}}px) ",
        }),
        { params: { positionX: 0, positionY: 0 } }
      ),
      state(
        "next",
        style({
          opacity: 1,
          transform: "translateX({{positionX}}px) translateY({{positionY}}px) ",
        }),
        { params: { positionX: 0, positionY: 0 } }
      ),
      // When the element goes from 'selected' state to whatever...
      transition("init => next", [animate("300ms ease-in")]),
      transition("init => die", [animate("800ms ease-in")]),
    ]),
  ],
})
export class HorizontalRaceComponent implements OnInit {
  @ViewChild("area") area: ElementRef;
  @Input() players;
  @Input() gameObject;
  @Input() numberOfQuestions: number;
  gap = 0;
  width;
  height;
  horizontalGap;
  currentPoint = { position: { x: 0, y: 0 }, id: this.helpers.makeid(6) };
  animationState = "init";
  sub;
  points = [];
  audio = new Audio();
  multiPlayer = {
    replicate: "horizontal",
    players: [],
  };

  margin: number;
  constructor(
    private helpers: HelpersService,
    private boardgameService: BoardgameService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfQuestions) {
      this.numberOfQuestions = changes.numberOfQuestions.currentValue;
    }

    if (changes.players) {
      if (changes.players.firstChange) {
        // setTimeout(() => {
        //   
        //   this.multiPlayer.players = changes.players.currentValue;
        //   this.multiPlayer.players.forEach((player) => {
        //     player.numberOfQuestions = this.numberOfQuestions;
        //     player.path = [];

        //     player.newComer = true;
        //     (player.currentPoint = {}),
        //       (player.animationState = "init"),
        //       (player.prize = "/assets/emojis/1f3c6.svg");
        //   });
        //   console.log(
        //     "🚀 ~ file: horizontal-race.component.ts ~ line 95 ~ HorizontalRaceComponent ~ setTimeout ~ this.gameObject",
        //     this.gameObject
        //   );
        //   this.multiPlayer.players = this.multiPlayer.players.filter(
        //     (player) => player.collaborative
        //   );
        //   this.playersReady();
        // }, 1000);
      }

      if (!changes.players.firstChange) {
        this.multiPlayer.players =
          this.boardgameService.redoPlayersForHorizontalRace(
            changes.players.currentValue,
            this.multiPlayer.players,
            this.numberOfQuestions
          );

        this.playersReady();


        this.multiPlayer.players.forEach((player) => {
          const playersCurrentPosition = player.path.findIndex(
            (p) => p.id === player.currentPoint.id
          );


          if (playersCurrentPosition < player.points) {
            this.addPointToPlayer(player.id, this.multiPlayer.players);
          }
          if (playersCurrentPosition > player.points) {
            this.audio.play();
            this.resetPointsForPlayer(player);
          }
        });
      }
    }

  }
  ngAfterViewInit(): void {
    this.audio.src = "/assets/sounds/fail.wav";
    this.audio.load();
    this.width = this.area.nativeElement.scrollWidth;

    setTimeout(() => {

      this.height = this.area.nativeElement.scrollHeight;

    }, 1500);
  }
  resetPoints() {
    this.multiPlayer.players.forEach((player) => {
      player.currentPoint = player.path[0];
    });
  }
  resetPointsForPlayer(player) {
    this.die(player.id);
    setTimeout(() => {
      player.currentPoint = player.path[0];
    }, 1000);
  }
  playersReady() {



    this.multiPlayer?.players.forEach((player: any, playerIndex) => {
      if (this.gameObject.displayPlayers) {
        player.display = true;
      } else {
        player.display = false;
      }
      if (player.newComer) {
        this.margin = this.width * 0.15;
        this.horizontalGap =
          (this.width - this.margin - 50) / player.numberOfQuestions;
        const lastPoint = this.width - 50;
        // const verticalGap = (this.height / (this.multiPlayer.players.length +1))
        let verticalGap = 60;

        let lower = playerIndex + 1;
        console.log(
          "🚀 ~ file: horizontal-race.component.ts ~ line 151 ~ HorizontalRaceComponent ~ this.multiPlayer?.players.forEach ~ this.gameObject[0]?.commonPath",
          this.gameObject[0]?.commonPath
        );
        if (this.gameObject?.commonPath) {
          verticalGap = 50;
          lower = 1;
        }
        for (let index = 0; index <= player.numberOfQuestions; index++) {
          if (playerIndex === 0) {
            if (index === player.numberOfQuestions) {
              player.path.push({
                position: { x: lastPoint, y: lower * 50 },
                id: this.helpers.makeid(6),
              });
            } else {
              const x = this.margin + index * this.horizontalGap;
              // if(x > lastPoint){
              //   x = lastPoint
              // }
              player.path.push({
                position: { x: x, y: lower * 50 },
                id: this.helpers.makeid(6),
              });
            }
          }
          if (playerIndex !== 0) {
            if (index === player.numberOfQuestions) {
              player.path.push({
                position: { x: lastPoint, y: lower * verticalGap },
                id: this.helpers.makeid(6),
              });
            } else {
              const x = this.margin + index * this.horizontalGap;
              // if(x > lastPoint){
              //   x = lastPoint
              // }
              player.path.push({
                position: { x: x, y: lower * verticalGap },
                id: this.helpers.makeid(6),
              });
            }
          }
        }
      }
    });
    this.multiPlayer?.players.forEach((player: any, playerIndex) => {
      if (player.newComer) {
        player.currentPoint = player.path[0];
        player.animationState = "init";

        this.moveToNextPoint(player.currentPoint.id, player.id, true);
        player.newComer = false;
      }
    });
  }
  ngOnInit(): void {
    this.sub = this.boardgameService
      .messagesListener()
      .subscribe((message: any) => {

        if (message.sender === "gameBoard") {
          if (message.message === "addPointToPlayer") {

            this.addPointToPlayer(
              message.data.userToUpdate,
              message.data.players
            );
          }
          // if(message.message === 'updatePlayers'){
          //   const newPlayers = message.data.players
          //   const oldPlayers = this.multiPlayer.players
          //   if(newPlayers.length > oldPlayers.length){
          //   let _newPlayers = newPlayers.filter(player=> !oldPlayers.some(_player=>player.id === player.id))
          //   
          //   }
          //   else{
          //       oldPlayers.forEach(player =>{
          //         const newPlayer = newPlayers.find(p => p.id === player.id)
          //         player.avatar = newPlayer.avatar
          //         player.points = newPlayer.points
          //         player.name = newPlayer.name
          //         player.numberOfQuestions = newPlayer.numberOfQuestions
          //         player.id = newPlayer.id
          //         player.items = newPlayer.items
          //       })
          //   }

          // }
          if (message.message === "resetPoints") {
            const wholeTime = this.multiPlayer.players.length * 100 + 2000;
            this.multiPlayer.players.forEach((player, index) => {
              const time = index * 100 + 1000;
              setTimeout(() => {
                this.die(player.id);
              }, time);
            });
            setTimeout(() => {
              this.multiPlayer.players.forEach((player, index) => {
                this.undie(player.id);
              });
            }, wholeTime);
          }
        }
      });



    // this.moveToNextPoint(this.currentPoint.id)
  }

  animationEnd(event, playerId) {
    const player = this.multiPlayer.players.find((p) => p.id === playerId);
    if (player?.animationState) {
      player.animationState = "init";
    }
  }
  addPointToPlayer(playerId, players) {
    // if(this.multiPlayer.players.length === 0 ){
    // this.multiPlayer.players = players

    // }
    const playerToUpdate = this.multiPlayer.players.find(
      (p) => p.id === playerId
    );
    this.moveToNextPoint(playerToUpdate.currentPoint.id, playerToUpdate.id);
  }
  removePointFromPlayer(playerId, players) {
    // if(this.multiPlayer.players.length === 0 ){
    // this.multiPlayer.players = players

    // }
    const playerToUpdate = this.multiPlayer.players.find(
      (p) => p.id === playerId
    );
    this.moveToPreviousPoint(playerToUpdate.currentPoint.id, playerToUpdate.id);
  }
  addPoint() {
    this.points.push({
      position: { x: 100, y: 100 },
      id: this.helpers.makeid(6),
    });
  }
  pointPositionChanged(id, event) {

    const point = this.points.find((p) => p.id === id);
    point.position.x = event.distance.x + 100;
    point.position.y = event.distance.y + 100;

  }
  die(playerId) {
    const player = this.multiPlayer.players.find((p) => p.id === playerId);
    player.animationState = "die";
  }
  undie(playerId) {
    const player = this.multiPlayer.players.find((p) => p.id === playerId);
    player.currentPoint = player.path[0];
    player.animationState = "next";
  }
  moveToNextPoint(pointId, playerId, start?) {
    const player = this.multiPlayer.players.find((p) => p.id === playerId);

    const currentPointIndex = player.path.findIndex((p) => p.id === pointId);
    if (!start) {
      if (player.path[currentPointIndex + 1]) {
        player.currentPoint = player.path[currentPointIndex + 1];
        player.animationState = "next";
      }
    }
    if (start) {
      player.currentPoint = player.path[currentPointIndex];
      player.animationState = "next";
    }
  }
  moveToPreviousPoint(pointId, playerId) {
    const player = this.multiPlayer.players.find((p) => p.id === playerId);
    const currentPointIndex = player.path.findIndex((p) => p.id === pointId);
    if (player.path[currentPointIndex - 1]) {
      player.currentPoint = player.path[currentPointIndex - 1];
      player.animationState = "next";
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
