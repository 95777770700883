import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { differenceInMonths, startOfMonth } from 'date-fns';
import { GroupsService } from 'src/app/shared/services/groups.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { SmallDataService } from 'src/app/shared/services/small-data.service';
import { YearlyPlanService } from 'src/app/shared/services/yearly-plan.service';
import { FinanceData, FinanceItem, FinancesStatsService, StateItem, TeacherCost } from './finances-stats.service';
import { MatDialog } from '@angular/material/dialog';
import { FinancesService, FinanceEntry } from './finances.service';
import { FinanceDialogComponent } from './finance-dialog/finance-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-finances-stats',
  templateUrl: './finances-stats.component.html',
  styleUrls: ['./finances-stats.component.css']
})
export class FinancesStatsComponent implements OnInit {
  teachers = [];
  teachersCosts: TeacherCost[] = [];
  finances: FinanceData;
  financeEntries: FinanceEntry[] = [];
  isLoading = false;
  statsLoading = false;

  // Add missing properties
  numberOfMonthsIncomes = 0;
  numberOfMonthsExpenses = 0;
  numberOfMonthsExpensesTeacher = 0;
  summarizedIncomes: any[] = [];
  summarizedExpenses: any[] = [];
  allIncomes = 0;
  allExpenses = 0;
  result = 0;
  companyResult = 0;

  financeStats: {
    income: {
      monthly: number;
      fixed: number;
      transactions: number;
      total: number;
    };
    expenses: {
      monthly: number;
      fixed: number;
      teacherCosts: number;
      transactions: number;
      total: number;
    };
    balance: number;
    currentState: {
      accountBalance: number;
      cash: number;
      unpaid: number;
      loss: number;
    };
  };

  private sub: Subscription;
  private financeSub: Subscription;
  private statsSub: Subscription;
  private financeDataSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<FinancesStatsComponent>,
    private smallDataService: SmallDataService,
    private groupsService: GroupsService,
    private yearlyPlanService: YearlyPlanService,
    private financesStatsService: FinancesStatsService,
    public helpers: HelpersService,
    private financesService: FinancesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Load finance data from backend
    this.isLoading = true;
    this.financeDataSub = this.financesStatsService.getFinanceDataListener().subscribe(
      financeData => {
        if (financeData) {
          this.finances = financeData;
          this.isLoading = false;
          this.loadTeachersData();
        }
      }
    );

    // Load teacher costs
    this.financesStatsService.getTeacherCosts().subscribe(
      response => {
        this.teachersCosts = Array.isArray(response) ? response : [];
        this.loadTeachersData();
      },
      error => {
        console.error('Error loading teacher costs:', error);
      }
    );

    // Fetch finance data
    this.financesStatsService.getFinanceData();

    // Subscribe to finance updates from the service
    this.financeSub = this.financesService.getFinancesUpdateListener()
      .subscribe(finances => {
        this.financeEntries = finances;
        this.isLoading = false;
      });

    // Fetch finances from the backend
    this.financesService.getFinances();

    // Try to get finance stats from the backend
    this.loadFinanceStats();

    // Add calculation of sums
    this.calculateSums();
  }

  loadTeachersData() {
    this.sub = this.groupsService.groupsUpdatedListener().subscribe(groups => {
      this.teachers = [];
      groups.forEach(group => {
        group.lessons.forEach(lesson => {
          if (!this.teachers.some(teacher => teacher.name.toLowerCase() === lesson.assignedTeacher.name.toLowerCase())) {
            this.teachers.push({ name: lesson.assignedTeacher.name, groups: [] });
          }

          const monthStart = startOfMonth(Date.now());
          const monthStart2 = Date.parse(this.yearlyPlanService.getDateWithTimeObject(monthStart));

          if (lesson.attributes.includes('avalible') && lesson.dateMs > monthStart2) {
            let selectedTeacher = this.teachers.find(teacher => teacher.name === lesson.assignedTeacher.name);
            if (!selectedTeacher.groups.some(_group => _group.name === group.name)) {
              selectedTeacher.groups.push({ name: group.name, lessons: [] });
            }
            selectedTeacher.groups.find(_group => _group.name === group.name).lessons.push(lesson);
          }
        });
      });
      this.teachers = this.teachers.filter(teacher => teacher.groups.length > 0);
    });
    this.groupsService.getGroupsList();
  }

  loadFinanceStats() {
    this.statsLoading = true;
    this.financesService.getFinanceStats()
      .subscribe(
        response => {
          this.financeStats = response.stats;
          this.calculateSums();
          this.statsLoading = false;
        },
        error => {
          console.error('Error loading finance stats:', error);
          this.statsLoading = false;
        }
      );
  }

  openFinanceDialog(finance?: FinanceEntry) {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: finance || {
        type: 'expense',
        name: '',
        value: 0,
        category: 'monthly',
        isCompany: false,
        isTeacher: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.id) {
          this.financesService.updateFinance(result.id, result);
        } else {
          this.financesService.addFinance(result);
        }
      }
    });
  }

  deleteFinance(id: string) {
    if (confirm('Are you sure you want to delete this entry?')) {
      this.financesService.deleteFinance(id);
    }
  }

  addFinanceItem(type: 'income' | 'expense', defaultName: string = '') {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: type,
        name: defaultName,
        value: 0,
        category: 'monthly',
        isCompany: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newItem: FinanceItem = {
          name: result.name,
          value: result.value,
          type: result.category,
          company: result.isCompany
        };

        if (type === 'income') {
          this.financesStatsService.addIncome(newItem).subscribe(
            response => {

              this.financesService.getFinances();
              this.financesStatsService.getFinanceData();
              this.loadFinanceStats();
            },
            error => {
              console.error('Error adding income:', error);
            }
          );
        } else {
          this.financesStatsService.addExpense(newItem).subscribe(
            response => {

              this.financesService.getFinances();
              this.financesStatsService.getFinanceData();
              this.loadFinanceStats();
            },
            error => {
              console.error('Error adding expense:', error);
            }
          );
        }

        // Create transaction in the financial system
        this.financesService.createTransaction({
          amount: result.value,
          type: type,
          description: result.name
        }).subscribe(
          transaction => {

            this.financesService.getFinances();
          },
          error => {
            console.error('Error creating transaction:', error);
          }
        );
      }
    });
  }

  updateFinanceItem(type: 'income' | 'expense', index: number, item: FinanceItem) {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: type,
        name: item.name,
        value: item.value,
        category: item.type,
        isCompany: item.company
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedItem: FinanceItem = {
          name: result.name,
          value: result.value,
          type: result.category,
          company: result.isCompany,
          teacher: item.teacher
        };

        if (type === 'income') {
          this.financesStatsService.updateIncome(index, updatedItem).subscribe(
            response => {

              this.financesService.getFinances();
              this.financesStatsService.getFinanceData();
              this.loadFinanceStats();
            },
            error => {
              console.error('Error updating income:', error);
            }
          );
        } else {
          this.financesStatsService.updateExpense(index, updatedItem).subscribe(
            response => {

              this.financesService.getFinances();
              this.financesStatsService.getFinanceData();
              this.loadFinanceStats();
            },
            error => {
              console.error('Error updating expense:', error);
            }
          );
        }
      }
    });
  }

  deleteFinanceItem(type: 'income' | 'expense', index: number) {
    if (confirm(`Are you sure you want to delete this ${type}?`)) {
      if (type === 'income') {
        this.financesStatsService.deleteIncome(index).subscribe(
          response => {

            this.financesService.getFinances();
            this.financesStatsService.getFinanceData();
            this.loadFinanceStats();
          },
          error => {
            console.error('Error deleting income:', error);
          }
        );
      } else {
        this.financesStatsService.deleteExpense(index).subscribe(
          response => {

            this.financesService.getFinances();
            this.financesStatsService.getFinanceData();
            this.loadFinanceStats();
          },
          error => {
            console.error('Error deleting expense:', error);
          }
        );
      }
    }
  }

  addTeacherCost() {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: 'teacher',
        name: '',
        value: 0
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newTeacherCost: TeacherCost = {
          name: result.name,
          value: result.value
        };
        this.financesStatsService.addTeacherCost(newTeacherCost).subscribe(
          response => {

            this.financesStatsService.getFinanceData();
            this.loadFinanceStats();
            this.financesStatsService.getTeacherCosts().subscribe(
              response => {
                this.teachersCosts = Array.isArray(response) ? response : [];
              }
            );
          },
          error => {
            console.error('Error adding teacher cost:', error);
          }
        );
      }
    });
  }

  updateTeacherCost(index: number, teacherCost: TeacherCost) {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: 'teacher',
        name: teacherCost.name,
        value: teacherCost.value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedTeacherCost: TeacherCost = {
          name: result.name,
          value: result.value
        };
        this.financesStatsService.updateTeacherCost(index, updatedTeacherCost).subscribe(
          response => {

            this.financesStatsService.getFinanceData();
            this.loadFinanceStats();
            this.financesStatsService.getTeacherCosts().subscribe(
              response => {
                this.teachersCosts = Array.isArray(response) ? response : [];
              }
            );
          },
          error => {
            console.error('Error updating teacher cost:', error);
          }
        );
      }
    });
  }

  deleteTeacherCost(index: number) {
    if (confirm('Are you sure you want to delete this teacher cost?')) {
      this.financesStatsService.deleteTeacherCost(index).subscribe(
        response => {

          this.financesStatsService.getFinanceData();
          this.loadFinanceStats();
          this.financesStatsService.getTeacherCosts().subscribe(
            response => {
              this.teachersCosts = Array.isArray(response) ? response : [];
            }
          );
        },
        error => {
          console.error('Error deleting teacher cost:', error);
        }
      );
    }
  }

  updateState(index: number, state: StateItem) {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: 'state',
        name: state.name,
        value: state.value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedState: StateItem = {
          name: result.name,
          value: result.value
        };
        this.financesStatsService.updateState(index, updatedState).subscribe(
          response => {

          },
          error => {
            console.error('Error updating state:', error);
          }
        );
      }
    });
  }

  calculateSums() {
    if (this.finances) {
      // Calculate income sums
      this.summarizedIncomes = this.finances.incomes.map(income => ({
        ...income,
        selected: false
      }));

      this.allIncomes = this.finances.incomes.reduce((sum, income) => {
        if (income.type === 'monthly') {
          this.numberOfMonthsIncomes = Math.max(this.numberOfMonthsIncomes, 1);
          return sum + (income.value * this.numberOfMonthsIncomes);
        }
        return sum + income.value;
      }, 0);

      // Calculate expense sums
      this.summarizedExpenses = this.finances.expenses.map(expense => ({
        ...expense,
        selected: false
      }));

      this.allExpenses = this.finances.expenses.reduce((sum, expense) => {
        if (expense.type === 'monthly') {
          this.numberOfMonthsExpenses = Math.max(this.numberOfMonthsExpenses, 1);
          return sum + (expense.value * this.numberOfMonthsExpenses);
        }
        return sum + expense.value;
      }, 0);

      // Add teacher costs to expenses
      const teacherCosts = this.teachersCosts.reduce((sum, teacher) => sum + teacher.value, 0);
      this.allExpenses += teacherCosts;

      // Calculate results
      this.result = this.allIncomes - this.allExpenses;

      // Calculate company result (only items marked as company)
      const companyIncomes = this.finances.incomes
        .filter(income => income.company)
        .reduce((sum, income) => {
          if (income.type === 'monthly') {
            return sum + (income.value * this.numberOfMonthsIncomes);
          }
          return sum + income.value;
        }, 0);

      const companyExpenses = this.finances.expenses
        .filter(expense => expense.company)
        .reduce((sum, expense) => {
          if (expense.type === 'monthly') {
            return sum + (expense.value * this.numberOfMonthsExpenses);
          }
          return sum + expense.value;
        }, 0);

      this.companyResult = companyIncomes - companyExpenses;
    }
  }

  createTransaction() {
    const dialogRef = this.dialog.open(FinanceDialogComponent, {
      width: '400px',
      data: {
        type: 'expense',
        name: '',
        value: 0,
        category: 'transaction',
        isCompany: false,
        isTeacher: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.financesService.createTransaction({
          amount: result.value,
          type: result.type,
          description: result.name
        }).subscribe(
          response => {

            this.loadFinanceStats();
            this.financesService.getFinances();
          },
          error => {
            console.error('Error creating transaction:', error);
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.financeSub) {
      this.financeSub.unsubscribe();
    }
    if (this.statsSub) {
      this.statsSub.unsubscribe();
    }
    if (this.financeDataSub) {
      this.financeDataSub.unsubscribe();
    }
  }
}
