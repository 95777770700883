import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoursePlanService {
  itemSelected = new Subject()
  planUpdated = new Subject()
  constructor() {
    this.createNextCoursePlanItem = this.createNextCoursePlanItem.bind(this);
  }
  passItemSelected(data) {
    this.itemSelected.next(data)
  }
  getCurrentLesson() {
    // Logic to get the current lesson
    // Assuming you have a way to access the current lesson
    return {
      sets: [], exercises: [], homework: [], activities
        : []
    };
  }
  duplicateSelectedItems(items: any[]): any[] {
    return items.map(item => this.createNextCoursePlanItem(item));
  }
  createNextCoursePlanItem(item: any): any {
    const newItem = { ...item };
    // Increment name number
    const nameMatch = newItem.name.match(/(\d+)([a-zA-Z]*)(\s.*)?$/);
    if (nameMatch) {
      const num = parseInt(nameMatch[1]) + 1;
      newItem.name = newItem.name.replace(/(\d+)([a-zA-Z]*)(\s.*)?$/, num.toString() + (nameMatch[2] || '') + (nameMatch[3] || ''));
    } else {
      newItem.name = `${newItem.name} 2`;
    }
    // Clear sets and files
    newItem.sets = [];
    newItem.files = [];
    return newItem;
  }
  addSetToCurrentLesson(set: any) {
    // Logic to add the set to the current lesson's sets
    // Assuming you have a way to access the current lesson
    const currentLesson = this.getCurrentLesson();
    currentLesson.sets.push(set);
  }
  passUpdatedPlan(plan) {

    this.planUpdated.next(plan)
  }
  itemSelectedListener() {
    return this.itemSelected.asObservable()
  }
  addExerciseToCurrentLesson(exercise: any) {
    // Logic to add the exercise to the current lesson's exercises
    const currentLesson = this.getCurrentLesson();
    currentLesson.exercises.push(exercise);
  }
  planUpdatedListener() {
    return this.itemSelected.asObservable()
  }


  addHomeworkToCurrentLesson(homework: any) {
    // Logic to add the homework to the current lesson's homework
    const currentLesson = this.getCurrentLesson();
    currentLesson.homework.push(homework);
  }
  addActivityToCurrentLesson(activity: any) {
    // Logic to add the activity to the current lesson's activities
    const currentLesson = this.getCurrentLesson();
    currentLesson.activities.push(activity);
  }
}