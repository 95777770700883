import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetectiveStory, DetectiveStoryService } from '../../pdf2/services/detective-story.service';

@Component({
    selector: 'app-detective-story-select',
    templateUrl: './detective-story-select.component.html',
    styleUrls: ['./detective-story-select.component.css']
})
export class DetectiveStorySelectComponent implements OnInit {
    stories: DetectiveStory[] = [];
    filteredStories: DetectiveStory[] = [];
    selectedStory: DetectiveStory | null = null;
    searchText: string = '';
    showFullStory: boolean = false;

    // Polish translations for level names
    levelTranslations = {
        'beginner': 'początkujący',
        'intermediate': 'średniozaawansowany',
        'advanced': 'zaawansowany'
    };

    constructor(
        public dialogRef: MatDialogRef<DetectiveStorySelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private detectiveStoryService: DetectiveStoryService
    ) { }

    ngOnInit(): void {
        this.stories = this.detectiveStoryService.getAllStories();
        this.filteredStories = [...this.stories];
    }

    selectStory(story: DetectiveStory): void {
        this.selectedStory = story;
    }

    confirm(): void {
        this.dialogRef.close(this.selectedStory);
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    getPolishLevel(level: string): string {
        return this.levelTranslations[level] || level;
    }

    searchStories(event: any): void {
        const searchValue = event.target.value.toLowerCase();
        this.searchText = searchValue;
        this.filteredStories = this.stories.filter(story =>
            story.title.toLowerCase().includes(searchValue)
        );
    }

    toggleShowFullStory(): void {
        this.showFullStory = !this.showFullStory;
    }
} 