import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FinancesStatsComponent } from './finances-stats.component';
import { FinancesStatsService } from './finances-stats.service';
import { FinancesService } from './finances.service';
import { FinanceDialogComponent } from './finance-dialog/finance-dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        FinancesStatsComponent,
        FinanceDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatCardModule,
        FlexLayoutModule,
        SharedModule
    ],
    providers: [
        FinancesStatsService,
        FinancesService
    ],
    exports: [
        FinancesStatsComponent
    ]
})
export class FinancesStatsModule { } 