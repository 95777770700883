import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-adults-test',
  templateUrl: './adults-test.component.html',
  styleUrls: ['./adults-test.component.css']
})
export class AdultsTestComponent implements OnInit {
  @Output() answersToSubmit = new EventEmitter()
  @Input() i
  firstTask = [
    "What __ (you /do) next Saturday?",
    "A: What __ (you/ do)? B: I'm a student.",
    "A: What __ (you / do)? B: I’m studying for a test.",
    "My son __ (study) German at school.",
    "This __ (not be) our cat.",
    "I __ (not eat) dinner yet.",
    "Where __ (you / go) on holiday last summer?",
    "Last summer I __ (go) to England.",
    "John phoned when I (run) __ in the park.",
    "I promise I __ (not lie) again."
  ]
  secondTask = [
    "Where __ you born?",
    "‘Would you like be a TV star?’ ‘No, I __.’",
    "__ your brother live in London?",
    "I usually get up late __ Sundays.",
    "I moved here two years __.",
    "We __ English, we’re American.",
    "It’s the __ interesting book I’ve ever read.",
    "I can dance, but I __ sing.",
    "Is there __ milk in the fridge?",
    "This is __ umbrella.",
  ]
  answers = new FormGroup({
    firstTask: new FormArray([
      new FormControl(null, { updateOn: 'blur' }),
      new FormControl(null, { updateOn: 'blur' }),
      new FormControl(null, { updateOn: 'blur' }),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null)
    ]),
    secondTask: new FormArray([
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null),
      new FormControl(null)
    ]),
  })





  constructor() {

  }

  ngOnInit(): void {

  }
  updateAnswers() {
    let answersToSend = {
      firstTask: [],
      secondTask: [],
    }
    this.answers.value.firstTask.forEach((e, index) => {
      const question = {
        question: this.firstTask[index],
        answer: e,
      }
      answersToSend.firstTask.push(question)
    });
    this.answers.value.secondTask.forEach((e, index) => {
      const question = {
        question: this.secondTask[index],
        answer: e,
      }
      answersToSend.secondTask.push(question)
    });

    this.answersToSubmit.emit({
      answers: answersToSend,
      i: this.i
    })
  }

}
