import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurriculumService } from '../curriculum.service';

@Component({
    selector: 'app-curriculum-selection-dialog',
    templateUrl: './curriculum-selection-dialog.component.html',
    styleUrls: ['./curriculum-selection-dialog.component.css']
})
export class CurriculumSelectionDialogComponent implements OnInit {
    curriculumList: any[] = [];
    filteredCurriculumList: any[] = [];
    isLoading: boolean = true;
    searchTerm: string = '';
    selectedCurriculum: any = null;

    constructor(
        public dialogRef: MatDialogRef<CurriculumSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private curriculumService: CurriculumService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.loadCurriculumList();
    }

    loadCurriculumList(): void {
        this.isLoading = true;
        this.curriculumService.getCurriculumList()
            .then(curriculums => {
                this.curriculumList = curriculums;
                this.applyFilter();
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Błąd podczas ładowania listy programów nauczania:', error);
                this.snackBar.open('Nie udało się załadować listy programów nauczania', 'OK', { duration: 3000 });
                this.isLoading = false;
            });
    }

    applyFilter(): void {
        if (!this.searchTerm) {
            this.filteredCurriculumList = [...this.curriculumList];
        } else {
            const searchTermLower = this.searchTerm.toLowerCase();
            this.filteredCurriculumList = this.curriculumList.filter(curriculum =>
                curriculum.data.name.toLowerCase().includes(searchTermLower)
            );
        }
    }

    selectCurriculum(curriculum: any): void {
        this.selectedCurriculum = curriculum;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        if (this.selectedCurriculum) {
            this.dialogRef.close({
                id: this.selectedCurriculum._id,
                name: this.selectedCurriculum.data.name
            });
        } else {
            this.snackBar.open('Proszę wybrać program nauczania', 'OK', { duration: 2000 });
        }
    }
} 