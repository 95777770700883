import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CoursesComponent } from './courses.component';
import { CoursesListComponent } from './courses-list/courses-list.component';
import { CourseAddEditComponent } from './course-add-edit/course-add-edit.component';
import { CoursesPlanComponent } from './courses-plan/courses-plan.component';
import { CoursesPlanItemComponent } from './courses-plan/courses-plan-item/courses-plan-item.component';
import { CoursePlanComponent } from './course-add-edit/course-plan/course-plan.component';
import { LessonDetailsDialogComponent } from './courses-plan/lesson-details-dialog/lesson-details-dialog.component';
import { SectionFullscreenDialogComponent } from './courses-plan/lesson-details-dialog/section-fullscreen-dialog/section-fullscreen-dialog.component';
import { ResourceSelectionDialogModule } from './course-add-edit/resource-selection-dialog/resource-selection-dialog.module';
import { ResourcePreviewDialogComponent, SimpleDialogComponent, CurriculumSimpleViewComponent } from './course-add-edit/resource-preview-dialog/resource-preview-dialog.component';

@NgModule({
  declarations: [
    CoursesComponent,
    CoursesListComponent,
    CourseAddEditComponent,
    CoursesPlanComponent,
    CoursesPlanItemComponent,
    CoursePlanComponent,
    LessonDetailsDialogComponent,
    SectionFullscreenDialogComponent,
    ResourcePreviewDialogComponent,
    SimpleDialogComponent,
    CurriculumSimpleViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: '', component: CoursesComponent },
    ]),
    SharedModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    LayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ResourceSelectionDialogModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    CoursePlanComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoursesModule { }
