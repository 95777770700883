import { Injectable } from '@angular/core';
import { VideoMakerService } from './video-maker.service';
import { VideoMakerVariablesService } from './video-maker-variables.service';

interface SrtItem {
  startTime: string;
  endTime: string;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class SrtManipulatorService {
  languages = []
  private srtData: { [language: string]: SrtItem[] } = {}
  constructor(
    private videoMakerService: VideoMakerService,
    private videoMakerVariablesService: VideoMakerVariablesService

  ) {

    this.languages = this.videoMakerService.translationsToDownload;
    this.languages.forEach(language => {
      this.srtData[language] = [];
    }
    );
  }


  private videoStartTime: Date;

  startDisplayingText(prop?: 'word' | 'description' | 'sentence' | 'story' | '', item?: any, introTime?: number, texts?: { [language: string]: string }, beforeIntro?) {
    if (!this.videoStartTime) {
      let wholeVideointroTime = 0
      if (introTime) {
        wholeVideointroTime += introTime;
      }
      this.videoStartTime = new Date(); // Capture the start time of the video
      this.videoStartTime.setMilliseconds(this.videoStartTime.getMilliseconds() - wholeVideointroTime);
    }

    const currentTime = new Date();
    // if !beforeIntro then add this.videoMakerVariablesService.introTime to the currentTime
    if (!beforeIntro) {
      currentTime.setMilliseconds(currentTime.getMilliseconds() + this.videoMakerVariablesService.introTime);
    }
    const relativeStartTime = new Date(currentTime.getTime() - this.videoStartTime.getTime());
    const formattedStartTime = this.formatTime(relativeStartTime);

    // Iterate over all languages and add the text
    for (let language in this.srtData) {
      let text = '';
      if (texts && texts[language]) {
        text = texts[language];
      } else {
        text = item[prop + language];
      }
      // Initialize the array for the language if it doesn't exist
      if (!this.srtData[language]) {
        this.srtData[language] = [];
      }

      // Push a new entry with a placeholder for the end time into the srtData
      if (!beforeIntro) {
        this.srtData[language].push({
          startTime: formattedStartTime,
          endTime: "PLACEHOLDER", // Placeholder for the end time
          text: text
        });
      }
      if (beforeIntro) {
        this.srtData[language].push({
          startTime: formattedStartTime,
          endTime: "PLACEHOLDER_BEFORE_INTRO", // Placeholder for the end time
          text: text
        });
      }
    }
  }

  endDisplayingText() {
    // Iterate over all languages and set the end time
    for (let language in this.srtData) {
      if (this.srtData[language] && this.srtData[language].length > 0) {
        const lastSubtitle = this.srtData[language][this.srtData[language].length - 1];
        if (lastSubtitle.endTime === "PLACEHOLDER") {
          const startTime = this.parseTime(lastSubtitle.startTime);
          const currentTime = new Date();
          currentTime.setMilliseconds(currentTime.getMilliseconds() + this.videoMakerVariablesService.introTime);

          const relativeEndTime = new Date(currentTime.getTime() - this.videoStartTime.getTime());
          const formattedEndTime = this.formatTime(relativeEndTime);


          // Update the placeholder with the actual end time
          lastSubtitle.endTime = formattedEndTime;
          localStorage.setItem('srtData', JSON.stringify(this.srtData));
        }
        if (lastSubtitle.endTime === "PLACEHOLDER_BEFORE_INTRO") {
          const startTime = this.parseTime(lastSubtitle.startTime);
          const currentTime = new Date();

          const relativeEndTime = new Date(currentTime.getTime() - this.videoStartTime.getTime());
          const formattedEndTime = this.formatTime(relativeEndTime);

          // Update the placeholder with the actual end time
          lastSubtitle.endTime = formattedEndTime;
          localStorage.setItem('srtData', JSON.stringify(this.srtData));
        }




        else {
        }
      } else {
        console.error("No subtitles to display. Please start a text first.");
      }
    }
  }

  formatTime(date) {
    return `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')},${date.getUTCMilliseconds().toString().padStart(3, '0')}`;
  }
  parseTime(srtTime: string): Date {
    const [hms, ms] = srtTime.split(',');
    const [hours, minutes, seconds] = hms.split(':').map(Number);
    return new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds, parseInt(ms)));
  }

  generateWebVTT(): { [language: string]: string } {
    let output: { [language: string]: string } = {};
    this.srtData = JSON.parse(localStorage.getItem('srtData'));
    for (let language in this.srtData) {
      output[language] = 'WEBVTT\n\n';
      output[language] += this.srtData[language].map((item, index) => {
        let startTime = item.startTime.replace(',', '.');
        let endTime = item.endTime.replace(',', '.');
        return `${index + 1}\n${startTime} --> ${endTime}\n${item.text}\n`;
      }).join("\n");
    }

    return output;
  }
  clearSrt(): void {
    this.srtData = null
    this.videoStartTime = null
  }
}
