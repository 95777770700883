import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { UserDetails } from 'src/app/shared/services/authentication.service';
import { SetsService } from 'src/app/shared/services/sets.service';
import { SetsCategorizeComponent } from '../sets-categorize/sets-categorize.component';

@Component({
  selector: 'app-set-merge-preview',
  templateUrl: './set-merge-preview.component.html',
  styleUrls: ['./set-merge-preview.component.css'],
})
export class SetMergePreviewComponent implements OnInit {
  userDetails;
  sub: any;
  exportList = [];
  showFreeSet = false;
  showSetContent: any;
  constructor(
    public dialogRef: MatDialogRef<SetMergePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public userDetailsService: UserDetailsService,
    private setsService: SetsService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    if (this.data.set) {
      this.data = this.data.set;
    }

    this.sub = this.setsService.foundSetListener().subscribe((set: any) => {
      console.log(
        '🚀 ~ file: set-merge-preview.component.ts ~ line 29 ~ SetMergePreviewComponent ~ this.sub=this.setsService.foundSetListener ~ this.data',
        this.data,
      );

      if (this.data.set?.type === 'free') {
        this.data.set = set;
        this.showFreeSet = true;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }
    });
    this.userDetails = this.userDetailsService.getUserDetails();
    if (this.data.type === 'free') {
      // this.spinner.show();
      // this.setsService.searchForSetsbyId(this.data.parentSet);
    }
    if (this.data.set?.type !== 'free') {
      // this.setsService.searchForSetsbyId(this.data.set.parentSet);
      // this.spinner.show();
    }
  }
  openCategorizeSetDialog() {
    setTimeout(() => {
      const dialogRef = this.dialog.open(SetsCategorizeComponent, {
        width: '90vw',
        height: '90vh',
        data: { set: this.data },
      });
    }, 500);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
