<div class="dialog-container">
  <h2 mat-dialog-title>Wybierz pliki PDF do zadań domowych</h2>

  <mat-dialog-content>
    <mat-tab-group [(selectedIndex)]="selectedTab">
      <!-- Children PDFs Tab -->
      <mat-tab label="Dla dzieci">
        <div class="pdf-list">
          <mat-card
            *ngFor="let pdf of childrenPdfTypes"
            [class.selected]="isPdfSelected(pdf)"
            (click)="togglePdfSelection(pdf)"
          >
            <mat-card-header>
              <mat-card-title>{{ pdf.displayName }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ pdf.descriptionPl }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>

      <!-- Vocabulary Sets Tab -->
      <mat-tab label="Zestawy słownictwa">
        <div class="pdf-list">
          <mat-card
            *ngFor="let pdf of sentencesPdfTypes"
            [class.selected]="isPdfSelected(pdf)"
            (click)="togglePdfSelection(pdf)"
          >
            <mat-card-header>
              <mat-card-title>{{ pdf.displayName }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ pdf.descriptionPl }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>

      <!-- Free Sets & Multi-page Sets Tab -->
      <mat-tab label="Zestawy wolne i wielostronicowe">
        <div class="pdf-list">
          <mat-card
            *ngFor="let pdf of freeSetPdfTypes"
            [class.selected]="isPdfSelected(pdf)"
            (click)="togglePdfSelection(pdf)"
          >
            <mat-card-header>
              <mat-card-title>{{ pdf.displayName }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ pdf.descriptionPl }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="selected-pdfs">
      <h3>Wybrane pliki: {{ selectedPdfs.length }}</h3>
      <mat-chip-list>
        <mat-chip
          *ngFor="let pdf of selectedPdfs"
          [removable]="true"
          (removed)="togglePdfSelection(pdf)"
        >
          {{ pdf.displayName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Anuluj</button>
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirm()"
      [disabled]="selectedPdfs.length === 0"
    >
      Zatwierdź
    </button>
  </mat-dialog-actions>
</div>
