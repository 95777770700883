import { Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { VideosTypesService } from '../video-generator/videos-types/videos-types.service';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-video-data-creator',
  templateUrl: './video-data-creator.component.html',
  styleUrls: ['./video-data-creator.component.scss']
})
export class VideoDataCreatorComponent implements OnInit {
  chatBots = []
  chosenChatBot = null;
  allVideosTypes = null;
  fixedTags = `ESL,learn english,Improve English, Learning English,Englisch lernen,Aprendiendo inglés,Aprendendo inglês,İngilizce öğrenmek,Apprendre l'anglais,Nauka angielskiego,Học tiếng Anh,เรียนภาษาอังกฤษ,Belajar bahasa Inggris,अंग्रेजी सीखना,Practical English,Praktisches Englisch,Inglés práctico,Inglês prático,Pratik İngilizce,Anglais pratique,Praktyczny angielski,Tiếng Anh thực hành,Tiếng Anh thực hành,Bahasa Inggris praktis,व्यावहारिक अंग्रेजी`
  selectedVideoTitle = null
  selectedVideoType = null;
  selectedVideoMechanics = null;
  selectedVideoTopic = null;
  selectedTeacherName = null;
  selectedTeachersVoice = null;
  selectedVideoDescription = null;
  selectedTags = null;
  selectedIntroText1 = null;
  selectedIntroText2 = null;
  selectedIntroText3 = null;
  selectedNumberOfWords = 0
  selectedThumbnailText = null
  chosenVideoType = null;
  currentStepIndex = 0
  generatedTitles = null
  videoLevel = '';
  textData = 'asd'
  steps = [
    {

      type: 'choice',
      options: [], // videosTypes here
      promptForUser: 'Wybierz rodzaj filmu',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [20, 25, 30, 35, 40, 45, 50], // videosTypes here
      promptForUser: 'Wybierz Ilość słow',
      promptToSend: null
    },
    {

      type: 'choice',
      options: ['Beginner', 'Intermidiate', 'Advanced'],
      promptForUser: 'Poziom trudności',
      promptToSend: null
    },
    {

      type: 'input',
      inputText: '',
      promptForUser: 'Wpisz temat filmu (podkategoria np. parts of the house)',
      promptToSend:
        null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz Tekst miniatury',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz Tytuł',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz Opis',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz Tagi',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz tekst wstępu 1',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz tekst wstępu 2',
      promptToSend: null
    },
    {

      type: 'choice',
      options: [], // generatedTitles here
      promptForUser: 'Wybierz tekst wstępu 3',
      promptToSend: null
    },


  ]
  constructor(
    private chatbotsService: ChatbotsService,
    private chatService: ChatService,
    private videosTypesService: VideosTypesService
  ) {
    this.chosenChatBot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === 'Taylor');
  }

  ngOnInit() {
    this.allVideosTypes = this.videosTypesService.videoTypes
    this.steps[0].options = this.allVideosTypes.map((videoType) => videoType.displayName)

  }

  nextStep(step, input) {

    switch (this.currentStepIndex) {
      case 0:
        this.chosenVideoType = this.allVideosTypes.find((videoType) => videoType.displayName === input)
        this.selectedTeacherName = this.chosenVideoType.teacherName
        this.selectedTeachersVoice = this.chosenVideoType.teachersVoice
        this.selectedVideoMechanics = this.chosenVideoType.mechanics



        break;
      case 1:
        this.selectedNumberOfWords = input

      case 2:
        this.videoLevel = input

        break;
      case 3:
        this.selectedVideoTopic = input
        console.log("🚀 ~ VideoDataCreatorComponent ~ nextStep ~ this.selectedVideoTopic:", this.selectedVideoTopic

        )


        this.steps[3].promptToSend = {
          prompt: `Create a thumbnail text for a youtube video that will be engaging and will make people click it. 
          The thumbnail text should be well balanced between typical clickbait and a dull title where there are only 
          informations about the video. 
          The thumbnail text should be very interesting and engading. It should provide a benefit for the viewer. 
          The video is for english learners for level ${this.videoLevel} 
          Adjust the thumbnail text to be speciffically tailored and interesting to the viewers level, for example add words like 'for beginners' etc.
          The thumbnail text should have maximum of 30 characters. In 3 examples use questions.
          The video  has ${this.selectedNumberOfWords} 
          words and is in the topic of ${this.selectedVideoTopic} ${this.selectedVideoMechanics}.
          Give me 5 options. Return a json with format: {type:"texts", options:[]}.`,
          placeholderText: 'Tworzę tekst miniatury...'
        }
        break;
      case 4:
        this.selectedThumbnailText = input
        this.steps[4].promptToSend = {
          prompt: `Create a title for a youtube video that will be engaging and will make people click it. 
            The title should be well balanced between typical clickbait and a dull title where there are only 
            informations about the video. 
            The title should be very interesting and engading. It should provide a benefit for the viewer. 
            The video is for english learners for level ${this.videoLevel} and. 
            Adjust the title to be speciffically tailored and interesting to the viewers level, for example add words like 'for beginners' etc.
            The title should have maximum of 90 characters.  In 3 examples use questions.
            The video  has ${this.selectedNumberOfWords} 
            words and is in the topic of ${this.selectedVideoTopic} ${this.selectedVideoMechanics}.
            Give me 5 options. Return a json with format: {type:"titles", options:[]}.`,
          placeholderText: 'Tworzę tytuł...'
        }
      case 5:
        this.selectedVideoTitle = input

        this.steps[5].promptToSend = {
          prompt: `Create a description for a youtube video that will be engaging and will make people click it. 
          The description should be well balanced between typical clickbait and a dull title where there are only 
          informations about the video. 
          The description should be very interesting and engading. It should provide a benefit for the viewer.
          Use the markdown formatting language. For example, to make a text bold, use **text** 
          The video is for english learners for level ${this.videoLevel} and ${this.selectedVideoMechanics} 
          and has title ${this.selectedVideoTitle} and has a thumbnail text ${this.selectedThumbnailText}.
          Give me 2 options. Return a json with format: {type:"descriptions", options:[]}.`,
          placeholderText: 'Tworzę opis...'
        }
        break;
      case 6:
        this.selectedVideoDescription = input
        this.steps[6].promptToSend = {
          prompt: `Create a set of tags for a youtube video that will make youtube recommend the video to the right people.
          Make sure that the tags are relevant to the video's topic and that they are not misleading.
          The tags shuld make the algorithm understand the topic of the video. 
          The video is for english learners for level ${this.videoLevel} and ${this.selectedVideoMechanics} 
          and has title ${this.selectedVideoTitle} and has a thumbnail text ${this.selectedThumbnailText}.
          The tags should consist of 50 characters.
          Return a json with format: {type:"tags", options:['tag1, tag2, tag3...']}.`,
          placeholderText: 'Tworzę tagi...'
        }
        break;
      case 7:
        this.selectedTags = step.options
        this.steps[7].promptToSend = {
          prompt: `Create a text for the first part of the video introduction that will make the viewer feel good and that will make the viewer feel that the video is worth watching.
          The text should more-less repeat the title and the thumbnail text but in a different way. This will be first of 3 paragraphs.
          This message should have 60-90 characters.
          The narrator is a teacher with a name ${this.selectedTeacherName} and he or she should introduce himself or herself in the text.
          The video is for english learners for level ${this.videoLevel} and ${this.selectedVideoMechanics} 
          and has title ${this.selectedVideoTitle} and has a thumbnail text ${this.selectedThumbnailText}.

          Adjust the message to be speciffically tailored and interesting to the viewers level.

          Give me 2 options.
          Return a json with format: {type:"text", options:[]}.`,
          placeholderText: 'Tworzę tekst wstępu 1...'
        }
        break;
      case 8:
        this.selectedIntroText1 = input
        this.steps[8].promptToSend = {
          prompt: `Create a text for the second part of the video introduction that will summarize what the video is about and how it works and what we will do in the video.
         The first part of introduction is ${this.selectedIntroText1}  This will be first of 3 paragraphs.
         Adjust the message to be speciffically tailored and interesting to the viewers level.
         This message should have 60-90 characters.          The video is for english learners for level ${this.videoLevel} and ${this.selectedVideoMechanics} 
          and has title ${this.selectedVideoTitle} and has a thumbnail text ${this.selectedThumbnailText}.
          Give me 2 options.
          Return a json with format: {type:"text", options:[]}.`,
          placeholderText: 'Tworzę tekst wstępu 2...'
        }
        break;
      case 9:
        this.selectedIntroText2 = input
        this.steps[9].promptToSend = {
          prompt: `Create the last paragraph of a introductionaly script for the teacher in a language learning video that will tell the viewer what they will gain from watching the video and staying until the end (but don't promise any extras or bonuses at the end), encourage the viewer to interact in comments. At the end add something like: Remember to like and subscribe
         The first part of introduction is ${this.selectedIntroText1}, and the second is ${this.selectedIntroText2}  This will be last of 3 paragraphs.
         Adjust the message to be speciffically tailored and interesting to the viewers level.
         This message should have 90-120 characters. The video is for english learners for level ${this.videoLevel} and ${this.selectedVideoMechanics} 
          and has title ${this.selectedVideoTitle} and has a thumbnail text ${this.selectedThumbnailText}.
          Give me 4 options.
          Return a json with format: {type:"text", options:[]}.`,
          placeholderText: 'Tworzę tekst wstępu 3...'
        }
      case 10:
        this.selectedIntroText3 = input
        break;

      default:
        break;
    }

    if (step.promptToSend) {
      this.chatService.sendPromptToChosenAi({
        messages: [{ role: 'user', content: step.promptToSend.prompt + 'return only json, nothing else' }],
        aiProvider: this.chosenChatBot.aiProvider,
        model: this.chosenChatBot.model,
        maxTokens: this.chosenChatBot.maxTokens,
        systemMsg: this.chosenChatBot.systemMsg,
        files: null
      }).subscribe((response) => {

        if (response.res.content) {
          this.steps[this.currentStepIndex].options = this.extractJsonFromText(response.res.content).options
        }
      })
    }
    this.currentStepIndex++
  }
  downloadTextFile() {
    this.textData = `ThumbnailText: ${this.selectedThumbnailText} \n\n Title: ${this.selectedVideoTitle} \n\n Description: ${this.selectedVideoDescription} \n\n Tags: ${this.fixedTags + this.selectedTags} \n\n Intro1: ${this.selectedIntroText1} \n\n Intro2: ${this.selectedIntroText2} \n\n Intro3: ${this.selectedIntroText3} \n\n voice: ${this.selectedTeachersVoice}`
    const element = document.createElement('a');
    const file = new Blob([this.textData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = this.selectedVideoTitle + '.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  extractJsonFromText(text: string) {
    const jsonStart = text.indexOf('{');
    const jsonEnd = text.lastIndexOf('}');
    if (jsonStart === -1 || jsonEnd === -1) {
      return null;
    }
    return JSON.parse(text.substring(jsonStart, jsonEnd + 1));
  }
}
