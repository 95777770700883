import { Injectable } from '@angular/core';
import { types } from 'util';
import { HelpersService } from '../shared/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class Pdf2ExercisesService {
  allPdfTypes = [
  ]
  avatars = [
    'assets/avatars/animals-in-clothes/b1.png',
    'assets/avatars/animals-in-clothes/b2.png',
    'assets/avatars/animals-in-clothes/b3.png',
    'assets/avatars/animals-in-clothes/b4.png',
    'assets/avatars/animals-in-clothes/b5.png',
    'assets/avatars/animals-in-clothes/b6.png',
    'assets/avatars/animals-in-clothes/b7.png',
    'assets/avatars/animals-in-clothes/b8.png',
    'assets/avatars/monsters/b1.png',
    'assets/avatars/monsters/b2.png',
    'assets/avatars/monsters/b3.png',
    'assets/avatars/monsters/b4.png',
    'assets/avatars/monsters/b5.png',
    'assets/avatars/monsters/b6.png',
    'assets/avatars/monsters/b7.png',
    'assets/avatars/monsters/b8.png',
    'assets/avatars/oldschool_wizard/b1.png',
    'assets/avatars/oldschool_wizard/b2.png',
    'assets/avatars/oldschool_wizard/b3.png',
    'assets/avatars/oldschool_wizard/b4.png',
    'assets/avatars/oldschool_wizard/b5.png',
    'assets/avatars/oldschool_wizard/b6.png',
    'assets/avatars/oldschool_wizard/b7.png',
    'assets/avatars/oldschool_wizard/b8.png',
    'assets/avatars/cactus-succulents/b1.png',
    'assets/avatars/cactus-succulents/b2.png',
    'assets/avatars/cactus-succulents/b3.png',
    'assets/avatars/cactus-succulents/b4.png',
    'assets/avatars/cactus-succulents/b5.png',
    'assets/avatars/cactus-succulents/b6.png',
    'assets/avatars/cactus-succulents/b7.png',
    'assets/avatars/cactus-succulents/b8.png',
    'assets/avatars/pug_in_disguise/b1.png',
    'assets/avatars/pug_in_disguise/b2.png',
    'assets/avatars/pug_in_disguise/b3.png',
    'assets/avatars/pug_in_disguise/b4.png',
    'assets/avatars/pug_in_disguise/b5.png',
    'assets/avatars/pug_in_disguise/b6.png',
    'assets/avatars/pug_in_disguise/b7.png',
    'assets/avatars/pug_in_disguise/b8.png',
    'assets/avatars/unicorns_in_disguise/b1.png',
    'assets/avatars/unicorns_in_disguise/b2.png',
    'assets/avatars/unicorns_in_disguise/b3.png',
    'assets/avatars/unicorns_in_disguise/b4.png',
    'assets/avatars/unicorns_in_disguise/b5.png',
    'assets/avatars/unicorns_in_disguise/b6.png',
    'assets/avatars/unicorns_in_disguise/b7.png',
    'assets/avatars/unicorns_in_disguise/b8.png',
    'assets/avatars/animals-in-clothes/p1.png',
    'assets/avatars/animals-in-clothes/p2.png',
    'assets/avatars/animals-in-clothes/p3.png',
    'assets/avatars/animals-in-clothes/p4.png',
    'assets/avatars/animals-in-clothes/p5.png',
    'assets/avatars/animals-in-clothes/p6.png',
    'assets/avatars/animals-in-clothes/p7.png',
    'assets/avatars/animals-in-clothes/p8.png',
    'assets/avatars/monsters/p1.png',
    'assets/avatars/monsters/p2.png',
    'assets/avatars/monsters/p3.png',
    'assets/avatars/monsters/p4.png',
    'assets/avatars/monsters/p5.png',
    'assets/avatars/monsters/p6.png',
    'assets/avatars/monsters/p7.png',
    'assets/avatars/monsters/p8.png',
    'assets/avatars/oldschool_wizard/p1.png',
    'assets/avatars/oldschool_wizard/p2.png',
    'assets/avatars/oldschool_wizard/p3.png',
    'assets/avatars/oldschool_wizard/p4.png',
    'assets/avatars/oldschool_wizard/p5.png',
    'assets/avatars/oldschool_wizard/p6.png',
    'assets/avatars/oldschool_wizard/p7.png',
    'assets/avatars/oldschool_wizard/p8.png',
    'assets/avatars/cactus-succulents/p1.png',
    'assets/avatars/cactus-succulents/p2.png',
    'assets/avatars/cactus-succulents/p3.png',
    'assets/avatars/cactus-succulents/p4.png',
    'assets/avatars/cactus-succulents/p5.png',
    'assets/avatars/cactus-succulents/p6.png',
    'assets/avatars/cactus-succulents/p7.png',
    'assets/avatars/cactus-succulents/p8.png',
    'assets/avatars/pug_in_disguise/p1.png',
    'assets/avatars/pug_in_disguise/p2.png',
    'assets/avatars/pug_in_disguise/p3.png',
    'assets/avatars/pug_in_disguise/p4.png',
    'assets/avatars/pug_in_disguise/p5.png',
    'assets/avatars/pug_in_disguise/p6.png',
    'assets/avatars/pug_in_disguise/p7.png',
    'assets/avatars/pug_in_disguise/p8.png',
    'assets/avatars/unicorns_in_disguise/p1.png',
    'assets/avatars/unicorns_in_disguise/p2.png',
    'assets/avatars/unicorns_in_disguise/p3.png',
    'assets/avatars/unicorns_in_disguise/p4.png',
    'assets/avatars/unicorns_in_disguise/p5.png',
    'assets/avatars/unicorns_in_disguise/p6.png',
    'assets/avatars/unicorns_in_disguise/p7.png',
    'assets/avatars/unicorns_in_disguise/p8.png',
  ];
  waiters = [32];

  childrenPdfTypes = [
    {
      name: 'children-tracing-easy',
      displayName: 'Ślad i dopasowanie',
      selected: false,
      descriptionPl: 'Popraw słowa po śladzie i połącz z tłumaczeniami.',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-tracing-supereasy',
      displayName: 'Pokoloruj napisy',
      selected: false,
      descriptionPl: 'Zamaluj angielskie napisy.',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-fill-in-2-letters',
      displayName: 'Uzupełnij 2 litery',
      selected: false,
      descriptionPl: 'Wpisz brakujące litery.',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-fill-write-sentences',
      displayName: 'Napisz 4 dowolne zdania',
      selected: false,
      descriptionPl: 'Napisz zdania.',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-scramble',
      displayName: 'Rozsypanka',
      selected: false,
      descriptionPl: 'Rozszyfruj słowa',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-scramble-harder',
      displayName: 'Rozsypanka (trudniejsza)',
      selected: false,
      descriptionPl: 'Rozszyfruj słowa i dopasuj je do obrazków',
      descriptionEn: 'Write words and connect them to translations.',
      type: 'children',
    },
    {
      name: 'children-dictionary',
      displayName: 'Słowniczek',
      selected: false,
      descriptionPl: 'Słowniczek.',
      descriptionEn: 'Dictionary.',
      type: 'children',
    },
    {
      name: 'children-rewrite-word-from-sentence',
      displayName: 'Przepisz słowo ze zdania',
      selected: false,
      descriptionPl: 'Przepisz słowo ze zdania.',
      descriptionEn: 'Rewrite word from sentence.',
      type: 'children',
    },
    {
      name: 'children-choose-word-from-two',
      displayName: 'Wybierz słowo z dwóch',
      selected: false,
      descriptionPl: 'Skreśl niewłaściwe słowo.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-snake',
      displayName: 'Ślimak',
      selected: false,
      descriptionPl: 'Wyszukaj słowa.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-words',
      displayName: 'Uzupełnij słowa',
      selected: false,
      descriptionPl: 'Uzupełnij słowa',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-sentences-easy',
      displayName: 'Uzupełnij zdania',
      selected: false,
      descriptionPl: 'Uzupełnij zdania',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-make-sentences-easy',
      displayName: 'Utwórz zdania z rozsypanki',
      selected: false,
      descriptionPl: 'Utwórz zdania z rozsypanki',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-fill-in-words-harder',
      displayName: 'Uzupełnij słowa (trudniejsze)',
      selected: false,
      descriptionPl: 'Wpisz słowa',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },

    {
      name: 'children-wordsearch',
      displayName: 'Wyszukaj słowa',
      selected: false,
      descriptionPl: 'Wyszukaj słowa.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
    {
      name: 'children-crossword',
      displayName: 'Krzyżówka',
      selected: false,
      descriptionPl: 'Uzupełnij krzyżówkę.',
      descriptionEn: 'Cross out wrong word.',
      type: 'children',
    },
  ];
  sentencesPdfTypes = [
    {
      name: 'sentences-fill-gaps-no-clues',
      displayName: 'Uzupełnij luki bez podpowiedzi',
      selected: false,
      descriptionPl: 'Uzupełnij luki.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-picture-dictionary',
      displayName: 'Słowniczek obrazkowy',
      selected: false,
      numberOfItems: 32,
      descriptionPl: 'Dopasuj słowa do obrazków.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-picture-dictionary-single',
      displayName: 'Słowniczek obrazkowy - pojedynczy',
      selected: false,
      numberOfItems: 16,
      descriptionPl: 'Dopasuj słowa do obrazków.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-picture-dictionary-single-20',
      displayName: 'Słowniczek obrazkowy - pojedynczy 20',
      selected: false,
      numberOfItems: 20,
      descriptionPl: 'Dopasuj słowa do obrazków.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-dialogue-fill-gaps',
      displayName: 'Uzupełnij luki w dialogu',
      selected: false,
      descriptionPl: 'Uzupełnij luki w dialogu',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-fill-gaps',
      displayName: 'Uzupełnij luki w tekście',
      selected: false,
      descriptionPl: 'Uzupełnij luki w tekście',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-make-sentences',
      displayName: 'Utwórz zdania z rozsypanki',
      selected: false,
      descriptionPl: 'Ułóż zdania z rozsypanki',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-make-sentences-with-translations',
      displayName: 'Utwórz zdania z rozsypanki z podpowiedziami',
      selected: false,
      descriptionPl: 'Ułóż zdania z rozsypanki z podpowiedziami',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-make-sentences-write-translations',
      displayName: 'Utwórz zdania z rozsypanki i napisz tłumaczenie zdań',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku (fioletowe pole) i po polsku (zielone pole)',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations',
      displayName: 'Napisz zdania z tłumaczeń ',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations-clues-25',
      displayName: 'Napisz zdania z tłumaczeń 1/4 uzupełnione ',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations-clues-50',
      displayName: 'Napisz zdania z tłumaczeń 1/2 uzupełnione',
      selected: false,
      numberOfItems: 9,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations-clues-5-25',
      displayName: 'Napisz zdania z tłumaczeń 1/4 uzupełnione 5',
      selected: false,
      numberOfItems: 5,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations-clues-5-50',
      displayName: 'Napisz zdania z tłumaczeń 1/2 uzupełnione 5',
      selected: false,
      numberOfItems: 5,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-in-badges-from-translations-clues-75',
      displayName: 'Napisz zdania z tłumaczeń 2/3 uzupełnione',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-write-sentences-from-choices',
      displayName: 'Napisz zdania z wyboru',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-translate-sentences-from-english-with-words-translation',
      displayName: 'Napisz zdania ze słowami',
      selected: false,
      descriptionPl: 'Przetłumacz słowa i napisz zdania po polsku. Jeśli nie znasz jakiegoś słowa, sprawdź je w słowniku.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-change-3-words',
      displayName: 'Zmień 3 słowa ',
      selected: false,
      descriptionPl: 'Zmień 3 słowa w kazdym zdaniu ',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-text-change-3-words-with-translations',
      displayName: 'Zmień 3 słowa z podpowiedziami',
      selected: false,
      descriptionPl: 'Zmień 3 słowa w kazdym zdaniu ',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Wejściówka dorośli 10 słów',
      selected: false,
      numberOfItems: 10,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: 'Fill in the gaps.',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Wejściówka dorośli 15 słów',
      selected: false,
      numberOfItems: 15,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: 'Fill in the gaps',
      type: 'sentences',
    },
    {
      name: 'sentences-fill-gaps-clues',
      displayName: 'Wejściówka dorośli 20 słów',
      selected: false,
      numberOfItems: 20,
      descriptionPl: 'Uzupełnij luki z podpowiedziami',
      descriptionEn: 'Fill in the gaps',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-mixed',
      displayName: 'Przetłumacz i dopasuj słowa',
      selected: false,
      descriptionPl:
        'Przetłumacz i dopasuj słowa, a następnie zapisz je po angielsku obok tłumaczeń.',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps',
      displayName: 'Przetłumacz i uzupełnij luki 2 strony',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-mixed+sentences-fill-gaps-no-clues',
      type: 'sentences',
    },
    {
      name: 'text+3Times+freeset-sentences-dictionary3',
      allItems: true,
      displayName: 'Uzupełnij luki + 3xpytania',
      inOrder: true,
      showRings: false,
      preset: 'text+3Times+freeset-sentences-dictionary3',
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Napisz odpowiedzi.',
      forGaps: false,
      numberOfItems: 10,
      type: 'freeset',
    },

    {
      name: 'picture-write-answer-with-answers',
      allItems: true,
      displayName: 'Obrazek - napisz odpowiedź + odpowiedzi',
      inOrder: true,
      showRings: false,
      preset: 'picture-write-answer+answers-gaps',
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Napisz odpowiedzi.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'sentences-translate-fill-gaps-single-page',
      displayName: 'Przetłumacz i uzupełnij luki 1 strona',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-mixed+sentences-fill-gaps-no-clues-single-page',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-single-page-write-sntences-50%',
      displayName: 'Business English - p1',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-mixed+sentences-fill-gaps-no-clues-single-page-write-sntences-50%',
      type: 'sentences',
    },
    {
      name: 'entry-test-group',
      displayName: 'Wejściówka grupy',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-picture-dictionary+sentences-text-make-sentences-with-translations',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-single-page',
      displayName: 'słowniczek obrazkowy i uzupełnij luki 1 strona',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-picture-dictionary+sentences-fill-gaps-no-clues-single-page',
      type: 'sentences',
    },

    {
      name: 'sentences-translate-fill-gaps-random-letters',
      displayName: 'Przetłumacz i uzupełnij luki 2 strony z losowymi literami',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset:
        'sentences-translate-mixed+sentences-fill-gaps-no-clues-random-letters',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-all-items',
      displayName: 'Przetłumacz i uzupełnij luki wszystkie słowa z zestawu',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-fill-gaps-all-items',
      type: 'sentences',
    },
    {
      name: 'sentences-wordsearch',
      displayName: 'Wyszukiwanka - zdania',
      selected: false,
      numberOfItems: 10,

      descriptionPl: 'Wyszukaj podane słowa',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-crossword',
      displayName: 'Krzyówka - zdania',
      selected: false, numberOfItems: 12,

      descriptionPl: 'Uzupełnij krzyżówkę',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-snake',
      displayName: 'Wąż - zdania',
      selected: false,
      numberOfItems: 12,
      descriptionPl: 'Wyszukaj podane słowa',
      descriptionEn: '********',
      type: 'sentences',
    },
    {
      name: 'sentences-translate-fill-gaps-all-items',
      displayName: 'Wejściówka grupy (Zostaw 2 pierwsze strony)',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-fill-gaps-all-items',
      type: 'sentences',
    },
    {
      name: 'sentences-flashcards-all-items',
      displayName: 'Flashcards - printable',
      preset: 'sentences-flashcards-all-items',
      selected: false,
      numberOfItems: 12,
      descriptionPl: '********',
      descriptionEn: '********',
      type: 'printable',
    },
    {
      name: 'sentences-translate-fill-gaps-all-items-all-sets',
      displayName:
        'Przetłumacz i uzupełnij luki wszystkie słowa ze wszystkich zestawów',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'sentences-translate-fill-gaps-all-items-all-sets',
      multiSet: true,
      type: 'sentences',
    },
  ];
  freeSetPdfTypes: any = [
    {
      name: 'fold-story',
      displayName: 'Fold Story',
      descriptionPl: 'Odpowiedz na jedno pytanie, złóż i podaj dalej kartkę, aby kontynuować historię!',
      descriptionEn: 'Answer one question and fold the paper to continue the story! ',
      numberOfItems: 10,
      showRings: false,
      preset: false,
      inOrder: true,
      forGaps: false,
      allItems: false,
      exercises: [
        {
          text: 'Answer one question at a time, then fold the paper to reveal the next part of the story!'
        }
      ]
    },
    {
      name: 'text-gap-fill-with-translations',
      displayName: 'Tekst z lukami',
      descriptionPl: 'Uzupełnij luki w tekście.',
      descriptionEn: 'Fill in the gaps in the text.',
      numberOfItems: 0, // Will be determined based on questions
      showRings: false,
      preset: false,
      inOrder: true,
      forGaps: true,
      allItems: true,
      exercises: [
        {
          text: 'Uzupełnij luki odpowiednimi słowami z ramki.'
        }
      ]
    },
    {
      name: 'translations-from-text',
      displayName: 'Tłumaczenia z tekstu',
      descriptionPl: 'Przetłumacz podkreślone słowa w tekście.',
      descriptionEn: 'Translate the underlined words from the text.',
      numberOfItems: 0, // Will be determined based on text
      showRings: false,
      preset: false,
      inOrder: true,
      forGaps: false,
      allItems: true,
      exercises: [
        {
          text: 'Przetłumacz podkreślone słowa na język polski. Wartość pola itemsPack określa procent słów do przetłumaczenia (domyślnie 20%).'
        }
      ]
    },
    {
      name: 'translations-from-text-pdf',
      displayName: 'Tłumaczenia z tekstu PDF',
      descriptionPl: 'Przetłumacz podkreślone słowa w tekście.',
      descriptionEn: 'Translate the underlined words from the text.',
      numberOfItems: 0, // Will be determined based on text
      showRings: false,
      itemsPack: 100,
      preset: false,
      inOrder: true,
      forGaps: false,
      allItems: true,
      exercises: [
        {
          text: 'Przetłumacz podkreślone słowa na język polski. Wartość pola itemsPack określa procent słów do przetłumaczenia (domyślnie 20%).'
        }
      ]
    },
    {
      name: 'detective-mystery-game',
      displayName: 'Detective Mystery Game',
      description: 'Solve a mystery by completing language exercises',
      numberOfItems: 15,
      showRings: false,
      preset: false,
      inOrder: true,
      forGaps: false,
      allItems: false,
      exercises: [
        {
          text: 'Solve the mystery by finding the correct symbols for each category. Each correct answer unlocks a new clue!'
        }
      ]
    },
    {
      name: 'detective-mystery-game-story',
      displayName: 'Detective Mystery Game Story',
      description: 'Read the detective story with clues to solve the mystery',
      numberOfItems: 1,
      showRings: false,
      preset: false,
      inOrder: true,
      forGaps: false,
      allItems: false,
      exercises: [
        {
          text: 'Read the story carefully to find clues that will help you solve the mystery on the next page!'
        }
      ]
    },
    {
      name: 'detective-mystery-game-two-page',
      displayName: 'Detective Mystery Game (Two Pages)',
      description: 'Two-page detective mystery with story and questions',
      selected: false,
      preset: 'detective-mystery-game-two-page',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues',
      displayName: 'zadania z luką + odpowiedzi wszystko z zestawu',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues-single-exercise',
      displayName: 'zadania z luką + odpowiedzi pojedyncze zadanie',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues-single-exercise',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-make-sentencesPlEn',
      displayName: 'Utwórz zdania z rozsypanki PL-EN',
      selected: false,
      descriptionPl: 'Utwórz zdania z rozsypanki',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'freeset-make-sentencesPlEnWriteTranslations',
      displayName: 'Utwórz zdania z rozsypanki i przetłumacz PL-EN',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku (fioletowe pole) i po polsku (szare pole)',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'freeset-make-sentencesPlEnWriteTranslations',
      displayName: 'Utwórz zdania z rozsypanki i napisz w są jest czasie PL-EN',
      selected: false,
      descriptionPl: 'Napisz zdania po angielsku (fioletowe pole) i napisz w jakim jest czasie i dlaczego (szare pole) Napisz dlaczego ten czas został użyty.',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'freeset-make-sentencesPlEnWTranslations',
      displayName: 'Utwórz zdania z rozsypanki z podpowiedziami PL-EN',
      selected: false,
      descriptionPl: 'Utwórz zdania z rozsypanki ',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'fill-gaps-answers-no-clues-single-exercise',
      displayName: 'zadania z bez luki odpowiedzi pojedyncze zadanie',
      selected: false,
      descriptionPl: '********',
      descriptionEn: '********',
      preset: 'fill-gaps-answers-no-clues-single-exercise',
      type: 'freeset',
    },

    {
      name: 'answers-gaps',
      displayName: 'Odpowiedzi',
      selected: false,
      descriptionPl: 'Odpowiedzi',
      descriptionEn: '********',
      type: 'freeset',
    },
    {
      name: 'freeset-fill-gaps-sentences-clues',
      displayName: 'Uzupełnij luki z podpowiedziami',
      selected: false,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-lesson-text-with-exercises',
      displayName: 'Lekcja - tekst z ćwiczeniami p1',
      selected: false,
      fullPage: true,
      hidden: true,
      orientation: 'landscape',
      numberOfItems: 1,
      itemsPack: -2, // so the page doesnt steal one item from the second page
      inOrder: true,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-lesson-text-with-exercises-p1p2',
      displayName: 'Lekcja - tekst z ćwiczeniami',
      selected: false,
      fullPage: true,
      preset: 'freeset-lesson-text-with-exercises-p1+free-lesson-text-with-exercises-p2',
      orientation: 'landscape',
      numberOfItems: 1,
      itemsPack: -1, // so the page doesnt steal one item from the second page
      descriptionPl: 'Uzupełnij luki',
      inOrder: true,
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-business-p2',
      displayName: 'Business English - p2 (S)',
      selected: false,
      fullPage: true,
      inOrder: true,
      preset: 'freeset-business-p2',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-text-3',
      displayName: 'Business English - p2 (S)',
      selected: false,
      fullPage: true,
      inOrder: true,
      preset: 'freeset-text-3',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-lesson-text-with-exercises-p2',
      displayName: 'Lekcja - tekst z ćwiczeniami p2',
      selected: false,
      fullPage: true,
      hidden: true,
      inOrder: true,
      orientation: 'landscape',
      numberOfItems: 20,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-fill-gaps-sentences-no-clues',
      displayName: 'Uzupełnij luki bez podpowiedzi',
      selected: false,
      descriptionPl: 'Uzupełnij luki',
      descriptionEn: '********',
      forGaps: true,
      type: 'freeset',
    },
    {
      name: 'freeset-no-gaps-translate-answer-clues',
      displayName: 'Wpisz właściwą odpowiedź z podpowiedziami',
      selected: false,
      descriptionPl: 'Wpisz odpowiednie słowo w lukę',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-no-gaps-translate-answer',
      displayName: 'Wpisz właściwą odpowiedź bez podpowiedzi',
      selected: false,
      descriptionPl: 'Wpisz odpowiednie słowo w lukę',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-text-fill-gaps',
      displayName: 'Uzupełnij luki w tekście',
      selected: false,
      numberOfItems: 1,
      descriptionPl: 'Uzupełnij luki w tekście',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'dialogues-match',
      displayName: 'Dopasuj dialogi (wszystko z zestawu)',
      inOrder: true,
      allItems: true,
      selected: false,
      descriptionPl: 'Dopasuj zdania',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'reading-with-questions-with-clues',
      allItems: true,
      displayName: 'Czytanie z podpowiedziami',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-choose-answer',
      allItems: true,
      displayName: 'Obrazek - wybierz odpowiedź T/F',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-choose-answer-YN',
      allItems: true,
      displayName: 'Pytania wybierz odpowiedź Y/N',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-write-answer-YN',
      allItems: true,
      displayName: 'Pytania napisz odpowiedź Y/N',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Napisz właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-findSomeoneWho-childrenYN',
      allItems: true,
      displayName: 'Find Someone Who children Y/N',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwą odpowiedź',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-findSomeoneWho-older',
      allItems: true,
      displayName: 'Find Someone Who older',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Find someone who...',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-findSomeoneWho-childrenWA',
      allItems: true,
      displayName: 'Find Someone Who children WA',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Napisz odpowiedzi na pytania (Yes, I... / No, I...)',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-choose-answerFS',
      allItems: true,
      displayName: 'Pytania wybierz odpowiedź FS',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz w kółko właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-write-answerFS',
      allItems: true,
      displayName: 'Pytania napisz odpowiedź FS',
      inOrder: true,
      selected: false,
      showRings: false,
      descriptionEn: '********',
      descriptionPl: 'Napisz właściwe odpowiedzi',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'picture-write-answer',
      allItems: true,
      displayName: 'Obrazek - napisz odpowiedź',
      inOrder: true,
      showRings: false,

      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Popraw zdania. Jeśli zdanie jest prawidłowe, przepisz je, jeśli nie, popraw je.',
      forGaps: false,
      type: 'freeset',
    },

    {
      name: 'freeset-story-text',
      preset: 'freeset-story-text',
      allItems: false,
      displayName: 'Historia - Tekst',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Przeczytaj tekst.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-sentences-dictionary',
      allItems: false,
      displayName: 'Zdania - słownik',
      numberOfItems: 30,
      selected: false,
      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-sentences-dictionary2',
      allItems: false,
      displayName: 'Zdania - słownik2',
      numberOfItems: 30,
      selected: false,
      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-sentences-dictionary3',
      allItems: false,
      displayName: 'Zdania - słownik3 (tylko pytania, 10 elementów)',
      numberOfItems: 10,
      selected: false,
      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',

    },

    {
      name: 'freeset-sentences-match',
      allItems: false,
      displayName: 'Zdania - dopasuj',
      selected: false,
      numberOfItems: 30,

      descriptionEn: '********',
      descriptionPl: '',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'freeset-story-snail',
      allItems: false,
      displayName: 'Historia - ślimak',
      inOrder: true,
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Zaznacz słowa w ślimaku.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'reading-with-questions-with-clues',
      allItems: true,
      displayName: 'Ułóż historię w kolejności',
      selected: false,
      descriptionEn: '********',
      descriptionPl: 'Wpisz numerki zdań w odpowiedniej kolejności.',
      forGaps: false,
      type: 'freeset',
    },
    {
      name: 'conversations-choose',
      allItems: true,
      displayName: 'Konwersacja z podpowiedziami',
      inOrder: true,
      selected: false,
      descriptionPl: 'Zaznacz dowolną odpowiedź',
      descriptionEn: '********',
      forGaps: false,
      type: 'freeset',
    },
  ];
  constructor(private helpers: HelpersService) {

    this.allPdfTypes = this.childrenPdfTypes
      .concat(this.freeSetPdfTypes)
      .concat(this.sentencesPdfTypes);
  }
  getDescription(taskName, taskDisplayName, lang: 'pl' | 'en') {
    let result = '';
    let type;
    let types = this.helpers.flatenArray(
      this.childrenPdfTypes
        .concat(this.freeSetPdfTypes)
        .concat(this.sentencesPdfTypes)
    );
    if (lang == 'pl') {
      type = types.find((_type) => _type.name === taskName);
      result = type?.descriptionPl;
    }
    if (lang == 'en') {
      type = types.find((_type) => _type.name === taskName);
      result = type?.descriptionEn;
    }

    return result;
  }
}
