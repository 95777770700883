import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { CoursePlanItem } from './courses-plan-item/course-plan-item';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoursePlanService } from '../course-add-edit/course-plan/course-plan.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CoursePlanComponent } from '../course-add-edit/course-plan/course-plan.component';
import { set } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FolderService } from './folder.service';

@Component({
  selector: 'app-courses-plan',
  templateUrl: './courses-plan.component.html',
  styleUrls: ['./courses-plan.component.scss']
})
export class CoursesPlanComponent {
  @ViewChild('folderContentModal') folderContentModal: TemplateRef<any>;

  editMode = false
  planItems = []
  folders = []
  selectedItems = []
  index
  panelOpenState = -1
  addingElement = false
  originalFolders = []
  sub
  sub2: any;
  itemToCopy: any;
  showArchived = false;
  courseResources = [];
  selectedFolder = null;
  currentModalRef: MatDialogRef<any> = null;

  constructor(
    private helpers: HelpersService,
    public coursesService: CoursesService,
    @Inject(MAT_DIALOG_DATA) public data,
    private coursePlanService: CoursePlanService,
    private dialogRef: MatDialogRef<CoursePlanComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private folderService: FolderService
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.courseResources) {
      this.courseResources = this.data.courseResources;
      console.log('Loaded courseResources from data:', this.courseResources);
    } else {
      console.warn('No courseResources found in data');
    }

    this.sub2 = this.coursePlanService.itemSelectedListener().subscribe(selectedItem => {
      this.addRemoveItems(selectedItem)
    })

    // Subscribe to the folder service to get the current folder
    this.folderService.getCurrentFolder().subscribe(folder => {
      if (folder) {
        this.selectedFolder = folder;

        // If the modal is open, update its content
        if (this.currentModalRef && this.currentModalRef.componentInstance) {
          // Check if componentInstance.data exists, if not initialize it
          if (!this.currentModalRef.componentInstance.data) {
            this.currentModalRef.componentInstance.data = {};
          }

          // Update the modal data
          this.currentModalRef.componentInstance.data = {
            folder: folder,
            addMode: this.data?.addMode,
            courseResources: this.courseResources || []
          };

          // Prepare the items for the modal
          this.prepareItemsForModal(folder);
        }
      }
    });

    // Subscribe to the folder service to get the folders
    this.folderService.getFolders().subscribe(folders => {
      if (folders && folders.length > 0) {
        this.folders = folders;
      }
    });

    this.sub = this.coursesService.coursesPlanItemsUpdatedListener().subscribe((planItems: any) => {
      if (!planItems.newItemId) {
        this.planItems = planItems
        this.createFolders()
      }
      else {
        let itemToUpdate = this.planItems.findIndex(item => item.id === 'temp')
        if (itemToUpdate > -1) {
          this.planItems[itemToUpdate].id = planItems.newItemId
          this.addingElement = false
        }
      }
    })

    // Initial load of plan items
    this.coursesService.getPlanItemsList()
  }

  // Initialize and validate folder items before opening the modal
  prepareItemsForModal(folder) {

    if (!folder || !folder.items) {
      console.warn('Cannot prepare items for invalid folder');
      return;
    }

    // Make sure all items in the folder have necessary properties initialized
    folder.items.forEach(item => {
      if (!item) return;

      // Initialize selected property
      if (item.selected === undefined) {
        item.selected = false;
      }

      // Make sure tags array exists
      if (!item.homework) {
        item.homework = [];
      }

      // Make sure skills array exists
      if (!item.skills) {
        item.skills = [];
      }

      // Ensure courseResources is available to the item
      item.courseResources = this.courseResources || [];

      // Update selected state based on global selectedItems
      const isSelected = this.selectedItems.some(selected =>
        selected.item && item && selected.item.id === item.id
      );

      // Set selected property on item
      item.selected = isSelected;
    });
  }

  // Open folder modal instead of expanding in-place
  openFolderModal(folder) {
    if (!folder) {
      console.error('Attempted to open modal with null folder');
      return;
    }

    // Initialize folder search before opening modal
    this.findInFolder(folder, '');

    // Prepare items for the modal
    this.prepareItemsForModal(folder);

    // Prepare the modal data
    const modalData = {
      folder: folder,
      addMode: this.data?.addMode,
      courseResources: this.courseResources || []
    };

    // Open dialog with the folder content template
    this.currentModalRef = this.dialog.open(this.folderContentModal, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog',
      data: modalData
    });

    // Register the modal with the folder service
    this.folderService.setCurrentModal(this.currentModalRef, modalData);

    // Show a snackbar notification
    this.snackBar.open(`Otwarto folder: ${folder.name}`, 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['info-snackbar']
    });

    // Update selected folder in the component and the service
    this.selectedFolder = folder;
    this.folderService.setCurrentFolder(folder);

    // Handle dialog closing
    this.currentModalRef.afterClosed().subscribe(() => {
      // Update the main folder list with any changes made in the modal
      const updatedFolder = this.folders.find(f => f.name === folder.name);
      if (updatedFolder) {
        // Transfer selected items back to the main component
        updatedFolder.items.forEach(item => {
          if (item && item.selected) {
            this.addRemoveItems({ selected: true, item: item });
          }
        });
      }

      // Keep the reference to the folder in the list, but clear the modal reference
      this.currentModalRef = null;

      // Clear the modal reference in the folder service
      this.folderService.clearCurrentModal();
      // We don't set selectedFolder to null here to avoid affecting the folder list
    });
  }

  close(): void {
    // Transform selected items to the format expected by CourseAddEditComponent
    // Based on how course-plan.component.ts processes these items
    // Filter out any temporary or invalid items to prevent component hangs
    const validItems = this.selectedItems.filter(selectedItem =>
      selectedItem.item &&
      selectedItem.item.id !== 'temp' &&
      selectedItem.item.id !== undefined
    );

    // Close the dialog with the selected items
    this.dialogRef.close(validItems);

    // Ensure the document body has proper overflow settings
    document.body.style.overflow = 'auto';
  }

  closeFolderModal(): void {
    // Only close the folder content modal if it exists
    if (this.currentModalRef) {
      this.currentModalRef.close();
      this.currentModalRef = null;

      // Clear the modal reference in the folder service
      this.folderService.clearCurrentModal();
    }
  }

  addRemoveItems(event) {
    if (!event || !event.item) return;

    // Skip temporary or invalid items
    if (event.item.id === 'temp' || event.item.id === undefined) return;

    if (event.selected) {
      // Add item to selectedItems if not already present
      if (!this.selectedItems.some(selected => selected.item.id === event.item.id)) {
        this.selectedItems.push({ item: event.item });
      }
    } else {
      // Remove item from selectedItems
      this.selectedItems = this.selectedItems.filter(selected => selected.item.id !== event.item.id);
    }


  }

  copyItem() {
    this.folders.forEach(folder => {
      if (folder.name.includes('00') || folder.name.includes('01') || folder.name.includes('02') || folder.name.includes('03') || folder.name.includes('04') || folder.name.includes('05') || folder.name.includes('06') || folder.name.includes('07') || folder.name.includes('08') || folder.name.includes('09')) {
        const itemToCopy = folder.items.find(item => item.name.toLowerCase().includes(this.itemToCopy.toLowerCase()))
        this.coursesService.duplicateCoursePlanItem(itemToCopy)
      }
    })
    // Use the improved refreshFolders method
    this.refreshFolders();
  }
  addItem() {
    const itemToPush: CoursePlanItem = {
      id: 'temp',
      name: '',
      added: Date.now(),
      ages: [],
      skills: [],
      homework: [],
      activities: [],
      sets: [],
      tags: [{ tag: 'empty' }],
      description: '',
      new: true

    }
    this.coursesService.saveNewCoursePlanItem(itemToPush)
    this.addingElement = true
    this.planItems.push(itemToPush)
  }
  findInFolder(folder, value) {
    // Check if folder exists
    if (!folder) {
      console.error('Attempted to search in non-existent folder');
      return;
    }

    // Ensure items array exists
    if (!folder.items) {
      folder.items = [];
      return;
    }

    // Ensure we have originalFolders for consistent search results
    if (!this.originalFolders || this.originalFolders.length === 0) {
      this.originalFolders = JSON.parse(JSON.stringify(this.folders));
    }

    try {
      if (value && value.length > 2) {
        // Make a temporary copy to avoid modifying the original data
        const originalItems = [...folder.items];

        // Filter items by search term
        folder.items = originalItems.filter(item => {
          if (!item || !item.name) return false;
          return item.name.toLowerCase().includes(value.toLowerCase());
        });

        if (folder.items.length === 0) {

          return;
        }

        // Separate items with numbers from those without
        const itemsWithNumbers = [];
        const itemsWithoutNumbers = [];

        // First separate items with numbers from those without
        folder.items.forEach(item => {
          if (!item || !item.name) return;

          const name = item.name.toLowerCase();
          const match = name.match(/\d+/);
          if (match) {
            item._sortKey = parseInt(match[0], 10);
            itemsWithNumbers.push(item);
          } else {
            itemsWithoutNumbers.push(item);
          }
        });

        // Sort items with numbers by their numeric value
        itemsWithNumbers.sort((a, b) => {
          if (!a || !b || a._sortKey === undefined || b._sortKey === undefined) {
            return 0;
          }

          if (a._sortKey !== b._sortKey) {
            return a._sortKey - b._sortKey;
          }
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        // Sort items without numbers alphabetically
        itemsWithoutNumbers.sort((a, b) => {
          if (!a || !a.name || !b || !b.name) return 0;
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        // Combine the sorted arrays
        folder.items = [...itemsWithNumbers, ...itemsWithoutNumbers];
      }
      else if (value.length == 0) {
        // Restore original items when search is cleared
        const originalFolder = this.originalFolders.find(_folder => _folder.name === folder.name);
        if (originalFolder && originalFolder.items) {
          folder.items = [...originalFolder.items];
        }
      }
    } catch (error) {
      console.error('Error searching folder:', error);
      // Attempt to restore from original if possible
      const originalFolder = this.originalFolders.find(_folder => _folder.name === folder.name);
      if (originalFolder && originalFolder.items) {
        folder.items = [...originalFolder.items];
      }
    }
  }
  private getItemContent(item: any): any {
    if (!item) return null;

    // Try the deepest level first
    if (item?.item?.item?.item) {
      return item.item.item.item;
    }

    // Fallback to each level up
    if (item?.item?.item) {
      return item.item.item;
    }
    if (item?.item) {
      return item.item;
    }
    return item;
  }

  createFolders() {
    this.folders = [];

    // Reset selected folder when recreating folders
    this.selectedFolder = null;

    if (!this.planItems || !Array.isArray(this.planItems)) {
      console.warn('Plan items is not an array:', this.planItems);
      this.planItems = [];
      return;
    }

    this.planItems.forEach((item) => {
      const content = this.getItemContent(item);

      // Skip archived items unless showArchived is true
      if (!content || (!this.showArchived && content.tags && content.tags.some(tag => tag?.tag === 'archived'))) {
        return;
      }

      // Ensure tags exists
      if (!content.tags) {
        content.tags = [];
      }

      content.tags.forEach((tag) => {
        // Skip if tag is null or undefined
        if (!tag || !tag.tag) return;

        if (!this.folders.some(folder => folder?.name?.toLowerCase() === tag.tag.toLowerCase())) {
          // Add last modified date based on the most recent item in the folder
          this.folders.push({
            name: tag.tag,
            items: [],
            lastModified: content.added || content.lastModified || Date.now()
          });
        }
        let folderForTag = this.folders.find(folder => folder?.name?.toLowerCase() === tag.tag.toLowerCase());

        // Skip if folder not found
        if (!folderForTag) return;

        folderForTag.items = folderForTag.items.filter((_item) => _item.id !== content.id);

        folderForTag.items.push(content);

        // Update last modified date if this item is newer
        const itemDate = content.added || content.lastModified || 0;
        if (itemDate > folderForTag.lastModified) {
          folderForTag.lastModified = itemDate;
        }
      });
    });

    this.folders.forEach(folder => {
      if (!folder || !folder.items) return;

      folder.items = folder.items.filter(item => {
        // Skip null checks to prevent errors
        if (!item || !item.tags) return false;
        return item.tags.some(tag => tag && tag.tag === folder.name);
      });

      if (!folder.items || folder.items.length === 0) {
        this.folders = this.helpers.removeElementFromArray(this.folders, folder);
      }
    });

    this.folders = this.folders.map(folder => {
      if (!folder) return null;
      return {
        name: folder?.name,
        items: folder.items || [],
        lastModified: folder.lastModified
      };
    }).filter(folder => folder !== null);

    // Sort folders alphabetically instead of by last modified date
    this.folders.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    // Sort items within each folder
    this.folders.forEach(folder => {
      if (!folder || !folder.items || !Array.isArray(folder.items)) {
        folder.items = [];
        return;
      }

      const itemsWithNumbers = [];
      const itemsWithoutNumbers = [];

      folder.items.forEach(item => {
        if (!item) return;

        const name = item.name ? item.name.toLowerCase() : '';
        const match = name.match(/\d+/);
        if (match) {
          item._sortKey = parseInt(match[0], 10);
          itemsWithNumbers.push(item);
        } else {
          itemsWithoutNumbers.push(item);
        }
      });

      itemsWithNumbers.sort((a, b) => {

        if (a._sortKey !== b._sortKey) {
          return a._sortKey - b._sortKey;
        }
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });

      // Sort items without numbers alphabetically
      itemsWithoutNumbers.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      // Combine the sorted arrays
      folder.items = [...itemsWithNumbers, ...itemsWithoutNumbers];


    });

    // Save the sorted folders for search functionality
    this.originalFolders = JSON.parse(JSON.stringify(this.folders));

    // Update the folders in the folder service
    this.folderService.setFolders(this.folders);
  }

  toggleArchived() {
    this.showArchived = !this.showArchived;
    this.createFolders();
  }

  refreshFolders() {
    // Use the folder service to refresh the folders
    this.folderService.refreshFolders();
  }

  duplicateSelectedItems() {
    if (this.selectedItems.length === 0) {
      this.snackBar.open('Nie wybrano żadnych elementów do duplikacji', 'OK', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['warning-snackbar']
      });
      return;
    }

    // Create a confirmation message based on the number of items
    const confirmMessage = `Czy na pewno chcesz zduplikować ${this.selectedItems.length} wybranych elementów?`;

    if (confirm(confirmMessage)) {
      // Get the current folder name before duplicating items
      const folderName = this.selectedFolder ? this.selectedFolder.name : null;

      // Loop through selectedItems and duplicate each one
      const itemCount = this.selectedItems.length;
      let processedCount = 0;

      this.selectedItems.forEach(selectedItem => {
        if (selectedItem && selectedItem.item && selectedItem.item.id !== 'temp') {
          this.coursesService.duplicateCoursePlanItem(selectedItem.item);
          processedCount++;

          // If this is the last item, refresh the folders
          if (processedCount === itemCount) {
            // Use the improved refreshFolders method after a short delay
            setTimeout(() => {
              // First refresh the folders
              this.folderService.refreshFolders();

              // Then force update the modal if it's open
              setTimeout(() => {
                // Get the current modal reference
                const modalRef = this.folderService.getCurrentModal();
                if (modalRef && folderName) {
                  // Get the updated folders
                  this.folderService.getFolders().subscribe(folders => {
                    // Find the updated folder
                    const updatedFolder = folders.find(f => f.name === folderName);
                    if (updatedFolder) {
                      // Update the modal data
                      this.folderService.updateModalData(updatedFolder);

                      // Show a success message
                      this.snackBar.open(`Zduplikowano ${itemCount} elementów pomyślnie`, 'OK', {
                        duration: 3000,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        panelClass: ['success-snackbar']
                      });
                    }
                  });
                } else {
                  // Show a success message
                  this.snackBar.open(`Zduplikowano ${itemCount} elementów pomyślnie`, 'OK', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    panelClass: ['success-snackbar']
                  });
                }
              }, 500);
            }, 300);
          }
        }
      });

      // Clear selection
      this.selectedItems = [];
    }
  }

  archiveAllSelected() {
    if (this.selectedItems.length === 0) return;

    // Get the current folder name before archiving items
    const folderName = this.selectedFolder ? this.selectedFolder.name : null;

    const itemCount = this.selectedItems.length;
    let processedCount = 0;

    this.selectedItems.forEach(selected => {
      const item = selected.item;
      item.name += '-archived';
      item.tags = item.tags || [];
      if (!item.tags.some(tag => tag.tag === 'archived')) {
        item.tags.push({ tag: 'archived' });
      }
      this.coursesService.updatePlanItem(item.id, item).subscribe(
        () => {
          processedCount++;

          // If this is the last item, refresh the folders
          if (processedCount === itemCount) {
            // Use the improved refreshFolders method after a short delay
            setTimeout(() => {
              // First refresh the folders
              this.folderService.refreshFolders();

              // Then force update the modal if it's open
              setTimeout(() => {
                // Get the current modal reference
                const modalRef = this.folderService.getCurrentModal();
                if (modalRef && folderName) {
                  // Get the updated folders
                  this.folderService.getFolders().subscribe(folders => {
                    // Find the updated folder
                    const updatedFolder = folders.find(f => f.name === folderName);
                    if (updatedFolder) {
                      // Update the modal data
                      this.folderService.updateModalData(updatedFolder);

                      // Show a success message
                      this.snackBar.open(`Zarchiwizowano ${itemCount} elementów pomyślnie`, 'OK', {
                        duration: 3000,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        panelClass: ['success-snackbar']
                      });
                    }
                  });
                } else {
                  // Show a success message
                  this.snackBar.open(`Zarchiwizowano ${itemCount} elementów pomyślnie`, 'OK', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    panelClass: ['success-snackbar']
                  });
                }
              }, 500);
            }, 300);
          }
        },
        error => {
          console.error(`Error archiving item ${item.id}:`, error);
        }
      );
    });

    // Clear selection
    this.selectedItems = [];
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.sub2) {
      this.sub2.unsubscribe();
    }

    // Close any open modals when component is destroyed
    if (this.currentModalRef) {
      this.currentModalRef.close();
    }

    // Ensure scrolling is restored
    document.body.style.overflow = 'auto';
  }

  // Method to check if any items in a folder are selected
  areItemsSelected(folder): boolean {
    return folder.items && folder.items.some(item => item.selected);
  }

  // Method to count selected items in a folder
  countSelectedItems(folder): number {
    return folder.items ? folder.items.filter(item => item.selected).length : 0;
  }

  removeFolder(folder, event?) {
    // Stop event propagation if called from a button click inside the card
    if (event) {
      event.stopPropagation();
    }

    // Confirm deletion with the user
    if (!confirm(`Czy na pewno chcesz usunąć folder "${folder.name}" i wszystkie jego elementy?`)) {
      return;
    }

    // Archive all items in the folder
    if (folder && folder.items && folder.items.length > 0) {
      const itemsToArchive = [...folder.items];

      // Add 'archived' tag to each item if it doesn't already have it
      itemsToArchive.forEach(item => {
        if (!item.tags) {
          item.tags = [];
        }

        // Check if item already has 'archived' tag
        if (!item.tags.some(tag => tag && tag.tag === 'archived')) {
          item.tags.push({ tag: 'archived' });

          // Update the item in the database
          this.coursesService.updatePlanItem(item.id, item).subscribe(
            () => {
              // Success handling
            },
            error => {
              console.error(`Error archiving item ${item.id}:`, error);
            }
          );
        }
      });

      // Show a success message
      this.snackBar.open(`Usunięto folder "${folder.name}"`, 'OK', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['info-snackbar']
      });

      // Remove the folder from the folders array
      this.folders = this.helpers.removeElementFromArray(this.folders, folder);

      // Refresh the folders list
      setTimeout(() => {
        this.coursesService.getPlanItemsList();
      }, 500);
    }
  }
}
