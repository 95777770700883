import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesResourcesService } from 'src/app/shared/services/courses-resources.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CoursesResourcesAddEditComponent } from './courses-resources-add-edit/courses-resources-add-edit.component';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
    selector: 'app-courses-resources',
    templateUrl: './courses-resources.component.html',
    styleUrls: ['./courses-resources.component.css']
})
export class CoursesResourcesComponent implements OnInit, OnDestroy {
    resources = [];
    filteredResources = [];
    searchText = '';
    private resourcesSub: Subscription;

    constructor(
        private coursesResourcesService: CoursesResourcesService,
        private dialog: MatDialog,
        private helpers: HelpersService,
        private snackbarService: SnackbarService
    ) { }

    ngOnInit(): void {
        this.coursesResourcesService.getResourcesList();
        this.resourcesSub = this.coursesResourcesService
            .resourcesUpdatedListener()
            .subscribe((resources) => {
                this.resources = resources;
                this.filteredResources = resources;
            });
    }

    filterResources() {
        if (!this.searchText) {
            this.filteredResources = this.resources;
            return;
        }

        this.filteredResources = this.resources.filter(resource =>
            resource.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.description?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.theme?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.competence?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.difficultyLevel?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            resource.difficultyLevelCEFR?.toLowerCase().includes(this.searchText.toLowerCase())
        );
    }

    openAddEditDialog(resource = null) {
        const dialogRef = this.dialog.open(CoursesResourcesAddEditComponent, {
            width: '80%',
            height: '80%',
            data: { resource }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.id) {
                    // Update existing resource
                    this.coursesResourcesService.updateResource(result.id, result);
                } else {
                    // Add new resource
                    result.added = new Date();
                    this.coursesResourcesService.saveNewResource(result);
                }
            }
        });
    }

    deleteResource(resource) {
        if (confirm('Czy na pewno chcesz usunąć te zasoby kursu: ' + resource.name + '?')) {
            this.coursesResourcesService.removeResource(resource.id);
        }
    }

    /**
     * Duplikuje istniejący zasób
     */
    duplicateResource(resource) {
        this.coursesResourcesService.duplicateResource(resource);
    }

    /**
     * Usuwa zestaw z zasobu
     */
    deleteSet(resource, set, event) {
        event.stopPropagation();
        if (confirm(`Czy na pewno chcesz usunąć zestaw: ${set.name}?`)) {
            // Znajdź indeks zestawu do usunięcia
            const index = resource.sets.findIndex(s => s.id === set.id);
            if (index !== -1) {
                // Utwórz kopię zasobu i usuń zestaw
                const resourceCopy = this.helpers.clone(resource);
                resourceCopy.sets.splice(index, 1);
                // Zaktualizuj zasób
                this.coursesResourcesService.updateResource(resource.id, resourceCopy);
                this.snackbarService.showSnackBar('Zestaw został usunięty', 'success');
            }
        }
    }

    /**
     * Usuwa ćwiczenie z zasobu
     */
    deleteActivity(resource, activity, event) {
        event.stopPropagation();
        if (confirm(`Czy na pewno chcesz usunąć ćwiczenie: ${activity.name}?`)) {
            // Znajdź indeks ćwiczenia do usunięcia
            const index = resource.activities.findIndex(a => a.id === activity.id);
            if (index !== -1) {
                // Utwórz kopię zasobu i usuń ćwiczenie
                const resourceCopy = this.helpers.clone(resource);
                resourceCopy.activities.splice(index, 1);
                // Zaktualizuj zasób
                this.coursesResourcesService.updateResource(resource.id, resourceCopy);
                this.snackbarService.showSnackBar('Ćwiczenie zostało usunięte', 'success');
            }
        }
    }

    /**
     * Usuwa zadanie domowe z zasobu
     */
    deleteHomework(resource, homework, event) {
        event.stopPropagation();
        if (confirm(`Czy na pewno chcesz usunąć zadanie domowe: ${homework.displayName || homework.name}?`)) {
            // Znajdź indeks zadania domowego do usunięcia
            const index = resource.homework.findIndex(h => h.id === homework.id);
            if (index !== -1) {
                // Utwórz kopię zasobu i usuń zadanie domowe
                const resourceCopy = this.helpers.clone(resource);
                resourceCopy.homework.splice(index, 1);
                // Zaktualizuj zasób
                this.coursesResourcesService.updateResource(resource.id, resourceCopy);
                this.snackbarService.showSnackBar('Zadanie domowe zostało usunięte', 'success');
            }
        }
    }

    /**
     * Usuwa plik z grupy plików zasobu
     */
    deleteFile(resource, group, file, event) {
        event.stopPropagation();
        if (confirm(`Czy na pewno chcesz usunąć plik: ${file.name}?`)) {
            // Znajdź grupę plików
            const groupIndex = resource.fileGroups.findIndex(g => g.id === group.id);
            if (groupIndex !== -1) {
                // Znajdź plik w grupie
                const fileIndex = resource.fileGroups[groupIndex].files.findIndex(f => f.id === file.id);
                if (fileIndex !== -1) {
                    // Utwórz kopię zasobu i usuń plik
                    const resourceCopy = this.helpers.clone(resource);
                    resourceCopy.fileGroups[groupIndex].files.splice(fileIndex, 1);

                    // Jeśli grupa nie ma już żadnych plików, usuń również grupę
                    if (resourceCopy.fileGroups[groupIndex].files.length === 0) {
                        resourceCopy.fileGroups.splice(groupIndex, 1);
                    }

                    // Zaktualizuj zasób
                    this.coursesResourcesService.updateResource(resource.id, resourceCopy);
                    this.snackbarService.showSnackBar('Plik został usunięty', 'success');
                }
            }
        }
    }

    /**
     * Oblicza całkowitą ilość plików we wszystkich grupach plików zasobu
     */
    getFilesCount(resource): number {
        if (!resource.fileGroups || !Array.isArray(resource.fileGroups)) {
            return 0;
        }

        return resource.fileGroups.reduce((total, group) => {
            if (group.files && Array.isArray(group.files)) {
                return total + group.files.length;
            }
            return total;
        }, 0);
    }

    /**
     * Sprawdza czy zasób zawiera jakiekolwiek elementy, które można pokazać w podglądzie
     */
    hasResourceItems(resource): boolean {
        if (!resource) return false;

        const hasSets = resource.sets && Array.isArray(resource.sets) && resource.sets.length > 0;
        const hasActivities = resource.activities && Array.isArray(resource.activities) && resource.activities.length > 0;
        const hasHomework = resource.homework && Array.isArray(resource.homework) && resource.homework.length > 0;
        const hasFiles = this.getFilesCount(resource) > 0;

        return hasSets || hasActivities || hasHomework || hasFiles;
    }

    ngOnDestroy(): void {
        if (this.resourcesSub) {
            this.resourcesSub.unsubscribe();
        }
    }
} 