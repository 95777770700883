import { Component, OnInit } from '@angular/core';
import { ComponentNameService } from '../shared/services/component-name.service';
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  constructor(private nameService: ComponentNameService) {
    this.nameService.changeName('Kursy')
  }

  ngOnInit(): void {

  }

}
