<div style="background-color: #f5f5f5">
  <div *ngIf="dialogMode || data?.addMode" class="bottom-sheet-dismiss-button">
    <button
      mat-icon-button
      color="primary"
      (click)="bottomSheet.dismiss(this.chosenSets)"
      matTooltip="Zamknij"
    >
      <mat-icon>arrow_downward</mat-icon>
    </button>
  </div>
  <app-sets-menu
    *ngIf="setsType === 'words'"
    [windowMode]="dialogMode"
    [group]="data.group"
  ></app-sets-menu>
  <app-sets-create
    *ngIf="setsType === 'words' && showComponent === 'create'"
  ></app-sets-create>
  <app-sets-list
    *ngIf="showComponent === 'list' && mode !== 'teachers' && mode !== 'mySets'"
    [source]="this.data.source"
    [group]="this.data.group"
  ></app-sets-list>
  <app-sets-free-menu
    *ngIf="setsType === 'free' && showComponent === 'create'"
  ></app-sets-free-menu>
  <app-sets-free-create
    *ngIf="setsType === 'free' && showComponent === 'create'"
  ></app-sets-free-create>
  <!-- <mat-tab-group (selectedTabChange)="tabChanged($event)" *ngIf="showComponent === 'list' && mode === 'teachers'" [disableRipple]="false"> -->
  <!-- <mat-tab #tab1 label="My sets" > -->
  <app-sets-list
    *ngIf="showComponent === 'list' && mode === 'teachers'"
    [group]="this.data.group"
    [source]="this.data.source"
  ></app-sets-list>
  <!-- </mat-tab> -->
  <!-- <mat-tab #tab2  label="Main sets" >
    <app-sets-list *ngIf="tab2.isActive" [filter]="'main'" [group]="this.data"  ></app-sets-list>
  </mat-tab>
  <mat-tab #tab3 label="Others sets" >
    <app-sets-list *ngIf="tab3.isActive" [filter]="'other'" [group]="this.data" ></app-sets-list>
  </mat-tab>
  <mat-tab #tab4 label="Free sets" >
    <app-sets-list *ngIf="tab4.isActive" [filter]="'free'" [group]="this.data" ></app-sets-list>
  </mat-tab> -->
  <!-- </mat-tab-group> -->
  <mat-tab-group
    *ngIf="showComponent === 'list' && mode == 'mySets'"
    [disableRipple]="false"
    style="height: max-content"
  >
    <mat-tab #tab5 label="Groups sets" style="height: fit-content">
      <app-sets-list
        (setRemoved)="updateGroupAfterSetRemoval($event)"
        *ngIf="tab5.isActive"
        [filter]="'groups'"
        [group]="this.data.group"
        [source]="this.data.source"
        [windowMode]="dialogMode"
      ></app-sets-list>
    </mat-tab>
    <mat-tab #tab6 label="All sets" style="height: fit-content">
      <app-sets-list
        *ngIf="tab6.isActive"
        [filter]="'main'"
        [group]="this.data.group"
        [source]="this.data.source"
        [windowMode]="dialogMode"
      ></app-sets-list>
    </mat-tab>
    <!-- <mat-tab #tab7 style="height: 500px;" label="My sets" >
      <app-sets-list *ngIf="tab7.isActive" [windowMode]="dialogMode" [filter]="'teacher'" [group]="this.data"  ></app-sets-list>
    </mat-tab>
    <mat-tab #tab8 style="height: 500px;" label="Free sets" >
      <app-sets-list *ngIf="tab8.isActive" [windowMode]="dialogMode" [filter]="'free'" [group]="this.data"  ></app-sets-list>
    </mat-tab> -->
  </mat-tab-group>
  <!-- <app-sets-list [windowMode]="dialogMode" *ngIf="showComponent === 'list' && mode == 'mySets'" [filter]="'teacherMain'" [group]="this.data"  ></app-sets-list> -->

  <app-set-content
    *ngIf="showComponent === 'content'"
    [freeSet]="currentFreeSet"
    [setType]="setType"
  ></app-set-content>
</div>
<!--<button *ngIf="dialogMode " [mat-dialog-close]="this.data" color="primary" mat-flat-button>Zamknij</button>-->
