import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { VersionService } from './version.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class CoursesResourcesService {
    resources = [];
    url;
    private resourcesUpdated = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private helpers: HelpersService,
        private versionService: VersionService,
        private snackbarService: SnackbarService
    ) {
        this.url = this.versionService.url;
    }

    saveNewResource(resource) {
        let resourceToSend = this.helpers.clone(resource);

        // Upewnij się, że curriculumRefs istnieje i jest tablicą
        if (!resourceToSend.curriculumRefs) {
            resourceToSend.curriculumRefs = [];
        } else if (!Array.isArray(resourceToSend.curriculumRefs)) {
            console.warn('curriculumRefs nie jest tablicą, konwersja do tablicy');
            resourceToSend.curriculumRefs = [];
        }

        console.log('resourceToSend przed wysłaniem:', JSON.stringify(resourceToSend));

        this.http
            .post(this.url + '/api/courses-resources/addresource', resourceToSend)
            .subscribe((response) => {
                this.getResourcesList();
                this.snackbarService.showSnackBar('Zasoby kursu zostały dodane', 'success');
            });
    }

    getResourcesList() {
        this.http
            .get(this.url + '/api/courses-resources/getresourceslist', {
                headers: { Authorization: `Bearer ${this.authService.getToken()}` },
            })
            .subscribe((response) => {
                let resourcesArray = Object.keys(response).map((i) => response[i]);

                resourcesArray = resourcesArray[0].map((incomingResource) => {
                    return {
                        id: incomingResource._id,
                        name: incomingResource.data.name,
                        sets: incomingResource.data.sets || [],
                        activities: incomingResource.data.activities || [],
                        homework: incomingResource.data.homework || [],
                        files: incomingResource.data.files || [],
                        fileGroups: incomingResource.data.fileGroups || [],
                        curriculum: incomingResource.data.curriculum || [],
                        curriculumRefs: incomingResource.data.curriculumRefs || [],
                        ageFrom: incomingResource.data.ageFrom || 0,
                        ageTo: incomingResource.data.ageTo || 0,
                        ages: incomingResource.data.ages,
                        difficultyLevel: incomingResource.data.difficultyLevel,
                        difficultyLevelCEFR: incomingResource.data.difficultyLevelCEFR,
                        isSchoolCurriculum: incomingResource.data.isSchoolCurriculum,
                        bookName: incomingResource.data.bookName || '',
                        competence: incomingResource.data.competence,
                        theme: incomingResource.data.theme,
                        description: incomingResource.data.description,
                        added: incomingResource.data.added
                    };
                });
                this.resources = resourcesArray;
                this.resourcesUpdated.next(this.resources);
            });
    }

    findResourceByName(name) {
        this.http
            .post(
                this.url + '/api/courses-resources/findresourcebyname',
                { name: name },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                let resourcesArray = Object.keys(response).map((i) => response[i]);

                resourcesArray = resourcesArray[0].map((incomingResource) => {
                    return {
                        id: incomingResource._id,
                        name: incomingResource.data.name,
                        sets: incomingResource.data.sets || [],
                        activities: incomingResource.data.activities || [],
                        homework: incomingResource.data.homework || [],
                        files: incomingResource.data.files || [],
                        fileGroups: incomingResource.data.fileGroups || [],
                        curriculum: incomingResource.data.curriculum || [],
                        curriculumRefs: incomingResource.data.curriculumRefs || [],
                        ageFrom: incomingResource.data.ageFrom || 0,
                        ageTo: incomingResource.data.ageTo || 0,
                        ages: incomingResource.data.ages,
                        difficultyLevel: incomingResource.data.difficultyLevel,
                        difficultyLevelCEFR: incomingResource.data.difficultyLevelCEFR,
                        isSchoolCurriculum: incomingResource.data.isSchoolCurriculum,
                        bookName: incomingResource.data.bookName || '',
                        competence: incomingResource.data.competence,
                        theme: incomingResource.data.theme,
                        description: incomingResource.data.description,
                        added: incomingResource.data.added
                    };
                });
                this.resources = resourcesArray;
                this.resourcesUpdated.next(this.resources);
            });
    }

    findResourceById(id) {
        this.http
            .post(
                this.url + '/api/courses-resources/findresourcebyid',
                { id: id },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .subscribe((response) => {
                let resourcesArray = Object.keys(response).map((i) => response[i]);

                resourcesArray = resourcesArray[0].map((incomingResource) => {
                    return {
                        id: incomingResource._id,
                        name: incomingResource.data.name,
                        sets: incomingResource.data.sets || [],
                        activities: incomingResource.data.activities || [],
                        homework: incomingResource.data.homework || [],
                        files: incomingResource.data.files || [],
                        fileGroups: incomingResource.data.fileGroups || [],
                        curriculum: incomingResource.data.curriculum || [],
                        curriculumRefs: incomingResource.data.curriculumRefs || [],
                        ageFrom: incomingResource.data.ageFrom || 0,
                        ageTo: incomingResource.data.ageTo || 0,
                        ages: incomingResource.data.ages,
                        difficultyLevel: incomingResource.data.difficultyLevel,
                        difficultyLevelCEFR: incomingResource.data.difficultyLevelCEFR,
                        isSchoolCurriculum: incomingResource.data.isSchoolCurriculum,
                        bookName: incomingResource.data.bookName || '',
                        competence: incomingResource.data.competence,
                        theme: incomingResource.data.theme,
                        description: incomingResource.data.description,
                        added: incomingResource.data.added
                    };
                });
                this.resources = resourcesArray;
                this.resourcesUpdated.next(this.resources);
            });
    }

    removeResource(id) {
        return this.http
            .delete(this.url + '/api/courses-resources/removeresource/' + id)
            .subscribe(() => {
                this.getResourcesList();
                this.snackbarService.showSnackBar('Zasoby kursu zostały usunięte', 'success');
            });
    }

    updateResource(resourceId, resource) {
        // Najpierw utworzymy pustą kopię, aby uniknąć błędów z referencjami
        let resourceToSend: any = {};

        // Następnie przenosimy właściwości ręcznie, aby upewnić się, że struktura jest prawidłowa
        resourceToSend.name = resource.name || '';
        resourceToSend.sets = Array.isArray(resource.sets) ? resource.sets : [];
        resourceToSend.activities = Array.isArray(resource.activities) ? resource.activities : [];
        resourceToSend.homework = Array.isArray(resource.homework) ? resource.homework : [];
        resourceToSend.files = Array.isArray(resource.files) ? resource.files : [];
        resourceToSend.fileGroups = Array.isArray(resource.fileGroups) ? resource.fileGroups : [];
        resourceToSend.ageFrom = resource.ageFrom || 0;
        resourceToSend.ageTo = resource.ageTo || 0;
        resourceToSend.ages = resource.ages;
        resourceToSend.difficultyLevel = resource.difficultyLevel || '';
        resourceToSend.difficultyLevelCEFR = resource.difficultyLevelCEFR || '';
        resourceToSend.isSchoolCurriculum = !!resource.isSchoolCurriculum;
        resourceToSend.bookName = resource.bookName || '';
        resourceToSend.competence = resource.competence || '';
        resourceToSend.theme = resource.theme || '';
        resourceToSend.description = resource.description || '';
        resourceToSend.added = resource.added || new Date().toISOString();

        // Ensure curriculum is an array
        resourceToSend.curriculum = Array.isArray(resource.curriculum) ? resource.curriculum : [];

        // Upewnij się, że curriculumRefs istnieje i jest tablicą
        resourceToSend.curriculumRefs = Array.isArray(resource.curriculumRefs)
            ? resource.curriculumRefs.filter(ref => ref && typeof ref === 'object' && ref.id && typeof ref.id === 'string')
            : [];

        // Dla debugowania - wypisz zawartość curriculumRefs przed wysłaniem
        console.log('curriculumRefs przed wysłaniem:', JSON.stringify(resourceToSend.curriculumRefs));
        console.log('Pełny obiekt resourceToSend:', JSON.stringify(resourceToSend));

        return this.http
            .put(this.url + '/api/courses-resources/updateresource/' + resourceId, resourceToSend)
            .subscribe({
                next: (response) => {
                    console.log('Odpowiedź z serwera:', response);
                    this.getResourcesList();
                    this.snackbarService.showSnackBar('Zasoby kursu zostały zaktualizowane', 'success');
                },
                error: (error) => {
                    console.error('Błąd podczas aktualizacji zasobów:', error);
                    this.snackbarService.showSnackBar('Błąd podczas aktualizacji zasobów kursu', 'error');
                }
            });
    }

    resourcesUpdatedListener() {
        return this.resourcesUpdated.asObservable();
    }

    getResourceById(id: string): Promise<any> {
        return this.http
            .post(
                this.url + '/api/courses-resources/findresourcebyid',
                { id: id },
                { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
            )
            .toPromise()
            .then((response: any) => {
                if (response && response.resources && response.resources.length > 0) {
                    const incomingResource = response.resources[0];
                    return {
                        id: incomingResource._id,
                        name: incomingResource.data.name,
                        sets: incomingResource.data.sets || [],
                        activities: incomingResource.data.activities || [],
                        homework: incomingResource.data.homework || [],
                        files: incomingResource.data.files || [],
                        fileGroups: incomingResource.data.fileGroups || [],
                        curriculum: incomingResource.data.curriculum || [],
                        curriculumRefs: incomingResource.data.curriculumRefs || [],
                        ageFrom: incomingResource.data.ageFrom || 0,
                        ageTo: incomingResource.data.ageTo || 0,
                        ages: incomingResource.data.ages,
                        difficultyLevel: incomingResource.data.difficultyLevel,
                        difficultyLevelCEFR: incomingResource.data.difficultyLevelCEFR,
                        isSchoolCurriculum: incomingResource.data.isSchoolCurriculum,
                        bookName: incomingResource.data.bookName || '',
                        competence: incomingResource.data.competence,
                        theme: incomingResource.data.theme,
                        description: incomingResource.data.description,
                        added: incomingResource.data.added
                    };
                }
                return null;
            });
    }

    // New debug method to directly check curriculum data
    debugCurriculum(resourceId: string) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.url + '/api/courses-resources/debug-curriculum/' + resourceId, {
                    headers: { Authorization: `Bearer ${this.authService.getToken()}` },
                })
                .subscribe(
                    (response) => {

                        resolve(response);
                    },
                    (error) => {
                        console.error('Error in debug curriculum endpoint:', error);
                        reject(error);
                    }
                );
        });
    }

    duplicateResource(resource) {
        const resourceCopy = this.helpers.clone(resource);
        // Remove id to create a new resource
        delete resourceCopy.id;
        // Update name to indicate it's a copy
        resourceCopy.name = `${resourceCopy.name} (kopia)`;
        // Set new added date
        resourceCopy.added = new Date();

        this.saveNewResource(resourceCopy);
        this.snackbarService.showSnackBar('Zasoby kursu zostały zduplikowane', 'success');
    }
} 