import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-curriculum-view',
    templateUrl: './curriculum-view.component.html',
    styleUrls: ['./curriculum-view.component.css']
})
export class CurriculumViewComponent implements OnInit {
    // Track expanded state for each unit
    expandedUnits: boolean[] = [];

    constructor(
        public dialogRef: MatDialogRef<CurriculumViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        // Initialize all units as expanded
        if (this.data?.curriculum?.units) {
            this.expandedUnits = this.data.curriculum.units.map(() => true);
        }
    }

    onClose(): void {
        this.dialogRef.close();
    }

    toggleUnit(index: number): void {
        this.expandedUnits[index] = !this.expandedUnits[index];
    }

    // Format vocabulary list for better display
    formatVocabularyList(list: any): string[] {
        // Check if list is not defined or null
        if (!list) return [];

        // If list is already an array, return it after processing
        if (Array.isArray(list)) {
            return list.map(item => item.toString().trim())
                .filter(item => item.length > 0);
        }

        // Ensure list is a string before calling split
        if (typeof list !== 'string') {
            console.warn('Expected string for vocabularyList but got:', typeof list);
            // Try to convert to string if possible
            try {
                list = String(list);
            } catch (e) {
                console.error('Could not convert vocabularyList to string:', e);
                return [];
            }
        }

        // Split by comma or newline and trim whitespace
        return list.split(/,|\n/)
            .map(item => item.trim())
            .filter(item => item.length > 0);
    }

    // Method to print curriculum
    printCurriculum(): void {
        // Create a window to print
        const printWindow = window.open('', '_blank');
        if (!printWindow) {
            this.snackBar.open('Nie można otworzyć okna drukowania. Sprawdź, czy nie blokowane są wyskakujące okna.', 'OK', { duration: 3000 });
            return;
        }

        // Build HTML content
        let printContent = `
            <html>
            <head>
                <title>${this.data.curriculum.name}</title>
                <style>
                    body { font-family: Arial, sans-serif; margin: 20px; }
                    h1 { color: #3f51b5; text-align: center; margin-bottom: 30px; }
                    .unit { margin-bottom: 40px; page-break-inside: avoid; }
                    .unit-header { 
                        background-color: #f5f5f5; 
                        padding: 10px; 
                        border-radius: 4px; 
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                    }
                    .unit-number {
                        background-color: #3f51b5;
                        color: white;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        font-weight: bold;
                    }
                    .unit-name { font-size: 18px; font-weight: bold; }
                    .section { margin-bottom: 20px; }
                    .section-title { 
                        font-weight: bold; 
                        color: #3f51b5; 
                        margin-bottom: 10px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid #e0e0e0;
                    }
                    .metadata-item { margin-bottom: 8px; }
                    .metadata-label { font-weight: bold; display: inline-block; min-width: 150px; }
                    .vocabulary-list, .grammar-list, .skills-list { 
                        display: flex; 
                        flex-wrap: wrap; 
                        gap: 8px;
                        margin-top: 10px;
                    }
                    .vocabulary-item, .grammar-item { 
                        background-color: #f1f1f1; 
                        padding: 5px 10px; 
                        border-radius: 15px;
                        display: inline-block;
                    }
                    .standards-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }
                    .standard-tag {
                        background-color: #ffd54f;
                        color: #212121;
                        padding: 4px 8px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: bold;
                        display: inline-block;
                    }
                    .skill-category { margin-bottom: 15px; }
                    .skill-category-title { font-weight: bold; color: #673ab7; margin-bottom: 5px; }
                    .skill-box {
                        display: flex;
                        margin-bottom: 10px;
                        border: 1px solid #e0e0e0;
                        padding: 10px;
                        border-radius: 4px;
                    }
                    .skill-title { font-weight: bold; margin-right: 10px; min-width: 100px; }
                    @media print {
                        .page-break { page-break-before: always; }
                    }
                </style>
            </head>
            <body>
                <h1>${this.data.curriculum.name}</h1>
        `;

        // Generate content for each unit
        this.data.curriculum.units.forEach((unit, index) => {
            if (index > 0) {
                printContent += '<div class="page-break"></div>';
            }

            printContent += `
                <div class="unit">
                    <div class="unit-header">
                        <div class="unit-number">${index + 1}</div>
                        <div class="unit-name">${unit.topic || 'Bez tematu'}</div>
                    </div>
                    
                    <div class="section">
                        <div class="section-title">Informacje ogólne</div>
            `;

            if (unit.subjectArea) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Obszar tematyczny:</span>
                        <span>${unit.subjectArea}</span>
                    </div>
                `;
            }

            if (unit.subjectSubArea) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Podrozdział obszaru:</span>
                        <span>${unit.subjectSubArea}</span>
                    </div>
                `;
            }

            if (unit.functions) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Funkcje językowe:</span>
                        <span>${unit.functions}</span>
                    </div>
                `;
            }

            printContent += `
                </div>
                
                <div class="section">
                    <div class="section-title">Słownictwo</div>
                    <div class="metadata-item">
                        <span class="metadata-label">Temat słownictwa:</span>
                        <span>${unit.vocabularyTopic || ''}</span>
                    </div>
                    <div class="vocabulary-list">
            `;

            const vocabList = this.formatVocabularyList(unit.vocabularyList);
            if (vocabList.length > 0) {
                vocabList.forEach(vocab => {
                    printContent += `<div class="vocabulary-item">${vocab}</div>`;
                });
            } else {
                printContent += `<div>Brak słownictwa</div>`;
            }

            printContent += `
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">Gramatyka</div>
                    <div class="grammar-list">
            `;

            if (unit.grammar && unit.grammar.length > 0) {
                unit.grammar.forEach(grammar => {
                    printContent += `<div class="grammar-item">${grammar}</div>`;
                });
            } else {
                printContent += `<div>Brak punktów gramatycznych</div>`;
            }

            printContent += `
                    </div>
                </div>
            `;

            // Skills sections - combine all skills
            printContent += `
                <div class="section">
                    <div class="section-title">Umiejętności</div>
            `;

            // Communicative skills
            if (unit.communicativeSkills && unit.communicativeSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Komunikacyjne</div>
                        <ul>
                `;
                unit.communicativeSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Receptive skills
            if (unit.receptiveSkills && unit.receptiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Receptywne</div>
                        <ul>
                `;
                unit.receptiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Productive skills
            if (unit.productiveSkills && unit.productiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Produktywne</div>
                        <ul>
                `;
                unit.productiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Interactive skills
            if (unit.interactiveSkills && unit.interactiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Interaktywne</div>
                        <ul>
                `;
                unit.interactiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Processing skills
            if (unit.processingSkills && unit.processingSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Przetwarzania</div>
                        <ul>
                `;
                unit.processingSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Other skills
            if (unit.otherSkills && unit.otherSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Inne</div>
                        <ul>
                `;
                unit.otherSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            printContent += `
                </div>
            `;

            // Core standards
            if (unit.coreStandards && unit.coreStandards.length > 0) {
                printContent += `
                    <div class="section">
                        <div class="section-title">Odniesienia do podstawy programowej</div>
                        <div class="standards-list">
                `;
                unit.coreStandards.forEach(standard => {
                    printContent += `<div class="standard-tag">${standard}</div>`;
                });
                printContent += `
                        </div>
                    </div>
                `;
            }

            // Four skills
            printContent += `
                <div class="section">
                    <div class="section-title">Cztery umiejętności</div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Słuchanie:</div>
                        <div>${unit.listening || 'Brak ćwiczeń słuchania'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Czytanie:</div>
                        <div>${unit.reading || 'Brak ćwiczeń czytania'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Mówienie:</div>
                        <div>${unit.speaking || 'Brak ćwiczeń mówienia'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Pisanie:</div>
                        <div>${unit.writing || 'Brak ćwiczeń pisania'}</div>
                    </div>
                </div>
            `;

            printContent += `</div>`;
        });

        printContent += `
            </body>
            </html>
        `;

        // Write to the new window and print
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();

        // Wait for content to load before printing
        printWindow.onload = function () {
            printWindow.print();
        };
    }
} 