import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fact-video-answer',
  templateUrl: './fact-video-answer.component.html',
  styleUrls: ['./fact-video-answer.component.scss']
})
export class FactVideoAnswerComponent implements OnInit {
  fact

  constructor(
    private dialogRef: MatDialogRef<FactVideoAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.fact = this.data.fact.fact

  }

}
