import { Component, Inject, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CoursesService } from 'src/app/shared/services/courses.service';
import { CoursesResourcesService } from 'src/app/shared/services/courses-resources.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { forkJoin, Observable } from 'rxjs';
import { ResourcePreviewDialogComponent } from '../../course-add-edit/resource-preview-dialog/resource-preview-dialog.component';

// Enum for display modes
enum DisplayMode {
    PreviousUnarchived = 'previous',
    AllUnarchived = 'unarchived',
    All = 'all'
}

// Add this interface for the frequent items
interface FrequentItem {
    id?: string;
    name: string;
    displayName?: string;
    frequency: number;
    usedIn?: string[];
    source: 'resource' | 'lesson';
    type?: string;
    items?: any[];
    resourceId?: string;
    merged?: boolean; // Flag to track merged items
}

@Component({
    selector: 'app-lesson-details-dialog',
    templateUrl: './lesson-details-dialog.component.html',
    styleUrls: ['./lesson-details-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonDetailsDialogComponent implements OnInit, OnChanges {
    includeFutureLessons: boolean = false;

    // Tracking whether right panel is visible (false by default)
    isRightPanelVisible: boolean = false;

    // Display mode property with default value
    displayMode: DisplayMode = DisplayMode.AllUnarchived;

    // Expose enum to template
    DisplayMode = DisplayMode;

    // Cache for frequent items to avoid recalculating on every template check
    private frequentItemsCache: { [key: string]: FrequentItem[] } = {};

    // Nowa właściwość do przechowywania zasobów kursu
    courseResources: any[] = [];

    // Cache for resource usage
    private resourceUsageCache: { [key: string]: string[] } = {};

    // Cache for item usage
    private itemUsageCache: { [key: string]: any } = {};

    // Cache for usage tooltips
    private usageTooltipCache: { [key: string]: string } = {};

    // Cache for background colors
    private backgroundColorCache: { [key: number]: string } = {};

    // Cache for sorted resources
    private sortedResourcesCache: any[] | null = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private coursesService: CoursesService,
        private snackBar: MatSnackBar,
        private helpersService: HelpersService,
        private resourcesService: CoursesResourcesService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<LessonDetailsDialogComponent>
    ) { }

    trackByResourceId(index: number, item: any): string {
        return item.id;
    }

    trackByItemId(index: number, item: any): string {
        return item.id || item.name;
    }

    trackByLessonId(index: number, lesson: any): string {
        return lesson.id || lesson.name;
    }

    // Method to close the dialog
    closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * Toggles visibility of the right panel
     */
    toggleRightPanel(): void {
        this.isRightPanelVisible = !this.isRightPanelVisible;
    }

    sendToLessonPlan(item: any, type: string): void {


        // Clone the item to avoid reference issues
        const itemToSend = this.helpersService.clone(item);

        // Add a tracking ID to see if it persists
        const trackingId = `added_${Date.now()}`;
        itemToSend._trackingId = trackingId;

        // Always ensure items have a source property
        // This ensures consistent treatment of all items
        if (!item.source) {

            item.source = 'resource';
        }

        // Ensure resource ID is always set
        if (!itemToSend.resourceId && itemToSend.id) {
            itemToSend.resourceId = itemToSend.id;
        }

        // Make sure we send the right object structure based on source and type
        // Always try to process items directly first - this makes the function work more generally
        if (['set', 'activity', 'homework', 'file'].includes(type)) {
            const currentLesson = this.data.currentLesson;



            // Clean tracking IDs from existing items before checking (to avoid false duplicates)
            if (currentLesson.sets) currentLesson.sets.forEach(set => delete set._trackingId);
            if (currentLesson.activities) currentLesson.activities.forEach(activity => delete activity._trackingId);
            if (currentLesson.exercises) currentLesson.exercises.forEach(exercise => delete exercise._trackingId);
            if (currentLesson.homework) currentLesson.homework.forEach(hw => delete hw._trackingId);
            if (currentLesson.files) currentLesson.files.forEach(file => delete file._trackingId);

            // Check for duplicates before adding
            let isDuplicate = false;

            switch (type) {
                case 'set':


                    // Check if the set already exists in the lesson
                    if (currentLesson.sets && currentLesson.sets.some(set => set.name === itemToSend.name)) {
                        isDuplicate = true;
                        this.snackBar.open(`Element "${itemToSend.name}" już istnieje w lekcji`, 'OK', {
                            duration: 3000
                        });
                        return;
                    }

                    // Add the set to the current lesson
                    if (!currentLesson.sets) {
                        currentLesson.sets = [];
                    }
                    currentLesson.sets.push(itemToSend);
                    // Sort alphabetically
                    currentLesson.sets.sort((a, b) => a.name.localeCompare(b.name));
                    break;

                case 'activity':
                    // Check if the activity already exists in the lesson
                    if ((currentLesson.activities && currentLesson.activities.some(activity => activity.name === itemToSend.name)) ||
                        (currentLesson.exercises && currentLesson.exercises.some(exercise => exercise.name === itemToSend.name))) {
                        isDuplicate = true;
                        this.snackBar.open(`Element "${itemToSend.name}" już istnieje w lekcji`, 'OK', {
                            duration: 3000
                        });
                        return;
                    }

                    // Handle both arrays properly
                    if (!currentLesson.activities) {
                        currentLesson.activities = [];
                    }

                    if (!currentLesson.exercises) {
                        currentLesson.exercises = [];
                    }

                    // Create separate copies for each array to avoid reference issues
                    const itemForActivities = this.helpersService.clone(itemToSend);
                    const itemForExercises = this.helpersService.clone(itemToSend);

                    // Ensure the tracking ID is also cloned
                    itemForActivities._trackingId = trackingId;
                    itemForExercises._trackingId = trackingId;

                    // Push to both arrays
                    currentLesson.activities.push(itemForActivities);
                    currentLesson.exercises.push(itemForExercises);

                    // Sort both arrays
                    currentLesson.activities.sort((a, b) => a.name.localeCompare(b.name));
                    currentLesson.exercises.sort((a, b) => a.name.localeCompare(b.name));
                    break;

                case 'homework':



                    // Instead of checking for duplicates, we'll replace all homework items

                    // Initialize homework array if needed
                    if (!currentLesson.homework) {
                        currentLesson.homework = [];
                    } else {
                        // Clear existing homework items

                        currentLesson.homework = [];
                    }

                    // Add only the new homework item
                    currentLesson.homework.push(itemToSend);

                    // No need to sort since there's only one item now
                    this.snackBar.open(`Wybrano zadanie domowe: "${itemToSend.name}"`, 'OK', {
                        duration: 2000
                    });
                    break;

                case 'file':
                    // Check if the file already exists in the lesson
                    if (currentLesson.files && currentLesson.files.some(file => file.name === itemToSend.name)) {
                        isDuplicate = true;
                        this.snackBar.open(`Element "${itemToSend.name}" już istnieje w lekcji`, 'OK', {
                            duration: 3000
                        });
                        return;
                    }

                    // Add the file to the current lesson
                    if (!currentLesson.files) {
                        currentLesson.files = [];
                    }
                    currentLesson.files.push(itemToSend);
                    // Sort alphabetically
                    currentLesson.files.sort((a, b) => a.name.localeCompare(b.name));
                    break;
            }

            // If the item is a duplicate, stop here
            if (isDuplicate) {
                return;
            }



            // Clear any cached info before updating
            this.frequentItemsCache = {};

            // Show loading indicator
            this.snackBar.open(`Dodawanie elementu "${itemToSend.name}" do lekcji...`, '', {
                duration: 1000
            });

            // Clone lesson before sending to avoid reference issues
            const lessonToSend = this.helpersService.clone(currentLesson);

            // Update the lesson plan
            this.coursesService.updatePlanItem(currentLesson.id, lessonToSend)
                .subscribe({
                    next: (response) => {

                        this.snackBar.open(`Element "${itemToSend.name}" dodany do lekcji`, 'OK', {
                            duration: 3000
                        });
                    },
                    error: (error) => {
                        console.error('Error updating lesson:', error);
                        this.snackBar.open('Błąd podczas dodawania elementu do lekcji', 'Zamknij', {
                            duration: 3000
                        });
                    }
                });
        } else {
            // Something unusual is happening, log it for debugging





            // Use the foundPlanItem approach as fallback
            const processedItem = {
                item: itemToSend,
                type: type
            };

            this.coursesService.foundPlanItem.next([processedItem]);
            this.snackBar.open('Element dodany do planu lekcji (metoda alternatywna)', 'OK', {
                duration: 2000
            });
        }
    }

    ngOnInit(): void {


        // Initialize course resources and fetch their complete data
        if (this.data && this.data.courseResources && this.data.courseResources.length > 0) {


            // Create an array of promises for fetching each resource
            const fetchPromises = this.data.courseResources.map(resource => {
                if (!resource || !resource.id) {
                    console.warn('Invalid resource in courseResources:', resource);
                    return Promise.resolve(null);
                }


                return this.resourcesService.getResourceById(resource.id)
                    .catch(error => {
                        console.warn(`Failed to load resource ${resource.id}:`, error);
                        return null; // Return null for failed resources
                    });
            });

            // Fetch all resources in parallel
            Promise.all(fetchPromises)
                .then(resources => {
                    // Filter out null resources (failed to load)
                    this.courseResources = resources.filter(resource => resource !== null);

                    // Initialize frequency count for resources
                    this.courseResources.forEach(resource => {
                        if (!resource.frequency) {
                            resource.frequency = 0;
                        }
                    });

                    if (this.courseResources.length === 0) {
                        console.warn('None of the resources could be loaded');
                        this.snackBar.open('Nie udało się załadować żadnych zasobów', 'Zamknij', {
                            duration: 3000
                        });

                        // Try to continue anyway by setting an empty array
                        this.courseResources = [];
                        this.frequentItemsCache = {};
                        return;
                    }

                    // Debug the resource structure
                    if (this.courseResources && this.courseResources.length > 0) {
                        const firstResource = this.courseResources[0];
                    }

                    // Calculate resource frequencies based on usage
                    this.calculateResourceFrequencies();

                    // Clear the cache to force recalculation with new data
                    this.frequentItemsCache = {};
                })
                .catch(error => {
                    console.error('Error fetching resource details:', error);
                    this.snackBar.open('Błąd podczas pobierania szczegółów zasobów', 'Zamknij', {
                        duration: 3000
                    });

                    // Set an empty array to prevent null reference errors
                    this.courseResources = [];
                    this.frequentItemsCache = {};
                });
        } else {
            console.warn('No course resources in data or empty array');
            // Initialize with empty array
            this.courseResources = [];
        }

        // Ensure folders are uppercase and sorted alphabetically
        if (this.data && this.data.lessons) {
            // Group lessons by folder
            const folderMap = new Map<string, any[]>();

            this.data.lessons.forEach(lesson => {
                // Extract folder name from lesson name if it exists
                const folderMatch = lesson.name.match(/^([A-Za-z0-9]+)/);
                const folderName = folderMatch ? folderMatch[1].toUpperCase() : 'OTHER';

                if (!folderMap.has(folderName)) {
                    folderMap.set(folderName, []);
                }

                folderMap.get(folderName).push(lesson);
            });

            // Sort each folder's lessons alphabetically
            folderMap.forEach(lessons => {
                lessons.sort((a, b) => a.name.localeCompare(b.name));
            });

            // Convert map to sorted array of folders
            this.data.folders = Array.from(folderMap.entries())
                .map(([name, items]) => ({ name, items }))
                .sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['data'] || changes['displayMode'] || changes['includeFutureLessons']) {
            // Clear caches when relevant data changes
            this.clearCaches();
        }
    }

    private clearCaches() {
        this.frequentItemsCache = {};
        this.resourceUsageCache = {};
        this.itemUsageCache = {};
        this.usageTooltipCache = {};
        this.sortedResourcesCache = null;
    }

    getBackgroundColor(index: number): string {
        if (this.backgroundColorCache[index] === undefined) {
            const colors = ['#e3f2fd', '#e8f5e9', '#fffde7', '#ffebee', '#f3e5f5'];
            this.backgroundColorCache[index] = colors[index % colors.length];
        }
        return this.backgroundColorCache[index];
    }

    copyLessonsToClipboard(): void {
        try {
            const lessonsJson = JSON.stringify(this.data.lessons, null, 2);
            navigator.clipboard.writeText(lessonsJson).then(() => {
                this.snackBar.open('Lekcje skopiowane do schowka!', 'Zamknij', {
                    duration: 3000
                });
            }).catch(err => {
                this.snackBar.open('Nie udało się skopiować lekcji', 'Zamknij', {
                    duration: 3000
                });
                console.error('Failed to copy lessons: ', err);
            });
        } catch (err) {
            this.snackBar.open('Błąd podczas przygotowywania danych lekcji', 'Zamknij', {
                duration: 3000
            });
            console.error('Error preparing lessons data: ', err);
        }
    }

    copyLessonsTillSelectedToClipboard(): void {
        try {
            // Find the index of the current lesson
            const currentLessonIndex = this.data.lessons.findIndex(
                (lesson: any) => lesson.name === this.data.currentLesson.name
            );

            if (currentLessonIndex === -1) {
                this.snackBar.open('Nie można znaleźć wybranej lekcji', 'Zamknij', {
                    duration: 3000
                });
                return;
            }

            // Slice the lessons array up to and including the current lesson
            const selectedLessons = this.data.lessons.slice(0, currentLessonIndex + 1);
            const lessonsJson = JSON.stringify(selectedLessons, null, 2);

            navigator.clipboard.writeText(lessonsJson).then(() => {
                this.snackBar.open(`Skopiowano lekcje do "${this.data.currentLesson.name}"`, 'Zamknij', {
                    duration: 3000
                });
            }).catch(err => {
                this.snackBar.open('Nie udało się skopiować lekcji', 'Zamknij', {
                    duration: 3000
                });
                console.error('Failed to copy lessons: ', err);
            });
        } catch (err) {
            this.snackBar.open('Błąd podczas przygotowywania danych lekcji', 'Zamknij', {
                duration: 3000
            });
            console.error('Error preparing lessons data: ', err);
        }
    }

    /**
     * Toggle the inclusion of future lessons and clear the cache
     */
    toggleIncludeFutureLessons(): void {
        this.includeFutureLessons = !this.includeFutureLessons;
        this.clearCaches();
    }

    /**
     * Pobiera informacje o użyciu określonego elementu we wszystkich lekcjach
     * @param item Element do sprawdzenia
     * @param type Typ elementu (set, activity, homework)
     * @returns Obiekt z informacjami o użyciu
     */
    getItemUsage(item: any, type: string): any {
        const cacheKey = `${item.id}_${type}_${this.displayMode}_${this.includeFutureLessons}`;
        if (!this.itemUsageCache[cacheKey]) {
            this.itemUsageCache[cacheKey] = this.helpersService.trackItemUsage(this.data.lessons, item.id, type, {
                includeFuture: this.includeFutureLessons,
                currentLessonName: this.data.currentLesson.name
            });
        }
        return this.itemUsageCache[cacheKey];
    }

    /**
     * Zwraca tekst podpowiedzi z informacjami o użyciu
     * @param item Element do wygenerowania podpowiedzi
     * @param type Typ elementu
     * @returns Tekst podpowiedzi
     */
    getUsageTooltip(item: any, type: string): string {
        const cacheKey = `${item.id}_${type}_${this.displayMode}_${this.includeFutureLessons}`;
        if (!this.usageTooltipCache[cacheKey]) {
            const usage = this.getItemUsage(item, type);
            this.usageTooltipCache[cacheKey] = this.generateUsageTooltip(usage, item, type);
        }
        return this.usageTooltipCache[cacheKey];
    }

    private generateUsageTooltip(usage: any, item: any, type: string): string {
        if (usage.totalCount === 0 && usage.moduleCount === 0) {
            return 'Element nie był jeszcze używany w kursie';
        }

        const currentModuleName = this.helpersService.extractModuleNameFromLesson(this.data.currentLesson.name);
        const modeName = this.includeFutureLessons ? 'całym kursie' : 'poprzednich modułach';

        let tooltip = '';

        if (usage.totalCount > 0) {
            tooltip += `Użyty ${usage.totalCount} ${this.getPolishCountSuffix(usage.totalCount)} w ${modeName}`;
        }

        if (usage.moduleCount > 0) {
            if (tooltip) tooltip += ', ';
            tooltip += `${usage.moduleCount} ${this.getPolishCountSuffix(usage.moduleCount)} w module ${currentModuleName}`;
        }

        if (usage.currentModuleCount > 0) {
            tooltip += ` (w tym ${usage.currentModuleCount} ${this.getPolishCountSuffix(usage.currentModuleCount)} w tej lekcji)`;
        }

        if (usage.lastModule && usage.lastModule !== this.data.currentLesson.name) {
            tooltip += `. Ostatnio użyty w: ${usage.lastModule}`;
        }

        return tooltip;
    }

    /**
     * Zwraca poprawny polski przyrostek dla liczby
     * @param count Liczba do uzyskania przyrostka
     * @returns Odpowiedni polski przyrostek
     */
    getPolishCountSuffix(count: number): string {
        if (count === 1) {
            return 'raz';
        } else {
            return 'razy';
        }
    }

    /**
     * Changes the display mode and clears the cache
     * @param mode The new display mode or MatButtonToggleChange event
     */
    changeDisplayMode(mode: DisplayMode | MatButtonToggleChange): void {
        const newMode = mode instanceof Object && 'value' in mode ? mode.value : mode;
        this.displayMode = newMode as DisplayMode;
        this.clearCaches();
    }

    /**
     * Gets items of a specific type with their usage frequency across visible lessons
     * @param type The type of item ('set', 'activity', 'homework', 'file')
     * @returns Array of items with their frequency counts
     */
    getFrequentItems(type: string): FrequentItem[] {
        // Return cached results if available
        if (this.frequentItemsCache[type]) {
            return this.frequentItemsCache[type];
        }

        // Filter lessons based on display mode
        let visibleLessons = this.data.lessons;

        switch (this.displayMode) {
            case DisplayMode.PreviousUnarchived:
                visibleLessons = this.data.lessons.filter(lesson =>
                    !lesson.name.includes('archived') &&
                    !this.isFutureLesson(lesson)
                );
                break;
            case DisplayMode.AllUnarchived:
                visibleLessons = this.data.lessons.filter(lesson =>
                    !lesson.name.includes('archived')
                );
                break;
            case DisplayMode.All:
                visibleLessons = this.data.lessons;
                break;
        }

        // Map to collect items and count their frequency - use item name and type as key for merging
        const itemMap = new Map<string, FrequentItem>();

        // First, process items from resources
        if (this.courseResources && this.courseResources.length > 0) {
            this.courseResources.forEach((resource, index) => {
                let items: any[] = [];

                // Get the appropriate items from the resource based on type
                switch (type) {
                    case 'set':
                        items = resource.sets || [];
                        break;
                    case 'activity':
                        items = resource.activities || [];
                        break;
                    case 'homework':
                        items = resource.homework || [];
                        break;
                    case 'file':
                        items = resource.fileGroups ?
                            resource.fileGroups.flatMap(group => group.files || []) :
                            resource.files || [];
                        break;
                }

                items.forEach(item => {
                    // Use item name and type as the unique key for merging to avoid merging different types
                    const itemKey = `${item.name}__${item.type || type}`;

                    if (!itemKey) return; // Skip items without names

                    if (!itemMap.has(itemKey)) {
                        itemMap.set(itemKey, {
                            ...item,
                            frequency: 0,
                            usedIn: [],
                            source: 'resource',
                            type: item.type || type,
                            resourceId: resource.id,  // Store the parent resource ID
                            merged: false // Flag to track merged items
                        });
                    } else {
                        // Item already exists - it might be from another resource
                        // Update resourceId if it's the same item from a different resource
                        const existingItem = itemMap.get(itemKey);
                        existingItem.merged = true;
                    }
                });
            });
        }

        // Then, process items from lessons
        visibleLessons.forEach(lesson => {
            let items: any[] = [];

            // Get the appropriate items based on type
            switch (type) {
                case 'set':
                    items = lesson.sets || [];
                    break;
                case 'activity':
                    items = lesson.exercises || [];
                    break;
                case 'homework':
                    items = lesson.homework || [];
                    break;
                case 'file':
                    items = lesson.files || [];
                    break;
            }

            const lessonId = this.extractLessonIdentifier(lesson.name);

            // Count frequency of each item
            items.forEach(item => {
                // Use item name and type as the unique key for merging to avoid merging different types
                const itemKey = `${item.name}__${item.type || type}`;

                if (!itemKey) return; // Skip items without names

                if (itemMap.has(itemKey)) {
                    // Item already exists - either from resources or previous lessons
                    const existingItem = itemMap.get(itemKey);
                    existingItem.frequency += 1;

                    // If the item is from a lesson, keep the lesson source if it wasn't from a resource
                    if (item.source === 'lesson' && existingItem.source !== 'resource') {
                        existingItem.source = 'lesson';
                    }

                    // Store the resource ID if it exists and the existing item doesn't have one
                    if (item.resourceId && !existingItem.resourceId) {
                        existingItem.resourceId = item.resourceId;
                        existingItem.source = 'resource'; // If it has a resource ID, mark as resource
                    }

                    // Track lesson usage
                    if (lessonId && !existingItem.usedIn.includes(lessonId)) {
                        existingItem.usedIn.push(lessonId);
                    }

                    // Mark as merged
                    existingItem.merged = true;
                } else {
                    // New item from lessons
                    itemMap.set(itemKey, {
                        ...item,
                        frequency: 1,
                        usedIn: lessonId ? [lessonId] : [],
                        source: item.resourceId ? 'resource' : 'lesson', // Check for resourceId to determine source
                        type: item.type || type,
                        merged: false
                    });
                }
            });
        });

        // Function to extract number from set name (e.g. "Set 1" -> 1)
        const extractNumber = (name: string): number => {
            const match = name.match(/\d+/);
            return match ? parseInt(match[0]) : 0;
        };

        // Convert map to array and sort
        let result = Array.from(itemMap.values());

        // Different sorting logic based on item type
        if (type === 'set') {
            // For sets, sort by frequency (least frequent first), then by number in name
            result.sort((a, b) => {
                // Sort by frequency (least frequent first)
                if (a.frequency !== b.frequency) return a.frequency - b.frequency;

                // Then sort by number in name
                const numA = extractNumber(a.name);
                const numB = extractNumber(b.name);
                if (numA !== numB) return numA - numB;

                // Then alphabetically by name
                return (a.name || '').localeCompare(b.name || '');
            });
        } else {
            // For other types, sort by frequency (least frequent first)
            result.sort((a, b) => {
                // Sort by frequency (least frequent first)
                if (a.frequency !== b.frequency) return a.frequency - b.frequency;

                // Then alphabetically by name
                return (a.name || '').localeCompare(b.name || '');
            });
        }

        // Cache the results
        this.frequentItemsCache[type] = result;

        return result;
    }

    /**
     * Extracts a lesson identifier from the lesson name (e.g., "1C", "3B")
     * @param lessonName The full name of the lesson
     * @returns The extracted lesson identifier or null if not found
     */
    private extractLessonIdentifier(lessonName: string): string | null {
        // Try to extract patterns like "1C", "3B", etc.
        const match = lessonName.match(/(\d+[A-Z])/);
        return match ? match[1] : null;
    }

    /**
     * Checks if a lesson is in the future
     * @param lesson The lesson to check
     * @returns True if the lesson is in the future
     */
    private isFutureLesson(lesson: any): boolean {
        // If there's a current lesson in the data, we can compare with it
        if (this.data.currentLesson) {
            // Extract lesson identifiers for comparison
            const currentId = this.extractLessonIdentifier(this.data.currentLesson.name);
            const lessonId = this.extractLessonIdentifier(lesson.name);

            if (currentId && lessonId) {
                // Simple comparison - if the lesson ID is greater than current, it's in the future
                // This assumes lesson IDs follow a pattern where higher values are future lessons
                return lessonId > currentId;
            }
        }

        // Default to false if we can't determine
        return false;
    }

    /**
     * Sprawdza czy zasób jest używany w aktualnej lekcji
     * @param resourceId ID zasobu do sprawdzenia
     * @returns true jeśli zasób jest używany w aktualnej lekcji
     */
    isResourceUsedInCurrentLesson(resourceId: string): boolean {
        const currentLesson = this.data.currentLesson;
        if (!currentLesson || !resourceId) return false;

        // Sprawdź czy zasób jest powiązany z aktualną lekcją
        return this.isResourceLinkedToLesson(resourceId, currentLesson);
    }

    /**
     * Sprawdza czy zasób jest powiązany z daną lekcją
     * @param resourceId ID zasobu
     * @param lesson Obiekt lekcji
     * @returns true jeśli zasób jest powiązany z lekcją
     */
    private isResourceLinkedToLesson(resourceId: string, lesson: any): boolean {
        // Sprawdź czy którykolwiek element lekcji (zestaw, ćwiczenie, zadanie domowe) jest powiązany z zasobem
        return this.isResourceInSets(resourceId, lesson.sets) ||
            this.isResourceInActivities(resourceId, lesson.activities) ||
            this.isResourceInHomework(resourceId, lesson.homework);
    }

    /**
     * Sprawdza czy zasób jest używany w zestawach
     */
    private isResourceInSets(resourceId: string, sets: any[]): boolean {
        if (!sets || !Array.isArray(sets) || sets.length === 0) return false;
        return sets.some(set => set.resourceId === resourceId);
    }

    /**
     * Sprawdza czy zasób jest używany w ćwiczeniach
     */
    private isResourceInActivities(resourceId: string, activities: any[]): boolean {
        if (!activities || !Array.isArray(activities) || activities.length === 0) return false;
        return activities.some(activity => activity.resourceId === resourceId);
    }

    /**
     * Sprawdza czy zasób jest używany w zadaniach domowych
     */
    private isResourceInHomework(resourceId: string, homework: any[]): boolean {
        if (!homework || !Array.isArray(homework) || homework.length === 0) return false;
        return homework.some(hw => hw.resourceId === resourceId);
    }

    /**
     * Znajduje lekcje, w których używany jest zasób
     * @param resourceId ID zasobu
     * @returns Tablica z identyfikatorami lekcji
     */
    getResourceUsage(resourceId: string): string[] {
        if (!resourceId || !this.data.lessons) return [];

        const cacheKey = `${resourceId}_${this.displayMode}_${this.includeFutureLessons}`;
        if (!this.resourceUsageCache[cacheKey]) {
            this.resourceUsageCache[cacheKey] = this.data.lessons
                .filter(lesson => this.isResourceLinkedToLesson(resourceId, lesson))
                .map(lesson => this.extractLessonIdentifier(lesson.name))
                .filter(id => id !== null) as string[];
        }
        return this.resourceUsageCache[cacheKey];
    }

    /**
     * Dodaje powiązanie zasobu z elementem lekcji
     * @param resource Zasób do powiązania
     */
    linkResourceToCurrentLesson(resource: any): void {
        // Tutaj możemy dodać logikę powiązania zasobu z aktualną lekcją
        // Na razie po prostu aktualizujemy atrybuty zasobów w elementach lekcji
        const currentLesson = this.data.currentLesson;

        // Dodajemy ID zasobu do aktualnych elementów
        if (currentLesson.sets && Array.isArray(currentLesson.sets)) {
            currentLesson.sets.forEach(set => {
                if (!set.resourceId) set.resourceId = resource.id;
            });
        }

        if (currentLesson.activities && Array.isArray(currentLesson.activities)) {
            currentLesson.activities.forEach(activity => {
                if (!activity.resourceId) activity.resourceId = resource.id;
            });
        }

        if (currentLesson.homework && Array.isArray(currentLesson.homework)) {
            currentLesson.homework.forEach(hw => {
                if (!hw.resourceId) hw.resourceId = resource.id;
            });
        }

        // Aktualizujemy lekcję
        this.coursesService.updatePlanItem(currentLesson.id, currentLesson);

        // Pokazujemy komunikat
        this.snackBar.open(`Zasób "${resource.name}" powiązany z lekcją`, 'OK', {
            duration: 3000
        });
    }

    // Get resource name by ID
    getResourceNameById(resourceId: string): string {
        if (!resourceId || !this.courseResources) return 'Zasób';

        const resource = this.courseResources.find(r => r.id === resourceId);
        return resource ? resource.name : 'Zasób';
    }

    // Open resource preview
    openResourcePreview(resourceId: string): void {
        if (!resourceId) return;

        const resource = this.courseResources.find(r => r.id === resourceId);
        if (resource) {
            // Find an existing dialog or component for resource preview
            this.openResourcePreviewDialog(resource);
        }
    }

    // Resource preview dialog
    openResourcePreviewDialog(resource: any): void {
        // Look for the existing dialog in the course-add-edit component
        // This is the preview dialog that's already being used elsewhere
        this.dialog.open(ResourcePreviewDialogComponent, {
            width: '900px',
            height: '80vh',
            data: { resource }
        });
    }

    /**
     * Gets information about merged items, including where they were found
     * @param item The merged item to analyze
     * @returns String with information about the item merging
     */
    getMergedItemInfo(item: FrequentItem): string {
        if (!item.merged) {
            return '';
        }

        let info = 'Element połączony: ';

        if (item.source === 'resource') {
            info += `Element z zasobu "${this.getResourceNameById(item.resourceId)}"`;
            info += ` używany w ${item.usedIn?.length || 0} lekcjach`;
        } else {
            info += 'Element występuje w wielu lekcjach';
        }

        if (item.frequency > 0) {
            info += `, używany ${item.frequency} ${this.getPolishCountSuffix(item.frequency)}`;
        }

        return info;
    }

    /**
     * Calculates and updates the frequency of each resource based on its usage in lessons
     */
    calculateResourceFrequencies(): void {
        if (!this.courseResources || !this.data || !this.data.lessons) {
            return;
        }

        // Reset all frequencies
        this.courseResources.forEach(resource => {
            resource.frequency = 0;
        });

        // Count occurrences in lessons
        this.data.lessons.forEach(lesson => {
            // Check sets
            if (lesson.sets && Array.isArray(lesson.sets)) {
                lesson.sets.forEach(set => {
                    if (set.resourceId) {
                        const resource = this.courseResources.find(r => r.id === set.resourceId);
                        if (resource) {
                            resource.frequency = (resource.frequency || 0) + 1;
                        }
                    }
                });
            }

            // Check activities
            if (lesson.activities && Array.isArray(lesson.activities)) {
                lesson.activities.forEach(activity => {
                    if (activity.resourceId) {
                        const resource = this.courseResources.find(r => r.id === activity.resourceId);
                        if (resource) {
                            resource.frequency = (resource.frequency || 0) + 1;
                        }
                    }
                });
            }

            // Check exercises (also activities)
            if (lesson.exercises && Array.isArray(lesson.exercises)) {
                lesson.exercises.forEach(exercise => {
                    if (exercise.resourceId) {
                        const resource = this.courseResources.find(r => r.id === exercise.resourceId);
                        if (resource) {
                            resource.frequency = (resource.frequency || 0) + 1;
                        }
                    }
                });
            }

            // Check homework
            if (lesson.homework && Array.isArray(lesson.homework)) {
                lesson.homework.forEach(hw => {
                    if (hw.resourceId) {
                        const resource = this.courseResources.find(r => r.id === hw.resourceId);
                        if (resource) {
                            resource.frequency = (resource.frequency || 0) + 1;
                        }
                    }
                });
            }

            // Check files
            if (lesson.files && Array.isArray(lesson.files)) {
                lesson.files.forEach(file => {
                    if (file.resourceId) {
                        const resource = this.courseResources.find(r => r.id === file.resourceId);
                        if (resource) {
                            resource.frequency = (resource.frequency || 0) + 1;
                        }
                    }
                });
            }
        });
    }

    /**
     * Gets the course resources sorted by frequency (least frequent first)
     * @returns The sorted array of resources
     */
    getSortedResources(): any[] {
        if (!this.sortedResourcesCache) {
            if (!this.courseResources || this.courseResources.length === 0) {
                this.sortedResourcesCache = [];
            } else {
                this.sortedResourcesCache = [...this.courseResources].sort((a, b) => a.frequency - b.frequency);
            }
        }
        return this.sortedResourcesCache;
    }
}