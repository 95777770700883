import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceEntry } from '../finances.service';
import { TeacherCost, FinanceItem, StateItem } from '../finances-stats.service';

@Component({
    selector: 'app-finance-dialog',
    templateUrl: './finance-dialog.component.html',
    styleUrls: ['./finance-dialog.component.css']
})
export class FinanceDialogComponent implements OnInit {
    financeForm: FormGroup;
    dialogType: 'finance' | 'teacher' | 'state' = 'finance';

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<FinanceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        // Determine dialog type based on data
        if (this.data?.type === 'teacher') {
            this.dialogType = 'teacher';
            this.initTeacherForm();
        } else if (this.data?.type === 'state') {
            this.dialogType = 'state';
            this.initStateForm();
        } else {
            this.dialogType = 'finance';
            this.initFinanceForm();
        }
    }

    initFinanceForm(): void {
        this.financeForm = this.fb.group({
            id: [this.data?.id || null],
            type: [this.data?.type || 'expense', Validators.required],
            name: [this.data?.name || '', Validators.required],
            value: [this.data?.value || 0, [Validators.required, Validators.min(0)]],
            category: [this.data?.category || 'monthly', Validators.required],
            isCompany: [this.data?.isCompany || false],
            isTeacher: [this.data?.isTeacher || false]
        });
    }

    initTeacherForm(): void {
        this.financeForm = this.fb.group({
            name: [this.data?.name || '', Validators.required],
            value: [this.data?.value || 0, [Validators.required, Validators.min(0)]]
        });
    }

    initStateForm(): void {
        this.financeForm = this.fb.group({
            name: [this.data?.name || '', Validators.required],
            value: [this.data?.value || 0, [Validators.required, Validators.min(0)]]
        });
    }

    onSubmit(): void {
        if (this.financeForm.valid) {
            this.dialogRef.close(this.financeForm.value);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    get isTeacherDialog(): boolean {
        return this.dialogType === 'teacher';
    }

    get isStateDialog(): boolean {
        return this.dialogType === 'state';
    }

    get isFinanceDialog(): boolean {
        return this.dialogType === 'finance';
    }
} 