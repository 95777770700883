import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LessonActivitiesService } from 'src/app/shared/services/lesson-activities.service';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent implements OnInit {
  @Input() activity: any;
  @Input() trainingMode;
  @Input() addToLessonMode;
  @Input() activitiesToAdd = [];
  @Output() addActivity = new EventEmitter<any>();
  @Output() removeActivity = new EventEmitter<any>();

  constructor(
    private lessonActivitiesService: LessonActivitiesService,
    private helpers: HelpersService,
    private ref: ChangeDetectorRef,
    public userDetailsService: UserDetailsService
  ) { }

  ngOnInit() {
  }

  checkTags(activity) {
    if (activity?.tags?.some(tag => tag.tag == 'fullProcess')) {
      return 'fullProcess'
    }
    if (activity?.tags?.some(tag => tag.tag == 'processPiece')) {
      return 'processPiece'
    }
    if (activity?.tags?.some(tag => tag.tag == 'instruction')) {
      return 'instruction'
    }
  }

  addActivityToLesson(activity) {
    this.addActivity.emit(activity);
  }

  removeActivityFromLesson(activity) {
    this.removeActivity.emit(activity);
  }

  duplicateActivity(activity) {
    let activityToSend = this.helpers.clone(activity);
    activityToSend.name = activity.name + ' kopia'
    activityToSend.added = Date.now()
    this.lessonActivitiesService.saveNewActivity(activityToSend)
  }

  addForTrainingToActivity(activity) {
    activity.tags.push({ tag: 'forTraining' })
    this.lessonActivitiesService.updateActivity(activity.id, activity)
  }

  deleteActivity(id) {
    if (confirm("Czy na pewno usunąć?")) {
      this.lessonActivitiesService.removeActivity(id);
    }
  }
}
