import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Control } from '../../models/controls.model'
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter();
  @Output() text = new EventEmitter();
  @Input() apperance = 'static';
  @Input() input = true;
  @Input() inputSize = 30;
  @Input() inputLabel = 'Wyszukaj...';
  @Input() inputPlaceholder = '';
  @Input() inputIcon = 'search';
  @Input() inputHint = '';
  @Input() select = true;
  @Input() selectOptions = [{ name: 'dummyName', value: 'dummySleect' }];
  @Input() selectPlaceholder = 'dummyPlaceholder'
  @Input() controls: Control[] = [

  ];
  textToSend = ''
  textToSendUpdate = new Subject<string>();
  spacesOccupied = 0
  barControls: Control[] = [];
  moreControls: Control[] = [];
  constructor() {

    this.textToSendUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged())
      .subscribe(value => {

        this.text.emit(value)
      });
  }

  ngOnInit(): void {

    this.barControls = [this.controls[0], this.controls[1], this.controls[2], this.controls[3], this.controls[4]];
    this.moreControls = this.controls.filter(x => !this.barControls.includes(x));


  }

  onButtonClick(buttonText) {
    this.buttonClicked.emit(buttonText)
  }

  onCheckBoxChanged(checkBoxText, checkBoxValue) {

    if (checkBoxValue !== undefined) {

      this.buttonClicked.emit(checkBoxText)
    }
  }


  onRadioClicked(radioText) {

    this.buttonClicked.emit({ activeRadio: radioText })
  }
  selectChanged(event) {

    this.buttonClicked.emit({ selectChanged: event.value })

  }
}
