<mat-card
  class="mt-1 mb-1"
  *ngIf="folderOpened && folded"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayout.lt-sm="column"
  fxLayoutAlign.lt-sm="start stretch"
  [ngClass]="{
    archived: item?.name.toLowerCase().includes('archived'),
    'approved-item': approved
  }"
  style="width: 100%"
  [style.border]="
    item?.name.toLowerCase().includes('archived')
      ? '2px solid lightgray'
      : approved
      ? '2px solid green'
      : '2px solid #673ab7'
  "
>
  <!-- Desktop layout -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxFlex
    fxLayoutGap="16px"
    fxHide.lt-sm="true"
  >
    <span class="item-name" fxFlex.gt-xs="30%" fxFlex="100%">{{
      item?.name
    }}</span>
    <span class="item-desc" fxFlex.gt-xs="50" fxFlex="100%">{{
      item?.description
    }}</span>
  </div>

  <!-- Mobile layout with inline buttons (only visible on small screens) -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxFlex
    fxHide.gt-xs="true"
    class="mobile-header"
  >
    <span class="item-name" fxFlex="auto">{{ item?.name }}</span>

    <!-- Mobile buttons (inline) -->
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      class="mobile-inline-actions"
    >
      <button
        *ngIf="!editMode && !itemSelected"
        (click)="changeItemsSelection(item)"
        mat-icon-button
        color="primary"
        matTooltip="Wybierz element"
      >
        <mat-icon>grading</mat-icon>
      </button>
      <button
        *ngIf="!editMode && itemSelected"
        (click)="changeItemsSelection(item)"
        mat-icon-button
        color="warn"
        matTooltip="Usuń z wybranych"
      >
        <mat-icon>playlist_remove</mat-icon>
      </button>
      <button
        (click)="folded = !folded"
        mat-icon-button
        color="primary"
        matTooltip="Rozwiń"
      >
        <mat-icon>expand</mat-icon>
      </button>
      <button mat-icon-button [matMenuTrigger]="mobileMenu" color="primary">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>

  <!-- Mobile description (only visible on small screens, placed below header) -->
  <div
    class="item-desc-mobile"
    fxHide.gt-xs="true"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    {{ item?.description }}
  </div>

  <!-- Desktop buttons -->
  <div fxLayout="row" fxHide.lt-sm="true">
    <button
      *ngIf="!editMode"
      (click)="toggleApproved()"
      mat-icon-button
      [color]="approved ? 'accent' : 'primary'"
      matTooltip="{{
        approved ? 'Oznaczone jako zatwierdzone' : 'Oznacz jako zatwierdzone'
      }}"
    >
      <mat-icon>{{
        approved ? "check_circle" : "check_circle_outline"
      }}</mat-icon>
    </button>
    <button
      *ngIf="!editMode && !itemSelected"
      (click)="changeItemsSelection(item)"
      mat-icon-button
      color="primary"
      matTooltip="Wybierz element"
    >
      <mat-icon>grading</mat-icon>
    </button>
    <button
      *ngIf="!editMode && itemSelected"
      (click)="changeItemsSelection(item)"
      mat-icon-button
      color="warn"
      matTooltip="Usuń z wybranych"
    >
      <mat-icon>playlist_remove</mat-icon>
    </button>
    <button
      (click)="openLessonDetailsDialog()"
      mat-icon-button
      color="primary"
      matTooltip="Szczegóły lekcji"
    >
      <mat-icon>info</mat-icon>
    </button>
    <button
      (click)="folded = !folded"
      mat-icon-button
      color="primary"
      matTooltip="Rozwiń"
    >
      <mat-icon>expand</mat-icon>
    </button>
    <button
      matTooltip="Duplikuj moduł (ręczne przeładowanie)"
      *ngIf="!editMode"
      (click)="duplicateItem(item)"
      mat-icon-button
      color="primary"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      (click)="delete(item)"
      mat-icon-button
      color="warn"
      matTooltip="Usuń"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <!-- Mobile actions menu -->
  <mat-menu #mobileMenu="matMenu">
    <button
      *ngIf="!editMode"
      (click)="toggleApproved()"
      mat-menu-item
      [color]="approved ? 'accent' : 'primary'"
    >
      <mat-icon>{{
        approved ? "check_circle" : "check_circle_outline"
      }}</mat-icon>
      <span>{{
        approved ? "Oznaczone jako zatwierdzone" : "Oznacz jako zatwierdzone"
      }}</span>
    </button>
    <button (click)="openLessonDetailsDialog()" mat-menu-item>
      <mat-icon color="primary">info</mat-icon>
      <span>Szczegóły lekcji</span>
    </button>
    <button *ngIf="!editMode" (click)="duplicateItem(item)" mat-menu-item>
      <mat-icon color="primary">refresh</mat-icon>
      <span>Duplikuj moduł</span>
    </button>
    <button (click)="delete(item)" mat-menu-item>
      <mat-icon color="warn">delete</mat-icon>
      <span>Usuń</span>
    </button>
  </mat-menu>
</mat-card>
<mat-card
  *ngIf="folderOpened && !folded"
  [style.border]="
    editMode ? '3px solid #673ab7' : approved ? '3px solid green' : null
  "
  [ngClass]="{ 'approved-item': approved }"
  class="mt-2"
>
  <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
      style="width: 100%"
    >
      <div style="width: 100%" fxLayout="column">
        <div
          fxFlex
          fxLayoutAlign="start center"
          style="width: 100%"
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutGap="15px"
        >
          <button
            *ngIf="!editMode"
            (click)="toggleApproved()"
            mat-icon-button
            [color]="approved ? 'accent' : 'primary'"
            matTooltip="{{
              approved
                ? 'Oznaczone jako zatwierdzone'
                : 'Oznacz jako zatwierdzone'
            }}"
            style="position: absolute; top: 10px; right: 10px"
          >
            <mat-icon>{{
              approved ? "check_circle" : "check_circle_outline"
            }}</mat-icon>
          </button>
          <mat-form-field
            fxFlex="25"
            fxFlex.lt-sm="100"
            class="mt-3"
            appearance="outline"
          >
            <mat-label>Nazwa</mat-label>
            <input [disabled]="!editMode" [(ngModel)]="item.name" matInput />
          </mat-form-field>
          <div fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="mt-3" fxFlex appearance="outline">
              <mat-label>Opis</mat-label>
              <textarea
                [disabled]="!editMode"
                cdkTextareaAutosize
                matInput
                [(ngModel)]="item.description"
              ></textarea>
            </mat-form-field>
          </div>
          <mat-form-field fxFlex="40" fxFlex.lt-sm="100" appearance="outline">
            <mat-chip-list class="ml-3 p-2" [disabled]="!editMode" #chipList>
              <mat-chip
                selected
                removable
                *ngFor="let tag of item?.tags"
                (removed)="removeTag(item, tag)"
                >{{ tag?.tag }}<mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                [placeholder]="!editMode ? null : 'Dodaj tag...'"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false"
                (matChipInputTokenEnd)="addTag($event, item)"
                (keydown.enter)="
                  $event.stopPropagation(); $event.preventDefault()
                "
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div
          class="mt-2"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="20px"
        >
          <div fxFlex>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                1px"
              class="mt-2"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <span
                [ngClass]="{ disabled: !editMode }"
                class="mt-2 ml-3 mat-small"
                >Umiejętności
              </span>
              <mat-chip-list multiple selectable class="ml-2 p-1 mb-2">
                <mat-chip
                  [disabled]="!editMode"
                  color="primary"
                  [selected]="item?.skills?.includes(skill)"
                  (click)="editMode ? addSkill(skill) : null"
                  *ngFor="let skill of skills"
                  >{{ skill }}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                        1px"
              class="mt-2"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <span
                [ngClass]="{ disabled: !editMode }"
                class="mt-2 ml-3 mat-small"
                >Grupy wiekowe
              </span>
              <mat-chip-list multiple selectable class="ml-2 p-1 mb-2">
                <mat-chip
                  [disabled]="!editMode"
                  selectable
                  (click)="editMode ? addRemoveAge(item, age) : null"
                  [selected]="item?.ages?.includes(age)"
                  *ngFor="let age of ages"
                  >{{ age }}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                        1px"
              class="mt-2"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <span
                [ngClass]="{ disabled: !editMode }"
                class="mt-2 ml-3 mat-small"
                >Zestawy
              </span>
              <mat-chip-list multiple selectable class="ml-2 p-1 mb-2">
                <mat-chip
                  [disabled]="!editMode"
                  selectable
                  removable
                  color="accent"
                  selected
                  *ngFor="let set of item.sets"
                  [class]="getChipClass('set', set.name)"
                  >{{ set.name
                  }}<mat-icon
                    matChipRemove
                    *ngIf="editMode"
                    (click)="editMode ? removeSet(item, set) : null"
                    >cancel
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                      1px"
              class="mt-2"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <span
                [ngClass]="{ disabled: !editMode }"
                class="mt-2 ml-3 mat-small"
                >Ćwiczenia
              </span>
              <mat-chip-list multiple selectable class="ml-2 p-1 mb-2">
                <mat-chip
                  [disabled]="!editMode"
                  selectable
                  color="accent"
                  selected
                  removable
                  *ngFor="let activity of item.activities"
                  [class]="getChipClass('activity', activity.name)"
                  >{{ activity.name
                  }}<mat-icon
                    matChipRemove
                    *ngIf="editMode"
                    (click)="editMode ? removeActivity(item, activity) : null"
                    >cancel
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                1px"
              class="mt-2"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <mat-expansion-panel #panel>
                <mat-expansion-panel-header>
                  <span [ngClass]="{ disabled: !editMode }"
                    >Zadania domowe</span
                  >
                </mat-expansion-panel-header>
                <mat-chip-list
                  *ngIf="item.sets.length > 0"
                  multiple
                  selectable
                  class="ml-2 p-1 mb-2"
                >
                  <div *ngIf="panel.opened">
                    <mat-chip
                      [disabled]="!editMode"
                      color="primary"
                      [selected]="homework.selected"
                      [class]="getChipClass('homework', homework.displayName)"
                      (click)="
                        editMode
                          ? addRemoveHomework(item, {
                              name: homework.name,
                              displayName: homework.displayName,
                              type: homework.type
                            })
                          : null
                      "
                      *ngFor="let homework of homeworks"
                      >{{ homework.displayName }}
                    </mat-chip>
                  </div>
                </mat-chip-list>
              </mat-expansion-panel>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="
                    1px"
              class="mt-2 w-100"
              style="border: 1px solid#e0e0e0; border-radius: 5px"
            >
              <mat-expansion-panel class="w-100" #filesPanel>
                <mat-expansion-panel-header>
                  <span [ngClass]="{ disabled: !editMode }">Pliki</span>
                </mat-expansion-panel-header>

                <div *ngIf="filesPanel.opened">
                  <mat-list>
                    <mat-list-item *ngFor="let file of item.files">
                      <mat-icon mat-list-icon>description</mat-icon>
                      <div class="ml-5">
                        <mat-form-field style="width: 20vw" *ngIf="editMode">
                          <mat-label>Nazwa</mat-label>
                          <input
                            [(ngModel)]="file.name"
                            matInput
                            placeholder="Nazwa"
                          />
                        </mat-form-field>
                      </div>
                      <div class="ml-5">
                        <h4 style="width: 20vw" *ngIf="!editMode" mat-line>
                          {{ file.description }}
                        </h4>
                        <mat-form-field style="width: 20vw" *ngIf="editMode">
                          <mat-label>Opis</mat-label>
                          <input
                            [(ngModel)]="file.description"
                            matInput
                            placeholder="Opis"
                          />
                        </mat-form-field>
                      </div>
                      <div class="ml-5">
                        <h4 style="width: 20vw" *ngIf="!editMode" mat-line>
                          {{ file.url | slice: 0 : 20
                          }}{{ file.url.length > 20 ? "..." : "" }}
                        </h4>
                        <mat-form-field style="width: 20vw" *ngIf="editMode">
                          <mat-label>link</mat-label>
                          <input
                            [(ngModel)]="file.url"
                            matInput
                            placeholder="Link"
                          />
                        </mat-form-field>
                      </div>
                      <a
                        class="mr-2"
                        *ngIf="!editMode"
                        mat-line
                        style="
                          color: #3f51b5;
                          text-decoration: none;
                          cursor: pointer;
                        "
                        (click)="openFileWithFreshUrl(file.url)"
                      >
                        <mat-icon class="mr-1">download</mat-icon>
                        {{ file.name }}
                      </a>
                      <div style="flex: 1"></div>
                      <button
                        *ngIf="editMode"
                        mat-icon-button
                        color="warn"
                        (dblclick)="removeFile(item, file)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex></div>
  <div fxLayout="column">
    <div fxFlex></div>

    <button *ngIf="!editMode" (click)="edit()" mat-icon-button color="primary">
      <mat-icon>edit</mat-icon>
    </button>
    <!-- Removed the archive button -->
    <button
      (click)="delete(item)"
      mat-icon-button
      color="warn"
      matTooltip="Usuń"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      *ngIf="!itemSelected"
      (click)="changeItemsSelection(item)"
      mat-icon-button
      color="primary"
    >
      <mat-icon>grading</mat-icon>
    </button>
    <button
      *ngIf="itemSelected"
      (click)="changeItemsSelection(item)"
      mat-icon-button
      color="warn"
    >
      <mat-icon>playlist_remove</mat-icon>
    </button>
    <button
      *ngIf="editMode"
      [disabled]="addingElement"
      (click)="editFinished(item)"
      mat-icon-button
      color="primary"
    >
      <mat-icon>done</mat-icon>
    </button>
    <button
      [disabled]="addingElement"
      *ngIf="editMode"
      (click)="this.editMode = false"
      mat-icon-button
    >
      <mat-icon>cancel_outline</mat-icon>
    </button>
    <button (click)="folded = !folded" mat-icon-button color="primary">
      <mat-icon>expand</mat-icon>
    </button>
    <button
      (click)="openAddSetsList(item)"
      matTooltip="Dodaj Zestaw"
      [disabled]="addingElement"
      *ngIf="editMode"
      mat-icon-button
      color="primary"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
    <button
      (click)="openAddActivityPanel(item)"
      [disabled]="addingElement"
      *ngIf="editMode"
      mat-icon-button
      color="add"
      matTooltip="Dodaj ćwiczenie"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      (click)="addFile()"
      [disabled]="addingElement"
      *ngIf="editMode"
      mat-icon-button
      color="primary"
      matTooltip="Dodaj plik"
    >
      <span class="material-symbols-outlined"> upload_file </span>
    </button>

    <button
      (click)="openLessonDetailsDialog()"
      mat-icon-button
      color="primary"
      matTooltip="Open Lesson Details"
    >
      <mat-icon>info</mat-icon>
    </button>
    <span style="flex: 1"></span>
  </div>
</mat-card>
