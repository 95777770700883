import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Suspect {
  name: string;
  description: string;
  emoji: string;
}

export interface DetectiveStory {
  id: string;
  title: string;
  content?: string;
  suspects: Suspect[];
  level: 'beginner' | 'intermediate' | 'advanced';
  ageRange: [number, number];
}

@Injectable({
  providedIn: 'root'
})
export class DetectiveStoryService {
  // Add a BehaviorSubject to share the correct answer pattern between components
  private correctAnswerPatternSource = new BehaviorSubject<string[]>([]);
  correctAnswerPattern$ = this.correctAnswerPatternSource.asObservable();

  // Set the correct answer pattern
  setCorrectAnswerPattern(pattern: string[]): void {
    this.correctAnswerPatternSource.next(pattern);
  }

  // Get the current correct answer pattern
  getCorrectAnswerPattern(): string[] {
    return this.correctAnswerPatternSource.value;
  }

  private stories: DetectiveStory[] = [
    {
      id: '1',
      title: 'The Case of the Missing Pet',
      content: `Max's cat Whiskers went missing yesterday. 

Max was very sad and asked his friends to help find Whiskers.

"When did you last see Whiskers?" asked Emma.

"I saw her in the backyard in the evening," Max said.

"What was the weather like?" asked Sam.

"It was sunny and warm," Max replied.

The friends looked for clues. They found the gate was open and there was a small hole in the fence. They also heard a cat meowing somewhere nearby. They noticed some small paw prints and kibble on the ground.

"Whiskers doesn't have her collar on," Max remembered. "It fell off yesterday."

They need to figure out where Whiskers went. Can you help them solve the mystery?`,
      suspects: [
        {
          name: "Mrs. Johnson",
          description: "Friendly neighbor who feeds stray animals",
          emoji: "👵"
        },
        {
          name: "Tommy",
          description: "Boy from next door who likes to play with animals",
          emoji: "👦"
        },
        {
          name: "The Brown Dog",
          description: "Big dog that sometimes visits the neighborhood",
          emoji: "🐕"
        },
        {
          name: "Mail Carrier",
          description: "Delivers mail and often sees neighborhood animals",
          emoji: "📬"
        },
        {
          name: "Garden Gnome",
          description: "Statue that might have seen something",
          emoji: "🧙"
        },
        {
          name: "The Black Cat",
          description: "Mysterious cat that roams the neighborhood",
          emoji: "🐈‍⬛"
        },
        {
          name: "Bird Watcher",
          description: "Person who observes birds and other animals",
          emoji: "🦅"
        },
        {
          name: "Ice Cream Truck",
          description: "Vehicle that attracts animals with its music",
          emoji: "🍦"
        },
        {
          name: "The Squirrel",
          description: "Busy animal that might have seen something",
          emoji: "🐿️"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '2',
      title: 'The Mystery of the Missing Toy',
      content: `Lily couldn't find her favorite teddy bear. She looked everywhere!

"I had it in the living room on Sunday," Lily told her mom.

Mom helped Lily look for her teddy. They searched high and low.

"Look for clues," Mom said. "That might help us."

Lily found some pet hair on the floor. She also saw small footprints. During the night, she thought she heard purring sounds. Her teddy was medium-sized and brown.

Finally, Lily looked behind the sofa. There was her teddy bear!

But who moved Lily's teddy bear? Can you solve the mystery?`,
      suspects: [
        {
          name: "Fluffy",
          description: "Family cat who likes to play with toys",
          emoji: "🐱"
        },
        {
          name: "Baby Brother",
          description: "Lily's little brother who is learning to crawl",
          emoji: "👶"
        },
        {
          name: "Robot Toy",
          description: "Toy that sometimes moves when batteries are on",
          emoji: "🤖"
        },
        {
          name: "The Vacuum Cleaner",
          description: "Might have sucked up the toy",
          emoji: "🧹"
        },
        {
          name: "The Closet Monster",
          description: "Mythical creature that takes things",
          emoji: "👹"
        },
        {
          name: "The Laundry Basket",
          description: "Where lost things sometimes end up",
          emoji: "🧺"
        },
        {
          name: "The Window",
          description: "Maybe the toy fell out",
          emoji: "🪟"
        },
        {
          name: "The Neighbor's Cat",
          description: "Might have taken the toy",
          emoji: "🐈"
        },
        {
          name: "The Toy Box",
          description: "Where toys sometimes hide",
          emoji: "📦"
        }
      ],
      level: 'beginner',
      ageRange: [5, 7]
    },
    {
      id: '3',
      title: 'The Cookie Jar Mystery',
      content: `Mom made fresh chocolate chip cookies and put them in the cookie jar. She told everyone not to eat them until after dinner. The cookies were round and smelled sweet.

At lunchtime, Dad went to the kitchen to make sandwiches. He noticed something strange.

"The cookies are gone!" he called out. "All of them!"

The family gathered in the kitchen. The cookie jar was empty!

Dad found cookie crumbs on the floor. Mom noticed a chair pulled up to the counter.

Who took the cookies from the cookie jar? Can you solve the mystery?`,
      suspects: [
        {
          name: "Little Sister",
          description: "Always has a sweet tooth",
          emoji: "👧"
        },
        {
          name: "Big Brother",
          description: "Was playing video games all morning",
          emoji: "👦"
        },
        {
          name: "Family Dog",
          description: "Can't reach the counter without help",
          emoji: "🐕"
        },
        {
          name: "The Mouse",
          description: "Small creature that loves cookies",
          emoji: "🐭"
        },
        {
          name: "The Cleaning Robot",
          description: "Might have moved the cookies",
          emoji: "🤖"
        },
        {
          name: "The Babysitter",
          description: "Was watching the kids earlier",
          emoji: "👩‍🦰"
        },
        {
          name: "The Cookie Monster",
          description: "Famous cookie lover",
          emoji: "👾"
        },
        {
          name: "The Delivery Person",
          description: "Was at the house earlier",
          emoji: "🚚"
        },
        {
          name: "The Magic Oven",
          description: "Might have made the cookies disappear",
          emoji: "🔥"
        }
      ],
      level: 'intermediate',
      ageRange: [7, 9]
    },
    {
      id: '4',
      title: 'The Disappearing Homework',
      content: `Alex finished his homework and put it in his school bag. Next morning, it was gone!

"My teacher will be upset," said Alex. "I did my homework last night!"

Alex's mom asked, "Where did you put your bag?"

"By the front door," Alex said.

"Did anyone touch your bag?" his mom asked.

Alex thought hard. "My bag was open this morning. And I saw some water drops near it."

Who took Alex's homework? Can you solve the mystery?`,
      suspects: [
        {
          name: "Little Brother",
          description: "Likes to draw on papers",
          emoji: "👦"
        },
        {
          name: "The Family Dog",
          description: "Sometimes chews on things",
          emoji: "🐕"
        },
        {
          name: "Dad",
          description: "Was looking for some documents",
          emoji: "👨"
        },
        {
          name: "The Rain",
          description: "It rained last night and the window was open",
          emoji: "🌧️"
        },
        {
          name: "The School Bus Driver",
          description: "Helped Alex with his bag",
          emoji: "🚌"
        },
        {
          name: "Alex's Friend",
          description: "Came over to play yesterday",
          emoji: "👧"
        },
        {
          name: "The Wind",
          description: "Strong winds last night",
          emoji: "💨"
        },
        {
          name: "The Paper Airplane",
          description: "Alex made one yesterday",
          emoji: "✈️"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '5',
      title: 'The Mysterious Footprints',
      content: `Lisa woke up and found strange footprints in her garden. They were small and round.

"Look at these footprints!" Lisa told her dad. "Who made them?"

Dad looked closely. "They go from the gate to the bird feeder."

Lisa noticed the bird feeder was empty. "All the seeds are gone!"

They saw water drops near the footprints. There were also some feathers on the ground.

What made the footprints in Lisa's garden? Can you solve the mystery?`,
      suspects: [
        {
          name: "Neighborhood Cat",
          description: "Likes to hunt birds",
          emoji: "🐱"
        },
        {
          name: "The Squirrel",
          description: "Loves to eat seeds",
          emoji: "🐿️"
        },
        {
          name: "The Rabbit",
          description: "Lives in the nearby bushes",
          emoji: "🐰"
        },
        {
          name: "The Raccoon",
          description: "Comes out at night to find food",
          emoji: "🦝"
        },
        {
          name: "The Wind",
          description: "Could have blown the seeds away",
          emoji: "💨"
        },
        {
          name: "The Rain",
          description: "It rained last night",
          emoji: "🌧️"
        },
        {
          name: "The Bird",
          description: "Maybe ate all the seeds",
          emoji: "🐦"
        },
        {
          name: "The Garden Gnome",
          description: "Decorative statue in the garden",
          emoji: "🧙"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '6',
      title: 'The Missing Library Book',
      content: `Tom borrowed a dinosaur book from the school library. He put it in his desk before lunch. After lunch, the book was gone!

"I need to return it tomorrow," Tom said to his friend Mia.

"Let's look for clues," said Mia.

They found a pencil that wasn't Tom's near his desk. There was also a dinosaur sticker on the floor.

Tom remembered that the art class used dinosaur stickers today. He also noticed his desk was slightly moved.

Who took Tom's library book? Can you solve the mystery?`,
      suspects: [
        {
          name: "Ms. Johnson",
          description: "The teacher who loves dinosaurs",
          emoji: "👩‍🏫"
        },
        {
          name: "Jake",
          description: "Sits next to Tom and collects stickers",
          emoji: "👦"
        },
        {
          name: "The Janitor",
          description: "Cleaned the classroom during lunch",
          emoji: "🧹"
        },
        {
          name: "Library Assistant",
          description: "Collects overdue books",
          emoji: "👧"
        },
        {
          name: "The Class Pet",
          description: "A hamster that sometimes escapes its cage",
          emoji: "🐹"
        },
        {
          name: "Emma",
          description: "Has art class with Tom",
          emoji: "👧"
        },
        {
          name: "The Substitute Teacher",
          description: "Was in class briefly",
          emoji: "👨‍🏫"
        },
        {
          name: "The Wind",
          description: "The window was open during lunch",
          emoji: "💨"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '7',
      title: 'The Case of the Broken Vase',
      content: `Mom's favorite vase was broken. It was on the living room table, and now it was in pieces on the floor.

"Who broke my vase?" asked Mom. Nobody answered.

Dad said, "It was fine when I left for work."

There were small wet footprints near the broken vase. The cat's toy mouse was also there. The window was slightly open.

Mom noticed the flowers from the vase were arranged neatly on the table, not scattered.

Who broke Mom's vase? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Cat",
          description: "Likes to jump on tables",
          emoji: "🐱"
        },
        {
          name: "Little Sister",
          description: "Was playing in the living room",
          emoji: "👧"
        },
        {
          name: "Big Brother",
          description: "Was practicing soccer indoors",
          emoji: "👦"
        },
        {
          name: "The Wind",
          description: "The window was open",
          emoji: "💨"
        },
        {
          name: "The Dog",
          description: "Wags tail very hard when excited",
          emoji: "🐕"
        },
        {
          name: "Grandma",
          description: "Visited that morning",
          emoji: "👵"
        },
        {
          name: "The Vacuum Robot",
          description: "Cleans the house automatically",
          emoji: "🤖"
        },
        {
          name: "The Plant",
          description: "Has grown big with long leaves",
          emoji: "🌿"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '8',
      title: 'The Bicycle Mystery',
      content: `Mike's bicycle disappeared from the school bike rack. He was sure he locked it before class.

"I left it right here," Mike told his teacher. "It's a red mountain bike."

The teacher called the principal. They checked around the school.

The lock was on the ground, still closed. There were tire tracks leading to the back gate. A student reported seeing someone in a blue jacket near the bikes during lunch.

Mike remembered his bike had a squeaky wheel. The security camera at the front gate didn't show anyone leaving with a bike.

Where is Mike's bicycle? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Janitor",
          description: "Moves bikes when cleaning the area",
          emoji: "🧹"
        },
        {
          name: "New Student",
          description: "Might have taken the wrong bike by mistake",
          emoji: "👦"
        },
        {
          name: "Jake",
          description: "Mike's friend who borrowed his bike before",
          emoji: "👦"
        },
        {
          name: "The Gym Teacher",
          description: "Stores bikes for safety sometimes",
          emoji: "👨‍🏫"
        },
        {
          name: "The Principal",
          description: "Removes unlocked bikes",
          emoji: "👨‍💼"
        },
        {
          name: "Bike Repair Shop Owner",
          description: "Fixes bikes for students",
          emoji: "👨‍🔧"
        },
        {
          name: "Mike's Sister",
          description: "Needed a bike today",
          emoji: "👧"
        },
        {
          name: "The Groundskeeper",
          description: "Maintains the school property",
          emoji: "🧑‍🌾"
        }
      ],
      level: 'intermediate',
      ageRange: [11, 13]
    },
    {
      id: '9',
      title: 'The Disappearing Phone',
      content: `Sarah couldn't find her phone after PE class. She remembered putting it in her locker.

"I locked my locker," Sarah told her friend. "But now my phone is gone."

The locker wasn't damaged. Sarah's wallet and watch were still there. 

The PE teacher said, "All students should check their bags."

Sarah's phone had a distinctive blue case with stars. During the day, she heard a ringtone like hers coming from somewhere in the school. Her friend tried calling Sarah's number, but there was no answer.

Sarah remembered letting someone use her phone that morning to call home.

Where is Sarah's phone? Can you solve the mystery?`,
      suspects: [
        {
          name: "Locker Neighbor",
          description: "Has a similar looking locker",
          emoji: "👧"
        },
        {
          name: "Best Friend",
          description: "Borrowed Sarah's phone before",
          emoji: "👧"
        },
        {
          name: "PE Teacher",
          description: "Collects devices during class sometimes",
          emoji: "👩‍🏫"
        },
        {
          name: "The New Student",
          description: "Asked to use Sarah's phone that morning",
          emoji: "👦"
        },
        {
          name: "Lost and Found Box",
          description: "Where found items are placed",
          emoji: "📦"
        },
        {
          name: "The Janitor",
          description: "Cleans the locker room",
          emoji: "🧹"
        },
        {
          name: "Science Teacher",
          description: "Confiscates phones in class",
          emoji: "👨‍🔬"
        },
        {
          name: "Sarah's Sister",
          description: "Also goes to the same school",
          emoji: "👧"
        }
      ],
      level: 'advanced',
      ageRange: [11, 13]
    },
    {
      id: '10',
      title: 'The Museum Artifact Mystery',
      content: `During a school trip to the museum, a small ancient coin went missing from its display case. The alarm didn't sound.

The museum director said, "The coin was here during the morning tour."

The display case wasn't broken. The security camera showed a brief power outage at 2:15 PM. The class was in the Egypt section at that time.

One student noticed the cleaning staff working near the coin display. Another remembered seeing a museum guard talking on the phone and looking worried.

The tour guide had a special key for some display cases. A student reported seeing someone drop something small and shiny into their bag.

What happened to the ancient coin? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Tour Guide",
          description: "Has access to display cases",
          emoji: "👩‍🦰"
        },
        {
          name: "Museum Guard",
          description: "Was acting strangely",
          emoji: "👮"
        },
        {
          name: "History Teacher",
          description: "Very interested in ancient coins",
          emoji: "👨‍🏫"
        },
        {
          name: "Cleaning Staff",
          description: "Working near the display",
          emoji: "🧹"
        },
        {
          name: "The Photographer",
          description: "Taking pictures of the exhibits",
          emoji: "📸"
        },
        {
          name: "Museum Director",
          description: "Reported the missing coin",
          emoji: "👨‍💼"
        },
        {
          name: "The Technician",
          description: "Working on the lights during the power outage",
          emoji: "👨‍🔧"
        },
        {
          name: "Exchange Student",
          description: "Seen dropping something shiny",
          emoji: "👧"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '11',
      title: 'The Abandoned House Secret',
      content: `A group of friends noticed strange lights at night in an abandoned house at the edge of town. The house had been empty for years.

"Nobody should be in there," said James. "It's not safe."

The friends decided to investigate during the day. They found footprints in the dust leading to the basement. There were also fresh food wrappers and a battery-powered lamp.

In one room, they discovered a wall with newly installed locks. Strange sounds came from behind it. They found a notebook with coded messages and a map of the town with certain houses marked.

The local newspaper recently reported missing pets in the neighborhood.

What's happening in the abandoned house? Can you solve the mystery?`,
      suspects: [
        {
          name: "Homeless Person",
          description: "Looking for shelter",
          emoji: "🧔"
        },
        {
          name: "The Old Owner",
          description: "Secretly returned to the house",
          emoji: "👴"
        },
        {
          name: "Wildlife Researcher",
          description: "Studying local animals",
          emoji: "👩‍🔬"
        },
        {
          name: "Teenagers",
          description: "Using it as a hangout spot",
          emoji: "👫"
        },
        {
          name: "Animal Rescuer",
          description: "Helping stray animals",
          emoji: "👨"
        },
        {
          name: "Property Developer",
          description: "Examining the house structure",
          emoji: "👨‍💼"
        },
        {
          name: "Local Criminal",
          description: "Using it as a hideout",
          emoji: "🕵️"
        },
        {
          name: "Film Crew",
          description: "Preparing to shoot a movie",
          emoji: "🎬"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '12',
      title: 'The Corporate Sabotage Case',
      content: `TechCore's new software release was delayed after their main server crashed. The CEO suspected sabotage.

"Our security cameras were disabled for exactly 20 minutes last night," the security chief reported.

The IT team found that the server room had been accessed at 11:45 PM using a valid keycard. Only senior staff had access. The logs showed that the system failure was caused by an unauthorized program.

A janitor reported seeing someone in a suit working late. The R&D director mentioned that their main competitor recently hired a former TechCore employee. The code used in the malicious program contained references to older TechCore projects.

An employee resignation letter was drafted but never sent, found in a deleted email folder.

Who sabotaged TechCore's server? Can you solve the mystery?`,
      suspects: [
        {
          name: "Former CTO",
          description: "Left the company last month",
          emoji: "👨‍💼"
        },
        {
          name: "IT Security Manager",
          description: "Has access to all systems",
          emoji: "👨‍💻"
        },
        {
          name: "New Intern",
          description: "Started working in server maintenance",
          emoji: "👩‍💻"
        },
        {
          name: "Rival Company Spy",
          description: "Attempting corporate espionage",
          emoji: "🕵️"
        },
        {
          name: "R&D Director",
          description: "Project was going to be reassigned",
          emoji: "👨‍🔬"
        },
        {
          name: "System Administrator",
          description: "Has complete access to servers",
          emoji: "👨‍💻"
        },
        {
          name: "Cleaning Staff",
          description: "Works after hours with access to offices",
          emoji: "🧹"
        },
        {
          name: "Marketing VP",
          description: "Against the new software release timing",
          emoji: "👩‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '13',
      title: 'The Art Gallery Heist',
      content: `A valuable painting disappeared from the Riverside Art Gallery during its annual gala. The alarm system was not triggered.

"The painting was in place when I checked at 9:30 PM," said the curator.

Security footage showed no one approaching the painting, but there was a 5-minute gap in the camera coverage due to a "technical glitch." The attendance list revealed that several art collectors and a famous art restorer were present.

The frame was found in a storage closet, but the canvas was gone. Analysis showed the security code was entered correctly during the time of the theft. A waiter recalled seeing someone with a large portfolio case leaving early.

The painting had recently been authenticated after questions about its provenance arose.

Who stole the painting? Can you solve the mystery?`,
      suspects: [
        {
          name: "Art Restorer",
          description: "Expert in handling valuable paintings",
          emoji: "👨‍🎨"
        },
        {
          name: "Gallery Owner",
          description: "In financial trouble recently",
          emoji: "👩‍💼"
        },
        {
          name: "Security Guard",
          description: "New hire with access to all areas",
          emoji: "👮"
        },
        {
          name: "Art Collector",
          description: "Made offers to buy the painting previously",
          emoji: "🧔"
        },
        {
          name: "The Artist's Heir",
          description: "Claims the painting was stolen from their family",
          emoji: "👩"
        },
        {
          name: "Insurance Investigator",
          description: "Present to assess gallery security",
          emoji: "🕵️"
        },
        {
          name: "Waiter Staff",
          description: "Had access throughout the event",
          emoji: "🧑‍🍳"
        },
        {
          name: "Rival Gallery Owner",
          description: "Competing for the same clientele",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '14',
      title: 'The Lost School Lunch',
      content: `Ben brought his favorite lunch to school. He put his lunch box in his backpack.

At lunch time, Ben couldn't find his lunch box!

"I'm sure I packed it," Ben told his teacher.

They looked in Ben's backpack. The lunch box wasn't there.

Ben remembered putting his backpack on the floor near his desk. He saw a small hole in his backpack.

Some crumbs were on the classroom floor. Ben's friend said they saw someone near Ben's backpack.

Who took Ben's lunch? Can you solve the mystery?`,
      suspects: [
        {
          name: "Class Pet Hamster",
          description: "Sometimes escapes from its cage",
          emoji: "🐹"
        },
        {
          name: "The Art Teacher",
          description: "Came to borrow supplies from Ben's class",
          emoji: "👩‍🎨"
        },
        {
          name: "Ben's Friend",
          description: "Forgot their lunch today",
          emoji: "👦"
        },
        {
          name: "Classroom Mouse",
          description: "Lives in the wall and comes out sometimes",
          emoji: "🐭"
        },
        {
          name: "The School Cleaner",
          description: "Was cleaning classrooms early",
          emoji: "🧹"
        },
        {
          name: "Lunch Monitor",
          description: "Helps organize lunch boxes",
          emoji: "👧"
        },
        {
          name: "The Lost and Found Box",
          description: "Where misplaced items go",
          emoji: "📦"
        },
        {
          name: "Ben's Little Sister",
          description: "Visited Ben's classroom this morning",
          emoji: "👧"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '15',
      title: 'The Playground Puzzle',
      content: `Anna found a paper with strange drawings on the playground. It looked like a map.

"Look what I found!" Anna showed her friends.

The paper had X marks and arrows. It had the words "secret" and "treasure."

Anna and her friends decided to follow the map. It led them around the playground.

They found small stickers on the ground where the X marks were on the map. The stickers made a path.

Who made the mysterious map? Can you solve the puzzle?`,
      suspects: [
        {
          name: "The Art Teacher",
          description: "Likes creative projects",
          emoji: "👩‍🎨"
        },
        {
          name: "School Principal",
          description: "Wants to make school fun",
          emoji: "👨‍💼"
        },
        {
          name: "The Older Kids",
          description: "Like to play games with younger students",
          emoji: "👫"
        },
        {
          name: "Anna's Brother",
          description: "Knows Anna likes treasure hunts",
          emoji: "👦"
        },
        {
          name: "The School Librarian",
          description: "Promotes reading adventures",
          emoji: "👩‍🏫"
        },
        {
          name: "Birthday Party Planner",
          description: "Setting up for someone's birthday",
          emoji: "🎂"
        },
        {
          name: "New Student",
          description: "Wants to make friends",
          emoji: "👧"
        },
        {
          name: "The Wind",
          description: "Blew the paper from somewhere else",
          emoji: "💨"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '16',
      title: 'The Missing School Mascot',
      content: `The school mascot costume disappeared from the gym closet. It was needed for tomorrow's big game.

"The tiger costume is gone!" said the PE teacher. "We need it for the game!"

The students searched the school. The gym closet was not locked.

They found some orange fur on the floor near the art room. The janitor said he saw someone carrying a big bag after school.

The costume was last seen after yesterday's practice. The drama club was using the gym in the morning.

Who took the school mascot costume? Can you solve the mystery?`,
      suspects: [
        {
          name: "Drama Teacher",
          description: "Needs costumes for the school play",
          emoji: "👨‍🏫"
        },
        {
          name: "Rival School Student",
          description: "Their team is playing tomorrow",
          emoji: "👦"
        },
        {
          name: "School Photographer",
          description: "Taking pictures for the yearbook",
          emoji: "📸"
        },
        {
          name: "The Janitor",
          description: "Cleaning the gym closet",
          emoji: "🧹"
        },
        {
          name: "School Principal",
          description: "Wanted the costume cleaned",
          emoji: "👩‍💼"
        },
        {
          name: "Team Captain",
          description: "Responsible for team equipment",
          emoji: "👧"
        },
        {
          name: "Art Teacher",
          description: "Making repairs to school items",
          emoji: "👩‍🎨"
        },
        {
          name: "Mascot Player",
          description: "The student who wears the costume",
          emoji: "👦"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '17',
      title: 'The Science Fair Mystery',
      content: `The day before the science fair, Peter's volcano project stopped working. It was fine yesterday!

"My volcano won't erupt anymore," Peter told his teacher. "Someone changed it."

The science projects were kept in the classroom overnight. The door was locked, but the window was open a little.

Peter noticed his volcano had water instead of vinegar. The baking soda packet was almost empty. There were small footprints near his table.

Other projects seemed to be moved slightly. The class pet cage was open.

Who changed Peter's science project? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Class Hamster",
          description: "Escaped from its cage overnight",
          emoji: "🐹"
        },
        {
          name: "Cleaning Staff",
          description: "Cleaned the classroom last night",
          emoji: "🧹"
        },
        {
          name: "Another Student",
          description: "Competing in the same category",
          emoji: "👧"
        },
        {
          name: "Peter's Little Brother",
          description: "Visited the school yesterday",
          emoji: "👦"
        },
        {
          name: "Science Teacher",
          description: "Testing projects for safety",
          emoji: "👨‍🔬"
        },
        {
          name: "The Wind",
          description: "Came through the open window",
          emoji: "💨"
        },
        {
          name: "School Security Guard",
          description: "Checks classrooms at night",
          emoji: "👮"
        },
        {
          name: "The Math Teacher",
          description: "Has the classroom next door",
          emoji: "👩‍🏫"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '18',
      title: 'The Missing Game Console',
      content: `Kevin brought his new game console to school for game club. He put it in his locker before class.

After school, the console was gone! The locker wasn't damaged.

"I definitely put it in my locker this morning," Kevin told the teacher.

Three other students had lockers near Kevin's. The school security camera wasn't working that day.

Kevin remembered lending his locker key to his friend to get a book. He also noticed a flyer for a gaming tournament with a prize for the winner.

The janitor reported finding an empty game console box in the trash.

Where is Kevin's game console? Can you solve the mystery?`,
      suspects: [
        {
          name: "Kevin's Best Friend",
          description: "Borrowed Kevin's locker key",
          emoji: "👦"
        },
        {
          name: "Game Club President",
          description: "Organizing the school tournament",
          emoji: "👧"
        },
        {
          name: "New Student",
          description: "Very interested in gaming",
          emoji: "👦"
        },
        {
          name: "The Janitor",
          description: "Found the empty box",
          emoji: "🧹"
        },
        {
          name: "Kevin's Brother",
          description: "Also attends the same school",
          emoji: "👦"
        },
        {
          name: "Computer Teacher",
          description: "Runs the game club",
          emoji: "👨‍🏫"
        },
        {
          name: "School Security Guard",
          description: "Has access to all lockers",
          emoji: "👮"
        },
        {
          name: "Locker Neighbor",
          description: "Might have a similar locker",
          emoji: "👧"
        }
      ],
      level: 'intermediate',
      ageRange: [11, 13]
    },
    {
      id: '19',
      title: 'The Anonymous Note',
      content: `Mia found a strange note in her backpack. It said: "I know what you did last Tuesday."

"What does this mean?" Mia asked her friend. "I didn't do anything special last Tuesday."

Mia tried to remember what she did that day. She had a math test and won a spelling contest. She also accidentally broke a beaker in science class.

The note was written on special paper from the art room. The handwriting was disguised.

Mia noticed some other students whispering and looking at her. She also found a small drawing on the back of the note.

Who sent the mysterious note? Can you solve the mystery?`,
      suspects: [
        {
          name: "Best Friend",
          description: "Planning Mia's surprise party",
          emoji: "👧"
        },
        {
          name: "Science Teacher",
          description: "Knows about the broken beaker",
          emoji: "👩‍🔬"
        },
        {
          name: "Math Class Partner",
          description: "Saw Mia during the test",
          emoji: "👦"
        },
        {
          name: "Art Club Member",
          description: "Has access to the special paper",
          emoji: "👧"
        },
        {
          name: "Spelling Contest Runner-up",
          description: "Lost to Mia in the contest",
          emoji: "👦"
        },
        {
          name: "New Student",
          description: "Trying to make friends in a strange way",
          emoji: "👧"
        },
        {
          name: "School Newspaper Editor",
          description: "Looking for interesting stories",
          emoji: "👨"
        },
        {
          name: "Drama Club President",
          description: "Known for practical jokes",
          emoji: "👧"
        }
      ],
      level: 'advanced',
      ageRange: [11, 13]
    },
    {
      id: '20',
      title: 'The Disappearing Street Art',
      content: `A famous street artist created a new mural in the city center. The next morning, it was gone, with only a blank wall remaining.

"The mural was definitely here yesterday," said a shop owner. "I saw people taking photos of it."

The wall showed no signs of painting over. There were no paint stains nearby. Security cameras in the area were disabled during the night.

A witness reported seeing a truck with no markings parked by the wall around midnight. Another mentioned seeing people in work uniforms.

The art depicted a local historical event. It was creating a lot of attention on social media. The building owner had not given permission for the original artwork.

What happened to the street art? Can you solve the mystery?`,
      suspects: [
        {
          name: "Building Owner",
          description: "Didn't approve the original artwork",
          emoji: "👨‍💼"
        },
        {
          name: "City Council",
          description: "Enforces strict rules about public art",
          emoji: "🏛️"
        },
        {
          name: "Rival Artist",
          description: "Competing for attention in the area",
          emoji: "👩‍🎨"
        },
        {
          name: "Art Collector",
          description: "Known for acquiring street art by unusual means",
          emoji: "🧔"
        },
        {
          name: "Construction Company",
          description: "Working on nearby buildings",
          emoji: "👷"
        },
        {
          name: "Museum Curator",
          description: "Interested in preserving important artworks",
          emoji: "👩‍💼"
        },
        {
          name: "The Original Artist",
          description: "Known for creating temporary installations",
          emoji: "👨‍🎨"
        },
        {
          name: "Local Business Association",
          description: "Concerned about neighborhood appearance",
          emoji: "👔"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '21',
      title: 'The High School Time Capsule Mystery',
      content: `During renovation of the high school, workers discovered an old time capsule that wasn't on any school records. Inside was a cryptic message about a secret room.

"This isn't the official time capsule from 1985," said the principal. "That one is still in place."

The capsule contained photos of a part of the school that no one recognized, old keychains, and a hand-drawn map with strange symbols. A note mentioned "where we hid everything before graduation."

The date on the capsule was from 25 years ago. Some current teachers were students at that time. One of the photos showed five students, but their faces were partly hidden.

The renovation crew reported finding unusual wall configurations in the east wing. The school yearbook from that year had several pages missing.

What is the mystery behind the secret time capsule? Can you solve it?`,
      suspects: [
        {
          name: "Former Student Group",
          description: "Created a secret club 25 years ago",
          emoji: "👥"
        },
        {
          name: "Current School Librarian",
          description: "Was a student during that time",
          emoji: "👩‍🏫"
        },
        {
          name: "Science Teacher",
          description: "Known for elaborate student projects",
          emoji: "👨‍🔬"
        },
        {
          name: "Former Principal",
          description: "Might have known about secret student activities",
          emoji: "👴"
        },
        {
          name: "History Teacher",
          description: "Researches school history as a hobby",
          emoji: "👩‍🏫"
        },
        {
          name: "Maintenance Worker",
          description: "Has worked at the school for 30 years",
          emoji: "👨‍🔧"
        },
        {
          name: "Drama Club",
          description: "Known for time capsule traditions",
          emoji: "🎭"
        },
        {
          name: "School Newspaper Team",
          description: "Documented school events and secrets",
          emoji: "📰"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '22',
      title: 'The Dark Web Marketplace Mystery',
      content: `A cybersecurity firm detected unusual activity from a dark web marketplace. Transaction data suggested stolen information was being sold from a local company.

"The data breach didn't trigger any security alerts," said the IT director. "But customer information is definitely appearing online."

Analysis showed the stolen data contained information only accessible to senior staff. The company's firewall logs showed no unusual access patterns. However, a temporary network outage occurred during system maintenance last week.

Internal investigation revealed several employees had attended the same cybersecurity conference. A new security consultant had recently been hired. The company had just rejected a takeover offer from a larger competitor.

A coded message was found in the metadata of recently modified company files. An employee reported seeing someone in the server room outside normal hours.

Who is behind the data breach? Can you solve the mystery?`,
      suspects: [
        {
          name: "New Security Consultant",
          description: "Has access to all systems for testing",
          emoji: "👨‍💻"
        },
        {
          name: "Disgruntled Former Employee",
          description: "Was let go during recent layoffs",
          emoji: "👩‍💼"
        },
        {
          name: "IT Manager",
          description: "Oversees all security protocols",
          emoji: "👨‍💻"
        },
        {
          name: "Competing Company Agent",
          description: "Seeking competitive advantage",
          emoji: "🕵️"
        },
        {
          name: "Night Shift Cleaner",
          description: "Has access to offices after hours",
          emoji: "🧹"
        },
        {
          name: "Third-party Vendor",
          description: "Provides technical services to the company",
          emoji: "👨‍🔧"
        },
        {
          name: "Cybercriminal Group",
          description: "Known for targeting similar companies",
          emoji: "👤"
        },
        {
          name: "Company Intern",
          description: "Works across multiple departments",
          emoji: "👩‍💻"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '23',
      title: 'The Underground Poker Game Scandal',
      content: `A high-stakes underground poker game ended in chaos when players discovered marked cards. $250,000 in cash disappeared during the confusion.

"Someone switched the decks during the break," claimed a player. "Then the lights went out."

The game was held in a private room at an exclusive club. Only invited players and staff knew about it. Security footage from the hallway showed everyone who entered and left, but the camera inside the room was disabled.

The dealer was new, recommended by a regular player. The club owner had financial troubles recently. A waitress reported overhearing a heated phone conversation in the bathroom before the game.

The cards were found to have invisible markings visible only with special glasses. A player's lucky chip was discovered under the table with strange scratches on it.

Who took the money from the poker game? Can you solve the mystery?`,
      suspects: [
        {
          name: "The New Dealer",
          description: "Recently hired with limited references",
          emoji: "👨‍💼"
        },
        {
          name: "Club Owner",
          description: "In debt with mounting pressure from creditors",
          emoji: "👨‍💼"
        },
        {
          name: "Professional Player",
          description: "Has been losing heavily in recent games",
          emoji: "🧔"
        },
        {
          name: "Waitress",
          description: "Has access to the room during service",
          emoji: "👩‍🍳"
        },
        {
          name: "Security Guard",
          description: "Responsible for monitoring the games",
          emoji: "👮"
        },
        {
          name: "Club Regular",
          description: "Knows the layout and security protocols",
          emoji: "👨"
        },
        {
          name: "Professional Cheater",
          description: "Suspected of operating in other venues",
          emoji: "🕵️"
        },
        {
          name: "Business Rival",
          description: "Wants to damage the club's reputation",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '24',
      title: 'The Magic Crayon Mystery',
      content: `Tim got a new box of crayons for his birthday. He put them on his desk at school.

After recess, his favorite blue crayon was gone!

"I had all my crayons before recess," Tim told his teacher.

The teacher looked at Tim's desk. The crayon box was open. Some crayons were on the floor.

Tim saw blue marks on a paper nearby. His friend said they saw someone using a blue crayon during art time.

Where is Tim's blue crayon? Can you solve the mystery?`,
      suspects: [
        {
          name: "Lucy",
          description: "Sits next to Tim and likes to draw",
          emoji: "👧"
        },
        {
          name: "Sam",
          description: "Forgot his crayons at home today",
          emoji: "👦"
        },
        {
          name: "The Art Teacher",
          description: "Collecting supplies for a project",
          emoji: "👩‍🎨"
        },
        {
          name: "The Floor",
          description: "Crayons sometimes roll away",
          emoji: "📏"
        },
        {
          name: "Tim's Backpack",
          description: "Where Tim keeps his things",
          emoji: "🎒"
        },
        {
          name: "Class Helper",
          description: "Cleans up after art time",
          emoji: "👦"
        },
        {
          name: "The Crayon Box",
          description: "Has a hole in the bottom",
          emoji: "📦"
        },
        {
          name: "The Pencil Sharpener",
          description: "Located near Tim's desk",
          emoji: "✏️"
        }
      ],
      level: 'beginner',
      ageRange: [5, 7]
    },
    {
      id: '25',
      title: 'The Birthday Cake Surprise',
      content: `Mom made a chocolate cake for Dad's birthday. She put it in the fridge.

"Don't touch the cake until the party," Mom told everyone.

When Mom checked later, she saw small finger marks in the frosting!

"Someone touched the cake," Mom said. "Who was it?"

There was chocolate on the kitchen counter. A chair was moved near the fridge. A small footprint was on the floor.

Who touched Dad's birthday cake? Can you solve the mystery?`,
      suspects: [
        {
          name: "Little Sister",
          description: "Loves chocolate cake",
          emoji: "👧"
        },
        {
          name: "Big Brother",
          description: "Was in the kitchen earlier",
          emoji: "👦"
        },
        {
          name: "Family Cat",
          description: "Can jump on counters",
          emoji: "🐱"
        },
        {
          name: "Neighbor Kid",
          description: "Visited this morning",
          emoji: "👦"
        },
        {
          name: "Grandma",
          description: "Helping prepare for the party",
          emoji: "👵"
        },
        {
          name: "The Babysitter",
          description: "Was watching the kids earlier",
          emoji: "👩"
        },
        {
          name: "Dad",
          description: "Whose birthday it is",
          emoji: "👨"
        },
        {
          name: "Mom",
          description: "Who made the cake",
          emoji: "👩"
        }
      ],
      level: 'beginner',
      ageRange: [5, 7]
    },
    {
      id: '26',
      title: 'The Park Swing Problem',
      content: `The park had a new swing. Everyone wanted to use it.

One morning, the swing was broken. The chain was on the ground.

"Who broke the swing?" asked the park keeper.

It had rained last night. There were footprints in the mud near the swing. Someone found a red cap on the ground.

The park keeper saw some children playing near the swing yesterday. An old tree branch was also on the ground.

Why is the swing broken? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Wind",
          description: "It was very windy last night",
          emoji: "💨"
        },
        {
          name: "Tommy",
          description: "Wears a red cap and likes the swing",
          emoji: "👦"
        },
        {
          name: "Lisa",
          description: "Was at the park yesterday with friends",
          emoji: "👧"
        },
        {
          name: "The Big Tree",
          description: "Has branches over the swing",
          emoji: "🌳"
        },
        {
          name: "The Old Chain",
          description: "Was rusting and weak",
          emoji: "⛓️"
        },
        {
          name: "The Park Keeper",
          description: "Responsible for park equipment",
          emoji: "👨"
        },
        {
          name: "The Rain",
          description: "Heavy rain fell last night",
          emoji: "🌧️"
        },
        {
          name: "Neighborhood Dog",
          description: "Likes to jump and play on things",
          emoji: "🐕"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '27',
      title: 'The Strange Sound in the Attic',
      content: `Every night, Emma heard strange sounds from the attic. It sounded like tapping.

"What's that noise?" Emma asked her parents.

"Let's check tomorrow," Dad said.

In the morning, they went to the attic. They found small nuts on the floor. There were tiny footprints in the dust.

Some boxes were moved. A small hole was in the wall. The window was slightly open.

What's making the strange sounds in the attic? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Squirrel",
          description: "Lives in trees near the house",
          emoji: "🐿️"
        },
        {
          name: "The Wind",
          description: "Blows through the attic window",
          emoji: "💨"
        },
        {
          name: "Old Pipes",
          description: "Make noises when water runs",
          emoji: "🚿"
        },
        {
          name: "The Mouse",
          description: "Small animal that likes nuts",
          emoji: "🐭"
        },
        {
          name: "The Cat",
          description: "Sometimes goes to the attic",
          emoji: "🐱"
        },
        {
          name: "The Rain",
          description: "Falls on the roof at night",
          emoji: "🌧️"
        },
        {
          name: "Tree Branches",
          description: "Touch the roof when windy",
          emoji: "🌿"
        },
        {
          name: "The Neighbor",
          description: "Working late on house repairs",
          emoji: "👨"
        }
      ],
      level: 'beginner',
      ageRange: [6, 8]
    },
    {
      id: '28',
      title: 'The Classroom Plant Mystery',
      content: `The classroom had a big plant that everyone helped care for. One Monday, half the leaves were gone!

"What happened to our plant?" asked the teacher.

The plant was fine on Friday. The classroom was locked over the weekend. Only teachers had keys.

Some soil was on the floor. There were small holes in the remaining leaves. A student noticed a tiny green bug on one leaf.

What happened to the classroom plant? Can you solve the mystery?`,
      suspects: [
        {
          name: "Cleaning Staff",
          description: "Cleaned the classroom over weekend",
          emoji: "🧹"
        },
        {
          name: "The Caterpillar",
          description: "Small insect that eats leaves",
          emoji: "🐛"
        },
        {
          name: "Science Teacher",
          description: "Borrowed plants for an experiment",
          emoji: "👨‍🔬"
        },
        {
          name: "Another Class",
          description: "Needed plants for a project",
          emoji: "👩‍🎓"
        },
        {
          name: "The Sunlight",
          description: "Too much sun burns leaves",
          emoji: "☀️"
        },
        {
          name: "The Janitor",
          description: "Waters the plants on weekends",
          emoji: "👨"
        },
        {
          name: "The Heater",
          description: "Gets very hot near the plant",
          emoji: "🔥"
        },
        {
          name: "Class Pet Turtle",
          description: "Sometimes escapes its tank",
          emoji: "🐢"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '29',
      title: 'The Substitute Teacher Problem',
      content: `Mrs. Lee, the substitute teacher, couldn't find the math worksheets she was supposed to give the class.

"I put them on the teacher's desk this morning," she said.

The class waited while Mrs. Lee looked everywhere. The worksheets weren't in her bag or on any shelf.

A student noticed the classroom recycling bin was very full. The classroom door had been open during recess. The principal visited earlier.

Where are the math worksheets? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Regular Teacher",
          description: "Left instructions for the substitute",
          emoji: "👩‍🏫"
        },
        {
          name: "Class Helper",
          description: "Organizes papers and materials",
          emoji: "👧"
        },
        {
          name: "The Principal",
          description: "Visited the classroom earlier",
          emoji: "👨‍💼"
        },
        {
          name: "The Janitor",
          description: "Emptied trash during recess",
          emoji: "🧹"
        },
        {
          name: "Art Teacher",
          description: "Needed paper for a project",
          emoji: "👩‍🎨"
        },
        {
          name: "Office Assistant",
          description: "Delivers messages to classrooms",
          emoji: "👩‍💼"
        },
        {
          name: "The Wind",
          description: "Blew through the open window",
          emoji: "💨"
        },
        {
          name: "Mrs. Lee",
          description: "The substitute teacher herself",
          emoji: "👩‍🏫"
        }
      ],
      level: 'intermediate',
      ageRange: [8, 10]
    },
    {
      id: '30',
      title: 'The Cafeteria Mix-up',
      content: `The school cafeteria was serving pizza on Monday. But when lunchtime came, they served spaghetti instead!

"Where's the pizza?" asked the students.

The lunch menu clearly said "pizza." The cafeteria staff looked confused.

The delivery truck came early that morning. The head cook was absent today. The substitute cook found a different menu in the kitchen.

Someone changed the lunch menu! Who did it and why? Can you solve the mystery?`,
      suspects: [
        {
          name: "Head Cook",
          description: "Absent today but planned the menu",
          emoji: "👨‍🍳"
        },
        {
          name: "Substitute Cook",
          description: "Working her first day",
          emoji: "👩‍🍳"
        },
        {
          name: "Delivery Person",
          description: "Brought food supplies this morning",
          emoji: "🚚"
        },
        {
          name: "School Principal",
          description: "Makes last-minute decisions",
          emoji: "👩‍💼"
        },
        {
          name: "Cafeteria Manager",
          description: "Oversees all meal planning",
          emoji: "👨‍💼"
        },
        {
          name: "Kitchen Helper",
          description: "Assists with meal preparation",
          emoji: "👨‍🍳"
        },
        {
          name: "Office Secretary",
          description: "Prints the lunch menus",
          emoji: "👩‍💼"
        },
        {
          name: "Student Council",
          description: "Requested menu changes last week",
          emoji: "👨‍👩‍👧‍👦"
        }
      ],
      level: 'intermediate',
      ageRange: [9, 11]
    },
    {
      id: '31',
      title: 'The School Newspaper Error',
      content: `The school newspaper had an embarrassing error. The front page story about the principal was full of made-up facts!

"I didn't approve this story," said the newspaper advisor.

The newspaper was printed yesterday. The final draft was checked by the editor. The computer file was different from the printed version.

Only the newspaper club had access to the computers. A new student joined the club last week. The editor stayed late to finish the layout.

Who changed the newspaper story? Can you solve the mystery?`,
      suspects: [
        {
          name: "Newspaper Editor",
          description: "Responsible for final approval",
          emoji: "👧"
        },
        {
          name: "New Club Member",
          description: "Recently joined the newspaper team",
          emoji: "👦"
        },
        {
          name: "Computer Teacher",
          description: "Helps with technical issues",
          emoji: "👨‍🏫"
        },
        {
          name: "Rival School Prankster",
          description: "Known for practical jokes",
          emoji: "👦"
        },
        {
          name: "Printing Company",
          description: "Produces the physical newspapers",
          emoji: "🖨️"
        },
        {
          name: "School Secretary",
          description: "Handles communications",
          emoji: "👩‍💼"
        },
        {
          name: "Computer Virus",
          description: "Could have altered files",
          emoji: "🦠"
        },
        {
          name: "Assistant Editor",
          description: "Helps with final edits",
          emoji: "👨"
        }
      ],
      level: 'intermediate',
      ageRange: [11, 13]
    },
    {
      id: '32',
      title: 'The Strange Email',
      content: `Jason received a strange email. It invited him to a secret meeting at the old playground after school.

"I don't know who sent this," Jason told his friend.

The email came from an unknown address. It mentioned Jason's science project, which only classmates would know about. The message had a photo of the playground.

Jason's computer had been left unattended in the library. The email was sent during lunch period. A classmate mentioned seeing someone using Jason's computer.

Who sent the mysterious email? Can you solve the mystery?`,
      suspects: [
        {
          name: "Jason's Best Friend",
          description: "Planning a surprise party",
          emoji: "👦"
        },
        {
          name: "Science Club President",
          description: "Organizing a special meeting",
          emoji: "👧"
        },
        {
          name: "Computer Class Rival",
          description: "Competes with Jason on projects",
          emoji: "👦"
        },
        {
          name: "New Student",
          description: "Trying to make friends",
          emoji: "👧"
        },
        {
          name: "School Librarian",
          description: "Monitors computer use",
          emoji: "👩‍🏫"
        },
        {
          name: "School Bully",
          description: "Known for playing pranks",
          emoji: "👦"
        },
        {
          name: "Computer Teacher",
          description: "Testing computer security",
          emoji: "👨‍🏫"
        },
        {
          name: "Secret Admirer",
          description: "Wants to meet Jason privately",
          emoji: "👧"
        }
      ],
      level: 'intermediate',
      ageRange: [11, 13]
    },
    {
      id: '33',
      title: 'The Math Competition Mystery',
      content: `During the city math competition, someone leaked the questions before the event. All schools had to use new questions at the last minute.

"This has never happened before," said the competition organizer.

The questions were stored on a secure computer. Only three people had the password. A printer error created extra copies the day before.

A janitor reported seeing someone in the office late at night. The competing schools had practice sessions with very similar questions. A student claimed they saw the questions online.

Who leaked the math competition questions? Can you solve the mystery?`,
      suspects: [
        {
          name: "Competition Organizer",
          description: "Has access to all materials",
          emoji: "👨‍💼"
        },
        {
          name: "Math Teacher",
          description: "Helped create the questions",
          emoji: "👩‍🏫"
        },
        {
          name: "IT Specialist",
          description: "Manages the secure computer",
          emoji: "👨‍💻"
        },
        {
          name: "Rival School Coach",
          description: "Wants his team to win",
          emoji: "👨‍🏫"
        },
        {
          name: "Office Secretary",
          description: "Handles printing and copying",
          emoji: "👩‍💼"
        },
        {
          name: "Former Winner",
          description: "Tutoring students for the competition",
          emoji: "👧"
        },
        {
          name: "Janitor",
          description: "Has keys to all rooms",
          emoji: "🧹"
        },
        {
          name: "School Principal",
          description: "Under pressure for school to perform well",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [11, 13]
    },
    {
      id: '34',
      title: 'The Drama Club Sabotage',
      content: `The night before the school play, all the costumes were found damaged. Some had paint spilled on them, others were torn.

"We can't perform without costumes," the drama teacher said.

The costumes were locked in the drama club room. The key was kept in the teacher's office. The damage happened after the final rehearsal.

Only drama club members knew where everything was stored. Another club was scheduled to use the room for a different event. The rival school was performing the same play next week.

Who sabotaged the costumes? Can you solve the mystery?`,
      suspects: [
        {
          name: "Understudy Actor",
          description: "Didn't get the main role",
          emoji: "👦"
        },
        {
          name: "Art Club President",
          description: "Needed the space for an exhibition",
          emoji: "👩‍🎨"
        },
        {
          name: "School Janitor",
          description: "Cleaning the rooms after hours",
          emoji: "🧹"
        },
        {
          name: "Drama Teacher",
          description: "Responsible for all equipment",
          emoji: "👨‍🏫"
        },
        {
          name: "Former Lead Actor",
          description: "Replaced in this year's play",
          emoji: "👧"
        },
        {
          name: "Rival School Student",
          description: "Competing in drama competitions",
          emoji: "👦"
        },
        {
          name: "Stage Manager",
          description: "In charge of all props and costumes",
          emoji: "👩"
        },
        {
          name: "Water Pipe",
          description: "Old plumbing system in the building",
          emoji: "🚿"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '35',
      title: 'The Yearbook Photo Switch',
      content: `When the school yearbooks were distributed, students discovered that some photos had been switched. The wrong names were under some pictures.

"This isn't what we submitted," said the yearbook editor.

The final digital files were sent to the printing company last month. The yearbook committee reviewed everything before submission. The printed books arrived yesterday.

The computer used for yearbook editing had been left unattended several times. A backup file on the school server was different from the printed version. The printing company reported receiving a last-minute update email.

Who switched the yearbook photos? Can you solve the mystery?`,
      suspects: [
        {
          name: "Yearbook Committee Member",
          description: "Responsible for final edits",
          emoji: "👧"
        },
        {
          name: "School Photographer",
          description: "Took and organized all photos",
          emoji: "📸"
        },
        {
          name: "Computer Lab Assistant",
          description: "Helps with technical issues",
          emoji: "👨‍💻"
        },
        {
          name: "Printing Company Employee",
          description: "Handles all school printing jobs",
          emoji: "👨‍🔧"
        },
        {
          name: "Student Prankster",
          description: "Known for elaborate jokes",
          emoji: "👦"
        },
        {
          name: "New IT Teacher",
          description: "Recently gained system access",
          emoji: "👩‍🏫"
        },
        {
          name: "Student Council President",
          description: "Has access to many school resources",
          emoji: "👨"
        },
        {
          name: "Computer Hacker",
          description: "Unknown person with technical skills",
          emoji: "🕵️"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '36',
      title: 'The Haunted Theater Mystery',
      content: `Strange events kept happening at the old city theater. Lights turned on and off by themselves, props moved overnight, and actors heard whispering when no one was there.

"The theater is haunted," some people claimed.

The theater was undergoing renovations. The building was over 100 years old. Security cameras showed no intruders at night.

A new electrical system was recently installed. A local history buff mentioned that the theater had a hidden basement. One actor discovered an old script with notes about secret passages.

What's causing the strange events at the theater? Can you solve the mystery?`,
      suspects: [
        {
          name: "Former Theater Owner",
          description: "Still has keys to the building",
          emoji: "👴"
        },
        {
          name: "Renovation Worker",
          description: "Working late hours on repairs",
          emoji: "👨‍🔧"
        },
        {
          name: "Theater Director",
          description: "Wants publicity for upcoming show",
          emoji: "👩‍💼"
        },
        {
          name: "Local Teenagers",
          description: "Looking for excitement",
          emoji: "👫"
        },
        {
          name: "Old Electrical System",
          description: "Malfunctioning during renovations",
          emoji: "💡"
        },
        {
          name: "Homeless Person",
          description: "Seeking shelter in the building",
          emoji: "🧔"
        },
        {
          name: "Theater Ghost",
          description: "Spirit of famous actor from the past",
          emoji: "👻"
        },
        {
          name: "Rival Theater Owner",
          description: "Trying to sabotage the competition",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '37',
      title: 'The Closed Restaurant Case',
      content: `A popular local restaurant suddenly closed. A sign on the door said "Closed for renovations" but no work was happening.

"Something doesn't seem right," said a regular customer.

The restaurant was always busy and profitable. The owner's car hadn't been seen for days. Employees arrived for work to find the doors locked.

A health inspector visited last week. A bank representative was seen entering the restaurant after it closed. A real estate sign briefly appeared in the window then disappeared.

What happened to the restaurant? Can you solve the mystery?`,
      suspects: [
        {
          name: "Restaurant Owner",
          description: "Suddenly unavailable",
          emoji: "👨‍🍳"
        },
        {
          name: "Health Department",
          description: "Conducts regular inspections",
          emoji: "👩‍⚕️"
        },
        {
          name: "Bank Manager",
          description: "Handles business finances",
          emoji: "👨‍💼"
        },
        {
          name: "Competing Restaurant Owner",
          description: "Opening new place nearby",
          emoji: "👩‍🍳"
        },
        {
          name: "Building Owner",
          description: "Manages the property",
          emoji: "👴"
        },
        {
          name: "Head Chef",
          description: "Recently had disagreement with owner",
          emoji: "👨‍🍳"
        },
        {
          name: "City Inspector",
          description: "Enforces building codes",
          emoji: "👷"
        },
        {
          name: "Tax Authorities",
          description: "Investigate business compliance",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '38',
      title: 'The Anonymous Blog Leaks',
      content: `An anonymous blog began publishing private information about city officials. The leaks included details of closed meetings and personal conversations.

"This information could only come from someone on the inside," the mayor said.

The blog used sophisticated encryption. The information was always posted late at night. Some of the details were slightly wrong, suggesting the source wasn't always present.

A city employee was recently fired. A journalist attended all public meetings. A new IT system was installed at city hall last month. The building security log showed after-hours access by several officials.

Who is behind the anonymous blog? Can you solve the mystery?`,
      suspects: [
        {
          name: "Fired City Employee",
          description: "Recently let go under controversial circumstances",
          emoji: "👨‍💼"
        },
        {
          name: "Local Journalist",
          description: "Investigating city corruption rumors",
          emoji: "👩‍💻"
        },
        {
          name: "IT Consultant",
          description: "Installed new computer systems",
          emoji: "👨‍💻"
        },
        {
          name: "City Council Member",
          description: "Opposed to current administration",
          emoji: "👩‍💼"
        },
        {
          name: "Mayor's Assistant",
          description: "Has access to sensitive information",
          emoji: "👨‍💼"
        },
        {
          name: "Building Security Guard",
          description: "Works night shifts at city hall",
          emoji: "👮"
        },
        {
          name: "Political Activist Group",
          description: "Campaigns for government transparency",
          emoji: "👥"
        },
        {
          name: "Cleaning Staff",
          description: "Works after hours in all offices",
          emoji: "🧹"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '39',
      title: 'The Forged Painting Scandal',
      content: `An art collector discovered that a valuable painting he purchased from a prestigious gallery was actually a forgery. The original artist had died 30 years ago.

"This forgery is exceptionally well-done," said the art authenticator.

The painting came with proper certification papers. The gallery had a stellar reputation. The piece was acquired from a private collection.

The artist's former assistant was still alive. A new authentication technology recently revealed other forgeries in the market. The gallery owner had financial problems. A similar painting was sold to another collector last year.

Who is responsible for the forged painting? Can you solve the mystery?`,
      suspects: [
        {
          name: "Gallery Owner",
          description: "Sells high-value artwork",
          emoji: "👨‍💼"
        },
        {
          name: "Former Assistant",
          description: "Worked closely with the original artist",
          emoji: "👩‍🎨"
        },
        {
          name: "Art Authenticator",
          description: "Certified the painting as genuine",
          emoji: "👨‍🔬"
        },
        {
          name: "Previous Owner",
          description: "Sold the painting to the gallery",
          emoji: "👴"
        },
        {
          name: "Talented Forger",
          description: "Known in underground art circles",
          emoji: "👨‍🎨"
        },
        {
          name: "Rival Collector",
          description: "Competing for rare artworks",
          emoji: "👩‍💼"
        },
        {
          name: "Family Member",
          description: "Related to the original artist",
          emoji: "👩"
        },
        {
          name: "Insurance Investigator",
          description: "Handling similar cases recently",
          emoji: "🕵️"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '40',
      title: 'The Neighborhood Power Outage',
      content: `In a small neighborhood, the power went out every night at exactly 10:30 PM for exactly two minutes. It had been happening for a week.

"The power company says there's no problem on their end," a resident reported.

The outages affected just three streets. Electricians couldn't find any technical issues. The weather had been clear with no storms.

A new family moved in recently. A neighbor was building a workshop in his garage. The local science club was conducting experiments nearby. An old substation was located at the edge of the neighborhood.

What's causing the strange power outages? Can you solve the mystery?`,
      suspects: [
        {
          name: "New Family",
          description: "Recently moved to the neighborhood",
          emoji: "👨‍👩‍👧"
        },
        {
          name: "Amateur Electrician",
          description: "Working on home projects",
          emoji: "👨‍🔧"
        },
        {
          name: "Science Club Students",
          description: "Conducting electricity experiments",
          emoji: "👩‍🔬"
        },
        {
          name: "Power Company Worker",
          description: "Responsible for local grid",
          emoji: "👷"
        },
        {
          name: "Smart Home System",
          description: "New technology installed in several homes",
          emoji: "🏠"
        },
        {
          name: "Construction Project",
          description: "Building work at the edge of neighborhood",
          emoji: "🚧"
        },
        {
          name: "Wildlife",
          description: "Animals interfering with equipment",
          emoji: "🦝"
        },
        {
          name: "Old Substation",
          description: "Aging electrical equipment",
          emoji: "⚡"
        }
      ],
      level: 'intermediate',
      ageRange: [9, 11]
    },
    {
      id: '41',
      title: 'The Missing Shipment Case',
      content: `A truck carrying electronic parts disappeared between the warehouse and the factory. The driver reported the truck was hijacked, but investigators were suspicious.

"Something doesn't add up in the driver's story," said the detective.

The truck's GPS tracker stopped working during the journey. The driver was found by the side of the road uninjured. The cargo was valued at over $500,000.

The company had recently laid off workers. A competing business had tried to obtain the same parts. The driver had money problems. There were no witnesses to the alleged hijacking.

What happened to the missing shipment? Can you solve the mystery?`,
      suspects: [
        {
          name: "The Driver",
          description: "Reported the hijacking",
          emoji: "👨‍💼"
        },
        {
          name: "Laid-off Workers",
          description: "Recently lost their jobs",
          emoji: "👥"
        },
        {
          name: "Competing Business",
          description: "Wanted the same electronic parts",
          emoji: "🏢"
        },
        {
          name: "Warehouse Manager",
          description: "Oversaw the shipment loading",
          emoji: "👨‍💼"
        },
        {
          name: "Organized Crime Group",
          description: "Known for cargo theft in the area",
          emoji: "🕵️"
        },
        {
          name: "Insurance Investigator",
          description: "Handling the claim",
          emoji: "👩‍💼"
        },
        {
          name: "GPS Technician",
          description: "Installed the tracking system",
          emoji: "👨‍🔧"
        },
        {
          name: "Factory Owner",
          description: "Waiting for the shipment",
          emoji: "👨‍💼"
        }
      ],
      level: 'advanced',
      ageRange: [18, 99]
    },
    {
      id: '42',
      title: 'The Library Book Code',
      content: `A university student noticed strange markings in a series of old books in the library. When put together, they seemed to form a code or message.

"These markings aren't random," the student told the librarian.

The books were all from the history section. Some markings were made recently, others decades ago. The pattern appeared in books rarely checked out.

A famous professor did research in that section years ago. A secret society was rumored to exist at the university. Construction workers recently found an old sealed room in the library basement. The library was renovating the archive system.

What's the secret behind the library book code? Can you solve the mystery?`,
      suspects: [
        {
          name: "Former Professor",
          description: "Conducted secret research",
          emoji: "👴"
        },
        {
          name: "Secret Society",
          description: "Leaves coded messages for members",
          emoji: "👥"
        },
        {
          name: "Library Staff",
          description: "Has access to all books",
          emoji: "👩‍💼"
        },
        {
          name: "History Department",
          description: "Using books for class project",
          emoji: "👨‍🏫"
        },
        {
          name: "University Archivist",
          description: "Preserving historical documents",
          emoji: "👨‍💼"
        },
        {
          name: "Treasure Hunter",
          description: "Searching for rumored valuable artifact",
          emoji: "👨"
        },
        {
          name: "Literary Prankster",
          description: "Creating elaborate puzzle",
          emoji: "👩"
        },
        {
          name: "Book Conservator",
          description: "Restoring damaged books",
          emoji: "👩‍🔬"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    },
    {
      id: '43',
      title: 'The Disappearing Athlete',
      content: `A star college athlete vanished the night before a championship game. He left his dorm room but never arrived at the team meeting.

"He was in perfect form and excited about the game," said his coach.

The athlete's room was neat, nothing missing except his phone. His car was still in the parking lot. Campus security cameras showed him leaving the dorm but not exiting campus.

A rival team had tried to recruit him last year. He had recently argued with team management about his future. His girlfriend reported receiving a strange text message that night. His academic standing was under review.

Where is the missing athlete? Can you solve the mystery?`,
      suspects: [
        {
          name: "Team Coach",
          description: "Had disagreement with the athlete",
          emoji: "👨‍🏫"
        },
        {
          name: "Rival Team Manager",
          description: "Wanted the athlete for their team",
          emoji: "👨‍💼"
        },
        {
          name: "Girlfriend",
          description: "Received strange message that night",
          emoji: "👩"
        },
        {
          name: "Team Member",
          description: "Competing for same position",
          emoji: "👦"
        },
        {
          name: "Sports Agent",
          description: "Negotiating professional contract",
          emoji: "👨‍💼"
        },
        {
          name: "Academic Advisor",
          description: "Reviewing athlete's eligibility",
          emoji: "👩‍🏫"
        },
        {
          name: "Campus Security",
          description: "Monitors all campus access",
          emoji: "👮"
        },
        {
          name: "The Athlete Himself",
          description: "May have arranged his own disappearance",
          emoji: "🏃"
        }
      ],
      level: 'advanced',
      ageRange: [13, 16]
    }
  ];

  constructor() { }

  getStory(id: string): DetectiveStory | undefined {
    return this.stories.find(story => story.id === id);
  }

  getRandomStory(): DetectiveStory {
    const randomIndex = Math.floor(Math.random() * this.stories.length);
    return this.stories[randomIndex];
  }

  // Helper method to shuffle an array
  private shuffleArray<T>(array: T[]): T[] {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  // Add this new method to get 3 random suspects from a story
  getRandomSuspects(storyId: string): Suspect[] {
    const story = this.getStory(storyId);
    if (!story) {
      return [];
    }
    return this.shuffleArray(story.suspects).slice(0, 3);
  }

  // Get all available detective stories
  getAllStories(): DetectiveStory[] {
    return this.stories;
  }
}
