<h2 mat-dialog-title>Wybierz program nauczania</h2>
<div mat-dialog-content>
  <div class="mb-3">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Szukaj programu nauczania</mat-label>
      <input
        matInput
        [(ngModel)]="searchTerm"
        (keyup)="applyFilter()"
        placeholder="Wpisz nazwę programu..."
      />
      <button
        *ngIf="searchTerm"
        matSuffix
        mat-icon-button
        aria-label="Wyczyść"
        (click)="searchTerm = ''; applyFilter()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="isLoading" class="text-center py-3">
    <mat-spinner style="margin: 0 auto"></mat-spinner>
    <p class="mt-3">Ładowanie programów nauczania...</p>
  </div>

  <div
    *ngIf="!isLoading && filteredCurriculumList.length === 0"
    class="text-center py-3"
  >
    <mat-icon style="font-size: 48px; width: 48px; height: 48px; color: #ccc"
      >school</mat-icon
    >
    <p class="mt-3">Brak programów nauczania</p>
  </div>

  <div
    *ngIf="!isLoading && filteredCurriculumList.length > 0"
    class="curriculum-list"
  >
    <mat-selection-list [multiple]="false">
      <mat-list-option
        *ngFor="let curriculum of filteredCurriculumList"
        [value]="curriculum"
        [selected]="selectedCurriculum?._id === curriculum._id"
        (click)="selectCurriculum(curriculum)"
      >
        <div class="d-flex align-items-center">
          <div>
            <h3 matLine>{{ curriculum.data.name }}</h3>
            <p matLine>{{ curriculum.data.units?.length || 0 }} jednostek</p>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Anuluj</button>
  <button
    mat-raised-button
    color="primary"
    (click)="onConfirm()"
    [disabled]="!selectedCurriculum"
  >
    Wybierz
  </button>
</div>
