import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SetMergePreviewComponent } from 'src/app/database/sets/set-merge-preview/set-merge-preview.component';
import { LessonActivitiesAddEditComponent } from 'src/app/profile/teacher/teacher-activities/lesson-activities-add-edit/lesson-activities-add-edit.component';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { DigitalOceanService } from 'src/app/shared/services/digitalocean.service';
import { CoursePlanService } from 'src/app/courses/course-add-edit/course-plan/course-plan.service';
import { CoursesPlanComponent } from '../../courses-plan/courses-plan.component';
import { SetsComponent } from 'src/app/database/sets/sets.component';
import { TeacherActivitiesComponent } from 'src/app/profile/teacher/teacher-activities/teacher-activities.component';
import { Pdf2ExercisesService } from 'src/app/pdf2/pdf2-exercises.service';
import { PdfSelectionDialogComponent } from '../pdf-selection-dialog/pdf-selection-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurriculumAddEditComponent } from '../curriculum-add-edit/curriculum-add-edit.component';
import { CurriculumViewComponent } from '../curriculum-add-edit/curriculum-view/curriculum-view.component';
import { CurriculumSelectionDialogComponent } from '../curriculum-selection-dialog/curriculum-selection-dialog.component';
import { CurriculumService } from '../curriculum.service';

@Component({
    selector: 'app-courses-resources-add-edit',
    templateUrl: './courses-resources-add-edit.component.html',
    styleUrls: ['./courses-resources-add-edit.component.css']
})
export class CoursesResourcesAddEditComponent implements OnInit {
    resource: any = {
        name: '',
        sets: [],
        activities: [],
        homework: [],
        fileGroups: [],
        curriculumRefs: [],
        ageFrom: 0,
        ageTo: 0,
        difficultyLevel: '',
        difficultyLevelCEFR: '',
        isSchoolCurriculum: false,
        bookName: '',
        competence: '',
        description: ''
    };

    competenceOptions = ['Konwersacje', 'Egzamin', 'Poprawa w szkole', 'Nauka poza szkołą'];
    difficultyLevelOptions = ['Z zaległościami w szkole', 'Szkolny', 'Początkujący', 'Średniozaawansowany', 'Zaawansowany',];
    difficultyLevelCEFROptions = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    ageOptions = Array.from({ length: 85 }, (_, i) => i + 1); // 1-85 lat

    constructor(
        public dialogRef: MatDialogRef<CoursesResourcesAddEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
        private helpers: HelpersService,
        private digitalOceanService: DigitalOceanService,
        private coursePlanService: CoursePlanService,
        private pdf2ExercisesService: Pdf2ExercisesService,
        private snackBar: MatSnackBar,
        private curriculumService: CurriculumService
    ) { }

    ngOnInit(): void {
        if (this.data && this.data.resource) {
            this.resource = this.helpers.clone(this.data.resource);

            // Konwersja ze starego formatu wieku (string) na nowy format (dwie liczby)
            if (this.resource.ages && typeof this.resource.ages === 'string') {
                const ageRange = this.resource.ages.split('-');
                if (ageRange.length === 2) {
                    this.resource.ageFrom = parseInt(ageRange[0]) || 0;
                    this.resource.ageTo = parseInt(ageRange[1]) || 0;
                }
            }

            // Jeśli brak ageFrom i ageTo, ustaw wartości domyślne
            if (!this.resource.ageFrom) this.resource.ageFrom = 0;
            if (!this.resource.ageTo) this.resource.ageTo = 0;

            // Sprawdź czy stary format difficultyLevel to format CEFR
            if (this.resource.difficultyLevel && this.difficultyLevelCEFROptions.includes(this.resource.difficultyLevel)) {
                this.resource.difficultyLevelCEFR = this.resource.difficultyLevel;
                this.resource.difficultyLevel = '';
            }

            // Konwersja ze starego formatu plików do nowego formatu grup plików, jeśli istnieją stare dane
            if (this.resource.files && this.resource.files.length > 0 && (!this.resource.fileGroups || this.resource.fileGroups.length === 0)) {
                this.resource.fileGroups = [{
                    name: 'Pliki zaimportowane',
                    files: this.resource.files.map(file => ({
                        name: file.name,
                        description: '',
                        url: file.url,
                        editMode: false
                    })),
                    editMode: false
                }];
                // Możemy usunąć stary format po konwersji
                delete this.resource.files;
            }

            // Upewnij się, że fileGroups istnieje
            if (!this.resource.fileGroups) {
                this.resource.fileGroups = [];
            }

            // Upewnij się, że curriculum istnieje i jest tablicą
            if (!this.resource.curriculum) {
                this.resource.curriculum = [];
            } else if (!Array.isArray(this.resource.curriculum)) {
                console.warn('Curriculum is not an array, converting to array');
                if (typeof this.resource.curriculum === 'object' && this.resource.curriculum !== null) {
                    this.resource.curriculum = [this.resource.curriculum];
                } else {
                    this.resource.curriculum = [];
                }
            }

            // Log curriculum data for debugging
            console.log('Curriculum after initialization:',
                Array.isArray(this.resource.curriculum) ?
                    `Array with ${this.resource.curriculum.length} items` :
                    typeof this.resource.curriculum);

            // Convert old curriculum array to curriculum references if needed
            if (this.resource.curriculum && Array.isArray(this.resource.curriculum) &&
                this.resource.curriculum.length > 0 && !this.resource.curriculumRefs) {
                this.resource.curriculumRefs = [];
                // Don't migrate old embedded curricula - they will be lost in this transition
                console.warn('Stare programy nauczania nie będą automatycznie migrowane. Należy je dodać ręcznie z nowego panelu programów nauczania.');
                this.snackBar.open('Stare programy nauczania będą widoczne do momentu zapisania, ale nie będą migrowane automatycznie. Dodaj je z panelu programów nauczania.', 'OK', { duration: 5000 });
            }

            // Initialize curriculum refs if not present
            if (!this.resource.curriculumRefs) {
                this.resource.curriculumRefs = [];
            }
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    previewSet(set) {
        const dialogRef = this.dialog.open(SetMergePreviewComponent, {
            width: '100%',
            data: set,
        });
    }

    openAddActivityPanel(activity) {
        let _class = 'empty-wide';
        if (window.innerWidth < 800) {
            _class = 'empty';
        }
        const ref = this.bottomSheet.open(LessonActivitiesAddEditComponent, {
            data: {
                id: activity.id,
            },
            panelClass: _class,
        });
    }

    openShowHomeworkPanel(homework) {
        let _class = 'empty-wide';
        if (window.innerWidth < 800) {
            _class = 'empty';
        }
        const ref = this.bottomSheet.open(LessonActivitiesAddEditComponent, {
            data: {
                tag: homework.displayName,
            },
            panelClass: _class,
        });
    }

    openAddSetDialog() {
        let _class = "end-wide";
        if (window.innerWidth < 800) {
            _class = "end";
        }
        const ref = this.bottomSheet.open(SetsComponent, {
            data: {
                group: { name: 'Zasoby kursu' },
                source: { type: "lessonPlanning", forStudents: false, removePossible: true },
                addMode: true,
                selectSets: true
            },
            panelClass: _class,
        });

        ref.afterDismissed().subscribe((result) => {
            if (result) {
                result.forEach((_item) => {
                    if (!this.resource.sets.some(set => set.id === _item.set.id)) {
                        this.resource.sets.push({
                            id: _item.set.id,
                            name: _item.set.name,
                            items: _item.set.items.length,
                            type: _item.set.children ? 'children' : _item.set.type
                        });
                    }
                });
            }
        });
    }

    openAddActivityDialog() {
        const dialogRef = this.dialog.open(TeacherActivitiesComponent, {
            width: "100vw",
            height: "100vh",
            maxWidth: "100vw",
            panelClass: "full-screen-dialog",
            data: {
                addToLessonMode: true,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                result.forEach((activity) => {
                    if (!this.resource.activities.some(_activity => activity.id === _activity.id)) {
                        this.resource.activities.push({ name: activity.name, id: activity.id });
                    }
                });
            }
        });
    }

    openAddHomeworkDialog() {
        const dialogRef = this.dialog.open(PdfSelectionDialogComponent, {
            data: {
                pdf2ExercisesService: this.pdf2ExercisesService,
                selectHomework: true
            },
            width: '95vw',
            height: '90vh',
            maxWidth: '95vw',
            maxHeight: '90vh',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                result.forEach((item) => {
                    if (!this.resource.homework.some(hw => hw.id === item.id)) {
                        this.resource.homework.push(item);
                    }
                });
            }
        });
    }

    removeSet(set) {
        this.resource.sets = this.helpers.removeElementFromArray(this.resource.sets, set);
    }

    removeActivity(activity) {
        this.resource.activities = this.helpers.removeElementFromArray(this.resource.activities, activity);
    }

    removeHomework(homework) {
        this.resource.homework = this.helpers.removeElementFromArray(this.resource.homework, homework);
    }

    removeFile(file, groupIndex) {
        this.resource.fileGroups[groupIndex].files = this.helpers.removeElementFromArray(this.resource.fileGroups[groupIndex].files, file);
    }

    removeAllSets() {
        if (confirm('Czy na pewno chcesz usunąć wszystkie zestawy?')) {
            this.resource.sets = [];
            this.snackBar.open('Wszystkie zestawy zostały usunięte', 'OK', { duration: 2000 });
        }
    }

    removeAllActivities() {
        if (confirm('Czy na pewno chcesz usunąć wszystkie ćwiczenia?')) {
            this.resource.activities = [];
            this.snackBar.open('Wszystkie ćwiczenia zostały usunięte', 'OK', { duration: 2000 });
        }
    }

    removeAllHomework() {
        if (confirm('Czy na pewno chcesz usunąć wszystkie zadania domowe?')) {
            this.resource.homework = [];
            this.snackBar.open('Wszystkie zadania domowe zostały usunięte', 'OK', { duration: 2000 });
        }
    }

    removeFileGroup(groupIndex) {
        if (this.resource.fileGroups && groupIndex > -1 && groupIndex < this.resource.fileGroups.length) {
            if (confirm('Czy na pewno chcesz usunąć tę grupę plików? Wszystkie pliki w tej grupie zostaną również usunięte.')) {
                this.resource.fileGroups.splice(groupIndex, 1);
            }
        }
    }

    addFileGroup() {
        if (!this.resource.fileGroups) {
            this.resource.fileGroups = [];
        }
        this.resource.fileGroups.push({
            name: '',
            files: [],
            editMode: true
        });
    }

    addFileToGroup(groupIndex) {
        if (!this.resource.fileGroups[groupIndex].files) {
            this.resource.fileGroups[groupIndex].files = [];
        }
        this.resource.fileGroups[groupIndex].files.push({
            name: '',
            description: '',
            url: '',
            editMode: true
        });
    }

    toggleFileEditMode(file) {
        file.editMode = !file.editMode;
    }

    toggleGroupEditMode(group) {
        group.editMode = !group.editMode;
    }

    openFileWithFreshUrl(fileUrl: string) {
        // Validate URL format first
        if (!fileUrl) {
            console.error('Invalid URL provided');
            return;
        }

        try {
            // Test if it's a valid URL by creating a URL object
            new URL(fileUrl);

            // Check if this is a DigitalOcean Spaces URL
            if (fileUrl.includes('digitaloceanspaces.com')) {
                // Get a fresh download link for Digital Ocean URLs
                this.digitalOceanService.getFreshDownloadLinkFromUrl(fileUrl)
                    .subscribe(
                        response => {
                            if (response.success) {
                                // Open the fresh URL in a new tab
                                window.open(response.downloadLink, '_blank');
                            } else {
                                console.error('Error getting fresh download link:', response);
                                // Fallback to the original URL if we can't get a fresh one
                                window.open(fileUrl, '_blank');
                            }
                        },
                        error => {
                            console.error('Error getting fresh download link:', error);
                            // Fallback to the original URL if there's an error
                            window.open(fileUrl, '_blank');
                        }
                    );
            } else {
                // If it's not a DigitalOcean URL, just open it directly
                window.open(fileUrl, '_blank');
            }
        } catch (e) {
            // If the URL is invalid, show an error
            console.error('Invalid URL format:', fileUrl);
            alert('Nieprawidłowy format adresu URL pliku.');
        }
    }

    copyItems(type: 'sets' | 'activities' | 'homework') {
        const items = this.resource[type];
        if (items && items.length > 0) {
            const jsonStr = JSON.stringify(items);
            navigator.clipboard.writeText(jsonStr).then(() => {
                this.snackBar.open('Skopiowano do schowka', 'OK', { duration: 2000 });
            }).catch(err => {
                console.error('Błąd podczas kopiowania:', err);
                this.snackBar.open('Błąd podczas kopiowania', 'OK', { duration: 2000 });
            });
        } else {
            this.snackBar.open('Brak elementów do skopiowania', 'OK', { duration: 2000 });
        }
    }

    async pasteItems(type: 'sets' | 'activities' | 'homework') {
        try {
            const text = await navigator.clipboard.readText();
            const items = JSON.parse(text);

            if (!Array.isArray(items)) {
                throw new Error('Nieprawidłowy format danych');
            }

            // Validate items based on type
            const isValid = items.every(item => {
                switch (type) {
                    case 'sets':
                        return item.id && item.name && !this.resource.sets.some(set => set.id === item.id);
                    case 'activities':
                        return item.id && item.name && !this.resource.activities.some(act => act.id === item.id);
                    case 'homework':
                        return (item.id || item.displayName) && !this.resource.homework.some(hw => hw.id === item.id);
                    default:
                        return false;
                }
            });

            if (!isValid) {
                throw new Error('Nieprawidłowy format danych');
            }

            this.resource[type].push(...items);
            this.snackBar.open('Wklejono pomyślnie', 'OK', { duration: 2000 });
        } catch (error) {
            console.error('Błąd podczas wklejania:', error);
            this.snackBar.open('Błąd podczas wklejania. Upewnij się, że skopiowane dane są prawidłowe.', 'OK', { duration: 3000 });
        }
    }

    openAddCurriculumDialog(): void {
        const dialogRef = this.dialog.open(CurriculumSelectionDialogComponent, {
            width: '600px',
            maxWidth: '95vw',
            maxHeight: '80vh',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.id) {
                // Check if this curriculum is already added
                if (!this.resource.curriculumRefs.some(ref => ref.id === result.id)) {
                    this.resource.curriculumRefs.push({
                        id: result.id,
                        name: result.name
                    });
                } else {
                    this.snackBar.open('Ten program nauczania jest już dodany', 'OK', { duration: 3000 });
                }
            }
        });
    }

    editCurriculum(curriculumIndex: number) {
        if (!this.resource.curriculum || !Array.isArray(this.resource.curriculum) || !this.resource.curriculum[curriculumIndex]) {
            console.error('Cannot edit curriculum: curriculum is not properly initialized or index is invalid');
            return;
        }

        const dialogRef = this.dialog.open(CurriculumAddEditComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {
                curriculum: this.helpers.clone(this.resource.curriculum[curriculumIndex])
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.name) {
                // Make a deep clone of the result to avoid reference issues
                this.resource.curriculum[curriculumIndex] = this.helpers.clone(result);

                // Log for debugging

            }
        });
    }

    removeCurriculum(curriculumIndex: number) {
        if (confirm('Czy na pewno chcesz usunąć ten program nauczania?')) {
            this.resource.curriculum.splice(curriculumIndex, 1);
        }
    }

    copyCurriculum() {
        const items = this.resource.curriculum;
        if (items && items.length > 0) {
            const jsonStr = JSON.stringify(items);
            navigator.clipboard.writeText(jsonStr).then(() => {
                this.snackBar.open('Skopiowano do schowka', 'OK', { duration: 2000 });
            }).catch(err => {
                console.error('Błąd podczas kopiowania:', err);
                this.snackBar.open('Błąd podczas kopiowania', 'OK', { duration: 2000 });
            });
        } else {
            this.snackBar.open('Brak programów nauczania do skopiowania', 'OK', { duration: 2000 });
        }
    }

    async pasteCurriculum() {
        try {
            const text = await navigator.clipboard.readText();
            const items = JSON.parse(text);

            if (!Array.isArray(items)) {
                throw new Error('Nieprawidłowy format danych');
            }

            // Validate items
            const isValid = items.every(item => item.name && Array.isArray(item.units));

            if (!isValid) {
                throw new Error('Nieprawidłowy format danych');
            }

            if (!this.resource.curriculum) {
                this.resource.curriculum = [];
            }

            this.resource.curriculum.push(...items);
            this.snackBar.open('Wklejono pomyślnie', 'OK', { duration: 2000 });
        } catch (error) {
            console.error('Błąd podczas wklejania:', error);
            this.snackBar.open('Błąd podczas wklejania. Upewnij się, że skopiowane dane są prawidłowe.', 'OK', { duration: 3000 });
        }
    }

    viewCurriculum(curriculumIndex: number) {
        if (!this.resource.curriculum || !this.resource.curriculum[curriculumIndex]) {
            return;
        }

        const dialogRef = this.dialog.open(CurriculumViewComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {
                curriculum: this.helpers.clone(this.resource.curriculum[curriculumIndex])
            }
        });
    }

    viewReferencedCurriculum(curriculumRef: any): void {
        if (!curriculumRef || !curriculumRef.id) {
            this.snackBar.open('Nie można wyświetlić programu nauczania', 'OK', { duration: 3000 });
            return;
        }

        this.curriculumService.getCurriculumById(curriculumRef.id)
            .then(curriculum => {
                if (curriculum && curriculum.data) {
                    const dialogRef = this.dialog.open(CurriculumViewComponent, {
                        width: '1000px',
                        maxWidth: '95vw',
                        height: '90vh',
                        maxHeight: '95vh',
                        panelClass: 'full-screen-dialog',
                        data: {
                            curriculum: this.helpers.clone(curriculum.data)
                        }
                    });
                } else {
                    this.snackBar.open('Nie znaleziono programu nauczania', 'OK', { duration: 3000 });
                }
            })
            .catch(error => {
                console.error('Błąd podczas pobierania programu nauczania:', error);
                this.snackBar.open('Wystąpił błąd podczas pobierania programu nauczania', 'OK', { duration: 3000 });
            });
    }

    removeCurriculumRef(curriculumRef: any): void {
        this.resource.curriculumRefs = this.helpers.removeElementFromArray(this.resource.curriculumRefs, curriculumRef);
    }
} 