import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TasksService } from 'src/app/shared/services/tasks-service';

@Component({
  selector: 'app-overdue-tasks-dialog',
  templateUrl: './overdue-tasks-dialog.component.html',
  styleUrls: ['./overdue-tasks-dialog.component.scss']
})
export class OverdueTasksDialogComponent implements OnInit {
  futureDays: { dayName: string, date: string }[] = [];

  overdueTasks = []
  currentTask = null
  currentIndex = 0
  @ViewChild('picker') datePicker: MatDatepicker<Date>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OverdueTasksDialogComponent>,
    private tasksService: TasksService
  ) { }

  ngOnInit() {
    this.generateFutureDays();

    this.overdueTasks = this.data.overdueTasks
    this.currentTask = this.overdueTasks[this.currentIndex]

  }
  nextTask() {


    if (this.currentIndex < this.overdueTasks.length - 1) {
      this.currentIndex++

      this.currentTask = this.overdueTasks[this.currentIndex]
    } else {
      this.closeWindow()
    }
  }
  previousTask() {

    if (this.currentIndex > 0) {
      this.currentIndex--
      this.currentTask = this.overdueTasks[this.currentIndex]
    }
  }

  snooze() {
    alert('To okno otworzy się ponownie za 10 minut lub po ponnownym wejściu w zadania')
    this.dialogRef.close('snooze')
  }
  markCurrentTaskAsFinished() {
    this.tasksService.setTasksCompletion(this.currentTask.id, 'done')
  }
  generateFutureDays(): void {
    const daysPL = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
    let currentDate = new Date();

    // Add "today" and "tomorrow"
    this.futureDays.push({ dayName: 'Dzisiaj', date: new Date().toISOString().split('T')[0] });
    currentDate.setDate(currentDate.getDate() + 1);
    this.futureDays.push({ dayName: 'Jutro', date: currentDate.toISOString().split('T')[0] });

    // Add the rest of the days
    for (let i = 0; i < 5; i++) { // Assuming next 5 days
      currentDate.setDate(currentDate.getDate() + 1); // Increment the day
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) { // Exclude weekends
        this.futureDays.push({
          dayName: daysPL[currentDate.getDay()],
          date: currentDate.toISOString().split('T')[0]
        });
      }
    }
  }
  selectCustomDate(event: any) {


    let selectedDate;

    if (event.target && event.target.value) {
      // If the event is from the HTML5 date picker
      selectedDate = new Date(event.target.value);
    } else if (typeof event === 'string') {
      // If the date is passed as a string (e.g., from other buttons)
      selectedDate = new Date(event);
    } else {
      // Handle other cases or show an error
      console.error("Invalid date or event format");
      return;
    }

    // Check if the date is valid
    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date selected");
      return;
    }

    const formattedDate = selectedDate.toISOString().split('T')[0];


    // Continue with your task scheduling logic
    this.tasksService.rescheduleTask(this.currentTask.id, formattedDate);
  }
  closeWindow() {
    if (confirm('Czy na pewno chcesz zamknąć okno?')) {
      this.dialogRef.close()
    }
  }
}
