import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AuthenticationService,
  TokenPayload,
} from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { share } from 'rxjs/internal/operators/share';

@Component({
  selector: 'app-owner-register',
  templateUrl: './owner-register.component.html',
  styleUrls: ['./owner-register.component.scss'],
})
export class OwnerRegisterComponent {
  @Output() changeView = new EventEmitter();
  hide = true;
  url = `https://backend-int.linget.it/api/users/`;

  registerForm = this.fb.group({
    name: [null, Validators.required],
    email: [null, [Validators.required]],
    password: [null, Validators.required],
    role: ['student', Validators.required],
  });

  hasUnitNumber = false;

  credentials: TokenPayload = {
    email: '',
    name: '',
    password: '',
    role: '',
    status: '',
  };

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
  ) { }

  onNewUserSubmit() {
    this.credentials = {
      email: this.registerForm.controls.email.value,
      name: this.registerForm.controls.name.value,
      password: this.registerForm.controls.password.value,
      role: this.registerForm.controls.role.value,
      status: 'active',
      userData: { fresh: true },
    };
    if (this.credentials.role === 'teacher') {
      this.credentials.userData.teacherId = Date.now();
    }
    this.auth.register(this.credentials).subscribe((result) => {

    });
    this.changeView.emit('list');
  }
}
