import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CurriculumJsonImportDialogComponent } from './curriculum-json-import-dialog/curriculum-json-import-dialog.component';
import { CurriculumViewComponent } from './curriculum-view/curriculum-view.component';

@Component({
    selector: 'app-curriculum-add-edit',
    templateUrl: './curriculum-add-edit.component.html',
    styleUrls: ['./curriculum-add-edit.component.css']
})
export class CurriculumAddEditComponent implements OnInit {
    curriculum: any = {
        name: '',
        units: []
    };

    // Track expanded state for units in the preview panel
    expandedUnitsInPreview: boolean[] = [];

    // Whether the edit panel is visible
    isEditPanelVisible: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CurriculumAddEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private helpers: HelpersService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        if (this.data && this.data.curriculum) {
            this.curriculum = this.helpers.clone(this.data.curriculum);
            // Initialize expanded state for existing units
            this.initializeExpandedUnits();

            // Show edit panel only if curriculum is empty (has no units)
            // Hide edit panel if curriculum has units
            this.isEditPanelVisible = !this.curriculum.units || this.curriculum.units.length === 0;
        } else {
            // Initialize with one empty unit if creating new curriculum
            this.addUnit();
            // If creating a new curriculum, show edit panel by default
            this.isEditPanelVisible = true;
        }
    }

    initializeExpandedUnits(): void {
        if (this.curriculum && this.curriculum.units) {
            this.expandedUnitsInPreview = this.curriculum.units.map(() => true);
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    toggleEditPanel(): void {
        this.isEditPanelVisible = !this.isEditPanelVisible;
    }

    addUnit(): void {
        this.curriculum.units.push({
            topic: '',
            vocabularyTopic: '',
            vocabularyList: '',
            grammar: [],
            listening: '',
            reading: '',
            speaking: '',
            writing: '',
            subjectArea: '',         // Obszar tematyczny (np. Człowiek, Edukacja)
            subjectSubArea: '',      // Podrozdział obszaru (np. dane personalne, cechy charakteru)
            functions: '',           // Funkcje językowe
            communicativeSkills: [], // Umiejętności komunikacyjne (np. Opisywanie ludzi, Przedstawianie siebie)
            receptiveSkills: [],     // Umiejętności receptywne (np. Znajdowanie w tekście określonych informacji)
            productiveSkills: [],    // Umiejętności produktywne (np. Opisywanie ludzi, Przedstawianie faktów)
            interactiveSkills: [],   // Umiejętności interaktywne (np. Wyrażanie swoich opinii, pytanie o opinię)
            processingSkills: [],    // Umiejętności przetwarzania (np. Przekazywanie informacji z materiałów wizualnych)
            otherSkills: [],         // Inne umiejętności (np. Współdziałanie w grupie)
            coreStandards: []        // Odniesienia do podstawy programowej (np. II 5, III 4)
        });
        // Update expanded state for the new unit
        this.expandedUnitsInPreview.push(true);
    }

    removeUnit(index: number): void {
        if (confirm('Czy na pewno chcesz usunąć tę jednostkę z programu nauczania?')) {
            this.curriculum.units.splice(index, 1);
            // Also remove the expanded state for this unit
            this.expandedUnitsInPreview.splice(index, 1);
        }
    }

    addGrammarItem(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].grammar) {
            this.curriculum.units[unitIndex].grammar = [];
        }
        this.curriculum.units[unitIndex].grammar.push('');
    }

    removeGrammarItem(unitIndex: number, grammarIndex: number): void {
        this.curriculum.units[unitIndex].grammar.splice(grammarIndex, 1);
    }

    // Helper methods for the added fields
    addCommunicativeSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].communicativeSkills) {
            this.curriculum.units[unitIndex].communicativeSkills = [];
        }
        this.curriculum.units[unitIndex].communicativeSkills.push('');
    }

    removeCommunicativeSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].communicativeSkills.splice(skillIndex, 1);
    }

    addReceptiveSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].receptiveSkills) {
            this.curriculum.units[unitIndex].receptiveSkills = [];
        }
        this.curriculum.units[unitIndex].receptiveSkills.push('');
    }

    removeReceptiveSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].receptiveSkills.splice(skillIndex, 1);
    }

    addProductiveSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].productiveSkills) {
            this.curriculum.units[unitIndex].productiveSkills = [];
        }
        this.curriculum.units[unitIndex].productiveSkills.push('');
    }

    removeProductiveSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].productiveSkills.splice(skillIndex, 1);
    }

    addInteractiveSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].interactiveSkills) {
            this.curriculum.units[unitIndex].interactiveSkills = [];
        }
        this.curriculum.units[unitIndex].interactiveSkills.push('');
    }

    removeInteractiveSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].interactiveSkills.splice(skillIndex, 1);
    }

    addProcessingSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].processingSkills) {
            this.curriculum.units[unitIndex].processingSkills = [];
        }
        this.curriculum.units[unitIndex].processingSkills.push('');
    }

    removeProcessingSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].processingSkills.splice(skillIndex, 1);
    }

    addOtherSkill(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].otherSkills) {
            this.curriculum.units[unitIndex].otherSkills = [];
        }
        this.curriculum.units[unitIndex].otherSkills.push('');
    }

    removeOtherSkill(unitIndex: number, skillIndex: number): void {
        this.curriculum.units[unitIndex].otherSkills.splice(skillIndex, 1);
    }

    addCoreStandard(unitIndex: number): void {
        if (!this.curriculum.units[unitIndex].coreStandards) {
            this.curriculum.units[unitIndex].coreStandards = [];
        }
        this.curriculum.units[unitIndex].coreStandards.push('');
    }

    removeCoreStandard(unitIndex: number, standardIndex: number): void {
        this.curriculum.units[unitIndex].coreStandards.splice(standardIndex, 1);
    }

    // Method to toggle unit expansion in preview
    toggleUnitInPreview(index: number): void {
        this.expandedUnitsInPreview[index] = !this.expandedUnitsInPreview[index];
    }

    // Format vocabulary list for better display in preview
    formatVocabularyList(list: any): string[] {
        // Check if list is not defined or null
        if (!list) return [];

        // If list is already an array, return it after processing
        if (Array.isArray(list)) {
            return list.map(item => item.toString().trim())
                .filter(item => item.length > 0);
        }

        // Ensure list is a string before calling split
        if (typeof list !== 'string') {
            console.warn('Expected string for vocabularyList but got:', typeof list);
            // Try to convert to string if possible
            try {
                list = String(list);
            } catch (e) {
                console.error('Could not convert vocabularyList to string:', e);
                return [];
            }
        }

        // Split by comma or newline and trim whitespace
        return list.split(/,|\n/)
            .map(item => item.trim())
            .filter(item => item.length > 0);
    }

    // Method to open the JSON import dialog
    openImportJsonDialog(): void {
        const dialogRef = this.dialog.open(CurriculumJsonImportDialogComponent, {
            width: '700px',
            maxHeight: '80vh',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.error === 'invalid_json') {
                    this.snackBar.open('Nieprawidłowy format JSON. Sprawdź składnię i spróbuj ponownie.', 'OK', { duration: 3000 });
                } else if (result.name && Array.isArray(result.units)) {
                    this.curriculum = result;
                    this.initializeExpandedUnits();
                    this.snackBar.open('Pomyślnie zaimportowano program nauczania', 'OK', { duration: 2000 });
                } else {
                    this.snackBar.open('Nieprawidłowy format danych. Program nauczania musi zawierać nazwę i jednostki.', 'OK', { duration: 3000 });
                }
            }
        });
    }

    // Method to export curriculum to JSON file
    exportToJson(): void {
        const dataStr = JSON.stringify(this.curriculum, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        const exportFileDefaultName = `curriculum_${this.curriculum.name.replace(/\s+/g, '_').toLowerCase()}.json`;

        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    // Method to view curriculum in visual format
    viewCurriculum(): void {
        if (!this.curriculum.name || this.curriculum.units.length === 0) {
            this.snackBar.open('Brak danych do wyświetlenia. Dodaj jednostki do programu nauczania.', 'OK', { duration: 3000 });
            return;
        }

        const dialogRef = this.dialog.open(CurriculumViewComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: ['full-screen-dialog', 'no-padding-dialog'],
            position: { top: '0', left: '0' },
            data: {
                curriculum: this.helpers.clone(this.curriculum)
            }
        });
    }

    // Method to add an example unit with predefined content
    addExampleUnit(): void {
        this.curriculum.units.push({
            topic: 'What are you like?',
            vocabularyTopic: 'Cechy charakteru',
            vocabularyList: 'friendly, shy, smart, funny, serious, talkative, quiet, patient, impatient, sensitive, open-minded, stubborn, cheerful, confident, polite',
            grammar: [
                'Formy twierdzące czasownika be w czasie Present Simple',
                'Zaimki osobowe',
                'Przymiotniki opisujące cechy charakteru'
            ],
            listening: 'Rozumienie dialogów z opisem cech charakteru osób',
            reading: 'Czytanie opisów osób i ich cech charakteru',
            speaking: 'Opisywanie siebie i innych osób',
            writing: 'Pisanie krótkiego opisu swojego przyjaciela',
            subjectArea: 'Człowiek',
            subjectSubArea: 'dane personalne, cechy charakteru',
            functions: 'Wyrażanie opinii o cechach charakteru ludzi',
            communicativeSkills: [
                'Przedstawianie siebie i innych',
                'Opisywanie ludzi',
                'Wyrażanie opinii'
            ],
            receptiveSkills: [
                'Znajdowanie w wypowiedzi określonych informacji',
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Opisywanie ludzi',
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Przedstawianie siebie i innych',
                'Wyrażanie swoich opinii, pytanie o opinię'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'II 5', 'III 4', 'IV 1', 'V 1', 'V 3', 'VI 1', 'VI 4', 'VII 4', 'XI'
            ]
        });
        // Update expanded state for the new unit
        this.expandedUnitsInPreview.push(true);

        // Show the edit panel
        this.isEditPanelVisible = true;
    }

    // Method to add a unit from template - will add second example unit based on the prompt
    addUnitFromTemplate(): void {
        this.curriculum.units.push({
            topic: 'Student\'s blog',
            vocabularyTopic: 'Znajomi i przyjaciele',
            vocabularyList: 'best friend, classmate, colleague, close friend, pen friend, neighbor, to share interests, to spend time together, to have fun, to trust, honest, loyal, reliable',
            grammar: [
                'Formy twierdzące i przeczące czasownika be w czasie Present Simple'
            ],
            listening: 'Słuchanie opisów przyjaciół',
            reading: 'Czytanie blogów studentów o ich przyjaciołach',
            speaking: 'Przedstawianie swoich przyjaciół',
            writing: 'Pisanie wpisu na blog o swoim najlepszym przyjacielu',
            subjectArea: 'Człowiek, Życie prywatne',
            subjectSubArea: 'dane personalne, cechy charakteru, znajomi i przyjaciele',
            functions: 'Opisywanie przyjaciół i relacji z nimi',
            communicativeSkills: [
                'Przedstawianie siebie i innych',
                'Opisywanie ludzi'
            ],
            receptiveSkills: [
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Opisywanie ludzi',
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Przedstawianie siebie i innych'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'III 4', 'V 1', 'V 3', 'VI 1', 'VIII 1', 'XI'
            ]
        });
        // Update expanded state for the new unit
        this.expandedUnitsInPreview.push(true);

        // Show the edit panel
        this.isEditPanelVisible = true;
    }

    // Add more examples from the prompt
    addMoreExamples(): void {
        // Third example
        this.curriculum.units.push({
            topic: 'How much is the ruler?',
            vocabularyTopic: 'Przybory szkolne i zakupy',
            vocabularyList: 'ruler, pen, pencil, pencil case, notebook, textbook, eraser, calculator, scissors, glue, sharpener, buy, sell, cost, price, cheap, expensive, money',
            grammar: [
                'How much is..?',
                'Formy pytające czasownika to be w czasie Present Simple'
            ],
            listening: 'Słuchanie dialogów w sklepie z przyborami szkolnymi',
            reading: 'Czytanie cenników przyborów szkolnych',
            speaking: 'Pytanie o cenę i podawanie ceny przyborów szkolnych',
            writing: 'Tworzenie listy zakupów z cenami',
            subjectArea: 'Edukacja, Zakupy i usługi',
            subjectSubArea: 'przybory szkolne, sprzedawanie i kupowanie',
            functions: 'Pytanie o cenę i podawanie ceny',
            communicativeSkills: [
                'Pytanie o cenę',
                'Podawanie ceny'
            ],
            receptiveSkills: [
                'Znajdowanie w wypowiedzi określonych informacji',
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Opisywanie przedmiotów',
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'II 5', 'III 4', 'IV 1', 'V 3', 'VI 3', 'VII 3', 'VIII 1', 'XI'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Fourth example
        this.curriculum.units.push({
            topic: 'Iggy the inventor: Episode 2 – Your inventions are dangerous',
            vocabularyTopic: 'Zawody i przybory szkolne',
            vocabularyList: 'inventor, invention, engineer, scientist, teacher, doctor, nurse, designer, artist, writer, dangerous, useful, interesting, creative',
            grammar: [
                'Pytania z czasownikiem to be w czasie Present Simple',
                'Krótkie odpowiedzi',
                'Zaimki pytające: who, what'
            ],
            listening: 'Słuchanie historyjki o wynalazcy',
            reading: 'Czytanie historyjki obrazkowej',
            speaking: 'Zadawanie pytań i udzielanie odpowiedzi odnośnie danych personalnych oraz zawodów',
            writing: 'Opisywanie przedmiotów i wynalazków',
            subjectArea: 'Człowiek, Edukacja, Praca',
            subjectSubArea: 'dane personalne, przybory szkolne, popularne zawody',
            functions: 'Opisywanie przedmiotów i osób',
            communicativeSkills: [
                'Zadawanie pytań i udzielanie odpowiedzi odnośnie danych personalnych oraz zawodów',
                'Opisywanie przedmiotów'
            ],
            receptiveSkills: [
                'Znajdowanie w wypowiedzi określonych informacji',
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Opisywanie przedmiotów'
            ],
            interactiveSkills: [
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [],
            otherSkills: [
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'II 5', 'III 4', 'IV 1', 'VI 3', 'VII 3', 'XI'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Fifth example
        this.curriculum.units.push({
            topic: 'Train your brain 2',
            vocabularyTopic: 'Przybory szkolne i dane personalne',
            vocabularyList: 'classroom objects, personal details, numbers, age, country, nationality, language',
            grammar: [
                'Formy twierdzące, przeczące i pytające czasownika to be w czasie Present Simple',
                'Zaimki pytające: who, what'
            ],
            listening: 'Słuchanie wypowiedzi o przyborach szkolnych i danych personalnych',
            reading: 'Czytanie tekstów z ćwiczeniami powtórzeniowymi',
            speaking: 'Opisywanie ludzi i przedmiotów, zadawanie pytań',
            writing: 'Uzupełnianie formularzy osobowych',
            subjectArea: 'Człowiek, Edukacja, Zakupy i usługi',
            subjectSubArea: 'dane personalne, przybory szkolne, sprzedawanie, kupowanie',
            functions: 'Ćwiczenie poznanego materiału poprzez różne umiejętności',
            communicativeSkills: [
                'Opisywanie ludzi i przedmiotów',
                'Zadawanie pytań i udzielanie odpowiedzi odnośnie danych personalnych oraz przyborów szkolnych'
            ],
            receptiveSkills: [
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Opisywanie ludzi i przedmiotów',
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'III 4', 'IV 1', 'V 3', 'VI 3', 'VII 3', 'VIII 1', 'XI'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Show the edit panel
        this.isEditPanelVisible = true;
    }

    // Add final examples from the prompt
    addFinalExamples(): void {
        // Sixth example
        this.curriculum.units.push({
            topic: 'In the playground',
            vocabularyTopic: 'Nazwy krajów',
            vocabularyList: 'the UK, the USA, Poland, Germany, France, Spain, Italy, Russia, China, Japan, Australia, Canada, Brazil, Mexico, India',
            grammar: [
                'Pytania szczegółowe z czasownikiem to be w czasie Present Simple'
            ],
            listening: 'Słuchanie dialogów między uczniami z różnych krajów',
            reading: 'Czytanie profilów uczniów z różnych krajów',
            speaking: 'Nawiązywanie kontaktów towarzyskich z osobami z różnych krajów',
            writing: 'Wypełnianie formularza z informacjami o kraju pochodzenia',
            subjectArea: 'Człowiek',
            subjectSubArea: 'dane personalne, nazwy krajów',
            functions: 'Nawiązywanie kontaktów towarzyskich z osobami z różnych krajów',
            communicativeSkills: [],
            receptiveSkills: [
                'Znajdowanie w wypowiedzi określonych informacji'
            ],
            productiveSkills: [],
            interactiveSkills: [
                'Nawiązywanie kontaktów towarzyskich',
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [],
            otherSkills: [
                'Współdziałanie w grupie',
                'Świadomość podobieństw między językami'
            ],
            coreStandards: [
                'II 5', 'VI 2', 'VI 3', 'VII 3', 'XI', 'XIV'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Seventh example
        this.curriculum.units.push({
            topic: 'Around the world Culture: flags of different countries',
            vocabularyTopic: 'Flagi i symbole narodowe',
            vocabularyList: 'flag, country, national, symbol, emblem, colors, represent, identity, heritage, culture, tradition',
            grammar: [],
            listening: 'Słuchanie prezentacji o flagach różnych krajów',
            reading: 'Czytanie tekstów o symbolach narodowych',
            speaking: 'Opisywanie flag i symboli narodowych',
            writing: 'Tworzenie notatki o fladze wybranego kraju',
            subjectArea: 'Kultura, Życie społeczne',
            subjectSubArea: 'Tradycje i zwyczaje, rozwijanie świadomości i wrażliwości międzykulturowej',
            functions: 'Poszerzanie wiedzy o krajach anglojęzycznych',
            communicativeSkills: [],
            receptiveSkills: [
                'Znajdowanie w wypowiedzi określonych informacji',
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Wiedza o krajach anglojęzycznych',
                'Świadomość związku między kulturą własną i obcą oraz wrażliwość międzykulturowa',
                'Współdziałanie w grupie',
                'Korzystanie ze źródeł informacji w języku angielskim'
            ],
            coreStandards: [
                'II 5', 'III 4', 'V 3', 'VI 3', 'VIII 1', 'IX 1', 'IX 2', 'XI', 'XII'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Eighth example
        this.curriculum.units.push({
            topic: 'Revision Workout 2',
            vocabularyTopic: 'Powtórzenie wiadomości',
            vocabularyList: 'dane personalne, przybory szkolne, ceny, liczby 1-100',
            grammar: [
                'Powtórzenie poznanych zagadnień gramatycznych z rozdziału 2'
            ],
            listening: 'Ćwiczenia powtórzeniowe ze słuchania',
            reading: 'Ćwiczenia powtórzeniowe z czytania',
            speaking: 'Zadawanie pytań i udzielanie odpowiedzi odnośnie danych personalnych, pytanie o ceny',
            writing: 'Pisanie krótkich tekstów powtórzeniowych',
            subjectArea: 'Człowiek, Edukacja, Zakupy i usługi',
            subjectSubArea: 'dane personalne, przybory szkolne, sprzedawanie, kupowanie, liczby 1-100',
            functions: 'Powtórzenie wiadomości i umiejętności z rozdziału 2',
            communicativeSkills: [
                'Zadawanie pytań i udzielanie odpowiedzi odnośnie danych personalnych',
                'Pytanie o ceny i podawanie ceny przyborów szkolnych'
            ],
            receptiveSkills: [
                'Znajdowanie w tekście określonych informacji'
            ],
            productiveSkills: [
                'Przedstawianie faktów z teraźniejszości'
            ],
            interactiveSkills: [
                'Uzyskiwanie i przekazywanie informacji'
            ],
            processingSkills: [
                'Przekazywanie w języku angielskim informacji zawartych w materiałach wizualnych'
            ],
            otherSkills: [
                'Dokonywanie samooceny',
                'Współdziałanie w grupie'
            ],
            coreStandards: [
                'III 4', 'V 3', 'VI 3', 'VII 3', 'VIII 1', 'X', 'XI'
            ]
        });
        this.expandedUnitsInPreview.push(true);

        // Show the edit panel
        this.isEditPanelVisible = true;
    }

    // Method to create a full curriculum with all examples
    createFullExampleCurriculum(): void {
        // Set curriculum name
        this.curriculum.name = "Program nauczania języka angielskiego - poziom podstawowy";

        // Clear any existing units
        this.curriculum.units = [];
        this.expandedUnitsInPreview = [];

        // Add first example unit
        this.addExampleUnit();

        // Add second example unit
        this.addUnitFromTemplate();

        // Add examples 3-5
        this.addMoreExamples();

        // Add examples 6-8
        this.addFinalExamples();

        // Set first unit as expanded, others collapsed for better UI
        this.expandedUnitsInPreview = this.curriculum.units.map((_, index) => index === 0);

        // Show the preview panel
        this.isEditPanelVisible = false;
    }

    // Method to duplicate a unit
    duplicateUnit(unitIndex: number): void {
        // Clone the unit
        const duplicatedUnit = this.helpers.clone(this.curriculum.units[unitIndex]);

        // Add a copy suffix to the topic if it exists
        if (duplicatedUnit.topic) {
            duplicatedUnit.topic = `${duplicatedUnit.topic} (kopia)`;
        }

        // Insert the duplicated unit after the original
        this.curriculum.units.splice(unitIndex + 1, 0, duplicatedUnit);

        // Update expanded state for the new unit
        this.expandedUnitsInPreview.splice(unitIndex + 1, 0, true);

        // Show notification
        this.snackBar.open('Jednostka została zduplikowana', 'OK', { duration: 2000 });
    }

    // Method to print curriculum
    printCurriculum(): void {
        // Create a window to print
        const printWindow = window.open('', '_blank');
        if (!printWindow) {
            this.snackBar.open('Nie można otworzyć okna drukowania. Sprawdź, czy nie blokowane są wyskakujące okna.', 'OK', { duration: 3000 });
            return;
        }

        // Build HTML content
        let printContent = `
            <html>
            <head>
                <title>${this.curriculum.name}</title>
                <style>
                    body { font-family: Arial, sans-serif; margin: 20px; }
                    h1 { color: #3f51b5; text-align: center; margin-bottom: 30px; }
                    .unit { margin-bottom: 40px; page-break-inside: avoid; }
                    .unit-header { 
                        background-color: #f5f5f5; 
                        padding: 10px; 
                        border-radius: 4px; 
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                    }
                    .unit-number {
                        background-color: #3f51b5;
                        color: white;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        font-weight: bold;
                    }
                    .unit-name { font-size: 18px; font-weight: bold; }
                    .section { margin-bottom: 20px; }
                    .section-title { 
                        font-weight: bold; 
                        color: #3f51b5; 
                        margin-bottom: 10px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid #e0e0e0;
                    }
                    .metadata-item { margin-bottom: 8px; }
                    .metadata-label { font-weight: bold; display: inline-block; min-width: 150px; }
                    .vocabulary-list, .grammar-list, .skills-list { 
                        display: flex; 
                        flex-wrap: wrap; 
                        gap: 8px;
                        margin-top: 10px;
                    }
                    .vocabulary-item, .grammar-item { 
                        background-color: #f1f1f1; 
                        padding: 5px 10px; 
                        border-radius: 15px;
                        display: inline-block;
                    }
                    .standards-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }
                    .standard-tag {
                        background-color: #ffd54f;
                        color: #212121;
                        padding: 4px 8px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: bold;
                        display: inline-block;
                    }
                    .skill-category { margin-bottom: 15px; }
                    .skill-category-title { font-weight: bold; color: #673ab7; margin-bottom: 5px; }
                    .skill-box {
                        display: flex;
                        margin-bottom: 10px;
                        border: 1px solid #e0e0e0;
                        padding: 10px;
                        border-radius: 4px;
                    }
                    .skill-title { font-weight: bold; margin-right: 10px; min-width: 100px; }
                    @media print {
                        .page-break { page-break-before: always; }
                    }
                </style>
            </head>
            <body>
                <h1>${this.curriculum.name}</h1>
        `;

        // Generate content for each unit
        this.curriculum.units.forEach((unit, index) => {
            if (index > 0) {
                printContent += '<div class="page-break"></div>';
            }

            printContent += `
                <div class="unit">
                    <div class="unit-header">
                        <div class="unit-number">${index + 1}</div>
                        <div class="unit-name">${unit.topic || 'Bez tematu'}</div>
                    </div>
                    
                    <div class="section">
                        <div class="section-title">Informacje ogólne</div>
            `;

            if (unit.subjectArea) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Obszar tematyczny:</span>
                        <span>${unit.subjectArea}</span>
                    </div>
                `;
            }

            if (unit.subjectSubArea) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Podrozdział obszaru:</span>
                        <span>${unit.subjectSubArea}</span>
                    </div>
                `;
            }

            if (unit.functions) {
                printContent += `
                    <div class="metadata-item">
                        <span class="metadata-label">Funkcje językowe:</span>
                        <span>${unit.functions}</span>
                    </div>
                `;
            }

            printContent += `
                </div>
                
                <div class="section">
                    <div class="section-title">Słownictwo</div>
                    <div class="metadata-item">
                        <span class="metadata-label">Temat słownictwa:</span>
                        <span>${unit.vocabularyTopic || ''}</span>
                    </div>
                    <div class="vocabulary-list">
            `;

            const vocabList = this.formatVocabularyList(unit.vocabularyList);
            if (vocabList.length > 0) {
                vocabList.forEach(vocab => {
                    printContent += `<div class="vocabulary-item">${vocab}</div>`;
                });
            } else {
                printContent += `<div>Brak słownictwa</div>`;
            }

            printContent += `
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">Gramatyka</div>
                    <div class="grammar-list">
            `;

            if (unit.grammar && unit.grammar.length > 0) {
                unit.grammar.forEach(grammar => {
                    printContent += `<div class="grammar-item">${grammar}</div>`;
                });
            } else {
                printContent += `<div>Brak punktów gramatycznych</div>`;
            }

            printContent += `
                    </div>
                </div>
            `;

            // Skills sections - combine all skills
            printContent += `
                <div class="section">
                    <div class="section-title">Umiejętności</div>
            `;

            // Communicative skills
            if (unit.communicativeSkills && unit.communicativeSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Komunikacyjne</div>
                        <ul>
                `;
                unit.communicativeSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Receptive skills
            if (unit.receptiveSkills && unit.receptiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Receptywne</div>
                        <ul>
                `;
                unit.receptiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Productive skills
            if (unit.productiveSkills && unit.productiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Produktywne</div>
                        <ul>
                `;
                unit.productiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Interactive skills
            if (unit.interactiveSkills && unit.interactiveSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Interaktywne</div>
                        <ul>
                `;
                unit.interactiveSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Processing skills
            if (unit.processingSkills && unit.processingSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Przetwarzania</div>
                        <ul>
                `;
                unit.processingSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            // Other skills
            if (unit.otherSkills && unit.otherSkills.length > 0) {
                printContent += `
                    <div class="skill-category">
                        <div class="skill-category-title">Inne</div>
                        <ul>
                `;
                unit.otherSkills.forEach(skill => {
                    printContent += `<li>${skill}</li>`;
                });
                printContent += `
                        </ul>
                    </div>
                `;
            }

            printContent += `
                </div>
            `;

            // Core standards
            if (unit.coreStandards && unit.coreStandards.length > 0) {
                printContent += `
                    <div class="section">
                        <div class="section-title">Odniesienia do podstawy programowej</div>
                        <div class="standards-list">
                `;
                unit.coreStandards.forEach(standard => {
                    printContent += `<div class="standard-tag">${standard}</div>`;
                });
                printContent += `
                        </div>
                    </div>
                `;
            }

            // Four skills
            printContent += `
                <div class="section">
                    <div class="section-title">Cztery umiejętności</div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Słuchanie:</div>
                        <div>${unit.listening || 'Brak ćwiczeń słuchania'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Czytanie:</div>
                        <div>${unit.reading || 'Brak ćwiczeń czytania'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Mówienie:</div>
                        <div>${unit.speaking || 'Brak ćwiczeń mówienia'}</div>
                    </div>
                    
                    <div class="skill-box">
                        <div class="skill-title">Pisanie:</div>
                        <div>${unit.writing || 'Brak ćwiczeń pisania'}</div>
                    </div>
                </div>
            `;

            printContent += `</div>`;
        });

        printContent += `
            </body>
            </html>
        `;

        // Write to the new window and print
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();

        // Wait for content to load before printing
        printWindow.onload = function () {
            printWindow.print();
        };
    }
} 