import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Report } from '../create-report/report.model';
import { EvaluateStudentService } from '../../evaluate-student.service';
import { CustomersService } from '../../services/customers.service';
import { MatDialog } from '@angular/material/dialog';
import { DatetimePickerDialogComponent } from '../../compnents/datetime-picker-dialog/datetime-picker-dialog.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailService } from '../../services/email.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { LessonsArchiveService } from 'src/app/shared/services/lessons-archive.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-evaluate-student',
  templateUrl: './evaluate-student.component.html',
  styleUrls: ['./evaluate-student.component.css'],
})
export class EvaluateStudentComponent implements OnInit {
  @Input() studentData;
  @Input() studentId: string;
  @Input() studentName: string;
  @Input() studentCustomerId: string;
  @Input() studentReports: Array<Report> = [];
  @Output() resetReport = new EventEmitter();
  report: Report;
  startDateForJoinedLate = 1633453285624;
  reportSentToday: boolean = false;
  html: String;
  yearMode: 'start' | 'end' = null;
  htmlContent: String;
  dataFrom2PreviousReportsLoaded: boolean = false;
  subject = 'Raport z nauki w Ling King';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300',
    minHeight: '300',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    // upload: (file: File) => { ... }
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };
  studentFirstName: any;
  constructor(
    private evaluateStudentService: EvaluateStudentService,
    private customersService: CustomersService,
    private dialog: MatDialog,
    private emailService: EmailService,
    public userDetailsService: UserDetailsService,
    private lessonsArchiveService: LessonsArchiveService,
    private _snackBar: MatSnackBar
  ) {
    this.report = new Report();
  }

  setDate(event) {
    let septemberDate = new Date('2021-09-25');
    let year =
      new Date().getMonth() > 8
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    septemberDate.setFullYear(year);
    this.report.date =
      event.value == 'start' ? new Date(septemberDate) : new Date();
  }
  ngOnInit(): void {
    this.studentFirstName = this.studentName.split(' ')[0];

    setInterval(() => {
      if (this.report.teachersComment.length > 0) {
        this.setValue();
      }
    }, 1000);
    console.log(
      '🚀 ~ file: evaluate-student.component.ts ~ line 28 ~ EvaluateStudentComponent ~ ngOnInit ~ this.studentReports',
      this.studentReports
    );
    this.report.date = new Date();
    this.report.studentId = this.studentId;
    this.report.homework = 0;
    this.report.listening = 0;
    this.report.practicalSkills = 0;
    this.report.reading = 0;
    this.report.schoolSkills = 0;
    this.report.speaking = 0;
    this.report.writing = 0;
    this.report.activity = 0;
    this.report.attendance = 0;
    this.report.teachersComment = '';
    this.studentReports = this.studentReports?.filter((report) => report?.date);
  }
  loadPresetDataFrom2PreviousReports() {
    if (this.studentReports.length > 1) {
      this.dataFrom2PreviousReportsLoaded = true;
      this.report = this.studentReports[this.studentReports.length - 1];
      this.setValue();
    } else {
      alert('Uczeń nie ma 2 raportów!');
    }
  }
  loadPresetDataFromPreviousReport() {
    this.dataFrom2PreviousReportsLoaded = false;

    if (this.studentReports[this.studentReports.length - 1].reading) {
      this.report.homework =
        this.studentReports[this.studentReports.length - 1].homework + 1;
      this.report.listening =
        this.studentReports[this.studentReports.length - 1].listening + 1;
      this.report.practicalSkills =
        this.studentReports[this.studentReports.length - 1].practicalSkills + 1;
      this.report.reading =
        this.studentReports[this.studentReports.length - 1].reading + 1;
      this.report.schoolSkills =
        this.studentReports[this.studentReports.length - 1].schoolSkills + 1;
      this.report.speaking =
        this.studentReports[this.studentReports.length - 1].speaking + 1;
      this.report.writing =
        this.studentReports[this.studentReports.length - 1].writing + 1;
      this.report.activity =
        this.studentReports[this.studentReports.length - 1].activity;
      this.report.attendance =
        this.studentReports[this.studentReports.length - 1].attendance;
      this.report.teachersComment =
        this.studentReports[this.studentReports.length - 1].teachersComment +
        ' ';
    } else {
      alert('Brak danych do raportu!');
    }
  }
  openDatePickerDialog() {
    const dialogRef = this.dialog.open(DatetimePickerDialogComponent, {
      data: { title: 'Wybierz datę', selectedDateTime: '2021-11-01T09:51' },
    });
    dialogRef.afterClosed().subscribe((chosenDate) => {
      console.log(
        '🚀 ~ file: evaluate-student.component.ts ~ line 50 ~ EvaluateStudentComponent ~ dialogRef.afterClosed ~ chosenDate',
        chosenDate
      );
      this.report.date = new Date(chosenDate.dateTime);
    });
  }
  setYearsMode(event) {
    this.yearMode = event.value;
  }
  setValue(event?) {
    const studentJoinedDate = parseInt(this.studentData.studentId);
    const difference = Date.now() - studentJoinedDate;
    const joinedLater = difference < 2629746000 * 8;
    let previousReport: Report;
    if (this.dataFrom2PreviousReportsLoaded) {
      this.studentReports?.length > 1
        ? this.studentReports[this.studentReports?.length - 2]
        : null;
    }
    if (!this.dataFrom2PreviousReportsLoaded) {
      previousReport =
        this.studentReports?.length > 0
          ? this.studentReports[this.studentReports?.length - 1]
          : null;
    }
    if (event) {
      this.report[event.propertyName] = event.propertyValue;
    }
    this.html = this.evaluateStudentService.generateHtml(
      previousReport,
      this.report,
      this.studentFirstName,
      this.studentData.smallChild,
      joinedLater,
      this.studentData.gender
    );
  }
  validateReport() {
    let result = false;
    if (
      this.report.homework != 0 &&
      this.report.listening != 0 &&
      this.report.practicalSkills != 0 &&
      this.report.reading != 0 &&
      this.report.speaking != 0 &&
      this.report.activity != 0 &&
      this.report.writing != 0 &&
      this.report.attendance != 0
    ) {
      result = true;
    }
    return result;
  }
  removeReport(report: Report) {
    // if (confirm("Czy na pewno usunąć ten raport?")) {
    this.customersService.removeReport(
      report.date,
      this.studentId,
      this.studentCustomerId
    );
    this.studentReports = this.studentReports.filter(
      (_report) => _report.date !== report.date
    );
    // }
  }
  sendEmail(andSave?) {
    if (this.yearMode) {
      let dataToSend = {
        students: [this.studentData],
        html: this.html,
        subject: this.subject,
        filesToSend: null,
        from: 'lingkingteachers@lingking.pl',
      };
      this.emailService.sendEmailWithHtml(dataToSend, true);
      if (andSave) {
        this.saveReport();
      }
      this.reportSentToday = true;
    } else {
      alert('Wybierz początek/koniec roku szkolnego!');
    }
  }
  saveReport() {
    if (this.yearMode) {
      setTimeout(() => {
        this.resetReport.emit(null);
      }, 500);
      if (this.validateReport()) {
        if (
          confirm(
            'Nie będzie już można zmienić raportu. Czy na pewno kontynuować?'
          )
        ) {
          console.log(
            '🚀 ~ file: evaluate-student.component.ts ~ line 31 ~ EvaluateStudentComponent ~ saveReport ~ this.report',
            this.report
          );

          // Save the report
          this.customersService.addReport(
            this.report,
            this.studentId,
            this.studentCustomerId
          );

          // Update the local reports list
          this.studentReports
            ? this.studentReports.unshift(this.report)
            : (this.studentReports = [this.report]);

          // Save to lessons archive
          this.saveToLessonsArchive();
        }
      } else {
        alert('Uzupełnij wszystkie pola!');
      }
    } else {
      alert('Wybierz początek/koniec roku szkolnego!');
    }
  }

  // New method to save to lessons archive
  saveToLessonsArchive() {
    try {
      // Student details based on student type
      const studentDetails = this.studentData?.child ?
        {
          isChild: true,
          childFirstName: this.studentData.childFirstName,
          childLastName: this.studentData.childLastName,
          age: this.studentData.childAge,
          gender: this.studentData.gender,
          level: this.studentData.level
        } :
        {
          isChild: false,
          adultFirstName: this.studentData.adultFirstName,
          adultLastName: this.studentData.adultLastName,
          level: this.studentData.level
        };

      // Prepare lesson data for archive with full report object
      const lessonArchiveData = {
        groupId: this.studentData.groupId || 'individual',
        groupName: this.studentData.groupName || 'Lekcja indywidualna',
        teacherId: this.userDetailsService.getSimpleUserDetails().id,
        teacherName: this.userDetailsService.getSimpleUserDetails().name,
        date: new Date(this.report.date),
        courseId: this.studentData.courseId,
        courseName: this.studentData.courseName,
        studentData: this.studentData, // Include full student data
        fullReport: this.report, // Include full report object
        lessonData: {
          author: this.userDetailsService.getSimpleUserDetails(),
          added: Date.now(),
          activities: [],
          attendance: [{
            date: new Date(),
            studentId: this.studentId,
            studentAppId: this.studentData.studentAppId,
            name: this.studentName,
            comments: [],
            present: true
          }],
          sets: [],
          homework: [],
          status: {
            type: 'completed',
          },
          comments: [{
            author: this.userDetailsService.getSimpleUserDetails(),
            comment: this.report.teachersComment,
            date: Date.now()
          }],
          lessonAnswers: [{
            studentId: this.studentId,
            studentName: this.studentName,
            answers: [
              { question: 'Czytanie', answer: `Poziom: ${this.report.reading}` },
              { question: 'Pisanie', answer: `Poziom: ${this.report.writing}` },
              { question: 'Mówienie', answer: `Poziom: ${this.report.speaking}` },
              { question: 'Słuchanie', answer: `Poziom: ${this.report.listening}` },
              { question: 'Praktyczne umiejętności', answer: `Poziom: ${this.report.practicalSkills}` },
              { question: 'Umiejętności szkolne', answer: `Poziom: ${this.report.schoolSkills}` },
              { question: 'Obecność', answer: `Poziom: ${this.report.attendance}` },
              { question: 'Aktywność', answer: `Poziom: ${this.report.activity}` },
              { question: 'Praca domowa', answer: `Poziom: ${this.report.homework}` },
              { question: 'Komentarz nauczyciela', answer: this.report.teachersComment }
            ]
          }],
          lessonFinishedAndChecked: true,
          lessonType: 'evaluation',
          htmlReport: this.html,
          yearMode: this.yearMode,
          originalReport: JSON.parse(JSON.stringify(this.report)), // Include a clean stringified copy of the report
          studentDetails: studentDetails // Add student details as a field in lessonData
        }
      };

      // Save to archive
      this.lessonsArchiveService.saveLessonToArchive(lessonArchiveData)
        .then(() => {
          this._snackBar.open('Raport zapisany w archiwum lekcji z pełnymi danymi', 'OK', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        })
        .catch((error) => {
          console.error('Error saving to lesson archive:', error);
          this._snackBar.open('Błąd podczas zapisywania w archiwum lekcji', 'OK', {
            duration: 5000,
            panelClass: ['error-snackbar']
          });
        });
    } catch (error) {
      console.error('Error preparing lesson archive data:', error);
    }
  }
}
