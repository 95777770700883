<h2 mat-dialog-title>Wybierz zasoby dla kursu</h2>
<mat-divider></mat-divider>

<div
  class="resource-selection-dialog-content"
  style="height: calc(100% - 130px); overflow-y: auto; padding: 20px"
>
  <!-- Wyszukiwarka -->
  <div class="search-container mb-4">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Wyszukaj zasoby</mat-label>
      <input
        matInput
        [(ngModel)]="searchText"
        (keyup)="filterResources()"
        placeholder="Wpisz nazwę, opis, temat lub kompetencję"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <!-- Lista zasobów -->
  <div *ngIf="filteredResources.length === 0" class="text-center p-5">
    <p>Brak zasobów do wyświetlenia</p>
  </div>

  <mat-card
    *ngFor="let resource of filteredResources"
    class="mb-3 resource-card compact"
    [class.selected-resource]="resource.selected"
    (click)="toggleResourceSelection(resource)"
    style="
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      position: relative;
    "
  >
    <div class="card-content-wrapper">
      <!-- Główna zawartość karty -->
      <div class="card-main-content w-100">
        <mat-card-header>
          <mat-card-title>{{ resource.name }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <!-- Górna część - tagi i info -->
          <div class="resource-info-grid">
            <div class="resource-tags-container">
              <div class="d-flex flex-wrap align-items-center">
                <span *ngIf="resource.theme" class="resource-tag theme">
                  <mat-icon class="tag-icon">local_offer</mat-icon>
                  {{ resource.theme }}
                </span>
                <span
                  *ngIf="resource.difficultyLevel"
                  class="resource-tag difficulty"
                >
                  <mat-icon class="tag-icon">signal_cellular_alt</mat-icon>
                  {{ resource.difficultyLevel }}
                </span>
                <span
                  *ngIf="resource.competence"
                  class="resource-tag competence"
                >
                  <mat-icon class="tag-icon">psychology</mat-icon>
                  {{ resource.competence }}
                </span>
                <span *ngIf="resource.ages" class="resource-tag ages">
                  <mat-icon class="tag-icon">people</mat-icon>
                  {{ resource.ages }}
                </span>
                <span
                  *ngIf="resource.isSchoolCurriculum"
                  class="resource-tag curriculum"
                  matTooltip="Zgodne z programem szkolnym"
                >
                  <mat-icon class="tag-icon">school</mat-icon>
                  Program szkolny
                </span>
              </div>
            </div>

            <div class="resource-metadata">
              <div
                class="metadata-item"
                *ngIf="resource.isSchoolCurriculum && resource.bookName"
              >
                <mat-icon class="metadata-icon">menu_book</mat-icon>
                <span>{{ resource.bookName }}</span>
              </div>
              <div class="metadata-item" *ngIf="resource.added">
                <mat-icon class="metadata-icon">event</mat-icon>
                <span>{{ resource.added | date: "dd.MM.yyyy" }}</span>
              </div>
            </div>
          </div>

          <!-- Opis -->
          <div class="description-container" *ngIf="resource.description">
            <p class="description-text">
              {{ resource.description | slice: 0 : 100
              }}{{ resource.description.length > 100 ? "..." : "" }}
            </p>
          </div>

          <!-- Sekcja zawartości z ustaloną wysokością i scrollowaniem -->
          <div
            class="resource-content-section"
            *ngIf="hasResourceItems(resource)"
          >
            <h3 class="content-header">Zawartość:</h3>
            <div class="content-grid">
              <!-- Zestawy -->
              <div
                class="content-item-box"
                *ngIf="resource.sets && resource.sets.length > 0"
              >
                <div class="content-item-header">
                  <span class="content-item-title">Zestawy</span>
                  <span class="content-item-count">{{
                    resource.sets.length
                  }}</span>
                </div>
                <div
                  class="content-item-details-container"
                  style="max-height: 80px; overflow-y: auto"
                >
                  <div
                    class="content-item-details"
                    *ngFor="let set of resource.sets"
                  >
                    <mat-icon class="content-item-icon sets-icon"
                      >category</mat-icon
                    >
                    <span class="content-item-name">{{ set.name }}</span>
                  </div>
                </div>
              </div>

              <!-- Ćwiczenia -->
              <div
                class="content-item-box"
                *ngIf="resource.activities && resource.activities.length > 0"
              >
                <div class="content-item-header">
                  <span class="content-item-title">Ćwiczenia</span>
                  <span class="content-item-count">{{
                    resource.activities.length
                  }}</span>
                </div>
                <div
                  class="content-item-details-container"
                  style="max-height: 80px; overflow-y: auto"
                >
                  <div
                    class="content-item-details"
                    *ngFor="let activity of resource.activities"
                  >
                    <mat-icon class="content-item-icon activities-icon"
                      >extension</mat-icon
                    >
                    <span class="content-item-name">{{ activity.name }}</span>
                  </div>
                </div>
              </div>

              <!-- Zadania domowe -->
              <div
                class="content-item-box"
                *ngIf="resource.homework && resource.homework.length > 0"
              >
                <div class="content-item-header">
                  <span class="content-item-title">Zadania domowe</span>
                  <span class="content-item-count">{{
                    resource.homework.length
                  }}</span>
                </div>
                <div
                  class="content-item-details-container"
                  style="max-height: 80px; overflow-y: auto"
                >
                  <div
                    class="content-item-details"
                    *ngFor="let hw of resource.homework"
                  >
                    <mat-icon class="content-item-icon homework-icon"
                      >assignment</mat-icon
                    >
                    <span class="content-item-name">{{
                      hw.displayName || hw.name
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- Pliki -->
              <div
                class="content-item-box"
                *ngIf="resource.fileGroups && getFilesCount(resource) > 0"
              >
                <div class="content-item-header">
                  <span class="content-item-title">Pliki</span>
                  <span class="content-item-count">{{
                    getFilesCount(resource)
                  }}</span>
                </div>
                <div
                  class="content-item-details-container"
                  style="max-height: 80px; overflow-y: auto"
                >
                  <ng-container *ngFor="let group of resource.fileGroups">
                    <div
                      class="file-group-name"
                      *ngIf="group.name"
                      style="
                        font-weight: 500;
                        margin-top: 8px;
                        margin-bottom: 4px;
                        padding-left: 4px;
                        color: #5c6bc0;
                        font-size: 0.9rem;
                      "
                    >
                      {{ group.name }}
                    </div>
                    <div
                      class="content-item-details"
                      *ngFor="let file of group.files"
                    >
                      <mat-icon class="content-item-icon files-icon"
                        >insert_drive_file</mat-icon
                      >
                      <span class="content-item-name">{{ file.name }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>

        <!-- Przycisk wyboru na całą szerokość -->
        <mat-card-actions class="select-button-container">
          <button
            mat-button
            class="select-button"
            [class.selected]="resource.selected"
            (click)="
              $event.stopPropagation(); toggleResourceSelection(resource)
            "
          >
            <mat-icon *ngIf="resource.selected">check_circle</mat-icon>
            <span *ngIf="resource.selected">Zaznaczony</span>
            <span *ngIf="!resource.selected">Wybierz zasób</span>
          </button>
        </mat-card-actions>
      </div>
    </div>
  </mat-card>
</div>

<div
  mat-dialog-actions
  class="d-flex justify-content-end"
  style="padding: 16px; border-top: 1px solid #e0e0e0"
>
  <button mat-button (click)="cancelSelection()">Anuluj</button>
  <button mat-raised-button color="primary" (click)="confirmSelection()">
    Wybierz ({{ selectedResources.length }})
  </button>
</div>
