<h2 mat-dialog-title>Dodaj kurs</h2>
<mat-divider></mat-divider>
<form
  (ngSubmit)="addCourse()"
  [formGroup]="coursesForm"
  class="scrollable-form"
>
  <div fxLayout="column">
    <div
      class="mt-3"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Nazwa</mat-label>
        <input matInput formControlName="name" placeholder="Nazwa" />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Opis</mat-label>
        <input matInput formControlName="description" placeholder="Opis" />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Online</mat-label>
        <mat-select formControlName="online">
          <mat-option value="true">tak</mat-option>
          <mat-option value="false">nie</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Zajęcia grupowe</mat-label>
        <mat-select formControlName="group">
          <mat-option value="true">tak</mat-option>
          <mat-option value="false">nie</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Godziny lekcyjne/zegarowe</mat-label>
        <mat-select formControlName="hoursType">
          <mat-option value="zegarowych">Zegarowe</mat-option>
          <mat-option value="lekcyjnych">Lekcyjne</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Ilość razy w tygodniu</mat-label>
        <input
          type="number"
          matInput
          formControlName="weeklyFrequency"
          placeholder="Ilość razy w tygodniu"
        />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Ilość godzin</mat-label>
        <input
          type="number"
          matInput
          formControlName="duration"
          placeholder="Ilość godzin"
        />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Data rozpoczęcia</mat-label>
        <input
          matInput
          type="date"
          formControlName="startDate"
          placeholder="Data rozpoczęcia"
        />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Data zakończenia</mat-label>
        <input
          matInput
          type="date"
          formControlName="endDate"
          placeholder="Data zakończenia"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Maksymalna ilość uczniów</mat-label>
        <input
          type="number"
          matInput
          formControlName="maxStudents"
          placeholder="Maksymalna ilość uczniów"
        />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Cena (zł)</mat-label>
        <input
          type="number"
          matInput
          formControlName="price"
          placeholder="Cena (zł)"
        />
      </mat-form-field>
    </div>

    <h3 mat-dialog-title>Zasoby kursu</h3>
    <mat-divider></mat-divider>
    <div class="resource-selection-container mt-3 mb-3 p-3 resources-section">
      <div *ngIf="selectedResources.length === 0" class="text-center p-3">
        <p>Brak wybranych zasobów</p>
      </div>

      <mat-chip-list *ngIf="selectedResources.length > 0" class="mb-4">
        <mat-chip
          *ngFor="let resource of selectedResources"
          removable="true"
          (removed)="removeResource(resource)"
          class="resource-chip"
        >
          <span class="resource-name">{{ resource.name }}</span>
          <div class="resource-actions">
            <button
              mat-icon-button
              class="preview-button"
              matTooltip="Podgląd zasobu"
              (click)="
                $event.stopPropagation(); openResourcePreviewDialog(resource)
              "
            >
              <mat-icon>visibility</mat-icon>
            </button>
            <mat-icon matChipRemove>cancel</mat-icon>
          </div>
        </mat-chip>
      </mat-chip-list>

      <div class="text-end mt-4">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="openResourceSelectionDialog()"
        >
          <mat-icon>library_books</mat-icon> Wybierz zasoby
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div
      class="mt-3"
      [formGroup]="coursesForm.get('additionalPayments')"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field fxFlex="30" appearance="outline">
        <mat-label>Kwota (zł)</mat-label>
        <input
          matInput
          type="number"
          formControlName="price"
          placeholder="Kwota (zł)"
        />
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Za co</mat-label>
        <input matInput formControlName="forWhat" placeholder="Za co" />
      </mat-form-field>
    </div>
    <h3 mat-dialog-title>Plan kursu</h3>
    <mat-divider></mat-divider>
    <app-course-plan
      [plan]="coursesForm.value.plan"
      [draggable]="true"
      [editable]="true"
      [courseResources]="selectedResources"
    ></app-course-plan>

    <h3 mat-dialog-title>Dodatkowe opłaty</h3>
  </div>
  <div mat-card-actions fxLayoutAlign="start" fxLayoutGap="10px">
    <button
      fxLayoutAlign="start"
      type="button"
      *ngIf="editMode"
      color="warn"
      (dblclick)="removeCourse(data.course)"
      mat-stroked-button
    >
      Usuń
    </button>
    <div fxFlex></div>
    <button type="button" mat-stroked-button mat-dialog-close color="primary">
      Anuluj
    </button>
    <button *ngIf="!editMode" type="submit" mat-flat-button color="primary">
      Dodaj
    </button>
    <button
      (click)="updateCourse(data.course)"
      *ngIf="editMode"
      type="button"
      mat-flat-button
      color="primary"
    >
      Zapisz
    </button>
  </div>
</form>
