import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { VersionService } from './version.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  courses = [];
  //  url = 'https://backend-int.linget.it'
  url;
  // url = 'https://test.lingking.com.pl:3001'
  private coursesUpdated = new Subject<any[]>();
  private coursesPlanItems = new Subject();
  foundPlanItem = new Subject<any[]>();
  // Track in-progress requests to avoid duplicate requests
  private inProgressRequests = new Set<string>();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private helpers: HelpersService,
    private versionService: VersionService,
    private snackbarService: SnackbarService
  ) {
    const loc = window.location.hostname;
    this.url = this.versionService.url;
  }

  saveNewCourse(course) {
    let courseToSend = this.helpers.clone(course);

    // Zabezpieczenie przed pustym lub undefined plan
    if (courseToSend.plan && Array.isArray(courseToSend.plan)) {
      courseToSend.plan = courseToSend.plan.map((item) => {
        try {
          // Sprawdzamy, czy item ma oczekiwaną strukturę
          if (item && item.item && item.item.item && item.item.item.id) {
            return {
              item: { id: item.item.item.id },
              optional: item.item.optional,
              done: item.item.done,
            };
          } else {
            console.warn('Nieprawidłowa struktura elementu planu:', item);
            // Zwracamy bezpieczną strukturę
            return {
              item: { id: item?.item?.id || 'unknown' },
              optional: item?.item?.optional || false,
              done: item?.item?.done || false,
            };
          }
        } catch (error) {
          console.error('Błąd podczas przetwarzania elementu planu:', error);
          return {
            item: { id: 'error' },
            optional: false,
            done: false,
          };
        }
      });
    } else {
      // Jeśli plan jest undefined lub nie jest tablicą, inicjalizujemy go jako pustą tablicę
      courseToSend.plan = [];
    }

    this.http
      .post(this.url + '/api/courses/addcourse', courseToSend)
      .subscribe((response) => {
        this.getCoursesList();
      });
  }
  duplicateCoursePlanItem(item) {
    let itemToPush = this.helpers.clone(item)
    itemToPush.new = true

    // Extract and increment the module number
    const nameMatch = item.name.match(/([^0-9]*)([0-9]+)([A-Za-z]*)(.*)/);
    if (nameMatch) {
      // Group 1: text before number (e.g., "BRAINIACS ")
      // Group 2: the number (e.g., "7")
      // Group 3: any letters after the number (e.g., "A")
      // Group 4: any remaining text (e.g., " 1x")

      const prefix = nameMatch[1];
      const num = parseInt(nameMatch[2]) + 1;
      const suffix = nameMatch[3] || '';
      const remaining = nameMatch[4] || '';

      itemToPush.name = prefix + num + suffix + remaining;
    } else {
      // Fallback if the pattern doesn't match
      itemToPush.name = item.name + ' - kopia';
    }

    // Clear all content from the duplicated item
    itemToPush.sets = [];
    itemToPush.activities = [];
    itemToPush.exercises = [];
    itemToPush.homework = [];
    itemToPush.files = [];
    itemToPush.skills = [];
    // Keep the ages array as requested
    // itemToPush.ages = [];
    itemToPush.description = '';
    itemToPush.approved = false;

    itemToPush.id = 'temp'
    this.saveNewCoursePlanItem(itemToPush)
  }
  saveNewCoursePlanItem(planItem, duplicate?) {
    this.http
      .post(this.url + '/api/courses/addplanitem', planItem)
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: courses.service.ts ~ line 47 ~ CoursesService ~ .subscribe ~ response',
          response,
        );
        this.coursesPlanItems.next({ newItemId: response.createdPlanItemId });

        // Always refresh the list to update the UI
        this.getPlanItemsList();

        // Show success message if it was a duplicate operation
        if (duplicate) {
          this.snackbarService.showSnackBar('Element został zduplikowany', 'success');
        }
      });
  }

  getCoursesList() {
    this.http
      .get(this.url + '/api/courses/getcourseslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let coursesArray = Object.keys(response).map((i) => response[i]);

        coursesArray = coursesArray[0].map((incomingCourse) => {
          return {
            id: incomingCourse._id,
            name: incomingCourse.data.name,
            weeklyFrequency: incomingCourse.data.weeklyFrequency,
            duration: incomingCourse.data.duration,
            maxStudents: incomingCourse.data.maxStudents,
            plan: incomingCourse.data.plan,
            hoursType: incomingCourse.data.hoursType,
            online: incomingCourse.data.online,
            price: incomingCourse.data.price,
            group: incomingCourse.data.group,
            individual: incomingCourse.data.individual,
            forWho: incomingCourse.data.forWho,
            description: incomingCourse.data.description,
            startDate: incomingCourse.data.startDate,
            endDate: incomingCourse.data.endDate,
            additionalPayments: incomingCourse.data.additionalPayments,
            resources: incomingCourse.data.resources || [],
          };
        });
        this.courses = coursesArray;
        this.courses.forEach((course: any) => {
          // Ensure we keep all plan items and just clean up nulls/undefined
          course.plan = (course.plan || []).filter(item => item !== null && item !== undefined);
        });
        this.coursesUpdated.next(this.courses);
      });
  }
  getPlanItemsList() {
    this.http
      .get(this.url + '/api/courses/getplanitemslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let coursesArray = Object.keys(response).map((i) => response[i]);
        coursesArray = coursesArray[0].map((item) => {
          item.data.id = item._id;
          return item.data;
        });
        console.log(
          '🚀 ~ file: courses.service.ts ~ line 89 ~ CoursesService ~ .subscribe ~ coursesArray',
          coursesArray,
        );

        this.coursesPlanItems.next(coursesArray);
      });
  }

  findPlanItemsbyId(id) {
    // Skip if no ID provided
    if (!id) {
      console.warn('Attempted to find plan item with no ID');
      return;
    }

    // Skip if request is already in progress
    if (this.inProgressRequests.has(id)) {
      return;
    }

    // Mark request as in progress
    this.inProgressRequests.add(id);


    // Directly fetch the item without caching
    this.http
      .get(this.url + '/api/courses/findplanitembyid/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe({
        next: (response) => {
          try {
            if (!response) {
              console.warn('Empty response from findPlanItemsbyId for ID:', id);
              return;
            }



            let planItemArray = Object.keys(response).map((i) => response[i]);
            if (planItemArray && planItemArray.length > 0) {
              planItemArray = planItemArray[0].map((item) => {
                if (item && item.data) {
                  const processedItem = { ...item.data };
                  processedItem.id = item._id;

                  // Ensure sets, activities, exercises, homework, and files are arrays
                  if (!Array.isArray(processedItem.sets)) {
                    processedItem.sets = [];
                  }

                  if (!Array.isArray(processedItem.activities)) {
                    processedItem.activities = [];
                  }

                  if (!Array.isArray(processedItem.exercises)) {
                    processedItem.exercises = [];
                  }

                  if (!Array.isArray(processedItem.homework)) {
                    processedItem.homework = [];
                  }

                  if (!Array.isArray(processedItem.files)) {
                    processedItem.files = [];
                  }

                  // Process nested items if needed
                  if (processedItem.item && typeof processedItem.item === 'object') {
                    if (!Array.isArray(processedItem.item.sets)) {
                      processedItem.item.sets = [];
                    }

                    if (!Array.isArray(processedItem.item.activities)) {
                      processedItem.item.activities = [];
                    }

                    if (!Array.isArray(processedItem.item.exercises)) {
                      processedItem.item.exercises = [];
                    }

                    if (!Array.isArray(processedItem.item.homework)) {
                      processedItem.item.homework = [];
                    }

                    if (!Array.isArray(processedItem.item.files)) {
                      processedItem.item.files = [];
                    }
                  }


                  return processedItem;
                }
                return null;
              }).filter(item => item !== null);

              if (planItemArray && planItemArray.length > 0) {
                // Emit the result without caching

                this.foundPlanItem.next(planItemArray[0]);
              } else {
                console.warn('No valid plan items found for ID:', id);
              }
            } else {
              console.warn('No plan items in response array for ID:', id);
            }
          } catch (error) {
            console.error('Error processing plan item data:', error);
            this.snackbarService.showSnackBar('Błąd podczas przetwarzania danych elementu planu. Odśwież stronę.', 'error');
          }
        },
        error: (error) => {
          console.error('Error fetching plan item:', error);
          this.snackbarService.showSnackBar('Błąd podczas pobierania elementu planu. Odśwież stronę.', 'error');
        },
        complete: () => {
          // Remove from in-progress tracking when complete
          this.inProgressRequests.delete(id);
        }
      });
  }

  findCourseByName(name) {
    this.http
      .post(
        this.url + '/api/courses/findcoursebyname',
        { name: name },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let coursesArray = Object.keys(response).map((i) => response[i]);

        coursesArray = coursesArray[0].map((incomingCourse) => {
          return {
            id: incomingCourse._id,
            name: incomingCourse.data.name,
            weeklyFrequency: incomingCourse.data.weeklyFrequency,
            duration: incomingCourse.data.duration,
            maxStudents: incomingCourse.data.maxStudents,
            plan: incomingCourse.data.plan,
            hoursType: incomingCourse.data.hoursType,
            online: incomingCourse.data.online,
            price: incomingCourse.data.price,
            group: incomingCourse.data.group,
            forWho: incomingCourse.data.forWho,
            description: incomingCourse.data.description,
            startDate: incomingCourse.data.startDate,
            endDate: incomingCourse.data.endDate,
            additionalPayments: incomingCourse.data.additionalPayments,
            resources: incomingCourse.data.resources || [],
          };
        });
        this.courses = coursesArray;

        this.coursesUpdated.next(this.courses);
      });
  }
  findCourseById(id) {
    this.http
      .post(
        this.url + '/api/courses/findcoursebyid',
        { id: id },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let coursesArray = Object.keys(response).map((i) => response[i]);

        coursesArray = coursesArray[0].map((incomingCourse) => {
          return {
            id: incomingCourse._id,
            name: incomingCourse.data.name,
            weeklyFrequency: incomingCourse.data.weeklyFrequency,
            duration: incomingCourse.data.duration,
            maxStudents: incomingCourse.data.maxStudents,
            plan: incomingCourse.data.plan,
            hoursType: incomingCourse.data.hoursType,
            online: incomingCourse.data.online,
            price: incomingCourse.data.price,
            group: incomingCourse.data.group,
            forWho: incomingCourse.data.forWho,
            description: incomingCourse.data.description,
            startDate: incomingCourse.data.startDate,
            endDate: incomingCourse.data.endDate,
            additionalPayments: incomingCourse.data.additionalPayments,
            resources: incomingCourse.data.resources || [],
          };
        });
        this.courses = coursesArray;

        this.coursesUpdated.next(this.courses);
      });
  }

  // filterStudents(text) {
  //
  //   const lowerText = text.toLowerCase()
  //   const filteredStudents = this.courses.filter(e => e.childFirstName.toLowerCase().includes(lowerText))

  //   this.coursesUpdated.next(filteredStudents)

  // }

  removeCourse(id) {
    this.http
      .delete(this.url + '/api/courses/removecourse/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.courses = this.courses.filter((e) => e.id !== id);

        this.coursesUpdated.next(this.courses);
      });
  }

  updateCourse(courseId, course) {
    let courseToSend = this.helpers.clone(course);

    // Zabezpieczenie przed pustym lub undefined plan
    if (courseToSend.plan && Array.isArray(courseToSend.plan)) {
      courseToSend.plan = courseToSend.plan.map((item) => {
        try {
          // Sprawdzamy, czy item ma oczekiwaną strukturę
          if (item && item.item && item.item.item && item.item.item.id) {
            return {
              item: { id: item.item.item.id },
              optional: item.optional,
              finished: item.finished,
            };
          } else {
            console.warn('Nieprawidłowa struktura elementu planu:', item);
            // Zwracamy bezpieczną strukturę
            return {
              item: { id: item?.item?.id || 'unknown' },
              optional: item?.optional || false,
              finished: item?.finished || false,
            };
          }
        } catch (error) {
          console.error('Błąd podczas przetwarzania elementu planu:', error);
          return {
            item: { id: 'error' },
            optional: false,
            finished: false,
          };
        }
      });
    } else {
      // Jeśli plan jest undefined lub nie jest tablicą, inicjalizujemy go jako pustą tablicę
      courseToSend.plan = [];
    }

    this.http
      .put(this.url + '/api/courses/updatecourse/' + courseId, courseToSend, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        const currentId = this.courses.findIndex((e) => e.id === courseId);
        const singleCourse = this.courses[currentId];
        (singleCourse.name = course.name),
          (singleCourse.weeklyFrequency = course.weeklyFrequency);
        singleCourse.duration = course.duration;
        singleCourse.maxStudents = course.maxStudents;
        singleCourse.plan = course.plan;
        singleCourse.online = course.online;
        singleCourse.hoursType = course.hoursType;
        singleCourse.price = course.price;
        (singleCourse.group = course.group),
          (singleCourse.forWho = course.forWho),
          (singleCourse.description = course.description);
        singleCourse.startDate = course.startDate;
        singleCourse.endDate = course.endDate;
        singleCourse.additionalPayments = course.additionalPayments;
        singleCourse.resources = course.resources || [];
        this.coursesUpdated.next(this.courses);
      });
  }
  updatePlanItem(itemId, item) {
    let itemToSend = this.helpers.clone(item);

    // Return an observable but also handle the response
    const request = this.http.put(
      this.url + '/api/courses/updateplanitem/' + itemId,
      { item: itemToSend },
      { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
    );

    // Subscribe to the request internally to handle success/failure
    request.subscribe({
      next: (response) => {
        // Show success notification
        this.snackbarService.showSnackBar('Element został zaktualizowany', 'success');

        // Emit the updated item directly instead of fetching it again
        this.coursesPlanItems.next(itemToSend);
      },
      error: (error) => {
        console.error('Error updating plan item:', error);
        this.snackbarService.showSnackBar('Błąd podczas aktualizacji elementu', 'error');
      }
    });

    // Return the original request so the component can also subscribe
    return request;
  }
  removePlanItem(id) {
    this.http
      .delete(this.url + '/api/courses/removeplanitem/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.getPlanItemsList();
      });
  }

  coursesUpdatedListener() {
    return this.coursesUpdated.asObservable();
  }
  coursesPlanItemsUpdatedListener() {
    return this.coursesPlanItems.asObservable();
  }
  foundPlanItemsUpdatedListener() {
    return this.foundPlanItem.asObservable();
  }

  findResourceById(id: string) {
    return this.http.post<{ resources: any[] }>(
      this.url + '/resources/findById',
      { id: id }
    );
  }
}
