import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { VersionService } from 'src/app/shared/services/version.service';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CurriculumService {
    private apiUrl;

    constructor(
        private http: HttpClient,
        private helpers: HelpersService,
        private versionService: VersionService
    ) {
        this.apiUrl = this.versionService.url;
    }

    getCurriculumList(): Promise<any[]> {
        return this.http.get<any>(`${this.apiUrl}/api/curriculum/getcurriculumlist`)
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                if (response && response['curriculums']) {
                    return response['curriculums'];
                }
                return [];
            });
    }

    getCurriculumById(id: string): Promise<any> {
        return this.http.get<any>(`${this.apiUrl}/api/curriculum/findcurriculumbyid/${id}`)
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                if (response && response['curriculum']) {
                    return response['curriculum'];
                }
                return null;
            });
    }

    findCurriculumByName(name: string): Promise<any[]> {
        return this.http.post<any>(`${this.apiUrl}/api/curriculum/findcurriculumbyname`, { name })
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                if (response && response['curriculums']) {
                    return response['curriculums'];
                }
                return [];
            });
    }

    saveCurriculum(curriculum: any): Promise<any> {
        return this.http.post<any>(`${this.apiUrl}/api/curriculum/addcurriculum`, curriculum)
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                return response;
            });
    }

    updateCurriculum(id: string, curriculum: any): Promise<any> {
        return this.http.put<any>(`${this.apiUrl}/api/curriculum/updatecurriculum/${id}`, curriculum)
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                return response;
            });
    }

    removeCurriculum(id: string): Promise<any> {
        return this.http.delete<any>(`${this.apiUrl}/api/curriculum/removecurriculum/${id}`)
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                return response;
            });
    }

    /**
     * Triggers migration of curriculums from resources to standalone curriculum documents
     */
    migrateCurriculums(): Promise<any> {
        return this.http.post<any>(`${this.apiUrl}/api/curriculum/migrate`, {})
            .pipe(
                catchError(error => this.helpers.handleError(error))
            )
            .toPromise()
            .then(response => {
                return response;
            });
    }

    someMethod() {
        return this.http.get('some/url')
            .pipe(
                catchError(error => this.helpers.handleError(error))
            );
    }
} 